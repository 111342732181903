import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
// MATERIAL UI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import Logo from "../components/Logo";
import LanguageSelector from "../components/LanguageSelector";
// STORE
import { toggleCollapsedNav } from "@/store/Settings/actions";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "@/store/Settings/types";
// STYLES
import "./styles.scss";


const PrivateAppHeader = ({
                            campaignName,
                            publicSurveyHeader,

                            // store to props
                            drawerType,
                            navCollapsed,
                            toggleCollapsedNav,
                          }) => {
  const onToggleCollapsedNav = () => {
    toggleCollapsedNav(!navCollapsed);
  };

  const headerClass = classnames({
    "app-main-header": true,
    "app-header__client-theme": true
  });

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";
  const logoBtnClass = classnames({
    "jr-menu-icon": true,
    [drawerStyle]: Boolean(drawerStyle)
  });

  return (
    <div className="app-header">
      <AppBar elevation={0} className={headerClass}>
        <Toolbar className="app-toolbar app-toolbar-private" disableGutters={false}>
          <div className="app-header__logo-wrapper">
            {/* MENU BUTTON  */}
            {!publicSurveyHeader && (
              <IconButton
                className={logoBtnClass}
                aria-label="Menu"
                onClick={onToggleCollapsedNav}
                size="large"
              >
                <span className="menu-icon"/>
              </IconButton>
            )}
            {/* LOGO  */}
            <Logo
              alt={campaignName}
              disableLink={publicSurveyHeader}
              redirectTo="/organizations"
              classes={{
                root: "logo-btn"
              }}
            />
          </div>

          <LanguageSelector/>
          {/*<div className="ellipse-shape"/>*/}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  drawerType: state.settings.drawerType,
  navCollapsed: state.settings.navCollapsed
});

const mapDispatchToProps = {
  toggleCollapsedNav
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivateAppHeader));
