import { requestActionCreator } from "@/utils/requestActionCreator";
import * as types from "../types";
import { baseApiUrl } from "@/configs/MainConfig";
import { authService } from "@/shared/services/auth.service";
import querystring from "query-string";

export const fetchRegistrationFormForAutocomplete = ({ id, page, search, incognito }) => {
  const query = { organization_id: id, page_size: 10 };

  if (page) {
    query.page = page;
  }

  if (search) {
    query.search = search;
  }

  if (incognito !== undefined) {
    query.incognito = incognito;
  }

  const queryString = querystring.stringify(query);
  const url = `${baseApiUrl}/organization/registration/form/list/?${queryString}`;

  return requestActionCreator(
    types.FETCH_ORGANIZATION_REGISTRATION_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};

export const fetchOrganizationRegistrationFormList = ({ id, page, pageSize, search, registrationId }) => {
  const query = { organization_id: id, };

  if (page) {
    query.page = page;
  }

  if (pageSize) {
    query.page_size = pageSize;
  }

  if (registrationId) {
    query.id = registrationId;
  }

  if (search) {
    query.search = search;
  }

  const queryString = querystring.stringify(query);
  const url = `${baseApiUrl}/organization/registration/form/list/table/?${queryString}`;

  return requestActionCreator(
    types.FETCH_ORGANIZATION_REGISTRATION_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};

export const fetchOrganizationRegistrationItemManage = ({ id }) => {
  const query = querystring.stringify({
    id,
  });

  const url = `${baseApiUrl}/organization/registration/form/item/manage/?${query}`;

  return requestActionCreator(
    types.FETCH_ORGANIZATION_REGISTRATION_ITEM_MANAGE,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};

export const updateOrganizationRegistrationItemManage = ({ id, data }) => {
  const query = querystring.stringify({
    id,
  });

  const url = `${baseApiUrl}/organization/registration/form/item/manage/?${query}`;

  const formData = new FormData();

  Object.keys(data).forEach(key => {
    if (key === "flows" || key === "config") {
      return formData.append(key, JSON.stringify(data[key]));
    }

    formData.append(key, data[key]);
  });

  return requestActionCreator(
    types.UPDATE_ORGANIZATION_REGISTRATION_ITEM_MANAGE,
    url,
    "PATCH",
    { ...authService.getAuthHeaders() },
    formData
  );
};

export const createOrganizationRegistrationItemManage = ({ data }) => {
  const url = `${baseApiUrl}/organization/registration/form/item/manage/`;
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    if (key === "flows" || key === "config") {
      return formData.append(key, JSON.stringify(data[key]));
    }

    formData.append(key, data[key]);
  });

  return requestActionCreator(
    types.CREATE_ORGANIZATION_REGISTRATION_ITEM_MANAGE,
    url,
    "POST",
    { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem("userToken")}` },
    formData
  );
};

export const deleteOrganizationRegistrationItemManage = ({ id }) => {
  const query = querystring.stringify({
    id,
  });

  const url = `${baseApiUrl}/organization/registration/form/item/manage/?${query}`;

  return requestActionCreator(
    types.DELETE_ORGANIZATION_REGISTRATION_ITEM_MANAGE,
    url,
    "DELETE",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchOrganizationRegistrationList = ({ id, pageSize, page, search }) => (dispatch, getState) => {
  const currentState = getState();
  const sortString = currentState.organization.selectedItem.registrations.confirmations.sortBy;

  const query = {
    organization_id: id,
    verification_status: "unverified",
    sort_by: sortString?.sort_by || ""
  };

  if (page) {
    query.page = page;
  }

  if (pageSize) {
    query.page_size = pageSize;
  }

  if (search) {
    query.search = search;
  }

  const queryString = querystring.stringify(query);
  const url = `${baseApiUrl}/organization/registration/list/table/?${queryString}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_REGISTRATION_FEED_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  ));
};

export const setOrganizationRegistrationConfirmationsSort = (payload) => ({
  type: types.SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_SORT,
  payload
});

export const fetchOrganizationRegistrationExtendedById = ({ organization_id, id }) => {
  const queryString = querystring.stringify({ organization_id, id });
  const url = `${baseApiUrl}/organization/registration/form/item/extended/?${queryString}`;

  return requestActionCreator(
    types.FETCH_ORGANIZATION_REGISTRATION_EXTENDED_BY_ID,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};

export const fetchOrganizationRegistrationAnswers = ({ id }) => {
  const queryString = querystring.stringify({ id });
  const url = `${baseApiUrl}/organization/registration/item/manage/?${queryString}`;

  return requestActionCreator(
    types.FETCH_ORGANIZATION_REGISTRATION_ANSWERS,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};

export const updateOrganizationRegistrationAnswerSingle = ({ data }) => {
  const url = `${baseApiUrl}/organization/registration/answer/single/`;

  return requestActionCreator(
    types.UPDATE_ORGANIZATION_REGISTRATION_FEED_ANSWER_SINGLE,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};

export const organizationRegistrationFeedSendToCRM = ({ data }) => {
  const url = `${baseApiUrl}/temp/iqc/registration/send_to_crm/`;

  return requestActionCreator(
    types.ORGANIZATION_REGISTRATION_FEED_SEND_TO_CRM,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};

export const generateRegistrationReport = ({ type, formId, regSubmissionIds }) => {
  const query = { form_id: formId };

  if (type === "xlsx" && !regSubmissionIds) {
    query.full_mode = true;
  }

  if (regSubmissionIds) {
    query.reg_act_ids = regSubmissionIds;
  }

  const queryString = querystring.stringify(query);
  const url = `${baseApiUrl}/reports/answers/registration_answers/file/${type}/?${queryString}`;

  return requestActionCreator(
    types.ORGANIZATION_REGISTRATION_GENERATE_REPORT,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
    undefined,
    undefined,
    "arraybuffer"
  );
};

export const generatePublishJson = ({ formId }) => {
  const query = { form_id: formId };

  const queryString = querystring.stringify(query);
  const url = `${baseApiUrl}/organization/registration/form/public/answers/?${queryString}`;

  return requestActionCreator(
    types.ORGANIZATION_REGISTRATION_GENERATE_PUBLISH_JSON,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    { formId },
  );
};

export const clearOrganizationRegistration = () => ({
  type: types.CLEAR_ORGANIZATION_REGISTRATION,
});

export const clearOrganizationRegistrationAnswers = () => ({
  type: types.CLEAR_ORGANIZATION_REGISTRATION_ANSWERS,
});

export const setAccountRegistrationsPagination = ({ page, pageSize }) => ({
  type: types.SET_ORGANIZATION_REGISTRATIONS_PAGINATION,
  payload: { page, pageSize }
});

export const setAccountRegistrationSubmissionsPagination = ({ page, pageSize }) => ({
  type: types.SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_PAGINATION,
  payload: { page, pageSize }
});

export const setAccountRegistrationsViewType = (viewType) => ({
  type: types.SET_ORGANIZATION_REGISTRATIONS_VIEW_TYPE,
  payload: viewType
});

export const resetAccountRegistrationsPagination = () => ({
  type: types.RESET_ORGANIZATION_REGISTRATIONS_PAGINATION
});

export const setAccountRegistrationSortBy = ({ tableType = "defaultTable", sortBy = null }) => ({
  type: types.SET_ORGANIZATION_REGISTRATIONS_SORT_BY,
  payload: { tableType, sortBy }
});
