import React from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
// UTILS
import reactStringReplace from "@/shared/utils/stringReplace";
// STYLES
import styles from "../styles.module.scss";

export const parseTermsHelper = ({ term, classes }) => {
  return reactStringReplace(term, /\[\[(.*?)\]\]/gmi, (match, i) => (
    <span key={i} className={`${styles.termTerm} ${classes.term}`}>{match}</span>
  ));
};

export const parseTermsWithFroalaHelper = ({ term, classes }) => {
  const parseResult = ReactHtmlParser(term, {
    decodeEntities: true,
    transform: (node, index) => {
      return convertNodeToElement(node, index, (node) => {
        if (node?.data) {
          return reactStringReplace(node.data, /\[\[(.*?)\]\]/gmi, (match, i) => (
            <span key={i} className={`${styles.termTerm} ${classes.term}`}>{match}</span>
          ));
        }
      });
    },
  });

  return parseResult?.[1]?.props?.children?.[1]?.props?.children ?? reactStringReplace(term, /\[\[(.*?)\]\]/gmi, (match, i) => (
    <span key={i} className={`${styles.termTerm} ${classes.term}`}>{match}</span>
  ));
};