import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
//HELPERS
import addUserControls from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/addUserControls";
// HOOKS
import useAssessorControls from "./useAssessorControls";
// STORE
import { getLocale } from "@/store/Settings/selectors";
import { getQuestionData } from "@/store/SurveyAssessment/selectors";


const useQuestionControls = ({
                               surveyModel,
                               surveyDomBuilder,
                               progressInfo,

                               permissions,
                               parentTarget,
                               service,
                               callbacks,
                             }) => {
  const { locale } = useSelector(getLocale);
  const questionData = useSelector(getQuestionData);

  const handleAddUserControls = useCallback((sender, options) => {
    addUserControls({
      options,
      props: {
        progressInfo,
        locale,
        permissions,
        surveyDomBuilder,
        parentTarget
      },
      state: {
        questionData
      }
    });
    // eslint-disable-next-line
  }, [
    progressInfo,
    parentTarget?.id,
    locale,
    questionData,
    permissions,
  ]);

  const { addAssessorControls } = useAssessorControls({
    props: {
      permissions,
      parentTarget,
      surveyDomBuilder,
      progressInfo,
      service,
      callbacks
    }
  });

  // COMMON USER VIEW
  useMemo(() => {
    if (!surveyModel) return;
    if (permissions?.canViewManagement) {
      surveyModel.onAfterRenderQuestion.add(addAssessorControls);
    } else {
      surveyModel.onAfterRenderQuestion.add(handleAddUserControls);
    }
  }, [surveyModel, permissions?.canViewManagement, addAssessorControls, handleAddUserControls]);
};

export default useQuestionControls;