import React from "react";
import { useDispatch, useSelector } from "react-redux";
// TYPES
import surveyStateTypes from "@/store/SurveyAssessment/surveyStateTypes";
// STORE
import { surveyAssessmentsState } from "@/store/SurveyAssessment/selectors";
// CONSTANTS
import * as navigationConstants from "@/shared/constants/surveyJs/navigation";


const BottomNavigation = ({
                            surveyModel,
                            showQuestionsTotalCount,
                            showCompleteBtn = true
                          }) => {
  const dispatch = useDispatch();
  const state = useSelector(surveyAssessmentsState);
  const isNavigationButtonVisible = (!surveyModel?.isEmpty && surveyModel?.currentPage);

  const handleCompletePrevPage = () => {
    surveyModel.prevPage();
  };

  const handleCompleteLastPage = () => {
    const isPageValid = surveyModel.isCurrentPageHasErrors;
    dispatch({ type: surveyStateTypes.SET_PAGE_VALID, payload: isPageValid });
    surveyModel.completeLastPage();
  };

  const handleNextPage = () => {
    const isPageValid = surveyModel.isCurrentPageHasErrors;
    dispatch({ type: surveyStateTypes.SET_PAGE_VALID, payload: isPageValid });

    surveyModel.nextPage();
  };

  const getTranslationByLocale = (data, localizableStringKey) => {
    let result;
    const surveyLocale = surveyModel.getLocale() || "default";

    if (surveyModel && localizableStringKey) {
      result = surveyModel.localizableStrings?.[localizableStringKey]?.values?.[surveyLocale];
    }

    if (!result) {
      result = data?.[surveyLocale] || data?.en;
    }
    return result;
  };

  return (
    <>
      {isNavigationButtonVisible && (
        <div className='survey_custom-navigation'>
          {/* PREV */}
          <div className='survey__navigation-group'>
            <button
              style={{
                display: state?.pagination?.surveyPrev
              }}
              className='survey__navigation-button'
              disabled={Boolean(surveyModel.isCompleted)}
              onClick={handleCompletePrevPage}
            >
              {getTranslationByLocale(navigationConstants.prevPageStrings, "pagePrevText")}
            </button>
          </div>
          {/* COUNT */}
          <div className='survey__navigation-group'>
            <div className='survey__unanswered'>
              <span className='page'>
                {state?.unansweredQuestionsQuantityPage}
                {" "}
                {getTranslationByLocale(navigationConstants.unansweredQuestionsStrings)}
              </span>
              {/* does it deprecated ?? */}
              {showQuestionsTotalCount && (
                <span>
                  {" "}
                  {state?.unansweredQuestionsQuantityTotal}
                  {" "}
                  {getTranslationByLocale(navigationConstants.totalQuestionsStrings)}
                </span>
              )}
            </div>
            {/* NEXT */}
            <button
              style={{
                display: state?.pagination?.surveyNext
              }}
              className='survey__navigation-button'
              disabled={Boolean(surveyModel.isCompleted)}
              onClick={handleNextPage}
            >
              {getTranslationByLocale(navigationConstants.nextPageStrings, "pageNextText")}
            </button>
            {/* COMPLETE */}
            {surveyModel.mode !== "display" && showCompleteBtn && (
              <button
                style={{
                  display: state?.pagination?.surveyComplete
                }}
                className='survey__navigation-button'
                disabled={Boolean(surveyModel.isCompleted)}
                onClick={handleCompleteLastPage}
              >
                {getTranslationByLocale(navigationConstants.completeSurveyStrings, "completeText")}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BottomNavigation;