import React, { memo } from "react";

const AsrOpenLinkIcon = memo(({ classes = { root: "" } }) => {
  return (
      <div className={classes.root}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.07683 6.51273V9.58965C9.07683 9.86167 8.96877 10.1225 8.77643 10.3149C8.58408 10.5072 8.3232 10.6153 8.05119 10.6153H2.41016C2.13815 10.6153 1.87727 10.5072 1.68492 10.3149C1.49258 10.1225 1.38452 9.86167 1.38452 9.58965V3.94862C1.38452 3.67661 1.49258 3.41573 1.68492 3.22339C1.87727 3.03104 2.13815 2.92298 2.41016 2.92298H5.48709M7.53837 1.38452H10.6153M10.6153 1.38452V4.46144M10.6153 1.38452L4.97426 7.02555"
              stroke="#243B47" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
  );
});

export default AsrOpenLinkIcon;