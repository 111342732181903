import * as types from "./types";
import { baseApiUrl } from "../../configs/MainConfig";
import { requestActionCreator } from "../../utils/requestActionCreator";
import { authService } from "../../shared/services/auth.service";
import querystring from "query-string";

/*
*  @param {Object} payload - query args
*  @param {string} payload.user_id
*  @param {string|number} payload.campaign_id
*  @param {Array} payload.cards
*  @param {boolean} payload.for_auditor
*  @param {boolean} payload.all_assessments
* @returns {Promise}
* */
export const getCampaignStatistics = (payload) => (dispatch) => {
  const payloadQuery = {
    all_assessments: true,
    for_auditor: false
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);
  return dispatch(requestActionCreator(
    types.GET_CAMPAIGN_STATISTICS,
    `${baseApiUrl}/campaign/statistics/cards/?${query}`,
    "GET",
    { ...authService.getAuthHeaders() },
  ));
};

export const downloadStatisticsByMember = (member_id, for_assessor = false, extension = "xlsx", organizationId) => requestActionCreator(
  types.DOWNLOAD_STATISTICS_BY_MEMBER,
  `${baseApiUrl}/campaign/statistics/${extension}/?member_id=${member_id}&organization_id=${organizationId}&all_assessments=true&for_assessor=${for_assessor}`,
  "GET",
  { ...authService.getAuthHeaders() },
  undefined,
  undefined,
  "arraybuffer"
);

export const downloadCampaignStatistics = (extension, payload) => {
  const payloadQuery = {};

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  return requestActionCreator(
    types.DOWNLOAD_CAMPAIGN_STATISTICS,
    `${baseApiUrl}/campaign/statistics/${extension}/?${query}`,
    "GET",
    { ...authService.getAuthHeaders() },
    undefined,
    undefined,
    "arraybuffer"
  );
};

export const downloadStatisticsByCampaign = (campaign_id, extension = "xlsx", organizationId) => requestActionCreator(
  types.DOWNLOAD_STATISTICS_BY_CAMPAIGN,
  `${baseApiUrl}/campaign/statistics/${extension}/?campaign_id=${campaign_id}&organization_id=${organizationId}&all_assessments=true`,
  "GET",
  { ...authService.getAuthHeaders() },
  undefined,
  undefined,
  "arraybuffer"
);

export const publishStatistics = (id, payload = {}) => {
  const payloadQuery = {};

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/statistics/public/publish/?assessment_id=${id}&${query}`;

  return requestActionCreator(
    types.PUBLISH_STATISTICS,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
  );
};

export const downloadStatistics = (type = "csv", {
  campaign_id,
  assessment_ides,
  organizationId
}) => {
  const url = `${baseApiUrl}/campaign/statistics/${type}/?campaign_id=${campaign_id}&organization_id=${organizationId}&assessment_ides=${assessment_ides}`;

  return requestActionCreator(
    types.DOWNLOAD_STATISTICS,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
    undefined,
    undefined,
    type === "xlsx" ? "arraybuffer" : "json"
  );
};