export const USER_TYPES = {
  USER: "user",
  AUDITOR: "auditor",
  PERMITTED_USER: "permitted_user"
};

export const SURVEY_CONTAINER_NAME = "surveyContainer";

export const answerVerificationTypes = ["unverified", "verified", "modified"];
export const answerVerificationTypesI18N = {
  "en": ["unverified", "verified", "modified"],
  "fr": ["non vérifié", "vérifié", "modifié"],
};

export const parentTargetTypes = {
  ASSESSMENT: "assessment",
  REGISTRATION: "registration"
}

export const emptyNote = {};
