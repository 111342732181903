import withStyles from "@mui/styles/withStyles";
import { default as MuiTextField } from "@mui/material/TextField";

const TextField = withStyles({
  root: {
    "& label.MuiInputLabel-root": {
      color: "var(--asr-a-grey-bluish)",
    },
    "& label.Mui-focused": {
      color: "var(--asr-a-grey-bluish)",
    },
    "& .MuiInput-underline": {
      color: "var(--asr-a-grey-bluish)",
      borderBottom: "1px solid #7D7C7D",
    },
    "& .MuiInput-underline:after": {
      content: "none",
    },
    "& .MuiInput-underline:before": {
      content: "none",
    },
  },
})(MuiTextField);

export default TextField;