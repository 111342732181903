import React from "react";
import classNames from "classnames";
import { Button, CircularProgress } from "@mui/material";

import IntlMessages from "@/utils/IntlMessages";

import styles from "./styles.module.scss";


const AsrSaveButton = ({
                         children,
                         isVisible = true,
                         handler,
                         label = "appModule.save",
                         type = "button",
                         loading,
                         disabled,
                         classNameWrapper,
                         classes = {
                           buttonRoot: ""
                         }
                       }) => {
  if (!isVisible) return null;

  const rootClass = classNames({
    [styles.saveButtonWrapper]: true,
    [classNameWrapper]: classNameWrapper
  });

  const buttonRootClass = classNames({
    [styles.saveButton]: true,
    [classes.buttonRoot]: classes.buttonRoot
  });

  return (
    <div className={rootClass}>
      <Button
        type={type}
        variant="outlined"
        disabled={disabled || loading}
        className={buttonRootClass}
        onClick={handler}
      >
        <span className={styles.label}>
          {label && !children && <IntlMessages id={label}/>}
          {children}
        </span>
      </Button>
      {loading && <CircularProgress size={24} className={styles.buttonProgress}/>}
    </div>

  );
};

export default AsrSaveButton;