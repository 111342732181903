import { assessorApi } from "@/store/services/rtk";
import transformPayloadToQuery from "@/store/services/utils/transformPayloadToQuery";
import { QUERY_KEYS } from "@/shared/constants/api";
import { HTTPMethods } from "@/store/services/config/constants";
import { QUERY_PARAMS, TAGS, TARGETS } from "./constants";
import transformPayloadToFormData from "@/shared/helpers/forms/transformPayloadToFormData";

const rtk = assessorApi.injectEndpoints({
  endpoints: (build) => ({
    getLandingPageInfoblocks: build.query({
      query: ({ targetKey, ...payload }) => {
        const defaultQuery = {
          [QUERY_KEYS.PAGE_SIZE]: 50,
        };

        const query = transformPayloadToQuery({
          payload: {
            ...payload,
            [QUERY_PARAMS.IFB_SECTION]: targetKey || QUERY_PARAMS.IFB_TARGET_OVERVIEW
          },
          defaultQuery
        });
        return { url: `/public/infoblocks/${query}` };
      },
      providesTags: [TAGS.INFOBLOCKS]
    }),
    getPublicInfoblockForModifying: build.query({
      query: ({ infoblockId }) => {
        return { url: `/public/infoblocks/${infoblockId}/` };
      },
      providesTags: [TAGS.INFOBLOCKS]
    }),
    createPublicInfoblock: build.mutation({
      query: (payload) => {
        const body = transformPayloadToFormData(payload);
        return {
          url: "/public/infoblocks/",
          method: HTTPMethods.POST,
          body
        };
      },
      invalidatesTags: [TAGS.INFOBLOCKS]
    }),
    updatePublicInfoblock: build.mutation({
      query: ({ infoblockId, ...payload }) => {
        const body = transformPayloadToFormData(payload);
        return {
          url: `/public/infoblocks/${infoblockId}/`,
          method: HTTPMethods.PATCH,
          body
        };
      },
      invalidatesTags: [TAGS.INFOBLOCKS]
    }),
    deletePublicInfoblock: build.mutation({
      query: ({ infoblockId }) => {
        return {
          url: `/public/infoblocks/${infoblockId}/`,
          method: HTTPMethods.DELETE,
        };
      },
      invalidatesTags: [TAGS.INFOBLOCKS]
    }),
    clonePublicInfoblock: build.mutation({
      query: ({ infoblockId }) => {
        return {
          url: `/public/infoblocks/${infoblockId}/actions/clone/`,
          method: HTTPMethods.POST
        };
      },
      invalidatesTags: [TAGS.INFOBLOCKS]
    }),
    getSystemBlockInfoItem: build.query({
      query: ({ targetKey, raise404 = true }) => {
        const defaultQuery = {
          [QUERY_KEYS.TARGET]: TARGETS.RETRIEVE_ITEM_INFO,
        };

        const query = transformPayloadToQuery({
          payload: {
            [QUERY_PARAMS.SYSTEM_BLOCK_KEY]: targetKey,
            raise404: raise404.toString()
          },
          defaultQuery
        });
        return { url: `/public/system-blocks/${query}` };
      },
      providesTags: [TAGS.SYSTEM_BLOCKS]
    }),
  }),
  overrideExisting: true,
});

rtk.enhanceEndpoints({
  addTagTypes: [
    TAGS.INFOBLOCKS,
    TAGS.SYSTEM_BLOCKS
  ]
});

export const {
  useGetLandingPageInfoblocksQuery,
  useGetPublicInfoblockForModifyingQuery,
  useCreatePublicInfoblockMutation,
  useClonePublicInfoblockMutation,
  useUpdatePublicInfoblockMutation,
  useDeletePublicInfoblockMutation,
  useGetSystemBlockInfoItemQuery
} = rtk;