export const CAMPAIGN_MEMBERS_SORT = "-last_access";

export const CAMPAIGN_ASSESSMENTS_CARDS_SORT = "rank,id";
export const CAMPAIGN_ASSESSMENTS_TABLE_SORT = "rank,id";

export const CAMPAIGN_ASSESSMENT_FILTERS_LABELS = "labels";
export const CAMPAIGN_ASSESSMENT_FILTERS_CUSTOM_FIELDS = "custom_fields";

export const CAMPAIGN_MEMBERS_SEARCH = "fullname_email";
export const CAMPAIGN_ASSESSMENTS_SEARCH = "name__created_dt_code";

// MEMBER MODALS
export const memberModals = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};
// ASSESSMENTS
export const assessmentsModals = {
  CREATE: "create",
  UPDATE: "update",
  FOLLOW_LINK: "follow_link",
  NO_CONNECTED_SURVEY: "no_connected_survey",
  NO_CAMPAIGN_MEMBER: "no_campaign_member",
  PUBLISH_JSON: "publish_json",
};

export const CAMPAIGN_CARDS_SEARCH = "name__created_dt_code";

export const CAMPAIGN_FILTERS_TYPE = "type";
export const CAMPAIGN_FILTERS_IDS = "ids";
export const CAMPAIGN_FILTERS_STATUS = "status";
// MEMBER INVITATIONS
export const CAMPAIGN_MEMBER_INVITATION_TABLE_SORT = "-created_dt";

export const CAMPAIGN_INVITATIONS_SEARCH = "search";
export const CAMPAIGN_INVITATIONS_USER_FILTER = "invited_by";
export const CAMPAIGN_INVITATIONS_STATUS_FILTER = "status";
export const CAMPAIGN_INVITATIONS_ROLES_FILTER = "roles";
export const memberInvitationModals = {
  CREATE: "create",
  UPDATE: "update",
  RESEND_INVITE: "resend_invite",
};
// USER ROLES
export const CAMPAIGN_MEMBER_ROLES_SEARCH = "name";
export const CAMPAIGN_MEMBER_ROLES_TYPE_FILTER = "type";
export const CAMPAIGN_MEMBER_ROLES_SORT = "-permissions_modified_dt";
export const memberRolesModals = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};