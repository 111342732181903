import { assessorApi } from "@/store/services/rtk";
// CONSTANTS
import { assessorApiTargets } from "../../config/assessorApiConstants";
import { REG_SUBMISSIONS_TAGS, TARGETS } from "@/store/services/accountRegistrations/constants";
// UTILS
import transformPayloadToQuery from "../../utils/transformPayloadToQuery";
import { HTTPMethods } from "../../config/constants";


const rtk = assessorApi.injectEndpoints({
  endpoints: (build) => ({
    // Set Registration Status
    setRegistrationSubmissionVerificationStatus: build.mutation({
      query: ({ reg_id, management_type, verification_action, ...body }) => {
        return {
          url: `/registrations/acts/${reg_id}/mt/${management_type}/action/${verification_action}/?target=set-verification-status`,
          method: HTTPMethods.POST,
          body
        };
      },
      invalidatesTags: [REG_SUBMISSIONS_TAGS.SUBMISSIONS_LIST]
    }),
    // Submit Registration
    submitPublicRegistration: build.mutation({
      query: ({ reg_form_id, target = "public-submission", ...body }) => {
        return {
          url: `/registrations/${reg_form_id}/acts/?target=${target}`,
          method: HTTPMethods.POST,
          body
        };
      }
    }),
    // Update Registration answer
    updateRegistrationAnswers: build.mutation({
      query: ({ reg_id, management_type, ...body }) => {
        return {
          url: `/registrations/acts/${reg_id}/mt/${management_type}/action/edit/?target=${assessorApiTargets.QUESTION_ANSWERS}`,
          method: HTTPMethods.POST,
          body
        };
      }
    }),

    fetchAccountRegistrationSubmissionsList: build.query({
      query: ({ accountId, target, ...payload } = {}) => {
        const defaultQuery = {
          target: target
        };
        const query = transformPayloadToQuery({ payload, defaultQuery });
        return { url: `/accounts/${accountId}/registrations/acts/${query}` };
      },
      providesTags: [REG_SUBMISSIONS_TAGS.SUBMISSIONS_LIST]
    }),

    reverseRejectionForRegistrationSubmission: build.mutation({
      query: ({ regId }) => {
        return {
          url: `registrations/acts/${regId}/action/reverse-rejection/`,
          method: HTTPMethods.POST
        };
      },
      invalidatesTags: [REG_SUBMISSIONS_TAGS.SUBMISSIONS_LIST]
    }),
    declineMultipleRegistrationSubmissions: build.mutation({
      query: ({ regIds, managementType } = {}) => {
        const data = {
          ids: regIds,
          management_type: managementType
        };

        return {
          url: "registrations/acts/action/decline/",
          method: HTTPMethods.POST,
          body: data
        };
      },
      invalidatesTags: [REG_SUBMISSIONS_TAGS.SUBMISSIONS_LIST]
    }),
    deleteRegistrationSubmission: build.mutation({
      query: ({ regId }) => {
        return {
          url: `registrations/acts/${regId}/`,
          method: HTTPMethods.DELETE
        };
      },
      invalidatesTags: [REG_SUBMISSIONS_TAGS.SUBMISSIONS_LIST]
    }),

    updateRegistrationSubmission: build.mutation({
      query: ({ regId, ...body }) => {
        return {
          url: `registrations/acts/${regId}/?target=${TARGETS.COMMON_UPDATE}`,
          method: HTTPMethods.PATCH,
          body
        };
      },
      invalidatesTags: [REG_SUBMISSIONS_TAGS.SUBMISSIONS_LIST, REG_SUBMISSIONS_TAGS.SUBMISSION_MANAGEMENT_INFO]
    }),

    fetchUpdateDataForRegistrationSubmission: build.query({
      query: ({ regId }) => {
        return { url: `registrations/acts/${regId}/?target=${TARGETS.COMMON_UPDATE}` };
      },
      providesTags: [REG_SUBMISSIONS_TAGS.SUBMISSION_MANAGEMENT_INFO]
    }),

    fetchRegistrationSubmissionMiniLogs: build.query({
      query: ({ regId }) => {
        const defaultQuery = {
          target: TARGETS.SUBMISSION_MINI_LOGS
        };
        const query = transformPayloadToQuery({ payload: {}, defaultQuery });
        return { url: `registrations/acts/${regId}/logs/${query}` };
      }
    })
  }),
  overrideExisting: true,
});

rtk.enhanceEndpoints({
  addTagTypes: [...Object.values(REG_SUBMISSIONS_TAGS)]
});

export const {
  useUpdateRegistrationAnswersMutation,
  useSubmitPublicRegistrationMutation,
  useSetRegistrationSubmissionVerificationStatusMutation,
  useFetchAccountRegistrationSubmissionsListQuery,
  useReverseRejectionForRegistrationSubmissionMutation,
  useDeclineMultipleRegistrationSubmissionsMutation,
  useDeleteRegistrationSubmissionMutation,
  useUpdateRegistrationSubmissionMutation,
  useFetchUpdateDataForRegistrationSubmissionQuery,
  useFetchRegistrationSubmissionMiniLogsQuery
} = rtk;