import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import IntlMessages from "../../../../utils/IntlMessages";
import classNames from "classnames";

const SideNavHome = ({ isStaffView = false, closeNavCallBack, path = null }) => {

  const match = useRouteMatch();

  const isActive = match.path === "/organizations/";

  const prependedClass = classNames({
    "prepend-icon": true,
    "home": true,
    "admin": isStaffView,
    "active": isActive
  });

  const tittleIntl = "sidenav.home";
  const defaultPath = "/organizations";
  const _path = path ? path : defaultPath;

  return (
      <NavLink
          className={prependedClass}
          to={_path}
          exact
          onClick={closeNavCallBack}
      >
       <span className="nav-text">
         <i className="zmdi zmdi-home"/>
         <IntlMessages id={tittleIntl}/>
       </span>
      </NavLink>
  );
};

SideNavHome.displayName = "SideNavHome";
export default SideNavHome;