import React from "react";
import classnames from "classnames";
// STYLES
import styles from "./styles.module.scss";

const AsrLangIcon = ({
                       classes = {
                         circle: "",
                         fillPath: ""
                       }
                     }) => {

  const circleClass = classnames({
    [styles.Circle]: true,
    [classes.circle]: classes.circle
  });

  const fillPathClass = classnames({
    [styles.FillPath]: true,
    [classes.fillPath]: classes.fillPath
  });

  return (
    <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" className={circleClass}/>
      <path
        className={fillPathClass}
        d="M21.3127 18.5217C22.608 16.6755 23.3682 14.4265 23.3682 12C23.3682 5.72139 18.2784 0.631578 11.9998 0.631578C8.59049 0.631578 5.53173 2.1323 3.44804 4.50918H7.64628V2.90526H9.70846V4.50918H14.9785V6.34223H13.1454C13.1454 6.34223 12.9163 9.32093 10.3959 11.8414C11.2825 12.728 12.1692 13.4775 13.4807 14.1958L15.7479 8.17527H17.5573L21.3127 18.5217ZM20.089 19.9878C18.0279 22.075 15.1649 23.3684 11.9998 23.3684C5.72116 23.3684 0.631348 18.2786 0.631348 12C0.631348 9.93296 1.18301 7.99477 2.14714 6.32462V6.34223H11.3124C11.3124 6.34223 11.3124 8.86267 9.02107 10.6957C9.02107 10.6957 7.64628 9.55006 6.72976 7.48788H4.89671C5.12584 8.86267 7.40294 11.4021 7.87541 11.8414C6.11114 13.2136 5.52085 13.5795 4.23926 14.374C4.02285 14.5082 3.78673 14.6546 3.52192 14.8201L4.43845 16.1949C4.43845 16.1949 7.64628 14.3618 9.02107 13.2162C10.1343 14.1439 11.2475 15.0716 12.8474 15.8776L11.2918 20.0087H12.9351L14.2184 16.4643H18.8609L20.089 19.9878ZM16.5842 9.93133L18.406 15.1592H14.6909L16.5842 9.93133Z"
      />
    </svg>
  );
};

export default AsrLangIcon;

