import React from "react";
import PropTypes from "prop-types";
import CardBox from "./CardBox";
import IntlMessages from "../../../../utils/IntlMessages";
import QuestionAnswer from "./QuestionAnswer";

const Card1 = React.forwardRef(function Card1({ data, exportImage, keyForSavingCardState }, ref) {
  return (
    <CardBox
      heading={<IntlMessages id="reports.card1Title"/>}
      exportImage={exportImage}
      ref={ref}
      keyForSavingCardState={keyForSavingCardState}
    >
      {data.length > 0
        ? (
          <div className="report-card__body__container">
            {data.map((question) => (
                <QuestionAnswer
                  key={question.question_id}
                  question={question}
                />
              )
            )}
          </div>
        ) : (
          <div className="report-card__no-content">
            <IntlMessages id="reports.noContent"/>
          </div>
        )}
    </CardBox>
  );
});

Card1.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  exportImage: PropTypes.func.isRequired,
  keyForSavingCardState: PropTypes.string.isRequired,
};

export default Card1;
