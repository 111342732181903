import { createSelector } from "reselect";

export const organizationsState = (state) => state.organization;

export const getAccountUserNormalizedPermissions = createSelector(organizationsState, (state) => {
  return state.account.currentUser.permissionsNormalized;
});

export const getAccountUserMeta = createSelector(organizationsState, (state) => {
  return state.account.currentUser.meta;
});

export const getAccountMembership = createSelector(organizationsState, (state) => {
  return state.account.currentUser.membership;
});