import React from "react";

export default {
  "remove": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2 14.2222C2 15.2 2.9 16 4 16H12C13.1 16 14 15.2 14 14.2222V3.55556H2V14.2222ZM15 0.888889H11.5L10.5 0H5.5L4.5 0.888889H1V2.66667H15V0.888889Z"
        fill="#4F4F4F"/>
  </svg>,
  "remove_thin": <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#E0E0E0"/>
  </svg>,
  "cannot_remove_thin": <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.5 18C2.4 18 1.5 17.1 1.5 16V4H13.5V16C13.5 17.1 12.6 18 11.5 18H3.5ZM11 1H14.5V3H0.5V1H4L5 0H10L11 1ZM7.5 13.8C6.30592 13.8 5.28642 13.0525 4.88385 12H10.1161C9.71358 13.0525 8.69408 13.8 7.5 13.8ZM7.5 8.2C8.87573 8.2 10.0197 9.19217 10.2555 10.5H4.74451C4.98027 9.19217 6.12427 8.2 7.5 8.2ZM7.5 7C5.29086 7 3.5 8.79086 3.5 11C3.5 13.2091 5.29086 15 7.5 15C9.70914 15 11.5 13.2091 11.5 11C11.5 8.79086 9.70914 7 7.5 7Z" fill="#EB5757"/>
  </svg>,
  "lock": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M3 7V5C3 3.67392 3.52678 2.40215 4.46447 1.46447C5.40215 0.526784 6.67392 0 8 0C9.32608 0 10.5979 0.526784 11.5355 1.46447C12.4732 2.40215 13 3.67392 13 5V7C13.5304 7 14.0391 7.21071 14.4142 7.58579C14.7893 7.96086 15 8.46957 15 9V14C15 14.5304 14.7893 15.0391 14.4142 15.4142C14.0391 15.7893 13.5304 16 13 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V9C1 8.46957 1.21071 7.96086 1.58579 7.58579C1.96086 7.21071 2.46957 7 3 7ZM11 5V7H5V5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5Z"
          fill="#4F4F4F"/>
  </svg>,
  "edit": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0.968897 10.6602L0.0236545 14.7482C-0.00895326 14.8977 -0.00783544 15.0525 0.0269261 15.2015C0.0616877 15.3504 0.129215 15.4897 0.224575 15.6092C0.319935 15.7286 0.440719 15.8252 0.578102 15.8919C0.715486 15.9586 0.866 15.9937 1.01865 15.9946C1.08977 16.0018 1.16144 16.0018 1.23257 15.9946L5.33691 15.0474L13.2173 7.18033L8.81939 2.78317L0.968897 10.6602Z"
        fill="#4F4F4F"/>
    <path
        d="M15.6952 3.24183L12.76 0.300417C12.567 0.108011 12.3059 0 12.0337 0C11.7614 0 11.5003 0.108011 11.3073 0.300417L9.67553 1.93564L14.0684 6.33779L15.7002 4.70256C15.7957 4.60638 15.8713 4.49228 15.9228 4.36678C15.9742 4.24127 16.0005 4.10684 16 3.97116C15.9995 3.83549 15.9724 3.70123 15.9201 3.57609C15.8678 3.45094 15.7914 3.33735 15.6952 3.24183Z"
        fill="#4F4F4F"/>
  </svg>,
  "plus": <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 0H7V6.9961H0V13.0039H7V20H13V13.0039H20V6.9961H13V0Z" fill="#24B3B3"/>
  </svg>,
  "locked": <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2.5 8.75V6.25C2.5 4.5924 3.15848 3.00269 4.33058 1.83058C5.50269 0.65848 7.0924 0 8.75 0C10.4076 0 11.9973 0.65848 13.1694 1.83058C14.3415 3.00269 15 4.5924 15 6.25V8.75C15.663 8.75 16.2989 9.01339 16.7678 9.48223C17.2366 9.95107 17.5 10.587 17.5 11.25V17.5C17.5 18.163 17.2366 18.7989 16.7678 19.2678C16.2989 19.7366 15.663 20 15 20H2.5C1.83696 20 1.20107 19.7366 0.732233 19.2678C0.263392 18.7989 0 18.163 0 17.5V11.25C0 10.587 0.263392 9.95107 0.732233 9.48223C1.20107 9.01339 1.83696 8.75 2.5 8.75ZM12.5 6.25V8.75H5V6.25C5 5.25544 5.39509 4.30161 6.09835 3.59835C6.80161 2.89509 7.75544 2.5 8.75 2.5C9.74456 2.5 10.6984 2.89509 11.4017 3.59835C12.1049 4.30161 12.5 5.25544 12.5 6.25Z"
          fill="#EB5757"/>
  </svg>,
  "unlocked": <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.5 9.5V6.1413L5.50025 6.13349C5.50078 6.11866 5.50212 6.08942 5.50532 6.04787C5.51177 5.96425 5.52552 5.83409 5.55441 5.6735C5.61337 5.3458 5.72867 4.92901 5.94497 4.52828C6.15693 4.13559 6.45781 3.77147 6.89751 3.49994C7.33315 3.23092 7.99006 3 9.00004 3C10.01 3 10.6669 3.23092 11.1026 3.49994C11.5422 3.77146 11.8431 4.13558 12.0551 4.52826C12.2714 4.92898 12.3866 5.34577 12.4456 5.67348C12.4745 5.83406 12.4882 5.96423 12.4947 6.04784C12.4979 6.08939 12.4992 6.11863 12.4997 6.13346L12.5 6.14289C12.5061 6.96609 13.1753 7.63155 14 7.63158C14.8284 7.6316 15.5 6.96005 15.5 6.13163L14 6.13158C15.5 6.13163 15.5 6.12999 15.5 6.12999L15.5 6.12824L15.5 6.12441L15.4999 6.11542L15.4996 6.09222C15.4993 6.07448 15.4988 6.0522 15.4978 6.02567C15.4959 5.97265 15.4924 5.90238 15.4858 5.81729C15.4727 5.64762 15.4474 5.41594 15.3982 5.14231C15.3009 4.6016 15.1037 3.86049 14.6951 3.10332C14.282 2.3381 13.6454 1.54433 12.6788 0.947428C11.7082 0.348026 10.4901 0 9.00004 0C7.50998 0 6.29186 0.348026 5.32123 0.947424C4.35466 1.54432 3.71804 2.33809 3.305 3.1033C2.8963 3.86047 2.6991 4.60157 2.60182 5.14229C2.55259 5.41591 2.52728 5.64759 2.5142 5.81726C2.50764 5.90236 2.5041 5.97262 2.50219 6.02564C2.50124 6.05217 2.50069 6.07446 2.50038 6.0922L2.50007 6.11539L2.50002 6.12438L2.5 6.12821L2.5 6.12996C2.5 6.12996 2.5 6.13156 3.90055 6.13158H2.5V9.5H2C0.89543 9.5 0 10.3954 0 11.5V18C0 19.1046 0.89543 20.5 2 20.5H15C16.1046 20.5 17 19.1046 17 18V11.5C17 10.3954 16.1046 9.5 15 9.5H5.5Z"
        fill="#24B3B3"/>
  </svg>,
  "cancel": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346632 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84548 13.6543 2.34569C12.1545 0.845895 10.121 0.00230171 8 0ZM2.08696 8C2.08561 6.91961 2.38065 5.85956 2.93996 4.93522C3.49928 4.01089 4.30143 3.25769 5.25912 2.75761C6.2168 2.25754 7.29331 2.02975 8.37147 2.09904C9.44963 2.16833 10.4881 2.53205 11.3739 3.15061L3.15061 11.3739C2.45789 10.3852 2.0865 9.2072 2.08696 8ZM8 13.913C6.7928 13.9135 5.61476 13.5421 4.62609 12.8494L12.8494 4.62609C13.4679 5.51188 13.8317 6.55037 13.901 7.62853C13.9703 8.70669 13.7425 9.7832 13.2424 10.7409C12.7423 11.6986 11.9891 12.5007 11.0648 13.06C10.1404 13.6194 9.08039 13.9144 8 13.913Z" fill="#4F4F4F"/>
  </svg>
};