import React, { useMemo } from "react";
import PropTypes from "prop-types";
// COMPONENTS
import AsrAutocompleteSingle from "@/shared/components/FormComponents/Autocomplete/AsrAutocompleteSingle";
import AsrAutocompleteMultipleRenderOption
  from "@/shared/components/FormComponents/Autocomplete/components/AsrAutocompleteMultipleRenderOption";
// STYLES
import styles from "./styles.module.scss";

const AsrAutoCompleteWithCustomOptionsSingle = ({
                                                  options,
                                                  value,
                                                  onChange,
                                                  classes = {
                                                    root: "",
                                                    selectFieldRoot: "",
                                                    inputRoot: "",
                                                    input: "",
                                                    inputLabel: "",
                                                    popupIndicator: "",
                                                    endAdornment: ""
                                                  },
                                                  label,
                                                  labelColon
                                                }) => {

  const findItemByValue = (findValue) => {
    if (findValue === undefined) return;
    return options.find(item => item.value === findValue);
  };

  const onChangeValue = (e, value) => {
    if (!onChange) return;
    onChange(value);
  };

  const selectedItem = useMemo(() => {
    return findItemByValue(value);
  }, [value]);

  return (
    <AsrAutocompleteSingle
      withLoadingIndicator={false}
      options={options}
      optionSelected="value"
      renderOption={(props, option) => {
        return (
          <AsrAutocompleteMultipleRenderOption
            {...props}
            key={option.value}
            withCheckbox={false}
            loading={false}
            option={option}
            labelToDisplay={["title"]}
          />
        );
      }}
      customRenderInput={<span className={styles.SelectedOptionTitle}>{selectedItem?.title}</span>}
      selectedItem={selectedItem}
      handleAutocompleteChange={onChangeValue}
      inputLabel={label}
      inputLabelColon={labelColon}
      disableClearable={true}
      classes={{
        root: classes?.root,
        selectFieldRoot: classes?.selectFieldRoot,
        inputRoot: classes?.inputRoot,
        input: classes?.input,
        inputLabel: classes?.inputLabel,
        popupIndicator: classes?.popupIndicator,
        endAdornment: classes?.endAdornment,
      }}
    />
  );
};

AsrAutoCompleteWithCustomOptionsSingle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    selectFieldRoot: PropTypes.string,
    inputRoot: PropTypes.string,
    input: PropTypes.string,
    inputLabel: PropTypes.string,
    popupIndicator: PropTypes.string,
    endAdornment: PropTypes.string
  }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelColon: PropTypes.bool
};
export default AsrAutoCompleteWithCustomOptionsSingle;
