import { createSelector } from "reselect";
import {
  CLONE_CAMPAIGN,
  FETCH_ASSESSMENT_EXTENDED_INFO_BY_ID,
  FETCH_CAMPAIGN_DASHBOARD_COMPOSITION_BY_SCROLL,
  FETCH_CAMPAIGN_DASHBOARD_EVENTS,
  FETCH_CAMPAIGN_DASHBOARD_EVENTS_BY_SCROLL,
  FETCH_CAMPAIGN_DASHBOARD_HEADER,
  FETCH_CAMPAIGN_DASHBOARD_MANAGERS,
  FETCH_CAMPAIGN_DASHBOARD_MANAGERS_BY_SCROLL,
  FETCH_CAMPAIGN_EXTENDED_INFO,
  FETCH_CAMPAIGN_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER,
  FETCH_CAMPAIGN_MEMBERS_CARD,
  FETCH_CAMPAIGN_MEMBERS_LIST_MINIMALISTIC,
  FETCH_CAMPAIGN_POTENTIAL_MEMBERS_LIST,
  FETCH_CAMPAIGN_USERS_ROLES_FOR_AUTOCOMPLETE,
  FETCH_CAMPAIGN_USERS_ROLES_TABLE,
  FETCH_EXTENDED_INFO_OF_ASSESSMENT_ANSWER_FOR_ASSESSOR,
  FETCH_TERM
} from "./types";
import { DOWNLOAD_STATISTICS, PUBLISH_STATISTICS } from "../Statistics/types";

export const campaignState = (state) => state.campaign;
export const networkState = (state) => state.network;

// BASE INFO
export const getCampaignBaseInfo = createSelector(campaignState, (state) => state?.info);
//
export const getCampaignAvailability = createSelector(campaignState, (state) => state?.info?.settings?.availability);
export const getCampaignTableColumns = createSelector(campaignState, (state) => state.config?.table?.columns);
export const getCampaignMembers = createSelector(campaignState, (state) => state.members);
export const getCampaignMembersPageViewType = createSelector(campaignState, (state) => state.members.pageViewType);
export const getCampaignMembersInvites = createSelector(campaignState, (state) => state.invites);
export const getCampaignUsersRoles = createSelector(campaignState, (state) => state.usersRoles);
export const getCampaignSelectedTableColumns = createSelector(campaignState, (state) => state.selectedColumns);

export const getCampaignAssessmentPagination = createSelector(campaignState, (state) => state.assessment.pagination);
export const getCampaignAssessmentPageViewType = createSelector(campaignState, (state) => state.assessment.pageViewType);

export const getCampaignDashboard = createSelector(campaignState, (state) => state.campaignDashboard);
export const getCampaignDashboardHeader = createSelector(getCampaignDashboard, (campaignDashboard) => campaignDashboard.header);
export const getCampaignDashboardManagers = createSelector(getCampaignDashboard, (campaignDashboard) => campaignDashboard.managers);

export const getCampaignDashboardEvents = createSelector(getCampaignDashboard, (campaignDashboard) => campaignDashboard.mainEvents);

// ORGANIZATION
export const getOrganization = createSelector(campaignState, (campaignDashboard) => campaignDashboard.info?.organization);
export const getOrganizationId = createSelector(getOrganization, (organizationInfo) => organizationInfo?.id);

//
export const getAssessmentUpdatedQuestion = createSelector(campaignState, (state) => state?.user_modules?.management?.updatedQuestion);
export const getOrganizationSlugName = createSelector(campaignState, (state) => state.organization_extended?.slug_name);
export const getCampaignOrganizationId = createSelector(campaignState, (state) => state.organization);


//========================= NETWORK ==================
// MEMBER / PARTICIPANT
export const getCampaignMembersCardListLoading = createSelector(networkState, (state) => {
  return Boolean(state?.queries[FETCH_CAMPAIGN_MEMBERS_CARD]?.pending);
});
export const getCampaignMembersMinimalisticListLoading = createSelector(networkState, (state) => {
  return Boolean(state?.queries[FETCH_CAMPAIGN_MEMBERS_LIST_MINIMALISTIC]?.pending);
});


export const getParticipantMe = createSelector(campaignState, (state) => state.requester?.participantMe);
export const getParticipantMeAssessmentsProgress = createSelector(getParticipantMe, (state) => state?.assessments?.progress);

// ROLE
export const getCampaignAssessmentPublishStatisticsLoading = createSelector(networkState, (state) => Boolean(state?.mutations[PUBLISH_STATISTICS]?.pending));
export const getCampaignAssessmentDownloadStatisticsLoading = createSelector(networkState, (state) => Boolean(state?.queries[DOWNLOAD_STATISTICS]?.pending));

export const getCampaignUserRolesTableLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_USERS_ROLES_TABLE]?.pending));
export const getCampaignUserRolesForAutocompleteLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_USERS_ROLES_FOR_AUTOCOMPLETE]?.pending));
export const getCampaignInvitationMemberRoleTypesByCurrentUserLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER]?.pending));

export const getCampaignPotentialMembersLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_POTENTIAL_MEMBERS_LIST]?.pending));


export const getFetchCampaignExtendedInfoLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_EXTENDED_INFO]?.pending));
export const getFetchTermLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_TERM]?.pending));
export const getCloneCampaignLoading = createSelector(networkState, (state) => Boolean(state?.queries[CLONE_CAMPAIGN]?.pending));
export const getAssessmentExtendedInfoByIdLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ASSESSMENT_EXTENDED_INFO_BY_ID]?.pending));
export const getAssessmentExtendedInfoByIdForAssessorLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_EXTENDED_INFO_OF_ASSESSMENT_ANSWER_FOR_ASSESSOR]?.pending));

// DASHBOARD
export const getCampaignDashboardHeaderLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_DASHBOARD_HEADER]?.pending));
export const getCampaignDashboardCompositionByScrollLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_DASHBOARD_COMPOSITION_BY_SCROLL]?.pending));
export const getCampaignDashboardManagersLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_DASHBOARD_MANAGERS]?.pending));
export const getCampaignDashboardManagersByScrollLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_DASHBOARD_MANAGERS_BY_SCROLL]?.pending));
export const getCampaignDashboardEventsLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_DASHBOARD_EVENTS]?.pending));
export const getCampaignDashboardEventsByScrollLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGN_DASHBOARD_EVENTS_BY_SCROLL]?.pending));