// CONSTANTS
import { actionsConstants } from "@/shared/hooks/policyValidation/constants/actionsConstants";
// HOOKS
import usePolicyValidation from "@/shared/hooks/policyValidation/usePolicyValidation";
import { useSelector } from "react-redux";
import { getAccountMembership } from "@/store/Organization/rootReducers/account/currentUser/selectors";

const useRegistrationPermissions = () => {
  const membership = useSelector(getAccountMembership);
  const canSeeNotConnectedAssessment = membership?.type === "member";

  const canViewConfirmations = usePolicyValidation({
    action: actionsConstants.RegistrationActListView,
  });

  const canRegistrationActModify = usePolicyValidation({
    action: actionsConstants.RegistrationActModify
  });

  const canRegistrationActModifyStatus = usePolicyValidation({
    action: actionsConstants.RegistrationActModifyStatus
  });

  const canModifyConfirmations = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerModify,
  });

  const canSeeConfirmations = usePolicyValidation({
    action: actionsConstants.RegistrationActListView,
  });

  const canRegistrationActDownloadReportXLSX = usePolicyValidation({
    action: actionsConstants.RegistrationActDownloadReportXLSX
  });

  const canRegistrationActDelete = usePolicyValidation({
    action: actionsConstants.RegistrationActDelete
  });

  return {
    canSeeNotConnectedAssessment,
    canViewConfirmations,
    canModifyConfirmations,
    canSeeConfirmations,
    canRegistrationActModify,
    canRegistrationActModifyStatus,
    canRegistrationActDownloadReportXLSX,
    canRegistrationActDelete
  };
};

export default useRegistrationPermissions;