import React from "react";
import _ from "lodash";
// CONSTANTS
import languageData from "@/components/LanguageSwitcher/data";
// UTILS
import CustomScrollbars from "@/utils/CustomScrollbars";
// COMPONENTS
import LanguageItem from "./LanguageItem";
// STYLES
import styles from "../../../../styles.module.scss";

const LanguageSwitcherSystem = ({
                                  switchLanguage,
                                  handleRequestClose,
                                  currentLocale = "en"
                                }) => {
  const languagesListSorted = _.sortBy(languageData, "name");
  return (
    <CustomScrollbars className="scrollbar app-custom-scrollbar">
      <ul className={styles.LanguagesDropdownList}>
        {languagesListSorted.map((languageData, index) => (
          <LanguageItem
            key={index}
            language={languageData}
            handleRequestClose={handleRequestClose}
            switchLanguage={switchLanguage}
            currentLocale={currentLocale}
            showLanguageIcon={false}
          />
        ))}
      </ul>
    </CustomScrollbars>
  );
};

export default LanguageSwitcherSystem;