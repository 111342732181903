import { success } from 'redux-saga-requests';
import * as types from './types';

const initialState = {
    datasets: {
        list: [],
        availableCount: 0,
    },
    selectedDataset: {},
    selectedReport: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case success(types.FETCH_DATASET_LIST): {
            const { response } = action.payload;
            return {
                ...state,
                datasets: {
                    list: response.results,
                    availableCount: response.count,
                }
            }
        }
        case success(types.PUSH_DATA_TO_DATASET): {
            const { datasetId } = action.meta;

            return {
                ...state,
                datasets: {
                    ...state.datasets,
                    list: state.datasets.list.map((item) => item.id === datasetId
                        ? ({
                            ...item,
                            need_update: false,
                        })
                        : item
                    ),
                }
            }
        }
        case success(types.FETCH_DATASET_EXTENDED_INFO): {
            const { response } = action.payload;
            return {
                ...state,
                selectedDataset: {
                    ...response.results,
                }
            }
        }
        case success(types.FETCH_REPORT_EXTENDED_INFO): {
            const { response } = action.payload;
            return {
                ...state,
                selectedReport: {
                    ...response.results,
                }
            }
        }
        case types.CLEAN_DATASET_EXTENDED_INFO: {
            return {
                ...state,
                selectedDataset: {}
            }
        }
        case types.CLEAN_REPORT_EXTENDED_INFO: {
            return {
                ...state,
                selectedReport: {}
            }
        }
        default:
            return state;
    }
};