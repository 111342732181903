import React from "react";
// COMPONENTS
import AsrLightModal from "src/shared/components/Modals/AsrLightModal";
import SupportUserVerificationForm from "./components/SupportUserVerificationForm";
// STYLES
import styles from "./styles.module.scss";

const UserVerificationInfoModal = ({
                                        open,
                                        closeModal,
                                        unpackModal = false,
                                        userEmail,
                                        userPassword,
                                        showThankYouTitle,
                                        initialStage,
                                        maxStage,
                                        periodBetweenStages
                                      }) => {

  const supportUserVerificationFormProps = {
    userEmail,
    userPassword,
    showThankYouTitle,
    initialStage,
    maxStage,
    periodBetweenStages
  };
  return (
    <>
      {unpackModal ? (
        <div className={styles.UserVerificationContentContainer}>
          <SupportUserVerificationForm {...supportUserVerificationFormProps}/>
        </div>
      ) : (
        <AsrLightModal
          open={open}
          onClose={closeModal}
        >
          <div className={styles.UserVerificationContentContainer}>
            <SupportUserVerificationForm {...supportUserVerificationFormProps}/>
          </div>
        </AsrLightModal>
      )}
    </>
  );
};

export default UserVerificationInfoModal;
