import { success } from "redux-saga-requests";
import * as types from "./types";
import { DEFAULT_LABELS_GROUPS_SORT, DEFAULT_LABELS_SORT } from "./constants";

const initialState = {
  labels: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: DEFAULT_LABELS_SORT
    },
  },
  groups: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: DEFAULT_LABELS_GROUPS_SORT
    },
    applicability: [],
  },
  selectedGroup: {
    labels: [],
    availableCount: 0,
    pagination: {
      page: 1,
      next: null,
    },
  },
  groupsAutocomplete: [],
  labelsAutocomplete: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.FETCH_LABELS): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;
      return {
        ...state,
        labels: {
          ...state.labels,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_LABELS_PAGINATION: {
      return {
        ...state,
        labels: {
          ...state.labels,
          pagination: {
            ...state.labels.pagination,
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_LABELS_FILTERS: {
      return {
        ...state,
        labels: {
          ...state.labels,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_LABELS_SORT_ORDER: {
      return {
        ...state,
        labels: {
          ...state.labels,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    case success(types.FETCH_LABELS_GROUPS): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;
      return {
        ...state,
        groups: {
          ...state.groups,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_LABELS_GROUPS_PAGINATION: {
      return {
        ...state,
        groups: {
          ...state.groups,
          pagination: {
            ...state.groups.pagination,
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_LABELS_GROUPS_FILTERS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_LABELS_GROUPS_SORT_ORDER: {
      return {
        ...state,
        groups: {
          ...state.groups,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    case success(types.FETCH_LABEL_GROUP_APPLICABILITY): {
      const {
        response: {
          results,
        }
      } = action.payload;

      return {
        ...state,
        groups: {
          ...state.groups,
          applicability: results,
        }
      };
    }
    case success(types.FETCH_LABELS_IN_GROUP): {
      const {
        response: {
          results,
          count,
          page,
          next,
        }
      } = action.payload;

      const addToList = results
        .filter(itemResponse => !state.selectedGroup.labels.some(itemState => itemState.id === itemResponse.id));

      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          labels: [...state.selectedGroup.labels, ...addToList],
          availableCount: count,
          pagination: {
            page,
            next,
          }
        }
      };
    }
    case types.CLEAN_LABELS_IN_SELECTED_GROUP: {
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          labels: [],
          availableCount: 0,
          pagination: {
            page: 1,
            next: null,
          }
        }
      };
    }
    case success(types.FETCH_LABELS_GROUPS_FOR_AUTOCOMPLETE): {
      const {
        response: {
          results,
        }
      } = action.payload;

      return {
        ...state,
        groupsAutocomplete: results,
      };
    }
    case success(types.FETCH_LABELS_FOR_AUTOCOMPLETE): {
      const {
        response: {
          results,
        }
      } = action.payload;

      return {
        ...state,
        labelsAutocomplete: results,
      };
    }
    default:
      return state;
  }
};
