import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import PropTypes from "prop-types";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  Tooltip,
  RadarChart,
  ResponsiveContainer,
  Legend
} from "recharts";
import CardBox from "./CardBox";
import IntlMessages, { useIntlMessageToString } from "../../../../utils/IntlMessages";
import QuestionAnswer from "./QuestionAnswer";

const Card4 = React.forwardRef(function Card4({
                                                data,
                                                exportImage,
                                                windowWidth,
                                                keyForSavingCardState,
                                                isIqcRelated
                                              }, ref) {
  const yearQuestion = data.find((question) => question.question_id === "1591353106185774") || {};
  const projectsNumberQuestion = data.find((question) => question.question_id === "1591353106185777") || {};
  const projectsPercentageQuestion = data.find((question) => question.question_id === "1591353106185779") || {};
  const radarData = get(data.find((question) => question.type === "score"), "items", []);

  const radarDataTranslations = {
    "Managerial": useIntlMessageToString("reports.managerial"),
    "Administration": useIntlMessageToString("reports.administration"),
    "Functional": useIntlMessageToString("reports.functional"),
    "Operational": useIntlMessageToString("reports.operational"),
    "Technical": useIntlMessageToString("reports.technical"),
    "Implementation": useIntlMessageToString("reports.implementation"),
    "Supportive": useIntlMessageToString("reports.supportive"),
    "Research and Development": useIntlMessageToString("reports.researchDevelopment"),
  };

  const preparedRadarData = radarData.map((item) => {
    return {
      ...item,
      title: radarDataTranslations[item.title],
      score: +item.score.toFixed(1)
    };
  });

  const isLargeWindow = windowWidth >= 1100;

  const avgLabel = useIntlMessageToString("reports.average");

  yearQuestion.title = useIntlMessageToString("reports.bimAdoption");
  projectsNumberQuestion.title = useIntlMessageToString("reports.completedProjects");
  projectsPercentageQuestion.title = useIntlMessageToString("reports.bimProjects");

  return (
    <CardBox
      heading={<IntlMessages id='reports.card4Title'/>}
      exportImage={exportImage}
      ref={ref}
      keyForSavingCardState={keyForSavingCardState}
    >
      <div className='report-card__body__container'>
        <QuestionAnswer
          question={yearQuestion}
        />
        <QuestionAnswer
          question={projectsNumberQuestion}
        />
        <QuestionAnswer
          question={projectsPercentageQuestion}
        />
        {isIqcRelated && (
          <>
            <div className='bar-chart__title'>
              <IntlMessages id='reports.budgetDistribution'/> <IntlMessages id='reports.chart'/>:
            </div>
            <ResponsiveContainer height={400}>
              <RadarChart
                outerRadius={isLargeWindow ? 150 : 100}
                data={preparedRadarData}
              >
                <Radar
                  name='SCORE'
                  dataKey='score'
                  stroke='#0c77c1'
                  fill='#ffffff'
                  fillOpacity={0}
                  strokeWidth={2}
                />
                <Radar
                  name={avgLabel}
                  dataKey='moy'
                  stroke='#4ea684'
                  fill='#ffffff'
                  fillOpacity={0}
                  strokeWidth={2}
                />
                <PolarGrid/>
                <PolarAngleAxis
                  dataKey='title'
                />
                <PolarRadiusAxis
                  type='number'
                  angle={90}
                  domain={[0, 100]}
                  orientation='left'
                />
                <Legend iconType='plainline'/>
                <Tooltip/>
              </RadarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </CardBox>
  );
});

Card4.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  exportImage: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  keyForSavingCardState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  windowWidth: state.settings.windowWidth,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Card4);
