import { usePolicyValidation, actionsConstants } from "@/shared/hooks/policyValidation";

const useAssessmentActPermissions = ({ campaignId }) => {
  const canAssessmentActAnswerNoteView = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerNoteView,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAnswerNoteCreate = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerNoteCreate,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAnswerNoteModify = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerNoteModify,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAnswerNoteLockUnlock = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerNoteLockUnlock,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAnswerNoteDelete = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerNoteDelete,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAnswerAnalyticsView = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerAnalyticsView,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAnswerVerify = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerVerify,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAnswerModify = usePolicyValidation({
    action: actionsConstants.AssessmentActAnswerModify,
    lookup: {
      campaign: campaignId
    }
  });

  const canAssessmentActAccessLockUnlock = usePolicyValidation({
    action: actionsConstants.AssessmentActAccessLockUnlock,
    lookup: {
      campaign: campaignId
    }
  });

  return {
    canAssessmentActAnswerNoteView,
    canAssessmentActAnswerNoteCreate,
    canAssessmentActAnswerNoteModify,
    canAssessmentActAnswerNoteLockUnlock,
    canAssessmentActAnswerNoteDelete,
    canAssessmentActAnswerAnalyticsView,
    canAssessmentActAnswerVerify,
    canAssessmentActAnswerModify,
    canAssessmentActAccessLockUnlock
  };
};

export default useAssessmentActPermissions;