export const FETCH_LABELS = "FETCH_LABELS";
export const SET_LABELS_PAGINATION = "SET_LABELS_PAGINATION";
export const SET_LABELS_FILTERS = "SET_LABELS_FILTERS";
export const SET_LABELS_SORT_ORDER = "SET_LABELS_SORT_ORDER";
export const FETCH_LABELS_GROUPS = "FETCH_LABELS_GROUPS";
export const SET_LABELS_GROUPS_PAGINATION = "SET_LABELS_GROUPS_PAGINATION";
export const SET_LABELS_GROUPS_FILTERS = "SET_LABELS_GROUPS_FILTERS";
export const MANAGE_LABELS_RELATIONS_WITH_GROUPS = "MANAGE_LABELS_RELATIONS_WITH_GROUPS";
export const CHECK_DUPLICATE_LABEL_IN_GROUP = "CHECK_DUPLICATE_LABEL_IN_GROUP";
export const DELETE_LABEL = "DELETE_LABEL";
export const UPDATE_LABEL = "UPDATE_LABEL";
export const CHECK_DUPLICATE_LABEL_GROUP = "CHECK_DUPLICATE_LABEL_GROUP";
export const FETCH_LABEL_GROUP_APPLICABILITY = "FETCH_LABEL_GROUP_APPLICABILITY";
export const CREATE_LABEL_GROUP = "CREATE_LABEL_GROUP";
export const UPDATE_LABEL_GROUP = "UPDATE_LABEL_GROUP";
export const DELETE_LABEL_GROUP = "DELETE_LABEL_GROUP";
export const SET_LABELS_GROUPS_SORT_ORDER = "SET_LABELS_GROUPS_SORT_ORDER";
export const CLONE_LABELS_GROUP = "CLONE_LABELS_GROUP";
export const FETCH_LABELS_IN_GROUP = "FETCH_LABELS_IN_GROUP";
export const CLEAN_LABELS_IN_SELECTED_GROUP = "CLEAN_LABELS_IN_SELECTED_GROUP";
export const FETCH_LABELS_GROUPS_FOR_AUTOCOMPLETE = "FETCH_LABELS_GROUPS_FOR_AUTOCOMPLETE";
export const FETCH_LABELS_FOR_AUTOCOMPLETE = "FETCH_LABELS_FOR_AUTOCOMPLETE";
// LABELS GROUP VISIBILITY
export const SET_LABEL_GROUP_HIDDEN = "SET_LABEL_GROUP_HIDDEN";
export const SET_LABEL_GROUP_SHOW = "SET_LABEL_GROUP_SHOW";





