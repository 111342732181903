import React, { useState } from "react";
import classNames from "classnames";
// COMPONENTS
import AppHeader from "@/components/AppHeader/PrivateAppHeader";
import PrivateFooter from "@/components/AppFooter/components/PrivateFooter";
import MainPageRouter from "@/router/MainPageRouter";
import CustomScrollbars from "@/utils/CustomScrollbars";
import ErrorBoundary from "@/shared/components/ErrorBoundary";
import BgLogo from "./components/BgLogo";
// HOOKS
import useTheme from "@/shared/hooks/themes/useTheme";
import useScrollTrack from "./hooks/useScrollTrack";
// STYLES
import "./styles.scss";


const PageContent = () => {
  const [onScrollData, setOnScrollData] = useState(null);
  const onScroll = useScrollTrack(onScrollData);

  const contentClass = classNames({
    "app-main-content-wrapper": true,
    "app-content__client-theme": true,
  });

  useTheme();

  return (
    <div className="app-main-container">
      <AppHeader/>
      <CustomScrollbars
        className="app-custom-scrollbar app-main-content-scrollbar-wrapper"
        options={{
          callbacks: { onScroll }
        }}
      >
        <main className={contentClass}>
          <div className="app-main-content">
            <BgLogo/>
            <div className="asr-app-wrapper">
              <ErrorBoundary>
                <MainPageRouter setOnScrollData={setOnScrollData}/>
              </ErrorBoundary>
            </div>
          </div>
          <PrivateFooter/>
        </main>
      </CustomScrollbars>
    </div>
  );
};

PageContent.displayName = "PageContent";
export default PageContent;