//Uncomment this line on creating a translation file
import { localization } from "../localizationManager";

export var dutchStrings = {
  groupButton: "Groep door mij",
  ungroupButton: "Degroeperen bij mij",
  selectButton: "Selecteer mij",
  hideColumn: "Kolom verbergen",
  showColumn: "Toon kolom",
  makePrivateColumn: "Kolom privé maken",
  makePublicColumn: "Maak de kolom openbaar",
  moveToDetail: "Ga naar Detail",
  showAsColumn: "Weergeven als kolom",
  filterPlaceholder: "Zoeken...",
  removeRows: "Verwijder rijen",
  showLabel: "Tonen",
  entriesLabel: "inzendingen",
  visualizer_text: "Teksten in tabel",
  visualizer_wordcloud: "Wordcloud",
  chartType_bar: "Bar",
  chartType_stackedbar: "Gestapelde Bar",
  chartType_doughnut: "Donut",
  chartType_pie: "Taart",
  chartType_scatter: "Verstrooien",
  chartType_gauge: "Meter",
  chartType_bullet: "Kogel",
  hideButton: "Verbergen",
  makePrivateButton: "Maak prive",
  makePublicButton: "Openbaar maken",
  showButton: "Tonen",
  resetFilter: "Filter resetten",
  changeLocale: "Wijzig de landinstelling",
  clearButton: "Doorzichtig",
  addElement: "Kies een vraag om weer te geven ...",
  defaultOrder: "Standaardvolgorde",
  ascOrder: "Oplopend",
  descOrder: "Aflopend",
  showMinorColumns: "Toon kleine kolommen",
  otherCommentTitle: "Andere items en opmerkingen",
  showPercentages: "Percentages weergeven",
  hidePercentages: "Verberg percentages",
  pdfDownloadCaption: "PDF",
  xlsxDownloadCaption: "Excel",
  csvDownloadCaption: "CSV",
  saveDiagramAsPNG: "Download plot als een png",
  hideEmptyAnswers: "Verberg lege antwoorden",
  showEmptyAnswers: "Toon lege antwoorden",
  "topNValueText-1": "Alle antwoorden",
  "topNValueText5": "Top 5 antwoorden",
  "topNValueText10": "Top 10 antwoorden",
  "topNValueText20": "Top 20 antwoorden",
};

//Uncomment these two lines on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
localization.locales["nl"] = dutchStrings;
localization.localeNames["nl"] = "Dutch";
