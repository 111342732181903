import React, { useState } from "react";
import classnames from "classnames";
// MATERIAL UI
import Avatar from "@mui/material/Avatar";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// CONSTANTS
import { imageSourceType } from "@/shared/constants/forms";
// COMPONENTS
import AsrSvgIcon from "@/shared/components/AsrSvgIcon/AsrSvgIcon";
import PreviewImageActions from "../PreviewImageActions/PreviewImageActions";
// STYLES
import styles from "../styles.module.scss";


const PreviewUrlImage = ({
                           inputFileId,
                           setLogoContent,
                           logoContent,
                           setIsImageFromPc,
                           imageRounded
                         }) => {
  const [isImageUrlInvalid, setIsImageUrlInvalid] = useState(false);
  const imageURL = logoContent?.[imageSourceType.LINK];
  const handleRemove = () => {
    setLogoContent((prevState) => ({
      ...prevState,
      [imageSourceType.LINK]: ""
    }));
  };

  const handleReplace = () => {
    setIsImageFromPc(true);
    document.getElementById(inputFileId).click();
  };

  const onAvatarError = () => setIsImageUrlInvalid(true);
  const onAvatarLoad = () => setIsImageUrlInvalid(false);

  const previewLinkContainerStyles = classnames({
    [styles.PreviewLinkContainer]: true,
    [styles.Rounded]: imageRounded,
    [styles.Error]: imageURL && isImageUrlInvalid
  });

  const avatarStyles = classnames({
    [styles.Avatar]: true,
    [styles.Rounded]: imageRounded,
    [styles.Error]: imageURL && isImageUrlInvalid
  });

  return (
    <div className={previewLinkContainerStyles}>
      {imageURL ? (
        <Avatar
          variant="rounded"
          alt="campaignAvatar"
          src={imageURL}
          className={avatarStyles}
          imgProps={{
            onError: () => onAvatarError(),
            onLoad: () => onAvatarLoad()
          }}
        >
          <span className={styles.AvatarFallback}>
             <IntlMessages id="appCommon.brokenLink"/>
          </span>
        </Avatar>
      ) : (
        <AsrSvgIcon
          classes={{
            asrSvgIconContainer: avatarStyles
          }}
          icon="logo_placeholder"
        />
      )}
      <div className={styles.ImagePreviewActions}>
        <PreviewImageActions
          classes={{
            root: styles.PreviewImageActions
          }}
          isRemove={imageURL}
          onRemove={handleRemove}
          onReplace={handleReplace}
          imageRounded={imageRounded}
        />
      </div>
    </div>
  );
};

export default PreviewUrlImage;