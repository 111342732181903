import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
// MATERIAL UI
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
import { generateUserFullName } from "@/utils/helpers/user";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// COMPONENTS
import AsrDropdownIcon from "@/shared/UI/Icons/AsrDropdownIcon/AsrDropdownIcon";
import AsrAvatar from "@/shared/components/Images/AsrAvatar";
// STORE
import { userLogoutAction } from "@/store/User/actions";
import { toggleCollapsedNav } from "@/store/Settings/actions";
import { getOrganizationMemberMe } from "@/store/Organization/selectors";
import { accountUserTypes } from "@/store/Organization/constants";
import { getCurrentUser } from "@/store/User/selectors";
// STYLES
import styles from "./styles.module.scss";


const UserInfo = (props) => {
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const currentUser = useSelector(getCurrentUser);

  const modalToggleHandlers = useToggleCommonModalHandlers();
  const { toggleCollapsedNav, userLogoutAction } = props;
  const accountUser = useSelector(getOrganizationMemberMe);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleProfileClick = () => {
    handleRequestClose();
    toggleCollapsedNav(false);
    modalToggleHandlers.editProfile({ show: true });
  };

  const handleLogoutRequest = () => {
    toggleCollapsedNav(false);
    userLogoutAction();
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <AsrDropdownIcon
        classes={{ root: styles.UserInfoCaret }}
        handler={handleClick}
      />
      <AsrAvatar
        alt='User avatar'
        src={currentUser?.content?.avatar}
        rounded={true}
        bordered={true}
        classes={{
          root: styles.UserInfoAvatar
        }}
      >
        {currentUser?.content?.fullname}
      </AsrAvatar>

      <div className={`user-detail ${styles.UserInfoDetail}`}>
        <div className={`user-name ${styles.UserInfoDetailName}`} onClick={handleClick}>
          {currentUser && generateUserFullName({
            titleName: currentUser?.content?.title_name,
            firstName: currentUser?.content?.first_name,
            lastName: currentUser?.content?.last_name,
          })}
        </div>
        {accountUser?.membership?.type && (
          <div>
            {
              accountUser?.membership?.type === accountUserTypes.MEMBER
                ? <IntlMessages id="organizationMainPage.member"/>
                : <IntlMessages id="organizationMainPage.user"/>
            }
          </div>
        )}

      </div>
      <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorE1}
            open={open}
            onClose={handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
      >
        <MenuItem onClick={handleProfileClick}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
          <IntlMessages id="user.profile"/>
        </MenuItem>
        <MenuItem onClick={handleLogoutRequest}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
          <IntlMessages id="user.logout"/>
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = {
  userLogoutAction,
  toggleCollapsedNav,
};

UserInfo.displayName = "UserInfo";
export default connect(null, mapDispatchToProps)(UserInfo);


