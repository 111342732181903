import surveyStateTypes from "./surveyStateTypes";

const initialState = {
  showTermModal: false,
  termData: "",
  showCompleteModal: false,
  questionData: {},
  showNoteModal: false,
  isPageValid: null,
  showPreviewNoteModal: false,
  unansweredQuestionsQuantityTotal: 0,
  unansweredQuestionsQuantityPage: 0,
  pagination: {
    surveyPrev: "",
    surveyNext: "",
    surveyComplete: ""
  }
};

const surveyAssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case surveyStateTypes.INITIALIZE_STATE: {
      const { questions, answers } = action.payload;
      let questionData = questions.reduce((acc, question) => ({
        ...acc,
        [question.propertyHash.name]: {
          name: question.propertyHash.name,
          question_id: question.propertyHash.question_id,
          answer: question.propertyHash.value,
          other_text: question.propertyHash.comment,
        }
      }), {});

      if (answers && Array.isArray(answers)) {
        answers.forEach((answer) => {
          questionData = {
            ...questionData,
            [answer.name]: {
              ...questionData[answer.name],
              created_dt: answer.created_dt || Date.now(),
              status: answer?.status || answer?.verification_status,
              note: answer?.note || answer?.auditor_note,
            }
          };
        });
      }
      return {
        ...state,
        questionData
      };
    }
    case surveyStateTypes.SET_QUESTION_DATA: {
      return {
        ...state,
        questionData: {
          ...state.questionData,
          ...action.payload
        }
      };
    }
    case surveyStateTypes.SET_QUESTION_STATUS: {
      return {
        ...state,
        questionData: {
          ...state.questionData,
          [action.payload.questionName]: {
            ...state.questionData[action.payload.questionName],
            status: action.payload.status
          }
        }
      };
    }
    case surveyStateTypes.SET_QUESTION_NOTE: {
      return {
        ...state,
        questionData: {
          ...state.questionData,
          [action.payload.questionName]: {
            ...state.questionData[action.payload.questionName],
            note: {
              ...action.payload.note
            }
          }
        }
      };
    }
    case surveyStateTypes.COUNT_UNANSWERED_QUESTION: {
      const unansweredQuestionsQuantityPage = action.payload.questions
        .filter(({
                   isVisible,
                   isRequired,
                   isAnswered,
                   page
                 }) => isVisible && isRequired && !isAnswered && page.isActive)
        .length;

      const unansweredQuestionsQuantityTotal = action.payload.questions
        .filter(({
                   isVisible,
                   isRequired,
                   isAnswered,
                   page
                 }) => isVisible && isRequired && !isAnswered && page.isVisible)
        .length;
      return {
        ...state,
        unansweredQuestionsQuantityTotal,
        unansweredQuestionsQuantityPage
      };
    }
    case surveyStateTypes.SET_PAGE_VALID : {
      return {
        ...state,
        isPageValid: action.payload
      };
    }
    case surveyStateTypes.ON_CURRENT_PAGE_CHANGED : {
      const { surveyModel } = action.payload;
      return {
        ...state,
        pagination: {
          surveyPrev: !surveyModel.isFirstPage ? "inline" : "none",
          surveyNext: !surveyModel.isLastPage ? "inline" : "none",
          surveyComplete: surveyModel.isLastPage ? "inline" : "none"
        }
      };
    }
    case surveyStateTypes.SET_STATE_COMMON : {
      return {
        ...state,
        ...action.payload
      };
    }
    case surveyStateTypes.RESET_SURVEY_ASSESSMENT_STATE: {
      return initialState;

    }
    default:
      return state;
  }
};

export default surveyAssessmentReducer;