// MATERIAL UI
import { createTheme } from "@mui/material/styles";


export const useMuiThemes = () => {
  const defaultMuiTheme = createTheme({
    palette: {
      primary: {
        main: `${window.getComputedStyle(document.body).getPropertyValue('--asr-a-blue')}`.trim()
      }
    }
  });

  return {
    defaultMuiTheme,
  };
};

export const getCurrentMuiTheme = () => {
  return useMuiThemes().defaultMuiTheme;
};
