import axios from "axios";
import { all, call } from "redux-saga/effects";
import { createRequestInstance, watchRequests, sendRequest } from "redux-saga-requests";
import { createDriver } from "redux-saga-requests-axios";

// const saveNewToken = (token) => localStorage.set("token", token);
const tokenExpired = (error) => {
  let result = false;
  if (error.response && error.response.status === 401) {
    if (localStorage.getItem("userToken")) {
      localStorage.removeItem("userToken");
      window.location.reload();
    }
  }
  return result;
};


function* onRequestSaga(request, action) {
  return request;
}

function* onSuccessSaga(response, action) {
  return response.data;
}

function* onAbortSaga(action) {
  // console.log(action)
  // const {type, payload: {request}, meta} = action;
  // const abortObject = {
  //   type,
  //   meta,
  //   request
  // };
  // console.log("SAGA ABORT",  abortObject);
}

function* onErrorSaga(error, action) {

  if (tokenExpired(error)) {
    try {
      return yield call(sendRequest, action, { silent: true });
    } catch (error) {
      return { error };
    }
  }
  return { error };
}

export default function* rootSaga() {
  yield createRequestInstance({
    driver: createDriver(axios),
    onRequest: onRequestSaga,
    onSuccess: onSuccessSaga,
    onError: onErrorSaga,
    // onAbort: onAbortSaga
  });
  yield all([
    watchRequests(),
  ]);
}
