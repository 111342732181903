import React from "react";
import { NavLink, Route, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
// CONSTANTS
import { actionsConstants } from "../../../../shared/hooks/policyValidation/constants/actionsConstants";
// UTILS
import IntlMessages from "../../../../utils/IntlMessages";
// HELPERS
import NestedLinks from "../../helpers/NestedLinks/NestedLinks";
// HOOKS
import usePolicyValidation from "../../../../shared/hooks/policyValidation/usePolicyValidation";


const SideNavLabels = ({
                         isGlobal = true,
                         isStaffView = false,
                         closeNavCallBack,
                         path = null,
                         isSuperUser
                       }) => {
  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const canLabelListView = usePolicyValidation({
    action: actionsConstants.LabelListView,
  });

  const canLabelGroupListView = usePolicyValidation({
    action: actionsConstants.LabelGroupListView,
  });

  const tittleIntl = isGlobal ? "sidenav.labelsGlobal" : "sidenav.labels";
  const defaultPath = isGlobal ? "/labels" : `${match.url}/labels`;
  const _path = path ? path : defaultPath;

  const canSeeLabels = isGlobal ? isSuperUser : canLabelListView;
  const canSeeLabelsGroup = isGlobal ? isSuperUser : canLabelGroupListView;

  const links = [
    {
      to: `${_path}/#labels`,
      hash: "#labels",
      isVisible: canSeeLabels,
      onClick: closeNavCallBack,
      label: "sidenav.labels"
    },
    {
      to: `${_path}/#groups`,
      hash: "#groups",
      isVisible: canSeeLabelsGroup,
      onClick: closeNavCallBack,
      label: "sidenav.labelGroups"
    },
  ];

  if (!canSeeLabels && !canSeeLabelsGroup) return null;
  return (
    <>
      <NavLink
        className={prependedClass}
        to={_path}
        onClick={closeNavCallBack}
      >
        <IntlMessages id={tittleIntl}/>
      </NavLink>
      <Route path={_path}>
        <NestedLinks links={links}/>
      </Route>
    </>
  );
};

SideNavLabels.displayName = "SideNavLabels";
export default SideNavLabels;