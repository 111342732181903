import React from "react";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  Line,
  LabelList,
} from "recharts";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import ChartSvg from "../../Components/Chart";
import CardBox from "../CardBox/CardBox";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";
import useAssessmentSectionsNames from "../../../Cards/useAssessmentSectionsNames";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    padding: "3px",
    border: "1px solid #24B2B2",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  chartTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "8pt",
    backgroundColor: "#F5F5F5",
    marginBottom: "15pt"
  },
  chartTitleText: {
    fontWeight: "medium",
    fontSize: "8pt",
    paddingVertical: "5pt",
    color: "#333333",
  },
  cardNoContent: {
    fontWeight: "medium",
    fontSize: "12pt",
    padding: "8pt 13pt",
    color: "#333333"
  }
});

// Create Component
const CardAssessment = ({ data, title, barColor }) => {
  const noContent = useIntlMessageToString("reports.noContent");
  const chartText = useIntlMessageToString("reports.chart");
  const avgLabel = useIntlMessageToString("reports.average");

  const sectionsNames = useAssessmentSectionsNames();

  const preparedData = data.map((item) => ({ ...item, title: sectionsNames[item.topic_id] }));

  return (
    <CardBox heading={title}>
      {data.length > 0
        ? (
          <View style={styles.cardBody}>
            <View style={styles.chartTitle}>
              <Text style={styles.chartTitleText}>{`${title} ${chartText}`}:</Text>
            </View>
            <View>
              <ChartSvg>
                <ComposedChart
                  width={450}
                  height={300}
                  data={preparedData}
                  margin={{ top: 10, right: 5, bottom: 0 }}
                >
                  <XAxis
                    height={80}
                    axisLine={false}
                    tick={false}
                  />
                  <YAxis
                    width={30}
                    type='number'
                    domain={[0, 100]}
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    axisLine={false}
                    unit='%'
                    tickLine={false}
                  />
                  <Legend
                    wrapperStyle={{
                      transform: "translateX(55pt)"
                    }}
                    align='right'
                    verticalAlign='middle'
                    layout='vertical'
                  />
                  <CartesianGrid
                    stroke='#f5f5f5'
                    vertical={false}
                  />
                  <Bar
                    dataKey='score'
                    barSize={30}
                    fill={barColor}
                    name='SCORE'
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey='title'
                      position='bottom'
                      offset={10}
                    />
                  </Bar>
                  <Line
                    type='monotone'
                    dataKey='moy'
                    name={avgLabel}
                    stroke='#bfbfbf'
                    dot={false}
                    strokeWidth={2}
                  />
                </ComposedChart>
              </ChartSvg>
            </View>
          </View>
        ) : (
          <Text style={styles.cardNoContent}>{noContent}</Text>
        )
      }
    </CardBox>
  );
};

export default CardAssessment;
