import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "../styles.module.scss";

const EvolutionaryContentStage2 = ({ onResendConfirmationClick }) => {

  return (
    <>
      <div className={styles.Bold}>
        <IntlMessages id="auth.messageNotReceived"/>?
      </div>
      <div>
        <IntlMessages
          id="auth.pleaseWaitSomeTimeOrCheckSpamEmail"
          values={{
            button: value => (
              <span
                className={styles.Link}
                onClick={onResendConfirmationClick}
              >
                {value}
              </span>
            )
          }}
        />
      </div>
    </>
  );
};

export default EvolutionaryContentStage2;
