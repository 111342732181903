import useAuditorPermissions from "../../../../../Organization/Assessor/hooks/useAuditorPermissions";
import usePolicyValidation from "../../../../../../shared/hooks/policyValidation/usePolicyValidation";
import { actionsConstants } from "../../../../../../shared/hooks/policyValidation/constants/actionsConstants";


const usePermissions = ({ forAssessorMemberProfile, campaignId, memberId, member }) => {
  const auditorPermissions = useAuditorPermissions();

  const permissions = {
    isDownloadCSVVisible: false,
    isDownloadPDFVisible: false
  };

  if (forAssessorMemberProfile) {
    permissions.isDownloadCSVVisible = auditorPermissions.canAuditorDownloadReportXLSX;
    permissions.isDownloadPDFVisible = auditorPermissions.isAuditor;
  } else {
    permissions.isDownloadCSVVisible = usePolicyValidation({
      action: actionsConstants.CampaignParticipantDownloadReportXLSX,
      lookup: {
        campaign: campaignId,
        id: memberId,
        owner: member?.meta?.created_by
      }
    });

    permissions.isDownloadPDFVisible = usePolicyValidation({
      action: actionsConstants.CampaignParticipantDownloadReportPDF,
      lookup: {
        campaign: campaignId,
        id: memberId,
        owner: member?.meta?.created_by
      }
    });
  }
  return permissions;
};

export default usePermissions;