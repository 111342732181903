import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// CONSTANTS
import { imageSourceType } from "@/shared/constants/forms";
import { inputFileID } from "./constants";
// COMPONENTS
import ErrorBoundary from "@/shared/components/ErrorBoundary/ErrorBoundary";
import ImagePreviewSection from "./components/ImagePreviewSection/ImagePreviewSection";
import ImageActionsSection from "./components/ImageActionsSection/ImageActionsSection";
// STYLES
import useClasses from "./hooks/useClasses";


// REQUIRED to use FORMIK with this components
const AsrUploadImageV2 = ({
                            name,
                            logo,
                            setFieldValue,
                            error,
                            options = {
                              inputFileId: inputFileID
                            },
                            classes = {
                              root: ""
                            },
                            imageRounded
                          }) => {
  const styles = useClasses(classes);
  const [isImageFromPc, setIsImageFromPc] = useState(true);
  const [logoContent, setLogoContent] = useState({
    [imageSourceType.UPLOADED_FILE]: "",
    [imageSourceType.LINK]: "",
  });

  useEffect(() => {
    setIsImageFromPc(logo?.source === imageSourceType.UPLOADED_FILE);
  }, [logo?.source]);

  useEffect(() => {
    setLogoContent((prevState) => ({
      ...prevState,
      [logo.source]: logo?.url
    }));
  }, [logo]);

  useEffect(() => {
    if (isImageFromPc) {
      setFieldValue(name, logoContent[imageSourceType.UPLOADED_FILE]);
    } else {
      setFieldValue(name, logoContent[imageSourceType.LINK]);
    }
  }, [isImageFromPc, logoContent, name, setFieldValue]);

  return (
    <ErrorBoundary>
      <div className={styles.root}>
        <ImagePreviewSection
          inputFileId={options.inputFileId}
          isImageFromPc={isImageFromPc}
          setIsImageFromPc={setIsImageFromPc}
          setLogoContent={setLogoContent}
          logoContent={logoContent}
          error={error}
          imageRounded={imageRounded}
        />

        <ImageActionsSection
          inputFileId={options.inputFileId}
          setIsImageFromPc={setIsImageFromPc}
          isImageFromPc={isImageFromPc}
          name={name}
          setFieldValue={setFieldValue}
          setLogoContent={setLogoContent}
          logoContent={logoContent}
        />
      </div>
    </ErrorBoundary>
  );
};

AsrUploadImageV2.displayName = "AsrUploadImageV2";
AsrUploadImageV2.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    url: PropTypes.string,
    source: PropTypes.oneOf([
      imageSourceType.LINK,
      imageSourceType.UPLOADED_FILE
    ]),
  }),
  setFieldValue: PropTypes.func,
  error: PropTypes.object,
  options: PropTypes.shape({
    inputFileId: PropTypes.string,
  }),
  classes: PropTypes.shape({
    root: PropTypes.string
  }),
  imageRounded: PropTypes.bool,
};
export default AsrUploadImageV2;