import React from "react";
import { NavLink, Route, useParams, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
// UTILS
import IntlMessages from "../../../../../utils/IntlMessages";
// HOOKS
import useSideNavOrganizationPermissions from "./hooks/useSideNavOrganizationPermissions";
import useAccountPermissions from "../../../../../pages/Organization/AccountMainPage/hooks/useAccountPermissions";
// COMPONENTS
import SideNavLabels from "../../../Components/SideNavLabels/SideNavLabels";
import SideNavLogs from "../../../Components/SideNavLogs/SideNavLogs";
import SideNavHome from "../../../Components/SideNavHome/SideNavHome";
import SideNavStatements from "../../../Components/SideNavStatements/SideNavStatements";
import SideNavMembers from "./components/SideNavMembers/SideNavMembers";
import SideNavTemplates from "./components/SideNavTemplates/SideNavTemplates";
import SideNavAuditors from "./components/SideNavAuditors/SideNavAuditors";
import { SideNavRegistrations } from "./components/SideNavRegistrations/SideNavRegistrations";
import SideNavCampaigns from "./components/SideNavCampaigns/SideNavCampaigns";
// STYLES
import "../../styles.scss";
import styles from "./styles.module.scss";


const SideNavOrganization = (props) => {
  const {
    currentOrganization,
    campaignOrganizationExtended,
    organizationId
  } = props;

  const { organizationId: organizationIdFromParams } = useParams();
  const { name, created_dt_code, id: organizationCurrentId } = currentOrganization || {};
  const { name: nameFromCampaign, id: organizationExtendedId } = campaignOrganizationExtended || {};

  const id = organizationIdFromParams || organizationExtendedId || organizationCurrentId || organizationId;

  const match = useRouteMatch();

  const { canViewAccountLogs, isUserRole } = useAccountPermissions();

  const isStaffView = isUserRole;

  const {
    isAuditor,
    canSeeAccountDatasets,
    canSeeAuditorAssignments,
    canSeeStatements,
    canSeeStatementsSets
  } = useSideNavOrganizationPermissions();

  // TODO: TODO: POL
  const canSeeCertificates = false;

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  return (
    <>
      {/*  CONSOLE */}
      <li className="menu no-arrow">
        <div className="selected-organization">
          <NavLink
            className="p-0"
            to={`${match.url}`}
            onClick={props.closeNavCallBack}
          >
            <div className="nav-header admin">
              <IntlMessages id="sidenav.myOrganization"/>
            </div>
            <div className={`organization-name ${styles.SideNavOrganizationInfo}`}>
              <span className={styles.SideNavOrganizationInfoName}>{name || nameFromCampaign}</span>
              <span className={styles.SideNavOrganizationInfoCode}>Org ID: {created_dt_code}</span>
            </div>
          </NavLink>
        </div>
      </li>
      {/* HOME */}
      <li className="menu no-arrow">
        <SideNavHome isStaffView={isStaffView} {...props} />
      </li>

      <li className="menu no-arrow">
        {!isStaffView ? (
          <>
            <SideNavCampaigns
              organizationId={id}
              isStaffView={isStaffView}
              props={props}/>
          </>
        ) : (
          <>
            <SideNavCampaigns
              organizationId={id}
              isStaffView={isStaffView}
              props={props}/>
            {/* MEMBERS */}
            <SideNavMembers isStaffView={isStaffView} props={props}/>
            {/*   REGISTRATIONS  */}
            <SideNavRegistrations isStaffView={isStaffView} props={props}/>
            {/* TEMPLATES */}
            <SideNavTemplates isStaffView={isStaffView} props={props}/>
            {/*  PAGES */}
            {/*{canSeeStaticPages && (*/}
            {/*    <SideNavPages isGlobal={false} isStaffView={isStaffView} {...props}  />*/}
            {/*)}*/}
            {/* AUDITORS */}
            <SideNavAuditors
              isAuditor={isAuditor}
              canSeeAuditorAssignments={canSeeAuditorAssignments}
              canSeeAuditorsNotes={isAuditor}
              isStaffView={isStaffView} props={props}/>
            {/* CERTIFICATES  */}
            {canSeeCertificates && (
              <NavLink
                className={prependedClass}
                to={`${match.url}/certificates`}
                onClick={props.closeNavCallBack}
              >
                <IntlMessages id="sidenav.certificates"/>
              </NavLink>
            )}
            {/*   STATEMENTS  */}
            <SideNavStatements
              canSeeStatements={canSeeStatements}
              canSeeStatementsSets={canSeeStatementsSets}
              isTranslations={false}
              isGlobal={false}
              isStaffView
              {...props}/>
            {/* LABELS  */}
            <SideNavLabels isGlobal={false} isStaffView={isStaffView} {...props}/>
            {/* REPORTS */}
            {canSeeAccountDatasets && (
              <>
                <NavLink
                  className={prependedClass}
                  to={`${match.url}/reports`}
                  onClick={props.closeNavCallBack}
                >
                  <IntlMessages id="sidenav.reports"/>
                </NavLink>
                <Route path={`${match.path}/reports`}>
                  <NavLink
                    className="nested-link"
                    to={`${match.url}/reports#dataAnalysis`}
                    onClick={props.closeNavCallBack}
                  >
                    <IntlMessages id="sidenav.dataAnalysis"/>
                  </NavLink>
                </Route>
              </>
            )}
            {/*  LOGS */}
            {canViewAccountLogs && (
              <SideNavLogs isGlobal={false} isStaffView={isStaffView} {...props}/>
            )}
          </>
        )}
      </li>
    </>
  );
};


const mapStateToProps = (state) => ({
  currentOrganization: state.organization.selectedItem,
  campaignOrganizationExtended: state.campaign.organization_extended,
  isSuperUser: state.auth.currentUser?.meta?.is_superuser
});

export default connect(mapStateToProps, null)(SideNavOrganization);