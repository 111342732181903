import { assessorApi } from "@/store/services/rtk";
// CONSTANTS
import {
  EDIT_PROFILE_INFO_TAG,
  MY_PROFILE_TARGET,
  SEND_USER_VERIFICATION_EMAIL_TARGET,
  UPDATE_AVATAR_TARGET,
  UPDATE_PROFILE_TARGET,
  USER_PROFILE_INFO_TAG,
  VALIDATE_SELF_PASSWORD_TARGET,
  VERIFY_USER_TARGET,
} from "./constants";
import { HTTPMethods } from "@/store/services/config/constants";
// UTILS
import transformPayloadToQuery from "@/store/services/utils/transformPayloadToQuery";
import transformPayloadToFormData from "@/store/services/utils/transformPayloadToFormData";
// ACTIONS
import { setCurrentUser } from "@/store/User/actions";

const rtk = assessorApi.injectEndpoints({
  endpoints: (build) => ({
    getMyProfileInfo: build.query({
      query() {
        const defaultQuery = {
          target: MY_PROFILE_TARGET,
        };

        const query = transformPayloadToQuery({ defaultQuery });
        return { url: `/users/${query}` };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setCurrentUser(data));
      },
      providesTags: [USER_PROFILE_INFO_TAG]
    }),
    getEditProfileInfo: build.query({
      query: ({ userId }) => {
        const defaultQuery = {
          target: UPDATE_PROFILE_TARGET,
        };

        const query = transformPayloadToQuery({ defaultQuery });
        return { url: `/users/${userId}/${query}` };
      },
      providesTags: [EDIT_PROFILE_INFO_TAG]
    }),

    validateUserPassword: build.mutation({
      query: ({ password }) => {
        const defaultQuery = {
          target: VALIDATE_SELF_PASSWORD_TARGET,
        };
        const query = transformPayloadToQuery({ defaultQuery });

        return {
          url: `/users/${query}`,
          method: HTTPMethods.POST,
          body: { password }
        };
      }
    }),

    updateUserProfile: build.mutation({
      query: ({
                userId, verificationPassword, email, firstName,
                lastName, titleName, newPassword, avatar
              } = {}) => {
        const payload = { verification_password: verificationPassword };

        const personalInfo = {};
        if (email !== undefined) {
          personalInfo.email = email;
        }
        if (firstName !== undefined) {
          personalInfo.first_name = firstName;
        }
        if (lastName !== undefined) {
          personalInfo.last_name = lastName;
        }
        if (titleName !== undefined) {
          personalInfo.title_name = titleName;
        }
        if (newPassword !== undefined) {
          personalInfo.password = newPassword;
        }
        if (Object.values(personalInfo).length > 0) {
          payload.personal_info = personalInfo;
        }

        if (avatar !== undefined) {
          payload.avatar = avatar;
        }

        const formData = transformPayloadToFormData(payload);
        const query = transformPayloadToQuery({
          defaultQuery: {
            target: UPDATE_PROFILE_TARGET
          }
        });

        return {
          url: `/users/${userId}/${query}`,
          method: HTTPMethods.PATCH,
          body: formData
        };
      },
      invalidatesTags: [
        EDIT_PROFILE_INFO_TAG,
        USER_PROFILE_INFO_TAG
      ]
    }),

    updateUserAvatar: build.mutation({
      query({ userId, avatar }) {
        const formData = transformPayloadToFormData({ avatar });
        const query = transformPayloadToQuery({
          defaultQuery: {
            target: UPDATE_AVATAR_TARGET
          }
        });
        return {
          url: `/users/${userId}/${query}`,
          method: HTTPMethods.PATCH,
          body: formData
        };
      },
      invalidatesTags: [
        EDIT_PROFILE_INFO_TAG,
        USER_PROFILE_INFO_TAG
      ]
    }),

    userSendVerificationEmailAuth: build.mutation({
      query({ email, password }) {
        const query = transformPayloadToQuery({
          defaultQuery: {
            target: SEND_USER_VERIFICATION_EMAIL_TARGET
          }
        });

        const formData = transformPayloadToFormData({ email, password });
        return {
          url: `/users/${query}`,
          method: HTTPMethods.POST,
          body: formData
        };
      },
    }),

    userSendVerificationEmailByAdmin: build.mutation({
      query({ userId }) {
        const query = transformPayloadToQuery({
          defaultQuery: {
            target: SEND_USER_VERIFICATION_EMAIL_TARGET
          }
        });
        return {
          url: `/users/${userId}/${query}`,
          method: HTTPMethods.POST,
        };
      },
    }),

    userVerifyByAdmin: build.mutation({
      query({ userId }) {
        const query = transformPayloadToQuery({
          defaultQuery: {
            target: VERIFY_USER_TARGET
          }
        });
        return {
          url: `/users/${userId}/${query}`,
          method: HTTPMethods.POST,
        };
      },
    }),
  }),
  overrideExisting: true
});

rtk.enhanceEndpoints({
  addTagTypes: [
    EDIT_PROFILE_INFO_TAG,
    USER_PROFILE_INFO_TAG
  ]
});

export const {
  useGetEditProfileInfoQuery,
  useLazyGetMyProfileInfoQuery,
  useValidateUserPasswordMutation,
  useUpdateUserProfileMutation,
  useUpdateUserAvatarMutation,
  useUserSendVerificationEmailAuthMutation,
  useUserSendVerificationEmailByAdminMutation,
  useUserVerifyByAdminMutation
} = rtk;