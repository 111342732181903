import {
  ALLOW_ALL
} from "../../../../../components/Organization/AccountMembers/Table/MemberPoliciesTable/forms/MemberPoliciesForm/components/sections/CommonSections/constants";

const campaignsValidator = ({ lookup, policySettings }) => {
  let hasPermission = false;
  const campaign = lookup?.campaign;

  for (const policySetting of policySettings) {
    const campaigns = policySetting.campaigns ?? [];

    if (campaigns === ALLOW_ALL || campaigns === []) {
      hasPermission = true;
      break;
    }

    if (campaigns.includes(campaign) || campaigns.includes(Number(campaign))) {
      hasPermission = true;
      break;
    }
  }
  return hasPermission;
};

export default campaignsValidator;