import { cookieConsentKey, cookieConsentVersion } from "@/shared/constants/app";

export class CookieConsentService {
  doAccept(date, version) {
    localStorage.setItem(cookieConsentKey, JSON.stringify({
      isAccepted: true,
      version: version || cookieConsentVersion,
      date: date || new Date()
    }));
  }

  doReject(date, version) {
    localStorage.setItem(cookieConsentKey, JSON.stringify({
      isAccepted: false,
      version: version || cookieConsentVersion,
      date: date || new Date()
    }));
  }

  getConsentData() {
    const consent = localStorage.getItem(cookieConsentKey);
    let parsedConsent;

    try {
      parsedConsent = JSON.parse(consent);
    } catch (e) {
      //
    }
    return parsedConsent;
  }

  getIsActualVersion() {
    return (this.getConsentData()?.version === cookieConsentVersion);
  }

  getIsAccepted() {
    return this.getConsentData()?.isAccepted === true;
  }

  checkIfPageInBlackList(pageName) {
    return this.getBlackListPages().includes(pageName?.replaceAll("/", ""));
  }

  getBlackListPages() {
    // list of routes where banner should not be displayed
    // return [
    //   "privacy", "terms-of-use", "release-notes",
    //   "sign-in", "sign-up", "restore-password", "public-survey-email"
    // ];
    return ["public-survey-email"];
  }
}

export const cookieConsentService = new CookieConsentService();
