import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
// HELPERS
import NestedLinks from "@/components/SideNav/helpers/NestedLinks/NestedLinks";


const MembersNestedLinks = ({
                              props,
                              userPermissions
                            }) => {
  const match = useRouteMatch();

  const {
    canAccountMemberListView,
    canAccountInvitationListView,
    canAccountRoleListView,
    canPolicyListView,
    canCampaignParticipantAuditorAssignment
  } = userPermissions;

  const memberList = [
    {
      to: `${match.url}/members#members`,
      hash: "#members",
      onClick: props.closeNavCallBack,
      isVisible: canAccountMemberListView,
      label: "sidenav.membersList"
    },
    {
      to: `${match.url}/members#invitations`,
      hash: "#invitations",
      onClick: props.closeNavCallBack,
      isVisible: canAccountInvitationListView,
      label: "sidenav.membershipInvitations"
    },
    {
      to: `${match.url}/members#assignments`,
      hash: "#assignments",
      onClick: props.closeNavCallBack,
      isVisible: canCampaignParticipantAuditorAssignment,
      label: "accounts.tab.memberAssignments"
    },
    {
      to: `${match.url}/members#roles`,
      hash: "#roles",
      onClick: props.closeNavCallBack,
      isVisible: canAccountRoleListView,
      label: "sidenav.membershipRoles"
    },
    {
      to: `${match.url}/members#policies`,
      hash: "#policies",
      onClick: props.closeNavCallBack,
      isVisible: canPolicyListView,
      label: "sidenav.policies"
    },
  ];

  return (
    <Route path={`${match.path}/members`}>
      <NestedLinks links={memberList}/>
    </Route>
  );
};

export default MembersNestedLinks;