import React from "react";
import { Pie, PieChart, Legend, Cell } from "recharts";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import CardBox from "../CardBox/CardBox";
import ChartSvg from "../../Components/Chart";
import QuestionAnswer from "../../Components/QuestionAnswer";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";
import checkTotalValue from "../../../../../../utils/checkTotalValueForChart";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    padding: "3px",
    border: "1px solid #24B2B2",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  chartTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    minHeight: "14pt",
    paddingHorizontal: "8pt",
    backgroundColor: "#F5F5F5",
    marginBottom: "5pt"
  },
  chartTitleText: {
    fontWeight: "medium",
    fontSize: "8pt",
    paddingVertical: "5pt",
    color: "#333333",
  }
});

const COLORS = ["#255e91", "#4472c4", "#a5a5a5", "#5b9bd5", "#264478", "#636363"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='black' textAnchor={x > cx ? "start" : "end"} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

// Create Component
const Card2 = ({ data }) => {
  const heading = useIntlMessageToString("reports.card2Title");
  const budgetDistribution = useIntlMessageToString("reports.budgetDistribution");
  const chart = useIntlMessageToString("reports.chart");
  const revenueQuestion = data.find((question) => question.question_id === "1591353106185745") || {};
  const investmentsQuestion = data.find((question) => question.question_id === "1591353106185747") || {};
  const employeesNumberQuestion = data.find((question) => question.question_id === "1591353106185743") || {};

  const {
    value: investments = [],
  } = investmentsQuestion;

  revenueQuestion.title = useIntlMessageToString("reports.revenue");
  investmentsQuestion.title = useIntlMessageToString("reports.investments");
  employeesNumberQuestion.title = useIntlMessageToString("reports.employeesNumber");

  return (
    <CardBox heading={heading}>
      <View style={styles.cardBody}>
        <QuestionAnswer
          question={revenueQuestion}
        />
        <QuestionAnswer
          question={employeesNumberQuestion}
        />
        <QuestionAnswer
          question={investmentsQuestion}
        />
        <View style={styles.chartTitle}>
          <Text style={styles.chartTitleText}>{budgetDistribution} </Text>
          <Text style={styles.chartTitleText}>{chart}:</Text>
        </View>
        <View>
          <ChartSvg>
            <PieChart width={500} height={200}>
              <Pie
                dataKey='value'
                nameKey='title'
                data={checkTotalValue(investments)}
                cx={"50%"}
                innerRadius={40}
                outerRadius={80}
                labelLine={false}
                label={renderCustomizedLabel}
                isAnimationActive={false}
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={COLORS[index]}/>
                ))}
              </Pie>
              <Legend
                align="right"
                verticalAlign="middle"
                layout="vertical"
              />
            </PieChart>
          </ChartSvg>
        </View>
      </View>
    </CardBox>
  );
};

export default Card2;
