import classnames from "classnames";
// STYLES
import styles from "../styles.module.scss";


const useClasses = ({ classes }) => {
  const inputRootStyles = classnames({
    [styles.AsrMuiInputRoot]: true,
    [classes?.inputRoot]: Boolean(classes?.inputRoot)
  });

  const inputFocusedStyles = classnames({
    [styles.AsrMuiInputFocused]: true,
    [classes?.inputFocused]: Boolean(classes?.inputFocused)
  });

  const inputUnderlineStyles = classnames({
    [styles.AsrMuiInputUnderline]: true,
    [classes?.inputUnderline]: Boolean(classes?.inputUnderline)
  });

  const labelRootStyles = classnames({
    [styles.AsrMuiInputLabel]: true,
    [classes?.labelRoot]: Boolean(classes?.labelRoot)
  });


  return {
    inputRoot: inputRootStyles,
    inputFocused: inputFocusedStyles,
    inputUnderline: inputUnderlineStyles,
    labelRoot: labelRootStyles,
  };
};

export default useClasses;
