export const commonSectionConstants = {
  ALL: "all",
  OWNED: "owned",
  IDS: "ids",
  CAMPAIGNS: "campaigns",
  EMAIL: "email",
  ASSESSMENTS :"assessments",
  KEYS :"keys",
  COMPLEX: "complex",
  COMPLEX_ALL: "complex_all",
};

export const ALLOW_ALL = "*";

export const buttonType = {
  BOOLEAN: "boolean",
  FILTER: "filter",
  COMPLEX: "complex",
};


export const sectionName = {
  Account: "Account",
  AccountInvitation: "AccountInvitation",
  AccountMember: "AccountMember",
  AccountRole: "AccountRole",
  AccountUser: "AccountUser",
  Assessment: "Assessment",
  AssessmentAct: "AssessmentAct",
  Auditor: "Auditor",
  Campaign: "Campaign",
  CampaignInvitation: "CampaignInvitation",
  CampaignParticipant: "CampaignParticipant",
  CustomField: "CustomField",
  Label: "Label",
  LabelGroup: "LabelGroup",
  Notification: "Notification",
  Page: "Page",
  Policy: "Policy",
  PowerBIDataset: "PowerBIDataset",
  PowerBIReport: "PowerBIReport",
  RegistrationAct: "RegistrationAct",
  RegistrationForm: "RegistrationForm",
  Statement: "Statement",
  StatementSet: "StatementSet",
  SurveyTemplate: "SurveyTemplate"
};