export const REG_FORM_TAGS = {
  FORMS_LIST: "list-of-reg-forms",
  FORM_MANAGEMENT: "registration-form-management",
};

export const REG_SUBMISSIONS_TAGS = {
  SUBMISSIONS_LIST: "list-of-reg-submissions",
  SUBMISSION_MANAGEMENT_INFO: "submission-management-info",
};

export const TARGETS = {
  SHARED_ITEMS: "shared-items",
  BASE_CARDS: "cards-in-account",
  BASE_TABLE_ITEMS: "table-in-account",
  SUBMISSION_MINI_LOGS: "submission-mini-logs",
  COMMON_UPDATE: "common-update",
  PUBLIC_PAGE: "retrieve-for-public-page",
  IFRAME: "retrieve-for-iframe",
  CAMPAIGN_ENTER_FOR_EXISTING_PARTICIPANT: "retrieve-for-campaign-enter",
  GENERATE_IFRAME: "generate-iframe",
};


export const queryParams = {
  IDS: "ids",
  ACCOUNT_ID: "accountId",
  ACCOUNT_ID_SNAKE: "account_id",
  TARGET: "target",
  SEARCH: "search",
  PAGE: "page",
  PAGE_SIZE: "page_size",
  ORDER_BY: "order_by",
  LABEL_IDS: "label_ids",
  CAMPAIGN_ID: "campaign_id",
  CAMPAIGN_IDS: "campaign_ids",
  IDS_TO_TOP: "ids_to_top",
  EXCLUDE_IDS: "exclude_ids",
  REGISTRATION_FORM_IDS: "registration_form_ids",
  HASHTAG_IDS: "hashtag_ids",
  ITEM_STATUSES: "item_statuses",
};