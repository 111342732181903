import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const campaign = {
  [actionsConstants.CampaignDashboardView]: allOwnedIdsValidator,
  [actionsConstants.CampaignListView]: allOwnedIdsValidator,
  [actionsConstants.CampaignCreate]: simpleValidator,
  [actionsConstants.CampaignModify]: allOwnedIdsValidator,
  [actionsConstants.CampaignDelete]: allOwnedIdsValidator,
  // [actionsConstants.CampaignDownloadReportCSV]: allOwnedIdsValidator,
  // [actionsConstants.CampaignDownloadReportJSON]: allOwnedIdsValidator,
  [actionsConstants.CampaignDownloadReportXLSX]: allOwnedIdsValidator,
  [actionsConstants.CampaignViewLogs]: allOwnedIdsValidator,
  [actionsConstants.CampaignClone]: allOwnedIdsValidator,
};

export default campaign;