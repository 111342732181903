import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getIsSuperUser } from "@/store/User/selectors";

export const useCheckPermissions = (
  userPermissions = [],
  requiredPermissions = [],
  isAllRequired = true,
  isSuperUser = false
) => {
  const [isAvailable, setIsAvailable] = useState(null);
  const isSuperUserFromStore = useSelector(getIsSuperUser);

  useEffect(() => {
    const checkerEvery = (arr, target) => target.every(v => arr.includes(v));
    const checkerSome = (arr, target) => target.some(v => arr.includes(v));
    if (isSuperUserFromStore) {
      setIsAvailable(true);
      return;
    } else if (isSuperUser && !isSuperUserFromStore) {
      setIsAvailable(false);
      return;
    }

    if (!userPermissions.length || !requiredPermissions.length) {
      setIsAvailable(false);
      return;
    }

    if (isAllRequired) {
      setIsAvailable(checkerEvery(userPermissions, requiredPermissions));
    } else {
      setIsAvailable(checkerSome(userPermissions, requiredPermissions));
    }
  }, [userPermissions, requiredPermissions, isAllRequired, isSuperUserFromStore, isSuperUser]);

  return isAvailable;
};
