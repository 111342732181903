import React  from "react";

const AsrDropdownIcon = ({
                                handler,
                                classes = {
                                  root: ""
                                }
                              }) => {
  return (
      <div onClick={handler} className={`${classes.root}`}>
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 10.0001L18 6.10352e-05H0L9 10.0001Z" fill="#333333"/>
        </svg>
      </div>
  );
};

AsrDropdownIcon.displayName = "AsrDropdownIcon";
export default AsrDropdownIcon;