import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pagination: {
    page_size: 8,
    page: 1
  },

};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccountsPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload
      };
    },
    setInitialAccountsPagination: (state, action) => {
      state.pagination = {
        ...initialState.pagination
      };
    }
  },
});

export const {
  setAccountsPagination,
  setInitialAccountsPagination,
} = accountsSlice.actions;

export default accountsSlice.reducer;