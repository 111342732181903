import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const statement = {
  [actionsConstants.StatementListView]: simpleValidator,
  [actionsConstants.StatementCreate]: simpleValidator,
  [actionsConstants.StatementModify]: allOwnedIdsValidator,
  [actionsConstants.StatementLockUnlock]: allOwnedIdsValidator,
  [actionsConstants.StatementDelete]: allOwnedIdsValidator,
};


export default statement;