import React from "react";
import PropTypes from "prop-types";
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell, Legend } from "recharts";
import CardBox from "./CardBox";
import IntlMessages from "../../../../utils/IntlMessages";
import checkTotalValue from "../../../../utils/checkTotalValueForChart";
import QuestionNoteAndStatus from "../NoteAndStatus/NoteAndStatus";

const COLORS = ["#255e91", "#4472c4", "#a5a5a5", "#5b9bd5", "#264478", "#636363"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Card3 = React.forwardRef(function Card3({ data, exportImage, keyForSavingCardState }, ref) {
  const contractTypeDistribution = data.find((question) => question.question_id === "159135310618575") || {};
  const clientTypeDistribution = data.find((question) => question.question_id === "1591353106185752") || {};

  const {
    value: contracts = [],
    note: noteContractTypeDistribution,
    status: statusContractTypeDistribution
  } = contractTypeDistribution;
  const {
    value: clients = [],
    note: noteClientTypeDistribution,
    status: statusClientTypeDistribution
  } = clientTypeDistribution;

  return (
    <CardBox
      heading={<IntlMessages id="reports.card3Title"/>}
      exportImage={exportImage}
      ref={ref}
      keyForSavingCardState={keyForSavingCardState}
    >
      <div className="report-card__body__container">
        <div className="card3-content-container">
          <div className="contract-type">
            <div className="report-card__list__item card3-list__item">
              <div className="question__wrapper card3-question__wrapper">
                <div className="question"><IntlMessages id="reports.projectsByContractType"/>:</div>
              </div>
              <QuestionNoteAndStatus
                status={statusContractTypeDistribution}
                note={noteContractTypeDistribution}
                question={contractTypeDistribution}
              />
            </div>
            <ResponsiveContainer height={500}>
              <PieChart>
                <Pie
                  dataKey="value"
                  nameKey="title"
                  data={checkTotalValue(contracts)}
                  innerRadius={60}
                  outerRadius={120}
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {contracts?.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index] || "#5b9bd5"}/>
                  ))}
                </Pie>
                <Legend
                  align="center"
                  layout="vertical"
                  verticalAlign="bottom"
                />
                <Tooltip/>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="client-type">
            <div className="report-card__list__item card3-list__item">
              <div className="question__wrapper card3-question__wrapper">
                <div className="question"><IntlMessages id="reports.clientsType"/>:</div>
              </div>
              <QuestionNoteAndStatus
                status={statusClientTypeDistribution}
                note={noteClientTypeDistribution}
                question={clientTypeDistribution}
              />
            </div>
            <ResponsiveContainer height={500}>
              <PieChart>
                <Pie
                  dataKey="value"
                  nameKey="title"
                  data={checkTotalValue(clients)}
                  innerRadius={60}
                  outerRadius={120}
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {clients?.map((entry, index) => <Cell key={index} fill={COLORS[index]}/>)}
                </Pie>
                <Legend
                  align="center"
                  layout="vertical"
                  verticalAlign="bottom"
                />
                <Tooltip/>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </CardBox>
  );
});

Card3.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  exportImage: PropTypes.func.isRequired,
  keyForSavingCardState: PropTypes.string.isRequired,
};

export default Card3;
