import React, { memo } from "react";
import classnames from "classnames";
// COMPONENTS
import AsrLangIcon from "@/shared/UI/Icons/AsrLangIcon";
// UTILS
import { findSystemLangSettingByCode } from "@/utils/language";
// STYLES
import styles from "../../../../styles.module.scss";


const LanguageItem = memo(({
                             language,
                             switchLanguage,
                             handleRequestClose,
                             surveyCurrentLocale,
                             showLanguageIcon = true
                           }) => {
  const { nativeName, code } = language;
  const isSystemLang = Boolean(findSystemLangSettingByCode({ code: language.code }));

  const onClickHandler = () => {
    handleRequestClose && handleRequestClose();
    switchLanguage && switchLanguage(language);
  };

  const liStyles = classnames({
    [styles.Active]: code === surveyCurrentLocale,
    [styles.IsSystemLang]: isSystemLang,
    [styles.EmptyIconLang]: showLanguageIcon && !isSystemLang
  });

  return (
    <li onClick={onClickHandler} className={liStyles}>
      {showLanguageIcon && isSystemLang && <AsrLangIcon/>}

      <div className={styles.LanguageName}>
        {nativeName || code}
      </div>
    </li>
  );
});

export default LanguageItem;
