import { useEffect } from "react";

const useLogOut = ({
                     includeAdminToken = false,
                     reloadPage = true
                   }) => {
  useEffect(() => {
    localStorage.removeItem("userToken");
    if (includeAdminToken) {
      localStorage.removeItem("adminToken");
    }

    if (reloadPage) {
      window.location.reload();
    }
  }, [includeAdminToken, reloadPage]);
};

export default useLogOut;