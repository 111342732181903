import React, { useState } from "react";
import { Form, Formik } from "formik";
// COMPONENTS
import AsrInput from "@/components/Common/FormComponentsNew/AsrInput";
// HOOKS
import useInitialValues from "./hooks/useInitialValues";
import useValidationSchema from "./hooks/useValidationSchema";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";
import classnames from "classnames";
import AsrAutoCompleteWithCustomOptionsSingle
  from "@/shared/autocompletes/common/AsrAutoCompleteWithCustomOptionsSingle";


const MyProfileEditForm = ({
                             profileInfo,
                             formRef,
                             onSubmit
                           }) => {

  const initialValues = useInitialValues({ personalInfo: profileInfo?.personal_info });
  const validationSchema = useValidationSchema();

  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [repeatNewPasswordShow, setRepeatNewPasswordShow] = useState(false);

  const togglePasswordShow = (isPasswordVisible, setIsPasswordVisible) => () => setIsPasswordVisible(!isPasswordVisible);

  const getPasswordIconContainerStyles = (isPasswordVisible) => classnames({
    [styles.EyeIconContainer]: true,
    [styles.CloseEye]: isPasswordVisible
  });

  const getPasswordIconTooltipStyles = (isPasswordVisible) => classnames({
    [styles.EyeIconTooltip]: true,
    [styles.CloseEye]: isPasswordVisible
  });

  const onTitleChange = (option) => {
    if (!formRef) return;
    formRef.current.setFieldValue("titleName", option?.value || "");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {fmk => (
        <Form className={styles.EditFormContainer}>
          <div className={styles.FullNameFieldsContainer}>
            <AsrAutoCompleteWithCustomOptionsSingle
              name="titleName"
              options={[
                { title: "---", value: "" },
                { title: "Dr", value: "dr" },
                { title: "Mr", value: "mr" },
                { title: "Ms", value: "ms" }
              ]}
              value={formRef?.current?.values?.titleName || ""}
              onChange={onTitleChange}
              classes={{
                selectFieldRoot: styles.TitleSelectFieldRoot,
                inputRoot: styles.TitleFieldRoot,
                input: styles.TitleFieldInput,
                inputLabel: styles.InputLabel,
                popupIndicator: styles.PopupIndicator,
                endAdornment: styles.EndAdornment
              }}
              label={<IntlMessages id="appModule.Title"/>}
              labelColon={false}
            />

            <div className={styles.NameFieldsContainer}>
              <AsrInput
                label={<IntlMessages id="appModule.firstName"/>}
                labelColon={false}
                autoComplete="off"
                name="firstName"
                value={fmk.values.firstName || ""}
                type="string"
                classes={{
                  root: styles.NameFieldRoot,
                  input: styles.InputField,
                  label: styles.InputLabel
                }}
                required
              />

              <AsrInput
                label={<IntlMessages id="user.secondName"/>}
                labelColon={false}
                autoComplete="off"
                name="lastName"
                value={fmk.values.lastName || ""}
                type="string"
                classes={{
                  root: styles.NameFieldRoot,
                  input: styles.InputField,
                  label: styles.InputLabel
                }}
                required
              />
            </div>

          </div>

          <AsrInput
            label={
              <>
                <IntlMessages id="user.registeredEmail"/>
                <div className={styles.SubLabel}><IntlMessages id="appModule.changeRequiresVerification"/></div>
              </>
            }
            labelColon={false}
            autoComplete="off"
            name="email"
            value={fmk.values.email || ""}
            type="string"
            classes={{
              input: styles.InputField,
              label: styles.InputLabel
            }}
          />

          <div className={styles.NewPasswordFieldsContainer}>
            <AsrInput
              label={
                <>
                  <IntlMessages id="user.newPassword"/>
                  <div className={styles.SubLabel}><IntlMessages id="appModule.optional"/></div>
                </>
              }
              labelColon={false}
              autoComplete="new-password"
              name="newPassword"
              value={fmk.values.newPassword || ""}
              type={newPasswordShow ? "text" : "password"}
              classes={{
                input: `${styles.InputField} ${styles.Password}`,
                label: `${styles.InputLabel} ${styles.NewPasswordLabel}`,
                inputIconContainer: getPasswordIconContainerStyles(newPasswordShow),
                inputIconTooltip: getPasswordIconTooltipStyles(newPasswordShow)
              }}
              inputIcon={{
                iconName: newPasswordShow ? "eye_slashed" : "eye",
                position: "right",
                onClick: togglePasswordShow(newPasswordShow, setNewPasswordShow),
                tooltip: newPasswordShow ? "appCommon.hide" : "appCommon.show"
              }}
            />

            <AsrInput
              label={<IntlMessages id="user.confirmNewPassword"/>}
              labelColon={false}
              autoComplete="new-password"
              name="repeatNewPassword"
              value={fmk.values.repeatNewPassword || ""}
              type={repeatNewPasswordShow ? "text" : "password"}
              classes={{
                input: `${styles.InputField} ${styles.Password}`,
                label: styles.InputLabel,
                inputIconContainer: getPasswordIconContainerStyles(repeatNewPasswordShow),
                inputIconTooltip: getPasswordIconTooltipStyles(repeatNewPasswordShow)
              }}
              inputIcon={{
                iconName: repeatNewPasswordShow ? "eye_slashed" : "eye",
                position: "right",
                onClick: togglePasswordShow(repeatNewPasswordShow, setRepeatNewPasswordShow),
                tooltip: repeatNewPasswordShow ? "appCommon.hide" : "appCommon.show"
              }}
            />
          </div>

        </Form>
      )}
    </Formik>

  );
};

export default MyProfileEditForm;
