import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "../styles.module.scss";
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";

const EvolutionaryContentStage3 = () => {
  const modalToggleHandlers = useToggleCommonModalHandlers();

  const onContactUsClick = () => {
    modalToggleHandlers.contactUs({ show: true });
  };

  return (
    <>
      <div className={styles.Bold}>
        <IntlMessages id="auth.noMessageReceived"/>?
      </div>
      <div className={styles.ContactSupportText}>
        <IntlMessages
          id="auth.pleaseContactSupport"
          values={{
            button: value => (
              <span
                className={styles.Link}
                onClick={onContactUsClick}
              >
                {value}
              </span>
            )
          }}
        />
      </div>
    </>
  );
};

export default EvolutionaryContentStage3;
