import React from "react";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
// COMPONENTS
import TextField from "./components/TextField";
import AsrCheckBoxSmall from "@/shared/components/FormComponents/AsrCheckBoxSmall/AsrCheckBoxSmall";
// UTILS
import IntlMessages, { useIntlMessageToString } from "@/utils/IntlMessages";
import { validationSchema } from "./validationSchema";
// STORE
import { sendMessage } from "@/store/StaticPages/actions";
import { addSystemNotification } from "@/store/System/actions";
// CONSTANTS
import { MAX_MESSAGE_CHARS } from "./constants";
// STYLES
import styles from "../../styles.module.scss";


const ContactUsModalForm = ({ closeModal, isLoading, formRef, currentUser }) => {
  const dispatch = useDispatch();

  const successMessageText = useIntlMessageToString("contactUs.success");

  const onSubmitForm = async (values, { setErrors }) => {
    const { first_name, last_name, email, message } = values;
    try {
      await dispatch(sendMessage(first_name, last_name, email, message));
      await dispatch(addSystemNotification("", successMessageText, "success"));
      closeModal && closeModal();
    } catch (error) {
      const errorMessage = error.payload.response?.data?.details || error.payload.response?.data?.errors || error.payload.message;

      error.payload.response?.data?.errors && setErrors(error.payload.response.data.errors);
      await dispatch(addSystemNotification("", errorMessage, "error"));
    }
  };

  const initialValues = {
    first_name: currentUser?.content?.first_name || "",
    last_name: currentUser?.content?.last_name || "",
    email: currentUser?.content?.email || "",
    message: "",
    agreement: false
  };

  const generateMessageLabel = (value) => {
    return (
      <div className={styles.MessageLabel}>
        <IntlMessages
          id="appModule.yourMessageLessChars"
          values={{ count: MAX_MESSAGE_CHARS }}
        />
        <div className={styles.MessageLabelCounter}>
          {value ? value.length : 0}/{MAX_MESSAGE_CHARS}
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {fmk => (
        <Form className={styles.FormContainer}>
          {/* FIRST / LAST NAMES */}
          <div className={styles.NamesBlock}>
            <div>
              <TextField
                label={<IntlMessages id="appModule.firstName"/>}
                variant="standard"
                name={"first_name"}
                value={fmk.values.first_name}
                onChange={fmk.handleChange}
                type={"text"}
                fullWidth
              />
              {fmk.errors?.first_name && fmk.touched?.first_name && (
                <div className={`form__error-text ${styles.ErrorText}`}>{fmk.errors?.first_name}</div>
              )}
            </div>

            <div>
              <TextField
                label={<IntlMessages id="appModule.lastName"/>}
                variant="standard"
                type={"text"}
                name={"last_name"}
                value={fmk.values.last_name}
                onChange={fmk.handleChange}
                fullWidth
              />
              {fmk.errors?.last_name && fmk.touched?.last_name && (
                <div className={`form__error-text ${styles.ErrorText}`}>{fmk.errors?.last_name}</div>
              )}
            </div>
          </div>

          <div>
            <TextField
              label={<IntlMessages id="appModule.email"/>}
              variant="standard"
              type="email"
              name={"email"}
              value={fmk.values.email}
              onChange={fmk.handleChange}
              fullWidth
            />
            {fmk.errors?.email && fmk.touched?.email && (
              <div className={`form__error-text ${styles.ErrorText}`}>{fmk.errors?.email}</div>
            )}
          </div>

          <div>
            <TextField
              label={generateMessageLabel(fmk.values.message)}
              variant="standard"
              type={"text"}
              name={"message"}
              value={fmk.values.message}
              onChange={fmk.handleChange}
              classes={{
                root: styles.MessageRoot
              }}
              minRows={3}
              multiline={true}
              fullWidth
            />
            {fmk.errors?.message && fmk.touched?.message && (
              <div className={`form__error-text ${styles.ErrorText}`}>{fmk.errors?.message}</div>
            )}
          </div>

          {/* AGREEMENT */}
          <div className={styles.AgreementBlock}>
            <div>
              <div
                className={styles.CheckBoxBlock}
                onClick={() => fmk.setFieldValue("agreement", !fmk.values.agreement)}
              >
                <AsrCheckBoxSmall
                  checked={fmk.values.agreement}
                />
                <IntlMessages id="appModule.contactUsAgreementV2"/>
              </div>
              {fmk.errors?.agreement && fmk.touched?.agreement && (
                <div className={`form__error-text ${styles.ErrorText}`}>{fmk.errors?.agreement}</div>
              )}
            </div>

            {!currentUser && (
              <button
                className={styles.SendItButton}
                type="submit"
                disabled={isLoading}
              >
                <IntlMessages
                  id="appModule.sendItStyled"
                  values={{
                    text1: value => <div className={styles.Dark}>{value}</div>,
                    text2: value => <div className={styles.Light}>{value}</div>
                  }}
                />
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactUsModalForm;