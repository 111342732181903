import React, {useState, useEffect, memo} from "react";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {Form, Formik} from "formik";
import queryString from "query-string";
import PropTypes from "prop-types";
import * as Yup from "yup";


import Logo from "@/assets/images/logo-assessor.svg";
import IntlMessages from "@/utils/IntlMessages";
import {ButtonForm, PublicSurveyInput} from "@/components/Landing";
import {switchLanguage} from "@/store/Settings/actions";
import ChooseLanguage from "@/components/Common/ChooseLanguage";
import languageData from "@/components/LanguageSwitcher/data";
// STYLES
import "../Campaign/styles.scss";
import "./styles.scss";


const Schema = Yup.object().shape({
  email: Yup.string()
    .required(<IntlMessages id='appModule.emailRequired'/>)
    .email(<IntlMessages id='appModule.emailInvalid'/>),
});

const PublicSurveyEmail = memo(({locale, setEmail, setCurrentPage}) => {
  const [localeFromUrl, setLocaleFromUrl] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const locale = queryString.parse(location.search).locale;
    setLocaleFromUrl(locale);
  }, []);

  useEffect(() => {
    if (localeFromUrl && localeFromUrl !== locale.locale) {
      const language = languageData.find((item) => item.locale === localeFromUrl);
      localStorage.setItem("locale", localeFromUrl);
      switchLanguage(language);
    }
  }, [localeFromUrl]);

  const handleSubmitEmail = async ({email}) => {
    setEmail(email);
    setCurrentPage("survey-questions");
  };

  return (
    <>
      <div className='landing-page__container'>
        <div className="auth-page__registered-user">
          <ChooseLanguage/>
        </div>
        <div className='landing-page__content'>
          <img src={Logo} alt={"logo"} className='landing-page__logo'/>
          <div className='landing-page__label'>
            <span>assessor.</span><span>io</span>
          </div>
          <Formik
            initialValues={{email: ""}}
            onSubmit={handleSubmitEmail}
            validationSchema={Schema}
          >
            {({isSubmitting}) => (
              <Form className='landing-page__form'>
                <PublicSurveyInput
                  name='email'
                  type='text'
                  placeholder='Email'
                />
                <ButtonForm
                  type='submit'
                  isSubmitting={isSubmitting}
                >
                    <span className='public-survey__button-start'>
                        <IntlMessages id="appModule.startHere1"/>
                        <span className='here'> <IntlMessages id="appModule.startHere2"/></span>
                    </span>
                </ButtonForm>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
});

PublicSurveyEmail.propTypes = {
  locale: PropTypes.object.isRequired,
  switchLanguage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.settings.locale,
});

const mapDispatchToProps = {
  switchLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicSurveyEmail);