import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Pie, PieChart, ResponsiveContainer, Legend, Cell, Tooltip} from "recharts";
import CardBox from "./CardBox";
import IntlMessages, {useIntlMessageToString} from "../../../../utils/IntlMessages";
import checkTotalValue from "../../../../utils/checkTotalValueForChart";
import QuestionAnswer from "./QuestionAnswer";

const COLORS = ["#255e91", "#4472c4", "#a5a5a5", "#5b9bd5", "#264478", "#636363"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='black' textAnchor={x > cx ? "start" : "end"} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Card2 = React.forwardRef(function Card2({data, exportImage, windowWidth, keyForSavingCardState}, ref) {
  const revenueQuestion = data.find((question) => question.question_id === "1591353106185745") || {};
  const investmentsQuestion = data.find((question) => question.question_id === "1591353106185747") || {};
  const employeesNumberQuestion = data.find((question) => question.question_id === "1591353106185743") || {};

  const {
    value: investments = [],
  } = investmentsQuestion;

  revenueQuestion.title = useIntlMessageToString("reports.revenue");
  investmentsQuestion.title = useIntlMessageToString("reports.investments");
  employeesNumberQuestion.title = useIntlMessageToString("reports.employeesNumber");

  const isLargeWindow = windowWidth >= 1100;

  return (
    <CardBox
      heading={<IntlMessages id='reports.card2Title'/>}
      exportImage={exportImage}
      ref={ref}
      keyForSavingCardState={keyForSavingCardState}
    >
      <div className='report-card__body__container'>
        <QuestionAnswer
          question={revenueQuestion}
        />
        <QuestionAnswer
          question={employeesNumberQuestion}
        />
        <QuestionAnswer
          question={investmentsQuestion}
        />
        <div className='bar-chart__title'>
          <IntlMessages id='reports.budgetDistribution'/> <IntlMessages id='reports.chart'/>:
        </div>
        <ResponsiveContainer width='99%' height={isLargeWindow ? 300 : 450}>
          <PieChart>
            <Pie
              dataKey='value'
              nameKey='title'
              data={checkTotalValue(investments)}
              cx={isLargeWindow ? "70%" : "50%"}
              innerRadius={60}
              outerRadius={120}
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {data?.map((entry, index) => (
                <Cell key={index} fill={COLORS[index]}/>
              ))}
            </Pie>
            <Legend
              align={isLargeWindow ? "right" : "center"}
              layout='vertical'
              verticalAlign={isLargeWindow ? "middle" : "bottom"}
            />
            <Tooltip/>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </CardBox>
  );
});

Card2.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  exportImage: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  keyForSavingCardState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  windowWidth: state.settings.windowWidth,
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(Card2);
