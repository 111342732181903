import React, { useEffect, useMemo, useState } from "react";
import { useField, Field } from "formik";
import classnames from "classnames";
import PropTypes from "prop-types";
// MATERIAL UI
import Tooltip from "@mui/material/Tooltip";
// COMPONENTS
import AsrSvgIcon from "@/shared/components/AsrSvgIcon";
// STYLES
import "../styles.scss";
import styles from "./styles.module.scss";


const AsrInput = ({
                    label,
                    labelColon = true,
                    inputClassName,// deprecated
                    errorClassName,// deprecated
                    errors,
                    ignoreErrors = false,
                    tooltipErrorPlacement = "top-start",
                    classes = {
                      root: "",
                      input: "",
                      label: "",
                      error: "",
                      inputError: "",
                      tooltipError: "",
                      inputIconContainer: "",
                      inputIconTooltip: ""
                    },
                    tooltipPopperProps,
                    tooltipError = false,
                    isLabel = true, // need to be removed. Show label if label has been passed
                    errorScrollable = true,
                    inputIcon,
                    ...props
                  }) => {
  const [field, meta] = useField(props);

  const [errorTooltip, setErrorTooltip] = useState(false);
  const inputHasIcon = Boolean(inputIcon && inputIcon.iconName);

  const rootName = classnames({
    ["styled-form-input"]: true,
    [classes.root]: classes.root
  });

  const fieldClass = classnames({
    "styled-form-input__input": true,
    "styled-form-input__input--error": meta.touched && meta.error,
    [styles.Input]: true,
    [styles.InputWithIcon]: inputHasIcon,
    [classes.input]: classes.input,
    [classes.inputError]: classes.inputError,
    [inputClassName]: inputClassName,
  });

  const labelClass = classnames({
    "styled-form-input__label": true,
    [classes.label]: classes.label
  });

  const errorClass = classnames({
    "styled-form-input__error": true,
    [classes?.error]: Boolean(classes?.error),
    [errorClassName]: !!errorClassName,
    [styles.errorScrollable]: errorScrollable
  });

  const tooltipErrorClass = classnames({
    [styles.tooltipErrorTooltip]: true,
    [classes.tooltipError]: classes.tooltipError
  });

  const inputIconContainerClass = classnames({
    [styles.InputIconContainer]: true,
    [styles.Clickable]: Boolean(inputIcon?.onClick),
    [classes?.inputIconContainer]: Boolean(classes?.inputIconContainer)
  });

  useEffect(() => {
    if (tooltipError && meta.touched && meta.error) {
      setErrorTooltip(true);
    } else {
      setErrorTooltip(false);
    }
  }, [meta.error, meta.touched, tooltipError]);

  const checkError = (error) => {
    if (React.isValidElement(error)) {
      return error;
    }

    if (typeof error === "string") {
      return error;
    } else if (typeof error === "object") {
      return Object.values(error)?.[0];
    }
  };

  const tooltipErrorTitle = () => {
    const metaError = meta.error;
    if (!errorTooltip) return "";
    return checkError(metaError) || "";
  };

  const ErrorMsg = useMemo(() => {
    const metaError = meta.error;
    return checkError(metaError);
  }, [meta.error]);


  return (
    <Tooltip
      title={tooltipErrorTitle()}
      open={errorTooltip}
      arrow
      placement={tooltipErrorPlacement}
      PopperProps={{
        ...tooltipPopperProps
      }}
      TransitionProps={{ exit: false }}
      classes={{
        popper: styles.tooltipErrorPopper,
        tooltip: tooltipErrorClass,
        arrow: styles.tooltipErrorArrow,
        tooltipPlacementTop: styles.tooltipPlacementTop
      }}
    >
      <div className={rootName}>
        {isLabel && label && (
          <label className={labelClass} htmlFor={props.id || props.name}>
            {label}
            {props?.required && "*"}
            {labelColon ? ":" : null}
          </label>
        )}
        <div style={{ position: "relative" }}>
          <Field
            {...field}
            {...props}
            className={fieldClass}
          />

          {inputHasIcon && (
            <AsrSvgIcon
              icon={inputIcon?.iconName}
              handler={inputIcon?.onClick}
              tooltipTitle={inputIcon?.tooltip}
              classes={{
                asrSvgIconContainer: inputIconContainerClass,
                asrSvgTooltip: classes?.inputIconTooltip
              }}
            />
          )}
        </div>
        {(meta.touched && meta.error && !tooltipError) && !ignoreErrors && (
          <div className={errorClass}>
            {ErrorMsg}
          </div>
        )}
        {(errors && !tooltipError) && !ignoreErrors && (
          <div className={errorClass}>
            {errors}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

AsrInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelColon: PropTypes.bool,
  errors: PropTypes.object,
  ignoreErrors: PropTypes.bool,
  tooltipErrorPlacement: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    inputError: PropTypes.string,
    tooltipError: PropTypes.string,
    inputIconContainer: PropTypes.string,
    inputIconTooltip: PropTypes.string
  }),
  tooltipPopperProps: PropTypes.object,
  tooltipError: PropTypes.bool,
  isLabel: PropTypes.bool,
  errorScrollable: PropTypes.bool,
  inputIcon: PropTypes.shape({
    iconName: PropTypes.string,
    onClick: PropTypes.func,
    tooltip: PropTypes.string
  })
};
export default AsrInput;