import { success } from "redux-saga-requests";
import * as types from "./types";

const initialState = {
  id: null,
  body: null,
  key: null,
  organization: {
    name: null
  },
  settings: {
    enterPage: false,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.FETCH_PUBLIC_SURVEY_EXTENDED_INFO_BY_KEY): {
      const {
        response: { results: { relations, id, settings, meta } }
      } = action.payload;

      return {
        ...state,
        id,
        body: relations.survey.body,
        key: meta.key,
        organization: {
          ...state.organization,
          name: relations.organization.content.name
        },
        settings: {
          ...state.settings,
          enterPage: settings?.flow?.enter_page || false,
          colorScheme: relations.organization.settings.color_scheme
        }
      };
    }

    default:
      return state;
  }
};
