import React, { useEffect, useRef } from "react";


const FileField = ({ imageFile, setImageFile, inputFileId }) => {

  const inputRef = useRef();
  useEffect(() => {
    if (!imageFile) {
      inputRef.current.value = "";
    }
  }, [imageFile]);

  const handleSetFile = (e) => {
    const file = e.currentTarget.files[0];
    setImageFile(file);
  };

  return (
    <input
      accept="image/*"
      style={{ display: "none" }}
      id={inputFileId}
      type="file"
      ref={inputRef}
      onChange={handleSetFile}
    />
  );
};

export default FileField;