import { createSelector } from "reselect";
import {
  CREATE_ORGANIZATION_SURVEY,
  DOWNLOAD_ORGANIZATION_SURVEY,
  FETCH_ASSESSMENT_ITEMS,
  FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS,
  FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FOR_AUTOCOMPLETE,
  FETCH_ASSESSMENT_NOTE_HASHTAGS_FOR_AUTOCOMPLETE,
  FETCH_ASSESSMENT_QUESTIONS_FOR_AUTOCOMPLETE,
  FETCH_AUDITORS_FOR_AUTOCOMPLETE,
  FETCH_CAMPAIGNS_FOR_AUTOCOMPLETE,
  FETCH_ORGANIZATION_ASSESSMENTS_FOR_AUTOCOMPLETE,
  FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR,
  FETCH_ORGANIZATION_EXTENDED_INFO,
  FETCH_ORGANIZATION_POTENTIAL_MEMBERS_LIST,
  FETCH_ORGANIZATION_REGISTRATION_FEED_LIST,
  FETCH_ORGANIZATION_REGISTRATION_FOR_AUTOCOMPLETE,
  FETCH_ORGANIZATION_REGISTRATION_ITEM_MANAGE,
  FETCH_ORGANIZATION_REGISTRATION_LIST,
  FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST,
  FETCH_ORGANIZATIONS_LIST,
  FETCH_USER_LIST_FOR_AUTOCOMPLETE,
  GET_ORGANIZATION_SURVEY_FOR_MANAGING,
  ORGANIZATION_REGISTRATION_GENERATE_PUBLISH_JSON,
  UPDATE_ORGANIZATION_SURVEY
} from "./types";
import defaultColorSchema from "../../shared/data/defaultColorSchema";

export const organizationsState = (state) => state.organization;
export const networkState = (state) => state.network;

export const getOrganizationInfo = createSelector(organizationsState, (state) => state.selectedItem);
export const getOrganizationCampaigns = createSelector(organizationsState, (state) => state.selectedItem.all_campaigns);
export const getUserPermissions = createSelector(organizationsState, (state) => state.selectedItem?.requester_membership?.role?.permissions);
export const getOrganizationMemberMe = createSelector(organizationsState, (state) => state.account?.currentUser);
export const getOrganizationID = createSelector(organizationsState, (state) => (
  state.selectedItem?.id
  || state.account.generalInfo?.id
));
export const getColorScheme = (state) => (
  state.organization?.account?.generalInfo?.settings?.color_schema
  || state.campaign?.info?.organization?.settings?.color_schema
  || defaultColorSchema
);
export const getOrganizationSlugName = createSelector(organizationsState, (state) => state.selectedItem?.slug_name);
export const getOrganizationAssessorAssessments = createSelector(organizationsState, (state) => state.selectedItem?.assessor?.assignments);
export const getOrganizationAssessorNotes = createSelector(organizationsState, (state) => state.selectedItem?.assessor?.notes);
export const getOrganizationRegistrations = createSelector(organizationsState, (state) => state.selectedItem?.registrations);
export const getOrganizationRegistrationsViewType = createSelector(organizationsState, (state) => state.selectedItem?.registrations?.pageViewType);
export const getOrganizationRegistrationsDefaultTable = createSelector(organizationsState, (state) => state.selectedItem?.registrations?.tables?.defaultTable);
export const getOrganizationRegistrationsConfirmations = createSelector(organizationsState, (state) => state.selectedItem?.registrations?.confirmations);
export const getOrganizationRegistrationItem = createSelector(organizationsState, (state) => state.selectedItem?.registrations?.selectedItem);
export const getOrganizationRegistrationSurvey = createSelector(organizationsState, (state) => state.selectedItem?.registrations?.survey);
export const getOrganizationRegistrationCampaign = createSelector(organizationsState, (state) => state.selectedItem?.campaigns?.list);
export const getOrganizationRegistrationFlows = createSelector(organizationsState, (state) => state.selectedItem?.registrations?.flows);
/*  ======= ACCOUNT  =============*/
export const getCampaignMemberExtendedInfoRelatedToAssessor = createSelector(organizationsState, (state) => state?.selectedItem?.members?.selectedItem);

/*  =======  Survey Templates  =============*/
export const getSurveyTemplateSelectedItem = createSelector(organizationsState, (state) => state?.selectedItem?.surveyTemplates?.selectedItem);

// LOADING
export const getOrganizationAssessmentsLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ASSESSMENT_ITEMS]?.pending));
export const getOrganizationAssessmentsItemsCustomFieldsLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS]?.pending));
export const getOrganizationAssessmentsItemsCustomFieldsForAutocompleteLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FOR_AUTOCOMPLETE]?.pending));
export const getOrganizationAssessmentsForAutocompleteLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_ASSESSMENTS_FOR_AUTOCOMPLETE]?.pending));
export const getOrganizationExtendedInfoLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_EXTENDED_INFO]?.pending));
export const getOrgAttachedMemberForAssessorLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR]?.pending));
export const getOrgListLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATIONS_LIST]?.pending));
export const getOrgSurveyTemplatesLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST]?.pending));
export const getAssessmentsNoteHashtagsLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ASSESSMENT_NOTE_HASHTAGS_FOR_AUTOCOMPLETE]?.pending));

export const getOrganizationUserListForAutocompleteLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_USER_LIST_FOR_AUTOCOMPLETE]?.pending));
export const getOrganizationRegistrationItemManageLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_REGISTRATION_ITEM_MANAGE]?.pending));
export const getOrganizationSurveyForManagingLoading = createSelector(networkState, (state) => Boolean(state?.queries[GET_ORGANIZATION_SURVEY_FOR_MANAGING]?.pending));
export const getOrganizationRegistrationPublishJsonLoading = createSelector(networkState, (state) => Boolean(state?.mutations[ORGANIZATION_REGISTRATION_GENERATE_PUBLISH_JSON]?.pending));
export const getOrganizationRegistrationListLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_REGISTRATION_LIST]?.pending));
export const getOrganizationRegistrationFeedListLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_REGISTRATION_FEED_LIST]?.pending));
export const getOrganizationRegistrationForAutocompleteLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_REGISTRATION_FOR_AUTOCOMPLETE]?.pending));
export const getOrganizationPotentialMembersLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ORGANIZATION_POTENTIAL_MEMBERS_LIST]?.pending));

export const getOrganizationAuditorsLoading = createSelector(networkState, (state) => Boolean(state?.mutations[FETCH_AUDITORS_FOR_AUTOCOMPLETE]?.pending));

export const getDownloadOrganizationSurveyLoading = createSelector(networkState, (state) => Boolean(state?.mutations[DOWNLOAD_ORGANIZATION_SURVEY]?.pending));
export const updateOrganizationSurveyLoading = createSelector(networkState, (state) => Boolean(state?.mutations[UPDATE_ORGANIZATION_SURVEY]?.pending));
export const createOrganizationSurveyLoading = createSelector(networkState, (state) => Boolean(state?.mutations[CREATE_ORGANIZATION_SURVEY]?.pending));

export const getAssessmentQuestionsForAutocompleteLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_ASSESSMENT_QUESTIONS_FOR_AUTOCOMPLETE]?.pending));
// CAMPAIGN
export const getCampaignsForAutocompleteLoading = createSelector(networkState, (state) => Boolean(state?.queries[FETCH_CAMPAIGNS_FOR_AUTOCOMPLETE]?.pending));
