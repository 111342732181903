import React from "react";
import Dialog from "@mui/material/Dialog";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    paper: {
        overflow: "hidden",
        boxShadow: "none",
        backgroundColor: "transparent",
    },
});

const CircularProgressCover = ({ open }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} classes={{ paper: classes.paper }}>
            <div className='loader'>
                <svg className='circular' viewBox='25 25 50 50'>
                    <circle className='path' cx='50' cy='50' r='15' fill='none' strokeWidth='2' strokeMiterlimit='10'/>
                </svg>
            </div>
        </Dialog>
    );
};

export default CircularProgressCover;