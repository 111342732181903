import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
// HOOKS
import useInitialValues from "./hooks/useInitialValues";
import useValidationSchema from "./hooks/useValidationSchema";
// COMPONENTS
import SignInFormHeader from "./components/SignInFormHeader";
import SignInFormFields from "./components/SignInFormFields";
import SignInFormFooter from "./components/SignInFormFooter";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
// STORE
import { assessorApi } from "@/store/services/rtk";
import { signInUserBasic } from "@/store/User/actions";
import { addSystemNotification } from "@/store/System/actions";
// UTILS
import { useIntlMessageToString } from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";


const SignInAuthModalContent = ({ closeModal, onSignInNotVerifiedCallback }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const somethingWentWrongText = useIntlMessageToString("appModule.somethingWentWrong");
  const invalidCredentialsText = useIntlMessageToString("appModule.invalidAuthCredential");
  const notVerifiedText = useIntlMessageToString("appModule.notVerified");

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    try {
      const requestBody = {
        email: values.email,
        password: values.password
      };

      const { payload: { response: { results: { token } } } } = await dispatch(signInUserBasic(requestBody));
      localStorage.setItem("userToken", token);
      // invalidate api cache
      dispatch(assessorApi.util.resetApiState());
      setIsLoading(false);
      history.push("/organizations");
      closeModal();
    } catch (error) {
      const status = error?.payload?.response?.status;
      if (status === 401) {
        if (error?.payload?.response?.data?.details?.user?.[0]?.code === "authentication_failed.user_is_not_verified") {
          dispatch(addSystemNotification("", notVerifiedText, "error"));
          onSignInNotVerifiedCallback(values);
        } else {
          dispatch(addSystemNotification("", invalidCredentialsText, "error"));
        }
      } else {
        dispatch(addSystemNotification("", somethingWentWrongText, "error"));
      }
      setIsLoading(false);
    }
  };

  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  return (
    <div className={styles.AuthModalContentContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {fmk => (
          <>
            <Form>
              <div className={styles.FormContent}>
                <SignInFormHeader closeModal={closeModal}/>
                <SignInFormFields fmk={fmk}/>
                <SignInFormFooter closeModal={closeModal}/>
              </div>
            </Form>

            {isLoading && (
              <AsrCommonLoader/>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default SignInAuthModalContent;
