import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_MEMBERS_INVITATIONS_SORT, accountMemberInvitationsModals } from "./constants";

const initialState = {
  pagination: {
    page_size: 10,
    page: 1
  },
  sortBy: {
    sort_by: ACCOUNT_MEMBERS_INVITATIONS_SORT
  },
  modals: {
    [accountMemberInvitationsModals.CREATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberInvitationsModals.UPDATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberInvitationsModals.DELETE]: {
      isVisible: false,
      data: {},
    },
  }
};

export const memberInvitationsSlice = createSlice({
  name: "accountMembersInvitations",
  initialState,
  reducers: {
    setAccountMembersInvitationsPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload
      };
    },
    setAccountMembersInvitationsSort: (state, action) => {
      state.sortBy = {
        ...action.payload
      };
    },
    toggleAccountMembersInvitationsModal: (state, action) => {
      state.modals[action.payload.target] = {
        ...state.modals?.[action.payload.target],
        isVisible: action.payload.isVisible,
        data: action.payload?.data || {}
      };
    },
  },
});


export const {
  setAccountMembersInvitationsPagination,
  setAccountMembersInvitationsSort,
  toggleAccountMembersInvitationsModal
} = memberInvitationsSlice.actions;

export default memberInvitationsSlice.reducer;