import React, { memo, useState } from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as Yup from "yup";
import * as moment from "moment";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import CheckboxWithTooltip from "@/components/Common/CheckboxWithTooltip";
import FroalaEditor from "@/components/Common/FroalaEditor";
import ConfirmDeleteModal from "@/shared/modals/ConfirmDeleteModal";
// STORE
import { getCurrentUser } from "@/store/User/selectors";
// STYLES
import "react-responsive-modal/styles.css";
import "@/components/SurveyJs/styles/stylesV2/index.scss";

const validationSchema = Yup.object().shape({
  text: Yup
    .string()
    .required(<IntlMessages id="appModule.required"/>)
});

const NoteModal = memo(({
                          show,
                          setShow,
                          questionForNote,
                          changeQuestionNote,
                          canRemoveAssessorNotes
                        }) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const closeModal = () => setShow(false);

  const {
    name = "",
    number = "",
    title,
    note,
    image = null,
    tooltip
  } = questionForNote;

  const initialValues = {
    text: note?.text || "",
    sharedWithUser: note?.config?.is_visible || false,
    includedInExport: note?.config?.include_in_export || false,
  };

  const handleSubmit = (values) => {
    const { text, sharedWithUser, includedInExport } = values;
    const newCurrentNote = {
      createdDt: Date.now(),
      created_by: {
        id: currentUser.id,
        fullname: currentUser?.content?.fullname,
        email: currentUser?.content?.email
      },
      text,
      "config": {
        "is_visible": sharedWithUser ?? false,
        "include_in_export": includedInExport ?? false
      },
    };

    try {
      changeQuestionNote({ currentNote: newCurrentNote, name, image, tooltip });
      closeModal();
    } catch (e) {
      // console.log(e)
    }
  };

  const deleteNote = () => {
    changeQuestionNote({ currentNote: null, name, image, tooltip });
    closeModal();
  };

  const showDeleteConfirm = () => {
    setShowModalConfirm(true);
  };

  return (
    <Dialog
      open={show}
      onClose={closeModal}
      classes={{
        paper: "note-modal"
      }}
    >
      <div className="title-wrapper">
        <h3 className="title">
          {note
            ? (
              <IntlMessages id="notes.itemNotes.singleModify"/>
            )
            : (
              <IntlMessages id="notes.itemNotes.singleAdd"/>
            )}
        </h3>
        <i className="zmdi zmdi-close close-button zmdi-hc-2x" onClick={closeModal}/>
      </div>

      <div className="question-title__wrapper">
        <div className="question-title">
          {number}{title}
        </div>
        {note && (
          <div className="note-edited__wrapper">
            <div className="note-edited">
              <div className="note-edited__title"><IntlMessages id="assessor.lastEditedBy"/></div>
              <div className="note-edited__user">{note?.meta?.created_by?.fullname}</div>
              <div className="note-edited__date">
                {note?.meta?.modified_dt
                  ? moment(note?.meta?.modified_dt).format("MMM D, YYYY H:mm")
                  : ""
                }
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="question-note__editor">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ dirty, isValid, values, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="editor-wrapper">
                <FroalaEditor
                  value={values.text}
                  onChange={(value) => setFieldValue("text", value)}
                />
              </div>
              <div className="checkboxes-container">
                <CheckboxWithTooltip
                  checked={values.sharedWithUser}
                  label={<IntlMessages id="assessor.sharedWithUser"/>}
                  tooltip={<IntlMessages id="assessor.userReports"/>}
                  onChange={e => {
                    setFieldValue("sharedWithUser", e.target.checked);
                  }}
                  name="sharedWithUser"
                />
                <CheckboxWithTooltip
                  checked={values.includedInExport}
                  label={<IntlMessages id="assessor.includedInExports"/>}
                  tooltip={<IntlMessages id="assessor.nonformattedText"/>}
                  onChange={e => {
                    setFieldValue("includedInExport", e.target.checked);
                  }}
                  name="includedInExport"
                />
              </div>
              <div className="submit-button__wrapper">
                {note && canRemoveAssessorNotes && (
                  <Button
                    classes={{
                      root: "button delete"
                    }}
                    onClick={showDeleteConfirm}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={!dirty || !isValid || isSubmitting}
                  classes={{
                    root: "button submit"
                  }}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {showModalConfirm && (
        <ConfirmDeleteModal
          show={showModalConfirm}
          setShow={setShowModalConfirm}
          title={<IntlMessages id="assessor.deleteNote"/>}
          text={<IntlMessages
            id="assessor.deleteNoteText"
            values={{
              cannotBeUndone: (
                <span style={{ fontWeight: "bold" }}>
                  <IntlMessages id="appModule.cannotBeUndoneSimple"/>
                </span>
              ),
            }}
          />}
          action={deleteNote}
        />
      )}
    </Dialog>
  );
});

NoteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  questionForNote: PropTypes.object.isRequired,
  changeQuestionNote: PropTypes.func.isRequired,
  canRemoveAssessorNotes: PropTypes.bool.isRequired
};

export default NoteModal;