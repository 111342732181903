import { success, error } from "redux-saga-requests";
import * as types from "./types";
// import response from './response.json'

const initialState = {
  currentUser: null,
  errors: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.REMOVE_ERRORS: {
      return {
        ...state,
        errors: {}
      };
    }

    case types.USER_LOGOUT: {
      return {
        ...state,
        currentUser: null
      };
    }

    case types.SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload
      };
    }

    case success(types.UPATE_USER_PROFILE): {
      return {
        ...state,
        currentUser: action.payload.response.results.user
      };
    }

    case success(types.REFRESH_USER_TOKEN): {
      localStorage.setItem("userToken", action.payload.response.results.token);
      return {
        ...state,
      };
    }

    case success(types.FETCH_TOKEN_BY_EMAIL): {
      localStorage.setItem("adminToken", localStorage.getItem("userToken"));
      localStorage.setItem("userToken", action.payload.response.results.token);
      return {
        ...state,
      };
    }

    case error(types.FETCH_TOKEN_BY_EMAIL):
    case error(types.UPATE_USER_PROFILE):
    case error(types.EMAIL_VERIFYING):
    case error(types.VERIFY_RESTORE_PASSWORD_TOKEN):
    case error(types.RESTORE_PASSWORD_BY_TOKEN):
    case error(types.RESET_PASSWORD_REQUEST):
    case error(types.SIGN_IN_USER_BASIC):
    case error(types.SIGN_UP_USER_BASIC): {
      return {
        ...state,
        errors: action.payload.response.data.errors,
      };
    }

    case error(types.REFRESH_USER_TOKEN): {
      return {
        ...state,
        currentUser: null,
        errors: action.payload.response.data.errors,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
