import { assessorApi } from "@/store/services/rtk";
// CONSTANTS
import {
  CAMPAIGN_TAG,
  CAMPAIGNS_AUTOCOMPLETE_TAG,
  ASSIGNED_CAMPAIGN_CARDS_TARGET,
  PARTICIPATION_CAMPAIGN_CARDS_TARGET,
  CAMPAIGNS_CREATE_TARGET,
  CAMPAIGNS_DASHBOARD_TAG,
  CAMPAIGNS_DELETE_TARGET,
  CAMPAIGNS_TAG,
  CAMPAIGNS_UPDATE_TARGET,
  CAMPAIGNS_CLOSE_TARGET,
  CAMPAIGNS_GO_TO_LIVE_TARGET,
  campaignsQueryArg,
  CAMPAIGN_PARTICIPANT_INFO_TAG,
  ACCOUNT_PUBLIC_CAMPAIGN_CARDS_TARGET,
  AUDITOR_ACTIONS_STATS_TARGET,
  JOIN_PUBLIC_CAMPAIGN_TARGET,
  PUBLIC_CAMPAIGN_INFO,
} from "./constants";
import { assessorApiTargets } from "../config/assessorApiConstants";
import { HTTPMethods } from "../config/constants";
// HELPERS
import transformPayloadToQuery from "../utils/transformPayloadToQuery";
import transformPayloadToFormData from "@/shared/helpers/forms/transformPayloadToFormData";
import { setCampaignBaseInfo, setCampaignParticipantMe } from "@/store/Campaign/actions";


const rtk = assessorApi.injectEndpoints({
  endpoints: (build) => ({
    // GET CAMPAIGNS
    getAssignedCampaignsToUser: build.query({
      query: ({ account_id, ...payload } = {}) => {
        const defaultQuery = {
          target: ASSIGNED_CAMPAIGN_CARDS_TARGET,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${account_id}/campaigns/${query}` };
      },
      providesTags: [CAMPAIGNS_TAG]
    }),
    getParticipationCampaigns: build.query({
      query: ({ account_id, ...payload } = {}) => {
        const defaultQuery = {
          target: PARTICIPATION_CAMPAIGN_CARDS_TARGET,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${account_id}/campaigns/${query}` };
      },
      providesTags: [CAMPAIGNS_TAG]
    }),

    getAccountPublicAvailableCampaigns: build.query({
      query: ({ account_id, ...payload } = {}) => {
        const defaultQuery = {
          target: ACCOUNT_PUBLIC_CAMPAIGN_CARDS_TARGET,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${account_id}/campaigns/${query}` };
      },
      providesTags: [CAMPAIGNS_TAG]
    }),

    // GET CAMPAIGN Participant
    getCampaignParticipant: build.query({
      query: ({ campaign_id, ...payload } = {}) => {
        const defaultQuery = {
          target: "participants-me",
        };
        const query = transformPayloadToQuery({ payload, defaultQuery });
        return { url: `/campaigns/${campaign_id}/participants/me/${query}` };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setCampaignParticipantMe(data));
      },
      providesTags: [CAMPAIGN_PARTICIPANT_INFO_TAG]
    }),

    // GET CAMPAIGN DASHBOARD INFO
    getCampaignDashboardInfo: build.query({
      query(campaign_id) {
        return { url: `/campaigns/${campaign_id}/?target=dashboard-info` };
      },
      providesTags: [CAMPAIGNS_DASHBOARD_TAG]
    }),

    // GET CAMPAIGN BASE INFO
    getCampaignBaseInfo: build.query({
      query: ({ campaign_id }) => {
        return { url: `/campaigns/${campaign_id}/?target=base-info` };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setCampaignBaseInfo(data));
      },
      providesTags: [CAMPAIGN_TAG]
    }),

    // GET CAMPAIGN BASE INFO
    getCampaignPublicInfo: build.query({
      query: ({ campaign_id }) => {
        return { url: `/campaigns/${campaign_id}/?target=public-info` };
      },
      providesTags: [CAMPAIGN_TAG]
    }),

    // GET CAMPAIGN FOR MANAGE
    getCampaignForManage: build.query({
      query: ({ campaign_id }) => {
        return { url: `/campaigns/${campaign_id}/?target=${CAMPAIGNS_UPDATE_TARGET}` };
      }
    }),

    // CREATE CAMPAIGN
    addCampaign: build.mutation({
      query: (payload) => {
        const body = transformPayloadToFormData(payload);
        return {
          url: `/campaigns/?target=${CAMPAIGNS_CREATE_TARGET}`,
          method: HTTPMethods.POST,
          body,
        };
      },
      invalidatesTags: [CAMPAIGNS_TAG, CAMPAIGNS_AUTOCOMPLETE_TAG]
    }),

    // UPDATE CAMPAIGN
    updateCampaign: build.mutation({
      query: ({ id, ...body }) => {
        const formData = transformPayloadToFormData(body);
        return {
          url: `/campaigns/${id}/?target=${CAMPAIGNS_UPDATE_TARGET}`,
          method: HTTPMethods.PATCH,
          body: formData
        };
      },
      invalidatesTags: [CAMPAIGNS_TAG, CAMPAIGN_TAG, CAMPAIGNS_AUTOCOMPLETE_TAG, CAMPAIGNS_DASHBOARD_TAG]
    }),

    // DELETE CAMPAIGN
    deleteCampaign: build.mutation({
      query: (campaign_id) => {

        return {
          url: `/campaigns/${campaign_id}/?target=${CAMPAIGNS_DELETE_TARGET}`,
          method: HTTPMethods.DELETE
        };
      },
      invalidatesTags: [CAMPAIGNS_AUTOCOMPLETE_TAG]
    }),

    // CLOSE CAMPAIGN
    closeCampaign: build.mutation({
      query: (campaign_id) => {
        return {
          url: `/campaigns/${campaign_id}/actions/${CAMPAIGNS_CLOSE_TARGET}/?target=${CAMPAIGNS_CLOSE_TARGET}`,
          method: HTTPMethods.POST
        };
      },
      invalidatesTags: [CAMPAIGNS_TAG]
    }),

    // ACTIVATE CAMPAIGN (GO TO LIVE)
    goToLiveCampaign: build.mutation({
      query: (campaign_id) => {
        return {
          url: `/campaigns/${campaign_id}/actions/${CAMPAIGNS_GO_TO_LIVE_TARGET}/?target=${CAMPAIGNS_GO_TO_LIVE_TARGET}`,
          method: HTTPMethods.POST
        };
      },
      invalidatesTags: [CAMPAIGNS_TAG]
    }),

    // GET CAMPAIGNS FOR AUTOCOMPLETE
    getCampaignsForAutocomplete: build.query({
      query: (payload = {}) => {
        const defaultQuery = {
          target: assessorApiTargets.SHARED_ITEMS,
          [campaignsQueryArg.PAGE_SIZE]: 15,
        };

        const excludeQueryParams = [campaignsQueryArg.ACCOUNT_ID];

        const query = transformPayloadToQuery({ payload, defaultQuery, excludeQueryParams });

        return { url: `/accounts/${payload[campaignsQueryArg.ACCOUNT_ID]}/campaigns/${query}` };
      },
      providesTags: [CAMPAIGNS_AUTOCOMPLETE_TAG]
    }),

    // GET CAMPAIGNS FOR AUTOCOMPLETE
    getCampaignsMinimalistic: build.query({
      query: (payload = {}) => {
        const defaultQuery = {
          target: assessorApiTargets.SHARED_ITEMS,
          [campaignsQueryArg.PAGE_SIZE]: 15,
        };

        const excludeQueryParams = [campaignsQueryArg.ACCOUNT_ID];

        const query = transformPayloadToQuery({ payload, defaultQuery, excludeQueryParams });

        return { url: `/accounts/${payload[campaignsQueryArg.ACCOUNT_ID]}/campaigns/${query}` };
      },
      providesTags: [CAMPAIGNS_AUTOCOMPLETE_TAG]
    }),

    // GET CAMPAIGNS FOR POLICY
    getCampaignsForPolicyManage: build.query({
      query: (payload = {}) => {
        const defaultQuery = {
          target: assessorApiTargets.POLICY_MANAGE,
        };

        const excludeQueryParams = [campaignsQueryArg.ACCOUNT_ID];

        const query = transformPayloadToQuery({ payload, defaultQuery, excludeQueryParams });

        return { url: `/accounts/${payload[campaignsQueryArg.ACCOUNT_ID]}/campaigns/${query}` };
      },
      providesTags: [CAMPAIGNS_AUTOCOMPLETE_TAG]
    }),

    getCampaignDashboardAuditorsStats: build.query({
      query: ({ campaign_id, ...payload }) => {
        const defaultQuery = {
          target: AUDITOR_ACTIONS_STATS_TARGET,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return {
          url: `/campaigns/${campaign_id}/stats/dashboard-auditors/${query}`
        };
      }
    }),

    getJoinPublicCampaignInfo: build.query({
      query: ({ campaignId }) => {
        return {
          url: `/campaigns/${campaignId}/actions/join/?target=${JOIN_PUBLIC_CAMPAIGN_TARGET}`
        };
      },
      providesTags: [PUBLIC_CAMPAIGN_INFO]
    }),

    joinPublicCampaign: build.mutation({
      // query: ({ campaignId, passcode }) => {
      query: ({ campaignId, passcode }) => {
        return {
          url: `/campaigns/${campaignId}/actions/join/?target=${JOIN_PUBLIC_CAMPAIGN_TARGET}`,
          method: HTTPMethods.POST,
          body: {
            passcode
          }
        };
      },
      // invalidatesTags: [PUBLIC_CAMPAIGN_INFO]
    }),

    contactCampaignManagers: build.mutation({
      query: ({ campaignId, ...payload }) => {
        return {
          url: `/campaigns/${campaignId}/actions/contact-managers/?target=contact-managers`,
          method: "POST",
          body: payload,
        };
      }
    })
  }),
  overrideExisting: true,
});

rtk.enhanceEndpoints({
  addTagTypes: [
    CAMPAIGNS_AUTOCOMPLETE_TAG,
    CAMPAIGNS_TAG,
    CAMPAIGN_TAG,
    CAMPAIGNS_DASHBOARD_TAG,
    CAMPAIGN_PARTICIPANT_INFO_TAG,
    PUBLIC_CAMPAIGN_INFO,
  ]
});

export const {
  useGetCampaignsForAutocompleteQuery,
  useGetAssignedCampaignsToUserQuery,
  useGetParticipationCampaignsQuery,
  useGetAccountPublicAvailableCampaignsQuery,
  useGetCampaignDashboardInfoQuery,
  useAddCampaignMutation,
  useGetCampaignForManageQuery,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useCloseCampaignMutation,
  useGoToLiveCampaignMutation,
  useLazyGetCampaignsForPolicyManageQuery,
  useGetCampaignsForPolicyManageQuery,
  useGetCampaignsMinimalisticQuery,
  useGetCampaignBaseInfoQuery,
  useGetCampaignPublicInfoQuery,
  useLazyGetCampaignDashboardAuditorsStatsQuery,
  useGetCampaignParticipantQuery,
  useGetJoinPublicCampaignInfoQuery,
  useLazyGetJoinPublicCampaignInfoQuery,
  useJoinPublicCampaignMutation,
  useContactCampaignManagersMutation
} = rtk;