import React, { memo, useEffect, useState } from "react";
// MATERIAL UI
import Tooltip from "@mui/material/Tooltip";
//  UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
import { HASHTAGS_REG } from "../../../regex";
// STYLES
import styles from "../../styles.module.scss";
import commonStyles from "../../../../../../../common.module.scss";


const Hashtags = memo(({ hashtags, locale }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (hashtags?.length) {
      const data = hashtags
          ?.trim()
          ?.match(HASHTAGS_REG);
      setItems(data);
    }
  }, [hashtags]);

  if (!hashtags?.length) return null;

  const hashtagsLabel = getIntlMessageToStringLocal(locale, "assessments.hashtags");
  const tooltip = getIntlMessageToStringLocal(locale, "assessments.hashtagsTooltip");

  return (
      <div className={`${styles.formContainerFooterCheckBox} ${styles.noteHashtagsContainer}`}>
        <Tooltip
            title={tooltip}
            placement="top-start"
            classes={{
              popper: `${commonStyles.verifiedButtonTooltipPopper}`,
              tooltip: `${commonStyles.verifiedButtonTooltip} ${commonStyles.verifiedButtonTooltipHashtags}`,
              arrow: commonStyles.verifiedButtonArrow
            }}
            arrow
        >
          <div
              className={`${styles.formContainerFooterCheckBoxItemLabel} ${styles.noteHashtagsLabel}`}>{hashtagsLabel}</div>
        </Tooltip>
        <div className={styles.noteHashtags}>
          {items?.map((item, index) => {
            return (
                <div key={index} className={styles.noteHashtagsItem}>{item}</div>
            );
          })}
        </div>
      </div>
  );
});

export default Hashtags;