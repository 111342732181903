import React from "react";
import { pdf } from "@react-pdf/renderer";
import { getDateNow } from "../utils/date";
import Main from "../../components/Campaign/Results/DocumentPDF/Main";


const FETCH_ERROR = "fetch_error";

class PdfService {
  // DEFAULT FILE NAME FOR PDF
  setDefaultFileName() {
    return `assessor.io-${getDateNow()}.pdf`;
  }

  // PREPARE IMAGE
  prepareImage = async (src) => {
    try {
      if (!src || src === "none") return;

      const response = await fetch(src);

      if (response.status !== 200) throw Error();

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      return blobUrl;
    } catch (e) {
      return FETCH_ERROR;
    }
  };

  // SAVE
  async savePDF({
                  isIqcRelated,
                  data = {},
                  fileName = this.setDefaultFileName(),
                }) {
    try {
      const { cards, titles, headerData, footerData } = data;
      const {
        logoSrc,
        sublogoSrc,
        headerBgColor,
        defaultLogoSrc,
        defaultSublogoSrc,
        defaultHeaderBgColor
      } = headerData;

      const [fetchedLogo, fetchedSublogo] = await Promise.all([
        this.prepareImage(logoSrc),
        this.prepareImage(sublogoSrc)
      ]);

      const errorOccurred = fetchedLogo === FETCH_ERROR || fetchedSublogo === FETCH_ERROR;
      const noSelectedLogos = !fetchedLogo && !fetchedSublogo;
      const defaultSettings = (errorOccurred || noSelectedLogos);

      const logo = defaultSettings ? defaultLogoSrc : fetchedLogo;
      const sublogo = defaultSettings ? defaultSublogoSrc : fetchedSublogo;
      const headerBackgroundColor = defaultSettings ? defaultHeaderBgColor : headerBgColor;


      const blob = await pdf(
        <Main
          isIqcRelated={isIqcRelated}
          data={cards}
          titles={titles}
          header={{ logo, sublogo, headerBgColor: headerBackgroundColor }}
          footer={footerData}/>
      ).toBlob();

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
       console.error(e);
    }
  }
}

export const pdfService = new PdfService();