import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const label = {
  [actionsConstants.LabelListView]: simpleValidator,
  [actionsConstants.LabelCreate]: simpleValidator,
  [actionsConstants.LabelModify]: simpleValidator,
  [actionsConstants.LabelDelete]: simpleValidator,
};

export default label;