import { createSlice } from "@reduxjs/toolkit";
import { accountDashboard } from "./constants";

const initialState = {
  modals: {
    [accountDashboard.PRIVACY]: {
      isVisible: false,
      data: {},
    },
    [accountDashboard.TERMS_OF_USE]: {
      isVisible: false,
      data: {},
    },
    [accountDashboard.CONTACT_MANAGER]: {
      isVisible: false,
      data: {},
    }
  }
};

export const accountDashboardSlice = createSlice({
  name: "accountDashboard",
  initialState,
  reducers: {
    toggleAccountDashboardModal: (state, action) => {
      state.modals[action.payload.target] = {
        ...state.modals?.[action.payload.target],
        isVisible: action.payload.isVisible,
        data: action.payload?.data || {}
      };
    },
  },
});


export const { toggleAccountDashboardModal } = accountDashboardSlice.actions;

export default accountDashboardSlice.reducer;