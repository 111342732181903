// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// CONSTANTS
import { appReleaseNotesLink } from "@/shared/constants/app";

const useGetPrivateFooterItems = () => {
  const modalToggleHandlers = useToggleCommonModalHandlers();

  return [
    {
      linkTo: "/",
      titleId: "sidenav.home",
      rel: undefined,
      target: undefined
    },
    {
      linkTo: "/privacy/",
      titleId: "footer.privacy",
      rel: undefined,
      target: undefined
    },
    {
      linkTo: "/terms-of-use/",
      titleId: "footer.termsOfUse",
      rel: undefined,
      target: undefined
    },
    {
      linkTo: appReleaseNotesLink,
      titleId: "footer.releaseNotes",
      rel: "noopener noreferrer",
      target: "_blank"
    },
    {
      titleId: "footer.contact",
      rel: undefined,
      target: undefined,
      onClick: modalToggleHandlers.contactUs
    }
  ];
};

export default useGetPrivateFooterItems;