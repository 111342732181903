import { useCallback, useEffect, useRef, useState } from "react";
import { Model } from "survey-core";

const useSurveyModel = (surveyBody, config = {
  questionStartIndex: "1",
  cleanUp: () => {
  },
  onInit: () => {
  }
}) => {
  const surveyModelRef = useRef(null);
  const [surveyModel, setSurveyModel] = useState(null);

  const setSurveyInstance = useCallback(() => {
    if (surveyModelRef.current === null) {
      const sBody = { ...surveyBody };
      sBody.questionStartIndex = config?.questionStartIndex;
      surveyModelRef.current = new Model(sBody);
    }
    if (config?.onInit) {
      config.onInit(surveyModelRef.current);
    }
    return surveyModelRef.current;
  }, [config, surveyBody]);


  useEffect(() => {
    const surveyInstance = setSurveyInstance();
    setSurveyModel(surveyInstance);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    return () => {
      surveyModel?.dispose();
      surveyModelRef.current = null;
      if (config?.cleanUp) {
        config.cleanUp();
      }
    };
    // eslint-disable-next-line
  }, []);

  return surveyModel;
};

export default useSurveyModel;