import querystring from "query-string";

import { baseApiUrl } from "../../configs/MainConfig";
import { authService } from "../../shared/services/auth.service";
import * as types from "./types";
import { requestActionCreator } from "../../utils/requestActionCreator";

export const fetchStaticPagesList = (orgId) => {
  const query = {};
  if (orgId) {
    query.organization_id = orgId;
  }
  return requestActionCreator(
      types.FETCH_STATIC_PAGES_LIST,
      `${baseApiUrl}/static-page/available/?${querystring.stringify(query)}`,
      "GET",
      authService.getAuthHeaders()
  );
};

export const fetchStaticPage = (pageName, orgId) => {
  const query = {};
  if (orgId) {
    query.organization_id = orgId;
  }
  return requestActionCreator(
      types.FETCH_STATIC_PAGE,
      `${baseApiUrl}/static-page/${pageName}/?${querystring.stringify(query)}`,
      "GET",
      authService.getAuthHeaders()
  );
};

export const fetchStaticPageExtended = (pageName, orgId) => {
  const query = {};
  if (orgId) {
    query.organization_id = orgId;
  }
  return requestActionCreator(
      types.FETCH_STATIC_PAGE_EXTENDED,
      `${baseApiUrl}/static-page/${pageName}/manage/?${querystring.stringify(query)}`,
      "GET",
      authService.getAuthHeaders()
  );
};

export const updateStaticPage = (pageName, content, orgId) => {
  const query = {};
  if (orgId) {
    query.organization_id = orgId;
  }

  return requestActionCreator(
      types.UPDATE_STATIC_PAGE,
      `${baseApiUrl}/static-page/${pageName}/manage/?${querystring.stringify(query)}`,
      "PATCH",
      authService.getAuthHeaders(),
      { ...content }
  );
};

export const sendMessage = (first_name, last_name, email, text) => requestActionCreator(
    types.SEND_CONTACT_US,
    `${baseApiUrl}/contact-us/`,
    "POST",
    authService.getAuthHeaders(),
    { first_name, last_name, email, text }
);