import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import NestedLinks from "../../../../../../../helpers/NestedLinks/NestedLinks";

export const RegistrationsNestedLinks = ({ props, canViewRegistrationForm, canSeeConfirmations }) => {
  const match = useRouteMatch();

  const list = [
    {
      to: `${match.url}/registrations/#list`,
      hash: "#list",
      onClick: props.closeNavCallBack,
      isVisible: canViewRegistrationForm,
      label: "organizationMainPage.registrationList"
    },
    {
      to: `${match.url}/registrations/#confirmations`,
      hash: "#confirmations",
      onClick: props.closeNavCallBack,
      isVisible: canSeeConfirmations,
      label: "organizationMainPage.pendingConfirmations"
    },
  ];

  return (
      <Route path={`${match.path}/registrations`}>
        <NestedLinks links={list}/>
      </Route>
  );
};