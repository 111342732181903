import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// COMPONENTS
import AsrLightModal from "src/shared/components/Modals/AsrLightModal";
import AsrModal from "src/shared/components/Modals/AsrModal";
import ValidateUserPasswordModalBody from "./ValidateUserPasswordModalBody";
// STYLES
import styles from "./styles.module.scss";

const ValidateUserPasswordModal = ({
                                     open,
                                     closeModal,
                                     onSuccessHandler,
                                     lightModal = false
                                   }) => {
  const formRef = useRef();
  const modalToggleHandlers = useToggleCommonModalHandlers();

  const onSubmitButtonClick = async () => {
    if (!formRef.current) return;
    await formRef.current.handleSubmit();
  };

  const onForgotPasswordClick = () => {
    modalToggleHandlers.restorePassword({ show: true });
    closeModal();
  };

  const RenderModal = useCallback(({ children }) => {
    if (lightModal) {
      return (
        <AsrLightModal
          open={open}
          onClose={closeModal}
          dashedContentBorder={true}
          title={<IntlMessages id="user.enterPassword"/>}
          classes={{
            modalBox: styles.LightModalBox,
            titleContainer: styles.ModalHeader,
            footerContainer: styles.ModalFooter
          }}
          actionFooterButtons={[
            {
              title: <IntlMessages id='appModule.unlock'/>,
              type: "default",
              classes: {
                wrapper: styles.SubmitButtonWrapper
              },
              onClick: onSubmitButtonClick
            },
            {
              title: <><IntlMessages id="user.forgotPassword"/>?</>,
              type: "plainText",
              classes: {
                button: styles.ForgotPasswordText
              },
              onClick: onForgotPasswordClick
            }
          ]}
        >
          {children}
        </AsrLightModal>
      );
    }

    return (
      <AsrModal
        open={open}
        closeModal={closeModal}
        islandContent={true}
        title={<IntlMessages id="user.enterPassword"/>}
        classes={{
          modalBox: styles.DefaultModalBox,
          modalContent: styles.ContentContainer,
          footerContainer: styles.ModalFooter
        }}
        actionFooterButtons={[
          {
            title: <><IntlMessages id="user.forgotPassword"/>?</>,
            type: "plainText",
            classes: {
              button: styles.ForgotPasswordText
            },
            onClick: onForgotPasswordClick,
          },
          {
            title: <IntlMessages id='appModule.unlock'/>,
            type: "default",
            classes: {
              wrapper: styles.SubmitButtonWrapper,
              button: styles.SubmitButton
            },
            onClick: onSubmitButtonClick
          }
        ]}
      >
        {children}
      </AsrModal>
    );
  }, [lightModal]);

  if (!open) return null;
  return (
    <RenderModal>
      <ValidateUserPasswordModalBody
        onSuccessHandler={onSuccessHandler}
        formRef={formRef}
      />
    </RenderModal>
  );
};


ValidateUserPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSuccessHandler: PropTypes.func,
  lightModal: PropTypes.bool
};
export default ValidateUserPasswordModal;
