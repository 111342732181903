import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import IntlMessages from "../../../../utils/IntlMessages";
import classNames from "classnames";

const SideNavLogs = ({ isGlobal = true, isStaffView = false, closeNavCallBack, path = null }) => {

  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const tittleIntl = isGlobal ? "sidenav.logGlobal" : "sidenav.log";
  const defaultPath = isGlobal ? "/log" : `${match.url}/log`;
  const _path = path ? path : defaultPath;

  return (
      <NavLink
          className={prependedClass}
          to={_path}
          onClick={closeNavCallBack}
      >
        <IntlMessages id={tittleIntl}/>
      </NavLink>
  );
};

SideNavLogs.displayName = "SideNavLogs";
export default SideNavLogs;