export const FETCH_ACTION_VERBS = "FETCH_ACTION_VERBS";
export const SET_ACTION_VERBS_PAGINATION = "SET_ACTION_VERBS_PAGINATION";
export const SET_ACTION_VERBS_FILTERS = "SET_ACTION_VERBS_FILTERS";
// CRUD
export const CREATE_ACTION_VERB = "CREATE_ACTION_VERB";
export const UPDATE_ACTION_VERB = "UPDATE_ACTION_VERB";
export const DELETE_ACTION_VERB = "DELETE_ACTION_VERB";
export const GET_ACTION_VERB_INFO_FOR_MANAGING = "GET_ACTION_VERB_INFO_FOR_MANAGING";

export const SET_ACTION_VERBS_ORDER = "SET_ACTION_VERBS_ORDER";

export const CHECK_DUPLICATE_TITLE = "CHECK_DUPLICATE_TITLE";
export const FETCH_ACTION_VERBS_FOR_FOR_AUTOCOMPLETE = "FETCH_ACTION_VERBS_FOR_FOR_AUTOCOMPLETE";