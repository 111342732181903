import React from "react";
import PropTypes from "prop-types";
import QuestionNoteAndStatus from "../NoteAndStatus/NoteAndStatus";
import { parseTermins, prepareQuestionAnswer } from "../../../../utils/customFunctions";

const QuestionAnswer = ({ question }) => {
  const {
    type,
    value,
    status,
    note,
    title,
  } = question;

    return (
        <div className='report-card__list__item background'>
            <div className='question__wrapper'>
                <div
                    className='question'
                    dangerouslySetInnerHTML={{
                        __html: parseTermins(title)
                    }}
                />
            </div>
            <div className='answer__wrapper'>
                <div className='answer'>
                    {type === "checkbox" && (
                        <div
                            className='answer-value'
                            dangerouslySetInnerHTML={{
                                __html: prepareQuestionAnswer(value ?? ""),
                            }}
                        />
                    )}
                    {(type === "text" || type === "dropdown" || type === "country") && (

            <div className="answer-value">
              {value}
            </div>
          )}
          {type === "rating" && (
            <div className="answer-value">
              {value}%
            </div>
          )}
          {type === "radiogroup" && (
            <div className="answer-value">
              {value}
            </div>
          )}
          {(type === "multipletext" || type === "matrix") && (
            <div className="answer-value multipletext-answer">
              {value?.map((item, index) => {
                const { title, value } = item;
                return (
                  <div key={index}>
                    <span>{title}: </span><span>{value}</span>
                  </div>
                );
              })}
            </div>
          )}
          {type === "sortablelist" && (
            <div className="answer-value multipletext-answer">
              {value?.map((item, index) => {
                return (
                  <div key={index}>
                    <span>{index + 1}: {item}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <QuestionNoteAndStatus
          status={status}
          note={note}
          question={question}
        />
      </div>
    </div>
  );
};

QuestionAnswer.propTypes = {
  question: PropTypes.object.isRequired,
};

export default QuestionAnswer;