import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const policy = {
  [actionsConstants.PolicyListView]: simpleValidator,
  [actionsConstants.PolicyCreate]: simpleValidator,
  [actionsConstants.PolicyModify]: simpleValidator,
  [actionsConstants.PolicyLockUnlock]: simpleValidator,
  [actionsConstants.PolicyDelete]: simpleValidator,
};

export default policy;