export const deviceTypes = {
  DESKTOP: "desktop",
  LAPTOP: "laptop",
  TABLET: "tablet",
  PHONE: "phone",
};

export const displayWidth = {
  DESKTOP: 1200,
  LAPTOP: 992,
  TABLET: 768,
  PHONE: 480
};

export const getWidthDeviceType = ({ width } = {}) => {
  if (width >= displayWidth.DESKTOP) {
    return deviceTypes.DESKTOP;
  } else if (width < displayWidth.DESKTOP && width >= displayWidth.LAPTOP) {
    return deviceTypes.LAPTOP;
  } else if (width < displayWidth.LAPTOP && width >= displayWidth.TABLET) {
    return deviceTypes.TABLET;
  } else if (width < displayWidth.TABLET) {
    return deviceTypes.PHONE;
  }
  return deviceTypes.DESKTOP;
};

export const getIsIframeMode = () => {
  return window !== window.parent;
};
