import axios from "axios";
// STYLES
import styles from "../components/SurveyTermTooltip/styles.module.scss";

export const initTooltip = async ({ target,id,text ,termLink,locale }) => {
  const topMargin = 16;

  // CREATE TOOLTIP ELEMENT
  const tooltipElem = document.createElement("div");
  tooltipElem.className = styles.surveyTermTooltipContent;
  tooltipElem.id = `cf-auditor-term-link--tooltip__${id}-${text}`;
  document.body.append(tooltipElem);
  // SET TOOLTIP POSITION
  const coords = target.getBoundingClientRect();
  const setTooltipPosition = () => {
    let left = coords.left + (target.offsetWidth - tooltipElem.offsetWidth) / 2;
    if (left < 0) left = 0;
    let top = coords.top - tooltipElem.offsetHeight - topMargin;
    tooltipElem.dataset.placement = "top";
    tooltipElem.dataset.left = coords.left;
    if (top < 0) {
      top = coords.top + target.offsetHeight + topMargin;
      tooltipElem.dataset.placement = "bottom";
    }
    tooltipElem.style.transform = `translate3d(${left}px,${top}px, 0)`;
    // tooltipElem.style.left = left + "px";
    // tooltipElem.style.top = top + "px";
  };
  const term = termLink.innerText;
  // FETCH DATA
  if (!tooltipElem.innerHTML) {
    tooltipElem.innerHTML = `<div class="${styles.surveyTermTooltipPlaceholder}">
                                  <span class="${styles.skeletonLine}"></span>
                                  <span class="${styles.skeletonLine}"></span>
                                  <span class="${styles.skeletonLine}"></span> 
                                  <span class="${styles.skeletonLine}"></span> 
                                  <span class="${styles.skeletonLine}"></span> 
                                </div>`;
    setTooltipPosition();
    try {
      const { data: { data } } = await axios.get(`https://bimdictionary.com/api/v1/terms/by_title?title=${encodeURIComponent(term)}&lang=${locale}`);
      tooltipElem.innerHTML = data?.description.replace(/[[\]]+/g, "") || "";
      setTooltipPosition();
    } catch (e) {
      if (e?.response?.data?.message) {
        if (tooltipElem) {
          tooltipElem.innerHTML = e?.response?.data?.message || "";
          setTooltipPosition();
        }
      }
    }
  }
};