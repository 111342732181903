import React from "react";
// COMPONENTS
import SignUpLightModalForm from "./components/SignUpLightModalForm";
// STYLES
import styles from "./styles.module.scss";

const SignUpAuthModalContent = ({ closeModal, onSignUpCallback }) => {
  const showSucceedFormHandler = (values) => {
    onSignUpCallback(values);
  };

  return (
    <div className={styles.SignUpContentContainer}>
      <SignUpLightModalForm
        showSucceedFormHandler={showSucceedFormHandler}
        closeModal={closeModal}
      />
    </div>
  );
};

export default SignUpAuthModalContent;
