import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const surveyTemplate = {
  [actionsConstants.SurveyTemplateListView]: simpleValidator,
  [actionsConstants.SurveyTemplateCreate]: simpleValidator,
  [actionsConstants.SurveyTemplateModify]: allOwnedIdsValidator,
  [actionsConstants.SurveyTemplateDelete]: allOwnedIdsValidator,
  [actionsConstants.SurveyTemplateLockUnlock]: allOwnedIdsValidator,
  [actionsConstants.SurveyTemplateAnswerLockUnlock]: allOwnedIdsValidator,
  [actionsConstants.SurveyTemplateClone]: allOwnedIdsValidator,
  [actionsConstants.SurveyTemplateDownloadBody]: allOwnedIdsValidator,
  [actionsConstants.SurveyTemplateUploadBody]: allOwnedIdsValidator,
};

export default surveyTemplate;