import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// MATERIAL UI
import { Card, CardContent, CardHeader } from "@mui/material";
import Collapse from "@mui/material/Collapse";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
import { getAvailableQuestionsType } from "./utils/serveyAnalyticsUtils";
// HOOKS
import useHiddenQuestionType from "./hooks/useHiddenQuestionType";
import AuditorSurveyAnalytics from "./components/AuditorSurveyAnalytics";
// STORE
import {
  fetchSurveyAnalytics,
  addSurveyAnalyticsId,
  removeSurveyAnalyticsId,
  clearSurveyAnalytics,
  setCurrentSurveyAnalytics
} from "@/store/Survey/actions";
import {
  fetchSurveyAnalyticsLoading,
  getSurveyAnalytics,
  getCurrentSurveyAnalytics
} from "@/store/Survey/selectors";
// STYLES
import cardStyles from "../card.module.scss";


const Analytics = ({
                     locale,
                     isVisible,
                     survey,
                     question,
                     question_id,
                     assessmentId,
                     manageType,
                     auditorControls,
                     questionType,
                   }) => {
  const dispatch = useDispatch();
  const loading = useSelector(fetchSurveyAnalyticsLoading);
  const getCurrentQuestionAnalytics = useSelector(getSurveyAnalytics);
  const currentSurveyAnalytics = useSelector(getCurrentSurveyAnalytics);

  const [analytics, setAnalytics] = useState([]);

  useEffect(() => {
    setAnalytics(getCurrentQuestionAnalytics);
  }, [getCurrentQuestionAnalytics]);

  const availableQuestionsType = getAvailableQuestionsType();

  const isQuestionHidden = useHiddenQuestionType(questionType, availableQuestionsType);

  const title = getIntlMessageToStringLocal(locale, "assessments.analytics");
  const notAvailableQuestionType = getIntlMessageToStringLocal(locale, "assessments.notAvailableQuestionType");


  const fetchAnalytics = useCallback(async () => {
    const data = {
      question_ids: [question_id],
      management_type: manageType,
      assessment: assessmentId,
      exclude_null: true
    };
    if (auditorControls.headerSections.analytics.checked) {
      const { payload: { response } } = await dispatch(fetchSurveyAnalytics(data));
      setAnalytics(response);
    }
  }, [assessmentId, auditorControls, dispatch, manageType, question_id]);


  const handleOpen = useCallback(async () => {
    dispatch(setCurrentSurveyAnalytics({ id: question_id }));
    await dispatch(addSurveyAnalyticsId(Number(question_id)));
    try {
      await fetchAnalytics();
    } catch (e) {
      // console.log(e);
    }
  }, [dispatch, fetchAnalytics, question_id]);

  const handleClose = () => {
    dispatch(setCurrentSurveyAnalytics({}));
    dispatch(removeSurveyAnalyticsId(question_id));
  };

  const clearAnalytics = useCallback(() => {
    dispatch(clearSurveyAnalytics());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearAnalytics();
    };
  }, [clearAnalytics]);

  const actionButton = () => {
    return (
      isOpen
        ? <i onClick={handleClose} className={"zmdi zmdi-minus"}/>
        : <i onClick={handleOpen} className={"zmdi zmdi-plus"}/>
    );
  };
  const handleToggleOpen = () => {
    return isOpen ? () => {
    } : handleOpen();
  };

  const isOpen = useMemo(() => {
    return currentSurveyAnalytics.id === question_id;
  }, [currentSurveyAnalytics, question_id]);

  if (!isVisible) return null;
  return (
    <Card
      variant="outlined"
      classes={{
        root: cardStyles.root
      }}
    >
      <CardHeader
        onClick={!isQuestionHidden ? handleToggleOpen : () => {
        }}
        action={!isQuestionHidden ? actionButton() : null}
        classes={{
          root: `${cardStyles.header} ${isOpen ? cardStyles.open : cardStyles.close} ${isQuestionHidden && cardStyles.hiddenQuestionType}`,
          action: cardStyles.actionIconContainer,
          content: cardStyles.headerContent,
          title: cardStyles.headerTitle,
          subheader: cardStyles.headerSubTitle
        }}
        title={title}
        subheader={isQuestionHidden ? notAvailableQuestionType : ""}
      />
      {/* CONTENT  */}
      <Collapse in={!isQuestionHidden ? isOpen : false} timeout="auto" unmountOnExit>
        <CardContent
          classes={{
            root: cardStyles.content
          }}
        >
          {!isQuestionHidden && (
            <>
              <AuditorSurveyAnalytics
                question={question}
                analytics={analytics}
                survey={survey}
                locale={locale}
                questionId={question_id}
                clearAnalytics={clearAnalytics}
                loading={loading}
              />
            </>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Analytics;