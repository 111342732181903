import { combineReducers } from "redux";
// ACCOUNTS
import accounts from "./accounts";
// ACCOUNT
import account from "./account";
// SELECTED ITEM
import selectedItem from "./selectedItem";
// ERRORS
// import errors from "./errors";

const organizationReducer = combineReducers({
  accounts,
  account,
  selectedItem,
  // errors
});

export default organizationReducer;