import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const statementSet = {
  [actionsConstants.StatementSetListView]: simpleValidator,
  [actionsConstants.StatementSetCreate]: simpleValidator,
  [actionsConstants.StatementSetModify]: allOwnedIdsValidator,
  [actionsConstants.StatementSetLockUnlock]: allOwnedIdsValidator,
  [actionsConstants.StatementSetDelete]: allOwnedIdsValidator,
};

export default statementSet;