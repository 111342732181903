import { baseApiUrl } from "../../../configs/MainConfig";
import { requestActionCreator } from "../../../utils/requestActionCreator";
import * as types from "../types";
import { authService } from "../../../shared/services/auth.service";
import querystring from "query-string";
import makeQueryString from "../../../utils/makeQueryString";
import { makeActionQuery } from "../../../shared/helpers/makeActionQuery";

export const fetchOrganizationMembersList = (payload = {}) => (dispatch, getState) => {
  const currentState = getState();

  const sort = currentState.organization.selectedItem.members.sortBy;
  const pagination = currentState.organization.selectedItem.members.pagination;

  const payloadQuery = {
    ...sort,
    ...pagination,
    is_archived: false
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/organization/members/list/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_MEMBERS_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setOrganizationMembersSortOrder = (payload) => ({
  type: types.SET_ORGANIZATION_MEMBERS_SORT_ORDER,
  payload
});

export const setOrganizationMembersPagination = (payload) => ({
  type: types.SET_ORGANIZATION_MEMBERS_PAGINATION,
  payload
});

export const resetOrganizationMembersSortOrder = () => ({
  type: types.RESET_ORGANIZATION_MEMBERS_SORT_ORDER
});

export const resetOrganizationMembersPagination = () => ({
  type: types.RESET_ORGANIZATION_MEMBERS_PAGINATION
});


export const fetchCampaignMembersListForAssessor = (payload) => (dispatch, getState) => {
  const currentState = getState();
  const sorting = currentState.organization.selectedItem.assessor.assignments.sortBy;
  const pagination = currentState.organization.selectedItem.assessor.assignments.pagination;

  const payloadQuery = {
    page: 1,
    page_size: 10,
    ...sorting,
    ...pagination
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);
  let url = `${baseApiUrl}/campaign/members/relation-to-assessor/list/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setCampaignMembersListForAssessorSortOrder = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_ORDER,
  payload
});

export const setCampaignMembersListForAssessorPagination = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_PAGINATION,
  payload
});

export const fetchCampaignMembersListForAssessorAutocomplete = (payload) => {
  const payloadQuery = {
    page_size: 15,
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);
  let url = `${baseApiUrl}/user/list/?${query}`;

  return requestActionCreator(
    types.FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchUserListForAutocomplete = (relatedTo = "organization", payload) => {
  const payloadQuery = {
    page_size: 10,
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);
  let url = `${baseApiUrl}/user/related_to/${relatedTo}/minimalistic/?${query}`;

  return requestActionCreator(
    types.FETCH_USER_LIST_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchAuditorsListForAutocomplete = (payload) => {
  const payloadQuery = {
    page_size: 10,
    page: 1,
    members_type: "assessor"
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);
  let url = `${baseApiUrl}/organization/members/list/?${query}`;

  return requestActionCreator(
    types.FETCH_AUDITORS_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const clearCampaignMembersListForAssessor = () => {
  return {
    type: types.CLEAN_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR,
  };
};
// TODO: DELETE
export const fetchCampaignMemberNotesListForAssessor = (payload) => (dispatch, getState) => {
  const currentState = getState();
  const paginationString = makeQueryString(currentState.organization.selectedItem.assessor.notes.pagination);

  const queryParts = [];
  queryParts.push(paginationString);

  const payloadQuery = {};
  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });
  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/assessment/answer/assessor/note/list/?${query}&${queryParts.join("&")}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setCampaignMemberNotesListForAssessorPagination = (payload) => ({
  type: types.SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_PAGINATION,
  payload
});

export const setCampaignMemberNotesListForAssessorFilters = (payload) => ({
  type: types.SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_FILTERS,
  payload: payload
});

// TODO: DELETE
export const fetchCampaignMemberExtendInfoRelatedToAssessor = (id, assessments_results = []) => {
  const assessments_results_ids = assessments_results.join(",");

  return requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR,
    `${baseApiUrl}/campaign/members/relation-to-assessor/extended/?id=${id}&assessments_results=${assessments_results_ids}`,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const resetCampaignMemberExtendInfoRelatedToAssessor = () => ({
  type: types.RESET_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR,
});

export const fetchOrganizationPotentialMembersList = (payload) => {
  const query = makeActionQuery({ payload });
  return requestActionCreator(
    types.FETCH_ORGANIZATION_POTENTIAL_MEMBERS_LIST,
    `${baseApiUrl}/organization/members/potential/list/?${query}`,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const createOrganizationMember = (data) => requestActionCreator(
  types.CREATE_ORGANIZATION_MEMBER,
  `${baseApiUrl}/organization/members/`,
  "POST",
  { ...authService.getAuthHeaders() },
  data
);

export const updateOrganizationMember = (memberId, data) => requestActionCreator(
  types.UPDATE_ORGANIZATION_MEMBER,
  `${baseApiUrl}/organization/members/?id=${memberId}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  data,
);

export const deleteOrganizationMember = (id) => requestActionCreator(
  types.DELETE_ORGANIZATION_MEMBER,
  `${baseApiUrl}/organization/members/?id=${id}`,
  "DELETE",
  { ...authService.getAuthHeaders() },
  null
);

export const fetchOrganizationMemberRoleTypes = () => requestActionCreator(
  types.FETCH_ORGANIZATION_MEMBER_ROLE_TYPES,
  `${baseApiUrl}/core/choice-lists/?key=ORGANIZATION_MEMBER_ROLE_TYPES`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchOrganizationMemberRoleTypesByCurrentUser = (organizationId, searchValue) => {
  let url = `${baseApiUrl}/organization/member/role/list/?organization_id=${organizationId}&is_archived=false&page_size=15`;

  if (searchValue) {
    url = `${url}&name=${searchValue}`;
  }

  return requestActionCreator(
    types.FETCH_ORGANIZATION_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchOrganizationMemberInvitationsList = (payload) => (dispatch, getState) => {
  const currentState = getState();

  const pagination = currentState.organization.selectedItem.invites.pagination;

  const payloadQuery = {
    ...pagination
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/organization/members/invitation/list/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_MEMBER_INVITATIONS_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setOrganizationMemberInvitationsListPagination = (payload) => ({
  type: types.SET_ORGANIZATION_MEMBERS_INVITATIONS_PAGINATION,
  payload
});

export const createOrganizationMemberInvitation = (inviterId, organizationId, user_email, roleId) => requestActionCreator(
  types.CREATE_ORGANIZATION_MEMBER_INVITATION,
  `${baseApiUrl}/organization/members/invitation/`,
  "POST",
  { ...authService.getAuthHeaders() },
  { invited_by: inviterId, organization: organizationId, user_email, role: roleId }
);

export const updateOrganizationMemberInvitation = (id, data) => requestActionCreator(
  types.UPDATE_ORGANIZATION_MEMBER_INVITATION,
  `${baseApiUrl}/organization/members/invitation/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  data
);

export const resendOrganizationMemberInvitation = (id) => requestActionCreator(
  types.RESEND_ORGANIZATION_MEMBER_INVITATION,
  `${baseApiUrl}/organization/members/invitation/resend/?id=${id}`,
  "POST",
  { ...authService.getAuthHeaders() }
);