import { actionsConstants } from "../../constants/actionsConstants";
import campaignsValidator from "../validatorsHandlers/campaignsValidator";
import allOwnedIdsCampaignsValidator from "../validatorsHandlers/allOwnedIdsCampaignsValidator";

const assessment = {
  [actionsConstants.AssessmentListView]: campaignsValidator,
  [actionsConstants.AssessmentCreate]: campaignsValidator,
  [actionsConstants.AssessmentModify]: allOwnedIdsCampaignsValidator,
  [actionsConstants.AssessmentDelete]: allOwnedIdsCampaignsValidator,
  // [actionsConstants.AssessmentGenerateIframe]: allOwnedIdsCampaignsValidator,
  [actionsConstants.AssessmentDownloadReportCSV]: allOwnedIdsCampaignsValidator,
  // [actionsConstants.AssessmentDownloadReportJSON]: allOwnedIdsCampaignsValidator,
  [actionsConstants.AssessmentDownloadReportXLSX]: allOwnedIdsCampaignsValidator,
  [actionsConstants.AssessmentPublishPublicReport]: allOwnedIdsCampaignsValidator,

};

export default assessment;