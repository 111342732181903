import React from "react";
// ASSETS
import handWaitingGif from '@/assets/gifs/hand-waiting-transp-grey.gif';


const AsrWaitingHandGif = ({ className }) => {
  return (
    <img
      src={handWaitingGif}
      alt="hand waiting..."
      className={className}
    />
  );
};

export default AsrWaitingHandGif;
