import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const auditor = {
  [actionsConstants.AuditorIsAuditor]: simpleValidator,
  [actionsConstants.AuditorAnswerNoteCreate]: simpleValidator,
  [actionsConstants.AuditorAnswerNoteModify]: simpleValidator,
  [actionsConstants.AuditorAnswerNoteLockUnlock]: simpleValidator,
  [actionsConstants.AuditorAnswerNoteDelete]: simpleValidator,
  [actionsConstants.AuditorAnswerAnalyticsView]: simpleValidator,
  [actionsConstants.AuditorAnswerVerify]: simpleValidator,
  [actionsConstants.AuditorAnswerModify]: simpleValidator,
  [actionsConstants.AuditorAssessmentAccessLockUnlock]: simpleValidator,
  [actionsConstants.AuditorDownloadReportXLSX]: simpleValidator,
};

export default auditor;