import * as types from "./types";
import {FIXED_DRAWER} from "./types";

const initialState = {
    windowWidth: window.innerWidth,
    navCollapsed: false,
    locale: {
        languageId: "english",
        locale: "en",
        name: "English",
        icon: "us"
    },
    drawerType: FIXED_DRAWER,
};

const settingsReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.WINDOW_WIDTH: {
            return {
                ...state,
                windowWidth: action.payload
            };
        }

        case types.TOGGLE_COLLAPSED_NAV: {
            return {
                ...state,
                navCollapsed: action.payload
            };
        }

        case types.SWITCH_LANGUAGE: {
            return {
                ...state,
                locale: action.payload
            };
        }

        case types.TOGGLE_DRAWER_TYPE: {
            return {
                ...state,
                drawerType: action.payload
            };
        }

        default:
            return state;
    }
};

export default settingsReducer;
