import React from "react";
// COMPONENTS
import { Modal } from "react-responsive-modal";
// STYLES
import styles from "./styles.module.scss";

const AsrSimpleMessageModal = ({
                                 open,
                                 closeModal,
                                 children,
                                 zIndex = 1300
                               }) => {
  return (
    <Modal
      styles={{
        overlay: { "zIndex": zIndex }
      }}
      classNames={{
        modal: styles.SimpleMessageModal,
        closeButton: styles.SimpleMessageModalCloseButton
      }}
      open={open}
      onClose={closeModal}
      center
    >
      <div className={styles.SimpleMessageModalContent}>
        {children}
      </div>
    </Modal>
  );
};

export default AsrSimpleMessageModal;
