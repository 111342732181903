import React from "react";
import { NavLink, Route, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
// UTILS
import IntlMessages from "../../../../utils/IntlMessages";
// HELPERS
import NestedLinks from "../../helpers/NestedLinks/NestedLinks";


const SideNavStatements = ({
                             isSuperUser = false,
                             canSeeStatements,
                             canSeeStatementsSets,
                             canSeeStatementTranslationsTab = false,
                             isGlobal = true,
                             isStaffView = false,
                             closeNavCallBack,
                             isTranslations = true,
                             path = null
                           }) => {

  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const tittleIntl = isGlobal ? "sidenav.statementsGlobal" : "sidenav.statements";
  const defaultPath = isGlobal ? "/statements" : `${match.url}/statements`;
  const _path = path ? path : defaultPath;

  const links = [
    {
      to: `${_path}#statements`,
      hash: "#statements",
      onClick: closeNavCallBack,
      isVisible: isGlobal ? isSuperUser : canSeeStatements,
      label: "sidenav.statements"
    },
    {
      to: `${_path}#types`,
      hash: "#types",
      onClick: closeNavCallBack,
      isVisible: isSuperUser && isGlobal,
      label: "sidenav.statementsTypes"
    },
    {
      to: `${_path}#translations`,
      hash: "#translations",
      onClick: closeNavCallBack,
      isVisible: isTranslations && canSeeStatementTranslationsTab,
      label: "sidenav.statementsTranslations"
    },
    {
      to: `${_path}#sets`,
      hash: "#sets",
      onClick: closeNavCallBack,
      isVisible: isGlobal ? isSuperUser : canSeeStatementsSets,
      label: "sidenav.statementsSets"
    },
    {
      to: `${_path}#actions`,
      hash: "#actions",
      onClick: closeNavCallBack,
      isVisible: isSuperUser && isGlobal,
      label: "sidenav.statementsActions"
    }
  ];

  if (!canSeeStatements
    && !canSeeStatementsSets
    && !(isSuperUser && isGlobal)
    && !(isTranslations && canSeeStatementTranslationsTab)) return null;

  return (
    <>
      <NavLink
        className={prependedClass}
        to={_path}
        onClick={closeNavCallBack}
      >
        <IntlMessages id={tittleIntl}/>
      </NavLink>
      <Route path={_path}>
        <NestedLinks links={links}/>
      </Route>
    </>
  );
};

SideNavStatements.displayName = "SideNavStatements";
export default SideNavStatements;