import { useSelector } from "react-redux";
// HELPERS
import { policyValidation } from "./helpers/policyValidation";
// STORE
import { getIsSuperUser, getUserId } from "@/store/User/selectors";
import { getAccountUserNormalizedPermissions } from "@/store/Organization/rootReducers/account/currentUser/selectors";

/**
 * Single validation func for defining if user has permission to specific action
 * @returns {boolean} is user has permission
 */
const usePolicyValidation = ({
                               action,
                               lookup
                             } = {}) => {
  const isSuperUser = useSelector(getIsSuperUser);
  const userId = useSelector(getUserId);
  const permissions = useSelector(getAccountUserNormalizedPermissions);
  /*
  * to determine the loading status
  * on initial data load, all policies are in the state === "false"
  * */
  if (!permissions || !action) return isSuperUser;
  const isOwner = lookup && (lookup.isOwner || lookup.owner?.id === userId);

  return policyValidation({
    action,
    lookup,
    isSuperUser,
    isOwner,
    permissions
  });
};

export default usePolicyValidation;