import { assessorApi } from "@/store/services/rtk";
// CONSTANTS
import {
  ACCOUNT_TAG,
  ACCOUNT_TARGET,
  ACCOUNTS_TAG,
  ACCOUNTS_TARGET,
  ACCOUNT_MEMBERS_TARGET,
  ADD_ACCOUNT_TARGET,
  PAGE_ACCOUNT_TARGET,
  PAGE_TAG,
  PAGE_TARGET,
  UPDATE_ACCOUNT_TARGET,
 ACCOUNT_FOR_MANAGE_TAG
} from "./constants";
import { apiQueryArg } from "../config/assessorApiConstants";
import { HTTPMethods } from "../config/constants";
import { SET_ACCOUNT_GENERAL_INFO } from "../../Organization/types";
// HELPERS
import transformPayloadToQuery from "@/store/services/utils/transformPayloadToQuery";


const accounts = assessorApi.injectEndpoints({
  endpoints: (build) => ({
    // GET ALL ACCOUNTS
    getAccounts: build.query({
      query: (payload = {}) => {
        const defaultQuery = {
          // is_archived: false,
          target: ACCOUNTS_TARGET,
          [apiQueryArg.SORT_BY]: apiQueryArg.ID
        };
        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${query}` };
      },
      providesTags: [ACCOUNTS_TAG]
    }),
    // GET ACCOUNT GENERAL INFO
    getAccountGeneralInfo: build.query({
      query: (payload = {}) => {
        const defaultQuery = {
          target: ACCOUNT_TARGET
        };

        const excludeQueryParams = ["id"];

        const query = transformPayloadToQuery({ payload, defaultQuery, excludeQueryParams });

        return { url: `/accounts/${payload.id}/${query}` };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch({ type: SET_ACCOUNT_GENERAL_INFO, payload: data });
      },
      keepUnusedDataFor: 0,
      providesTags: [ACCOUNT_TAG]
    }),
    // GET ACCOUNT MEMBERSHIP
    getAccountMembership: build.query({
      query: (id) => {
        return { url: `/accounts/${id}/members/me/?target=${ACCOUNT_MEMBERS_TARGET}` };
      },
    }),
    // GET ACCOUNT FOR MANAGE
    getAccountForManage: build.query({
      query: (account_id) => {
        return { url: `/accounts/${account_id}/?target=${UPDATE_ACCOUNT_TARGET}` };
      },
      providesTags: [ACCOUNT_FOR_MANAGE_TAG]
    }),
    // ADD ACCOUNT
    addAccount: build.mutation({
      query: (body) => {
        return {
          url: `/accounts/?target=${ADD_ACCOUNT_TARGET}`,
          method: HTTPMethods.POST,
          body
        };
      },
      invalidatesTags: [ACCOUNTS_TAG, ACCOUNT_FOR_MANAGE_TAG],
    }),
    // EDIT ACCOUNT
    editAccount: build.mutation({
      query: ({ account_id, ...body }) => {
        const formData = new FormData();
        Object.keys(body)
          .forEach(key => {
            if (body[key]?.type) {
              formData.append(key, body[key]);
            } else if (typeof body[key] === "object") {
              formData.append(key, JSON.stringify(body[key]));
            } else {
              formData.append(key, body[key]);
            }
          });

        return {
          url: `/accounts/${account_id}/?target=${UPDATE_ACCOUNT_TARGET}`,
          method: HTTPMethods.PATCH,
          body: formData
        };
      },
      invalidatesTags: [ACCOUNT_TAG, PAGE_TAG, ACCOUNT_FOR_MANAGE_TAG],
    }),
    // CLONE ACCOUNT
    cloneAccount: build.mutation({
      query: ({ account_id }) => {
        return {
          url: `/accounts/${account_id}/actions/clone/?target=clone`,
          method: "POST",
        };
      },
      invalidatesTags: [ACCOUNTS_TAG]
    }),
    // ARCHIVE ACCOUNT
    archiveAccount: build.mutation({
      query: ({ account_id }) => {
        return {
          url: `/accounts/${account_id}/actions/archive/?target=archive`,
          method: "POST",
        };
      },
      invalidatesTags: [ACCOUNTS_TAG]
    }),
    // UN ARCHIVE ACCOUNT
    unArchiveAccount: build.mutation({
      query: ({ account_id }) => {
        return {
          url: `/accounts/${account_id}/actions/unarchive/?target=unarchive`,
          method: "POST",
        };
      },
      invalidatesTags: [ACCOUNTS_TAG]
    }),
    contactAccountManagers: build.mutation({
      query: ({ account_id, ...payload }) => {
        return {
          url: `/accounts/${account_id}/actions/contact-managers/?target=contact-managers`,
          method: "POST",
          body: payload,
        };
      },
    }),
    // GET PAGE
    getPageInfo: build.query({
      query: ({ key, ...payload } = {}) => {
        const defaultQuery = {
          target: PAGE_TARGET,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/pages/${key}/${query}` };
      },
    }),
    // GET ACCOUNT PAGE
    getAccountPageInfo: build.query({
      query: ({ key, account_id, ...payload } = {}) => {
        const defaultQuery = {
          target: PAGE_ACCOUNT_TARGET,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${account_id}/pages/${key}/${query}` };
      },
      providesTags: [PAGE_TAG]
    }),
  }),
  overrideExisting: true,
});

accounts.enhanceEndpoints({
  addTagTypes: [ACCOUNTS_TAG, ACCOUNT_TAG, PAGE_TAG, ACCOUNT_FOR_MANAGE_TAG]
});

export const {
  useGetAccountsQuery,
  useGetAccountForManageQuery,
  useGetAccountGeneralInfoQuery,
  useLazyGetAccountGeneralInfoQuery,
  useGetAccountMembershipQuery,
  useLazyGetAccountMembershipQuery,
  useAddAccountMutation,
  useCloneAccountMutation,
  useArchiveAccountMutation,
  useUnArchiveAccountMutation,
  useContactAccountManagersMutation,
  useEditAccountMutation,
  useGetPageInfoQuery,
  useGetAccountPageInfoQuery,
} = accounts;
