import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { networkReducer, requestsPromiseMiddleware } from "redux-saga-requests";

import rootSaga from "./sagas";
import userReducer from "./User/reducers";
import organizationReducer from "./Organization/rootReducers";
// import organizationReducer from "./Organization/reducers"; //TODO: remove after testing "./Organization/rootReducers"
import campaignReducer from "./Campaign/reducers";
import systemReducer from "./System/reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import settingsReducer from "./Settings/reducers";
import staticPageReducer from "./StaticPages/reducers";
import campaignStatisticsReducer from "./Statistics/reducers";
import adminReducer from "./Admin/reducers";
import powerBIReducer from "./PowerBI/reducers";
import labelsReducer from "./Labels/reducers";
import statementsReducer from "./Statements/reducers";
import actionVerbsReducer from "./ActionVerb/reducer";
import surveyReducer from "./Survey/reducers";
import surveyAssessmentReducer from "./SurveyAssessment/reducers";
import surveyRegistrationReducer from "./SurveyRegistration/reducers";
import publicSurveyReducer from "./PublicSurvey/reducers";
// UTILS
import { appService } from "@/utils/app";
// ASSESSOR API
import { assessorApi } from "@/store/services/rtk";

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const promiseMiddleware = requestsPromiseMiddleware({
  auto: true // if you with to promisify all request actions without explicit meta.asPromise true
});

export const store = configureStore({
  reducer: {
    network: networkReducer({}),
    [assessorApi.reducerPath]: assessorApi.reducer,
    auth: userReducer,
    organization: organizationReducer,
    campaign: campaignReducer,
    system: systemReducer,
    settings: settingsReducer,
    campaignStatistics: campaignStatisticsReducer,
    admin: adminReducer,
    staticPage: staticPageReducer,
    powerBI: powerBIReducer,
    labels: labelsReducer,
    statements: statementsReducer,
    actionVerbs: actionVerbsReducer,
    survey: surveyReducer,
    surveyAssessment: surveyAssessmentReducer,
    surveyRegistration: surveyRegistrationReducer,
    publicSurvey: publicSurveyReducer,
    router: connectRouter(history),
  },
  // eslint-disable-next-line no-undef
  devTools: !appService.getIsProductionMode(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
    .concat(assessorApi.middleware)
    .concat(routeMiddleware)
    .concat(promiseMiddleware)
    .concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

