import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// COMPONENTS
import LazyLoader from "@/shared/UI/Loaders/LazyLoader/LazyLoader";
// PAGES
import App from "@/pages/App";
import { OrganizationPublicSurvey } from "@/pages/PublicSurvey/Organization/OrganizationPublicSurvey";
import PublicSurvey from "@/pages/PublicSurvey/PublicSurvey";
//  LAZY PAGES
const Error500Page = lazy(() => import("@/pages/ErrorPage/Error500Page"));
const Error404Page = lazy(() => import("@/pages/ErrorPage/Error404Page"));
const InvitationError = lazy(() => import("@/pages/InvitationError"));
const LandingCampaignEmail = lazy(() => import("@/pages/Landing/LandingEmail/LandingCampaignEmail/LandingCampaignEmail"));
const LandingOrganizationEmail = lazy(() => import("@/pages/Landing/LandingEmail/LandingOrganizationEmail/LandingOrganizationEmail"));
const RestorePasswordForm = lazy(() => import("@/pages/Auth/RestorePasswordForm"));
const RestorePassword = lazy(() => import("@/pages/Auth/RestorePassword"));
const EmailVerify = lazy(() => import("@/pages/Auth/EmailVerify"));
const PublicPages = lazy(() => import("@/pages/Public/PublicPages"));
const SvgIconsPage = lazy(() => import("@/pages/IconsPage/IconsPage"));
const PublicStaticPageView = lazy(() => import("@/pages/StaticPages/PublicStaticPageView"));
const LandingBase = lazy(() => import("@/pages/Landing/LandingBase"));
// UTILS
import { appService } from "@/utils/app";
// CONSTANTS
import { staticPageApiKeys } from "@/store/services/staticPages/constants";
import { QUERY_PARAMS as QUERY_PARAMS_LANDING } from "@/store/services/infoblocks/constants";
import CommonAppModals from "@/components/App/Modals/CommonAppModals";
import CookieBanner from "@/components/App/Banners/CookieBanner";

const AppRouter = () => {
  return (
    <Router>
      <Suspense fallback={<LazyLoader/>}>
        <Switch>
          <Route path="/email-verify/">
            <EmailVerify/>
          </Route>

          <Route path="/public/" component={PublicPages}/>

          <Route path="/terms-of-use/">
            <PublicStaticPageView pageKey={staticPageApiKeys.TERMS_OF_USE}/>
          </Route>

          <Route path="/privacy/">
            <PublicStaticPageView pageKey={staticPageApiKeys.PRIVACY}/>
          </Route>

          <Route path="/about/">
            <PublicStaticPageView pageKey={staticPageApiKeys.ABOUT}/>
          </Route>

          <Route path="/release-notes/">
            <PublicStaticPageView pageKey={staticPageApiKeys.RELEASE_NOTES}/>
          </Route>

          <Route path="/restore-password/verified/">
            <RestorePasswordForm/>
          </Route>

          <Route path="/restore-password/">
            <RestorePassword/>
          </Route>

          <Route path="/public-survey-email/" component={OrganizationPublicSurvey}/>

          <Route path="organization/public-survey-questions">
            <PublicSurvey/>
          </Route>

          <Route path="/public-survey-questions">
            <PublicSurvey/>
          </Route>

          <Route
            path='/organization-invitation'
            render={(props) => <LandingOrganizationEmail {...props} type='organization'/>}
          />

          <Route
            path='/campaign-invitation'
            render={(props) => <LandingCampaignEmail {...props} type='campaign'/>}
          />

          <Route
            path='/invitation-error'
            component={InvitationError}
          />
          {/* SVG ICONS */}
          {/* eslint-disable-next-line no-undef */}
          {/*{appService.isDevelopmentMode() && (*/}
          {appService.getIsDevelopmentMode() && (
            <Route
              path='/svg-icons'
              component={SvgIconsPage}
            />
          )}
          {/* ERROR PAGE */}
          <Route path='/error' component={Error500Page}/>

          {/* NOT FOUND PAGE */}
          <Route path="/404" component={Error404Page}/>

          <Route exact path="/features">
            <LandingBase targetKey={QUERY_PARAMS_LANDING.IFB_TARGET_FEATURES}/>
          </Route>

          <Route exact path="/">
            <LandingBase targetKey={QUERY_PARAMS_LANDING.IFB_TARGET_OVERVIEW}/>
          </Route>

          <Route path="/">
            <App/>
          </Route>
        </Switch>

        {/* COMMON BANNERS */}
        <CookieBanner/>

        {/* COMMON APP MODALS */}
        <CommonAppModals/>
      </Suspense>
    </Router>
  );
};

AppRouter.displayName = "AppRouter";
export default AppRouter;