const hiddenQuestionAnalytics = [
  "email",
  "password",
  "tel",
  "text",
  "url",
  "multipletext",
  "comment",
  "expression",
];

export default hiddenQuestionAnalytics;