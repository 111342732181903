export const actionsConstants = {
  ALL: "acc:*:*",
  // Account
  AccountDashboardView: "acc:Account:DashboardView",
  AccountModify: "acc:Account:Modify",
  AccountArchive: "acc:Account:Archive",
  AccountViewLogs: "acc:Account:ViewLogs",
  // Account Invitation
  AccountInvitationListView: "acc:AccountInvitation:ListView",
  AccountInvitationCreate: "acc:AccountInvitation:Create",
  AccountInvitationModify: "acc:AccountInvitation:Modify",
  AccountInvitationResend: "acc:AccountInvitation:Resend",
  AccountInvitationDelete: "acc:AccountInvitation:Delete",
  // ACCOUNT MEMBER
  AccountMemberListView: "acc:AccountMember:ListView",
  AccountMemberCreate: "acc:AccountMember:Create",
  AccountMemberModify: "acc:AccountMember:Modify",
  AccountMemberLockUnlock: "acc:AccountMember:LockUnlock",
  AccountMemberHoldAndActivate: "acc:AccountMember:HoldAndActivate",
  AccountMemberDelete: "acc:AccountMember:Delete",
  // Account Role
  AccountRoleListView: "acc:AccountRole:ListView",
  AccountRoleCreate: "acc:AccountRole:Create",
  AccountRoleModify: "acc:AccountRole:Modify",
  AccountRoleDelete: "acc:AccountRole:Delete",
  // Account User
  AccountUserListView: "acc:AccountUser:ListView",
  AccountUserCreate: "acc:AccountUser:Create",
  AccountUserModify: "acc:AccountUser:Modify",
  AccountUserLockUnlock: "acc:AccountUser:LockUnlock",
  AccountUserDelete: "acc:AccountUser:Delete",
  // Assessment
  AssessmentListView: "acc:Assessment:ListView",
  AssessmentCreate: "acc:Assessment:Create",
  AssessmentModify: "acc:Assessment:Modify",
  AssessmentDelete: "acc:Assessment:Delete",
  // AssessmentGenerateIframe: "acc:Assessment:GenerateIframe",
  AssessmentDownloadReportCSV: "acc:Assessment:DownloadReportCSV",
  // AssessmentDownloadReportJSON: "acc:Assessment:DownloadReportJSON",
  AssessmentDownloadReportXLSX: "acc:Assessment:DownloadReportXLSX",
  AssessmentPublishPublicReport: "acc:Assessment:PublishPublicReport",
  // Assessment Act
  AssessmentActAnswerNoteView: "acc:AssessmentAct:AnswerNoteView",
  AssessmentActAnswerNoteCreate: "acc:AssessmentAct:AnswerNoteCreate",
  AssessmentActAnswerNoteModify: "acc:AssessmentAct:AnswerNoteModify",
  AssessmentActAnswerNoteLockUnlock: "acc:AssessmentAct:AnswerNoteLockUnlock",
  AssessmentActAnswerNoteDelete: "acc:AssessmentAct:AnswerNoteDelete",
  AssessmentActAnswerAnalyticsView: "acc:AssessmentAct:AnswerAnalyticsView",
  AssessmentActAnswerVerify: "acc:AssessmentAct:AnswerVerify",
  AssessmentActAnswerModify: "acc:AssessmentAct:AnswerModify",
  AssessmentActAccessLockUnlock: "acc:AssessmentAct:AccessLockUnlock",
  // Auditor
  AuditorIsAuditor: "acc:Auditor:IsAuditor",
  AuditorAnswerNoteCreate: "acc:Auditor:AnswerNoteCreate",
  AuditorAnswerNoteModify: "acc:Auditor:AnswerNoteModify",
  AuditorAnswerNoteLockUnlock: "acc:Auditor:AnswerNoteLockUnlock",
  AuditorAnswerNoteDelete: "acc:Auditor:AnswerNoteDelete",
  AuditorAnswerAnalyticsView: "acc:Auditor:AnswerAnalyticsView",
  AuditorAnswerVerify: "acc:Auditor:AnswerVerify",
  AuditorAnswerModify: "acc:Auditor:AnswerModify",
  AuditorAssessmentAccessLockUnlock: "acc:Auditor:AssessmentAccessLockUnlock",
  AuditorDownloadReportXLSX: "acc:Auditor:DownloadReportXLSX",
  // Campaign
  CampaignDashboardView: "acc:Campaign:DashboardView",
  CampaignListView: "acc:Campaign:ListView",
  CampaignCreate: "acc:Campaign:Create",
  CampaignModify: "acc:Campaign:Modify",
  CampaignDelete: "acc:Campaign:Delete",
  // CampaignDownloadReportCSV: "acc:Campaign:DownloadReportCSV",
  // CampaignDownloadReportJSON: "acc:Campaign:DownloadReportJSON",
  CampaignDownloadReportXLSX: "acc:Campaign:DownloadReportXLSX",
  CampaignViewLogs: "acc:Campaign:ViewLogs",
  CampaignClone: "acc:Campaign:Clone",
  // Campaign Invitation
  CampaignInvitationListView: "acc:CampaignInvitation:ListView",
  CampaignInvitationCreate: "acc:CampaignInvitation:Create",
  CampaignInvitationModify: "acc:CampaignInvitation:Modify",
  CampaignInvitationDelete: "acc:CampaignInvitation:Delete",
  // Campaign Participant
  CampaignParticipantListView: "acc:CampaignParticipant:ListView",
  CampaignParticipantCreate: "acc:CampaignParticipant:Create",
  CampaignParticipantModify: "acc:CampaignParticipant:Modify",
  CampaignParticipantDelete: "acc:CampaignParticipant:Delete",
  CampaignParticipantDownloadReportXLSX: "acc:CampaignParticipant:DownloadReportXLSX",
  CampaignParticipantDownloadReportPDF: "acc:CampaignParticipant:DownloadReportPDF",
  CampaignParticipantViewChartsReport: "acc:CampaignParticipant:ViewChartsReport",
  CampaignParticipantAuditorAssignment: "acc:CampaignParticipant:AuditorAssignment",
  // Custom Field
  CustomFieldListView: "acc:CustomField:ListView",
  CustomFieldCreate: "acc:CustomField:Create",
  CustomFieldModify: "acc:CustomField:Modify",
  CustomFieldDelete: "acc:CustomField:Delete",
  // Label
  LabelListView: "acc:Label:ListView",
  LabelCreate: "acc:Label:Create",
  LabelModify: "acc:Label:Modify",
  LabelDelete: "acc:Label:Delete",
  // Label Group
  LabelGroupListView: "acc:LabelGroup:ListView",
  LabelGroupCreate: "acc:LabelGroup:Create",
  LabelGroupModify: "acc:LabelGroup:Modify",
  LabelGroupLockUnlock: "acc:LabelGroup:LockUnlock",
  LabelGroupDelete: "acc:LabelGroup:Delete",
  // Notification
  NotificationAssessmentCompletion: "acc:Notification:AssessmentCompletion",
  NotificationCampaignCompletion: "acc:Notification:CampaignCompletion",
  NotificationRegistrationFormSubmission: "acc:Notification:RegistrationFormSubmission",
  // Policy
  PolicyListView: "acc:Policy:ListView",
  PolicyCreate: "acc:Policy:Create",
  PolicyModify: "acc:Policy:Modify",
  PolicyLockUnlock: "acc:Policy:LockUnlock",
  PolicyDelete: "acc:Policy:Delete",
  // Power BI Dataset
  PowerBIDatasetListView: "acc:PowerBIDataset:ListView",
  PowerBIDatasetCreate: "acc:PowerBIDataset:Create",
  PowerBIDatasetModify: "acc:PowerBIDataset:Modify",
  PowerBIDatasetLockUnlock: "acc:PowerBIDataset:LockUnlock",
  PowerBIDatasetDelete: "acc:PowerBIDataset:Delete",
  // Power BI Report
  PowerBIReportListView: "acc:PowerBIReport:ListView",
  PowerBIReportCreate: "acc:PowerBIReport:Create",
  PowerBIReportModify: "acc:PowerBIReport:Modify",
  PowerBIReportLockUnlock: "acc:PowerBIReport:LockUnlock",
  PowerBIReportDelete: "acc:PowerBIReport:Delete",
  // Registration Act
  RegistrationActListView: "acc:RegistrationAct:ListView",
  RegistrationActModify: "acc:RegistrationAct:Modify",
  RegistrationActModifyStatus: "acc:RegistrationAct:ModifyStatus",
  RegistrationActArchive: "acc:RegistrationAct:Archive",
  RegistrationActDelete: "acc:RegistrationAct:Delete",
  RegistrationActAnswerVerify: "acc:RegistrationAct:AnswerVerify",
  RegistrationActAnswerNoteView: "acc:RegistrationAct:AnswerNoteView",
  RegistrationActAnswerNoteLockUnlock: "acc:RegistrationAct:AnswerNoteLockUnlock",
  RegistrationActAnswerModify: "acc:RegistrationAct:AnswerModify",
  RegistrationActAnswerNoteCreate: "acc:RegistrationAct:AnswerNoteCreate",
  RegistrationActAnswerNoteModify: "acc:RegistrationAct:AnswerNoteModify",
  RegistrationActAnswerNoteDelete: "acc:RegistrationAct:AnswerNoteDelete",
  RegistrationActDownloadReportXLSX: "acc:RegistrationAct:DownloadReportXLSX",
  // Registration Form
  RegistrationFormListView: "acc:RegistrationForm:ListView",
  RegistrationFormCreate: "acc:RegistrationForm:Create",
  RegistrationFormModify: "acc:RegistrationForm:Modify",
  RegistrationFormLockUnlock: "acc:RegistrationForm:LockUnlock",
  RegistrationFormDelete: "acc:RegistrationForm:Delete",
  RegistrationFormDownloadReportCSV: "acc:RegistrationForm:DownloadReportCSV",
  RegistrationFormDownloadReportJSON: "acc:RegistrationForm:DownloadReportJSON",
  RegistrationFormDownloadReportXLSX: "acc:RegistrationForm:DownloadReportXLSX",
  RegistrationFormPublishPublicReport: "acc:RegistrationForm:PublishPublicReport",
  RegistrationFormGenerateIframe: "acc:RegistrationForm:GenerateIframe",
  // Statement
  StatementListView: "acc:Statement:ListView",
  StatementCreate: "acc:Statement:Create",
  StatementModify: "acc:Statement:Modify",
  StatementLockUnlock: "acc:Statement:LockUnlock",
  StatementDelete: "acc:Statement:Delete",
  // Statement Set
  StatementSetListView: "acc:StatementSet:ListView",
  StatementSetCreate: "acc:StatementSet:Create",
  StatementSetModify: "acc:StatementSet:Modify",
  StatementSetLockUnlock: "acc:StatementSet:LockUnlock",
  StatementSetDelete: "acc:StatementSet:Delete",
  // Survey Template
  SurveyTemplateListView: "acc:SurveyTemplate:ListView",
  SurveyTemplateCreate: "acc:SurveyTemplate:Create",
  SurveyTemplateModify: "acc:SurveyTemplate:Modify",
  SurveyTemplateLockUnlock: "acc:SurveyTemplate:LockUnlock",
  SurveyTemplateDelete: "acc:SurveyTemplate:Delete",
  SurveyTemplateAnswerLockUnlock: "acc:SurveyTemplate:AnswerLockUnlock",
  SurveyTemplateClone: "acc:SurveyTemplate:Clone",
  SurveyTemplateDownloadBody: "acc:SurveyTemplate:DownloadBody",
  SurveyTemplateUploadBody: "acc:SurveyTemplate:UploadBody",
};