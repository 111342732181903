import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditorComponent from "react-froala-wysiwyg";
// HOOKS
import useFroalaConfig from "@/shared/hooks/useFroalaConfig";
// Import all Froala Editor plugins;
import "froala-editor/js/plugins.pkgd.min.js";

// Import a language file.
import "froala-editor/js/languages/fr.js";
// STORE
import { getLocale } from "@/store/Settings/selectors";
// CONSTANTS
import { baseApiUrlV2, generateAuthHeaders } from "@/configs/MainConfig";


const FroalaEditor = ({
                        onChange,
                        value,
                        fullBar,
                        classes = {
                          container: "",
                          editor: "",
                        },
                        moreMiscButtons = [],
                        moreMiscButtonsVisible = 3,
                        heightMin = 100,
                        heightMax = 300,
                        height,
                        iframeStyle,
                        iframe = false,
                        onMaxCharNumberWasExceeded,
                        placeholder = "",
                        setCharCount,
                        charCounterMax = 500
                      }) => {
  const { locale } = useSelector(getLocale);
  const { validatePasteEvent } = useFroalaConfig({ onMaxCharNumberWasExceeded });


  let config = {
    // eslint-disable-next-line no-undef
    key: process.env.REACT_APP_FROALA_KEY,
    iframe,
    direction: "ltr",
    attribution: false,
    indentMargin: 0,
    linkStyles: {
      "fr-strong": "Thick"
    },
    toolbarVisibleWithoutSelection: true,
    toolbarInline: false,
    spellcheck: false,
    heightMin,
    heightMax,
    quickInsertTags: [],
    language: locale.locale,
    placeholderText: placeholder,
    pastePlain: true,
    wordPasteModal: false,
    wordPasteKeepFormatting: false,
    editorClass: classes.editor,
    iframeStyle,
    events: {
      "charCounter.update": function () {
        if (setCharCount) {
          setCharCount(this.charCounter?.count());
        }
      },
      ...validatePasteEvent
    },
    imageUploadParam: "file",
    imageUploadURL: `${baseApiUrlV2}/files/froala-upload-file/`,
    imageUploadMethod: "POST",
    imageAllowedTypes: ["jpeg", "jpg", "png", "gif", "webp", "svg+xml"],
    requestHeaders: generateAuthHeaders()
  };

  if (charCounterMax) {
    config.charCounterMax = charCounterMax;
  }

  if (height) {
    config.height = height;
  }

  if (!fullBar) {
    config = {
      ...config,
      toolbarButtons: {
        moreText: {
          buttons: [
            "bold",
            "italic",
            "underline",
          ],
          align: "left",
          buttonsVisible: 3,
        },
        moreParagraph: {
          buttons: ["alignLeft", "alignCenter", "formatOLSimple"],
          align: "left",
          buttonsVisible: 3,
        },
        moreRich: {
          buttons: ["insertLink"],
          align: "left",
          buttonsVisible: 1,
        },
        moreMisc: {
          buttons: ["undo", "redo", ...moreMiscButtons],
          align: "right",
          buttonsVisible: moreMiscButtonsVisible,
        }
      },
    };
  }

  return (
    <div className={classes.container}>
      <FroalaEditorComponent
        tag="div"
        config={config}
        model={value || ""}
        onModelChange={onChange}
        contenteditable={false}
      />
    </div>
  );
};


FroalaEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default FroalaEditor;