export const FETCH_ORGANIZATIONS_LIST = "FETCH_ORGANIZATIONS_LIST";
export const FETCH_ORGANIZATIONS_LIST_FOR_AUTOCOMPLETE = "FETCH_ORGANIZATIONS_LIST_FOR_AUTOCOMPLETE";
export const SET_ORGANIZATIONS_LIST_PAGINATION = "SET_ORGANIZATIONS_LIST_PAGINATION";

export const SET_ORGANIZATION_CAMPAIGNS_SORT_ORDER = "SET_ORGANIZATION_CAMPAIGNS_SORT_ORDER";
export const SET_ORGANIZATION_CAMPAIGNS_CARDS_PAGINATION = "SET_ORGANIZATION_CAMPAIGNS_CARDS_PAGINATION";
export const RESET_ORGANIZATION_CAMPAIGNS_PAGINATION = "RESET_ORGANIZATION_CAMPAIGNS_CARDS_PAGINATION";
export const SET_ORGANIZATION_REGISTRATIONS_VIEW_TYPE = "SET_ORGANIZATION_REGISTRATIONS_VIEW_TYPE";
export const SET_ORGANIZATION_REGISTRATIONS_PAGINATION = "SET_ORGANIZATION_REGISTRATIONS_PAGINATION";
export const RESET_ORGANIZATION_REGISTRATIONS_PAGINATION = "RESET_ORGANIZATION_REGISTRATIONS_PAGINATION";
export const SET_ORGANIZATION_REGISTRATIONS_SORT_BY = "SET_ORGANIZATION_REGISTRATIONS_SORT_BY";
export const SET_ORGANIZATION_CAMPAIGNS_FILTERS = "SET_ORGANIZATION_CAMPAIGN_FILTERS";
export const FETCH_ORGANIZATION_EXTENDED_INFO = "FETCH_ORGANIZATION_EXTENDED_INFO";
export const RESET_ORGANIZATION_SELECTED_ITEM = "RESET_ORGANIZATION_SELECTED_ITEM";

export const FETCH_ORGANIZATION_ADDRESSES_LIST = "FETCH_ORGANIZATION_ADDRESSES_LIST";
export const CREATE_ORGANIZATION_ADDRESS = "CREATE_ORGANIZATION_ADDRESS";
export const UPDATE_ORGANIZATION_ADDRESS = "UPDATE_ORGANIZATION_ADDRESS";
export const DELETE_ORGANIZATION_ADDRESS = "DELETE_ORGANIZATION_ADDRESS";

export const FETCH_ORGANIZATION_CAMPAIGNS_LIST = "FETCH_ORGANIZATION_CAMPAIGNS_LIST";
export const FETCH_ORGANIZATION_CAMPAIGNS_CARDS_LIST = "FETCH_ORGANIZATION_CAMPAIGNS_CARDS_LIST";
export const FETCH_ORGANIZATION_CAMPAIGNS_TABLE_LIST = "FETCH_ORGANIZATION_CAMPAIGNS_TABLE_LIST";
export const FETCH_ORGANIZATION_CAMPAIGNS_ASSESSMENTS = "FETCH_ORGANIZATION_CAMPAIGNS_ASSESSMENTS";
export const CREATE_ORGANIZATION_CAMPAIGN = "CREATE_ORGANIZATION_CAMPAIGN";
export const UPDATE_ORGANIZATION_CAMPAIGN = "UPDATE_ORGANIZATION_CAMPAIGN";
export const DELETE_ORGANIZATION_CAMPAIGN = "DELETE_ORGANIZATION_CAMPAIGN";
export const DELETE_ORGANIZATION_CAMPAIGN_CARD = "DELETE_ORGANIZATION_CAMPAIGN_CARD";
export const TOGGLE_ORGANIZATION_CAMPAIGN_MODAL = "TOGGLE_ORGANIZATION_CAMPAIGN_MODAL";
export const SET_ORGANIZATION_CAMPAIGN_PAGE_VIEW_TYPE = "SET_ORGANIZATION_CAMPAIGN_PAGE_VIEW_TYPE";
export const SET_ORGANIZATION_CAMPAIGN_PAGE_BAR_ADD_BUTTON_VISIBLE = "SET_ORGANIZATION_CAMPAIGN_PAGE_BAR_ADD_BUTTON_VISIBLE";

export const FETCH_ORGANIZATION_MEMBERS_LIST = "FETCH_ORGANIZATION_MEMBERS_LIST";
export const SET_ORGANIZATION_MEMBERS_SORT_ORDER = "SET_ORGANIZATION_MEMBERS_SORT_ORDER";
export const SET_ORGANIZATION_MEMBERS_PAGINATION = "SET_ORGANIZATION_MEMBERS_PAGINATION";
export const RESET_ORGANIZATION_MEMBERS_SORT_ORDER = "RESET_ORGANIZATION_MEMBERS_SORT_ORDER";
export const RESET_ORGANIZATION_MEMBERS_PAGINATION = "RESET_ORGANIZATION_MEMBERS_PAGINATION";
export const FETCH_ORGANIZATION_POTENTIAL_MEMBERS_LIST = "FETCH_ORGANIZATION_POTENTIAL_MEMBERS_LIST";
export const CREATE_ORGANIZATION_MEMBER = "CREATE_ORGANIZATION_MEMBER";
export const UPDATE_ORGANIZATION_MEMBER = "UPDATE_ORGANIZATION_MEMBER";
export const DELETE_ORGANIZATION_MEMBER = "DELETE_ORGANIZATION_MEMBER";
export const FETCH_ORGANIZATION_MEMBER_ROLE_TYPES = "FETCH_ORGANIZATION_MEMBER_ROLE_TYPES";
export const FETCH_ORGANIZATION_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER = "FETCH_ORGANIZATION_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER";

export const FETCH_ORGANIZATION_ASSESSMENTS = "FETCH_ORGANIZATION_ASSESSMENTS";
export const FETCH_ORGANIZATION_ASSESSMENTS_FOR_AUTOCOMPLETE = "FETCH_ORGANIZATION_ASSESSMENTS_FOR_AUTOCOMPLETE";

export const FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST = "FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST";
export const FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST_FOR_AUTOCOMPLETE = "FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST_FOR_AUTOCOMPLETE";
export const FETCH_ORGANIZATION_SURVEY_TEMPLATE_EXTENDED_INFO = "FETCH_ORGANIZATION_SURVEY_TEMPLATE_EXTENDED_INFO";
export const CREATE_ORGANIZATION_SURVEY = "CREATE_ORGANIZATION_SURVEY";
export const GET_ORGANIZATION_SURVEY_FOR_MANAGING = "GET_ORGANIZATION_SURVEY_FOR_MANAGING";
export const UPDATE_ORGANIZATION_SURVEY = "UPDATE_ORGANIZATION_SURVEY";
export const UPDATE_ORGANIZATION_SURVEY_BODY = "UPDATE_ORGANIZATION_SURVEY_BODY";
export const DELETE_ORGANIZATION_SURVEY = "DELETE_ORGANIZATION_SURVEY";
export const CLONE_ORGANIZATION_SURVEY = "CLONE_ORGANIZATION_SURVEY";
export const DOWNLOAD_ORGANIZATION_SURVEY = "DOWNLOAD_ORGANIZATION_SURVEY";
export const UPLOAD_ORGANIZATION_SURVEY = "UPLOAD_ORGANIZATION_SURVEY";
export const LOCK_ORGANIZATION_SURVEY = "LOCK_ORGANIZATION_SURVEY";
export const UNLOCK_ORGANIZATION_SURVEY = "UNLOCK_ORGANIZATION_SURVEY";
export const SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_PAGINATION = "SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_PAGINATION";
export const SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_ORDER = "SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_ORDER";

export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";

export const FETCH_ORGANIZATION_MEMBER_INVITATIONS_LIST = "FETCH_ORGANIZATION_MEMBER_INVITATIONS_LIST";
export const SET_ORGANIZATION_MEMBERS_INVITATIONS_PAGINATION = "SET_ORGANIZATION_MEMBERS_INVITATIONS_PAGINATION";
export const CREATE_ORGANIZATION_MEMBER_INVITATION = "CREATE_ORGANIZATION_MEMBER_INVITATION";
export const UPDATE_ORGANIZATION_MEMBER_INVITATION = "UPDATE_ORGANIZATION_MEMBER_INVITATION";
export const RESEND_ORGANIZATION_MEMBER_INVITATION = "RESEND_ORGANIZATION_MEMBER_INVITATION";

export const FETCH_ORGANIZATION_USERS_ROLES_LIST = "FETCH_ORGANIZATION_USERS_ROLES_LIST";
export const SET_ORGANIZATION_USERS_ROLES_PAGINATION = "SET_ORGANIZATION_USERS_ROLES_PAGINATION";
export const FETCH_ORGANIZATION_USER_ROLE_MULTI_LANG_INFO = "FETCH_ORGANIZATION_USER_ROLE_MULTI_LANG_INFO";
export const CREATE_ORGANIZATION_USER_ROLE = "CREATE_ORGANIZATION_USER_ROLE";
export const UPDATE_ORGANIZATION_USER_ROLE = "UPDATE_ORGANIZATION_USER_ROLE";
export const FETCH_ORGANIZATION_PERMISSIONS_LIST = "FETCH_ORGANIZATION_PERMISSIONS_LIST";
export const FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR = "FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR";
export const SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_ORDER = "SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_ORDER";
export const SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_PAGINATION = "SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_PAGINATION";
export const FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR_AUTOCOMPLETE = "FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR_AUTOCOMPLETE";
export const FETCH_USER_LIST_FOR_AUTOCOMPLETE = "FETCH_USER_LIST_FOR_AUTOCOMPLETE";
export const FETCH_AUDITORS_FOR_AUTOCOMPLETE = "FETCH_AUDITORS_FOR_AUTOCOMPLETE";
export const FETCH_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR = "FETCH_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR";
export const RESET_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR = "RESET_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR";

export const FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR = "FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR";
export const SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_PAGINATION = "SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_PAGINATION";
export const SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_FILTERS = "SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_FILTERS";

export const CREATE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR = "CREATE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR";
export const UPDATE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR = "UPDATE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR";
export const DELETE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR = "DELETE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR";
export const UPDATE_ASSESSMENT_ANSWER_STATUS_BY_ASSESSOR = "UPDATE_ASSESSMENT_ANSWER_STATUS_BY_ASSESSOR";
export const UPDATE_ASSESSMENT_ANSWER_STATUS_SINGULAR = "UPDATE_ASSESSMENT_ANSWER_STATUS_SINGULAR";
export const UPDATE_ASSESSMENT_ANSWER_NOTE_SINGULAR = "UPDATE_ASSESSMENT_ANSWER_NOTE_SINGULAR";
export const FETCH_ASSESSMENT_ANSWER_NOTE_SINGULAR = "FETCH_ASSESSMENT_ANSWER_NOTE_SINGULAR";

export const CLEAN_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR = "CLEAN_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR";

export const FETCH_ORGANIZATION_LOG_ACTION_TYPES = "FETCH_ORGANIZATION_LOG_ACTION_TYPES";
export const FETCH_ORGANIZATION_LOG = "FETCH_ORGANIZATION_LOG";
export const CLEAN_ORGANIZATION_LOG = "CLEAN_ORGANIZATION_LOG";
export const UPDATE_ORGANIZATION_LOG_FILTERS = "UPDATE_ORGANIZATION_LOG_FILTERS";
export const CLEAN_ORGANIZATION_LOG_FILTERS = "CLEAN_ORGANIZATION_LOG_FILTERS";

export const FETCH_ASSESSMENT_ITEMS = "FETCH_ASSESSMENT_ITEMS";
export const SET_ORGANIZATION_ASSESSMENTS = "SET_ORGANIZATION_ASSESSMENTS";
export const SET_ASSESSMENT_ITEMS_PAGINATION = "SET_ASSESSMENT_ITEMS_PAGINATION";
export const SET_ASSESSMENT_ITEMS_FILTERS = "SET_ASSESSMENT_ITEMS_FILTERS";
export const SET_ASSESSMENT_ITEMS_SORT_ORDER = "SET_ASSESSMENT_ITEMS_SORT_ORDER";

export const SET_AUDITOR_NOTES_SORT_ORDER = "SET_AUDITOR_NOTES_SORT_ORDER";

export const FETCH_CAMPAIGNS_FOR_AUTOCOMPLETE = "FETCH_CAMPAIGNS_FOR_AUTOCOMPLETE";
export const MANAGE_STATEMENTS_RELATIONS_TO_ASSESSMENT_ITEM = "MANAGE_STATEMENTS_RELATIONS_TO_ASSESSMENT_ITEM";

export const FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS = "FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS";
export const FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FOR_AUTOCOMPLETE = "FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FOR_AUTOCOMPLETE";
export const SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_PAGINATION = "SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_PAGINATION";
export const SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FILTERS = "SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FILTERS";
export const SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_SORT_ORDER = "SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_SORT_ORDER";

export const CREATE_ASSESSMENT_ITEMS_CUSTOM_FIELD = "CREATE_ASSESSMENT_ITEMS_CUSTOM_FIELD";
export const UPDATE_ASSESSMENT_ITEMS_CUSTOM_FIELD = "UPDATE_ASSESSMENT_ITEMS_CUSTOM_FIELD";
export const DELETE_ASSESSMENT_ITEMS_CUSTOM_FIELD = "DELETE_ASSESSMENT_ITEMS_CUSTOM_FIELD";
export const FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO = "FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO";
export const CLEAN_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO = "CLEAN_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO";

export const FETCH_ORGANIZATION_REGISTRATION_LIST = "FETCH_ORGANIZATION_REGISTRATION_LIST";
export const FETCH_ORGANIZATION_REGISTRATION_ITEM_MANAGE = "FETCH_ORGANIZATION_REGISTRATION_ITEM_MANAGE";
export const UPDATE_ORGANIZATION_REGISTRATION_ITEM_MANAGE = "UPDATE_ORGANIZATION_REGISTRATION_ITEM_MANAGE";
export const CREATE_ORGANIZATION_REGISTRATION_ITEM_MANAGE = "CREATE_ORGANIZATION_REGISTRATION_ITEM_MANAGE";
export const DELETE_ORGANIZATION_REGISTRATION_ITEM_MANAGE = "DELETE_ORGANIZATION_REGISTRATION_ITEM_MANAGE";
export const CLEAR_ORGANIZATION_REGISTRATION_ITEM_MANAGE = "CLEAR_ORGANIZATION_REGISTRATION_ITEM_INFO";
export const FETCH_ORGANIZATION_REGISTRATION_FEED_LIST = "FETCH_ORGANIZATION_REGISTRATION_FEED_LIST";
export const SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_PAGINATION = "SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_PAGINATION";
export const SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_SORT = "SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_SORT";
export const CLEAR_ORGANIZATION_REGISTRATION = "CLEAR_ORGANIZATION_REGISTRATION";
export const FETCH_ORGANIZATION_REGISTRATION_EXTENDED_BY_ID = "FETCH_ORGANIZATION_REGISTRATION_EXTENDED_BY_ID";
export const FETCH_ORGANIZATION_REGISTRATION_ANSWERS = "FETCH_ORGANIZATION_REGISTRATION_ANSWERS";
export const CLEAR_ORGANIZATION_REGISTRATION_ANSWERS = "CLEAR_ORGANIZATION_REGISTRATION_ANSWERS";
export const UPDATE_ORGANIZATION_REGISTRATION_FEED_ANSWER_SINGLE = "UPDATE_ORGANIZATION_REGISTRATION_FEED_ANSWER_SINGLE";
export const ACCEPT_ORGANIZATION_REGISTRATION_FEED_VERIFICATION = "ACCEPT_ORGANIZATION_REGISTRATION_FEED_VERIFICATION";
export const REJECT_ORGANIZATION_REGISTRATION_FEED_VERIFICATION = "REJECT_ORGANIZATION_REGISTRATION_FEED_VERIFICATION";
export const ORGANIZATION_REGISTRATION_FEED_SEND_TO_CRM = "ORGANIZATION_REGISTRATION_FEED_SEND_TO_CRM";
export const ORGANIZATION_REGISTRATION_GENERATE_REPORT = "ORGANIZATION_REGISTRATION_GENERATE_REPORT";
export const ORGANIZATION_REGISTRATION_GENERATE_PUBLISH_JSON = "ORGANIZATION_REGISTRATION_GENERATE_PUBLISH_JSON";
export const FETCH_ORGANIZATION_REGISTRATION_FOR_AUTOCOMPLETE = "FETCH_ORGANIZATION_REGISTRATION_FOR_AUTOCOMPLETE";

export const FETCH_ASSESSMENT_QUESTIONS_FOR_AUTOCOMPLETE = "FETCH_ASSESSMENT_QUESTIONS_FOR_AUTOCOMPLETE";

export const FETCH_USERS_RELATED_TO_ORGANIZATION = "FETCH_USERS_RELATED_TO_ORGANIZATION";
// HASHTAGS
export const FETCH_ASSESSMENT_NOTE_HASHTAGS_FOR_AUTOCOMPLETE = "FETCH_ASSESSMENT_NOTE_HASHTAGS_FOR_AUTOCOMPLETE";
// REFRESH PERMISSIONS
export const REFRESH_ORGANIZATION_PERMISSIONS = "REFRESH_ORGANIZATION_PERMISSIONS";
//SET THEME
export const SET_ORGANIZATION_THEME = "SET_ORGANIZATION_THEME";
//GET THEME
export const GET_ORGANIZATION_THEME = "GET_ORGANIZATION_THEME";
// SET DEFAULT THEME
export const SET_DEFAULT_THEME = "SET_DEFAULT_THEME";
// SET COLOR SCHEMA
export const SET_COLOR_SCHEMA = "SET_COLOR_SCHEMA";
//
export const GET_CHOICES_FOR_AUTOCOMPLETE = "GET_CHOICES_FOR_AUTOCOMPLETE";
//// ACCOUNT
export const SET_ACCOUNT_GENERAL_INFO = "SET_ACCOUNT_GENERAL_INFO";
export const RESET_ACCOUNT = "RESET_ACCOUNT";
