import React from "react";
import { withRouter } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import SidenavContent from "./SidenavContent";
import UserInfo from "./User/UserInfo";
import CustomScrollbars from "../../utils/CustomScrollbars";
import { updateWindowWidth, toggleCollapsedNav } from "../../store/Settings/actions";
import { connect } from "react-redux";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "../../store/Settings/types";
import IntlMessages from "../../utils/IntlMessages";
import styles from "./styles.module.scss";

const SideNav = (props) => {

  const onToggleCollapsedNav = () => {
    const val = !props.navCollapsed;
    props.toggleCollapsedNav(val);
  };

  let drawerStyle = props.drawerType.includes(FIXED_DRAWER) ? "d-xl-flex" : props.drawerType.includes(COLLAPSED_DRAWER) ? "" : "d-flex";

  let type = "permanent";

  if (props.drawerType.includes(COLLAPSED_DRAWER) || (props.drawerType.includes(FIXED_DRAWER) && props.windowWidth < 1200)) {
    type = "temporary";
  }

  return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes("temporary") ? props.navCollapsed : true}
          onClose={onToggleCollapsedNav}
          classes={{
            paper: "side-nav",
          }}
        >
          <CustomScrollbars className="app-custom-scrollbar side-nav-scrollbar-wrapper">
            <UserInfo/>
            <SidenavContent/>
            <div className="app-sidebar-description">
              <div className='license'>
                <span>
                  <IntlMessages
                    id='footer.license'
                    values={{
                      link: value => (
                        <a href='https://changeagents.com.au/'
                           target="_blank"
                           className={`link ${styles.footerLink}`}
                           rel="noreferrer"
                        >
                          {value}
                        </a>
                      ),
                      currentYear: new Date().getFullYear()
                    }}
                  />
                </span>
              </div>
            </div>
          </CustomScrollbars>
        </Drawer>
      </div>
  );
};

const mapStateToProps = (state) => ({
  windowWidth: state.settings.windowWidth,
  navCollapsed: state.settings.navCollapsed,
  drawerType: state.settings.drawerType,
});

const mapDispatchToProps = {
  updateWindowWidth,
  toggleCollapsedNav
};

SideNav.displayName = "SideNav";
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideNav));
