import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CardBox from "./CardBox";
import CardSection from "./CardSection";
import QuestionAnswer from "./QuestionAnswer";
import IntlMessages from "../../../../utils/IntlMessages";
import useAssessmentSectionsNames from "./useAssessmentSectionsNames";

const CardAssessmentQuestions = React.forwardRef(function CardAssessmentQuestions({
                                                                                    title,
                                                                                    data,
                                                                                    exportImage,
                                                                                    windowWidth,
                                                                                    keyForSavingCardState
                                                                                  }, ref) {
  const sectionsNames = useAssessmentSectionsNames();
  const preparedQuestions = data?.reduce((acc, item) => {
    const sectionId = item?.topic_id;
    let newArr;

    if (acc[sectionId]) {
      newArr = acc[sectionId].concat(item);
    } else {
      newArr = [item];
    }

    return {
      ...acc,
      [sectionId]: newArr,
    };

  }, {});

  return (
    <CardBox
      heading={title}
      exportImage={exportImage}
      ref={ref}
      keyForSavingCardState={keyForSavingCardState}
    >
      {data?.length > 0
        ? (
          <div className="report-card__body__container">
            {Object.entries(preparedQuestions).map(([sectionId, sectionQuestions]) => (
              <CardSection
                heading={sectionsNames[sectionId]}
                key={sectionId}
                keyForSavingCardState={keyForSavingCardState}
                keyForSavingSectionState={sectionId}
              >
                {sectionQuestions.map((question) => (
                    <QuestionAnswer
                      key={question.question_id}
                      question={question}
                    />
                  )
                )}
              </CardSection>
            ))}
          </div>
        ) : (
          <div className="report-card__no-content"><IntlMessages id="reports.noContent"/></div>
        )}
    </CardBox>
  );
});

CardAssessmentQuestions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  exportImage: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  keyForSavingCardState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  windowWidth: state.settings.windowWidth,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(CardAssessmentQuestions);