import React from "react";
import classnames from "classnames";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// CONSTANTS
import { TAB_PROFILE } from "../../../../constants";
// STYLES
import styles from "../../styles.module.scss";

const SideMenuTabs = ({
                        currentTab,
                        changeCurrentTab
                      }) => {

  const profileTabStiles = classnames({
    [styles.SideMenuTab]: true,
    [styles.Active]: currentTab === TAB_PROFILE
  });

  const onChangeTab = (newTab) => () => {
    changeCurrentTab(newTab);
  };

  return (
    <div className={styles.SideMenuTabsContainer}>
      <div className={profileTabStiles} onClick={onChangeTab(TAB_PROFILE)}>
        <IntlMessages id="user.profile"/>
      </div>
    </div>
  );
};

export default SideMenuTabs;
