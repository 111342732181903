import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
// HOOKS
import useInitialValues from "./hooks/useInitialValues";
import useValidationSchema from "./hooks/useValidationSchema";
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// UTILS
import { useIntlMessageToString } from "@/utils/IntlMessages";
// COMPONENTS
import AsrLogo from "@/components/Common/AsrLogo";
import AsrInput from "@/components/Common/FormComponentsNew/AsrInput";
import AsrSaveButton from "@/shared/components/Buttons/AsrSaveButton";
import EnterCodeWrongCodeOverlay from "./components/EnterCodeWrongCodeOverlay";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
// STORE
import { joinCampaignBase } from "@/store/Landing/actions";
// STYLES
import styles from "./styles.module.scss";


const EnterCodeAuthModalContent = ({ setShowDashedContentBorder }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const formRef = useRef();
  const [showWrongCodeMessage, setShowWrongCodeMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const modalToggleHandlers = useToggleCommonModalHandlers();

  const toggleShowWrongMessage = () => {
    setShowDashedContentBorder(showWrongCodeMessage);
    setShowWrongCodeMessage(!showWrongCodeMessage);
  };

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    try {
      const requestBody = { passcode: values.passcode };
      const {
        payload: {
          response: {
            results: {
              campaign,
              reg_form: regForm
            }
          }
        }
      } = await dispatch(joinCampaignBase(requestBody));
      setIsLoading(false);

      modalToggleHandlers.enterCampaignPasscode({ show: false });
      if (regForm) {
        history.push({
          pathname: `/public/registration/${regForm.cid}/`,
          state: {
            accessKey: regForm.key
          }
        });
      } else {
        modalToggleHandlers.signUp({ show: true, campaignName: campaign?.name });
      }
    } catch (e) {
      setIsLoading(false);
      toggleShowWrongMessage();
    }
  };

  const onCloseWrongCodeOverlayMessage = async () => {
    toggleShowWrongMessage();
    if (formRef?.current) {
      const fmk = formRef.current;
      await fmk.setFieldValue("passcode", "");
      await fmk.setFieldTouched("passcode", false);
    }
  };

  const enterCodeText = useIntlMessageToString("appModule.enterCode");

  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  return (
    <div className={styles.EnterCodeModalContentContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formRef}
      >
        <Form>
          <div className={styles.FormContent}>
            <AsrLogo
              redirectOnClick={false}
              classes={{ logo: styles.AsrModalLogo }}
            />

            <div className={styles.EnterCodeSubmitFormWrapper}>
              <AsrInput
                name="passcode"
                placeholder={enterCodeText}
                classes={{
                  input: styles.EnterCodeInput,
                  error: styles.EnterCodeInputError
                }}
              />

              <AsrSaveButton
                type='submit'
                classes={{
                  buttonRoot: styles.SubmitButton
                }}
                label={"appModule.submit"}
              >
              </AsrSaveButton>
            </div>
          </div>
        </Form>
      </Formik>

      {showWrongCodeMessage && (
        <EnterCodeWrongCodeOverlay onClose={onCloseWrongCodeOverlayMessage}/>
      )}

      {isLoading && (
        <AsrCommonLoader/>
      )}
    </div>
  );
};

export default EnterCodeAuthModalContent;
