import React from "react";
import * as Yup from "yup";
import IntlMessages from "@/utils/IntlMessages";

const useValidationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email(<IntlMessages id='appModule.emailInvalid'/>)
      .required(<IntlMessages id='appModule.required'/>),
    firstName: Yup.string()
      .required(<IntlMessages id='appModule.required'/>),
    lastName: Yup.string()
      .required(<IntlMessages id='appModule.required'/>),
    password: Yup.string()
      .min(6, <IntlMessages id="appModule.mustBeAtLeastMinChars" values={{ count: 6 }}/>)
      .required(<IntlMessages id='appModule.required'/>),
    passwordRepeat: Yup.string().when("password", {
      is: val => (val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        <IntlMessages id='appModule.passwordsNotEqual'/>
      )
    }).required(<IntlMessages id='appModule.required'/>),
    agreement: Yup.bool()
      .oneOf([true], <IntlMessages id='appModule.required'/>)
  });
};

export default useValidationSchema;
