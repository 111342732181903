import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
// HELPERS
import NestedLinks from "../../../../../../../helpers/NestedLinks/NestedLinks";


const UsersNestedLinks = ({
                            props,
                            canCampaignParticipantListView,
                            canCampaignInvitationListView
                          }) => {
  const match = useRouteMatch();


  const list = [
    {
      to: `${match.url}/members#members`,
      hash: "#members",
      onClick: props.closeNavCallBack,
      isVisible: canCampaignParticipantListView,
      label: "sidenav.ParticipantsList"
    },
    {
      to: `${match.url}/members#invitations`,
      hash: "#invitations",
      onClick: props.closeNavCallBack,
      isVisible: canCampaignInvitationListView,
      label: "campaign.userInvitations"
    },
    // {
    //   to: `${match.url}/members#assignments`,
    //   hash: "#assignments",
    //   onClick: props.closeNavCallBack,
    //   isVisible: canSeeAssignmentsList,
    //   label: "campaign.auditorAssignments"
    // },
  ];

  return (
      <Route path={`${match.path}/members`}>
        <NestedLinks links={list}/>
      </Route>
  );
};

export default UsersNestedLinks;