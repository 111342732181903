// don't use absolute import. Webpack's initialization error
import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const registrationAct = {
  [actionsConstants.RegistrationActListView]: simpleValidator,
  [actionsConstants.RegistrationActModify]: simpleValidator,
  [actionsConstants.RegistrationActModifyStatus]: simpleValidator,
  [actionsConstants.RegistrationActArchive]: simpleValidator,
  [actionsConstants.RegistrationActDelete]: simpleValidator,
  [actionsConstants.RegistrationActAnswerVerify]: simpleValidator,
  [actionsConstants.RegistrationActAnswerNoteLockUnlock]: simpleValidator,
  [actionsConstants.RegistrationActAnswerNoteView]: simpleValidator,
  [actionsConstants.RegistrationActAnswerModify]: simpleValidator,
  [actionsConstants.RegistrationActAnswerNoteCreate]: simpleValidator,
  [actionsConstants.RegistrationActAnswerNoteModify]: simpleValidator,
  [actionsConstants.RegistrationActAnswerNoteDelete]: simpleValidator,
  [actionsConstants.RegistrationActDownloadReportXLSX]: simpleValidator,
};

export default registrationAct;