// AUDITOR CONTROLS
export const SET_SURVEY_AUDITOR_CONTROLS = "SET_SURVEY_AUDITOR_CONTROLS";
export const RESET_SURVEY_AUDITOR_CONTROLS = "RESET_SURVEY_AUDITOR_CONTROLS";
export const MODIFY_SURVEY_AUDITOR_QUESTION_PANEL = "MODIFY_SURVEY_AUDITOR_QUESTION_PANEL";
export const SURVEY_HEADER_PANEL_TOGGLE_NOTE = "SURVEY_HEADER_PANEL_TOGGLE_NOTE";
export const SET_SURVEY_HEADER_PANEL_TOGGLE_NOTE = "SET_SURVEY_HEADER_PANEL_TOGGLE_NOTE";
export const SURVEY_HEADER_PANEL_TOGGLE_RECOMMENDATIONS = "SURVEY_HEADER_PANEL_TOGGLE_RECOMMENDATIONS";
export const RESET_SURVEY_ASSESSMENT_ANSWER = "RESET_SURVEY_ASSESSMENT_ANSWER";
// ANALYTICS
export const SURVEY_HEADER_PANEL_TOGGLE_ANALYTICS = "SURVEY_HEADER_PANEL_TOGGLE_ANALYTICS";
export const FETCH_SURVEY_ANALYTICS = "FETCH_SURVEY_ANALYTICS";

export const ADD_SURVEY_ANALYTICS_ID = "ADD_SURVEY_ANALYTICS_ID";
export const REMOVE_SURVEY_ANALYTICS_ID = "REMOVE_SURVEY_ANALYTICS_ID";
export const CLEAR_SURVEY_ANALYTIC_IDS = "CLEAR_SURVEY_ANALYTIC_IDS";
export const SET_CURRENT_SURVEY_ANALYTICS = "SET_CURRENT_SURVEY_ANALYTICS";
export const CLEAR_SURVEY_ANALYTICS = "CLEAR_SURVEY_ANALYTICS";

export const UPDATE_SURVEY_ANSWER_INFO_V2 = "UPDATE_SURVEY_ANSWER_INFO_V2";

export const MOUNT_SURVEY_VIEWER = "MOUNT_SURVEY_VIEWER";
export const UNMOUNT_SURVEY_VIEWER = "UNMOUNT_SURVEY_VIEWER";
export const SET_SURVEY_VIEWER_LANGUAGE = "SET_SURVEY_VIEWER_LANGUAGE";