// SERVICES
import ReactDomFactory from "@/shared/helpers/ReactDomFactory";
// COMPONENTS
import SurveyQuestionCardFooter from "@/components/SurveyJs/components/ViewerV2/components/SurveyQuestionCardFooter";
import SurveyQuestionAuditorActions
  from "@/components/SurveyJs/components/ViewerV2/components/SurveyQuestionAuditorActions";
import SurveyQuestionHeader from "@/components/SurveyJs/components/ViewerV2/components/QuestionHeader";
// CONSTANTS
import { QUESTION_CONTROL_MODE } from "@/shared/constants/surveyJs";

class SurveyDomBuilderService {
  auditorTargetEl = [];
  footerTargetEl = [];

  // ADD CUSTOM HEADER
  initCustomHeader(args, config) {
    const { options } = config;
    const {
      questionHeader,
      mode = QUESTION_CONTROL_MODE.auditor,
    } = args;
    const props = {
      ...args,
      title: options.question.title,
      no: options.question.no,
      description: options.question.description,
      isRequired: options.question.isRequired,
      descriptionLocation: options.question.descriptionLocation,
      question_id: options.question.propertyHash.question_id,
      mode,
      questionName: options.question.propertyHash.name,
      question: options.question
    };
    ReactDomFactory.inject(SurveyQuestionHeader, questionHeader, props);
  }

  //  ADD FOOTER SECTION TO THE QUESTION
  initFooter(args, config) {
    const { questionHeader, itemsServiceInfo } = args;
    const { options } = config;
    const questionFooter = document.createElement("div");
    questionFooter.classList.add("survey-question-card-footer");
    questionFooter.id = options.question.propertyHash.question_id;
    // hide actions if no answer
    if (!questionHeader) {
      questionFooter.style.display = "none";
    }

    if (!options.htmlElement.querySelector(".survey-question-card-footer")) {
      options.htmlElement.append(questionFooter);
    }
    const footer = options.htmlElement.querySelector(".survey-question-card-footer");
    this.footerTargetEl.push(footer);
    const props = {
      questionId: options.question.propertyHash.question_id,
      itemsServiceInfo
    };
    ReactDomFactory.inject(SurveyQuestionCardFooter, footer, props);
  }

  //  ADD AUDITOR SECTION TO THE QUESTION
  initAuditorSection(args, config) {
    const { options } = config;
    const {
      questionHeader,
    } = args;

    const question_id = options.question.propertyHash.question_id;

    const questionAuditorSection = document.createElement("div");
    questionAuditorSection.classList.add("survey-question-card-auditor");
    // hide actions if no answer
    if (!questionHeader) {
      questionAuditorSection.style.display = "none";
    }
    if (!options.htmlElement.querySelector(".survey-question-card-auditor")) {
      options.htmlElement.append(questionAuditorSection);
    }
    const auditor = options.htmlElement.querySelector(".survey-question-card-auditor");
    this.auditorTargetEl.push(auditor);
    const props = {
      ...args,
      question_id,
    };
    ReactDomFactory.inject(SurveyQuestionAuditorActions, auditor, props);
  }

  // INJECT ELEMENT
  injectElement(el, target, props) {
    ReactDomFactory.inject(el, target, props);
  }

  dispose() {
    const questionHeader = document.querySelectorAll(".survey-question-card-header");
    ReactDomFactory.dispose(this.footerTargetEl);
    ReactDomFactory.dispose(this.auditorTargetEl);
    ReactDomFactory.dispose(questionHeader);
  }
}

export default SurveyDomBuilderService;