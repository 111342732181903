import React from "react";
import { Route, withRouter } from "react-router-dom";
import SideNavOrganization from "./SideNavOrganization/SideNavOrganization";
import SideNavCampaign from "./SideNavCampaign/SideNavCampaign";
import SideNavMain from "../SideNavMain";

const SideNavRoutes = (props) => {
  return (
      <>
        <Route path="/profile/">
          <SideNavMain {...props}/>
        </Route>

        <Route path="/organizations/">
          <SideNavMain {...props}/>
        </Route>
        {/* STATEMENTS */}
        <Route path="/statements">
          <SideNavMain {...props}/>
        </Route>
        {/* LABELS */}
        <Route path="/labels">
          <SideNavMain {...props}/>
        </Route>
        {/* PAGES */}
        <Route path="/static-editor">
          <SideNavMain {...props}/>
        </Route>
        {/* LOGS */}
        <Route path="/log">
          <SideNavMain {...props}/>
        </Route>
        {/* REPORTS  */}
        <Route path="/reports">
          <SideNavMain {...props}/>
        </Route>
        {/* USERS */}
        <Route path="/users">
          <SideNavMain {...props}/>
        </Route>

        <Route path="/organization/:organizationId">
          <SideNavOrganization {...props}/>
        </Route>

        <Route path="/privacy/">
          <SideNavMain {...props}/>
        </Route>

        <Route path="/terms-of-use/">
          <SideNavMain {...props}/>
        </Route>

        <Route path="/campaign/:campaignId">
          <SideNavCampaign {...props}/>
        </Route>

        <Route path="/my-account/">

        </Route>

        <Route path="/">

        </Route>
      </>
  );
};

SideNavRoutes.displayName = "SideNavRoutes";
export default withRouter(SideNavRoutes);