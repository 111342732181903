export const imageSourceType = {
  LINK: "link",
  UPLOADED_FILE: "uploaded_file"
};

export const formMode = {
  CREATE: "Create",
  MODIFY: "Modify",
};

export const IMAGE_FILE_MAX_SIZE = 1048576;