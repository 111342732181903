import * as SurveyCore from "survey-core";
import * as widgets from "surveyjs-widgets";
import $ from "jquery";
// CONSTANTS
import { QUESTION_PROPERTIES } from "@/shared/constants/surveyJs";
import topicIdChoices from "@/components/Organization/SurveyCreator/data/topicIdChoices";
// WIDGETS
import { noUiSliderWidget } from "@/components/SurveyJs/widgets/noUiSliderWidget";
import { countriesWidget } from "@/components/SurveyJs/widgets/countriesWidget";
// import ckEditorWidget from "@/components/SurveyJs/widgets/ckEditorWidget";
// import radioGroupWidget from "@/components/SurveyJs/widgets/radiogroupWidget";
import sortablejsWidget from "@/components/SurveyJs/widgets/sortablelistWidget";

// STYLES
import "survey-react/survey.css";
import "survey-core/modern.css";
import "jquery-ui/themes/base/all.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "pretty-checkbox/dist/pretty-checkbox.css";
import "survey-core/defaultV2.min.css";
import "@/components/SurveyJs/styles/stylesV2/index.scss";

window["$"] = window["jQuery"] = $;

// WIDGETS
widgets.prettycheckbox(SurveyCore);
widgets.select2(SurveyCore, $);
widgets.inputmask(SurveyCore);
widgets.jquerybarrating(SurveyCore, $);
widgets.jqueryuidatepicker(SurveyCore, $);
widgets.select2tagbox(SurveyCore, $);
// widgets.ckeditor(SurveyCore);
widgets.autocomplete(SurveyCore, $);
widgets.bootstrapslider(SurveyCore);
sortablejsWidget(SurveyCore);
// radioGroupWidget(SurveyCore);
noUiSliderWidget(SurveyCore);
countriesWidget(SurveyCore);
// ckEditorWidget();


SurveyCore.StylesManager.applyTheme("defaultV2");
// SurveyCore.settings.lazyRowsRendering = true;
SurveyCore.Serializer.addProperty("question", {
  name: QUESTION_PROPERTIES.QUESTION_ID,
  category: "general",
  readOnly: true,
  visibleIndex: 0
});
SurveyCore.Serializer.addProperty("question", {
  name: QUESTION_PROPERTIES.LOCK_ANSWER,
  isLocalizableValue: true,
  type: "switch",
  typeValue: "switch",
  category: "general",
  visibleIndex: 4
});
SurveyCore.Serializer.addProperty("question", {
  name: QUESTION_PROPERTIES.TOPIC_ID,
  choices: topicIdChoices,
  category: "general"
});
