import {
  ALLOW_ALL
} from "../../../../../components/Organization/AccountMembers/Table/MemberPoliciesTable/forms/MemberPoliciesForm/components/sections/CommonSections/constants";

const allOwnedCampaignsValidator = ({ lookup, isOwner, policySettings }) => {
  let hasPermission = false;
  const campaign = lookup?.campaign;

  const policySettingsFiltered = policySettings.filter((item) => {
    return (
      item?.campaigns === ALLOW_ALL
      || item?.campaigns.includes(campaign)
      || item?.campaigns.includes(Number(campaign))
    );
  });

  for (const policySetting of policySettingsFiltered) {
    if (policySetting.all) {
      hasPermission = true;
      break;
    }

    if (policySetting.owned && isOwner) {
      hasPermission = true;
      break;
    }
  }
  return hasPermission;
};

export default allOwnedCampaignsValidator;