import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// MATERIAL UI
import Avatar from "@mui/material/Avatar";
// COMPONENTS
import AsrSvgIcon from "@/shared/components/AsrSvgIcon";
// STYLES
import styles from "./styles.module.scss";


/***
 Used for showing user's avatar or assessor's default avatar
 */
const AsrAvatar = ({
                     src,
                     alt,
                     rounded = false,
                     bordered = false,
                     classes = {
                       root: ""
                     },
                     onError,
                     children
                   }) => {
  // const [isSrcValid, setIsSrcValid] = useState(true);

  const avatarRootStyles = classnames({
    [styles.Avatar]: true,
    [styles.Rounded]: rounded,
    [styles.Bordered]: bordered,
    // [styles.Error]: !isSrcValid,
    [classes?.root]: Boolean(classes?.root)
  });

  const onErrorHandler = () => {
    // setIsSrcValid(false);
    onError && onError();
  };

  return (
    <>
      {/* SHOW AVATAR */}
      {src && (
        <Avatar
          variant="rounded"
          alt={alt}
          src={src}
          className={avatarRootStyles}
          imgProps={{
            onError: onErrorHandler
          }}
        >
          {children ? (
            <>{typeof children === "string" ? children.charAt(0)?.toUpperCase() : children}</>
          ) : (
            <AsrSvgIcon
              classes={{
                asrSvgIconContainer: avatarRootStyles
              }}
              icon="logo_placeholder"
            />
          )}
        </Avatar>
      )}

      {/* SHOW DEFAULT ASSESSOR LOGO IMAGE */}
      {!src && (
        <AsrSvgIcon
          classes={{
            asrSvgIconContainer: avatarRootStyles
          }}
          icon="logo_placeholder"
        />
      )}
    </>
  );
};

AsrAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  rounded: PropTypes.bool,
  bordered: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string
  }),
  onError: PropTypes.func
};
export default AsrAvatar;
