import { createSelector } from "reselect";
import {
  SIGN_UP_USER_BASIC,
  SIGN_UP_USER_BY_PASSCODE,
  SIGN_IN_USER_BASIC,
  SIGN_IN_USER_BY_PASSCODE,
  EMAIL_VERIFYING
} from "./types";

export const userState = (state) => state.auth;
export const networkState = (state) => state.network;

export const getIsSuperUser = createSelector(userState, (state) => state.currentUser?.meta?.is_superuser);

export const getUserId = createSelector(userState, (state) => state.currentUser?.id);
export const getIsUserAuthenticated = createSelector(userState, (state) => Boolean(state.currentUser?.id));

export const getUserSystemPermissions = createSelector(userState, (state) => state.currentUser?.permissions?.system);
export const getUserAllowedStatementsLocales = createSelector(userState, (state) => {
  const userSystemPermissions = state.currentUser?.permissions?.system;
  const editStatementLanguagePermission = userSystemPermissions?.["user.global_statements.modify_translations"];
  if (!editStatementLanguagePermission) return [];
  return editStatementLanguagePermission.languages || [];
});
export const getCurrentUser = createSelector(userState, (state) => state.currentUser);
export const getAuthErrors = createSelector(userState, (state) => state.errors);

// NETWORK
export const getSignUpUserBasicLoading = createSelector(networkState, (state) => Boolean(state?.mutations[SIGN_UP_USER_BASIC]?.pending));
export const getSignUpUserByPassCodeLoading = createSelector(networkState, (state) => Boolean(state?.mutations[SIGN_UP_USER_BY_PASSCODE]?.pending));
export const getSignInUserBasicLoading = createSelector(networkState, (state) => Boolean(state?.mutations[SIGN_IN_USER_BASIC]?.pending));
export const getSignInUserByPassCodeLoading = createSelector(networkState, (state) => Boolean(state?.mutations[SIGN_IN_USER_BY_PASSCODE]?.pending));
export const getEmailVerifyingLoading = createSelector(networkState, (state) => Boolean(state?.mutations[EMAIL_VERIFYING]?.pending));