export const campaignParticipantsTargets = {
  TABLE: "participants-table",
  CARD: "participants-cards",
  ADD_EXISTING_USER: "add-existing-user",
  ADD_NEW_USER: "add-new-user",
};

export const campaignParticipantsQueryArg = {
  ASSESSMENTS_RESULTS: "assessments_results"
};

export const campaignParticipantsTags = {
  CAMPAIGN_PARTICIPANTS: "campaign-participants",
  CAMPAIGN_PARTICIPANT: "campaign-participant",
};