import React from "react";
import * as Yup from "yup";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// CONSTANTS
import { MAX_MESSAGE_CHARS, MIN_MESSAGE_CHARS } from "./constants";


export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, <IntlMessages id='appModule.mustBeAtLeastMinChars' values={{ count: 2 }}/>)
    .max(255, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: 255 }}/>)
    .required(<IntlMessages id='appModule.required'/>),
  last_name: Yup.string()
    .min(2, <IntlMessages id='appModule.mustBeAtLeastMinChars' values={{ count: 2 }}/>)
    .max(255, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: 255 }}/>)
    .required(<IntlMessages id='appModule.required'/>),
  email: Yup.string()
    .email(<IntlMessages id='appModule.emailInvalid'/>)
    .max(255, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: 255 }}/>)
    .required(<IntlMessages id='appModule.required'/>),
  message: Yup.string()
    .min(MIN_MESSAGE_CHARS, <IntlMessages id='appModule.mustBeAtLeastMinChars' values={{ count: MIN_MESSAGE_CHARS }}/>)
    .max(MAX_MESSAGE_CHARS, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: MAX_MESSAGE_CHARS }}/>)
    .required(<IntlMessages id='contactUs.emptyNotSend'/>),
  agreement: Yup.bool()
    .oneOf([true], <IntlMessages id='appModule.consentRequired'/>)
    .required(<IntlMessages id='appModule.required'/>)
});