import { authService } from "@/shared/services/auth.service";
import { endpointsWithoutAuthorization } from "./data";

const prepareHeaders = (headers, { endpoint }) => {
  Object.entries(authService.getAuthHeaders()).forEach(([key, value]) => {
    headers.set(key, value.toString());
  });

  if (endpointsWithoutAuthorization.includes(endpoint)) {
    headers.delete("Authorization");
  }

  headers.delete("Content-Type");

  return headers;
};

export default prepareHeaders;