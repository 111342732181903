export const FETCH_DATASET_LIST = 'FETCH_DATASET_LIST';
export const CREATE_DATASET = 'CREATE_DATASET';
export const PUSH_DATA_TO_DATASET = 'PUSH_DATA_TO_DATASET';
export const DELETE_DATASET = 'DELETE_DATASET';
export const SIGN_IN_POWERBI = 'SIGN_IN_POWERBI';
export const ADD_REPORT_TO_DATASET = 'ADD_REPORT_TO_DATASET';
export const DELETE_REPORT = 'DELETE_REPORT';
export const FETCH_DATASET_EXTENDED_INFO = 'FETCH_DATASET_EXTENDED_INFO';
export const FETCH_REPORT_EXTENDED_INFO = 'FETCH_REPORT_EXTENDED_INFO';
export const UPDATE_DATASET = 'UPDATE_DATASET';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const LOCK_UNLOCK_DATASET = 'LOCK_UNLOCK_DATASET';
export const LOCK_UNLOCK_REPORT = 'LOCK_UNLOCK_REPORT';
export const CLEAN_DATASET_EXTENDED_INFO = 'CLEAN_DATASET_EXTENDED_INFO';
export const CLEAN_REPORT_EXTENDED_INFO = 'CLEAN_REPORT_EXTENDED_INFO';