import React from "react";
import { Form, Formik } from "formik";
// COMPONENTS
import AsrUploadImageV2 from "@/shared/components/FormComponents/UploadImage/AsrUploadImageV2";
// HOOKS
import useGenerateInitialData from "../hooks/useGenerateInitialData";
// CONSTANTS
import { imageSourceType } from "@/shared/constants/forms";
// STYLES
import styles from "../styles.module.scss";


const AsrChangeImageModalForm = ({
                                   formRef,
                                   imageData,
                                   onSubmit
                                 }) => {

  const initialValues = useGenerateInitialData({ imageData });

  const onSubmitLocalHandler = (values) => {
    const image = values?.image;
    let imageSource;

    if (typeof image === "string") {
      imageSource = image === values?.imageContent?.url ? values?.imageContent?.source : imageSourceType.LINK;
    } else if (image instanceof File) {
      imageSource = imageSourceType.UPLOADED_FILE;
    } else {
      imageSource = values?.imageContent?.source ?? imageSourceType.LINK;
    }

    onSubmit && onSubmit(image, imageSource);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitLocalHandler}
      innerRef={formRef}
    >
      {fmk => (
        <Form className={styles.ModalContentContainer}>
          <AsrUploadImageV2
            name="image"
            logo={fmk.values?.imageContent}
            error={fmk?.errors?.image?.url}
            setFieldValue={fmk.setFieldValue}
            imageRounded={true}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AsrChangeImageModalForm;
