import { QUESTION_CONTROL_MODE } from "@/shared/constants/surveyJs";
import { emptyNote } from "@/components/SurveyJs/constants";
import { initCustomHeader } from "@/shared/Survey/helpers/question/initCustomHeader";


const addUserControls = ({
                           options,
                           state: {
                             questionData
                           } = {},
                           props: {
                             progressInfo,
                             permissions,
                             surveyDomBuilder,
                             parentTarget
                           }
                         }) => {
  const sjsAnswers = progressInfo?.object_answers || [];
  const questionName = options.question.propertyHash.name;
  const questionId = options.question.propertyHash.question_id;

  const sjsAnswerObj = sjsAnswers.find(item => {
    return item.question_id?.toString() === questionId?.toString();
  }) || {};
  //  NOTE STATE
  const noteFromLocalState = questionData[questionName]?.note;
  const currentNote = noteFromLocalState || sjsAnswerObj.note || { ...emptyNote };

    /*
  *  HEADER
  * */
  initCustomHeader({
    options,
    surveyDomBuilder,
    actionsOptions: {
      mode: QUESTION_CONTROL_MODE.user,
      canModifyAssessorNotes: permissions?.canModifyItemNotes,
      canViewAssessorNotes: permissions?.canViewItemNotes,
      currentNote,
      questionId,
      assessmentId: parentTarget?.id,
      assessmentType: parentTarget?.type
    }
  });
};

export default addUserControls;