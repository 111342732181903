import classnames from "classnames";
// STYLES
import styles from "../styles.module.scss";

const useStyles = ({ classes } = {}) => {

  const rootStyles = classnames({
    [styles.LanguageBox]: true,
    [classes?.root]: Boolean(classes?.root)
  });

  const labelStyles = classnames({
    [styles.LanguageLabel]: true,
    "app-header__language-current": true,
    [classes?.label]: Boolean(classes?.label)
  });

  const iconStyles = classnames({
    "icon": true,
    "text-center": true,
    "app-header__language-icon__wrapper": true,
    [classes?.icon]: Boolean(classes?.icon)
  });

  const dropdownStyles = classnames({
    "quick-menu": true,
    [styles.Dropdown]: true
  });

  const dropdownToggleStyles = classnames({
    [styles.DropdownToggle]: true,
    [classes?.dropdownToggle]: Boolean(classes?.dropdownToggle)
  });

  return {
    root: rootStyles,
    label: labelStyles,
    icon: iconStyles,
    dropdown: dropdownStyles,
    dropdownToggle: dropdownToggleStyles
  };
};

export default useStyles;