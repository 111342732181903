import classnames from "classnames";
import styles from "../styles.module.scss";


const useClasses = (classes) => {
  const classnamesContainer = classnames({
    [styles.AsrSvgIcon]: true,
    [classes.asrSvgIconContainer]: classes.asrSvgIconContainer
  });

  const tooltipPopper = classnames({
    "asr-table-popper": true,
    [classes.asrSvgPopper]: classes.asrSvgPopper
  });

  const tooltipPlacementTop = classnames({
    "asr-table-tooltip-placement-top": true,
    [classes.asrSvgTooltipPlacementTop]: classes.asrSvgTooltipPlacementTop
  });

  const tooltip = classnames({
    "asr-table-tooltip": true,
    [classes.asrSvgTooltip]: classes.asrSvgTooltip
  });

  const tooltipArrow = classnames({
    "asr-table-arrow": true,
    [classes.asrSvgTooltipArrow]: classes.asrSvgTooltipArrow
  });

  return {
    classnamesContainer,
    tooltipPopper,
    tooltipPlacementTop,
    tooltip,
    tooltipArrow
  };
};

export default useClasses;