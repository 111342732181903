import { localization } from "../localizationManager";

export var portugueseStrings = {
  groupButton: "Agrupar Por Mim",
  ungroupButton: "Desagrupar Por Mim",
  selectButton: "Selecionar",
  hideColumn: "Esconder coluna",
  showColumn: "Mostrar coluna",
  makePrivateColumn: "Tornar coluna privada",
  makePublicColumn: "Tornar coluna pública",
  moveToDetail: "Mover para Detalhes",
  showAsColumn: "Mostrar como Coluna",
  filterPlaceholder: "Pesquisar...",
  removeRows: "Remover linhas",
  showLabel: "Mostrar",
  entriesLabel: "entradas",
  visualizer_text: "Textos em tabela",
  visualizer_wordcloud: "Nuvem de palavras",
  visualizer_histogram: "Histograma",
  visualizer_number: "Média",
  visualizer_choices: "Tabela",
  visualizer_selectBase: "Gráfico",
  chartType_bar: "Barra",
  chartType_vbar: "Barra vertical",
  chartType_stackedbar: "Barra Empilhada",
  chartType_doughnut: "Rosca",
  chartType_pie: "Tarte",
  chartType_scatter: "Lastro",
  chartType_gauge: "Escala",
  chartType_bullet: "Bala",
  hideButton: "Esconder",
  makePrivateButton: "Tornar privado",
  makePublicButton: "Tornar público",
  showButton: "Mostrar",
  filter: "Filtrar",
  resetFilter: "Restaurar Filtro",
  changeLocale: "Mudar Localização",
  en: "Inglês",
  fr: "Francês",
  ru: "Russo",
  de: "Alemão",
  clearButton: "Limpar",
  addElement: "Escolher questão para mostrar...",
  defaultOrder: "Ordenação Padrão",
  ascOrder: "Ascendente",
  descOrder: "Descendente",
  showMinorColumns: "Mostrar colunas menores",
  otherCommentTitle: "Outros itens e comentários",
  showPercentages: "Mostrar percentuais",
  hidePercentages: "Esconder percentuais",
  pdfDownloadCaption: "PDF",
  xlsxDownloadCaption: "Excel",
  csvDownloadCaption: "CSV",
  saveDiagramAsPNG: "Baixar diagrama como PNG",
  hideEmptyAnswers: "Esconder perguntas vazias",
  showEmptyAnswers: "Mostrar perguntas vazias",
  "topNValueText-1": "Todas as respostas",
  "topNValueText5": "Primeiras 5 respostas",
  "topNValueText10": "Primeiras 10 respostas",
  "topNValueText20": "Primeiras 20 respostas",
  hideMissingAnswers: "Ocultar perguntas não respondidas",
  showMissingAnswers: "Mostrar perguntas não respondidas",
  missingAnswersLabel: "Perguntas não respondidas",
  noVisualizerForQuestion: "Este tipo de pergunta ainda não foi visualizada",
  noResults: "Ainda não possui resultados",
  showPerValues: "Por valores",
  showPerColumns: "Por colunas",
  answer: "Resposta",
  correctAnswer: "Resposta correta: ",
  percent: "Percentagem",
  responses: "Respostas"
};

//Uncomment these two lines on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
localization.locales["pt"] = portugueseStrings;
localization.localeNames["pt"] = "Português";
