export default [
  {
    "text": "Afghanistan",
    "value": "AF",
  },
  {
    "text": "Angola",
    "value": "AO",
  },
  {
    "text": "Albania",
    "value": "AL",
  },
  {
    "text": "Andorra",
    "value": "AD",
  },
  {
    "text": "United Arab Emirates",
    "value": "AE",
  },
  {
    "text": "Argentina",
    "value": "AR",
  },
  {
    "text": "Armenia",
    "value": "AM",
  },
  {
    "text": "Antigua and Barbuda",
    "value": "AG",
  },
  {
    "text": "Australia",
    "value": "AU",
  },
  {
    "text": "Austria",
    "value": "AT",
  },
  {
    "text": "Azerbaijan",
    "value": "AZ",
  },
  {
    "text": "Burundi",
    "value": "BI",
  },
  {
    "text": "Belgium",
    "value": "BE",
  },
  {
    "text": "Benin",
    "value": "BJ",
  },
  {
    "text": "Burkina Faso",
    "value": "BF",
  },
  {
    "text": "Bangladesh",
    "value": "BD",
  },
  {
    "text": "Bulgaria",
    "value": "BG",
  },
  {
    "text": "Bahrain",
    "value": "BH",
  },
  {
    "text": "Bahamas",
    "value": "BS",
  },
  {
    "text": "Bosnia and Herzegovina",
    "value": "BA",
  },
  {
    "text": "Belarus",
    "value": "BY",
  },
  {
    "text": "Belize",
    "value": "BZ",
  },
  {
    "text": "Bolivia",
    "value": "BO",
  },
  {
    "text": "Brazil",
    "value": "BR",
  },
  {
    "text": "Barbados",
    "value": "BB",
  },
  {
    "text": "Brunei",
    "value": "BN"
  },
  {
    "text": "Bhutan",
    "value": "BT",
  },
  {
    "text": "Botswana",
    "value": "BW",
  },
  {
    "text": "Central African Republic",
    "value": "CF",
  },
  {
    "text": "Canada",
    "value": "CA",
  },
  {
    "text": "Switzerland",
    "value": "CH",
  },
  {
    "text": "Chile",
    "value": "CL",
  },
  {
    "text": "China",
    "value": "CN",
  },
  {
    "text": "Ivory Coast",
    "value": "CI",
  },
  {
    "text": "Cameroon",
    "value": "CM",
  },
  {
    "text": "DR Congo",
    "value": "CD",
  },
  {
    "text": "Republic of the Congo",
    "value": "CG",
  },
  {
    "text": "Colombia",
    "value": "CO",
  },
  {
    "text": "Comoros",
    "value": "KM",
  },
  {
    "text": "Cape Verde",
    "value": "CV",
  },
  {
    "text": "Costa Rica",
    "value": "CR",
  },
  {
    "text": "Cuba",
    "value": "CU",
  },
  {
    "text": "Cyprus",
    "value": "CY",
  },
  {
    "text": "Czechia",
    "value": "CZ",
  },
  {
    "text": "Germany",
    "value": "DE",
  },
  {
    "text": "Djibouti",
    "value": "DJ",
  },
  {
    "text": "Dominica",
    "value": "DM",
  },
  {
    "text": "Denmark",
    "value": "DK",
  },
  {
    "text": "Dominican Republic",
    "value": "DO",
  },
  {
    "text": "Algeria",
    "value": "DZ",
  },
  {
    "text": "Ecuador",
    "value": "EC",
  },
  {
    "text": "Egypt",
    "value": "EG",
  },
  {
    "text": "Eritrea",
    "value": "ER",
  },
  {
    "text": "Spain",
    "value": "ES",
  },
  {
    "text": "Estonia",
    "value": "EE",
  },
  {
    "text": "Ethiopia",
    "value": "ET",
  },
  {
    "text": "Finland",
    "value": "FI",
  },
  {
    "text": "Fiji",
    "value": "FJ",
  },
  {
    "text": "France",
    "value": "FR",
  },
  {
    "text": "Micronesia",
    "value": "FM",
  },
  {
    "text": "Gabon",
    "value": "GA",
  },
  {
    "text": "United Kingdom",
    "value": "GB",
  },
  {
    "text": "Georgia",
    "value": "GE",
  },
  {
    "text": "Ghana",
    "value": "GH",
  },
  {
    "text": "Guinea",
    "value": "GN",
  },
  {
    "text": "Gambia",
    "value": "GM",
  },
  {
    "text": "Equatorial Guinea",
    "value": "GQ",
  },
  {
    "text": "Greece",
    "value": "GR",
  },
  {
    "text": "Grenada",
    "value": "GD",
  },
  {
    "text": "Guatemala",
    "value": "GT",
  },
  {
    "text": "Guyana",
    "value": "GY",
  },
  {
    "text": "Honduras",
    "value": "HN",
  },
  {
    "text": "Croatia",
    "value": "HR",
  },
  {
    "text": "Haiti",
    "value": "HT",
  },
  {
    "text": "Hungary",
    "value": "HU",
  },
  {
    "text": "Indonesia",
    "value": "ID",
  },
  {
    "text": "India",
    "value": "IN",
  },
  {
    "text": "Ireland",
    "value": "IE",
  },
  {
    "text": "Iran",
    "value": "IR",
  },
  {
    "text": "Iraq",
    "value": "IQ",
  },
  {
    "text": "Iceland",
    "value": "IS",
  },
  {
    "text": "Israel",
    "value": "IL",
  },
  {
    "text": "Italy",
    "value": "IT",
  },
  {
    "text": "Jamaica",
    "value": "JM",
  },
  {
    "text": "Jordan",
    "value": "JO",
  },
  {
    "text": "Japan",
    "value": "JP",
  },
  {
    "text": "Kazakhstan",
    "value": "KZ",
  },
  {
    "text": "Kenya",
    "value": "KE",
  },
  {
    "text": "Kyrgyzstan",
    "value": "KG",
  },
  {
    "text": "Cambodia",
    "value": "KH",
  },
  {
    "text": "Kiribati",
    "value": "KI",
  },
  {
    "text": "Saint Kitts and Nevis",
    "value": "KN",
  },
  {
    "text": "South Korea",
    "value": "KR",
  },
  {
    "text": "Kuwait",
    "value": "KW",
  },
  {
    "text": "Laos",
    "value": "LA",
  },
  {
    "text": "Lebanon",
    "value": "LB",
  },
  {
    "text": "Liberia",
    "value": "LR",
  },
  {
    "text": "Libya",
    "value": "LY",
  },
  {
    "text": "Saint Lucia",
    "value": "LC",
  },
  {
    "text": "Liechtenstein",
    "value": "LI",
  },
  {
    "text": "Sri Lanka",
    "value": "LK",
  },
  {
    "text": "Lesotho",
    "value": "LS",
  },
  {
    "text": "Lithuania",
    "value": "LT",
  },
  {
    "text": "Luxembourg",
    "value": "LU",
  },
  {
    "text": "Latvia",
    "value": "LV",
  },
  {
    "text": "Morocco",
    "value": "MA",
  },
  {
    "text": "Monaco",
    "value": "MC",
  },
  {
    "text": "Moldova",
    "value": "MD",
  },
  {
    "text": "Madagascar",
    "value": "MG",
  },
  {
    "text": "Maldives",
    "value": "MV",
  },
  {
    "text": "Mexico",
    "value": "MX",
  },
  {
    "text": "Marshall Islands",
    "value": "MH",
  },
  {
    "text": "North Macedonia",
    "value": "MK",
  },
  {
    "text": "Mali",
    "value": "ML",
  },
  {
    "text": "Malta",
    "value": "MT",
  },
  {
    "text": "Myanmar",
    "value": "MM",
  },
  {
    "text": "Montenegro",
    "value": "ME",
  },
  {
    "text": "Mongolia",
    "value": "MN",
  },
  {
    "text": "Mozambique",
    "value": "MZ",
  },
  {
    "text": "Mauritania",
    "value": "MR",
  },
  {
    "text": "Mauritius",
    "value": "MU",
  },
  {
    "text": "Malawi",
    "value": "MW",
  },
  {
    "text": "Malaysia",
    "value": "MY",
  },
  {
    "text": "Namibia",
    "value": "NA",
  },
  {
    "text": "Niger",
    "value": "NE",
  },
  {
    "text": "Nigeria",
    "value": "NG",
  },
  {
    "text": "Nicaragua",
    "value": "NI",
  },
  {
    "text": "Netherlands",
    "value": "NL",
  },
  {
    "text": "Norway",
    "value": "NO",
  },
  {
    "text": "Nepal",
    "value": "NP",
  },
  {
    "text": "Nauru",
    "value": "NR",
  },
  {
    "text": "New Zealand",
    "value": "NZ",
  },
  {
    "text": "Oman",
    "value": "OM",
  },
  {
    "text": "Pakistan",
    "value": "PK",
  },
  {
    "text": "Panama",
    "value": "PA",
  },
  {
    "text": "Peru",
    "value": "PE",
  },
  {
    "text": "Philippines",
    "value": "PH",
  },
  {
    "text": "Palau",
    "value": "PW",
  },
  {
    "text": "Papua New Guinea",
    "value": "PG",
  },
  {
    "text": "Poland",
    "value": "PL",
  },
  {
    "text": "North Korea",
    "value": "KP",
  },
  {
    "text": "Portugal",
    "value": "PT",
  },
  {
    "text": "Paraguay",
    "value": "PY",
  },
  {
    "text": "Qatar",
    "value": "QA",
  },
  {
    "text": "Romania",
    "value": "RO",
  },
  {
    "text": "Russia",
    "value": "RU",
  },
  {
    "text": "Rwanda",
    "value": "RW",
  },
  {
    "text": "Saudi Arabia",
    "value": "SA",
  },
  {
    "text": "Sudan",
    "value": "SD",
  },
  {
    "text": "Senegal",
    "value": "SN",
  },
  {
    "text": "Singapore",
    "value": "SG",
  },
  {
    "text": "Solomon Islands",
    "value": "SB",
  },
  {
    "text": "Sierra Leone",
    "value": "SL",
  },
  {
    "text": "El Salvador",
    "value": "SV",
  },
  {
    "text": "San Marino",
    "value": "SM",
  },
  {
    "text": "Somalia",
    "value": "SO",
  },
  {
    "text": "Serbia",
    "value": "RS",
  },
  {
    "text": "South Sudan",
    "value": "SS",
  },
  {
    "text": "São Tomé and Príncipe",
    "value": "ST",
  },
  {
    "text": "Suritext",
    "value": "SR",
  },
  {
    "text": "Slovakia",
    "value": "SK",
  },
  {
    "text": "Slovenia",
    "value": "SI",
  },
  {
    "text": "Sweden",
    "value": "SE",
  },
  {
    "text": "Eswatini",
    "value": "SZ",
  },
  {
    "text": "Seychelles",
    "value": "SC",
  },
  {
    "text": "Syria",
    "value": "SY",
  },
  {
    "text": "Chad",
    "value": "TD",
  },
  {
    "text": "Togo",
    "value": "TG",
  },
  {
    "text": "Thailand",
    "value": "TH",
  },
  {
    "text": "Tajikistan",
    "value": "TJ",
  },
  {
    "text": "Turkmenistan",
    "value": "TM",
  },
  {
    "text": "Timor-Leste",
    "value": "TL",
  },
  {
    "text": "Tonga",
    "value": "TO",
  },
  {
    "text": "Trinidad and Tobago",
    "value": "TT",
  },
  {
    "text": "Tunisia",
    "value": "TN",
  },
  {
    "text": "Turkey",
    "value": "TR",
  },
  {
    "text": "Tuvalu",
    "value": "TV",
  },
  {
    "text": "Tanzania",
    "value": "TZ",
  },
  {
    "text": "Uganda",
    "value": "UG",
  },
  {
    "text": "Ukraine",
    "value": "UA",
  },
  {
    "text": "Uruguay",
    "value": "UY",
  },
  {
    "text": "United States",
    "value": "US",
  },
  {
    "text": "Uzbekistan",
    "value": "UZ",
  },
  {
    "text": "Vatican City",
    "value": "VA",
  },
  {
    "text": "Saint Vincent and the Grenadines",
    "value": "VC",
  },
  {
    "text": "Venezuela",
    "value": "VE",
  },
  {
    "text": "Vietnam",
    "value": "VN",
  },
  {
    "text": "Vanuatu",
    "value": "VU",
  },
  {
    "text": "Samoa",
    "value": "WS",
  },
  {
    "text": "Yemen",
    "value": "YE",
  },
  {
    "text": "South Africa",
    "value": "ZA",
  },
  {
    "text": "Zambia",
    "value": "ZM",
  },
  {
    "text": "Zimbabwe",
    "value": "ZW",
  }
];