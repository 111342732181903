import { baseApiUrl } from "../../../configs/MainConfig";
import { requestActionCreator } from "../../../utils/requestActionCreator";
import * as types from "../types";
import { authService } from "../../../shared/services/auth.service";

export const fetchCampaignDashboardManagers = (payload = {}) => (dispatch) => {
  const url = `${baseApiUrl}/campaign/member/list/minimalistic/?campaign_id=${payload.id}&is_service=true`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_DASHBOARD_MANAGERS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchCampaignDashboardManagersByPage = (payload = {}) => (dispatch) => {
  const url = `${baseApiUrl}/campaign/member/list/minimalistic/?campaign_id=${payload.id}&page=${payload.page}&is_service=true`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_DASHBOARD_MANAGERS_BY_SCROLL,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchCampaignDashboardEvents = (payload = {}) => (dispatch) => {
  const url = `${baseApiUrl}/log/list/campaign-dashboard/?campaign_id=${payload.id}&sort_by=-id`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_DASHBOARD_EVENTS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchCampaignDashboardEventsByPage = (payload = {}) => (dispatch) => {
  const url = `${baseApiUrl}/log/list/campaign-dashboard/?campaign_id=${payload.id}&page=${payload.page}&sort_by=-id`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_DASHBOARD_EVENTS_BY_SCROLL,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};
