import * as types from "./types";

export const addSystemNotification = (title, text, type) => ({
  type: types.ADD_SYSTEM_NOTIFICATION,
  payload: { title, text, type }
});

export const removeSystemNotification = (id) => ({
  type: types.REMOVE_SYSTEM_NOTIFICATION,
  payload: { id }
});

export const toggleCommonModalShowStatus = ({ modalKey, show }) => ({
  type: types.TOGGLE_COMMON_MODAL_SHOW_STATUS,
  payload: { modalKey, show }
});

export const updateSignUpModalState = ({ show, campaignName, userEmail }) => ({
  type: types.UPDATE_SIGN_UP_MODAL_STATE,
  payload: { show, campaignName, userEmail }
});