import React, { useState } from "react";
import { useSelector } from "react-redux";
// CONSTANTS
import { systemModals } from "@/shared/constants/system";
// COMPONENTS
import AsrLightModal from "src/shared/components/Modals/AsrLightModal";
import SignInAuthModalContent from "./components/SignInAuthModalContent";
import SignUpAuthModalContent from "./components/SignUpAuthModalContent";
import RestorePasswordAuthModalContent from "./components/RestorePasswordAuthModalContent";
import EnterCodeAuthModalContent from "./components/EnterCodeAuthModalContent";
import UserVerificationInfoModal from "@/components/Auth/Modals/UserVerificationInfoModal";
// STORE
import { commonAppModals } from "@/store/System/selectors";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// STYLES
import styles from "./styles.module.scss";

const AllInOneAuthLightModal = () => {
  const [showDashedContentBorder, setShowDashedContentBorder] = useState(true);
  const [showUserVerificationInfoModal, setShowUserVerificationInfoModal] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
    source: ""
  });

  const modalsState = useSelector(commonAppModals);
  const modalToggleHandlers = useToggleCommonModalHandlers();

  const showSignInModal = modalsState[systemModals.SIGN_IN].show || false;
  const showSignUpModal = modalsState[systemModals.SIGN_UP].show || false;
  const showEnterCodeModal = modalsState[systemModals.ENTER_CODE].show || false;
  const showRestorePasswordModal = modalsState[systemModals.RESTORE_PASSWORD].show || false;

  const hasAnyModalShow = (
    showSignInModal
    || showSignUpModal
    || showEnterCodeModal
    || showRestorePasswordModal
  );

  const closeAllModalsHandler = () => {
    if (showSignInModal) {
      modalToggleHandlers.signIn({ show: false });
    } else if (showSignUpModal) {
      modalToggleHandlers.signUp({ show: false });
    } else if (showEnterCodeModal) {
      modalToggleHandlers.enterCampaignPasscode({ show: false });
    } else if (showRestorePasswordModal) {
      modalToggleHandlers.restorePassword({ show: false });
    }
  };

  const toggleUserVerificationInfoModal = () => {
    setShowUserVerificationInfoModal(!showUserVerificationInfoModal);
  };

  const onSignUpCallback = async (values) => {
    await setUserCredentials({
      email: values.email,
      password: values.password,
      source: "signUp"
    });
    await setShowUserVerificationInfoModal(true);
    closeAllModalsHandler();
  };

  const onSignInNotVerifiedCallback = async (values) => {
    await setUserCredentials({
      email: values.email,
      password: values.password,
      source: "signIn"
    });
    await setShowUserVerificationInfoModal(true);
    closeAllModalsHandler();
  };

  const onCloseModal = () => {
    setShowUserVerificationInfoModal(false);
    closeAllModalsHandler();
  };

  if (!hasAnyModalShow && !showUserVerificationInfoModal) return null;
  return (
    <AsrLightModal
      open={true}
      onClose={onCloseModal}
      classes={{
        contentContainer: styles.ContentContainer
      }}
      dashedContentBorder={showDashedContentBorder}
    >
      {!showUserVerificationInfoModal && (
        <>
          {modalsState[systemModals.SIGN_IN].show && (
            <SignInAuthModalContent
              closeModal={closeAllModalsHandler}
              onSignInNotVerifiedCallback={onSignInNotVerifiedCallback}
            />
          )}

          {modalsState[systemModals.SIGN_UP].show && (
            <SignUpAuthModalContent
              closeModal={closeAllModalsHandler}
              onSignUpCallback={onSignUpCallback}
            />
          )}

          {modalsState[systemModals.ENTER_CODE].show && (
            <EnterCodeAuthModalContent
              setShowDashedContentBorder={setShowDashedContentBorder}
            />
          )}

          {modalsState[systemModals.RESTORE_PASSWORD].show && (
            <RestorePasswordAuthModalContent
              closeModal={closeAllModalsHandler}
            />
          )}
        </>
      )}

      {showUserVerificationInfoModal && (
        <UserVerificationInfoModal
          open={showUserVerificationInfoModal}
          closeModal={toggleUserVerificationInfoModal}
          unpackModal={true}
          showThankYouTitle={Boolean(userCredentials.source === "signUp")}
          userEmail={userCredentials.email}
          userPassword={userCredentials.password}
          initialStage={userCredentials.source === "signUp" ? 1 : 2}
          maxStage={3}
          periodBetweenStages={20000}
        />
      )}
    </AsrLightModal>
  );
};

export default AllInOneAuthLightModal;
