import React, { useMemo } from "react";
import { Formik } from "formik";
// COMPONENTS
import AsrInput from "@/components/Common/FormComponentsNew/AsrInput";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
import { generateUserFullName } from "@/utils/helpers/user";
// STYLES
import styles from "./styles.module.scss";


const MyProfileReadOnlyForm = ({ profileInfo }) => {
  const initialValues = {
    email: profileInfo?.personal_info?.email || "",
    fullname: generateUserFullName({
      titleName: profileInfo?.personal_info?.title_name,
      firstName: profileInfo?.personal_info?.first_name,
      lastName: profileInfo?.personal_info?.last_name
    })
  };

  const isPasswordOlderThan3Years = useMemo(() => {
    let passwordChangeDt = profileInfo?.meta?.password_change_dt;
    if (!passwordChangeDt) return false;

    try {
      const todayDate = new Date();
      passwordChangeDt = new Date(passwordChangeDt);

      // check if password older than 90 days;
      return (todayDate - passwordChangeDt) / (1000 * 3600 * 24 * 90) > 1;
    } catch (e) {
      // skip error
    }
    return false;
  }, [profileInfo?.meta?.password_change_dt]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
      }}
      enableReinitialize={true}
    >
      {fmk => (
        <div className={styles.ReadOnlyFormContainer}>
          <AsrInput
            label={<IntlMessages id="user.registeredName"/>}
            labelColon={false}
            name="fullname"
            value={fmk.values.fullname}
            classes={{
              input: styles.InputField,
              label: styles.InputLabel
            }}
            disabled
          />

          <AsrInput
            label={<IntlMessages id="user.registeredEmail"/>}
            labelColon={false}
            name="email"
            value={fmk.values.email}
            classes={{
              input: styles.InputField,
              label: styles.InputLabel
            }}
            disabled
          />

          {isPasswordOlderThan3Years && (
            <div className={styles.PasswordChangeInfoText}>
              <IntlMessages
                id={"auth.yourPasswordIsMoreThanCountMonthsWarning"}
                values={{
                  number: 3,
                  bold: value => <span className={styles.Bold}>{value}</span>
                }}
              />.
            </div>
          )}
        </div>
      )}
    </Formik>
  );
};

export default MyProfileReadOnlyForm;
