import React, { memo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useGetPrivateFooterItems from "@/components/AppFooter/hooks/useGetPrivateFooterItems";
// STYLES
import "./styles.scss";


const PrivateFooter = memo(() => {
  const footerItems = useGetPrivateFooterItems();

  const footerClass = classNames({
    "app-footer__private": true,
  });

  const generateLiContent = (item) => {
    if (item?.linkTo) return (
      <Link
        className='link'
        to={{
          pathname: item.linkTo,
        }}
        target={item.target}
        rel={item.rel}
        onClick={item?.onClick}
      >
        <span className='bold'>
          <IntlMessages id={item.titleId}/>
        </span>
      </Link>
    );

    return (
      <div className="link" onClick={item?.onClick}>
        <span className='bold'>
          <IntlMessages id={item.titleId}/>
        </span>
      </div>
    );
  };

  return (
    <footer className={footerClass}>
      <div className='app-footer__inner'>
        <ul className='list-inline mb-0 asr-footer-link-list'>
          {footerItems.map(item => (
            <li className='d-inline-block' key={item.titleId}>
              {generateLiContent(item)}
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
});

export default PrivateFooter;