import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  permissions: undefined,
  permissionsNormalized: undefined,
  membership: {},
  meta: {}
};

export const currentUserSlice = createSlice({
  name: "accountCurrentUser",
  initialState,
  reducers: {
    setAccountCurrentUser: (state, action) => {
      state.permissions = action.payload.permissions;
      state.permissionsNormalized = action.payload.permissions_normalized;
      state.membership = action.payload.membership;
      state.meta = action.payload.meta;
    },
  },
});


export const {
  setAccountCurrentUser
} = currentUserSlice.actions;

export default currentUserSlice.reducer;