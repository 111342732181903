import classnames from "classnames";
// STYLES
import styles from "../../styles.module.scss";


const useClasses = (classes) => {
  const root = classnames({
    [styles.asrUploadImage]: true,
    [classes.root]: classes.root
  });


  return {
    root,
  };
};

export default useClasses;