import { actionsConstants } from "../../constants/actionsConstants";
import allOwnedCampaignsValidator from "../validatorsHandlers/allOwnedCampaignsValidator";
import campaignsValidator from "../validatorsHandlers/campaignsValidator";

const campaignInvitation = {
  [actionsConstants.CampaignInvitationListView]: campaignsValidator,
  [actionsConstants.CampaignInvitationCreate]: campaignsValidator,
  [actionsConstants.CampaignInvitationModify]: allOwnedCampaignsValidator,
  [actionsConstants.CampaignInvitationDelete]: allOwnedCampaignsValidator,
};


export default campaignInvitation;