import { useSelector } from "react-redux";
import { getColorScheme } from "@/store/Organization/selectors";

const useAccountLogoExtractor = ({
                                   ignoreAccountTheme = false
                                 } = {}) => {
  const theme = useSelector(getColorScheme);

  let result = {
    logoLink: "https://cdn.assessor.io/media/AssessorioText.svg",
    isLogoLinkDefault: true,
    subLogoLink: undefined,
    isSubLogoLinkDefault: true,
    hasCustomLogoItems: false
  };

  if (!ignoreAccountTheme) {
    if (theme?.["--asr-header-org-logo-image"]) {
      result = {
        ...result,
        logoLink: theme["--asr-header-org-logo-image"],
        isLogoLinkDefault: false
      };
    }

    if (theme?.["--asr-header-org-sub-logo-image"]) {
      result = {
        ...result,
        subLogoLink: theme["--asr-header-org-sub-logo-image"],
        isSubLogoLinkDefault: false
      };
    }

    if (!result.isLogoLinkDefault || !result.isSubLogoLinkDefault) {
      result = { ...result, hasCustomLogoItems: true };
    }
  }

  return result;
};

export default useAccountLogoExtractor;