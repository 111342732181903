import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// TYPES
import surveyStateTypes from "@/store/SurveyAssessment/surveyStateTypes";
// CONSTANTS
import {
  QUESTION_CONTROL_MODE,
  QUESTIONS_PANELS
} from "@/shared/constants/surveyJs";
import {
  answerVerificationTypes,
  answerVerificationTypesI18N,
  emptyNote
} from "@/components/SurveyJs/constants";
// HELPERS
import { initCustomHeader } from "@/shared/Survey/helpers/question/initCustomHeader";
// STORE
import { getQuestionId } from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/questions";
import { modifySurveyAuditorQuestionPanel } from "@/store/Survey/actions";
import { getLocale } from "@/store/Settings/selectors";
import { getQuestionData } from "@/store/SurveyAssessment/selectors";


const useAssessorControls = ({
                               props: {
                                 permissions,
                                 parentTarget,
                                 surveyDomBuilder,
                                 progressInfo,
                                 service,
                                 callbacks
                               }
                             }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(getLocale);
  const questionData = useSelector(getQuestionData);

  const handleModifySurveyAuditorQuestionPanel = useCallback((notePayload) => {
    dispatch(modifySurveyAuditorQuestionPanel(notePayload));
  }, [dispatch]);

  const selectHandler = useCallback(({ questionName, questionData, status }) => {
    const { question_id: questionId } = questionData[questionName];
    dispatch({
      type: surveyStateTypes.SET_QUESTION_STATUS,
      payload: {
        questionName,
        status,
      }
    });

    //direct change of status via api
    if (callbacks?.onAnswerVerificationStatusChanged) {
      callbacks.onAnswerVerificationStatusChanged(status, questionId);
    }
  }, [callbacks?.onAnswerVerificationStatusChanged, dispatch]);

  const addAuditorControls = useCallback((surveyModel, options) => {
    const surveyDomBuilderConfig = { options, locale };
    const arrayOfAnswersObjs = progressInfo?.object_answers || [];
    const questionName = options.question.propertyHash.name;
    const questionId = options.question.propertyHash.question_id;

    // GET QUESTION TYPE
    const type = options.question.getType();
    const inputType = options.question.inputType;
    const questionType = { type, inputType };
    const answerObj = arrayOfAnswersObjs.find(item => {
      return item.question_id?.toString() === questionId?.toString();
    }) || {};

    const questionHeader = options.htmlElement.querySelector(".survey-question-card-header");
    const statusFromLocalState = questionData[questionName]?.status;
    const currentStatus = statusFromLocalState || answerObj.status || "unverified";
    const currentStatusIndex = answerVerificationTypes.indexOf(currentStatus);

    // INIT QUESTION STATUS
    dispatch({
      type: surveyStateTypes.SET_QUESTION_STATUS,
      payload: {
        questionName,
        status: currentStatus,
      }
    });

    const noteFromLocalState = questionData[questionName]?.note;
    const currentNote = noteFromLocalState || answerObj.note || { ...emptyNote };
    const notePayload = {
      question_id: getQuestionId(questionId),
      question_panel: QUESTIONS_PANELS.NOTES,
      data: {
        currentNote
      }
    };
    handleModifySurveyAuditorQuestionPanel(notePayload);

    /*
     *  HEADER
     * */
    initCustomHeader({
      options,
      surveyDomBuilder,
      actionsOptions: {
        mode: QUESTION_CONTROL_MODE.auditor,
        canModifyAssessorNotes: permissions?.canModifyItemNotes,
        canViewAssessorNotes: permissions?.canViewItemNotes,
        currentNote,
        questionId,
        assessmentId: parentTarget?.id,
        assessmentType: parentTarget?.type
      }
    });
    /*
    *  ADD FOOTER SECTION
    * */
    const surveyDomBuilderFooterArgs = {
      questionHeader,
      itemsServiceInfo: service?.questionsInfo ? service.questionsInfo[getQuestionId(questionId)] : {}
    };
    surveyDomBuilder.initFooter(surveyDomBuilderFooterArgs, surveyDomBuilderConfig);
    /*
    * Add  Auditor section
    * */
    const allQuestions = surveyModel.getAllQuestions();
    const question = surveyModel.getQuestionByName(questionName);
    const surveyDomBuilderAuditorsArgs = {
      questionHeader,
      questionName,
      allQuestions,
      question,
      selectHandler,
      changeQuestionNote: callbacks?.onItemNoteChanged,
      translateOptionsForAssessorSelect: answerVerificationTypesI18N,
      currentStatusIndex,
      canViewAnalytics: permissions?.canViewAnalytics,
      canViewAssessorNotes: permissions?.canViewItemNotes,
      canCreateAssessorNotes: permissions?.canCreateItemNotes,
      canModifyAssessorNotes: permissions?.canModifyItemNotes,
      canRemoveAssessorNotes: permissions?.canDeleteItemNotes,
      canValidateAssessmentAnswer: permissions?.canVerifyAnswer,
      canViewRecommendations: permissions?.canViewRecommendations,
      assessmentId: parentTarget?.id,
      manageType: service?.managementType,
      questionType,
      survey: surveyModel,
      currentStatus
    };
    surveyDomBuilder.initAuditorSection(surveyDomBuilderAuditorsArgs, surveyDomBuilderConfig);

    if (
      (currentNote.is_visible)
      || (permissions?.canViewItemNotes && ((permissions?.canCreateItemNotes && !currentNote) || currentNote))
    ) {
      dispatch({
        type: surveyStateTypes.SET_QUESTION_NOTE,
        payload: {
          questionName,
          note: currentNote
        }
      });
    }

  }, [
    progressInfo?.object_answers,
    parentTarget?.id,
    permissions?.canViewItemNotes,
    permissions?.canCreateItemNotes,
    permissions?.canModifyItemNotes,
    permissions?.canDeleteItemNotes,
    permissions?.canVerifyAnswer,
    permissions?.canViewAnalytics,
    permissions?.canViewRecommendations,
    service?.questionsInfo,
    service?.managementType,
    callbacks?.onItemNoteChanged,
    dispatch,
    handleModifySurveyAuditorQuestionPanel,
    locale,
    questionData,
    selectHandler,
    surveyDomBuilder,
  ]);

  return { addAssessorControls: addAuditorControls };
};

export default useAssessorControls;