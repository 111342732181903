// TAGS
export const CAMPAIGNS_AUTOCOMPLETE_TAG = "campaigns-autocomplete-tag";
export const CAMPAIGNS_TAG = "campaigns";
export const CAMPAIGN_TAG = "campaign";
export const CAMPAIGNS_DASHBOARD_TAG = "dashboard-info";
export const CAMPAIGN_PARTICIPANT_INFO_TAG = "campaign-participant-info";
export const PUBLIC_CAMPAIGN_INFO = "public-campaign-info";

// TARGETS
export const ASSIGNED_CAMPAIGN_CARDS_TARGET = "assigned-campaign-cards";
export const ASSIGNED_CAMPAIGN_TABLE_TARGET = "assigned-campaign-table";
export const PARTICIPATION_CAMPAIGN_CARDS_TARGET = "participation-campaign-cards";
export const PARTICIPATION_CAMPAIGN_TABLE_TARGET = "participation-campaign-table";
export const ACCOUNT_PUBLIC_CAMPAIGN_CARDS_TARGET = "account-public-campaign-cards";
export const ACCOUNT_PUBLIC_CAMPAIGN_TABLE_TARGET = "account-public-campaign-table";
export const CAMPAIGNS_CREATE_TARGET = "common-create";
export const CAMPAIGNS_UPDATE_TARGET = "common-update";
export const CAMPAIGNS_DELETE_TARGET = "common-delete";
export const CAMPAIGNS_CLOSE_TARGET = "close";
export const CAMPAIGNS_GO_TO_LIVE_TARGET = "go-to-live";
export const AUDITOR_ACTIONS_STATS_TARGET = "auditor-actions-stats";
export const JOIN_PUBLIC_CAMPAIGN_TARGET = "public-join";


export const campaignsQueryArg = {
  PAGE: "page",
  PAGE_SIZE: "page_size",
  SORT_BY: "order_by",
  IDS: "ids",
  IDS_TO_TOP: "ids_to_top",
  IS_ARCHIVED: "is_archived",
  EXCLUDE_IDS: "exclude_ids",
  ID: "id",
  CAMPAIGN_ID: "campaign_id",
  GROUP_IDS: "group_ids",
  ACCOUNT_ID: "account_id",
  ACCOUNT: "account",
  SEARCH: "search",
  TYPE: "type",
  STATUS: "status",
  REGISTRATIONS: "registrations"
};