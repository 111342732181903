import React from "react";
// COMPONENTS
import AsrSimpleMessageModal from "@/shared/modals/AsrSimpleMessageModal";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "../styles.module.scss";


const EmailChangeConfirmationMessageModal = ({ open, closeModal }) => {
  return (
    <AsrSimpleMessageModal
      open={open}
      closeModal={closeModal}
      zIndex={1301}
    >
      <div className={styles.EmailChangeConfirmationContentContainer}>
        <div className={styles.MessageTitle}>
          <IntlMessages id="user.confirmationMessageSent"/>!
        </div>
        <div className={styles.MessageText}>
          <IntlMessages id="user.currentEmailWillBeUsedUntilConfirmationNewOne"/>.
        </div>
      </div>
    </AsrSimpleMessageModal>
  );
};

export default EmailChangeConfirmationMessageModal;
