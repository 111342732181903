import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
// CONSTANTS
import { actionsConstants } from "../../../../../../../shared/hooks/policyValidation/constants/actionsConstants";
// HOOKS
import usePolicyValidation from "../../../../../../../shared/hooks/policyValidation/usePolicyValidation";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import TemplatesNestedLinks from "./components/TemplatesNestedLinks/TemplatesNestedLinks";


const SideNavTemplates = ({
                            isStaffView,
                            props
                          }) => {
  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const canSeeAssessmentTemplates = usePolicyValidation({
    action: actionsConstants.SurveyTemplateListView,
  });
  const canSeeAssessmentItems = usePolicyValidation({
    action: actionsConstants.SurveyTemplateListView,
  });
  const canSeeAssessmentCustomFields = usePolicyValidation({
    action: actionsConstants.CustomFieldListView,
  });

  if (!canSeeAssessmentTemplates && !canSeeAssessmentItems && !canSeeAssessmentCustomFields) return null;

  return (
    <>
      <NavLink
        className={prependedClass}
        to={`${match.url}/assessment-templates`}
        onClick={props.closeNavCallBack}
      >
        <IntlMessages id="sidenav.templates"/>
      </NavLink>
      <TemplatesNestedLinks
        props={props}
        canSeeAssessmentTemplates={canSeeAssessmentTemplates}
        canSeeAssessmentItems={canSeeAssessmentItems}
        canSeeAssessmentCustomFields={canSeeAssessmentCustomFields}
      />
    </>
  );
};

export default SideNavTemplates;