import React, { useState } from "react";
import { NavLink, useParams, useRouteMatch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
// CONSTANTS
import { apiQueryArg } from "@/store/services/config/assessorApiConstants";
import { actionsConstants } from "@/shared/hooks/policyValidation/constants/actionsConstants";
import statusConstants from "@/shared/constants/statuses";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import usePolicyValidation from "@/shared/hooks/policyValidation/usePolicyValidation";
// COMPONENTS
import SideNavOrganization from "../SideNavOrganization/SideNavOrganization";
import CampaignResultsModal from "@/components/Campaign/Results/Modals/CampaignResultsModal/CampaignResultsModal";
import SideNavUsers from "./components/SideNavUsers/SideNavUsers";
// STORE
import { useGetCampaignParticipantQuery } from "@/store/services/campaigns/rtk";
import { useGetCampaignAssessmentsQuery } from "@/store/services/assessments/assessments";
import { getIsSuperUser } from "@/store/User/selectors";
import { getAccountMembership } from "@/store/Organization/rootReducers/account/currentUser/selectors";
import { getCampaignBaseInfo, getParticipantMeAssessmentsProgress } from "@/store/Campaign/selectors";
import { getAccessStatusForUser, getAssessmentStatusForUser } from "@/utils/helpers/assessments";


const statusText = {
  "Locked": <IntlMessages id="competency.locked"/>,
  "Complete": <IntlMessages id="competency.completed"/>,
  "Incomplete": <IntlMessages id="competency.incomplete"/>,
  "Closed": <IntlMessages id="competency.closed"/>,
  "is_submitted": <IntlMessages id="competency.is_submitted"/>
};

const STATUSES = {
  [statusConstants.CLOSED]: "Closed",
  [statusConstants.LOCKED]: "Locked",
  [statusConstants.NOT_STARTED]: "Incomplete",
  [statusConstants.IN_PROGRESS]: "Incomplete",
  [statusConstants.COMPLETED]: "Complete",
};

const SideNavCampaign = (props) => {
  const { campaignId } = useParams();
  const match = useRouteMatch();
  const isSuperUser = useSelector(getIsSuperUser);

  const [showModalResults, setShowModalResults] = useState(false);
  const campaignBaseInfo = useSelector(getCampaignBaseInfo);

  const {
    name,
    campaignDashboard,
    organization,
    requester_membership,
  } = props.campaign;
  const campaignName = campaignBaseInfo?.content?.name || name || campaignDashboard?.header?.name;
  const organizationId = campaignBaseInfo?.organization?.id || organization || campaignDashboard?.organization?.id;

  const {
    id: memberId,
    progress_info: {
      completed_percentage,
    } = {},
  } = requester_membership || {};

  const membership = useSelector(getAccountMembership);
  const assessmentsProgress = useSelector(getParticipantMeAssessmentsProgress);

  const isStaffView = membership?.type === "member" || isSuperUser;

  const { data: assessmentsData } = useGetCampaignAssessmentsQuery({
    [apiQueryArg.CAMPAIGN_ID]: campaignId
  }, {
    skip: membership === undefined && !isStaffView
  });

  const { isLoading } = useGetCampaignParticipantQuery({
    [apiQueryArg.CAMPAIGN_ID]: campaignId
  }, {
    skip: !campaignId
  });

  // PERMS
  // TODO: POL
  const canSeeOwnAssessmentResults = usePolicyValidation({
    action: actionsConstants.CampaignParticipantViewChartsReport,
    lookup: {
      campaign: campaignId,
      owner: campaignBaseInfo?.meta?.created_by
    }
  });

  const canSeeLog = usePolicyValidation({
    action: actionsConstants.CampaignViewLogs,
    lookup: {
      id: campaignId,
    }
  });


  const renderAssessmentItem = (item, index) => {
    const assessmentProgress = assessmentsProgress?.find(asmt => asmt.assessment?.id === item?.id);
    const assessmentStatus = getAssessmentStatusForUser({progressDetails: assessmentProgress?.details});

    const isAssessmentAccessible = getAccessStatusForUser({progressDetails: assessmentProgress?.details});
    const status = STATUSES[assessmentStatus] || "Locked";

    return isAssessmentAccessible ? (
      <NavLink
        key={index}
        className="prepend-icon"
        to={`/campaign/${campaignId}/assessment/${item.id}`}
        onClick={props.closeNavCallBack}
      >
        <span
          className={`nav-text asr-break-word colored module ${(status === "Incomplete" || status === "is_submitted") && "incomplete"}`}>
            {`${index + 1} - ${item?.content?.name} `} ({statusText[status]})
        </span>
      </NavLink>
    ) : (
      <a
        key={index}
        className="prepend-icon asr-break-word"
        onClick={props.closeNavCallBack}
      >
        <span
          className={`nav-text colored module ${(status === "Incomplete" || status === "is_submitted") && "incomplete"}`}>
            {`${index + 1} - ${item?.content?.name} `} ({statusText[status]})
        </span>
      </a>
    );
  };

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const toggleResults = (mode) => () => {
    setShowModalResults(mode);
  };

  if (membership === undefined || isLoading) return null;
  return (
    <>
      {!isStaffView
        ? (
          <SideNavOrganization organizationId={campaignBaseInfo?.organization?.id}/>
        ) : (
          <li className="menu no-arrow">
            <NavLink
              className={prependedClass}
              to={`${match.url}`}
              onClick={props.closeNavCallBack}
              exact
            >
              <IntlMessages id="sidebar.console"/>
            </NavLink>
          </li>
        )}

      <li className="menu no-arrow">
        <div className="selected-campaign">
          <div className="nav-header admin">
            <IntlMessages id="sidenav.myCampaign"/>
          </div>

          <div className="campaign-name">
            {campaignName}
          </div>

          <NavLink
            className="other-link"
            to={`/organization/${organizationId}`}
            onClick={props.closeNavCallBack}
          >
            <IntlMessages id="sidenav.other"/>
          </NavLink>
        </div>

        {isStaffView && (
          <>
            {/*  ASSESSMENTS */}
            <NavLink
              className={prependedClass}
              to={`${match.url}/assessment`}
              onClick={props.closeNavCallBack}
            >
              <IntlMessages id="sidenav.assessments"/>
            </NavLink>

            {/* USERS */}
            <SideNavUsers
              isStaffView={isStaffView}
              props={props}
            />
            {canSeeLog && (
              <NavLink
                className={prependedClass}
                to={`${match.url}/log`}
                onClick={props.closeNavCallBack}
              >
                <IntlMessages id="sidenav.log"/>
              </NavLink>
            )}
          </>
        )}
      </li>

      {!isStaffView && (
        <>
          <li className="nav-header">
            <IntlMessages id="sidebar.assessments"/>
          </li>

          <li className="menu no-arrow">
            {assessmentsData?.results?.map((item, index) => {
              return renderAssessmentItem(item, index);
            })}
            {completed_percentage === 100 && canSeeOwnAssessmentResults && (
              <a
                className="prepend-icon"
                onClick={toggleResults(true)}
                role="button"
              >
                <span className={"nav-text colored brackets"}>
                    <IntlMessages id="sidebar.myReports"/>
                </span>
              </a>
            )}
            {showModalResults && (
              <CampaignResultsModal
                isVisible={showModalResults}
                closeModal={toggleResults(false)}
                userName={<IntlMessages id="sidebar.myReports"/>}
                memberId={memberId}
              />
            )}
          </li>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
});

export default connect(mapStateToProps)(SideNavCampaign);