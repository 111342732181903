import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const accountRole = {
  [actionsConstants.AccountRoleListView]: simpleValidator,
  [actionsConstants.AccountRoleCreate]: simpleValidator,
  [actionsConstants.AccountRoleModify]: allOwnedIdsValidator,
  [actionsConstants.AccountRoleDelete]: allOwnedIdsValidator,
};

export default accountRole;