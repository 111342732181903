import ParseQuestionName
  from "@/components/SurveyJs/components/ViewerV2/components/QuestionHeader/components/ParseQuestionName";
import HeaderActions
  from "@/components/SurveyJs/components/ViewerV2/components/QuestionHeader/components/HeaderActions";


export const initCustomHeader = ({
                                   options,
                                   surveyDomBuilder,
                                   actionsOptions,
                                   headerActionsComponent = HeaderActions
                                 }) => {
  const questionHeader = options.htmlElement.querySelector(".survey-question-card-header");
  if (questionHeader) {
    // TITLE
    const questionHeaderTitle = options.htmlElement.querySelector(".question__title").querySelector(".sv-string-viewer");
    const questionHeaderNo = options.htmlElement.querySelector(".question__title").querySelector(".sv_q_num");
    // DESCRIPTION
    const questionHeaderDescriptionRoot = options.htmlElement.querySelector(".question__description");
    const questionHeaderDescription = questionHeaderDescriptionRoot.querySelector(".sv-string-viewer");
    questionHeaderDescriptionRoot.style.paddingLeft = `${(questionHeaderNo?.clientWidth ?? 0) + 6}px`;
    // ACTIONS
    const questionHeaderActionsContainer = document.createElement("div");
    questionHeaderActionsContainer.classList.add("question-header-actions-container");

    questionHeaderTitle.innerHTML = null;
    surveyDomBuilder.injectElement(ParseQuestionName, questionHeaderTitle, {
      name: options.question?.title
    });

    surveyDomBuilder.injectElement(ParseQuestionName, questionHeaderDescription, {
      name: options.question?.description
    });

    if (!options.htmlElement.querySelector(".question-header-actions-container")) {
      questionHeader.appendChild(questionHeaderActionsContainer);
      surveyDomBuilder.injectElement(headerActionsComponent, questionHeaderActionsContainer, actionsOptions);
    }
  }
};