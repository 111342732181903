import { getCurrentLocale } from "@/utils/customFunctions";

export const originUrl = window.location.origin;
// eslint-disable-next-line no-undef
const serverOrigin = process.env.REACT_APP_SERVER_HOST;
export const baseUrl = `${serverOrigin}`;
export const baseApiUrl = `${serverOrigin}/api/v1`;
export const baseApiUrlV2 = `${serverOrigin}/api/v2`;
export const mediaRootUrl = `${serverOrigin}/media`;
export const avatarRootUrl = `${mediaRootUrl}/avatars`;

export const defaultJSONHeaders = () => ({
  "Content-Type": "application/json",
  "Accept-Language": `${getCurrentLocale().locale}`
});

// OLD METHOD. Now Use  /src/shared/services/auth.service.js
export const defaultJSONHeadersWithAuth = () => {
  return {
    "Content-Type": "application/json",
    "Accept-Language": `${getCurrentLocale().locale}`,
    Authorization: `Bearer ${localStorage.getItem("userToken")}`
  };
};

export const reloadDefaultJSONHeaders = () => {
  defaultJSONHeaders["Accept-Language"] = `${getCurrentLocale().locale}`;
};

export const generateAuthHeaders = ({ ignoreIfNoToken = true } = {}) => {
  const userToken = localStorage.getItem("userToken");
  if (!userToken && ignoreIfNoToken) return {};
  return {
    Authorization: `Bearer ${localStorage.getItem("userToken")}`
  };
};