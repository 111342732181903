import React from "react";
import * as Yup from "yup";
import IntlMessages from "@/utils/IntlMessages";

const useValidationSchema = () => Yup.object({
  email: Yup.string()
    .email(<IntlMessages id='appModule.emailInvalid'/>)
    .max(255, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: 255 }}/>)
    .required(<IntlMessages id='appModule.required'/>),

  firstName: Yup.string()
    .min(2, <IntlMessages id='appModule.mustBeAtLeastMinChars' values={{ count: 2 }}/>)
    .max(255, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: 255 }}/>)
    .required(<IntlMessages id='appModule.required'/>),

  lastName: Yup.string()
    .min(2, <IntlMessages id='appModule.mustBeAtLeastMinChars' values={{ count: 2 }}/>)
    .max(255, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: 255 }}/>)
    .required(<IntlMessages id='appModule.required'/>),

  titleName: Yup.string()
    .notRequired()
    .oneOf(["mr", "ms", "dr"], <IntlMessages id="appModule.incorrectCode"/>),

  newPassword: Yup.string()
    .notRequired()
    .min(6, <IntlMessages id="appModule.mustBeAtLeastMinChars" values={{ count: 6 }}/>)
    .max(100, <IntlMessages id='appModule.mustBeLessMaxChars' values={{ count: 100 }}/>),

  repeatNewPassword: Yup.string().when("newPassword", {
    is: val => (val && val.length > 0),
    then: Yup.string()
      .oneOf([Yup.ref("newPassword")], <IntlMessages id='appModule.passwordsNotEqual'/>)
      .required(<IntlMessages id='appModule.required'/>)
  })
});

export default useValidationSchema;
