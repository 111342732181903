import React, { useEffect } from "react";
import PropTypes from "prop-types";
// MATERIAL UI
import Tooltip from "@mui/material/Tooltip";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// ICONS
import icons from "@/shared/UI/SvgIcons";
import { getAvailableSvgIcons } from "./iconTypes";
// STYLES
import useClasses from "./hooks/useClasses";


const AsrSvgIcon = ({
                      icon,
                      isVisible = true,
                      tooltipTitle = "",
                      disableTooltipInteractive = false,
                      disablePortal = false,
                      handler,
                      tooltipPlacement = "top",
                      classes = {
                        asrSvgIconContainer: "",
                        asrSvgPopper: "",
                        asrSvgTooltipPlacementTop: "",
                        asrSvgTooltip: "",
                        asrSvgTooltipArrow: "",
                      },
                      ...other
                    }) => {
  const _classes = useClasses(classes);

  useEffect(() => {
    const isValidIcon = getAvailableSvgIcons().includes(icon?.toLowerCase());
    if (!isValidIcon) {
      console.error("Icon no valid");
      return null;
    }
  }, [icon]);

  if (!isVisible) return null;

  const title = () => {
    if (!tooltipTitle) return "";

    if (typeof tooltipTitle === "string") {
      return <IntlMessages id={tooltipTitle}/>;
    } else {
      return tooltipTitle;
    }
  };

  return (
    <Tooltip
      {...other}
      placement={tooltipPlacement}
      arrow
      disableInteractive={disableTooltipInteractive}
      PopperProps={{
        disablePortal: disablePortal
      }}
      classes={{
        popper: _classes.tooltipPopper,
        tooltipPlacementTop: _classes.tooltipPlacementTop,
        tooltip: _classes.tooltip,
        arrow: _classes.tooltipArrow
      }}
      title={title()}
    >
      <div onClick={handler} className={_classes.classnamesContainer}>
        {icons[icon]}
      </div>
    </Tooltip>
  );
};

AsrSvgIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  disableTooltipInteractive: PropTypes.bool,
  disablePortal: PropTypes.bool,
  handler: PropTypes.func,
  tooltipPlacement: PropTypes.string,
  classes: PropTypes.shape({
    asrSvgIconContainer: PropTypes.string,
    asrSvgPopper: PropTypes.string,
    asrSvgTooltipPlacementTop: PropTypes.string,
    asrSvgTooltip: PropTypes.string,
    asrSvgTooltipArrow: PropTypes.string
  })
};

AsrSvgIcon.displayName = "AsrSvgIcon";
export default AsrSvgIcon;