import React from "react";
// REACT-PDF
import { Text, View, StyleSheet } from "@react-pdf/renderer";


// Create styles
const styles = StyleSheet.create({
    cardBox: {
        position: "relative",
        zIndex: 0,
        width: "100%",
        maxHeight: "100%",
        padding: "0px 30px 10px",
        flexShrink: 0,
    },
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #4F4F4F",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
    },
    cardHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderTopLeftRadius: "3px",
        borderTopRightRadius: "3px",
        backgroundColor: "#333333",
        padding: "8pt 10pt"
    },
    cardHeaderText: {
        fontSize: "12pt",
        fontWeight: "medium",
        color: "#F5F5F5",
    },
    cardBody: {
        padding: 10,
        flexShrink: 0,
        overflow: "hidden",
    }
});

// Create Component
const CardBox = ({ heading, children }) => {
    return (
        <View style={styles.cardBox} wrap={false}>
            <View style={styles.cardContainer}>
                <View style={styles.cardHeader}>
                    <Text style={[styles.cardHeaderText]}>
                        {heading}
                    </Text>
                </View>
                <View style={styles.cardBody}>
                    {children}
                </View>
            </View>
        </View>
    );
};

export default CardBox;
