import { createSelector } from "reselect";
import { DOWNLOAD_CAMPAIGN_STATISTICS, DOWNLOAD_STATISTICS_BY_CAMPAIGN } from "./types";

export const networkState = (state) => state.network;
export const campaignStatisticsState = (state) => state.campaignStatistics;


export const getStatisticsCards = createSelector(campaignStatisticsState, (state) => {
  return state.cards;
});
// NETWORK
export const getDownloadStatisticsByCampaignLoading = createSelector(networkState, (state) => Boolean(state?.queries[DOWNLOAD_STATISTICS_BY_CAMPAIGN]?.pending));
export const getDownloadCampaignStatisticsLoading = createSelector(networkState, (state) => Boolean(state?.queries[DOWNLOAD_CAMPAIGN_STATISTICS]?.pending));