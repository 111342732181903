import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import truncate from "lodash/truncate";
// SURVEY ANALYTICS
import * as SurveyAnalytics from "survey-analytics";
import "survey-analytics/survey.analytics.css";

import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";
// HOOKS
import useAnalyticsAnswerHighlight from "../../hooks/useAnalyticsAnswerHighlight";
// REGEX
import { replaceTagsRegex } from "@/shared/regex";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// COMPONENTS
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader/AsrCommonLoader";
// STORE
import { getWindowWidth } from "@/store/Settings/selectors";
// STYLES
import cardStyles from "../../../card.module.scss";
import reactStringReplace from "@/shared/utils/stringReplace";
import { parsBracketsRegex } from "@/shared/regex/auditor-regex";


const AuditorSurveyAnalytics = ({ question, analytics, survey, locale, questionId, clearAnalytics, loading }) => {
  const windowWidth = useSelector(getWindowWidth);
  const LABEL_TRUNCATE_LENGTH = windowWidth > 780 ? 25 : 10;

  const truncateText = useCallback((text, length = LABEL_TRUNCATE_LENGTH) => {
    return truncate(text, {
      "length": length,
      "separator": "..."
    });
  }, [LABEL_TRUNCATE_LENGTH]);

  const noAnalytics = getIntlMessageToStringLocal(locale, "assessments.noAnalytics");

  const { checkIsUserAnswer } = useAnalyticsAnswerHighlight({ survey, question });

  const getQuestionLocalizations = () => {
    const locBool = {
      "yes": question?.locLabelTrue?.htmlValues[locale] ?? "Yes",
      "no": question?.locLabelFalse?.htmlValues[locale] ?? "No",
    };

    return question?.propertyHash?.choices?.reduce((acc, item) => {
      const localeData = {};
      const locale = survey.locale || "default";
      const locTextValue = item.locText.values[locale];
      const locTextKey = item.locText.values["default"] ?? item?.value;

      if (locTextValue && locTextKey) {
        localeData[locTextKey.trim().toLowerCase()] = locTextValue;
      }

      return {
        ...acc,
        ...localeData
      };
    }, {}) || locBool;
  };
  const modifyChartText = useCallback((options, chartType) => {
    const isRoundGraph = chartType === "pie" || chartType === "doughnut";
    const rootClass = !isRoundGraph ? ".ytick" : ".slicetext";

    const yticks = options.htmlElement.querySelectorAll(`${rootClass} text`);
    yticks.forEach((item) => {
      const parsedText = reactStringReplace(item.dataset.unformatted.replace(replaceTagsRegex, ""), parsBracketsRegex, (match) => {
        return match;
      }).join("");
      const unformatted = !isRoundGraph ? item.dataset.unformatted : item.dataset.unformatted.split("<br>")[0];
      item.style.fontWeight = "normal";
      const isUserAnswer = checkIsUserAnswer(unformatted.replace("&#x27;", "'"));
      if (isUserAnswer) {
        item.style.fontWeight = "bold";
      }

      if (isRoundGraph) {
        const [text] = item.dataset.unformatted.split("<br>");
        item.children[0].textContent = `${truncateText(text.replace("&#x27;", "'"), LABEL_TRUNCATE_LENGTH)}`;

        return;
      }
      // const len = item.clientWidth < 100 ? 8 : LABEL_TRUNCATE_LENGTH;
      item.innerHTML = truncateText(parsedText, 8);
    });
  }, [LABEL_TRUNCATE_LENGTH, checkIsUserAnswer, truncateText]);

  const setupPlotly = () => {
    SurveyAnalytics
      .PlotlySetup
      .onPlotCreating
      .add((model, options) => {
        if (options.layout?.yaxis?.automargin) {
          options.layout.yaxis.automargin = false;
        }
        // Long text responsive fixes
        model.onAfterRender.add((sender, options) => {
          const { chartType } = sender;
          modifyChartText(options, chartType);
        });
        const colorway = options?.layout?.colorway;
        if (options.traces) {
          options.traces.forEach(item => {
            const parsedCustomData = item.customdata.map(item => {
              return reactStringReplace(item.replace(replaceTagsRegex, ""), parsBracketsRegex, (match) => {
                return match;
              }).join("");
            });
            item.customdata = parsedCustomData;
            item.labels = parsedCustomData;
            item.text = parsedCustomData;
            item.hovertext = parsedCustomData;
            setAnalyticsCustomColors({ item, colorway });
            if (item.type === "scatter") {
              item.y = parsedCustomData;
            }
            if (item?.name) {
              item.name = item.name.replace(replaceTagsRegex, "");
            }
            if (item?.title?.text) {
              item.title.text = item.title.text.replace(replaceTagsRegex, "");
            }
          });
        }

        options.layout.paper_bgcolor = "white";
        options.layout.plot_bgcolor = "white";
      });
  };

  survey.onCurrentPageChanged.add((() => {
    clearAnalytics();
  }));

  const setAnalyticsCustomColors = ({ item, colorway }) => {
    const availableColors = {
      "yes": "#C0CF62",
      "no": "#EB5757",
      "not sure": "#56CCF2",
      "not applicable": "#BDBDBD"
    };
    const customColorData = {};

    const questionLocalizations = getQuestionLocalizations();
    Object.keys(availableColors).map((key) => {
      if (questionLocalizations && Object.keys(questionLocalizations).length) {
        const currentTranslatedQuestion = questionLocalizations[key.trim().toLowerCase()];

        customColorData[currentTranslatedQuestion] = availableColors[key];
      }
    });

    const customColors = item.labels.map((item, index) => {
      if (!customColorData[item]) {
        return colorway[index];
      }
      return customColorData[item];
    });

    if (customColors.length) {
      item.marker.color = item?.marker?.color ? customColors : [];
      item.marker.colors = item?.marker?.colors ? customColors : [];
    }
  };

  const initSurvey = useCallback((node) => {
    setupPlotly(questionId);
    const visPanel = new SurveyAnalytics.VisualizationPanel(
      [question],
      analytics,
      {
        survey: survey,
        haveCommercialLicense: true,
        allowDynamicLayout: true,
        allowHideQuestions: false,
        labelTruncateLength: -1
      }
    );

    visPanel.showHeader = false;

    if (node) {
      node.innerHTML = "";
      visPanel.render(node);
    }

  }, [analytics, question, survey]);

  useEffect(() => {
    const surveyResultNode = document.getElementById(`surveyResult_${questionId}`);
    initSurvey(surveyResultNode);
  }, [initSurvey, questionId]);


  return (
    <div className={cardStyles.analyticsContainer}>
      <>
        {loading ? (
          <div className={cardStyles.loadingContainer}>
            <AsrCommonLoader/>
          </div>
        ) : null}
        {analytics.length ? (
          <div
            id={`surveyResult_${questionId}`}
            className="asr-survey-auditor-analytics"/>
        ) : null
        }
      </>

      {!loading && !analytics.length ? (
        <div className={cardStyles.noAnalyticsPlaceholderContainer}>
          <div className={cardStyles.noAnalyticsPlaceholder}>
            {noAnalytics}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AuditorSurveyAnalytics;