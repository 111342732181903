import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  fetchTerm,
} from "@/store/Campaign/actions/actions";
import Box from "@mui/material/Box";
import reactStringReplace from "react-string-replace";
import { addSystemNotification } from "@/store/System/actions";
import CircularProgress from "@/shared/UI/Loaders/CircularProgress";
import "./styles.scss";

const BimDictTermModal = ({ show, setShow, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({
    title: "Leadership",
    description: "BIMe Topic under [[Managerial Competency]]: Leading and guiding others throughout the process of implementing new systems and workflows"
  });
  const { term } = props;
  useEffect(() => {
    let mounted = true;
    props.fetchTerm(term)
      .then((data) => {
        mounted && setContent(data.payload.response.data);
        mounted && setLoading(false);
      })
      .catch((data) => {
        mounted && setLoading(false);
        mounted && setContent({ description: data.payload.response?.data?.message });
        // props.addSystemNotification(
        //     '',
        //     data.payload.response?.data?.message || data.payload.response?.statusText || data.payload.message,
        //     'error'
        // );
      });
    return () => mounted = false;
  }, []);

  const preparedDescription = reactStringReplace(content.description, /\[\[(.*?)\]\]/gmi, (match, i) => (
    `<span class="term__term">${match}</span>`
  )).join("");

  return (
    <Modal
      classNames={{ modal: "modal-body" }}
      styles={{ overlay: { "zIndex": 1300 } }}
      open={show}
      onClose={() => setShow(false)}
      center
    >
      {loading ? (
        <Box minHeight={120}>
          <div className="react-responsive-modal-overlay" style={{ "backgroundColor": "rgba(0, 0, 0, 0.1)" }}>
            <CircularProgress className="react-responsive-modal-modalCenter"/>
          </div>
        </Box>
      ) : (
        <Box minHeight={120} className="d-flex flex-column justify-content-around">
          <span className="term__title">{content.title}</span>
          <div className="row mb-3">
            <p className="col-12 term__text" dangerouslySetInnerHTML={{ __html: preparedDescription }}/>
          </div>
        </Box>
      )}
    </Modal>
  );
};

const mapDispatchToProps = {
  fetchTerm,
  addSystemNotification
};

export default connect(null, mapDispatchToProps)(BimDictTermModal);