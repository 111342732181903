import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import { RegistrationsNestedLinks } from "./components/RegistrationsNestedLinks/RegistrationsNestedLinks";
import useRegistrationPermissions from "@/components/Organization/Registration/hooks/useRegistrationPermissions";
import useRegistrationFormPermissions from "@/components/Organization/Registration/hooks/useRegistrationFormPermissions";

export const SideNavRegistrations = ({ isStaffView, props }) => {
  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const { canSeeConfirmations } = useRegistrationPermissions();
  const { canViewRegistrationForm } = useRegistrationFormPermissions();

  if (!canViewRegistrationForm && !canSeeConfirmations) return null;

  return (
    <>
      <NavLink
        className={prependedClass}
        to={`${match.url}/registrations`}
        onClick={props.closeNavCallBack}
      >
        <IntlMessages id="organizationMainPage.registrations"/>
      </NavLink>
      <RegistrationsNestedLinks
        props={props}
        canSeeConfirmations={canSeeConfirmations}
        canViewRegistrationForm={canViewRegistrationForm}
      />
    </>
  );
};