import React from "react";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import CardBox from "../CardBox/CardBox";
import CardSection from "../../Components/CardSection";
import QuestionAnswer from "../../Components/QuestionAnswer";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";
import useAssessmentSectionsNames from "../../../Cards/useAssessmentSectionsNames";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    padding: "3px",
    border: "1px solid #24B2B2",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  chartTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "8pt",
    backgroundColor: "#F5F5F5",
    marginBottom: "15pt"
  },
  chartTitleText: {
    fontWeight: "medium",
    fontSize: "8pt",
    paddingVertical: "5pt",
    color: "#333333",
  },
  chartImage: {
    marginVertical: "5pt",
    maxHeight: 400
  },
  cardNoContent: {
    fontWeight: "medium",
    fontSize: "12pt",
    padding: "8pt 13pt",
    color: "#333333"
  }
});

// Create Component
const CardAssessmentQuestions = ({ data, title }) => {
  const noContent = useIntlMessageToString("reports.noContent");
  const sectionsNames = useAssessmentSectionsNames();
  const preparedQuestions = data?.reduce((acc, item) => {
    const sectionId = item.topic_id;

    let newArr;

    if (acc[sectionId]) {
      newArr = acc[sectionId].concat(item);
    } else {
      newArr = [item];
    }

    return {
      ...acc,
      [sectionId]: newArr,
    };

  }, {});

  return (
    <CardBox heading={title}>
      {data?.length > 0
        ? (
          <View style={styles.cardBody}>
            {Object.entries(preparedQuestions).map(([sectionId, sectionQuestions], index, arr) => (
                <CardSection
                    heading={sectionsNames[sectionId]}
                    lastChild={arr.length - 1 === index}
                    key={sectionId}
                >
                  {sectionQuestions.map((question) => (
                          <QuestionAnswer
                              key={question.question_id}
                              question={question}
                              compactSize
                          />
                      )
                  )}
                </CardSection>
            ))}
          </View>
        ) : (
          <Text style={styles.cardNoContent}>{noContent}</Text>
        )
      }
    </CardBox>
  );
};

export default CardAssessmentQuestions;
