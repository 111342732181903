import { useMemo } from "react";
// HELPERS
import {
  modifyDropdown,
  validateQuestionAnswer,
  modifyRadiogroup,
} from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/questions";


const useQuestionsConfiguration = ({
                                     surveyModel,
                                     publicSurveyEmail,
                                     onAfterRenderQuestionCallback,
                                     canModifyAnswers,
                                     validateLockAnswerProperty
                                   }) => {
  useMemo(() => {
      if (!surveyModel) return;
      surveyModel
        .onAfterRenderQuestion
        .add((survey, options) => {
          if (onAfterRenderQuestionCallback) {
            onAfterRenderQuestionCallback(options);
          }

          if (options.question.propertyHash.question_id === "1591151594106496" && publicSurveyEmail) {
            options.question.value = publicSurveyEmail;
            options.question.readOnly = true;
          }
          // GENERAL QUESTION ANSWERS MANAGEMENT
          validateQuestionAnswer({
            options,
            canModifyAnswers,
            validateLockAnswerProperty,
          });
          modifyDropdown(options);
          modifyRadiogroup(options);
        });
    },
    [surveyModel, publicSurveyEmail]);
};

export default useQuestionsConfiguration;