import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "./assets/jumbo-react/assets/vendors/style";
// LOCALIZATION
import AppLocale from "./localization";
// UTILS
import { getCurrentLocale } from "./utils/customFunctions";
import { appAnalyticsService } from "@/utils/analytics";
// CONFIGS
import { reloadDefaultJSONHeaders } from "./configs/MainConfig";
// COMPONENTS
import BimDictTermModal from "./components/Common/BimDictTermModal";
import NotificationsProvider from "./components/NotificationsProvider";
// MAIN APP ROUTER
import AppRouter from "./router/AppRouter";
// STORE
import { switchLanguage, updateWindowWidth } from "./store/Settings/actions";
// STYLES
import "./index.scss";


const MainApp = (props) => {

  const { switchLanguage, locale, updateWindowWidth } = props;

  const currentAppLocale = locale ? AppLocale[locale.locale] : AppLocale["en"];
  const prevCurrentAppLocale = useRef();
  const [showTermModal, setTermModalState] = useState(false);
  const [termData, setTermData] = useState(null);

  useEffect(() => {
    if (currentAppLocale && prevCurrentAppLocale.current !== currentAppLocale) {
      if (!prevCurrentAppLocale.current) {
        switchLanguage(getCurrentLocale());
      }
      prevCurrentAppLocale.current = currentAppLocale;
      reloadDefaultJSONHeaders();
    }
    window.openTermModal = openTermModal;
  }, [currentAppLocale, switchLanguage]);

  const openTermModal = match => {
    const term = encodeURIComponent(match);
    setTermData(term);
    setTermModalState(true);
  };

  useEffect(() => {
    const resize = () => {
      updateWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [updateWindowWidth]);

  useEffect(() => {
    appAnalyticsService.doInit();
  }, []);

  return (
    <>
      {prevCurrentAppLocale.current && <>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <div className='app-main'>
            <AppRouter/>
          </div>

          {/* COMMON MODALS */}
          {showTermModal && (
            <BimDictTermModal
              term={termData}
              show={showTermModal}
              setShow={(value) => setTermModalState(value)}
            />
          )}

        </IntlProvider>
        <NotificationsProvider/>
      </>}
    </>
  );
};

const mapStateToProps = (state) => ({
  locale: state.settings.locale
});

const mapDispatchToProps = {
  switchLanguage,
  updateWindowWidth
};

MainApp.displayName = "MainApp";
export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
