import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// CONSTANTS
import { QUESTION_CONTROL_MODE, QUESTIONS_PANELS, NoteEditorMode } from "@/shared/constants/surveyJs";
// HELPERS
import { getQuestionId } from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/questions";
// COMPONENTS
import SurveyHeaderActions from "../SurveyHeaderActions";
// STORE
import { getLocale } from "@/store/Settings/selectors";
import {
  getQuestionNotesChecked,
  getQuestionsAuditorControls,
  getQuestionsManagement,
} from "@/store/Survey/selectors";
import {
  modifySurveyAuditorQuestionPanel,
  setSurveyHeaderPanelToggleNote,
  toggleSurveyHeaderNotePanel
} from "@/store/Survey/actions";


const HeaderActions = ({
                         mode = QUESTION_CONTROL_MODE.user,
                         canModifyAssessorNotes = false,
                         canViewAssessorNotes = false,
                         currentNote,
                         questionId,
                         assessmentId,
                         assessmentType
                       }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(getLocale);
  const questionNotesChecked = useSelector(getQuestionNotesChecked);
  const auditorControls = useSelector(getQuestionsAuditorControls);
  const questionsManagement = useSelector(getQuestionsManagement);

  const [questionNoteData, setQuestionNoteData] = useState();

  useEffect(() => {
    const notes = questionsManagement?.[getQuestionId(questionId)]?.notes;
    setQuestionNoteData(notes);
  }, [questionId, questionsManagement]);

  const auditorNoteHandler = useCallback(() => {
    const notePayload = {
      "assessment": assessmentId,
      "auditor_controls": {
        "item_analytics": auditorControls?.headerSections?.analytics.checked,
        "recommendations": auditorControls?.headerSections?.recommendations.checked,
        "auditor_notes": !auditorControls?.headerSections?.notes.checked
      }
    };

    if (!assessmentType) {
      dispatch(toggleSurveyHeaderNotePanel(notePayload));
    }

    if (assessmentType === "registration") {
      dispatch(setSurveyHeaderPanelToggleNote(!auditorControls?.headerSections?.notes.checked));
    }

    const payload = {
      question_id: getQuestionId(questionId),
      question_panel: QUESTIONS_PANELS.NOTES,
      data: {
        currentNote: questionNoteData.currentNote,
        expanded: true,
        mode: NoteEditorMode.READ
      }
    };
    if (!questionNoteData.currentNote?.currentVersion) {
      payload.data = {
        ...payload.data,
        mode: NoteEditorMode.EDIT
      };
    }
    dispatch(modifySurveyAuditorQuestionPanel(payload));
  }, [
    assessmentId,
    assessmentType,
    auditorControls,
    dispatch,
    questionId,
    questionNoteData
  ]);

  const userNoteHandler = () => {

  };

  return (
    <SurveyHeaderActions
      assessmentType={assessmentType}
      isVisible={canViewAssessorNotes && !questionNotesChecked}
      mode={mode}
      locale={locale}
      canModifyAssessorNotes={canModifyAssessorNotes}
      currentNote={currentNote}
      noteHandler={mode === QUESTION_CONTROL_MODE.user ? userNoteHandler : auditorNoteHandler}
      question_id={questionId}
    />
  );
};

export default HeaderActions;