export const SIGN_IN_USER_BASIC = "SIGN_IN_USER_BASIC";
export const SIGN_UP_USER_BASIC = "SIGN_UP_USER_BASIC";
export const USER_LOGOUT = "USER_LOGOUT";
export const REFRESH_USER_TOKEN = "REFRESH_USER_TOKEN";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const VERIFY_RESTORE_PASSWORD_TOKEN = "VERIFY_RESTORE_PASSWORD_TOKEN";
export const RESTORE_PASSWORD_BY_TOKEN = "RESTORE_PASSWORD_BY_TOKEN";
export const EMAIL_VERIFYING = "EMAIL_VERIFYING";
export const UPATE_USER_PROFILE = "UPATE_USER_PROFILE";
export const SIGN_UP_USER_BY_PASSCODE = "SIGN_UP_USER_BY_PASSCODE";
export const SIGN_IN_USER_BY_PASSCODE = "SIGN_IN_USER_BY_PASSCODE";
export const FETCH_TOKEN_BY_EMAIL = "FETCH_TOKEN_BY_EMAIL";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
