import { success, error } from "redux-saga-requests";
import * as types from "./types";

const initialState = {
  users: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {},
  },
  errors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.FETCH_USERS_LIST): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;
      return {
        ...state,
        users: {
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        },
        errors: {}
      };
    }

    case error(types.FETCH_USERS_LIST): {
      return {
        ...state,
        errors: action.payload.response.data.errors,
      };
    }

    default:
      return state;
  }
};