import axios from "axios";
import { baseApiUrl } from "@/configs/MainConfig";
import { authService } from "@/shared/services/auth.service";

export default (data, extension, fileName = "report") => {
  let blob;
  if (extension === "xlsx") {
    blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  } else {
    blob = new Blob(["\ufeff", data]);
  }

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFromLink = async ({ link, fileName = "file" , extension = "xlsx"}) => {
  await axios({
    url: `${baseApiUrl}${link}`,
    method: "GET",
    responseType: "blob",
    headers: { ...authService.getAuthHeaders() }
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.${extension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};