import usePolicyValidation from "@/shared/hooks/policyValidation/usePolicyValidation";
import { actionsConstants } from "@/shared/hooks/policyValidation";

const useAuditorPermissions = () => {
  const isAuditor = usePolicyValidation({
    action: actionsConstants.AuditorIsAuditor,
  });

  const canAuditorAnswerNoteCreate = usePolicyValidation({
    action: actionsConstants.AuditorAnswerNoteCreate,
  });

  const canAuditorAnswerNoteModify = usePolicyValidation({
    action: actionsConstants.AuditorAnswerNoteModify,
  });

  const canAuditorAnswerNoteLockUnlock = usePolicyValidation({
    action: actionsConstants.AuditorAnswerNoteLockUnlock,
  });

  const canAuditorAnswerNoteDelete = usePolicyValidation({
    action: actionsConstants.AuditorAnswerNoteDelete,
  });

  const canAuditorAnswerAnalyticsView = usePolicyValidation({
    action: actionsConstants.AuditorAnswerAnalyticsView,
  });

  const canAuditorAnswerVerify = usePolicyValidation({
    action: actionsConstants.AuditorAnswerVerify,
  });

  const canAuditorAnswerModify = usePolicyValidation({
    action: actionsConstants.AuditorAnswerModify,
  });

  const canAuditorLockUnlockAssessmentAccess = usePolicyValidation({
    action: actionsConstants.AuditorAssessmentAccessLockUnlock
  });

  const canAuditorDownloadReportXLSX = usePolicyValidation({
    action: actionsConstants.AuditorDownloadReportXLSX
  });

  return {
    isAuditor,
    canAuditorAnswerNoteCreate,
    canAuditorAnswerNoteModify,
    canAuditorAnswerNoteLockUnlock,
    canAuditorAnswerNoteDelete,
    canAuditorAnswerAnalyticsView,
    canAuditorAnswerVerify,
    canAuditorAnswerModify,
    canAuditorLockUnlockAssessmentAccess,
    canAuditorDownloadReportXLSX,
  };
};

export default useAuditorPermissions;