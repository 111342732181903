import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
// HELPERS
import NestedLinks from "../../../../../../../helpers/NestedLinks/NestedLinks";


const AuditorsNestedLinks = ({
                               canSeeAssessorNotes,
                               canSeeAuditorAssignments,
                               props
                             }) => {
  const match = useRouteMatch();

  const list = [
    {
      to: `${match.url}/assessor/dashboard#assignments`,
      hash: "#assignments",
      onClick: props.closeNavCallBack,
      isVisible: canSeeAuditorAssignments,
      label: "sidenav.assignments"
    },
    {
      to: `${match.url}/assessor/dashboard#notes`,
      hash: "#notes",
      onClick: props.closeNavCallBack,
      isVisible: canSeeAssessorNotes,
      label: "notes.itemNotes.multiple"
    },
  ];

  return (
    <Route path={`${match.path}/assessor`}>
      <NestedLinks links={list}/>
    </Route>
  );
};

export default AuditorsNestedLinks;