import React, { useRef, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// HOOKS
import useInitialValues from "../../hooks/useInitialValues";
import useValidationSchema from "../../hooks/useValidationSchema";
import useErrors from "@/shared/hooks/errors/useErrors";
// COMPONENTS
import SignUpFormHeader from "./components/SignUpFormHeader";
import SignUpFormFooter from "./components/SignUpFormFooter";
import SignUpFormFields from "./components/SignUpFormFields";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
// HELPERS
import prepareSubmitData from "../../helpers/prepareSubmitData";
// STORE
import { commonAppModals } from "@/store/System/selectors";
import { signUpUserBasic } from "@/store/User/actions";
// CONSTANTS
import { systemModals } from "@/shared/constants/system";
// STYLES
import styles from "./styles.module.scss";


const SignUpLightModalForm = ({
                                showSucceedFormHandler,
                                closeModal
                              }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const { handleFetchErrors, setFormFieldErrors } = useErrors();

  const modalState = useSelector(commonAppModals)?.[systemModals.SIGN_UP];
  const initialValues = useInitialValues({ userEmail: modalState?.user?.email });
  const validationSchema = useValidationSchema();

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    try {
      await dispatch(signUpUserBasic(prepareSubmitData(values)));
      showSucceedFormHandler(values);
    } catch (error) {
      const errorData = error.payload.response?.data;

      await handleFetchErrors(errorData, { notificationWhiteList: "*" });
      formRef?.current && setFormFieldErrors({
        setFieldError: formRef.current.setFieldError,
        details: errorData?.details
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {fmk => (
        <>
          <Form>
            <div className={styles.ModalContentContainer}>
              <SignUpFormHeader
                closeModal={closeModal}
                modalState={modalState}
              />
              <SignUpFormFields fmk={fmk}/>
              <SignUpFormFooter fmk={fmk}/>
            </div>
          </Form>

          {isLoading && (
            <AsrCommonLoader/>
          )}
        </>
      )}
    </Formik>
  );
};

export default SignUpLightModalForm;
