import React from "react";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
// COMPONENTS
import ValidateUserPasswordFormFields from "./components/ValidateUserPasswordFormFields";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
// STORE
import { useValidateUserPasswordMutation } from "@/store/services/users/rtk";
import { addSystemNotification } from "@/store/System/actions";
// CONSTANTS
import { validationSchema } from "./constants";
// UTILS
import { useIntlMessageToString } from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";

const ValidateUserPasswordModalBody = ({
                                         onSuccessHandler,
                                         formRef
}) => {
  const dispatch = useDispatch();
  const [validateUserPassword, { isLoading: isVerifying }] = useValidateUserPasswordMutation();
  const errorMessage = useIntlMessageToString("appModule.passwordIncorrect");

  const onSubmitForm = async (values) => {
    try {
      const response = await validateUserPassword({ password: values.password }).unwrap();
      if (response?.is_valid) {
        onSuccessHandler && onSuccessHandler({
          password: values.password,
          isValid: response.is_valid
        });
      } else {
        await dispatch(addSystemNotification("", errorMessage, "error"));
      }
    } catch (e) {
      await dispatch(addSystemNotification("", errorMessage, "error"));
    }
  };

  return (
    <>
      <Formik
        initialValues={{ password: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {fmk => (
          <Form className={styles.ModalContentForm}>
            {/* MODAL FORM FIELDS */}
            <ValidateUserPasswordFormFields fmk={fmk}/>
          </Form>
        )}
      </Formik>

      {isVerifying && (
        <AsrCommonLoader/>
      )}
    </>
  );
};

export default ValidateUserPasswordModalBody;
