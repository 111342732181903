import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const account = {
  [actionsConstants.AccountDashboardView]: simpleValidator,
  [actionsConstants.AccountModify]: simpleValidator,
  [actionsConstants.AccountArchive]: simpleValidator,
  [actionsConstants.AccountViewLogs]: simpleValidator,
};

export default account;