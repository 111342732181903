import { useEffect } from "react";

const useCurrentPageChanged = ({
                                 surveyModel,
                                 countUnansweredQuestions,
                                 doOnCurrentPageChanged
                               }) => {
  useEffect(() => {
    if (surveyModel) {
      surveyModel
        .onCurrentPageChanged
        .add((survey) => {
          countUnansweredQuestions(surveyModel.getAllQuestions());
          doOnCurrentPageChanged(survey);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    surveyModel
  ]);
};

export default useCurrentPageChanged;