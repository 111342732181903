import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
// STORE
import { getLocale } from "@/store/Settings/selectors";
// STYLES
import styles from "./styles.module.scss";


const AsrAutocompleteMultipleRenderInput = ({
                                              label,
                                              selectedItems,
                                              currentTarget,
                                              setInputWidth,
                                              classes = {
                                                root: "",
                                                label: ""
                                              }
                                            }) => {
  const { locale } = useSelector(getLocale);
  const inputRef = useRef();

  useEffect(() => {
    if (setInputWidth) {
      setInputWidth(inputRef?.current?.clientWidth);
    }
  }, [locale, currentTarget, inputRef, setInputWidth]);

  const item = useMemo(() => {
    if (Array.isArray(selectedItems)) {
      return selectedItems?.length || "";
    } else {
      return "";
    }
  }, [selectedItems]);

  const placeholderClassName = classnames({
    "asr-table-autocomplete--multi-render-input": true,
    [styles.AsrAutocompleteMultipleRenderInput]: true,
    [classes?.root]: classes?.root
  });

  const labelClassName = classnames({
    [styles.RenderInputLabel]: true,
    [classes?.label]: classes?.label
  });

  const labelHiddenClassName = classnames({
    [styles.RenderInputLabelHidden]: true,
    [classes?.label]: classes?.label
  });

  const labelCountClassName = classnames({
    [styles.RenderInputCount]: true,
    [classes?.label]: classes?.label
  });

  return (
    <div className={placeholderClassName}>
      {item && (
        <span className={labelCountClassName}>
          {item}
        </span>
      )}
      <span className={labelHiddenClassName} ref={inputRef}>
        {label}
      </span>
      <span className={labelClassName}>
        {label}
      </span>
    </div>
  );
};

export default AsrAutocompleteMultipleRenderInput;