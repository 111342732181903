// TAGS
export const EDIT_PROFILE_INFO_TAG = "edit-profile-info";
export const USER_PROFILE_INFO_TAG = "user-profile-info";

// TARGETS
export const UPDATE_PROFILE_TARGET = "update-profile";
export const UPDATE_AVATAR_TARGET = "update-avatar";
export const MY_PROFILE_TARGET = "my-profile";
export const VALIDATE_SELF_PASSWORD_TARGET = "check-self-password";
export const SEND_USER_VERIFICATION_EMAIL_TARGET = "send-user-verification";
export const VERIFY_USER_TARGET = "verify-user";
