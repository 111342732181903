import React, { memo } from "react";

import "./styles.scss";

const AsrCloseIcon = memo(({ iconClassName }) => {
  return (
      <div className={`asr-close-icon ${iconClassName}`}>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17 2.21619L15.2838 0.5L8.5 7.28381L1.71619 0.5L0 2.21619L6.78381 9L0 15.7838L1.71619 17.5L8.5 10.7162L15.2838 17.5L17 15.7838L10.2162 9L17 2.21619Z"
              fill="#333333"/>
        </svg>
      </div>
  );
});

export default AsrCloseIcon;