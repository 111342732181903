import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { store } from "../../store";

export default class ReactDomFactory {
  /**
   * Injects a react component
   * @param {object}      module    - The react component
   * @param {object}      props    - Props to initiate component with
   * @param {HTMLElement}    target    - The target element to inject to
   */
  static inject(module, target, props = {}) {
    if (target) {
      if (target.length) {
        target.forEach((item) => {
          ReactDOM.render(
            <Provider store={store}>
              {React.createElement(module, props || {})}
            </Provider>,
            item,
          );
        });
      } else {
        ReactDOM.render(
          <Provider store={store}>
            {React.createElement(module, props || {})}
          </Provider>,
          target,
        );
      }
    } else {
      console.error("RHW07", "Target element is null or undefined.");
    }
  }

  /**
   *  Disposes a React component
   * @param {(HTMLElement|HTMLElement[])}    target    - The target element to dispose
   */
  static dispose(target) {
    if (target) {
      if (target.length) {
        target.forEach((item) => {
          ReactDOM.unmountComponentAtNode(item);
          item.remove();
        });

      } else {
        if (target.length === 0) return;
        ReactDOM.unmountComponentAtNode(target);
        target.remove();
      }
    }
  }
}