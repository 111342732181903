import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Route } from "react-router-dom";
// UTILS
import IntlMessages from "../../../../utils/IntlMessages";
// STORE
import { getAccountsState } from "../../../../store/Organization/rootReducers/accounts/selectors";
import { useGetAccountsQuery } from "../../../../store/services/accounts/accounts";
// STYLES
import styles from "./styles.module.scss";


const SideNavOrganizations = ({
                                closeNavCallBack
                              }) => {
  const {
    pagination: {
      page_size,
      page
    }
  } = useSelector(getAccountsState);
  const { data: accountsData } = useGetAccountsQuery({
      page,
      page_size,
    },
  );

  return (
    <>
      <NavLink
        className="prepend-icon admin"
        to="/organizations/"
        onClick={closeNavCallBack}
      >
          <span className="nav-text">
              <IntlMessages id="sidenav.organizations"/>
          </span>
      </NavLink>
      <Route path="/organizations/">
        <div className={styles.OrganizationLinkList}>
          {accountsData?.results?.map((item) => {
            return (
              <NavLink
                key={item.id}
                className="nested-link"
                to={`/organization/${item.id}`}
                onClick={closeNavCallBack}
              >
                {item?.content?.name}
              </NavLink>
            );
          })}
        </div>
      </Route>
    </>
  );
};

SideNavOrganizations.displayName = "SideNavOrganizations";
export default SideNavOrganizations;