import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
// COMPONENTS
import LanguageSelectorSurvey from "./components/LanguageSelectorSurvey";
import LanguageSelectorSystem from "./components/LanguageSelectorSystem";
// STORE
import { getAvailableLocales } from "@/store/Survey/selectors";


const LanguageSelector = ({
                            onItemCLickHandler,
                            selectorConfig,
                            classes = {
                              root: "",
                              label: "",
                              icon: "",
                              dropdownToggle: ""
                            }
                          }) => {
  const usedSurveyLocales = useSelector(getAvailableLocales);
  const isSurveyViewerShowed = Boolean(usedSurveyLocales?.length);

  return (
    <>
      {isSurveyViewerShowed ? (
        <LanguageSelectorSurvey
          onItemCLickHandler={onItemCLickHandler}
          classes={classes}
          hideIfOnlyOneLang={selectorConfig?.surveySelector?.hideIfOnlyOneLang}
        />
      ) : (
        <LanguageSelectorSystem
          onItemCLickHandler={onItemCLickHandler}
          classes={classes}
        />
      )}
    </>

  );
};

LanguageSelector.propTypes = {
  onItemCLickHandler: PropTypes.func,
  selectorConfig: PropTypes.shape({
    surveySelector: PropTypes.shape({
      hideIfOnlyOneLang: PropTypes.bool
    }),
    appSelector: PropTypes.object
  }),
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    dropdownToggle: PropTypes.string
  })
};

export default LanguageSelector;