import React from "react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, LabelList } from "recharts";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import ChartSvg from "../../Components/Chart";
import CardBox from "../CardBox/CardBox";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";
import prepareTextForChart from "../../helpers/prepareChartText";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    padding: "3px",
    border: "1px solid #24B2B2",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardNoContent: {
    fontWeight: "medium",
    fontSize: "12pt",
    padding: "8pt 13pt",
    color: "#333333"
  }
});

// Create Component
const Card7 = ({ data }) => {
  const heading = useIntlMessageToString("reports.card7Title");
  const noContent = useIntlMessageToString("reports.noContent");
  const reasonsQuestion = data.find((question) => question.question_id === "1591353106185851") || {};

  const { value: reasons = [] } = reasonsQuestion;

  const preparedReasons = reasons.map(i => ({ ...i, title: prepareTextForChart(i.title) }));
    
  return (
    <CardBox heading={heading}>
      {reasons.length > 0
        ? (
          <View style={styles.cardBody}>
            <View>
              <ChartSvg>
                <BarChart
                  data={preparedReasons}
                  width={500}
                  height={320}
                >
                  <XAxis
                    height={120}
                    axisLine={false}
                    tick={false}
                  />
                  <YAxis
                    width={10}
                    type='number'
                    domain={[0, 5]}
                    ticks={[0, 1, 2, 3, 4, 5]}
                    axisLine={false}
                    tickLine={false}
                  />
                  <CartesianGrid
                    stroke='#f5f5f5'
                    vertical={false}
                  />
                  <Bar
                    dataKey='value'
                    fill='#4472c4'
                    barSize={20}
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey='title'
                      position='bottom'
                      offset={10}
                    />
                  </Bar>
                </BarChart>
              </ChartSvg>
            </View>
          </View>
        ) : (
          <Text style={styles.cardNoContent}>{noContent}</Text>
        )
      }
    </CardBox>
  );
};

export default Card7;
