import React from "react";
// MATERIAL UI
import Checkbox from "@mui/material/Checkbox";
// STYLES
import styles from "./styles.module.scss";

const AsrCheckBoxSmall = ({
                            id,
                            checked,
                            disabled,
                            handler,
                            classes = {
                              root: "",
                              checkedIcon: "",
                              icon: ""
                            },
                            ...props
                          }) => {
  return (
    <Checkbox
      {...props}
      id={id}
      checked={checked}
      disabled={disabled}
      classes={{
        root: `${styles.checkbox} ${classes.root}`,
        checked: styles.checked
      }}
      onChange={handler}
      checkedIcon={<div className={`${styles.icon} ${styles.checkedIcon} ${classes.checkedIcon}`}/>}
      icon={<div className={`${styles.icon} ${classes.icon} ${disabled && styles.iconDisabled}`}/>}
    />
  );
};

AsrCheckBoxSmall.displayName = "AsrCheckBoxSmall";
export default AsrCheckBoxSmall;