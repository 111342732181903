import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import AsrMuiInput from "@/components/Common/FormComponentsNew/AsrMuiInput";
import AsrLogo from "@/components/Common/AsrLogo";
// STYLES
import styles from "../styles.module.scss";


const RestorePasswordFormFields = ({ fmk }) => {

  const onChangeEmail = (e) => {
    fmk.setFieldValue("email", e.target.value);
  };

  return (
    <div className={styles.ModalFormFieldsBlockWrapper}>
      <div className={styles.ModalFormFieldsWrapper}>
        <AsrMuiInput
          label={<IntlMessages id="appModule.email"/>}
          classes={{
            inputRoot: styles.InputRoot,
            labelRoot: styles.InputLabel
          }}
          type="email"
          name="email"
          error={fmk?.errors?.email}
          isTouched={fmk?.touched?.email}
          onChange={onChangeEmail}
          autoComplete="email"
        />

        <div className={styles.InfoText}>
          <IntlMessages id="auth.resetPasswordHelpMessage"/>
        </div>
      </div>

      <AsrLogo
        redirectOnClick={false}
        classes={{
          logo: styles.AsrModalLogo,
          container: styles.ModalFormFieldsLogo
        }}
      />
    </div>
  );
};

export default RestorePasswordFormFields;
