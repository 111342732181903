import React from "react";
import ReactDOM from "react-dom";
// import {Helmet} from "react-helmet";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { appService } from "./shared/services/appService/app.service";
import { store, history } from "./store";
import MainApp from "./MainApp";
import "./index.scss";

appService.bootstrap();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MainApp/>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("app-site"));
