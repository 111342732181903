import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: 36,
    height: 18,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: "white",
    "&$checked": {
      transform: "translateX(17px)",
      color: "white",
      "& + $track": {
        opacity: 1,
        backgroundColor: "var(--asr-a-red)",
        borderColor: "transparent",
      },
      "&:hover + $track": {
        backgroundColor: "var(--asr-a-blue)",
        borderColor: "transparent",
        transition: ".4s"
      }
    },
    "&:hover + $track": {
      backgroundColor: "var(--asr-a-blue)",
      transition: ".4s"
    }
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  track: {
    border: "1px solid #BDBDBD",
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: (props) => props.trackBgColor,
    boxShadow: "inset 0.6px 0.6px 2.4px rgba(0, 0, 0, 0.25)",
  },
  checked: {},
}));

export default useStyles;