import {
  usePolicyValidation,
  actionsConstants
} from "@/shared/hooks/policyValidation";


const useRegistrationActPermissions = () => {
  const canRegistrationActModify = usePolicyValidation({
    action: actionsConstants.RegistrationActModify
  });

  const canRegistrationActModifyStatus = usePolicyValidation({
    action: actionsConstants.RegistrationActModifyStatus
  });

  const canRegistrationActArchive = usePolicyValidation({
    action: actionsConstants.RegistrationActArchive,
  });

  const canRegistrationActDelete = usePolicyValidation({
    action: actionsConstants.RegistrationActDelete,
  });

  const canRegistrationActAnswerVerify = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerVerify,
  });

  const canRegistrationActAnswerModify = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerModify,
  });

  const canRegistrationActAnswerNoteView = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerNoteView,
  });

  const canRegistrationActAnswerNoteCreate = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerNoteCreate,
  });

  const canRegistrationActAnswerNoteModify = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerNoteModify,
  });

  const canRegistrationActAnswerNoteDelete = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerNoteDelete,
  });

  const canRegistrationActAnswerNoteLockUnlock = usePolicyValidation({
    action: actionsConstants.RegistrationActAnswerNoteLockUnlock,
  });

  const canRegistrationActDownloadReportXLSX = usePolicyValidation({
    action: actionsConstants.RegistrationActDownloadReportXLSX
  });

  return {
    canRegistrationActModify,
    canRegistrationActModifyStatus,
    canRegistrationActArchive,
    canRegistrationActDelete,
    canRegistrationActAnswerVerify,
    canRegistrationActAnswerModify,
    canRegistrationActAnswerNoteView,
    canRegistrationActAnswerNoteCreate,
    canRegistrationActAnswerNoteModify,
    canRegistrationActAnswerNoteDelete,
    canRegistrationActAnswerNoteLockUnlock,
    canRegistrationActDownloadReportXLSX
  };
};

export default useRegistrationActPermissions;