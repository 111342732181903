import React from "react";
import { get } from "lodash";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Legend } from "recharts";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import ChartSvg from "../../Components/Chart";
import CardBox from "../CardBox/CardBox";
import QuestionAnswer from "../../Components/QuestionAnswer";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3px",
    border: "1px solid #24B2B2",
    overflow: "hidden",
  },
  chartTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "8pt",
    backgroundColor: "#F5F5F5",
    marginBottom: "15pt"
  },
  chartTitleText: {
    fontWeight: "medium",
    fontSize: "8pt",
    paddingVertical: "5pt",
    color: "#333333",
  }
});

// Create Component
const Card4 = ({ data, isIqcRelated }) => {
  const heading = useIntlMessageToString("reports.card4Title");
  const budgetDistribution = useIntlMessageToString("reports.budgetDistribution");
  const chart = useIntlMessageToString("reports.chart");
  const avgLabel = useIntlMessageToString("reports.average");
  const radarDataTranslations = {
    "Managerial": useIntlMessageToString("reports.managerial"),
    "Administration": useIntlMessageToString("reports.administration"),
    "Functional": useIntlMessageToString("reports.functional"),
    "Operational": useIntlMessageToString("reports.operational"),
    "Technical": useIntlMessageToString("reports.technical"),
    "Implementation": useIntlMessageToString("reports.implementation"),
    "Supportive": useIntlMessageToString("reports.supportive"),
    "Research and Development": useIntlMessageToString("reports.researchDevelopment"),
  };

  const yearQuestion = data.find((question) => question.question_id === "1591353106185774") || {};
  const projectsNumberQuestion = data.find((question) => question.question_id === "1591353106185777") || {};
  const projectsPercentageQuestion = data.find((question) => question.question_id === "1591353106185779") || {};
  const radarData = get(data.find((question) => question.type === "score"), "items", []);

  const preparedRadarData = radarData.map((item) => ({ ...item, title: radarDataTranslations[item.title] }));

  yearQuestion.title = useIntlMessageToString("reports.bimAdoption");
  projectsNumberQuestion.title = useIntlMessageToString("reports.completedProjects");
  projectsPercentageQuestion.title = useIntlMessageToString("reports.bimProjects");

  return (
    <CardBox heading={heading}>
      <View style={styles.cardBody}>
        <QuestionAnswer
          question={yearQuestion}
        />
        <QuestionAnswer
          question={projectsNumberQuestion}
        />
        <QuestionAnswer
          question={projectsPercentageQuestion}
        />
        <View style={styles.chartTitle}>
          <Text style={styles.chartTitleText}>{budgetDistribution} </Text>
          <Text style={styles.chartTitleText}>{chart}:</Text>
        </View>
        {isIqcRelated && (
          <View>
            <ChartSvg>
              <RadarChart
                width={500}
                height={300}
                outerRadius={80}
                data={preparedRadarData}
              >
                <Radar
                  name='SCORE'
                  dataKey='score'
                  stroke='#0c77c1'
                  fill='#ffffff'
                  fillOpacity={0}
                  strokeWidth={2}
                  isAnimationActive={false}
                />
                <Radar
                  name={avgLabel}
                  dataKey='moy'
                  stroke='#4ea684'
                  fill='#ffffff'
                  fillOpacity={0}
                  strokeWidth={2}
                  isAnimationActive={false}
                />
                <PolarGrid/>
                <PolarAngleAxis
                  dataKey='title'
                />
                <PolarRadiusAxis
                  type='number'
                  angle={90}
                  domain={[0, 100]}
                  orientation='left'
                />
                <Legend iconType={"line"}/>
              </RadarChart>
            </ChartSvg>
          </View>
        )}
      </View>
    </CardBox>
  );
};

export default Card4;
