import useAuditorPermissions from "../../../../Organization/Assessor/hooks/useAuditorPermissions";
import useAssessmentActPermissions from "../../../../../pages/Campaign/hooks/useAssessmentActPermissions";
import useRegistrationActPermissions
  from "../../../../../pages/Organization/OrganizationRegistration/hooks/useRegistrationActPermissions";


const useNotePermissions = ({ question, forAssessorMemberProfile, campaignId }) => {
  const isRegistration = question.registration_id;

  // ASSESSMENT
  const {
    canAssessmentActAnswerNoteView,
    canAssessmentActAnswerNoteCreate,
    canAssessmentActAnswerNoteModify,
    canAssessmentActAnswerNoteDelete
  } = useAssessmentActPermissions({ campaignId });
  // REGISTRATION
  const {
    canRegistrationActAnswerNoteView,
    canRegistrationActAnswerNoteCreate,
    canRegistrationActAnswerNoteModify,
    canRegistrationActAnswerNoteDelete
  } = useRegistrationActPermissions();

  const canNoteView = !isRegistration ? canAssessmentActAnswerNoteView : canRegistrationActAnswerNoteView;
  const canNoteCreate = !isRegistration ? canAssessmentActAnswerNoteCreate : canRegistrationActAnswerNoteCreate;
  const canNoteModify = !isRegistration ? canAssessmentActAnswerNoteModify : canRegistrationActAnswerNoteModify;
  const canNoteDelete = !isRegistration ? canAssessmentActAnswerNoteDelete : canRegistrationActAnswerNoteDelete;

  // AUDITOR
  const {
    isAuditor,
    canAuditorAnswerNoteCreate,
    canAuditorAnswerNoteModify,
    canAuditorAnswerNoteDelete
  } = useAuditorPermissions();

  const canViewAssessorNotes = forAssessorMemberProfile ? isAuditor : canNoteView;

  const canCreateAssessorNotes = forAssessorMemberProfile ? canAuditorAnswerNoteCreate : canNoteCreate;

  const canModifyAssessorNotes = forAssessorMemberProfile ? canAuditorAnswerNoteModify : canNoteModify;

  const canRemoveAssessorNotes = forAssessorMemberProfile ? canAuditorAnswerNoteDelete : canNoteDelete;

  return {
    isAuditor,
    canViewAssessorNotes,
    canCreateAssessorNotes,
    canModifyAssessorNotes,
    canRemoveAssessorNotes
  };
};

export default useNotePermissions;