import React from "react";
import * as Yup from "yup";
import IntlMessages from "@/utils/IntlMessages";

const useValidationSchema = () => {
  return Yup.object({
    passcode: Yup.string()
      .required(<IntlMessages id='appModule.required'/>)
  });
};

export default useValidationSchema;
