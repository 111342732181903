import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "../styles.module.scss";


const RestorePasswordFormHeader = () => {
  return (
    <div className={styles.ModalHeaderBlock}>
      <div className={styles.ModalHeader}>
        <div className={styles.ModalHeaderTitle}>
          <IntlMessages id="user.restorePassword"/>
        </div>
      </div>
    </div>

  );
};

export default RestorePasswordFormHeader;
