import { useCallback, useState } from "react";

const useScrollTrack = (props) => {
  const [loading, setLoading] = useState(false);

  const trackScroll = useCallback((e) => {
    if (loading) return;

    const { fetchData, data } = props;
    const { id, next, page, filters } = data;

    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && next) {
      const nextPage = page + 1;

      if (filters) {
          setLoading(true);
          fetchData(id, filters, nextPage).then(() => setLoading(false));
      } else {
          setLoading(true);
          fetchData(id, null, nextPage).then(() => setLoading(false));
      }
    }
  }, [props, loading]);

  return props && trackScroll;
};

export default useScrollTrack;
