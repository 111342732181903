import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import SignUpFormAccountCodeSwitcher from "./SignUpFormAccountCodeSwitcher";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// STYLES
import styles from "../styles.module.scss";


const SignUpFormHeader = ({
                            closeModal,
                            modalState
                          }) => {
  const modalToggleHandlers = useToggleCommonModalHandlers();

  const campaignName = modalState?.campaign?.name;

  const onSignInClick = () => {
    closeModal();
    modalToggleHandlers.signIn({ show: true });
  };

  return (
    <div className={styles.ModalHeaderBlock}>
      <div className={styles.ModalHeader}>
        <div className={styles.ModalHeaderTitle}>
          <IntlMessages id="appModule.signUp"/>
        </div>
        <div className={styles.ModalHeaderTextBlock}>
          <div className={styles.ModalHeaderText}>
            <IntlMessages id="auth.haveAnAccount"/>?
          </div>

          <div
            className={`${styles.ModalHeaderText} ${styles.Clickable}`}
            onClick={onSignInClick}
          >
            <IntlMessages id="user.signInInstead"/>
          </div>
        </div>
      </div>

      <SignUpFormAccountCodeSwitcher campaignName={campaignName}/>
    </div>

  );
};

export default SignUpFormHeader;
