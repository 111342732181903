import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// TYPES
import surveyStateTypes from "@/store/SurveyAssessment/surveyStateTypes";
// SERVICES
import SurveyDomBuilder from "@/components/SurveyJs/components/ViewerV2/services/survey-dom-builder.service";
// HOOKS
import useSurveyModel from "@/shared/Survey/hooks/useSurveyModel";
import {
  useQuestionControls,
  useUpdateQuestionCssClasses,
  useQuestionsConfiguration,
  useTextMarkdown,
  useValueChanged,
  useCurrentPageChanged,
  useUpdatePageCssClasses
} from "@/components/SurveyJs/components/ViewerV2/hooks/surveyHooks";
// WIDGETS
import "../helpers/surveyCore/initilConfigSurvey";
// UTILS
import { attachEventToBracketsTooltipText } from "@/utils/customFunctions";
// STORE
import { getLocale } from "@/store/Settings/selectors";
import { getCurrentLocale } from "@/store/Survey/selectors";
import { getQuestionData } from "@/store/SurveyAssessment/selectors";
import { resetSurveyAssessmentAnswer, unmountSurveyViewer, mountSurveyViewer } from "@/store/Survey/actions";


const useInitializeSurvey = ({
                               templateBody,
                               progressInfo,
                               readOnly,
                               callbacks,
                               permissions,
                               validations,
                               publicSurveyEmail,
                               parentTarget,
                               service
                             }) => {
  const surveyDomBuilder = new SurveyDomBuilder();
  const dispatch = useDispatch();
  const { locale: languageCode } = useSelector(getLocale);
  const surveyLocale = useSelector(getCurrentLocale);
  const questionData = useSelector(getQuestionData);

  // HANDLERS
  const initState = useCallback((surveyModel) => {
    const {
      sjs_answers: sjsAnswers = {},
      object_answers: arrayOfAnswersObjs = []
    } = progressInfo || {};

    let answers;
    if (Object.keys(sjsAnswers).length > 0) {
      surveyModel.data = sjsAnswers;
      answers = arrayOfAnswersObjs;
    }
    dispatch({
      type: surveyStateTypes.INITIALIZE_STATE,
      payload: {
        questions: surveyModel.getAllQuestions(),
        answers: answers
      }
    });
  }, [progressInfo, dispatch]);

  const doOnCurrentPageChanged = useCallback((survey) => {
    dispatch({
      type: surveyStateTypes.ON_CURRENT_PAGE_CHANGED,
      payload: {
        surveyModel: survey
      }
    });
  }, [dispatch]);

  const countUnansweredQuestions = useCallback((questions) => {
    dispatch({
      type: surveyStateTypes.COUNT_UNANSWERED_QUESTION,
      payload: {
        questions
      }
    });
  }, [dispatch]);

  // INIT SURVEY MODEL
  const onInitModel = useCallback((surveyModel) => {
    if (Object.keys(questionData).length) return null;
    initState(surveyModel);
    doOnCurrentPageChanged(surveyModel);
    countUnansweredQuestions(surveyModel.getAllQuestions());
  }, [countUnansweredQuestions, doOnCurrentPageChanged, initState, questionData]);

  const surveyModel = useSurveyModel(templateBody, {
    onInit: onInitModel
  });

  // CONFIGURE SURVEY
  useEffect(() => {
    if (!surveyModel) return;

    surveyModel.mode = readOnly ? "display" : "edit";
    surveyModel.questionErrorLocation = "bottom";
    surveyModel.focusFirstQuestionAutomatic = false;
  }, [readOnly, surveyModel]);

  useMemo(() => {
    if (!surveyModel) return;
    const usedLocales = surveyModel.getUsedLocales();

    if (surveyLocale || usedLocales.includes(surveyLocale)) {
      surveyModel.locale = surveyLocale;
    } else if (languageCode && usedLocales.includes(languageCode)) {
      surveyModel.locale = languageCode;
    } else {
      surveyModel.locale = usedLocales[0];
    }
  }, [surveyLocale, languageCode, surveyModel]);

  useEffect(() => {
    if (!surveyModel) return;
    // need to be called for fixing events missing after localization changed
    attachEventToBracketsTooltipText({ locale: surveyModel?.locale || "en" });
  }, [surveyModel, surveyModel?.locale]);

  useEffect(() => {
    if (!surveyModel) return;
    const usedLocales = surveyModel.getUsedLocales();
    dispatch(mountSurveyViewer({
      usedLocales: usedLocales,
      locale: surveyModel.getLocale() || "en"
    }));
  }, [surveyModel, dispatch]);

  // CLEAN UP
  useEffect(() => {
    return () => {
      dispatch(resetSurveyAssessmentAnswer());
      dispatch(unmountSurveyViewer());
      surveyDomBuilder.dispose();

      dispatch({
        type: surveyStateTypes.RESET_SURVEY_ASSESSMENT_STATE
      });
    };
    // eslint-disable-next-line
  }, []);

  useUpdateQuestionCssClasses(surveyModel);
  useUpdatePageCssClasses(surveyModel);
  // SURVEY API HANDLERS
  useQuestionControls({
    surveyModel,
    surveyDomBuilder,
    permissions,
    parentTarget,
    service,
    callbacks
  });
  useQuestionsConfiguration({
    surveyModel,
    publicSurveyEmail,
    onAfterRenderQuestionCallback: callbacks?.onAfterRenderQuestion,
    canModifyAnswers: permissions?.canModifyAnswers,
    validateLockAnswerProperty: validations?.validateLockAnswerProperty
  });
  useTextMarkdown(surveyModel);

  const { handleChange } = useValueChanged({
    surveyModel,
    countUnansweredQuestions,
    doOnCurrentPageChanged,
    sendDataSingular: callbacks?.onAnswerChanged,
    questionCallback: callbacks?.onQuestionAnswerValidate
  });

  useCurrentPageChanged({
    surveyModel,
    countUnansweredQuestions,
    doOnCurrentPageChanged
  });

  return {
    surveyModel,
    handleChange
  };
};

export default useInitializeSurvey;