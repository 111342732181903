import React, { useState } from "react";
import { useSelector } from "react-redux";
// COMPONENTS
import ProfileEditorSideMenu from "./components/ProfileEditorSideMenu";
import ProfileEditorHeader from "./components/ProfileEditorHeader";
import ProfileEditorContent from "./components/ProfileEditorContent";
// CONSTANTS
import { TAB_PROFILE } from "./constants";
import { getWindowWidth } from "@/store/Settings/selectors";
import { displayWidth } from "@/utils/window";
// STYLES
import styles from "./styles.module.scss";

const ProfileEditorModalBody = ({
                                  profileInfo,
                                  closeModal,
                                  isFetchLoading
                                }) => {
  const [currentTab, setCurrentTab] = useState(TAB_PROFILE);
  const windowWidth = useSelector(getWindowWidth);
  const isMinimizedView = windowWidth <= displayWidth.TABLET;

  const changeCurrentTab = (newTab) => {
    if (newTab === currentTab) return;
    setCurrentTab(newTab);
  };

  return (
    <>
      <ProfileEditorSideMenu
        profileInfo={profileInfo}
        currentTab={currentTab}
        changeCurrentTab={changeCurrentTab}
        isMinimizedView={isMinimizedView}
        closeModal={closeModal}
        isFetchLoading={isFetchLoading}
      />

      <div className={styles.ProfileEditorContentContainer}>
        {!isMinimizedView && (
          <ProfileEditorHeader
            closeModal={closeModal}
            currentTab={currentTab}
            isMinimizedView={isMinimizedView}
          />
        )}

        <ProfileEditorContent
          profileInfo={profileInfo}
          currentTab={currentTab}
        />

        {/* FOOTER */}
        <div className={styles.ContentFooterContainer}/>
      </div>
    </>
  );
};

export default ProfileEditorModalBody;
