import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
// CONSTANTS
import { verification } from "@/shared/constants/verification";
import { apiQueryArg } from "@/store/services/config/assessorApiConstants";
import { USER_TYPES } from "@/components/SurveyJs/constants";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useErrors from "../../../../shared/hooks/errors/useErrors";
import useNotePermissions from "./hooks/useNotePermissions";
// COMPONENTS
import Verified from "@/assets/images/verified.svg";
import Modified from "@/assets/images/modified.svg";
import SharedNote from "@/assets/images/shared-note.svg";
import PrivateNote from "@/assets/images/private-note.svg";
import AddNote from "@/assets/images/add-note.svg";
import MessageModal from "@/components/Common/CompleteDialog/MessageModal";
import NoteModal from "@/components/SurveyJs/components/NoteModal";
import { ResultsContext, arrayCards } from "../Modals/CampaignResultsModal/CampaignResultsModal";
// STORE
import {
  useUpdateAssessmentActsAnswersMutation,
} from "@/store/services/assessments/assessments";
import {
  useUpdateRegistrationAnswersMutation
} from "@/store/services/accountRegistrations/rtk/registrationSubmissions";
import { getCampaignStatistics } from "@/store/Statistics/actions";
// STYLES
import "../styles.scss";


const tooltipAssessorNote = {
  add: <IntlMessages id="notes.itemNotes.singleAdd"/>,
  viewPublic: <IntlMessages id="notes.itemNotes.singleView"/>,
  viewPrivate: <IntlMessages id="notes.itemNotes.singleViewPrivate"/>,
  editPublic: <IntlMessages id="notes.itemNotes.singleModify"/>,
  editPrivate: <IntlMessages id="notes.itemNotes.singleViewPrivate"/>,
};

const QuestionNoteAndStatus = ({
                                 status,
                                 note,
                                 question,
                               }) => {
  const dispatch = useDispatch();
  const [showPreviewNoteModal, setShowPreviewNoteModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const {
    memberId,
    forAssessorMemberProfile,
    campaignId,
    organizationId
  } = useContext(ResultsContext);

  const isQuestion = question?.assessment_id || question?.registration_id;
  const {
    isAuditor,
    canViewAssessorNotes,
    canCreateAssessorNotes,
    canModifyAssessorNotes,
    canRemoveAssessorNotes
  } = useNotePermissions({ question, forAssessorMemberProfile, campaignId });


  const { handleFetchErrors } = useErrors();
  const [updateAssessmentAnswers] = useUpdateAssessmentActsAnswersMutation();
  const [updateRegistrationAnswers] = useUpdateRegistrationAnswersMutation();

  const showNote = () => {
    if (canModifyAssessorNotes) {
      setShowNoteModal(true);
    } else {
      setShowPreviewNoteModal(true);
    }
  };

  const createNote = () => {
    if (canCreateAssessorNotes) {
      setShowNoteModal(true);
    } else {
      setShowPreviewNoteModal(true);
    }
  };

  const changeQuestionNote = async ({ currentNote, assessment_id, question_id }) => {
    const payload = {
      [apiQueryArg.ACT_ID]: assessment_id,
      [apiQueryArg.MANAGEMENT_TYPE]: forAssessorMemberProfile ? USER_TYPES.AUDITOR : USER_TYPES.PERMITTED_USER,
      answers: [{
        sjs_question_id: question_id,
        content: {
          auditor_note: currentNote,
        }
      }]
    };
    try {
      await updateAssessmentAnswers(payload).unwrap();
    } catch (e) {
      handleFetchErrors(e?.data);
    }
  };

  const changeRegistrationQuestionNote = async ({ currentNote, registration_id, question_id }) => {
    const payload = {
      [apiQueryArg.REG_ID]: registration_id,
      [apiQueryArg.MANAGEMENT_TYPE]: forAssessorMemberProfile ? USER_TYPES.AUDITOR : USER_TYPES.PERMITTED_USER,
      answers: [{
        sjs_question_id: question_id,
        content: {
          auditor_note: currentNote
        }
      }]
    };
    try {
      await updateRegistrationAnswers(payload).unwrap();
    } catch (e) {
      handleFetchErrors(e?.data);
    }
  };

  const handleChangeQuestionNote = async ({ currentNote }) => {
    const { registration_id, question_id, assessment_id } = question;
    const cards = arrayCards.join(",");
    const handlerControl = registration_id ? changeRegistrationQuestionNote : changeQuestionNote;

    await handlerControl({ currentNote, registration_id, question_id, assessment_id });
    await dispatch(getCampaignStatistics({
      user_id: memberId,
      cards,
      for_auditor: forAssessorMemberProfile,
      campaign_id: campaignId,
      organization_id: organizationId
    }));
  };

  return (
    <div className="answer-status-note">
      <div className="answer-status">
        {isAuditor && (
          <>
            {status === verification.VERIFIED && (
              <div className="answer-status__container">
                <img src={Verified} alt="verified"/>
                <div className="answer-status__tooltip">
                  <IntlMessages id="reports.verified"/>
                </div>
              </div>

            )}
            {status === verification.MODIFIED && (
              <div className="answer-status__container">
                <img src={Modified} alt="modified"/>
                <div className="answer-status__tooltip">
                  <IntlMessages id="reports.modified"/>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="answer-note">
        {note && note?.config?.is_visible && (
          <div className="answer-note__container" style={{ cursor: "pointer" }}>
            <img
              src={SharedNote}
              alt="shared-note"
              onClick={() => showNote(note)}
              className="shared-note"
            />
            <div className="answer-note__tooltip">
              {canModifyAssessorNotes
                ? tooltipAssessorNote.editPublic
                : tooltipAssessorNote.viewPublic
              }
            </div>
          </div>
        )}
        {isAuditor && (
          <>
            {note && !note?.config?.is_visible && canViewAssessorNotes && isQuestion && (
              <div className="answer-note__container">
                <img
                  src={PrivateNote}
                  alt="shared-note"
                  onClick={() => showNote(note)}
                  className="private-note"
                />
                <div className="answer-note__tooltip">
                  {canModifyAssessorNotes
                    ? tooltipAssessorNote.editPrivate
                    : tooltipAssessorNote.viewPrivate
                  }
                </div>
              </div>
            )}
            {!note && canCreateAssessorNotes && isQuestion && (
              <div className="answer-note__container">
                <img
                  src={AddNote}
                  alt="add-note"
                  onClick={() => createNote(note)}
                  className="shared-note"
                />
                <div className="answer-note__tooltip">
                  {tooltipAssessorNote.add}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {showNoteModal && (
        <NoteModal
          show={showNoteModal}
          setShow={setShowNoteModal}
          questionForNote={{
            title: question.title,
            note,
          }}
          changeQuestionNote={handleChangeQuestionNote}
          canRemoveAssessorNotes={canRemoveAssessorNotes}
        />
      )}
      {showPreviewNoteModal && (
        <MessageModal show={showPreviewNoteModal} setShow={setShowPreviewNoteModal}>
          <div
            className="note-preview-content"
            dangerouslySetInnerHTML={{
              __html: note.text,
            }}
          />
        </MessageModal>
      )}
    </div>
  );
};


QuestionNoteAndStatus.propTypes = {
  status: PropTypes.string.isRequired,
  note: PropTypes.object,
  question: PropTypes.object.isRequired,
};

export default QuestionNoteAndStatus;



