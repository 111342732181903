const useInitialValues = ({ userEmail, firstName, lastName } = {}) => {
  return {
    email: userEmail || "",
    firstName: firstName || "",
    lastName: lastName || "",
    password: "",
    passwordRepeat: "",
    agreement: false
  };
};

export default useInitialValues;
