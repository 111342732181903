import React from "react";
import { useSelector } from "react-redux";
// COMPONENTS
import AsrModal from "src/shared/components/Modals/AsrModal";
import ProfileEditorModalBody from "./ProfileEditorModalBody";
// STORE
import { getUserId } from "@/store/User/selectors";
import { useGetEditProfileInfoQuery } from "@/store/services/users/rtk";
// STYLES
import styles from "./styles.module.scss";


const ProfileEditorModal = ({
                              open,
                              closeModal
                            }) => {
  const currentUserId = useSelector(getUserId);

  const { data: profileInfo, isFetching: isFetchLoading } = useGetEditProfileInfoQuery({
    userId: currentUserId
  }, {
    skip: !currentUserId
  });

  return (
    <AsrModal
      open={open}
      closeModal={closeModal}
      withHeader={false}
      withCloseButton={false}
      isCustomCloseIcon={false}
      classes={{
        modal: styles.Modal,
        modalContent: styles.ModalContentContainer
      }}
    >
      <ProfileEditorModalBody
        profileInfo={profileInfo}
        closeModal={closeModal}
        isFetchLoading={isFetchLoading}
      />
    </AsrModal>
  );
};

export default ProfileEditorModal;
