export const ORGANIZATION_MEMBERS_SORT = "-last_access";
export const ORGANIZATION_CAMPAIGN_CARDS_SORT = "rank,id";

export const ORGANIZATION_REGISTRATION_CONFIRMATIONS_SORT = "-id";

export const ORGANIZATION_SURVEY_TEMPLATE_SORT = "-modified_dt";
export const ORGANIZATION_ASSESSMENTS_ITEMS_SORT = "-survey_template__modified_dt";
export const ORGANIZATION_ASSESSMENTS_CUSTOM_FIELDS_SORT = "-modified_dt";

export const ORGANIZATION_ASSESSOR_ASSIGNMENTS_SORT = "user_fullname";

export const CUSTOM_FIELDS_SEARCH = "name";//  "name__created_dt_code"

// CAMPAIGNS MODALS
export const campaignsModals = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};

export const campaignsViewType = {
  ASSIGNED_CAMPAIGNS: "assignedCampaigns",
  MY_CAMPAIGNS: "myCampaigns",
  PUBLIC_CAMPAIGNS: "publicCampaigns"
};

// ACCOUNT USER TYPES
export const accountUserTypes = {
  USER: "user",
  MEMBER: "member",
  MEMBER_ON_HOLD: "member.on_hold"
};