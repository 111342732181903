export default {
  COMPLETED: "completed",
  IS_COMPLETED: "is_completed",
  INCOMPLETE: "incomplete",
  AVAILABLE: "available",
  FORCED_AVAILABLE: "forced_available",
  IN_PROGRESS: "in_progress",
  NOT_STARTED: "not_started",
  NOT_AVAILABLE: "not_available",
  IS_LOCKED: "is_locked",
  LOCKED: "locked",
  CLOSED: "closed",
  IS_SUBMITTED: "is_submitted",
  SUBMITTED: "submitted",
  IN_PROGRESS_REOPENED: "in_progress_reopened",
  IS_PROGRESS_PERCENTS: "is_progress_percents",
  PRIVATE: "private",
  PUBLIC: "public",
};