import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// HELPERS
import checkNotificationBlackList from "./helpers/checkNotificationBlackList";
import checkNotificationWhiteList from "./helpers/checkNotificationWhiteList";
// STORE
import { addSystemNotification } from "@/store/System/actions";


const useErrors = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFetchErrors = useCallback((error, options = {}) => {
    const config = {
      notificationBlackList: null, // [] || "*" || null
      notificationWhiteList: null, // [] || "*" || null
      redirectTo404: [],
      ...options
    };

    if (config?.redirectTo404.includes(error?.code)) {
      history.replace("/404");
    }

    const isNotificationBlackList = checkNotificationBlackList(config?.notificationBlackList, error?.code);
    const isNotificationWhiteList = checkNotificationWhiteList(config?.notificationWhiteList, error?.code);

    if (isNotificationBlackList) {
      return error;
    }

    if (isNotificationWhiteList && error?.details) {
      const errMsg = (typeof error.details === "string"
          ? error.details
          : Object.values(error.details ?? {})?.[0]?.[0]?.["text"])
        ?? error?.error_text;
      dispatch(addSystemNotification("", errMsg, "error"));
    }

    return error;
  }, [dispatch, history]);

  const setFormFieldErrors = ({ setFieldError, details }) => {
    Object.entries(details ?? {}).forEach(([field, errors]) => {
      setFieldError(field, errors[0]?.text);
    });
  };

  return {
    handleFetchErrors,
    setFormFieldErrors
  };
};

export default useErrors;