// UTILS
import { removeHtmlTags as _removeHtmlTags } from "@/utils/dtypes/string";
import languageData from "@/components/LanguageSwitcher/data";

export const extractTranslationCodesFromObject = ({ data, removeHtmlTags = false }) => {
  const translations = [];
  for (const [key, value] of Object.entries(data)) {
    let hasContent = Boolean(value);
    if (removeHtmlTags) {
      hasContent = Boolean(_removeHtmlTags(value));
    }
    hasContent && translations.push(key);
  }
  return translations;
};

export const extractSystemLanguageCodes = () => {
  return languageData.map(item => item.locale);
};

export const findSystemLangSettingByCode = ({ code }) => {
  return languageData.find(item => item?.locale === code);
};