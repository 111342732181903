const prepareSubmitData = (data) => {
  return {
    first_name: data?.firstName || "",
    last_name: data?.lastName || "",
    email: data?.email,
    password: data?.password,
    repeat_password: data?.passwordRepeat,
  };
};

export default prepareSubmitData;
