import usePolicyValidation from "@/shared/hooks/policyValidation/usePolicyValidation";
import { actionsConstants } from "@/shared/hooks/policyValidation/constants/actionsConstants";
import { useSelector } from "react-redux";
import { getIsSuperUser } from "@/store/User/selectors";
import { getAccountMembership } from "@/store/Organization/rootReducers/account/currentUser/selectors";


const useAccountPermissions = () => {
  const isSuperUser = useSelector(getIsSuperUser);
  const membership = useSelector(getAccountMembership);

  const isUserRole = membership?.type === "member" || isSuperUser;

  const canViewAccountDashboard = usePolicyValidation({
    action: actionsConstants.AccountDashboardView,
  });

  const canEditAccount = usePolicyValidation({
    action: actionsConstants.AccountModify,
  });

  const canViewAccountLogs = usePolicyValidation({
    action: actionsConstants.AccountViewLogs,
  });

  const canAccountArchive = usePolicyValidation({
    action: actionsConstants.AccountArchive,
  });

  return {
    canViewAccountDashboard,
    canEditAccount,
    canViewAccountLogs,
    canAccountArchive,
    isUserRole
  };
};

export default useAccountPermissions;