import usePolicyValidation from "../../../../../../shared/hooks/policyValidation/usePolicyValidation";
import { actionsConstants } from "../../../../../../shared/hooks/policyValidation/constants/actionsConstants";

const useSideNavOrganizationPermissions = () => {
  const isAuditor = usePolicyValidation({
    action: actionsConstants.AuditorIsAuditor,
  });

  const canSeeAuditorAssignments = usePolicyValidation({
    action: actionsConstants.AuditorIsAuditor,
  });

  // const canSeeAccountDatasets = usePolicyValidation({
  //   action: actionsConstants.PowerBIDatasetListView,
  // });
  const canSeeAccountDatasets = usePolicyValidation();

  const canSeeStatements = usePolicyValidation({
    action: actionsConstants.StatementListView,
  });

  const canSeeStatementsSets = usePolicyValidation({
    action: actionsConstants.StatementSetListView,
  });

  return {
    isAuditor,
    canSeeAccountDatasets,
    canSeeAuditorAssignments,
    canSeeStatements,
    canSeeStatementsSets
  };
};

export default useSideNavOrganizationPermissions;