const transformPayloadToFormData = (body) => {
  const formData = new FormData();
  Object.keys(body)
    .forEach(key => {
      if (body[key]?.type) {
        formData.append(key, body[key]);
      } else if (typeof body[key] === "object") {
        formData.append(key, JSON.stringify(body[key]));
      } else {
        formData.append(key, body[key]);
      }
    });

  return formData;
};

export default transformPayloadToFormData;