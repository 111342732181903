import { actionsConstants } from "../../constants/actionsConstants";
import allOwnedIdsCampaignsValidator from "../validatorsHandlers/allOwnedIdsCampaignsValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const notification = {
  [actionsConstants.NotificationAssessmentCompletion]: allOwnedIdsCampaignsValidator,
  [actionsConstants.NotificationCampaignCompletion]: allOwnedIdsValidator,
  [actionsConstants.NotificationRegistrationFormSubmission]: allOwnedIdsValidator,
};

export default notification;