// don't use absolute import. Webpack's initialization error
import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const registrationForm = {
  [actionsConstants.RegistrationFormListView]: simpleValidator,
  [actionsConstants.RegistrationFormCreate]: simpleValidator,
  [actionsConstants.RegistrationFormModify]: allOwnedIdsValidator,
  [actionsConstants.RegistrationFormLockUnlock]: allOwnedIdsValidator,
  [actionsConstants.RegistrationFormDelete]: allOwnedIdsValidator,
  [actionsConstants.RegistrationFormDownloadReportCSV]: allOwnedIdsValidator,
  [actionsConstants.RegistrationFormDownloadReportJSON]: allOwnedIdsValidator,
  [actionsConstants.RegistrationFormDownloadReportXLSX]: allOwnedIdsValidator,
  [actionsConstants.RegistrationFormPublishPublicReport]: allOwnedIdsValidator,
  [actionsConstants.RegistrationFormGenerateIframe]: allOwnedIdsValidator,
};

export default registrationForm;