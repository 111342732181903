import { useCallback } from "react";
import { useSelector } from "react-redux";
import { surveyAssessmentsState } from "@/store/SurveyAssessment/selectors";

const useSurveyValueChanged = ({
                                 onSubmitCallback
                               }) => {
  const { questionData } = useSelector(surveyAssessmentsState);

  const sendDataHandler = useCallback(async ({
                                               result,
                                               isCompleted
                                             }) => {

    const answers = result.getAllQuestions().reduce((acc, {
      propertyHash: { name, value, inputType },
      value: surveyValue
    }) => {
      return {
        ...acc,
        [name]: {
          value: surveyValue ?? value,
          inputType
        }
      };
    }, {});

    const data = Object.keys(answers)
      .map((questionName) => {
        let answerData;
        if (questionData[questionName]) {
          answerData = {
            ...questionData[questionName],
            answer: answers[questionName].value,
            inputType: answers[questionName].inputType,
          };
        }
        return answerData;
      })
      .filter((item) => item);

    if (onSubmitCallback) {
      await onSubmitCallback({ data, isCompleted, survey: result });
    }
  }, [questionData, onSubmitCallback]);

  return { sendDataHandler };
};

export default useSurveyValueChanged;