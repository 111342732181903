import { baseApiUrl } from "@/configs/MainConfig";
import { requestActionCreator } from "@/utils/requestActionCreator";
import * as types from "../types";
import { authService } from "@/shared/services/auth.service";
import makeQueryString from "@/utils/makeQueryString";
import querystring from "query-string";

export const fetchOrganizationAssessments = (organizationIds, page = 1, page_size = 10, searchValue, campaignId, excludeIds, idsToTop) => {
  let url = `${baseApiUrl}/campaign/assessment/list/?page=${page}&page_size=${page_size}&is_archived=false&sort_by=order_in_list`;

  if (organizationIds) {
    url = `${url}&organization_ids=${organizationIds}`;
  }

  if (searchValue) {
    url = `${url}&name=${encodeURIComponent(searchValue)}`;
  }

  if (campaignId) {
    url = `${url}&campaign_ids=${campaignId}`;
  }

  if (excludeIds) {
    url = `${url}&exclude_ids=${excludeIds}`;
  }

  if (idsToTop) {
    url = `${url}&ids_to_top=${idsToTop}`;
  }

  return requestActionCreator(
    types.FETCH_ORGANIZATION_ASSESSMENTS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchOrganizationAssessmentsForAutocomplete = (payload) => {
  const payloadQuery = {
    is_archived: false,
    sort_by: "order_in_list",
    page: 1,
    page_size: 10
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/assessment/list/?${query}`;

  return requestActionCreator(
    types.FETCH_ORGANIZATION_ASSESSMENTS_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchAssessmentItemsList = ({ excludeTypes = ["html"] } = {}) => (dispatch, getState) => {
  const currentState = getState();

  const paginationString = makeQueryString(currentState.organization.selectedItem.assessmentItems.pagination);
  const filtersString = makeQueryString(currentState.organization.selectedItem.assessmentItems.filters);
  const sortString = makeQueryString(currentState.organization.selectedItem.assessmentItems.sortBy);

  const queryParts = [];
  queryParts.push(paginationString);

  if (filtersString) {
    queryParts.push(filtersString);
  }

  if (sortString) {
    queryParts.push(sortString);
  }

  if (excludeTypes) {
    queryParts.push(`exclude_types=${excludeTypes.join(",")}`);
  }

  const url = `${baseApiUrl}/survey-template/question/list/?${queryParts.join("&")}`;
  return dispatch(requestActionCreator(
    types.FETCH_ASSESSMENT_ITEMS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setAssessmentItemsPagination = (data) => ({
  type: types.SET_ASSESSMENT_ITEMS_PAGINATION,
  payload: {
    data
  }
});

export const setAssessmentItemsFilters = (data) => {
  return {
    type: types.SET_ASSESSMENT_ITEMS_FILTERS,
    payload: {
      data
    }
  };
};

export const setAssessmentItemsSortOrder = (data) => ({
  type: types.SET_ASSESSMENT_ITEMS_SORT_ORDER,
  payload: {
    data
  }
});

export const manageStatementsRelatedToAssessmentItem = (id, data) => requestActionCreator(
  types.MANAGE_STATEMENTS_RELATIONS_TO_ASSESSMENT_ITEM,
  `${baseApiUrl}/survey-template/question/manage/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  data
);

//FOR CUSTOM FIELDS
export const fetchAssessmentItemsCustomFieldsList = () => (dispatch, getState) => {
  const currentState = getState();

  const paginationString = makeQueryString(currentState.organization.selectedItem.assessmentItemsCustomFields.pagination);
  const filtersString = makeQueryString(currentState.organization.selectedItem.assessmentItemsCustomFields.filters);
  const sortString = makeQueryString(currentState.organization.selectedItem.assessmentItemsCustomFields.sortBy);

  const queryParts = [];
  queryParts.push(paginationString);

  if (filtersString) {
    queryParts.push(filtersString);
  }

  if (sortString) {
    queryParts.push(sortString);
  }

  const url = `${baseApiUrl}/custom-field/list/?${queryParts.join("&")}`;

  dispatch(requestActionCreator(
    types.FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchAssessmentItemsCustomFieldsForAutocomplete = (payload) => {
  const payloadQuery = {
    page_size: 15,
    page: 1
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/custom-field/list/?${query}`;

  return requestActionCreator(
    types.FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const setAssessmentItemsCustomFieldsPagination = (data) => ({
  type: types.SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_PAGINATION,
  payload: {
    data
  }
});

export const setAssessmentItemsCustomFieldsFilters = (data) => ({
  type: types.SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FILTERS,
  payload: {
    data
  }
});

export const setAssessmentItemsCustomFieldsSortOrder = (data) => ({
  type: types.SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_SORT_ORDER,
  payload: {
    data
  }
});

export const createAssessmentItemsCustomField = (data) => requestActionCreator(
  types.CREATE_ASSESSMENT_ITEMS_CUSTOM_FIELD,
  `${baseApiUrl}/custom-field/manage/`,
  "POST",
  { ...authService.getAuthHeaders() },
  data,
);

export const updateAssessmentItemsCustomField = (id, data) => requestActionCreator(
  types.UPDATE_ASSESSMENT_ITEMS_CUSTOM_FIELD,
  `${baseApiUrl}/custom-field/manage/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  data,
);

export const deleteAssessmentItemsCustomField = (id) => requestActionCreator(
  types.DELETE_ASSESSMENT_ITEMS_CUSTOM_FIELD,
  `${baseApiUrl}/custom-field/manage/?id=${id}`,
  "DELETE",
  { ...authService.getAuthHeaders() },
);

export const fetchAssessmentItemsCustomFieldInfo = (id) => requestActionCreator(
  types.FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO,
  `${baseApiUrl}/custom-field/manage/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() },
);

export const cleanAssessmentItemsCustomFieldInfo = () => ({
  type: types.CLEAN_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO,
});

export const setAuditorNotesSortOrder = (payload) => ({
  type: types.SET_AUDITOR_NOTES_SORT_ORDER,
  payload
});

// HASHTAGS
export const fetchHashtagsForAutocomplete = (payload) => {
  const payloadQuery = {};

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/hashtag/list/?${query}`;

  return requestActionCreator(
    types.FETCH_ASSESSMENT_NOTE_HASHTAGS_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

//
export const fetchAssessmentQuestionsForAutocomplete = (payload) => {
  const payloadQuery = {
    page: 1,
    page_size: 10
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/survey-template/question/list/sjs_question_include?${query}`;

  return requestActionCreator(
    types.FETCH_ASSESSMENT_QUESTIONS_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};


export const setOrganizationAssessments = (response) => ({
  type: types.SET_ORGANIZATION_ASSESSMENTS,
  payload: {
    response
  }
});