import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import AsrMuiInput from "@/components/Common/FormComponentsNew/AsrMuiInput";
import AsrLogo from "@/components/Common/AsrLogo";
// STYLES
import styles from "../styles.module.scss";


const SignUpFormFields = ({ fmk }) => {

  const onChangeEmail = (e) => {
    fmk.setFieldValue("email", e.target.value);
  };

  const onChangeFirstName = (e) => {
    fmk.setFieldValue("firstName", e.target.value);
  };

  const onChangeLastName = (e) => {
    fmk.setFieldValue("lastName", e.target.value);
  };

  const onChangePassword = (e) => {
    fmk.setFieldValue("password", e.target.value);
  };

  const onChangePasswordRepeat = (e) => {
    fmk.setFieldValue("passwordRepeat", e.target.value);
  };

  return (
    <div className={styles.ModalFormFieldsBlockWrapper}>
      <div className={styles.ModalFormFieldsWrapper}>
        <AsrMuiInput
          label={<IntlMessages id="appModule.email"/>}
          classes={{
            inputRoot: styles.InputRoot,
            labelRoot: styles.InputLabel
          }}
          type="email"
          name="email"
          error={fmk?.errors?.email}
          value={fmk?.values?.email || ""}
          isTouched={fmk?.touched?.email}
          onChange={onChangeEmail}
          autoComplete="email"
        />

        <div className={styles.FullNameFieldsWrapper}>
          <AsrMuiInput
            label={<IntlMessages id="appModule.firstName"/>}
            classes={{
              inputRoot: styles.InputRoot,
              labelRoot: styles.InputLabel
            }}
            name="firstName"
            value={fmk?.values?.firstName || ""}
            error={fmk?.errors?.firstName}
            isTouched={fmk?.touched?.firstName}
            onChange={onChangeFirstName}
            autoComplete="given-name"
          />

          <AsrMuiInput
            label={<IntlMessages id="appModule.lastName"/>}
            classes={{
              inputRoot: styles.InputRoot,
              labelRoot: styles.InputLabel
            }}
            name="lastName"
            value={fmk?.values?.lastName || ""}
            error={fmk?.errors?.lastName}
            isTouched={fmk?.touched?.lastName}
            onChange={onChangeLastName}
            autoComplete="family-name"
          />
        </div>

        <AsrMuiInput
          label={<IntlMessages id="appModule.password"/>}
          classes={{
            inputRoot: styles.InputRoot,
            labelRoot: styles.InputLabel
          }}
          type="password"
          name="password"
          error={fmk?.errors?.password}
          isTouched={fmk?.touched?.password}
          onChange={onChangePassword}
          autoComplete="new-password"
        />

        <AsrMuiInput
          label={<IntlMessages id="appModule.passwordConfirm"/>}
          classes={{
            inputRoot: styles.InputRoot,
            labelRoot: styles.InputLabel
          }}
          type="password"
          name="passwordRepeat"
          error={fmk?.errors?.passwordRepeat}
          isTouched={fmk?.touched?.passwordRepeat}
          onChange={onChangePasswordRepeat}
          autoComplete="new-password"
        />
      </div>

      <AsrLogo
        redirectOnClick={false}
        classes={{
          logo: styles.AsrModalLogo,
          container: styles.ModalFormFieldsLogo
        }}
      />
    </div>
  );
};

export default SignUpFormFields;
