import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { get } from "lodash";
import queryString from "query-string";
// HOOKS
import useFetchLangDependency from "@/shared/hooks/useFetchLangDependency";


export const OrganizationPublicSurvey = () => {
  // deprecated. Remained only for accessing through already embedded registration forms

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const key = get(location, "state.key") || queryString.parse(location.search).key || queryString.parse(location.search).accessKey;

  const fetchData = useCallback(async () => {
    try {
      history.replace({
        pathname: `/public/registration/${key}/`,
        search: location?.search,
        state: {
          email: get(location, "state.email") || get(location, "state.state.email"),
          accessKey: key
        }
      });
    } catch (e) {
      return history.replace({
        pathname: "/error",
        state: {
          type: "error"
        }
      });
    }
  }, [dispatch, history, key]);

  useFetchLangDependency(fetchData);
  return null;
};