import { useMemo } from "react";
import updateQuestionCssClasses
  from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/updateQuestionCssClasses";

const useUpdateQuestionCssClasses = (surveyModel) => {
  useMemo(() => {
    if (surveyModel) {
      surveyModel
        .onUpdateQuestionCssClasses
        .add(updateQuestionCssClasses);
    }
  }, [surveyModel]);
};

export default useUpdateQuestionCssClasses;