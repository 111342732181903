import { localization } from "../localizationManager";

export var spanishStrings = {
  "ungroupButton": "Desagrupar por mí",
  "selectButton": "Seleccionarme",
  "hideColumn": "Ocultar columna",
  "showColumn": "Mostrar columna",
  "makePrivateColumn": "Hacer que la columna sea privada",
  "makePublicColumn": "Hacer pública la columna",
  "moveToDetail": "Mover al detalle",
  "showAsColumn": "Mostrar como columna",
  "filterPlaceholder": "Buscar ...",
  "removeRows": "Eliminar filas",
  "showLabel": "Mostrar",
  "entryLabel": "entradas",
  "visualizer_text": "Textos en tabla",
  "visualizer_wordcloud": "Wordcloud",
  "chartType_bar": "Bar",
  "chartType_stackedbar": "Barra apilada",
  "chartType_doughnut": "Donut",
  "chartType_pie": "Pie",
  "chartType_scatter": "Dispersión",
  "chartType_gauge": "Gauge",
  "chartType_bullet": "Bullet",
  "hideButton": "Ocultar",
  "makePrivateButton": "Hacer privado",
  "makePublicButton": "Hacer público",
  "showButton": "Mostrar",
  "filter": "Filtro",
  "resetFilter": "Restablecer filtro",
  "changeLocale": "Cambiar configuración regional",
  "clearButton": "Borrar",
  "addElement": "Elija la pregunta para mostrar ...",
  "defaultOrder": "Orden predeterminado",
  "ascOrder": "Ascendente",
  "descOrder": "Descendente",
  "showMinorColumns": "Mostrar columnas secundarias",
  "otherCommentTitle": "Otros elementos y comentarios",
  "showPercentages": "Mostrar porcentajes",
  "hidePercentages": "Ocultar porcentajes",
  "pdfDownloadCaption": "PDF",
  "xlsxDownloadCaption": "Excel",
  "csvDownloadCaption": "CSV",
  "saveDiagramAsPNG": "Descargar el gráfico como png",
  "hideEmptyAnswers": "Ocultar respuestas vacías",
  "showEmptyAnswers": "Mostrar respuestas vacías",
  "topNValueText-1": "Todas las respuestas",
  "topNValueText5": "Las 5 respuestas principales",
  "topNValueText10": "Las 10 respuestas principales",
  "topNValueText20": "20 respuestas principales",
  "noVisualizerForQuestion": "Este tipo de pregunta aún no se visualiza",
  "noResults": "Aún no hay resultados"
};
localization.locales["es"] = spanishStrings;
localization.localeNames["es"] = "Spanish";
