import React from "react";
import classNames from "classnames";
// MUI
import { Button } from "@mui/material";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";


const AsrCancelButton = ({
                           isVisible = true,
                           handler,
                           classname,
                           type = "button",
                           label = "appModule.cancel",
                           disabled,
                           mr,
                         }) => {
  if (!isVisible) return null;

  const rootClass = classNames({
    [styles.cancelButton]: true,
    [classname]: classname,
    [styles.AsrCancelButtonMr1]: mr === 1,
    [styles.AsrCancelButtonMr2]: mr === 2,
  });

  return (
    <Button
      type={type}
      disabled={disabled}
      className={rootClass}
      onClick={handler}
    >
      <span className={styles.label}><IntlMessages id={label}/></span>
    </Button>
  );
};

export default AsrCancelButton;