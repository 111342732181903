import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-responsive-modal";
// MATERIAL UI
import Box from "@mui/material/Box";
// COMPONENTS
import LanguageSwitcher from "./components/LanguageSwitcher";
import AsrCloseModalButton from "@/shared/components/Buttons/AsrCloseModalButton";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
import AsrModalFooter from "./components/AsrModalFooter";
// HOOKS
import useClasses from "./hooks/useClasses";
// STYLES
import "react-responsive-modal/styles.css";


const AsrModal = ({
                    closeModal,
                    open,
                    titleCapitalize,
                    className, //need for backward compatibility use 'classes' instead
                    classes = {
                      overlay: "",
                      modal: "",
                      modalBox: "",
                      closeButton: "",
                      modalHeader: "",
                      modalTitle: "",
                      modalContent: "",
                      footerContainer: ""
                    },
                    onChangeLocale,
                    withHeader = true,
                    withCloseButton = true,
                    title,
                    isCustomCloseIcon = true,
                    isLoading = false,
                    islandContent = false,
                    actionFooterButtons,
                    minHeight = 220,
                    children,
                  }) => {
  const styles = useClasses(classes, { className, titleCapitalize, withCloseButton, islandContent });

  const hasFooter = Boolean(actionFooterButtons?.length > 0);

  return (
    <Modal
      classNames={{
        overlay: styles.overlay,
        modal: styles.modal,
        closeButton: styles.closeButton,
      }}
      closeIcon={isCustomCloseIcon ? <AsrCloseModalButton isVisible={isCustomCloseIcon}/> : null}
      open={open}
      onClose={closeModal}
      center
      focusTrapped={false}
    >
      <Box minHeight={minHeight} className={styles.modalBox}>
        {/* HEADER */}
        {withHeader && (
          <div className={styles.modalHeader}>
            <h3 className={styles.modalTitle}>{title}</h3>
            {onChangeLocale && (
              <LanguageSwitcher
                withCloseButton={withCloseButton}
                onChangeLocale={onChangeLocale}
              />
            )}
          </div>
        )}

        {/* BODY */}
        <div className={styles.modalIslandContentContainer}>
          <div className={styles.modalContent}>
            {!isLoading && (
              <>
                {children}
              </>
            )}
          </div>
        </div>

        {/* FOOTER */}
        {hasFooter && (
          <AsrModalFooter
            classes={styles}
            actionFooterButtons={actionFooterButtons}
          />
        )}
      </Box>
      {isLoading && <AsrCommonLoader/>}
    </Modal>
  );
};

AsrModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  titleCapitalize: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    overlay: PropTypes.string,
    modal: PropTypes.string,
    modalBox: PropTypes.string,
    closeButton: PropTypes.string,
    modalHeader: PropTypes.string,
    modalTitle: PropTypes.string,
    modalContent: PropTypes.string,
    footerContainer: PropTypes.string
  }),
  onChangeLocale: PropTypes.func,
  withHeader: PropTypes.bool,
  withCloseButton: PropTypes.bool,
  isCustomCloseIcon: PropTypes.bool,
  islandContent: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minHeight: PropTypes.number,
  actionFooterButtons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    type: PropTypes.oneOf(["plainText", "default"]),
    classes: PropTypes.shape({
      wrapper: PropTypes.string,
      button: PropTypes.string
    }),
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  }))
};

AsrModal.displayName = "AsrModal";
export default AsrModal;
