export const requestActionCreator = (type, url, method, headers, data, meta, responseType) => ({
    type,
    payload: {
        request: {
            url,
            method,
            headers,
            data,
            responseType
        }
    },
    meta
});
