import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// STORE
import { getColorScheme } from "@/store/Organization/selectors";
import { setDefaultTheme } from "@/store/Organization/actions";

const useTheme = ({
                    resetOnUnmount = false,
                    colorScheme
                  } = {}) => {
  const colorSchemeFromSelector = useSelector(getColorScheme);
  const _colorScheme = colorScheme || colorSchemeFromSelector;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const setTheme = (theme) => {
    const root = document.querySelector(":root");

    const themeItems = Object.entries(theme);

    themeItems.forEach((item) => {
      const isImg = item[0].endsWith("image") && item[1] && item[1] !== "none";
      const value = isImg ? `url(${item[1]})` : item[1];

      root.style.setProperty(item[0], value);
    });
  };

  useEffect(() => {
    if (pathname === "/organizations" || pathname === "/") {
      dispatch(setDefaultTheme());
    }
    setTheme(_colorScheme);
  }, [_colorScheme, pathname, dispatch]);

  useEffect(() => {
    return () => {
      if (!resetOnUnmount || !dispatch) return;
      dispatch(setDefaultTheme());
    };
  }, [dispatch, resetOnUnmount]);
};

export default useTheme;
