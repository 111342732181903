import React, { PureComponent } from "react";
import { connect } from "react-redux";
// CONSTANTS
import {
  QUESTION_CONTROL_MODE,
  QUESTIONS_PANELS,
  NoteEditorMode
} from "@/shared/constants/surveyJs";
// HELPERS
import { getQuestionId } from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/questions";
// COMPONENTS
import SurveyHeaderActions from "./components/SurveyHeaderActions";
import ParseQuestionName from "./components/ParseQuestionName";
// STORE
import {
  modifySurveyAuditorQuestionPanel,
  setSurveyHeaderPanelToggleNote,
  toggleSurveyHeaderNotePanel
} from "@/store/Survey/actions";
// STYLES
import styles from "./styles.module.scss";


class SurveyQuestionHeader extends PureComponent {
  state = {
    questionNoteData: {}
  };

  componentDidMount() {
    if (this.props?.notes(getQuestionId(this.props.question_id))) {
      this.setState({ questionNoteData: this.props.notes(getQuestionId(this.props.question_id)) });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.props?.notes(getQuestionId(this.props.question_id))) {
        this.setState({ questionNoteData: this.props.notes(getQuestionId(this.props.question_id)) });
      }
    }
  }

  auditorNoteHandler = async () => {
    const notePayload = {
      "assessment": this.props.assessmentId,
      "auditor_controls": {
        "item_analytics": this.props.auditorControls?.headerSections?.analytics.checked,
        "recommendations": this.props.auditorControls?.headerSections?.recommendations.checked,
        "auditor_notes": !this.props.auditorControls?.headerSections?.notes.checked
      }
    };

    if (!this.props.assessmentType) {
      this.props.toggleSurveyHeaderNotePanel(notePayload);
    }

    if (this.props.assessmentType === "registration") {
      this.props.setSurveyHeaderPanelToggleNote(!this.props.auditorControls?.headerSections?.notes.checked);
    }

    const payload = {
      question_id: getQuestionId(this.props.question_id),
      question_panel: QUESTIONS_PANELS.NOTES,
      data: {
        currentNote: this.state.questionNoteData.currentNote,
        expanded: true,
        mode: NoteEditorMode.READ
      }
    };
    if (!this.state?.questionNoteData.currentNote?.currentVersion) {
      payload.data = {
        ...payload.data,
        mode: NoteEditorMode.EDIT
      };
    }
    this.props.modifySurveyAuditorQuestionPanel(payload);
  };

  userNoteHandler = () => this.props.noteHandler(true, this.props.questionName, this.props.no, this.props.title);

  render() {
    const questionTitle = (this.props.question?.locTitle?.values[this.props.locale] ?? this.props.question?.locTitle?.values["default"]) || this.props?.title;
    const questionDescription = (this.props.question?.locDescription?.htmlValues[this.props.locale] ?? this.props.question?.locDescription?.htmlValues["default"]) || this.props?.description;
    return (
      <>
        <div className={ styles.questionTitleContainer }>
          <div className={ styles.questionNo }>{ this.props.no.slice(0, -1) }</div>
          <div className={ styles.questionInfo }>
            {/*  TITLE */ }
            <div className="d-flex">
              <h5 className={ styles.questionTitle }>
                <ParseQuestionName name={ questionTitle }/>
              </h5>
              <div className={ styles.questionStatusInfo }>
                { this.props.isRequired && (
                  <span className={ styles.required }>*</span>
                ) }
              </div>
            </div>
            {/*  DESCRIPTION */ }
            { this.props.descriptionLocation !== "underInput" && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: questionDescription }}
              />
            ) }
          </div>
        </div>
        {/*  ACTIONS  */ }
        { (!this.props.hideHeaderActions && !this.props.auditorControls?.headerSections?.notes.checked) && (
          <SurveyHeaderActions
            mode={ this.props.mode }
            locale={ this.props.locale }
            canModifyAssessorNotes={ this.props.canModifyAssessorNotes }
            currentNote={ this.state?.questionNoteData?.currentNote }
            noteHandler={ this.props.mode === QUESTION_CONTROL_MODE.user ? this.userNoteHandler : this.auditorNoteHandler }
            question_id={ this.props.question_id }
          />
        ) }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auditorControls: state.survey.surveyManagement.auditorControls,
  locale: state.settings.locale.locale,
  notes: (id) => {
    const questionsManagement = state.survey?.surveyManagement?.auditorControls?.questionsManagement;
    if (questionsManagement) {
      return state.survey?.surveyManagement?.auditorControls?.questionsManagement[id]?.notes;
    } else {
      return {};
    }
  },
});

const mapDispatchToProps = {
  modifySurveyAuditorQuestionPanel,
  toggleSurveyHeaderNotePanel,
  setSurveyHeaderPanelToggleNote
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestionHeader);