import { useMemo } from "react";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";

const useNoteTooltip = (locale) => {
  // AUDITOR TOOLTIP
  const addAuditorNote = getIntlMessageToStringLocal(locale, "notes.itemNotes.singleAdd");
  const privateAuditorNote = getIntlMessageToStringLocal(locale, "notes.itemNotes.private");
  const editAuditorNote = getIntlMessageToStringLocal(locale, "notes.itemNotes.singleModify");
  const sharedAuditorNote = getIntlMessageToStringLocal(locale, "notes.itemNotes.singleShared");
  const editSharedAuditorNote = getIntlMessageToStringLocal(locale, "notes.itemNotes.singleModifyShared");
  // USER TOOLTIP
  const addUserNote = getIntlMessageToStringLocal(locale, "assessments.addPrivateUserNote");
  const privateUserNote = getIntlMessageToStringLocal(locale, "assessments.privateUserNote");
  const sharedUserNote = getIntlMessageToStringLocal(locale, "assessments.sharedUserNote");

  const tooltipAssessorNote = useMemo(() => ({
    add: addAuditorNote,
    view: privateAuditorNote,
    edit: editAuditorNote,
    viewShared: sharedAuditorNote,
    editShared: editSharedAuditorNote,
  }), [addAuditorNote, editAuditorNote, editSharedAuditorNote, privateAuditorNote, sharedAuditorNote]);

  const tooltipUserNote = useMemo(() => ({
    add: addUserNote,
    view: privateUserNote,
    // edit: {
    //   en: "EDIT Auditor Note",
    //   fr: "Modifier la note de l'auditeur",
    // },
    viewShared: sharedUserNote,
    // editShared: {
    //   en: "Edit Shared Auditor Note",
    //   fr: "Modifier la note de l'auditeur partagée",
    // },
  }), [addUserNote, privateUserNote, sharedUserNote]);

  return [tooltipAssessorNote, tooltipUserNote];
};

export default useNoteTooltip;