import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import usePolicyValidation from "@/shared/hooks/policyValidation/usePolicyValidation";
import { actionsConstants } from "@/shared/hooks/policyValidation";
// COMPONENTS
import CampaignNestedLinks from "./components/CampaignsNestedLinks";


const SideNavCampaigns = ({ props, isStaffView, organizationId }) => {

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const canViewCampaigns = usePolicyValidation({
    action: actionsConstants.CampaignListView,
  });

  return (
    <>
      {/* CAMPAIGNS */}
      <NavLink
        className={prependedClass}
        to={`/organization/${organizationId}/campaigns`}
        onClick={props.closeNavCallBack}
      >
        <IntlMessages id="sidenav.campaings"/>
      </NavLink>

      <CampaignNestedLinks
        props={props}
        canViewAssignedCampaigns={canViewCampaigns}
      />
    </>
  );
};

export default SideNavCampaigns;