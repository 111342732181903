import React from "react";

const AsrImage = ({ src, alt = "", title = "", className }) => {
  // const [isLoaded, setIsLoaded] = useState(false);

  // const imgLoaded = () => {
  //     setIsLoaded(true);
  // }
  return (
    <>
      {/* {!isLoaded ? <div className='placeholder'/> : null} */}
      <img
        src={src}
        alt={alt}
        title={title}
        className={className}
        // onLoad={imgLoaded}
        loading='lazy'
      />
    </>
  );
};

export default AsrImage;
