import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import SideNavRoutes from "./Routes";
import { toggleCollapsedNav } from "@/store/Settings/actions";

const SidenavContent = () => {
    const dispatch = useDispatch();

    const closeSideNav = () => {
        dispatch(toggleCollapsedNav(false));
    };

    return (
        <div className="scrollbar side-nav__container">
            <ul id="side-nav-menu" className="nav-menu">
                <SideNavRoutes closeNavCallBack={closeSideNav}/>
            </ul>
        </div>
    );
};

export default withRouter(SidenavContent);
