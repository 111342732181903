export const FETCH_STATEMENTS = "FETCH_STATEMENTS";
export const SET_STATEMENTS_PAGINATION = "SET_STATEMENTS_PAGINATION";
export const SET_STATEMENTS_FILTERS = "SET_STATEMENTS_FILTERS";
export const DELETE_STATEMENT = "DELETE_STATEMENT";
export const UPDATE_STATEMENT = "UPDATE_STATEMENT";
export const UPDATE_ORGANIZATION_STATEMENT = "UPDATE_ORGANIZATION_STATEMENT";
export const SET_STATEMENTS_SORT_ORDER = "SET_STATEMENTS_SORT_ORDER";
export const CREATE_STATEMENT = "CREATE_STATEMENT";
export const CHECK_DUPLICATE_STATEMENT = "CHECK_DUPLICATE_STATEMENT";
export const FETCH_STATEMENTS_FOR_AUTOCOMPLETE = "FETCH_STATEMENTS_FOR_AUTOCOMPLETE";
export const FETCH_STATEMENT_INFO = "FETCH_STATEMENT_INFO";
export const GET_STATEMENT = "GET_STATEMENT";
export const FETCH_ORGANIZATION_STATEMENT_INFO = "FETCH_ORGANIZATION_STATEMENT_INFO";
export const CLEAN_STATEMENT_INFO = "CLEAN_STATEMENT_INFO";
// STATEMENT MULTI ACTION
export const MULTI_DELETE_STATEMENT = "MULTI_DELETE_STATEMENT";
export const MULTI_ATTACH_TYPE_STATEMENT = "MULTI_ATTACH_TYPE_STATEMENT";
export const MULTI_ATTACH_LABELS_STATEMENT = "MULTI_ATTACH_LABELS_STATEMENT";
export const MULTI_ATTACH_SETS_STATEMENT = "MULTI_ATTACH_SETS_STATEMENT";
export const MULTI_DUPLICATE_STATEMENT = "MULTI_DUPLICATE_STATEMENT";
export const MULTI_LOCK_UNLOCK_STATEMENT = "MULTI_LOCK_UNLOCK_STATEMENT";

export const FETCH_STATEMENTS_SETS = "FETCH_STATEMENTS_SETS";
export const SET_STATEMENTS_SETS_PAGINATION = "SET_STATEMENTS_SETS_PAGINATION";
export const SET_STATEMENTS_SETS_FILTERS = "SET_STATEMENTS_SETS_FILTERS";
export const DELETE_STATEMENTS_SET = "DELETE_STATEMENTS_SET";
export const UPDATE_STATEMENTS_SET = "UPDATE_STATEMENTS_SET";
export const FETCH_STATEMENTS_SETS_FOR_AUTOCOMPLETE = "FETCH_STATEMENTS_SETS_FOR_AUTOCOMPLETE";
export const SET_STATEMENTS_SETS_SORT_ORDER = "SET_STATEMENTS_SETS_SORT_ORDER";
export const CREATE_STATEMENT_SET = "CREATE_STATEMENT_SET";
// STATEMENT FILTERED SET
export const CREATE_FILTERED_STATEMENT_SET = "CREATE_FILTERED_STATEMENT_SET";
export const DELETE_FILTERED_STATEMENT_SET = "DELETE_FILTERED_STATEMENT_SET";
export const EDIT_FILTERED_STATEMENT_SET = "EDIT_FILTERED_STATEMENT_SET";

export const FETCH_STATEMENTS_SET_INFO = "FETCH_STATEMENTS_SET_INFO";
export const CLEAN_STATEMENTS_SET_INFO = "CLEAN_STATEMENTS_SET_INFO";

export const CHECK_DUPLICATE_STATEMENTS_SET = "CHECK_DUPLICATE_STATEMENTS_SET";
// TRANSLATIONS
export const FETCH_STATEMENTS_TRANSLATIONS = "FETCH_STATEMENTS_TRANSLATIONS";
export const SET_STATEMENTS_TRANSLATIONS_PAGINATION = "SET_STATEMENTS_TRANSLATIONS_PAGINATION";
export const SET_STATEMENTS_TRANSLATIONS_FILTERS = "SET_STATEMENTS_TRANSLATIONS_FILTERS";
export const SET_STATEMENTS_TRANSLATIONS_SORT_ORDER = "SET_STATEMENTS_TRANSLATIONS_SORT_ORDER";
export const DISMISS_STATEMENT_TRANSLATION_IS_OUTDATED = "DISMISS_STATEMENT_TRANSLATION_IS_OUTDATED";

export const FETCH_ASSESSMENT_ITEMS_FOR_STATEMENT = "FETCH_ASSESSMENT_ITEMS_FOR_STATEMENT";
export const SET_ASSESSMENT_ITEMS_FOR_STATEMENT_PAGINATION = "SET_ASSESSMENT_ITEMS_FOR_STATEMENT_PAGINATION";
export const SET_ASSESSMENT_ITEMS_FOR_STATEMENT_FILTERS = "SET_ASSESSMENT_ITEMS_FOR_STATEMENT_FILTERS";
export const CLEAN_ASSESSMENT_ITEMS_FOR_STATEMENT = "CLEAN_ASSESSMENT_ITEMS_FOR_STATEMENT";

// Upload StatementsInOrganization From XLSX
export const UPLOAD_STATEMENTS_FROM_XLSX = "UPLOAD_STATEMENTS_FROM_XLSX";
export const DOWNLOAD_STATEMENTS_XLSX_SAMPLE = "DOWNLOAD_STATEMENTS_XLSX_SAMPLE";
// STATEMENTS TYPE
export const FETCH_STATEMENT_TYPES = "FETCH_STATEMENT_TYPES";
export const FETCH_STATEMENTS_TYPE_FOR_AUTOCOMPLETE = "FETCH_STATEMENTS_TYPE_FOR_AUTOCOMPLETE";
export const FETCH_STATEMENT_TYPE_CATEGORIES_FOR_AUTOCOMPLETE = "FETCH_STATEMENT_TYPE_CATEGORIES_FOR_AUTOCOMPLETE";
export const SET_STATEMENT_TYPES_SORT_ORDER = "SET_STATEMENT_TYPES_SORT_ORDER";
export const SET_STATEMENT_TYPES_PAGINATION = "SET_STATEMENT_TYPES_PAGINATION";
export const SET_STATEMENT_TYPES_FILTERS = "SET_STATEMENT_TYPES_FILTERS";
export const UPDATE_STATEMENTS_TYPE = "UPDATE_STATEMENTS_TYPE";
export const CHECK_DUPLICATE_STATEMENT_TYPE = "CHECK_DUPLICATE_STATEMENT_TYPE";