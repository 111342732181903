import React from "react";
import PropTypes from "prop-types";
import CardBox from "./CardBox";
import IntlMessages from "../../../../utils/IntlMessages";
import QuestionAnswer from "./QuestionAnswer";

const Card5 = React.forwardRef(function Card5({ data, exportImage, keyForSavingCardState }, ref) {
    const issuesQuestion = data.find((question) => question.question_id === "1591353106185754") || {};
    const challengesQuestion = data.find((question) => question.question_id === "1591353106185785") || {};

    const {
        value: issues = [],
    } = issuesQuestion;

    const {
        value: challenges = [],
    } = challengesQuestion;

    return (
        <CardBox
            heading={<IntlMessages id='reports.card5Title'/>}
            exportImage={exportImage}
            ref={ref}
            keyForSavingCardState={keyForSavingCardState}
        >
            {issues?.length === 0 && challenges.length === 0
                ? (
                    <div className='report-card__no-content'><IntlMessages id='reports.noContent'/></div>
                ) : (
                    <div className='report-card__body__container'>
                        <QuestionAnswer
                            question={issuesQuestion}
                        />
                        <QuestionAnswer
                            question={challengesQuestion}
                        />
                    </div>
                )
            }
        </CardBox>
    );
});

Card5.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    exportImage: PropTypes.func.isRequired,
    keyForSavingCardState: PropTypes.string.isRequired,
};

export default Card5;
