import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
// MATERIAL UI
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// ICONS
import SvgIcons from "@/shared/UI/SvgIcons";
// UTILS
import { getAvailableLocales, getNormalizeAvailableLocales } from "@/utils/IntlMessages";
//
import { getLocale } from "@/store/Settings/selectors";
// STYLES
import styles from "./styles.module.scss";
import "./stylesMui.scss";


const LanguageSwitcher = ({ onChangeLocale, withCloseButton }) => {
  const { locale } = useSelector(getLocale);
  const [value, setValue] = useState(locale);

  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const availableLocales = getAvailableLocales(locale, true);
  const availableNormalizeLocales = getNormalizeAvailableLocales(locale);

  const handleClose = () => {
    setOpen(false);
    setIsHovered(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setValue(value);
    if (onChangeLocale) {
      onChangeLocale(value);
    }
  };

  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  const rootClass = classNames({
    [styles.LanguageSwitcher]: true,
    "AsrModalLanguageSwitcher": true,
    [styles.LanguageSwitcherExpanded]: open,
    [styles.LanguageSwitcherWithoutCloseBtn]: !withCloseButton
  });

  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  return (
    <div className={rootClass}>
      <div
        className={styles.LangSelectWrapper}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        data-locale={!open ? value.toUpperCase() : `${availableNormalizeLocales[value].language} (${availableNormalizeLocales[value].locale.toUpperCase()})`}
        data-lang={`${availableNormalizeLocales[value].language} (${availableNormalizeLocales[value].locale.toUpperCase()})`}
      >
        <div className={styles.LanguageSwitcherSelectCustom}>
          <div onClick={toggleOpen} className={styles.LanguageSwitcherIconCustom}>
            {SvgIcons["autocomplete_dropdown"]}
          </div>
        </div>
        <Select
          value={value}
          classes={{
            select: styles.LangSelect,
            icon: styles.LangSelectIcon
          }}
          MenuProps={{
            PopoverClasses: {
              root: styles.MenuRoot,
              paper: styles.MenuPaper,
            }
          }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={handleChange}
        >
          {availableLocales.map((item) => {
            return (
              <MenuItem
                key={item.locale}
                value={item.locale}
                classes={{
                  root: styles.LanguageSwitcherMenuItemRoot,
                  selected: styles.LanguageSwitcherMenuItemSelected
                }}
              >
                {(isHovered || open) ? (
                  <>
                    {item.language} ({item.locale.toUpperCase()})
                  </>
                ) : (
                  <>
                    {item.locale.toUpperCase()}
                  </>
                )}

              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default LanguageSwitcher;