export class AppService {
  getIsProductionMode() {
    return process.env.NODE_ENV === "production";
  }

  getIsDevelopmentMode() {
    return process.env.NODE_ENV === "development";
  }

  getGoogleAnalyticsId() {
    return process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  }
}

export const appService = new AppService();
