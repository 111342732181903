export const QUESTION_TYPES = {
  TEXT: "text",
  CHECKBOX: "checkbox",
  RADIOGROUP: "radiogroup",
  COMMENT: "comment",
  RATING: "rating",
  IMAGE_PICKER: "imagepicker",
  IMAGE: "image",
  DROPDOWN: "dropdown",
  BOOL: "boolean",
  HTML: "html",
  SIGNATUREPAD: "signaturepad",
  EXPRESSION: "expression",
  FILE: "file",
  MATRIX: "matrix",
  MATRIX_DROPDOWN: "matrixdropdown",
  MATRIX_DYNAMIC: "matrixdynamic",
  TEXT_MULTIPLE: "multipletext",
  PANEL: "panel",
  PANEL_DYNAMIC: "paneldynamic",
  BAR_RATING: "barrating",
  COUNTRY: "country",
  NO_UI_SLIDER: "nouislider",
  SORTABLE_LIST: "sortablelist"
};

export const NoteEditorMode = {
  READ: "read",
  EDIT: "edit"
};

export const QUESTION_CONTROL_MODE = {
  user: "user",
  auditor: "auditor"
};

export const QUESTION_CONTROL_ICONS = {
  NOTE_ADD: "NoteAdd",
  NOTE_EXIST_SHARED: "NoteExistShared",
  NOTE_EXIST_PRIVATE: "NoteExistPrivate",
  NOTE_AUDITOR_ADD: "AddAuditorNote",
  NOTE_AUDITOR_SHARED: "SharedAuditorNote",
  NOTE_AUDITOR_PRIVATE: "PrivateAuditorNote",
};

export const QUESTIONS_PANELS = {
  NOTES: "notes",
  RECOMMENDATIONS: "recommendations",
  ANALYTICS: "analytics"
};

export const TOOLBOX_CUSTOM_TITLES = [
  {
    name: QUESTION_TYPES.TEXT,
    title: "Text Field"
  },
  {
    name: QUESTION_TYPES.RADIOGROUP,
    title: "Radiogroup"
  },
  {
    name: QUESTION_TYPES.IMAGE,
    title: "Insert Image"
  },
  {
    name: QUESTION_TYPES.EXPRESSION,
    title: "Expression"
  },
  {
    name: QUESTION_TYPES.MATRIX,
    title: "Matrix (Single)"
  },
  {
    name: QUESTION_TYPES.MATRIX_DROPDOWN,
    title: "Matrix (Multiple)"
  },
  {
    name: QUESTION_TYPES.MATRIX_DYNAMIC,
    title: "Matrix (Dynamic)"
  },
];

export const QUESTION_PROPERTIES = {
  QUESTION_ID: "question_id",
  NAME: "name",
  LOCK_ANSWER: "lockAnswer",
  TOPIC_ID: "Topic id"
};

export const QUESTION_VALIDATION = {
  NOT_VALID: "question-not-valid",
  VALID: "question-valid",
};

export const SJS_PAGES = {
  IMPORTED_QUESTIONS: "imported_questions"
};

export const TABS = {
  DESIGNER: "designer",
  PREVIEW: "test",
  LOGIC: "logic",
  JSON: "editor",
  TRANSLATOR: "translation"
};

export const targetTypes = {
  ASSESSMENT: "assessment",
  REGISTRATION: "registration"
};