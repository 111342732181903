import classnames from "classnames";
import styles from "../styles.module.scss";

const useClasses = ({ classes, showDashedContentBorder }) => {
  const overlayStyles = classnames({
    "asr-modal-overlay": true,
    [classes?.overlay]: Boolean(classes?.overlay),
  });

  const modalStyles = classnames({
    [styles.LightModal]: true,
    [classes?.modal]: Boolean(classes?.modal)
  });

  const modalBoxStyles = classnames({
    [styles.LightModalBox]: true,
    [classes?.modalBox]: Boolean(classes?.modalBox)
  });

  const modalGapContainerStyles = classnames({
    [styles.LightModalGapContainer]: true,
    [classes?.modalGapContainer]: Boolean(classes?.modalGapContainer)
  });

  const modalContentContainerStyles = classnames({
    [styles.LightModalContentContainer]: true,
    [styles.DashedBorder]: showDashedContentBorder,
    [classes?.contentContainer]: Boolean(classes?.contentContainer)
  });

  const modalHeaderContainerStyles = classnames({
    [styles.LightModalTitleContainer]: true,
    [classes?.titleContainer]: Boolean(classes?.titleContainer)
  });

  const modalFooterContainerStyles = classnames({
    [styles.ModalFooter]: true,
    [classes?.footerContainer]: Boolean(classes?.footerContainer)
  });

  return {
    overlay: overlayStyles,
    modal: modalStyles,
    modalBox: modalBoxStyles,
    modalGapContainer: modalGapContainerStyles,
    contentContainer: modalContentContainerStyles,
    titleContainer: modalHeaderContainerStyles,
    footerContainer: modalFooterContainerStyles
  };
};

export default useClasses;
