import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// MATERIAL UI
import { Card, CardContent, CardHeader } from "@mui/material";
import Collapse from "@mui/material/Collapse";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// HELPERS
import { getQuestionId } from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/questions";
// CONSTANTS
import { QUESTIONS_PANELS } from "@/shared/constants/surveyJs";
// ICONS
// import AsrDotHandleIcon from "@/shared/UI/Icons/AsrDotHandleIcon/AsrDotHandleIcon";
// COMPONENTS
import NoteForm from "./forms/NoteForm/NoteForm";
// STORE
import { getQuestionsManagement } from "@/store/Survey/selectors";
import { modifySurveyAuditorQuestionPanel } from "@/store/Survey/actions";
// STYLES
import cardStyles from "../card.module.scss";


const AssessorNotes = ({
                         locale,
                         changeQuestionNote,
                         onNoteUpdate,
                         onNoteDelete,
                         manageType,
                         question_id,
                         isVisible = true,
                         canCreateAssessorNotes,
                         canModifyAssessorNotes,
                         canRemoveAssessorNotes,
                       }) => {
  const dispatch = useDispatch();
  const questionsManagement = useSelector(getQuestionsManagement);

  useEffect(() => {
    if (questionsManagement[getQuestionId(question_id)]) {
      setIsOpen(questionsManagement[getQuestionId(question_id)][QUESTIONS_PANELS.NOTES]?.expanded);
    }
  }, [question_id, questionsManagement]);

  const [isOpen, setIsOpen] = useState(false);
  const [charactersCount, setCharactersCount] = useState(0);

  const title = getIntlMessageToStringLocal(locale, "notes.itemNotes.multiple");
  const characters = getIntlMessageToStringLocal(locale, "appModule.characters");
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    changeNotePanel(!isOpen);
  };

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
      changeNotePanel(true);
    }
  };

  const changeNotePanel = useCallback((isOpen) => {
    const payload = {
      question_id: getQuestionId(question_id),
      question_panel: QUESTIONS_PANELS.NOTES,
      data: {
        currentNote: questionsManagement[getQuestionId(question_id)][QUESTIONS_PANELS.NOTES]?.currentNote,
        expanded: isOpen,
      }
    };
    dispatch(modifySurveyAuditorQuestionPanel(payload));
  }, [dispatch, question_id, questionsManagement]);

  if (!isVisible) return null;

  return (
    <Card
      variant="outlined"
      classes={{
        root: cardStyles.root
      }}
    >
      <CardHeader
        onClick={handleOpen}
        // avatar={<AsrDotHandleIcon className={cardStyles.headerAvatar}/>}
        action={
          <i onClick={handleToggleOpen} className={`zmdi zmdi-${isOpen ? "minus" : "plus"}`}/>
        }
        classes={{
          root: `${cardStyles.header} ${isOpen ? cardStyles.open : cardStyles.close}`,
          action: cardStyles.actionIconContainer,
          content: cardStyles.headerContent,
          title: cardStyles.headerTitle,
          subheader: cardStyles.headerSubTitle
        }}
        title={title}
        subheader={charactersCount > 0 ? `${charactersCount} ${characters}` : ""}
      >
      </CardHeader>
      {/* CONTENT  */}
      <Collapse in={isOpen} timeout="auto">
        <CardContent
          classes={{
            root: cardStyles.content
          }}
        >
          <NoteForm
            questionsManagement={questionsManagement}
            setCharactersCount={setCharactersCount}
            locale={locale}
            question_id={question_id}
            onNoteUpdate={onNoteUpdate}
            onNoteDelete={onNoteDelete}
            manageType={manageType}
            changeQuestionNote={changeQuestionNote}
            canCreateAssessorNotes={canCreateAssessorNotes}
            canModifyAssessorNotes={canModifyAssessorNotes}
            canRemoveAssessorNotes={canRemoveAssessorNotes}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default AssessorNotes;