import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_MEMBERS_POLICIES_SORT, accountMemberPoliciesModals } from "./constants";

const initialState = {
  pagination: {
    page_size: 10,
    page: 1
  },
  sortBy: {
    sort_by: ACCOUNT_MEMBERS_POLICIES_SORT
  },
  modals: {
    [accountMemberPoliciesModals.CREATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberPoliciesModals.UPDATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberPoliciesModals.DELETE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberPoliciesModals.UNLOCK]: {
      isVisible: false,
      data: {},
    },
    [accountMemberPoliciesModals.EDIT_POLICY_JSON]: {
      isVisible: false,
      data: {},
    },
    [accountMemberPoliciesModals.SELECT_POLICY_ITEMS]: {
      isVisible: false,
      data: {},
    }
  }
};

export const memberPoliciesSlice = createSlice({
  name: "accountMembersPolicies",
  initialState,
  reducers: {
    setAccountMembersPoliciesPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload
      };
    },
    setAccountMembersPoliciesSort: (state, action) => {
      state.sortBy = {
        ...action.payload
      };
    },
    toggleAccountMemberPoliciesModal: (state, action) => {
      state.modals[action.payload.target] = {
        ...state.modals?.[action.payload.target],
        isVisible: action.payload.isVisible,
        data: action.payload?.data || {}
      };
    },
  },
});


export const {
  setAccountMembersPoliciesPagination,
  setAccountMembersPoliciesSort,
  toggleAccountMemberPoliciesModal
} = memberPoliciesSlice.actions;

export default memberPoliciesSlice.reducer;