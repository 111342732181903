import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// STYLES
import styles from "../styles.module.scss";


const SignInFormHeader = ({
                            closeModal
                          }) => {
  const modalToggleHandlers = useToggleCommonModalHandlers();

  const onSignInClick = () => {
    closeModal();
    modalToggleHandlers.signUp({ show: true });
  };

  return (
    <div className={styles.ModalHeaderBlock}>
      <div className={styles.ModalHeader}>
        <div className={styles.ModalHeaderTitle}>
          <IntlMessages id="appModule.signIn"/>
        </div>
        <div className={styles.ModalHeaderTextBlock}>
          <div className={styles.ModalHeaderText}>
            <IntlMessages id="auth.dontHaveAnAccount"/>?
          </div>

          <div
            className={`${styles.ModalHeaderText} ${styles.Clickable}`}
            onClick={onSignInClick}
          >
            <IntlMessages id="user.signUpInstead"/>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SignInFormHeader;
