import * as types from "./types";
import { success } from "redux-saga-requests";
import { UPDATE_ORGANIZATION_REGISTRATION_FEED_ANSWER_SINGLE } from "../Organization/types";

const initialState = {
  showTermModal: false,
  termData: "",
  showCompleteModal: false,
  questionData: {},
  showNoteModal: false,
  isPageValid: null,
  showPreviewNoteModal: false,
  unansweredQuestionsQuantityTotal: 0,
  unansweredQuestionsQuantityPage: 0,
  pagination: {
    surveyPrev: "",
    surveyNext: "",
    surveyComplete: ""
  },
  management: {
    updatedQuestion: null
  }
};


const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIALIZE_STATE_REGISTRATION: {
      const { questions, answers } = action.payload;
      let questionData = questions.reduce((acc, question) => ({
        ...acc,
        [question.propertyHash.name]: {
          name: question.propertyHash.name,
          question_id: question.propertyHash.question_id,
          answer: question.propertyHash.value,
          other_text: question.propertyHash.comment,
        }
      }), {});
      if (answers && Array.isArray(answers)) {
        answers.forEach((answer) => {
          questionData = {
            ...questionData,
            [answer.name]: {
              ...questionData[answer.name],
              created_dt: answer.created_dt || Date.now(),
              status: answer?.verification_status,
              note: answer?.auditor_note
            }
          };
        });
      }
      return {
        ...state,
        questionData
      };
    }
    case types.SET_QUESTION_DATA_REGISTRATION: {
      return {
        ...state,
        questionData: {
          ...state.questionData,
          ...action.payload
        }
      };
    }
    case types.SET_QUESTION_REGISTRATION_STATUS: {
      return {
        ...state,
        questionData: {
          ...state.questionData,
          [action.payload.questionName]: {
            ...state.questionData[action.payload.questionName],
            status: action.payload.status
          }
        }
      };
    }
    case types.SET_QUESTION_REGISTRATION_NOTE: {
      return {
        ...state,
        questionData: {
          ...state.questionData,
          [action.payload.questionName]: {
            ...state.questionData[action.payload.questionName],
            note: action.payload.note
          }
        }
      };
    }
    case types.COUNT_UNANSWERED_QUESTION_REGISTRATION: {
      const unansweredQuestionsQuantityPage = action.payload.questions
        .filter(({
                   isVisible,
                   isRequired,
                   isAnswered,
                   page
                 }) => isVisible && isRequired && !isAnswered && page.isActive)
        .length;

      const unansweredQuestionsQuantityTotal = action.payload.questions
        .filter(({
                   isVisible,
                   isRequired,
                   isAnswered,
                   page
                 }) => isVisible && isRequired && !isAnswered && page.isVisible)
        .length;
      return {
        ...state,
        unansweredQuestionsQuantityTotal,
        unansweredQuestionsQuantityPage
      };
    }
    case types.SET_PAGE_VALID_REGISTRATION : {
      return {
        ...state,
        isPageValid: action.payload
      };
    }
    case types.ON_CURRENT_PAGE_CHANGED_REGISTRATION : {
      const { surveyModel } = action.payload;
      return {
        ...state,
        pagination: {
          surveyPrev: !surveyModel.isFirstPage ? "inline" : "none",
          surveyNext: !surveyModel.isLastPage ? "inline" : "none",
          surveyComplete: surveyModel.isLastPage ? "inline" : "none"
        }
      };
    }
    case types.SET_STATE_COMMON_REGISTRATION : {
      return {
        ...state,
        ...action.payload
      };
    }
    case success(UPDATE_ORGANIZATION_REGISTRATION_FEED_ANSWER_SINGLE): {
      const { response: { results } } = action.payload;
      return {
        ...state,
        management: {
          updatedQuestion: results
        }
      };
    }
    case types.RESET_SURVEY_ASSESSMENT_STATE_REGISTRATION: {
      return initialState;

    }
    default:
      return state;
  }
};

export default reducers;