import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
// HOOKS
import useInitialValues from "../../hooks/useInitialValues";
import useValidationSchema from "../../hooks/useValidationSchema";
import useErrors from "@/shared/hooks/errors/useErrors";
// STORE
import { restorePasswordRequest } from "@/store/User/actions";
import { getIsUserAuthenticated } from "@/store/User/selectors";
// COMPONENTS
import RestorePasswordFormHeader from "./components/RestorePasswordFormHeader";
import RestorePasswordFormFields from "./components/RestorePasswordFormFields";
import RestorePasswordFormFooter from "./components/RestorePasswordFormFooter";
// STYLES
import styles from "./styles.module.scss";


const RestorePasswordLightModalForm = ({ closeModal, showSucceedModalHandler }) => {
  const dispatch = useDispatch();
  const isUserAuthenticated = useSelector(getIsUserAuthenticated);

  const formRef = useRef();
  const { handleFetchErrors, setFormFieldErrors } = useErrors();

  const onSubmitForm = async (values) => {
    try {
      const userEmail = values.email;
      await dispatch(restorePasswordRequest(userEmail));
      showSucceedModalHandler(userEmail);
    } catch (error) {
      const errorData = error?.payload?.response?.data;
      await handleFetchErrors(errorData, { notificationWhiteList: "*" });
      formRef?.current && setFormFieldErrors({
        setFieldError: formRef.current.setFieldError,
        details: errorData?.details
      });
    }
  };

  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {fmk => (
        <Form>
          <div className={styles.ModalContentContainer}>
            <RestorePasswordFormHeader/>
            <RestorePasswordFormFields fmk={fmk}/>
            <RestorePasswordFormFooter
              closeModal={closeModal}
              isUserAuthenticated={isUserAuthenticated}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RestorePasswordLightModalForm;
