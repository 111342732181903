import React, { memo } from "react";
// UTILS
import reactStringReplace from "@/shared/utils/stringReplace";
// REGEX
import { parsBracketsRegex } from "@/shared/regex/auditor-regex";
// STYLES
import styles from "./styles.module.scss";
// HELPERS
import { eventTooltip } from "@/components/SurveyJs/components/ViewerV2/helpers/eventTooltip";


const SurveyTermTooltip = memo(({
                                  text,
                                  id,
                                  locale,
                                  ignoreEventTooltip = false
                                }) => {
  if (!ignoreEventTooltip) {
    const termLink = document.getElementById(`asr-cf-term-link__${id}-${text}`);

    if (termLink) eventTooltip({ termLink, id, text, locale });
  }

  return reactStringReplace(text, parsBracketsRegex, (match, i) => {
    return (
      <div className={styles.surveyTermTooltipContainer}>
        <a
          key={i}
          id={`asr-cf-term-link__${id}-${text}`}
          data-question-id={id}
          data-term-text={text.substring(2, text.length - 2)}
          data-language={locale}
        >
          {match}
        </a>
      </div>
    );
  });
});

export default SurveyTermTooltip;