import { baseApiUrl } from "../../../configs/MainConfig";
import { requestActionCreator } from "../../../utils/requestActionCreator";
import * as types from "../types";
import { authService } from "../../../shared/services/auth.service";

export const fetchOrganizationLog = (organizationId, filters, page = 1, page_size = 10) => {
  let url = `${baseApiUrl}/log/list/?organization_id=${organizationId}`;
  if (filters) {
    for (const [filterKey, filterValue] of Object.entries(filters)) {
      url = url + `&${filterKey}=${filterValue}`;
    }
  }
  return requestActionCreator(
      types.FETCH_ORGANIZATION_LOG,
      `${url}&page=${page}&page_size=${page_size}&sort_by=-id`,
      "GET",
      { ...authService.getAuthHeaders() }
  );
};

// export const fetchOrganizationLogActionTypes = () => requestActionCreator(
//     types.FETCH_ORGANIZATION_LOG_ACTION_TYPES,
//     `${baseApiUrl}/choice-lists/?key=LOG_ACTION_TYPE`,
//     'GET',
//     {...defaultJSONHeadersWithAuth()}
// );

export const fetchOrganizationLogActionTypes = () => {
  return requestActionCreator(
      types.FETCH_ORGANIZATION_LOG_ACTION_TYPES,
      `${baseApiUrl}/choice-lists/?key=LOG_ACTION_TYPE`,
      "GET",
      { ...authService.getAuthHeaders() }
  );
};

export const cleanOrganizationLog = () => ({
  type: types.CLEAN_ORGANIZATION_LOG,
});

export const updateOrganizationLogFilters = (data) => ({
  type: types.UPDATE_ORGANIZATION_LOG_FILTERS,
  payload: data
});

export const cleanOrganizationLogFilters = () => ({
  type: types.CLEAN_ORGANIZATION_LOG_FILTERS,
});
