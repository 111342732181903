import account from "./validationEntities/account";
import accountInvitation from "./validationEntities/accountInvitation";
import accountMember from "./validationEntities/accountMember";
import accountRole from "./validationEntities/accountRole";
import accountUser from "./validationEntities/accountUser";
import assessment from "./validationEntities/assessment";
import assessmentAct from "./validationEntities/assessmentAct";
import auditor from "./validationEntities/auditor";
import campaign from "./validationEntities/campaign";
import campaignInvitation from "./validationEntities/campaignInvitation";
import campaignParticipant from "./validationEntities/campaignParticipant";
import customField from "./validationEntities/customField";
import label from "./validationEntities/label";
import labelGroup from "./validationEntities/labelGroup";
import notification from "./validationEntities/notification";
import policy from "./validationEntities/policy";
import powerBIDataset from "./validationEntities/powerBIDataset";
import powerBIReport from "./validationEntities/powerBIReport";
import registrationAct from "./validationEntities/registrationAct";
import registrationForm from "./validationEntities/registrationForm";
import statement from "./validationEntities/statement";
import statementSet from "./validationEntities/statementSet";
import surveyTemplate from "./validationEntities/surveyTemplate";


const validators = {
  ...account,
  ...accountInvitation,
  ...accountMember,
  ...accountRole,
  ...accountUser,
  ...assessment,
  ...assessmentAct,
  ...auditor,
  ...campaign,
  ...campaignInvitation,
  ...campaignParticipant,
  ...customField,
  ...label,
  ...labelGroup,
  ...notification,
  ...policy,
  ...powerBIDataset,
  ...powerBIReport,
  ...registrationAct,
  ...registrationForm,
  ...statement,
  ...statementSet,
  ...surveyTemplate
};

export default validators;