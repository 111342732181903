export default `
  body {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
     line-height: 21px;
    color: #4F4F4F;
   }
   a {
      color: #24B3B3 !important;
      text-decoration: none !important;
    } 
    p{
      margin: 0;
    }
`;