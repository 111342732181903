(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("inputmask"), require("nouislider"), require("sortablejs"), require("bootstrap-slider"));
	else if(typeof define === 'function' && define.amd)
		define("surveyjs-widgets", ["inputmask", "nouislider", "sortablejs", "bootstrap-slider"], factory);
	else if(typeof exports === 'object')
		exports["surveyjs-widgets"] = factory(require("inputmask"), require("nouislider"), require("sortablejs"), require("bootstrap-slider"));
	else
		root["surveyjs-widgets"] = factory(root["Inputmask"], root["noUiSlider"], root["Sortable"], root["Slider"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_inputmask__, __WEBPACK_EXTERNAL_MODULE_nouislider__, __WEBPACK_EXTERNAL_MODULE_sortablejs__, __WEBPACK_EXTERNAL_MODULE_bootstrap_slider__) => {
return 