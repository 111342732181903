import React from "react";
import { useSelector } from "react-redux";

import IntlMessages from "@/utils/IntlMessages";
import { useCheckPermissions } from "@/utils/checkPermission";
import { baseUrl } from "@/configs/MainConfig";

// import SideNavLogs from "../Components/SideNavLogs/SideNavLogs";
import SideNavLabels from "../Components/SideNavLabels/SideNavLabels";
// import SideNavReports from "../Components/SideNavReports/SideNavReports";
import SideNavStatements from "../Components/SideNavStatements/SideNavStatements";
import SideNavHome from "../Components/SideNavHome/SideNavHome";
import SideNavPages from "../Components/SideNavPages/SideNavPages";
import SideNavOrganizations from "../Components/SideNavOrganizations/SideNavOrganizations";
import SideNavUsers from "../Components/SideNavUsers/SideNavUsers";
// STORE
import { getUserSystemPermissions } from "@/store/User/selectors";


const SideNavMain = (props) => {
  const userSystemPermissions = useSelector(getUserSystemPermissions);

  const isSuperUser = useCheckPermissions();
  const canSeeStatementTranslationsTab = useCheckPermissions(
    userSystemPermissions ? Object.keys(userSystemPermissions) : [],
    ["user.global_statements.modify_translations"]);
  const canSeeStatements = isSuperUser || canSeeStatementTranslationsTab;


  return (
    <>
      {/* HOME */}
      <li className="menu no-arrow">
        <SideNavHome isStaffView {...props} />
      </li>
      {/* ORGANIZATIONS */}
      <li className="menu no-arrow">
        <SideNavOrganizations {...props} />
      </li>
      {/* STATEMENTS */}
      {canSeeStatements && (
        <li className="menu no-arrow">
          <SideNavStatements
            canSeeStatementTranslationsTab={canSeeStatementTranslationsTab}
            isSuperUser={isSuperUser}
            isStaffView {...props}/>
        </li>
      )}
      {/*  LABELS */}
      {isSuperUser && (
        <li className="menu no-arrow">
          <SideNavLabels
            isSuperUser={isSuperUser}
            isStaffView
            {...props}
          />
        </li>
      )}
      {/*  PAGES */}
      {isSuperUser && (
        <li className="menu no-arrow">
          <SideNavPages isStaffView {...props}/>
        </li>
      )}
      {/*/!* REPORTS *!/*/}
      {/*{isSuperUser && (*/}
      {/*  <li className="menu no-arrow">*/}
      {/*    <SideNavReports isStaffView {...props}/>*/}
      {/*  </li>*/}
      {/*)}*/}
      {/*/!* LOGS *!/*/}
      {/*{isSuperUser && (*/}
      {/*  <li className="menu no-arrow">*/}
      {/*    <SideNavLogs isStaffView {...props}/>*/}
      {/*  </li>*/}
      {/*)}*/}
      {/* USERS */}
      {isSuperUser && (
        <li className="menu no-arrow">
          <SideNavUsers isStaffView {...props} />
        </li>
      )}
      {/*  ADMIN   */}
      {isSuperUser && (
        <li className="menu no-arrow">
          <a
            className="prepend-icon admin"
            href={`${baseUrl}/admin/`}
            onClick={props.closeNavCallBack}
          >
            <span className="nav-text">
                <IntlMessages id="sidenav.adminDashboard"/>
            </span>
          </a>
        </li>
      )}
    </>
  );
};

SideNavMain.displayName = "SideNavMain";
export default SideNavMain;