import React from "react";
// STYLES
import styles from "../styles.module.scss";
import IntlMessages from "@/utils/IntlMessages";

const EnterCodeWrongCodeOverlay = ({ onClose }) => {
  return (
    <div className={styles.WrongCodeOverlayContainer}>
      <div className={styles.WrongCodeTitle}>
        <IntlMessages id="appModule.incorrectCode"/>!
      </div>
      <div className={styles.WrongCodeDefinition}>
        <IntlMessages id="appModule.pleaseCheckSpellingAncCapitalization"/>
      </div>
      <div className={styles.WrongCodeActionText} onClick={onClose}>
        <IntlMessages id="appModule.clickHereToTryAgain"/>
      </div>
    </div>
  );
};

export default EnterCodeWrongCodeOverlay;
