export const findEmailInAllTemplateQuestions = (questions = []) => {
  let email = "";
  const filteredQuestions = questions
    .map(question => question?.propertyHash)
    .filter(question => question && question.inputType === "email");

  filteredQuestions.reverse().forEach(question => {
    if (question?.value) {
      email = question.value;
    }
  });
  return email;
};
