export const QUERY_PARAMS = {
  IFB_SECTION: "section",
  IFB_TARGET_OVERVIEW: "overview",
  IFB_TARGET_FEATURES: "features",
  SYSTEM_BLOCK_KEY: "key",
};

export const SYSTEM_BLOCK_KEYS = {
  ADD_PARTICIPANT_WARNING: "add_participant_warning",
  ADD_MEMBER_WARNING: "add_member_warning",
  POLICY_CONSENT: "policy_consent",
  CAMPAIGN_INSTRUCTIONS_WARNING: "campaign_instructions_warning",
  CAMPAIGN_CLOSED_TEXT_WARNING: "campaign_closed_message_warning",
  CAMPAIGN_ARCHIVED_WARNING: "campaign_archived_message_warning",
};

export const TARGETS = {
  RETRIEVE_ITEM_INFO: "retrieve-item-info",
};

export const infoblockTypes = {
  RTEXT: "rtext"
};

export const TAGS = {
  INFOBLOCKS: "infoblocksListTag",
  SYSTEM_BLOCKS: "systemBlocksListTag"
};