export default (data) => {
    if (data) {
        const total = data.reduce((acc, item) => acc + item.value, 0);
        const result = [...data];
        if (total < 100) {
            result.push({
                title: "Not specified",
                value: 100 - total,
            });
            return result;
        }
        return data;
    } else {
        return [{
            title: "Not specified",
            value: 100,
        }];
    }
};