import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
// import { ReactSortable } from "react-sortablejs";
//  MATERIAL UI
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
// TYPES
import surveyStateTypes from "@/store/SurveyAssessment/surveyStateTypes";
// HOOKS
import useAuditorActionStatus from "@/shared/Survey/hooks/useAuditorActionStatus";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// COMPONENTS
import Analytics from "./components/Analytics/Analytics";
import AssessorNotes from "./components/AssessorNotes/AssessorNotes";
// import Recommendations from "./components/Recommendations/Recommendations";
// STORE
import { getLocale } from "@/store/Settings/selectors";
import {
  getQuestionAnalyticsChecked,
  getQuestionNotesChecked,
  // getQuestionRecommendationsChecked,
  getQuestionsAuditorControls
} from "@/store/Survey/selectors";
import { getQuestionData } from "@/store/SurveyAssessment/selectors";

// STYLES
import styles from "./styles.module.scss";
import commonStyles from "../common.module.scss";


const SurveyQuestionAuditorActions = ({
                                        selectHandler,
                                        questionName,
                                        question_id,
                                        changeQuestionNote,
                                        manageType,
                                        note,
                                        survey,
                                        question,
                                        allQuestions,
                                        assessmentId,
                                        questionType,
                                        /// PERMS
                                        canCreateAssessorNotes,
                                        // canViewRecommendations,
                                        canViewAssessorNotes,
                                        canViewAnalytics,
                                        canValidateAssessmentAnswer,
                                        canModifyAssessorNotes,
                                        canRemoveAssessorNotes
                                      }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(getLocale);
  const auditorControls = useSelector(getQuestionsAuditorControls);
  const questionNotesChecked = useSelector(getQuestionNotesChecked);
  const questionAnalyticsChecked = useSelector(getQuestionAnalyticsChecked);
  const questionData = useSelector(getQuestionData);

  const verified = getIntlMessageToStringLocal(locale, "assessments.verified");
  const modified = getIntlMessageToStringLocal(locale, "assessments.modified");

  const verifiedBtnTooltip = getIntlMessageToStringLocal(locale, "appModule.changeStatusTooltip");

  const {
    handleVerified,
    questionStatus
  } = useAuditorActionStatus({
    questionData,
    questionName,
    selectHandler
  });

  const verifiedButtonStatusClass = classnames({
    [styles.verifiedButtonRoot]: true,
    [styles.verifiedButtonRootVerified]: questionStatus === verified,
    [styles.verifiedButtonRootModified]: questionStatus === modified,
  });

  const onNoteUpdate = ({ updatedNote }) => {
    dispatch({
      type: surveyStateTypes.SET_QUESTION_NOTE,
      payload: {
        questionName,
        note: updatedNote
      }
    });
  };

  const onNoteDelete = ({ note }) => {
    if (!note) {
      dispatch({
        type: surveyStateTypes.SET_QUESTION_NOTE,
        payload: {
          questionName,
          note: {},
        }
      });
    }
  };
  if (!canValidateAssessmentAnswer && !canViewAssessorNotes && !canViewAnalytics) {
    return null;
  }
  return (
    <div className={styles.SurveyQuestionAuditorActions}>
      {canValidateAssessmentAnswer && (
        <Tooltip
          title={verifiedBtnTooltip}
          placement="top"
          classes={{
            popper: commonStyles.verifiedButtonTooltipPopper,
            tooltip: commonStyles.verifiedButtonTooltip,
            arrow: commonStyles.verifiedButtonArrow
          }}
          arrow
        >
          <Button
            classes={{
              root: verifiedButtonStatusClass
            }}
            variant="outlined"
            color="primary"
            onClick={handleVerified}
          >
            {questionStatus}
          </Button>
        </Tooltip>
      )}
      <div className={styles.SurveyQuestionAuditorPanels}>
        <AssessorNotes
          isVisible={canViewAssessorNotes && questionNotesChecked}
          question_id={question_id}
          manageType={manageType}
          changeQuestionNote={changeQuestionNote}
          onNoteUpdate={onNoteUpdate}
          onNoteDelete={onNoteDelete}
          locale={locale}
          note={note}
          canCreateAssessorNotes={canCreateAssessorNotes}
          canModifyAssessorNotes={canModifyAssessorNotes}
          canRemoveAssessorNotes={canRemoveAssessorNotes}
        />
        <Analytics
          isVisible={canViewAnalytics && questionAnalyticsChecked}
          locale={locale}
          survey={survey}
          question={question}
          allQuestions={allQuestions}
          question_id={question_id}
          assessmentId={assessmentId}
          manageType={manageType}
          questionType={questionType}
          auditorControls={auditorControls}
        />
      </div>
    </div>
  );
};


export default SurveyQuestionAuditorActions;