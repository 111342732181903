// Previous
export const prevPageStrings = {
  "en": "Previous",
  "ar": "السابق",
  "eu": "Aurrekoa",
  "bg": "Назад",
  "ca": "Anterior",
  "hr": "Prethodni",
  "cs": "Předchozí",
  "da": "Tilbage",
  "nl": "Vorige",
  "et": "Tagasi",
  "fi": "Edellinen",
  "fr": "Précédent",
  "ka": "უკან",
  "de": "Zurück",
  "gr": "Προηγούμενο",
  "he": "אחורה",
  "hi": "אחורה",
  "hu": "Vissza",
  "is": "Tilbaka",
  "id": "Sebelumnya",
  "it": "Precedente",
  "ja": "前へ",
  "kk": "Артқа",
  "ko": "이전",
  "lv": "Atpakaļ",
  "lt": "Atgal",
  "mk": "Претходна",
  "ms": "Sebelumnya",
  "nl-BE": "Vorige",
  "no": "Forrige",
  "fa": "قبلی",
  "pl": "Wstecz",
  "pt-br": "Previous",
  "pt": "Anterior",
  "ro": "Precedent",
  "ru": "Назад",
  "rs": "Nazad",
  "zh-cn": "上一页",
  "sk": "Predchádzajúca",
  "es": "Anterior",
  "sw": "Iliyotangulia",
  "sv": "Föregående",
  "tg": "Бозгашт",
  "tel": "వెనకటి",
  "th": "ก่อนหน้า",
  "zh-tw": "上一頁",
  "tr": "Geri",
  "ua": "Назад",
  "vi": "Trở về",
  "cy": "Blaenorol"
};

// Next
export const nextPageStrings = {
  "en": "Next",
  "ar": "التالي",
  "eu": "Hurrengoa",
  "bg": "Напред",
  "ca": "Següent",
  "hr": "Sljedeći",
  "cs": "Další",
  "da": "Videre",
  "nl": "Volgende",
  "et": "Edasi",
  "fi": "Seuraava",
  "fr": "Suivant",
  "ka": "შემდეგ",
  "de": "Weiter",
  "gr": "Επόμενο",
  "he": "קדימה",
  "hi": "קדימה",
  "hu": "Tovább",
  "is": "Áfram",
  "id": "Selanjutnya",
  "it": "Successivo",
  "ja": "次へ",
  "kk": "Келесі",
  "ko": "다음",
  "lv": "Tālāk",
  "lt": "Toliau",
  "mk": "Следно",
  "ms": "Seterusnya",
  "nl-BE": "Volgende",
  "no": "Neste",
  "fa": "بعدی",
  "pl": "Dalej",
  "pt-br": "Next",
  "pt": "Próximo",
  "ro": "Următor",
  "ru": "Далее",
  "rs": "Dalje",
  "zh-cn": "下一页",
  "sk": "Ďalej",
  "es": "Siguiente",
  "sw": "Ifuatayo",
  "sv": "Nästa",
  "tg": "Оянда",
  "tel": "తరువాయ",
  "th": "ถัดไป",
  "zh-tw": "下一頁",
  "tr": "İleri",
  "ua": "Далі",
  "vi": "Tiếp theo",
  "cy": "Nesaf"
};

// Complete
export const completeSurveyStrings = {
  "en": "Complete",
  "ar": "إرسال البيانات",
  "eu": "Bukatu",
  "bg": "Край",
  "ca": "Complet",
  "hr": "Kompletan",
  "cs": "Dokončit",
  "da": "Færdig",
  "nl": "Verzenden",
  "et": "Lõpeta",
  "fi": "Valmis",
  "fr": "Terminer",
  "ka": "დასრულება",
  "de": "Abschließen",
  "gr": "Ολοκλήρωση",
  "he": "סיום",
  "hi": "סיום",
  "hu": "Kész",
  "is": "Lokið",
  "id": "Selesai",
  "it": "Salva",
  "ja": "完了",
  "kk": "Дайын",
  "ko": "완료",
  "lv": "Iesniegt",
  "lt": "Baigti",
  "mk": "Заврши",
  "ms": "Selesai",
  "nl-BE": "Verzenden",
  "no": "Fullfør",
  "fa": "تکمیل",
  "pl": "Gotowe",
  "pt-br": "Complete",
  "pt": "Finalizar",
  "ro": "Finalizare",
  "ru": "Готово",
  "rs": "Završi",
  "zh-cn": "提交问卷",
  "sk": "Dokončené",
  "es": "Completar",
  "sw": "Kamili",
  "sv": "Slutför",
  "tg": "Иҷро шуд",
  "tel": "పూర్తి చేయండి",
  "th": "สำเร็จ",
  "zh-tw": "提交問卷",
  "tr": "Anketi Tamamla",
  "ua": "Завершити",
  "vi": "Hoàn thành",
  "cy": "Cwblhau"
};

// Unanswered
export const unansweredQuestionsStrings = {
  "en": "unanswered",
  "ar": "دون إجابة",
  "eu": "erantzun gabe",
  "bg": "без отговор",
  "ca": "sense resposta",
  "hr": "bez odgovora",
  "cs": "nezodpovězeno",
  "da": "ubesvaret",
  "nl": "onbeantwoord",
  "et": "vastamata",
  "fi": "vastaamatta",
  "fr": "sans réponse",
  "ka": "უპასუხოდ",
  "de": "unbeantwortet",
  "gr": "αναπάντητος",
  "he": "ללא מענה",
  "hi": "अनुत्तरित",
  "hu": "megválaszolatlan",
  "is": "ósvarað",
  "id": "belum terjawab",
  "it": "senza risposta",
  "ja": "未回答",
  "kk": "жауапсыз",
  "ko": "보답 없는",
  "lv": "neatbildēts",
  "lt": "neatsakyta",
  "mk": "неодговорено",
  "ms": "tak terjawab",
  "nl-BE": "onbeantwoord",
  "no": "ubesvart",
  "fa": "بی پاسخ",
  "pl": "bez odpowiedzi",
  "pt-br": "sem resposta",
  "pt": "sem resposta",
  "ro": "fără răspuns",
  "ru": "без ответа",
  "rs": "без одговора",
  "zh-cn": "未答复的",
  "sk": "nezodpovedané",
  "es": "sin respuesta",
  "sw": "bila kujibiwa",
  "sv": "obesvarad",
  "tg": "беҷавоб",
  "tel": "సమాధానం చెప్పలేదు",
  "th": "ยังไม่ได้รับคำตอบ",
  "zh-tw": "未答复的",
  "tr": "cevapsız",
  "ua": "без відповіді",
  "vi": "chưa được trả lời",
  "cy": "heb ei ateb"
};

// Total
export const totalQuestionsStrings = {
  "en": "total",
  "ar": "مجموع",
  "eu": "guztira",
  "bg": "обща",
  "ca": "total",
  "hr": "ukupno",
  "cs": "celkový",
  "da": "total",
  "nl": "totaal",
  "et": "kokku",
  "fi": "kaikki yhteensä",
  "fr": "total",
  "ka": "სულ",
  "de": "gesamt",
  "gr": "σύνολο",
  "he": "סה\"כ",
  "hi": "कुल",
  "hu": "teljes",
  "is": "alls",
  "id": "total",
  "it": "totale",
  "ja": "合計",
  "kk": "жалпы",
  "ko": "총",
  "lv": "kopā",
  "lt": "viso",
  "mk": "вкупно",
  "ms": "jumlah",
  "nl-BE": "totaal",
  "no": "total",
  "fa": "جمع",
  "pl": "całkowity",
  "pt-br": "total",
  "pt": "total",
  "ro": "total",
  "ru": "общий",
  "rs": "укупно",
  "zh-cn": "全部的",
  "sk": "celkom",
  "es": "total",
  "sw": "jumla",
  "sv": "total",
  "tg": "умумии",
  "tel": "మొత్తం",
  "th": "ทั้งหมด",
  "zh-tw": "全部的",
  "tr": "toplam",
  "ua": "всього",
  "vi": "tổng cộng",
  "cy": "cyfanswm"
};