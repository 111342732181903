import { useCallback } from "react";
import { replaceTagsRegex } from "@/shared/regex";
import { parsBracketsRegex } from "@/shared/regex/auditor-regex";
import reactStringReplace from "@/shared/utils/stringReplace";

const bracketsRegx = /[[\]]+/g;

const useAnalyticsAnswerHighlight = ({ survey, question }) => {

  const checkIsUserAnswer = useCallback((text) => {
    const locale = survey.locale || "default";
    const value = question?.propertyHash?.value;

    const type = question.getType();

    const isOther = question?.propertyHash?.comment;

    if (type === "radiogroup" || type === "dropdown" || type === "imagepicker") {
      const answerLocValue = question?.propertyHash?.choices?.find(i => i?.propertyHash?.value === value);
      if (isOther) {
        return question?.otherText === text;
      }
      if (answerLocValue) {
        const val = answerLocValue?.locText?.values[locale]?.replace(bracketsRegx, "") || answerLocValue?.locText?.renderedText?.replace(bracketsRegx, "");
        return val === text.replace(replaceTagsRegex, "").trim();
      }
    }

    // CHECKBOX
    if (type === "checkbox") {
      const values = question?.propertyHash?.value;
      const answerLocValue = values.map((i) => {
        const choice = question?.propertyHash?.choices.find((j) => j?.propertyHash?.value === i);
        if (choice) {
          return choice?.locText?.values[locale]?.replace(bracketsRegx, "").trim() ?? choice?.locText?.renderedText.replace(bracketsRegx, "").trim();
        }
      }) || [];
      if (isOther) {
        const parsedText = reactStringReplace(question?.otherText, parsBracketsRegex, (match) => {
          return match;
        }).join("");
        answerLocValue.push(parsedText.trim());
      }
      const preparedText = reactStringReplace(text.replace(replaceTagsRegex, ""), parsBracketsRegex, (match) => {
        return match;
      }).join("");

      return answerLocValue.includes(preparedText.trim());
    }
    // BOOLEAN
    if (type === "boolean") {
      const answerValue = question?.propertyHash?.value;
      const locLabelFalse = question?.locLabelFalse?.renderedText;
      const locLabelTrue = question?.locLabelTrue?.renderedText;
      const answerLocValue = answerValue ? locLabelTrue : locLabelFalse;
      const parsedValue = reactStringReplace(answerLocValue, parsBracketsRegex, (match) => {
        return match;
      }).join("");

      return parsedValue.trim() === text.replace(replaceTagsRegex, "").trim();
    }
    // text - rating
    if (type === "text" || type === "rating") {
      const answerValue = question?.propertyHash?.value?.toString();
      return answerValue === text.trim();
    }
    // matrix
    if (type === "matrix") {
      const isRows = question?.propertyHash?.rows?.length;

      if (isRows) {
        const answersKeys = question?.propertyHash?.value ? Object.keys(question?.propertyHash?.value) : [];
        const answersRows = answersKeys.map((i) => {
          return question?.propertyHash?.rows.find((j) => j?.propertyHash?.value === i);
        });

        const answerLocValue = answersRows.map(i => i.locText?.values[locale]?.replace(bracketsRegx, "") ?? i?.locText?.renderedText);
        return answerLocValue.includes(text.replace(replaceTagsRegex, "").trim());
      } else {
        const answersCol = question?.propertyHash?.columns.find((j) => j?.propertyHash?.value === question?.propertyHash?.value);
        return answersCol?.propertyHash?.value === text.replace(replaceTagsRegex, "").trim();
      }
    }
  }, [question, survey.locale]);


  return { checkIsUserAnswer };
};

export default useAnalyticsAnswerHighlight;