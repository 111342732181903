export default {
  "ar": "العربية",
  "eu": "euskara",
  "bg": "български",
  "ca": "català",
  "hr": "hrvatski",
  "cs": "čeština",
  "da": "dansk",
  "nl": "nederlands",
  "et": "eesti keel",
  "fi": "suomi",
  "fr": "français",
  "ka": "ქართული",
  "de": "deutsch",
  "gr": "ελληνικά",
  "he": "עברית",
  "hi": "hindi",
  "hu": "magyar",
  "is": "íslenska",
  "id": "bahasa indonesia",
  "it": "italiano",
  "ja": "日本語",
  "kk": "kazakh",
  "ko": "한국어",
  "lv": "latviešu",
  "lt": "lietuvių",
  "mk": "македонски",
  "ms": "melayu",
  "nl-BE": "vlaams",
  "no": "norsk",
  "fa": "فارْسِى",
  "pl": "polski",
  "pt-br": "português brasileiro",
  "pt": "português",
  "ro": "română",
  "ru": "русский",
  "rs": "srpski",
  "zh-cn": "简体中文",
  "sk": "slovenčina",
  "es": "español",
  "sw": "swahili",
  "sv": "svenska",
  "tg": "тоҷикӣ",
  "tel": "telugu",
  "th": "ไทย",
  "zh-tw": "繁體中文",
  "tr": "türkçe",
  "ua": "українська",
  "vi": "việt Nam",
  "cy": "cymraeg",
  "en": "english"
};