import React from "react";
// COMPONENTS
import AsrSaveButton from "@/shared/components/Buttons/AsrSaveButton";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// STYLES
import styles from "../styles.module.scss";

const SignInFormFooter = ({ closeModal }) => {
  const modalToggleHandlers = useToggleCommonModalHandlers();

  const onForgotPasswordClick = () => {
    closeModal();
    modalToggleHandlers.restorePassword({ show: true });
  };

  return (
    <div className={styles.ModalFooter}>
      <AsrSaveButton
        type='submit'
        classes={{
          buttonRoot: styles.SubmitButton
        }}
        classNameWrapper={styles.SubmitButtonWrapper}
      >
        <IntlMessages
          id="auth.signInButtonText"
          values={{
            span: value => <span className={styles.AttachmentSpan}>{value}</span>
          }}
        />
      </AsrSaveButton>

      <div
        className={styles.ForgotPasswordText}
        onClick={onForgotPasswordClick}
      >
        <IntlMessages id="user.forgotPassword"/>?
      </div>
    </div>
  );
};

export default SignInFormFooter;
