import React from "react";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import CardBox from "../CardBox/CardBox";
import QuestionAnswer from "../../Components/QuestionAnswer";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    padding: "3px",
    border: "1px solid #24B2B2",
    display: "flex",
    flexDirection: "column"
  },
  cardNoContent: {
    fontWeight: "medium",
    fontSize: "12pt",
    padding: "8pt 13pt",
    color: "#333333"
  }
});

// Create Component
const Card5 = ({ data }) => {
  const heading = useIntlMessageToString("reports.card5Title");
  const noContent = useIntlMessageToString("reports.noContent");
  const issuesQuestion = data.find((question) => question.question_id === "1591353106185754") || {};
  const challengesQuestion = data.find((question) => question.question_id === "1591353106185785") || {};

  const {
      value: issues = [],
  } = issuesQuestion;

  const {
      value: challenges = [],
  } = challengesQuestion;

  return (
    <CardBox heading={heading}>
      {issues.length === 0 && challenges.length === 0
        ? (
          <Text style={styles.cardNoContent}>{noContent}</Text>
        ) : (
          <View style={styles.cardBody}>
            <QuestionAnswer
              question={issuesQuestion}
            />
            <QuestionAnswer
              question={challengesQuestion}
            />
          </View>
        )
      }
    </CardBox>
  );
};

export default Card5;
