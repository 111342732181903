import * as moment from "moment";

export const getDateNow = (format = "YUMMY h:mm:ss") => {
  return moment().format(format);
};

export const formatDate = (data, format = "YUMMY h:mm:ss") => {
  if (!data) return;
  return moment(data).format(format);
};

export const formatCalendarDate = (data, calendar = {}) => {
  return moment(data).calendar(null, calendar);
};

export const getDateTimestamp = (data) => {
  return new Date(data).getTime();
};
