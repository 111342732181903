import { createElement } from "react";
import ReactDomServer from "react-dom/server";
import reactHtmlParser from "react-html-parser";

export const htmlSvgToPdfSvg = (children) => {
  const svgString = ReactDomServer
    .renderToStaticMarkup(children)
    .replaceAll("px", "pt");

  const [component] = reactHtmlParser(svgString, { transform: convertToPdfSvg });

  return component;
};

function convertToPdfSvg(node, index) {
  if (node.type == "text") {
    return node.data;
  }

  node.props = { key: index };

  Object.entries(node.attribs).forEach(([key, value]) => {
    const [first, ...rest] = key.split("-");

    const newKey = [first, ...rest.map(word => `${word[0].toUpperCase()}${word.slice(1)}`)].join("");
    node.props[newKey] = newKey === "strokeDasharray" && value.indexOf("0pt") > -1 ? 0 : value;
  });

  node.name = node.name?.toUpperCase();

  if (node.name == "CLIPPATH") node.name = "CLIP_PATH";
  if (node.name == "DIV" || node.name == "UL" || node.name == "LI" || node.name === "P") node.name = "VIEW";
  if (node.name == "SPAN") node.name = "TEXT";

  // Rewrite styles
  if (node.name === "VIEW" || node.name === "TEXT") {
    const defStyles = node.props.class?.includes("recharts-legend-item-text")
      ? "marginLeft:4pt;fontSize:8pt"
      : node.props.class?.includes("recharts-legend-item")
        ? "display:flex;flexDirection:row;alignItems:baseline"
        : node.name === "TEXT"
          ? "fontSize:7pt"
          : "";
    
    const styles = (node.props.style ? `${node.props.style};` : "") + defStyles;

    const styleObj = styles
      .split(";")
      .reduce((obj, item) => {
        const [prop, value] = item.split(":");

        if (!prop) return obj;

        return {
          ...obj,
          [prop]: value
        };
      }, {});
    node.props.style = styleObj;
  }
  // react-pdf doesn't read 'dy' attr. Replace 'dy' to 'y'
  if (node.name === "TSPAN" && node.parent.name === "TEXT" && node.props.dy) {
    const prevY = +node.prev?.props?.y;
    const parentY = +node.parent?.props?.y;
    const lastY = prevY || parentY || 0;
    const nodeDy = parseFloat(node.props.dy);
    const nodeY = lastY + nodeDy * 9;

    node.props.y = nodeY;
  }

  // we're removing nested <defs> because they don't work
  if (node.name == "DEFS" && node.parent.name != "SVG") return null;

  if (node.children) {
    node.children = node.children.map(convertToPdfSvg);
  }

  return createElement(node.name, node.props, node.children);
}

export default htmlSvgToPdfSvg;
