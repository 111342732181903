import React, { useState } from "react";
import classnames from "classnames";
// COMPONENTS
import RestorePasswordLightModalForm from "./components/RestorePasswordLightModalForm";
import RestorePasswordSucceedModalForm from "./components/RestorePasswordSucceedModalForm";
// STYLES
import styles from "./styles.module.scss";

const RestorePasswordAuthModalContent = ({ closeModal }) => {
  const [showSucceedModal, setShowSucceedModal] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const showSucceedModalHandler = (userEmail) => {
    setUserEmail(userEmail);
    setShowSucceedModal(true);
  };

  const contentContainerStyles = classnames({
    [styles.RestorePasswordContentContainer]: true,
    [styles.SucceedMessageContainer]: showSucceedModal
  });

  return (
    <div className={contentContainerStyles}>
      {showSucceedModal ? (
        <RestorePasswordSucceedModalForm
          userEmail={userEmail}
        />
      ) : (
        <RestorePasswordLightModalForm
          closeModal={closeModal}
          showSucceedModalHandler={showSucceedModalHandler}
        />
      )}
    </div>
  );
};

export default RestorePasswordAuthModalContent;
