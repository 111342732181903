import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// STYLES
import styles from "../styles.module.scss";


const SignUpFormAccountCodeSwitcher = ({ campaignName }) => {
  const modalToggleHandlers = useToggleCommonModalHandlers();

  const onEnterDiffCodeClick = () => {
    modalToggleHandlers.enterCampaignPasscode({ show: true });
    modalToggleHandlers.signUp({ show: false });
  };

  if (!campaignName) return null;
  return (
    <div className={styles.ModalAccountBlock}>
      <div className={styles.AccountTitle}>
        {campaignName}
      </div>
      <div className={styles.EnterCodeText} onClick={onEnterDiffCodeClick}>
        <IntlMessages id="appModule.enterDifferentCode"/>
      </div>
    </div>
  );
};

export default SignUpFormAccountCodeSwitcher;
