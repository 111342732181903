export const assessorApiTargets = {
  SHARED_ITEMS: "shared-items",
  SHARED_ITEMS_AUDITORS: "shared-items-auditors",
  POLICY_MANAGE: "policy-management",
  COMMON_CREATE: "common-create",
  COMMON_UPDATE: "common-update",
  COMMON_DELETE: "common-delete",
  QUESTION_ANSWERS: "question-answers",
};

export const apiQueryArg = {
  ACCOUNT_ID: "account_id",
  POLICY_ID: "policy_id",

  AUDITOR_IDS: "auditor_ids",

  ROLE_ID: "role_id",
  ROLE_IDS: "role_ids",

  ID: "id",
  IDS: "ids",

  CAMPAIGN_ID: "campaign_id",
  CAMPAIGN_IDS: "campaign_ids",
  APPLIED_BY_PARTICIPANT: "applied_by_participant",
  EXCLUDE_CAMPAIGN_IDS: "exclude_campaign_ids",

  ACT_ID: "act_id",
  ASSESSMENT_ID: "assessment_id",
  ASSESSMENT_IDS: "assessment_ids",

  REGISTRATION_FORM_IDS: "registration_form_ids",
  REG_ID: "reg_id",
  REG_FORM_ID: "reg_form_id",
  REG_VERIFICATION_ACTION: "verification_action",

  HASHTAGS: "hashtags",

  LABEL_IDS: "label_ids",

  PROGRESS: "progress",

  CUSTOM_FIELD_IDS: "custom_field_ids",

  TARGET: "target",

  IS_ARCHIVED: "is_archived",

  PAGE: "page",
  PAGE_SIZE: "page_size",

  SORT_BY: "order_by",
  EXCLUDE_IDS: "exclude_ids",
  IDS_TO_TOP: "ids_to_top",
  SEARCH: "search",
  REGULATOR: "regulator",

  USER_TYPE: "user_type",
  USER_ID: "user_id",

  MEMBER_TYPE: "member_type",
  AUTHORIZED: "authorized",
  STATUS: "status",

  INVITED_BY: "invited_by",
  INVITATION_ID: "invitation_id",

  MANAGEMENT_TYPE: "management_type",

  TYPES: "types",

  EMAIL: "email"
};

export const apiConstants = {
  LABELS_GROUPS_APPLICABILITY: "applicability",
  LABELS_APPLICABILITY: "group_applicability",

  USER_TYPE_AUDITOR: "auditor"
};