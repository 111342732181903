/*
* 1. show all notifications set to "*" or null
* 2. to filter notifications set ["error_code_1", "error_code_2", ...]
* 3. to disable notifications set []
* */
const checkNotificationWhiteList = (notificationWhiteList, errCode) => {
  if (notificationWhiteList === "*" || !notificationWhiteList) {
    return true;
  }
  return !!(notificationWhiteList && notificationWhiteList?.includes(errCode));
};

export default checkNotificationWhiteList;