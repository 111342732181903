import React, { useState, memo, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";
// HELPERS
import { findEmailInAllTemplateQuestions } from "@/utils/helpers/surveyTemplate";
// UTILS
import IntlMessages, { useIntlMessageToString } from "@/utils/IntlMessages";
// HOOKS
import useLogOut from "@/shared/hooks/useLogOut";
// COMPONENTS
import MessageModal from "@/components/Common/CompleteDialog/MessageModal";
// import Survey from "@/components/SurveyJs/components/ViewerV1/SurveyNew";
import SurveyViewerV2 from "@/components/SurveyJs/components/ViewerV2";
import AppHeader from "@/components/AppHeader/PrivateAppHeader";
import CircularProgressCover from "@/shared/UI/Loaders/CircularProgressCover";
import CustomScrollbars from "@/utils/CustomScrollbars";
// STORE
import { addSystemNotification } from "@/store/System/actions";
import {
  createAssessmentAnswersByKey,
} from "@/store/PublicSurvey/actions";
import { getOrganizationTheme } from "@/store/Organization/actions";


const PublicSurveyQuestionsNew = memo((props) => {
  const {
    addSystemNotification,
    createAssessmentAnswersByKey,
    assessmentBody,
    campaignName,
    organizationName,
    email,
    surveyKey,
    campaignId,
    getOrganizationTheme,
    organizationId
  } = props;

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const emailError = useIntlMessageToString("survey.emailIsRequired");

  const history = useHistory();
// TODO: NEED TO BE METHOD
  useLogOut({
    includeAdminToken: true,
    reloadPage: false
  });

  useEffect(() => {
    if (organizationId) {
      getOrganizationTheme(organizationId);
    }
  }, [getOrganizationTheme, organizationId]);

  const setShowComplete = (mode) => {
    setShowCompleteModal(mode);
    if (!mode && campaignId) {
      history.push(`/campaign/${campaignId}/assessment/`);
    }
  };

  //TODO: fix hardcode ID
  const getEmailFromAnswer = (data, id = "1591151594106496") => {
    const answerObj = data.filter((item) => item.question_id === id);
    return answerObj[0]?.answer;
  };

  const sendData = async ({ data, isCompleted, survey }) => {
    const dataSend = {
      email,
      answer: data,
    };
    const emailFromQuestions = findEmailInAllTemplateQuestions(survey.getAllQuestions());
    if (!email) {
      dataSend.email = getEmailFromAnswer(data) || emailFromQuestions;
    }

    try {
      if (isCompleted) {
        if (!dataSend.email) {
          addSystemNotification("", emailError, "error");
          return;
        }
        setLoading(true);

        await createAssessmentAnswersByKey(dataSend, surveyKey);
        setShowComplete(true);
      }
    } catch (e) {
      const error = get(
        e,
        "payload.response.data.errors.details",
        "An error has occurred"
      );
      addSystemNotification("", error, "error");
    } finally {
      setLoading(false);
    }
  };


  const contentClass = classNames({
    "app-main-content-wrapper": true,
    "app-content__client-theme": true,
  });

  if (!assessmentBody) return null;
  return (
    <div className="app-main-container">
      <AppHeader campaignName={campaignName} publicSurveyHeader={true}/>
      <CustomScrollbars
        className="app-custom-scrollbar app-main-content-scrollbar-wrapper"
      >
        <main className={contentClass}>
          <div className="app-main-content">
            <div className="app-wrapper public-survey__container">
              <div className='assessment-survey__wrapper asr-assessment-survey'>
                <SurveyViewerV2
                  callbacks={{
                    onSubmit: sendData
                  }}
                  publicSurveyEmail={email}
                  templateBody={assessmentBody}
                />
                {/*<Survey*/}
                {/*  callback={sendData}*/}
                {/*  publicSurveyEmail={email}*/}
                {/*  assessmentBody={assessmentBody}*/}
                {/*  assessmentAnswers={null}*/}
                {/*/>*/}
                {showCompleteModal && (
                  <MessageModal
                    show={showCompleteModal}
                    setShow={setShowComplete}
                  >
                    <IntlMessages id='competency.survey1SuccessMessage1'/>
                    <span className='message-highlighted'> {campaignName} </span>
                    <IntlMessages id='competency.survey1SuccessMessage2'/>
                    <span className='message-highlighted'> {organizationName}. </span>
                    <IntlMessages id='competency.survey1SuccessMessage3'/>
                  </MessageModal>
                )}
                <CircularProgressCover open={loading}/>
              </div>
            </div>
          </div>
        </main>
      </CustomScrollbars>
    </div>
  );
});

PublicSurveyQuestionsNew.propTypes = {
  addSystemNotification: PropTypes.func.isRequired,
  createAssessmentAnswersByKey: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addSystemNotification,
  createAssessmentAnswersByKey,
  getOrganizationTheme
};

export default connect(null, mapDispatchToProps)(PublicSurveyQuestionsNew);