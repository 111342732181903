import enMessages from "../locales/fr_CA.json";
import appCommon from "../modules/appCommon/fr.json";
import breadcrumbs from "../modules/breadcrumbs/breadcrumbs_FR.json";
import accounts from "../modules/accounts/fr.json";
import accountMembers from "../modules/accountMembers/fr.json";
import policies from "../modules/policies/fr.json";
import campaign from "../modules/campaign/fr.json";
import notes from "../modules/notes/fr.json";
import landing from "../modules/landing/fr.json";
import registrations from "../modules/registrations/fr.json";
import appModule from "../modules/appModule/fr.json";
import auth from "../modules/auth/fr.json";
import user from "../modules/user/fr.json";

const FrLang = {
  messages: {
    ...appCommon,
    ...enMessages,
    ...breadcrumbs,
    ...accounts,
    ...accountMembers,
    ...policies,
    ...campaign,
    ...notes,
    ...landing,
    ...registrations,
    ...appModule,
    ...auth,
    ...user
  },
  locale: "fr-CA",
};
export default FrLang;
