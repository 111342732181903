import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, LabelList
} from "recharts";
import CardBox from "./CardBox";
import IntlMessages from "../../../../utils/IntlMessages";
import QuestionNoteAndStatus from "../NoteAndStatus/NoteAndStatus";

const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
        <g>
            <text x={x + width - 20} y={y + 15} fill='#fff' textAnchor='middle' dominantBaseline='middle'>
                {value}%
            </text>
        </g>
    );
};

const Card6 = React.forwardRef(function Card6({ data, exportImage, windowWidth, keyForSavingCardState }, ref) {
    const isLargeWindow = windowWidth >= 1100;

    return (
        <CardBox
            heading={<IntlMessages id='reports.card6Title'/>}
            exportImage={exportImage}
            ref={ref}
            keyForSavingCardState={keyForSavingCardState}
        >
            {data.every((question) => question.value === null)
                ? (
                    <div className='report-card__no-content'><IntlMessages id='reports.noContent'/></div>
                ) : (
                    <div className='report-card__body__container'>
                        {data.map((item) => {
                            const { note, status } = item;

                            return (
                                <React.Fragment key={item.question_id}>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <QuestionNoteAndStatus
                                            status={status}
                                            note={note}
                                            question={item}
                                        />
                                    </div>
                                    {!isLargeWindow && (
                                        <div>{item.title}</div>
                                    )}
                                    <ResponsiveContainer width='100%' height={100}>
                                        <BarChart layout='vertical' data={[item]}>
                                            <CartesianGrid horizontal={false}/>
                                            <XAxis
                                                type='number'
                                                domain={[0, 100]}
                                                ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                                hide
                                            />
                                            <YAxis
                                                dataKey='title'
                                                type='category'
                                                width={isLargeWindow ? 240 : 20}
                                                tickLine={false}
                                                axisLine={false}
                                                tick={isLargeWindow}
                                            />
                                            <Bar dataKey='value' barSize={30} fill='#4ea684'>
                                                <LabelList dataKey='value' content={renderCustomizedLabel}/>
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
        </CardBox>
    );
});

Card6.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    exportImage: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    keyForSavingCardState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    windowWidth: state.settings.windowWidth,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Card6);
