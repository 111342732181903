import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, LabelList } from "recharts";
import CardBox from "./CardBox";
import IntlMessages from "../../../../utils/IntlMessages";
import QuestionNoteAndStatus from "../NoteAndStatus/NoteAndStatus";

const Card7 = React.forwardRef(function Card7({ data, exportImage, windowWidth, keyForSavingCardState }, ref) {
    const reasonsQuestion = data?.find((question) => question.question_id === "1591353106185851") || {};

    const { value: reasons = [], note: note, status: status } = reasonsQuestion;
    const isLargeWindow = windowWidth >= 1100;

    return (
        <CardBox
            heading={<IntlMessages id='reports.card7Title'/>}
            exportImage={exportImage}
            ref={ref}
            keyForSavingCardState={keyForSavingCardState}
        >
            {reasons?.length > 0
                ? (
                    <div className='report-card__body__container'>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <QuestionNoteAndStatus
                                status={status}
                                note={note}
                                question={reasonsQuestion}
                            />
                        </div>
                        {isLargeWindow ? (
                            <ResponsiveContainer width='100%' height={500}>
                                <BarChart data={reasons}>
                                    <XAxis
                                        height={200}
                                        axisLine={false}
                                        tick={false}
                                    />
                                    <YAxis
                                        type='number'
                                        domain={[0, 5]}
                                        ticks={[0, 1, 2, 3, 4, 5]}
                                        axisLine={false}
                                        tickLine={false}
                                    />
                                    <CartesianGrid
                                        stroke='#f5f5f5'
                                        vertical={false}
                                    />
                                    <Bar
                                        dataKey='value'
                                        fill='#4472c4'
                                        barSize={20}
                                    >
                                        <LabelList
                                            dataKey='title'
                                            position='bottom'
                                            offset={10}
                                        />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        ) : (
                            <ResponsiveContainer width='100%' height={800}>
                                <BarChart
                                    layout='vertical'
                                    data={reasons}
                                >
                                    <YAxis
                                        width={200}
                                        dataKey='title'
                                        type='category'
                                    />
                                    <XAxis
                                        type='number'
                                        domain={[0, 5]}
                                        ticks={[0, 1, 2, 3, 4, 5]}
                                        axisLine={false}
                                        tickLine={false}
                                    />
                                    <CartesianGrid
                                        stroke='#f5f5f5'
                                        vertical={false}
                                    />
                                    <Bar
                                        dataKey='value'
                                        fill='#4472c4'
                                        barSize={20}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                ) : (
                    <div className='report-card__no-content'><IntlMessages id='reports.noContent'/></div>
                )}
        </CardBox>
    );
});

Card7.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    exportImage: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    keyForSavingCardState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    windowWidth: state.settings.windowWidth,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Card7);