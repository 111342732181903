import * as Yup from "yup";
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";

const validationSchema = (locale) => {
  const required = getIntlMessageToStringLocal(locale, "appModule.required");
  return Yup.object().shape({
    "text": Yup
        .string()
        .trim()
        .required(required)
  });
};

export default validationSchema;