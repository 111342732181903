import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
// COMPONENTS
import EvolutionaryContentStage1 from "./components/EvolutionaryContentStage1";
import EvolutionaryContentStage2 from "./components/EvolutionaryContentStage2";
import EvolutionaryContentStage3 from "./components/EvolutionaryContentStage3";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
// STORE
import { useUserSendVerificationEmailAuthMutation } from "@/store/services/users/rtk";
// STYLES
import styles from "./styles.module.scss";
import { useIntlMessageToString } from "@/utils/IntlMessages";
import useErrors from "@/shared/hooks/errors/useErrors";
import { addSystemNotification } from "@/store/System/actions";

const EvolutionaryContent = ({
                               initialStage = 1,
                               maxStage = 3,
                               periodBetweenStages,
                               userEmail,
                               userPassword
                             }) => {
  const dispatch = useDispatch();
  const [currentStage, setCurrentStage] = useState(initialStage || 1);
  const [isLoading, setIsLoading] = useState(false);

  const [userSendVerificationEmailAuth] = useUserSendVerificationEmailAuthMutation();

  const { handleFetchErrors } = useErrors();

  const successMessageSentText = useIntlMessageToString("contactUs.success");
  const somethingWentWrongText = useIntlMessageToString("appModule.somethingWentWrong");

  useEffect(() => {
    if (currentStage >= maxStage) return;
    if (!periodBetweenStages) return;

    const stageTimeout = setTimeout(() => {
      setCurrentStage(currentStage + 1);
    }, periodBetweenStages);

    return () => {
      if (!stageTimeout) return;
      clearTimeout(stageTimeout);
    };
  }, [currentStage]);

  const onResendConfirmationClick = async () => {
    if (!userEmail || !userPassword) return;
    setIsLoading(true);

    try {
      const response = await userSendVerificationEmailAuth({
        email: userEmail,
        password: userPassword
      }).unwrap();
      if (response?.is_processed) {
        dispatch(addSystemNotification("", successMessageSentText, "success"));
      } else {
        dispatch(addSystemNotification("", somethingWentWrongText, "error"));
      }
      setCurrentStage(1);
    } catch (error) {
      handleFetchErrors(error.data);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.EvolutionaryContentInfoBlock}>
      {currentStage === 1 && (
        <EvolutionaryContentStage1/>
      )}

      {currentStage === 2 && (
        <EvolutionaryContentStage2 onResendConfirmationClick={onResendConfirmationClick}/>
      )}

      {currentStage === 3 && (
        <EvolutionaryContentStage3/>
      )}

      {isLoading && (
        <AsrCommonLoader/>
      )}
    </div>
  );
};

EvolutionaryContent.propTypes = {
  initialStage: PropTypes.oneOf([1, 2, 3]),
  maxStage: PropTypes.oneOf([1, 2, 3]),
  periodBetweenStages: PropTypes.number,
  userEmail: PropTypes.string,
  userPassword: PropTypes.string,
};
export default EvolutionaryContent;
