import React, { memo } from "react";
// MATERIAL UI
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// ICONS
import icons from "@/components/SurveyJs/icons/customIcons";
// STYLES
import commonStyles from "../../../../../common.module.scss";
import styles from "./styles.module.scss";

const NoteButton = memo(({ handler, noteImg, noteTooltip }) => {

  return (
    <Tooltip
        title={noteTooltip}
        arrow
        classes={{
          popper: commonStyles.verifiedButtonTooltipPopper,
          tooltip: commonStyles.verifiedButtonTooltip,
          arrow: commonStyles.verifiedButtonArrow
        }}
        placement="top">
      <IconButton
        classes={{
          root: `${styles.noteButtonRoot} ${styles[noteImg]}`,
          label: styles.noteButtonLabel,
        }}
        onClick={handler}
        aria-label="delete"
        size="large">
        {icons[noteImg]}
      </IconButton>
    </Tooltip>
  );
});

export default NoteButton;