import { requestActionCreator } from "../../../utils/requestActionCreator";
import * as types from "../types";
import { baseApiUrl } from "../../../configs/MainConfig";
import { authService } from "../../../shared/services/auth.service";
import querystring from "query-string";

export const createCampaignAssessmentAnswer = (member, module_item, answers = {}) => requestActionCreator(
  types.CREATE_CAMPAIGN_ASSESSMENT_ANSWER,
  `${baseApiUrl}/campaign/assessment-module/survey/answers/`,
  "POST",
  { ...authService.getAuthHeaders() },
  { member, module_item, answers }
);

export const fetchCampaignAssessmentAnswerExtendedInfo = (item_id, member_id) => requestActionCreator(
  types.FETCH_CAMPAIGN_ASSESSMENT_ANSWER_EXTENDED_INFO,
  `${baseApiUrl}/campaign/assessment-module/survey/answers/extended/?item_id=${item_id}&member_id=${member_id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const updateCampaignAssessmentAnswer = (id, answers) => requestActionCreator(
  types.UPDATE_CAMPAIGN_ASSESSMENT_ANSWER,
  `${baseApiUrl}/campaign/assessment-module/survey/answers/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  { answers }
);


//new actions
export const fetchCampaignAssessments = (campaignIds = "", page = 1, page_size = 10, template_filled) => {
  let url = `${baseApiUrl}/campaign/assessment/list/?campaign_ids=${campaignIds}&page=${page}&page_size=${page_size}&is_archived=false&sort_by=order_in_list`;

  if (template_filled) {
    url += `&template_filled=${template_filled}`;
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_ASSESSMENTS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchCampaignAssessmentsCards = (payload = {}) => (dispatch, getState) => {
  const currentState = getState();
  const pagination = currentState.campaign.assessment.pagination;
  const sort = currentState.campaign.assessment.sortBy;
  const filters = currentState.campaign.assessment.filters;

  const payloadQuery = {
    ...sort,
    ...pagination,
    ...filters,
    is_archived: false
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/assessment/list/card/?${query}`;


  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_ASSESSMENTS_CARDS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchCampaignAssessmentsTable = (payload = {}) => (dispatch, getState) => {
  const currentState = getState();
  const pagination = currentState.campaign.assessment.pagination;
  const sort = currentState.campaign.assessment.sortBy;
  const filters = currentState.campaign.assessment.filters;

  const payloadQuery = {
    ...sort,
    ...pagination,
    ...filters,
    is_archived: false
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/assessment/list/table/?${query}`;


  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_ASSESSMENTS_TABLE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchCampaignAssessmentsMinimalistic = (payload = {}) => (dispatch) => {
  const payloadQuery = {};

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/assessment/list/minimalistic/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_ASSESSMENTS_MINIMALISTIC,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setCampaignsAssessmentPagination = (payload) => ({
  type: types.SET_CAMPAIGNS_ASSESSMENTS_PAGINATION,
  payload
});

export const setCampaignAssessmentTableSize = (payload) => ({
  type: types.SET_CAMPAIGN_ASSESSMENT_TABLE_SIZE,
  payload
});

export const setCampaignAssessmentFilters = (payload) => ({
  type: types.SET_CAMPAIGN_ASSESSMENT_FILTERS,
  payload
});

export const setCampaignAssessmentPageViewType = (payload) => ({
  type: types.SET_CAMPAIGN_ASSESSMENT_PAGE_VIEW_TYPE,
  payload
});

export const setCampaignAssessmentSortOrder = (payload) => ({
  type: types.SET_CAMPAIGN_ASSESSMENT_SORT_ORDER,
  payload
});

export const resetCampaignAssessmentState = () => ({
  type: types.RESET_CAMPAIGN_ASSESSMENT_STATE
});

export const fetchAssessmentExtendedInfoById = (id) => requestActionCreator(
  types.FETCH_ASSESSMENT_EXTENDED_INFO_BY_ID,
  `${baseApiUrl}/campaign/assessment/extended/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);


export const createAssessmentAnswerByAssessor = (data) => requestActionCreator(
  types.CREATE_ASSESSMENT_ANSWER_BY_ASSESSOR,
  `${baseApiUrl}/campaign/assessment/answer/assessor/`,
  "POST",
  { ...authService.getAuthHeaders() },
  data,
);

export const updateAssessmentAnswerByAssessor = (data, assessmentAnswerId) => requestActionCreator(
  types.UPDATE_ASSESSMENT_ANSWER_BY_ASSESSOR,
  `${baseApiUrl}/campaign/assessment/answer/assessor/?id=${assessmentAnswerId}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  data,
);

export const cleanAssessmentMultiLangInfo = () => ({
  type: types.CLEAN_ASSESSMENT_MULTI_LANG_INFO,
});

// TODO: REMOVE
export const createAssessmentInfo = (assessmentModuleItemData) => {
  const formData = new FormData();
  Object.keys(assessmentModuleItemData)
    .forEach(key => formData.append(key, assessmentModuleItemData[key]));
  return requestActionCreator(
    types.UPDATE_ASSESSMENT_INFO,
    `${baseApiUrl}/campaign/assessment/`,
    "POST",
    { ...authService.getAuthHeaders() },
    formData,
  );
};
// TODO: need to be transferred to the Survey
export const createAssessmentAnswer = (dataSend) => requestActionCreator(
  types.CREATE_ASSESSMENT_ANSWER,
  `${baseApiUrl}/campaign/assessment/answer/general/manage/`,
  "POST",
  { ...authService.getAuthHeaders() },
  dataSend,
);

export const updateAssessmentAnswerSingular = (dataSend) => requestActionCreator(
  types.UPDATE_ASSESSMENT_ANSWER,
  `${baseApiUrl}/campaign/assessment/answer/singular/manage/`,
  "POST",
  { ...authService.getAuthHeaders() },
  dataSend,
);

export const updateAssessmentAnswerGeneral = (dataSend, id) => requestActionCreator(
  types.UPDATE_ASSESSMENT_ANSWER,
  `${baseApiUrl}/campaign/assessment/answer/general/manage/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  dataSend,
);
// TODO: REMOVE  OLD
export const updateAssessmentAnswer = (dataSend, answersId) => requestActionCreator(
  types.UPDATE_ASSESSMENT_ANSWER,
  `${baseApiUrl}/campaign/assessment/answer/?id=${answersId}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  dataSend,
);
// TODO:  ===============================================

export const fetchAssessmentAnswerExtendedInfo = (assessmentId, memberId) => requestActionCreator(
  types.FETCH_ASSESSMENT_ANSWER_EXTENDED_INFO,
  `${baseApiUrl}/campaign/assessment/answer/extended/?assessment_id=${assessmentId}&user_id=${memberId}`,
  "GET",
  { ...authService.getAuthHeaders() },
);

export const assessmentMakeUncompleted = (body) => {
  return requestActionCreator(
    types.ASSESSMENT_MAKE_UNCOMPLETED,
    `${baseApiUrl}/campaign/assessment/answer/make-uncompleted/`,
    "POST",
    { ...authService.getAuthHeaders() },
    body
  );
};

export const assessmentChangeCompletionStatus  = (body) => {
  return requestActionCreator(
    types.ASSESSMENT_CHANGE_COMPLETION_STATUS,
    `${baseApiUrl}/campaign/assessment/answer/change-completion-status/`,
    "POST",
    { ...authService.getAuthHeaders() },
    body
  );
};

export const assessmentMakeUncompletedForAssessor = (body) => {
  return requestActionCreator(
    types.ASSESSMENT_MAKE_UNCOMPLETED_FOR_ASSESSOR,
    `${baseApiUrl}/campaign/assessment/answer/assessor/answer/make-uncompleted/`,
    "POST",
    { ...authService.getAuthHeaders() },
    body
  );
};

// MODALS
export const toggleCampaignAssessmentsModal = (payload) => ({
  type: types.TOGGLE_CAMPAIGN_ASSESSMENTS_MODAL,
  payload
});
export const setCampaignAssessmentsPageBarAddButtonVisible = (payload) => ({
  type: types.SET_CAMPAIGN_ASSESSMENTS_PAGE_BAR_ADD_BUTTON_VISIBLE,
  payload
});
