import * as types from "./types";
import { baseApiUrl } from "@/configs/MainConfig";
import { requestActionCreator } from "@/utils/requestActionCreator";
import { authService } from "@/shared/services/auth.service";

export const checkTokenOrganization = (token) => {
  return requestActionCreator(
      types.CHECK_TOKEN_ORGANIZATION,
      `${baseApiUrl}/organization/members/invitation/accept-by-token/?token=${token}`,
      "GET",
      { ...authService.getAuthHeaders() },
  );
};

export const joinOrganization = (data) => requestActionCreator(
    types.JOIN_ORGANIZATION,
    `${baseApiUrl}/organization/members/invitation/accept-by-token/`,
    "POST",
    { ...authService.getAuthHeaders() },
    data,
);

export const organizationAcceptByToken = (token) => requestActionCreator(
    types.ORGANIZATION_ACCEPT_BY_TOKEN,
    `${baseApiUrl}/organization/members/invitation/accept-by-token/?token=${token}`,
    "GET",
    { ...authService.getAuthHeaders() }
);

export const checkTokenCampaign = (token) => requestActionCreator(
    types.CHECK_TOKEN_CAMPAIGN,
    `${baseApiUrl}/campaign/members/invitation/accept-by-token/?token=${token}`,
    "GET",
    { ...authService.getAuthHeaders() },
);

export const joinCampaign = (data) => requestActionCreator(
    types.JOIN_CAMPAIGN,
    `${baseApiUrl}/campaign/members/invitation/accept-by-token/`,
    "PATCH",
    { ...authService.getAuthHeaders() },
    data,
);

export const joinCampaignBase = (data) => requestActionCreator(
    types.JOIN_CAMPAIGN_BASE,
    `${baseApiUrl}/user/register-by-passcode/campaign/check-passcode/`,
    "POST",
    { ...authService.getAuthHeaders() },
    data,
);
