import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
// MUI
import Icon from "@mui/material/Icon";
// COMPONENTS
import AsrLangIcon from "@/shared/UI/Icons/AsrLangIcon";
import LanguageSwitcherSystem from "./components/LanguageSwitcherSystem";
// STORE
import { switchLanguage } from "@/store/Settings/actions";
import { getLocale } from "@/store/Settings/selectors";
// STYLES
import styles from "../../styles.module.scss";
import useStyles from "@/components/AppHeader/components/LanguageSelector/hooks/useStyles";

const LanguageSelectorSystem = ({
                                  onItemCLickHandler,
                                  classes
                                }) => {
  const dispatch = useDispatch();
  const [langSwitcher, setLangSwitcher] = useState(false);
  const locale = useSelector(getLocale);

  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
  };

  const onSwitchLanguage = (lang) => {
    localStorage.setItem("locale", lang.locale);
    dispatch(switchLanguage(lang));
    onItemCLickHandler && onItemCLickHandler();
  };

  const hookClasses = useStyles({ classes });

  return (
    <div className={hookClasses.root}>
      <Dropdown
        className={hookClasses.dropdown}
        isOpen={langSwitcher}
        toggle={onLangSwitcherSelect}
      >
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          className={hookClasses.dropdownToggle}
        >
          <span className={hookClasses.label}>
            {locale.name}
          </span>
          <Icon className={hookClasses.icon}>
            <AsrLangIcon classes={{
              circle: "app-header__language-icon_st0",
              fillPath: "app-header__language-icon_st1"
            }}/>
          </Icon>
        </DropdownToggle>

        <DropdownMenu className={styles.LanguagesDropdownMenu}>
          <LanguageSwitcherSystem
            switchLanguage={onSwitchLanguage}
            handleRequestClose={handleRequestClose}
            currentLocale={locale?.locale}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default LanguageSelectorSystem;
