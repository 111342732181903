import React from "react";
import classNames from "classnames";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import AntSwitch from "src/shared/components/FormComponents/Switch/AntSwitch";
// STYLES
import styles from "./styles.module.scss";


const ImageTypeSwitcher = ({
                             checked,
                             onChange,
                             setIsImageFromPc,
                             onLabel = "appCommon.link",
                             offLabel = "appCommon.upload",
                           }) => {

  const uploadLabelClass = classNames({
    [styles.ImageTypeSwitcherLabel]: true,
    [styles.ImageTypeSwitcherLabelOff]: true,
    [styles.ImageTypeSwitcherLabelInactive]: checked
  });

  const linkLabelClass = classNames({
    [styles.ImageTypeSwitcherLabel]: true,
    [styles.ImageTypeSwitcherLabelInactive]: !checked
  });

  const handleCheckUpload = () => {
    setIsImageFromPc(true);
  };

  const handleCheckLink = () => {
    setIsImageFromPc(false);
  };

  return (
    <div className={styles.ImageTypeSwitcher}>
      <div onClick={handleCheckUpload} className={uploadLabelClass}>
        <IntlMessages id={offLabel}/>
      </div>

      <AntSwitch
        styleOptions={{
          trackBgColor: "var(--asr-a-blue-2)"
        }}
        checked={checked}
        onChange={onChange}
      />

      <div onClick={handleCheckLink} className={linkLabelClass}>
        <IntlMessages id={onLabel}/>
      </div>
    </div>
  );
};

export default ImageTypeSwitcher;