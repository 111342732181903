import { imageSourceType } from "@/shared/constants/forms";

const useGenerateInitialData = ({ imageData }) => {

  let initialData = {
    image: "",
    imageContent: {
      url: "",
      source: imageSourceType.UPLOADED_FILE
    }
  };

  let imageUrl = imageData?.url ?? "";
  if (imageUrl) {
    initialData = {
      ...initialData,
      image: imageUrl,
      imageContent: {
        ...initialData.imageContent,
        url: imageUrl,
        source: imageData?.source ?? imageSourceType.UPLOADED_FILE
      }
    };
  }

  return initialData;
};

export default useGenerateInitialData;
