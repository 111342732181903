import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";

const initialState = {
  assignedUsersSortBy: {
    sort_by: ""
  },
  availableUsersSortBy: {
    sort_by: ""
  },
  assignedCampaignsSortBy: {
    sort_by: ""
  },
  availableCampaignsSortBy: {
    sort_by: ""
  },
  autocompleteMember: null,
  assignedUsersSearch: "",
  availableUsersSearch: "",
  assignedCampaignsSearch: "",
  availableCampaignsSearch: "",
  assignedUsers: {
    results: [],
    count: 0,
    previous: null,
    next: null,
    page: 1,
    page_size: 10,
  },
  availableUsers: {
    results: [],
    count: 0,
    previous: null,
    next: null,
    page: 1,
    page_size: 10,
  },
  assignedUsersAddedList: [],
  assignedUsersCheckedList: [],
  availableUsersAddedList: [],
  availableUsersCheckedList: [],
  assignedCampaigns: {
    results: [],
    count: 0,
    previous: null,
    next: null,
    page: 1,
    page_size: 10,
  },
  availableCampaigns: {
    results: [],
    count: 0,
    previous: null,
    next: null,
    page: 1,
    page_size: 10,
  },
};
 
export const counterSlice = createSlice({
  name: "accountMembersAssignments",
  initialState,
  reducers: {
    cleanAssignmentsState: () => {
      return initialState;
    },
    cleanAssignedUsers: (state) => {
      state.assignedUsersSearch = "";
      state.availableUsersSearch = "";
      state.assignedUsers = initialState.assignedUsers;
      state.availableUsers = initialState.availableUsers;
      state.assignedUsersAddedList = [];
      state.assignedUsersCheckedList = [];
      state.availableUsersAddedList = [];
      state.availableUsersCheckedList = [];
    },
    cleanAssignedCampaigns: (state) => {
      state.assignedCampaignsSearch = "";
      state.availableCampaignsSearch = "";
      state.assignedCampaigns = initialState.assignedCampaigns;
      state.availableCampaigns = initialState.availableCampaigns;
    },
    setAccountAssignmentsAssignedUsersSort: (state, action) => {
      state.assignedUsersSortBy = action.payload;
    },
    setAccountAssignmentsAvailableUsersSort: (state, action) => {
      state.availableUsersSortBy = action.payload;
    },
    setAccountAssignmentsAssignedCampaignsSort: (state, action) => {
      state.assignedCampaignsSortBy = action.payload;
    },
    setAccountAssignmentsAvailableCampaignsSort: (state, action) => {
      state.availableCampaignsSortBy = action.payload;
    },
    setAssignedUsersSearch: (state, action) => {
      state.assignedUsersSearch = action.payload;
    },
    setAvailableUsersSearch: (state, action) => {
      state.availableUsersSearch = action.payload;
    },
    setAssignedCampaignsSearch: (state, action) => {
      state.assignedCampaignsSearch = action.payload;
    },
    setAvailableCampaignsSearch: (state, action) => {
      state.availableCampaignsSearch = action.payload;
    },
    setAccountAssignmentsAutocompleteMember: (state, action) => {
      state.autocompleteMember = action.payload;
    },
    setAssignedUsers: (state, action) => {
      const { data, arg = {} } = action.payload;
      const { page } = arg;
      const { results, ...rest } = data;

      if (page && page !== 1) {
        state.assignedUsers = {
          results: uniqBy(state.assignedUsers.results.concat(results), "id"),
          ...rest
        };
      } else {
        state.assignedUsers = data;
      }
    },
    setAvailableUsers: (state, action) => {
      const { data, arg = {} } = action.payload;
      const { page } = arg;
      const { results, ...rest } = data;

      if (page && page !== 1) {
        state.availableUsers = {
          results: uniqBy(state.availableUsers.results.concat(results), "id"),
          ...rest
        };
      } else {
        state.availableUsers = data;
      }
    },
    setAssignedUsersCheckedList: (state, action) => {
      state.assignedUsersCheckedList = action.payload;
    },
    setAvailableUsersCheckedList: (state, action) => {
      state.availableUsersCheckedList = action.payload;
    },
    setAssignedCampaigns: (state, action) => {
      const { data, arg = {} } = action.payload;
      const { page } = arg;
      const { results, ...rest } = data;

      if (page && page !== 1) {
        state.assignedCampaigns = {
          results: uniqBy(state.assignedCampaigns.results.concat(results), "id"),
          ...rest
        };
      } else {
        state.assignedCampaigns = data;
      }
    },
    setAvailableCampaigns: (state, action) => {
      const { data, arg = {} } = action.payload;
      const { page } = arg;
      const { results, ...rest } = data;

      if (page && page !== 1) {
        state.availableCampaigns = {
          results: uniqBy(state.availableCampaigns.results.concat(results), "id"),
          ...rest
        };
      } else {
        state.availableCampaigns = data;
      }
    },
    addAssignedUsers: (state, action) => {
      const {
        assignedUsers,
        availableUsers,
        assignedUsersAddedList,
        availableUsersAddedList,
      } = state;
      const { users } = action.payload;

      const availableUsersCheckedList = availableUsers.results.filter(item => users.includes(item.id));

      const newAssignedUsersList = [...assignedUsers.results, ...availableUsersCheckedList];
      const newAssignedUsersAddedList = availableUsersCheckedList.filter(item => !availableUsersAddedList.some(addedEl => addedEl.id === item.id));

      const newAvailableUsersList = availableUsers.results.filter(element => availableUsersCheckedList.indexOf(element) < 0);
      const newAvailableUsersAddedList = availableUsersAddedList.filter(item => !availableUsersCheckedList.some(movedEl => movedEl.id === item.id));

      state.assignedUsers.results = newAssignedUsersList;
      state.assignedUsers.count = newAssignedUsersList.length;
      state.assignedUsersAddedList = [...assignedUsersAddedList, ...newAssignedUsersAddedList];
      state.assignedUsersCheckedList = [];

      state.availableUsers.results = newAvailableUsersList;
      state.availableUsers.count = newAvailableUsersList.length;
      state.availableUsersAddedList = newAvailableUsersAddedList;
      state.availableUsersCheckedList = [];
    },
    removeAssignedUsers: (state, action) => {
      const {
        assignedUsers,
        availableUsers,
        assignedUsersAddedList,
        availableUsersAddedList,
      } = state;
      const { users } = action.payload;

      const assignedUsersCheckedList = assignedUsers.results.filter(item => users.includes(item.id));

      const newAvailableUsersList = [...availableUsers.results, ...assignedUsersCheckedList];
      const newAvailableUsersAddedList = assignedUsersCheckedList.filter(item => !assignedUsersAddedList.some(addedEl => addedEl.id === item.id));

      const newAssignedUsersList = assignedUsers.results.filter(element => assignedUsersCheckedList.indexOf(element) < 0);
      const newAssignedUsersAddedList = assignedUsersAddedList.filter(item => !assignedUsersCheckedList.some(movedEl => movedEl.id === item.id));

      state.assignedUsers.results = newAssignedUsersList;
      state.assignedUsers.count = newAssignedUsersList.length;
      state.assignedUsersAddedList = newAssignedUsersAddedList;
      state.assignedUsersCheckedList = [];

      state.availableUsers.results = newAvailableUsersList;
      state.availableUsers.count = newAvailableUsersList.length;
      state.availableUsersAddedList = [...availableUsersAddedList, ...newAvailableUsersAddedList];
      state.availableUsersCheckedList = [];
    },
    addAssignedCampaigns: (state, action) => {
      const { assignedCampaigns, availableCampaigns } = state;
      const { users, campaigns } = action.payload;

      const campaignsToAdd = availableCampaigns.results.filter(({ id }) => campaigns.includes(id));
      const newAssignedCampaigns = assignedCampaigns.results.map(item => {
        if (!users.includes(item.id)) {
          return item;
        }
         return {
          ...item,
          relations: {
            campaigns: {
              ...item?.relations?.campaigns,
              list: uniqBy([...item.relations.campaigns.list, ...campaignsToAdd], "id"),
            }
          }
         };
      });

      state.assignedCampaigns.results = newAssignedCampaigns;
    },
    removeAssignedCampaigns: (state, action) => {
      const { users, campaigns } = action.payload;

      const getNewList = (oldList) => {
        if (!campaigns.length) {
          return [];
        }
        return oldList.filter(item => !campaigns.includes(item.id));
      };

      state.assignedCampaigns.results = state.assignedCampaigns.results.map(item => {
        if (!users.includes(item.id)) {
          return item;
        }
         return {
          ...item,
          relations: {
            campaigns: {
              ...item?.relations?.campaigns,
              list: getNewList(item?.relations?.campaigns?.list),
            }
          }
         };
      });
    },
  },
});


export const {
  cleanAssignmentsState,
  cleanAssignedUsers,
  cleanAssignedCampaigns,
  setAccountAssignmentsAssignedUsersSort, 
  setAccountAssignmentsAvailableUsersSort,
  setAccountAssignmentsAssignedCampaignsSort,
  setAccountAssignmentsAvailableCampaignsSort,
  setAccountAssignmentsAutocompleteMember,
  setAssignedUsersSearch,
  setAvailableUsersSearch,
  setAssignedCampaignsSearch,
  setAvailableCampaignsSearch,
  setAssignedUsers,
  setAvailableUsers,
  setAssignedUsersCheckedList,
  setAvailableUsersCheckedList,
  setAssignedCampaigns,
  setAvailableCampaigns,
  addAssignedUsers,
  removeAssignedUsers,
  addAssignedCampaigns,
  removeAssignedCampaigns,
} = counterSlice.actions;

export default counterSlice.reducer;