import { actionsConstants } from "../../constants/actionsConstants";
import campaignsValidator from "../validatorsHandlers/campaignsValidator";
import allOwnedCampaignsValidator from "../validatorsHandlers/allOwnedCampaignsValidator";

const campaignParticipant = {
  [actionsConstants.CampaignParticipantListView]: campaignsValidator,
  [actionsConstants.CampaignParticipantCreate]: campaignsValidator,
  [actionsConstants.CampaignParticipantModify]: allOwnedCampaignsValidator,
  [actionsConstants.CampaignParticipantDelete]: allOwnedCampaignsValidator,
  [actionsConstants.CampaignParticipantDownloadReportXLSX]: allOwnedCampaignsValidator,
  [actionsConstants.CampaignParticipantDownloadReportPDF]: allOwnedCampaignsValidator,
  [actionsConstants.CampaignParticipantViewChartsReport]: allOwnedCampaignsValidator,
  [actionsConstants.CampaignParticipantAuditorAssignment]: campaignsValidator,
};


export default campaignParticipant;