import { baseUrl, baseApiUrl } from "@/configs/MainConfig";
import * as types from "./types";
import { requestActionCreator } from "@/utils/requestActionCreator";
import { authService } from "@/shared/services/auth.service";

export const signInUserBasic = (credentials) => requestActionCreator(
  types.SIGN_IN_USER_BASIC,
  `${baseUrl}/user/create-jwt-token/`,
  "POST",
  { ...authService.getAuthHeaders() },
  JSON.stringify(credentials),
);

export const refreshUserToken = () => requestActionCreator(
  types.REFRESH_USER_TOKEN,
  `${baseUrl}/user/refresh-jwt-token/`,
  "POST",
  { ...authService.getAuthHeaders() },
  { token: localStorage.getItem("userToken") },
);

export const signUpUserBasic = (data) => requestActionCreator(
  types.SIGN_UP_USER_BASIC,
  `${baseUrl}/user/register/`,
  "POST",
  { ...authService.getAuthHeaders() },
  data,
);


export const userLogoutAction = () => {
  const adminToken = localStorage.getItem("adminToken");
  if (adminToken) {
    localStorage.setItem("userToken", adminToken);
    localStorage.removeItem("adminToken");
  } else {
    localStorage.removeItem("userToken");
  }

  return {
    type: types.USER_LOGOUT
  };
};

export const removeAuthErrors = () => ({ type: types.REMOVE_ERRORS });

export const restorePasswordRequest = (email) => requestActionCreator(
  types.RESET_PASSWORD_REQUEST,
  `${baseUrl}/user/restore-password/`,
  "POST",
  { ...authService.getAuthHeaders() },
  JSON.stringify({ email }),
);

export const verifyRestorePasswordByToken = (locationSearch) => requestActionCreator(
  types.VERIFY_RESTORE_PASSWORD_TOKEN,
  `${baseUrl}/user/change-password-by-token/${locationSearch}`,
  "GET",
  { ...authService.getAuthHeaders() },
);

// need token in locationSearch
export const restorePasswordByToken = (locationSearch, password_new_1, password_new_2) => requestActionCreator(
  types.RESTORE_PASSWORD_BY_TOKEN,
  `${baseUrl}/user/change-password-by-token/${locationSearch}`,
  "POST",
  { ...authService.getAuthHeaders() },
  JSON.stringify({ password_new_1, password_new_2 }),
);

export const userEmailVerifying = (token) => requestActionCreator(
  types.EMAIL_VERIFYING,
  `${baseUrl}/user/email-verify/`,
  "POST",
  { ...authService.getAuthHeaders() },
  JSON.stringify({ token }),
);

export const fetchTokenByEmail = (email) => requestActionCreator(
  types.FETCH_TOKEN_BY_EMAIL,
  `${baseUrl}/user/get-user-token/`,
  "POST",
  { ...authService.getAuthHeaders() },
  JSON.stringify({ email }),
);


/**
 *
 * @param {Object} userData
 * @param {number} userData.id
 * @param {string} userData.first_name
 * @param {string} userData.last_name
 * @param {Object} userData.avatat
 * @returns {Object} {{payload: {request: {headers: *, method: *, data: *, url: *}}, meta: *, type: *}}
 */
export const updateUserProfile = (userData) => {
  const formData = new FormData();
  Object.keys(userData)
    .forEach(key => /*userData[key] &&*/ formData.append(key, userData[key]));

  return requestActionCreator(
    types.UPATE_USER_PROFILE,
    `${baseApiUrl}/user/?id=${userData.id}`,
    "PATCH",
    { ...authService.getAuthHeaders() },
    formData,
  );
};

export const setCurrentUser = (userData) => {
  return {
    type: types.SET_CURRENT_USER,
    payload: userData
  };
};

export const resetCurrentUser = () => {
  return {
    type: types.SET_CURRENT_USER,
    payload: null
  };
};
