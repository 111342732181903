import React from "react";

export default {
  "sort_up": <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.64645 5.35355C8.84171 5.54882 9.15829 5.54882 9.35355 5.35355C9.54882 5.15829 9.54882 4.84171 9.35355 4.64645L8.64645 5.35355ZM5 1L5.35355 0.646447C5.25979 0.552678 5.13261 0.5 5 0.5C4.86739 0.5 4.74022 0.552678 4.64645 0.646447L5 1ZM0.646447 4.64645C0.451184 4.84171 0.451184 5.15829 0.646447 5.35355C0.841709 5.54882 1.15829 5.54882 1.35355 5.35355L0.646447 4.64645ZM8.64645 13.3536C8.84171 13.5488 9.15829 13.5488 9.35355 13.3536C9.54882 13.1583 9.54882 12.8417 9.35355 12.6464L8.64645 13.3536ZM5 9L5.35355 8.64645C5.25979 8.55268 5.13261 8.5 5 8.5C4.86739 8.5 4.74022 8.55268 4.64645 8.64645L5 9ZM0.646447 12.6464C0.451184 12.8417 0.451184 13.1583 0.646447 13.3536C0.841709 13.5488 1.15829 13.5488 1.35355 13.3536L0.646447 12.6464ZM9.35355 4.64645L5.35355 0.646447L4.64645 1.35355L8.64645 5.35355L9.35355 4.64645ZM4.64645 0.646447L0.646447 4.64645L1.35355 5.35355L5.35355 1.35355L4.64645 0.646447ZM9.35355 12.6464L5.35355 8.64645L4.64645 9.35355L8.64645 13.3536L9.35355 12.6464ZM4.64645 8.64645L0.646447 12.6464L1.35355 13.3536L5.35355 9.35355L4.64645 8.64645Z"
      fill="white"/>
  </svg>,
  "sort_down": <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.35355 8.64645C1.15829 8.45118 0.84171 8.45118 0.646447 8.64645C0.451185 8.84171 0.451185 9.15829 0.646447 9.35355L1.35355 8.64645ZM5 13L4.64645 13.3536C4.74021 13.4473 4.86739 13.5 5 13.5C5.13261 13.5 5.25978 13.4473 5.35355 13.3536L5 13ZM9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645C9.15829 8.45118 8.84171 8.45118 8.64645 8.64645L9.35355 9.35355ZM1.35355 0.646446C1.15829 0.451184 0.84171 0.451184 0.646447 0.646445C0.451185 0.841708 0.451185 1.15829 0.646447 1.35355L1.35355 0.646446ZM5 5L4.64645 5.35355C4.74022 5.44732 4.86739 5.5 5 5.5C5.13261 5.5 5.25979 5.44732 5.35355 5.35355L5 5ZM9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646446C9.15829 0.451184 8.84171 0.451184 8.64645 0.646446L9.35355 1.35355ZM0.646447 9.35355L4.64645 13.3536L5.35355 12.6464L1.35355 8.64645L0.646447 9.35355ZM5.35355 13.3536L9.35355 9.35355L8.64645 8.64645L4.64645 12.6464L5.35355 13.3536ZM0.646447 1.35355L4.64645 5.35355L5.35355 4.64645L1.35355 0.646446L0.646447 1.35355ZM5.35355 5.35355L9.35355 1.35355L8.64645 0.646446L4.64645 4.64645L5.35355 5.35355Z"
      fill="white"/>
  </svg>,
  "assign_arrow": <svg width="12" height="38" viewBox="0 0 12 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 19L0 38L7.45456e-07 0L12 19Z" fill="#BDBDBD"/>
  </svg>
};