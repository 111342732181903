import React, { useState } from "react";
// COMPONENTS
import AsrChangeImageModal from "@/shared/modals/AsrChangeImageModal";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
import ConfirmDeleteModal from "@/shared/modals/ConfirmDeleteModal";
import AsrAvatar from "@/shared/components/Images/AsrAvatar";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useErrors from "@/shared/hooks/errors/useErrors";
// STORE
import { useUpdateUserAvatarMutation } from "@/store/services/users/rtk";
// STYLES
import styles from "../../../styles.module.scss";


const UserAvatar = ({ userId, userFullName, avatarData, isFetchLoading }) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [updateUserAvatar, { isLoading: isUpdateLoading }] = useUpdateUserAvatarMutation();
  const { handleFetchErrors } = useErrors();

  const isPatchLoading = isSubmitLoading || isUpdateLoading;

  const toggleShowChangeAvatarModal = () => setShowChangeAvatarModal(!showChangeAvatarModal);
  const toggleShowConfirmDeleteModal = () => setShowConfirmDeleteModal(!showConfirmDeleteModal);

  const onAvatarChange = async (image) => {
    if (!avatarData && !image) return;

    await setIsSubmitLoading(true);
    try {
      await updateUserAvatar({ userId, avatar: image }).unwrap();
      showChangeAvatarModal && toggleShowChangeAvatarModal();
    } catch (err) {
      handleFetchErrors(err?.data);
    }
    await setIsSubmitLoading(false);
  };

  const onDeleteAvatar = async () => {
    await onAvatarChange("");
  };

  return (
    <div className={styles.AvatarContainer}>
      <div className={styles.AvatarBlock}>
        <AsrAvatar
          alt="User avatar preview"
          src={avatarData?.url}
          classes={{
            root: styles.AvatarImage
          }}
          rounded
        >
          {userFullName}
        </AsrAvatar>

        {/* OVERLAY WITH HANDLE BUTTONS */}
        <div className={styles.AvatarOverlay}>
          {!isPatchLoading && !isFetchLoading && (
            <>
              <div
                className={styles.OverlayButton}
                onClick={!isPatchLoading ? toggleShowChangeAvatarModal : undefined}
              >
                <IntlMessages id="appCommon.change"/>
              </div>

              {Boolean(avatarData) && (
                <div
                  className={styles.OverlayButton}
                  onClick={!isPatchLoading ? toggleShowConfirmDeleteModal : undefined}
                >
                  <IntlMessages id="appCommon.remove"/>
                </div>
              )}
            </>
          )}
        </div>

        {/* SMALL AVATAR LOADER */}
        {(isPatchLoading || isFetchLoading) && (
          <AsrCommonLoader/>
        )}
      </div>

      {/* CHANGE AVATAR MODAL */}
      {showChangeAvatarModal && (
        <AsrChangeImageModal
          open={showChangeAvatarModal}
          closeModal={toggleShowChangeAvatarModal}
          imageData={avatarData}
          onSubmit={onAvatarChange}
          isSubmitLoading={isPatchLoading}
          lightModal={false}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmDeleteModal
          show={showConfirmDeleteModal}
          setShow={setShowConfirmDeleteModal}
          title={<IntlMessages id="user.removeAvatar"/>}
          text={<IntlMessages id="appModule.cannotBeUndone"/>}
          action={onDeleteAvatar}
        />
      )}

    </div>
  );
};

export default UserAvatar;
