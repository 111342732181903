import { baseApiUrl } from "../../../configs/MainConfig";
import { requestActionCreator } from "../../../utils/requestActionCreator";
import * as types from "../types";
import { authService } from "../../../shared/services/auth.service";
import formConfig from "../../../configs/formComponentsConfig";
import querystring from "query-string";


export const fetchCampaignMembersList = (payload = {}) => (dispatch, getState) => {
  const currentState = getState();
  const pagination = currentState.campaign.members.pagination;
  const sort = currentState.campaign.members.sortBy;

  const payloadQuery = {
    ...pagination,
    ...sort,
    is_archived: false
  };

  if (payload?.questionIds) {
    payloadQuery.assessments_results = payload.questionIds.join(",");
  }

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/member/list/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchCampaignMembersListMinimalistic = (payload = {}) => (dispatch) => {
  const payloadQuery = {
    is_archived: false
  };

  if (payload?.questionIds) {
    payloadQuery.assessments_results = payload.questionIds.join(",");
  }

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/member/list/minimalistic/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_LIST_MINIMALISTIC,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setCampaignMembersSort = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBERS_SORT_ORDER,
  payload
});

export const setCampaignMembersPagination = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBERS_PAGINATION,
  payload
});

// SET PAGE VIEW
export const setCampaignMembersPageViewType = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBERS_PAGE_VIEW_TYPE,
  payload
});

export const resetCampaignMembersPagination = () => ({
  type: types.RESET_CAMPAIGN_MEMBERS_PAGINATION
});
export const resetCampaignMembersSort = () => ({
  type: types.RESET_CAMPAIGN_MEMBERS_SORT_ORDER
});

export const fetchCampaignMembersListForAutocomplete = (payload) => {
  const payloadQuery = {
    page: 1,
    page_size: formConfig.AUTOCOMPLETE_PAGE_SIZE,
    members_type: "assessor"
  };

  Object.entries(payload).forEach((item) => {
    if (item[1] && item[0] !== "exclude_ids") {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  let url = `${baseApiUrl}/organization/members/list/?${query}`;

  if (payload?.exclude_ids?.length) {
    url += `&exclude_ids=${payload.exclude_ids}`;
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_LIST_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

/**
 * MEMBER TOGGLE MODALS
 * @param {{target: string, isVisible: boolean, data: object}} payload
 */
export const toggleCampaignMemberModal = (payload) => ({
  type: types.TOGGLE_CAMPAIGN_MEMBER_MODAL,
  payload
});

export const setCampaignMembersPageBarAddButtonVisible = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBERS_PAGE_BAR_ADD_BUTTON_VISIBLE,
  payload
});
