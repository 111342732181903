import { success } from "redux-saga-requests";
import * as types from "../../../types";
import defaultColorSchema from "../../../../../shared/data/defaultColorSchema";


const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACCOUNT_GENERAL_INFO: {
      return {
        ...state,
        ...action.payload
      };
    }
    case types.SET_DEFAULT_THEME: {
      return {
        ...state,
        settings: {
          ...state.settings,
          color_schema: defaultColorSchema
        }
      };
    }
    // SET COLOR SCHEMA
    case types.SET_COLOR_SCHEMA: {
      return {
        ...state,
        settings: {
          color_schema: action.payload
        }
      };
    }
    // GET COLOR THEME
    case success(types.GET_ORGANIZATION_THEME) :
    case success(types.SET_ORGANIZATION_THEME) : {
      const { response } = action.payload;
      return {
        ...state,
        settings: {
          color_schema: response.results
        }
      };
    }
    default:
      return state;
  }
};
