import React, { useEffect, useRef } from "react";
// import { CircularProgress } from "@mui/material";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
// CONFIG
import config from "@/configs/formComponentsConfig";
// STYLES
import styles from "./styles.module.scss";

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});


const ListBoxComponentNew = React.forwardRef(({
                                                hasNextPage,
                                                isNextPageLoading,
                                                loadNextPage,
                                                rowHeight = config.ASR_AUTOCOMPLETE_ROW_SIZE,
                                                rowPadding = 2,
                                                threshold = 1,
                                                children,
                                                inputValue,
                                                StickyHeaderComponent,
                                                stickyHeaderProps = {},
                                                ...other
                                              }, rootRef) => {
  // const LIST_BOX_PADDING = 2;
  const infiniteLoaderRef = useRef(null);
  const hasMountedRef = useRef(false);

  const listRef = useRef({});
  const rowHeights = useRef({});
  const itemSize = rowHeight || 50;
  const itemData = React.Children.toArray(children);

  const itemCount = hasNextPage ? itemData.length + 1 : itemData.length;

  const loadMoreItems = isNextPageLoading ? () => {
  } : loadNextPage;

  const isItemLoaded = index => !hasNextPage || index < itemData.length;

  function setRowHeight(index, size) {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    }
  }

  function getRowHeight(index) {
    return rowHeights.current[index] || rowHeight;
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getRowHeight).reduce((a, b) => a + b, 0) + getRowHeight();
  };

  const Item = ({ data, index, style }) => {
    const rowRef = useRef();
    useEffect(() => {
      if (rowRef.current) {
        const el =   rowRef.current.clientHeight;
        const height = (el + rowPadding);

        setRowHeight(index, height);
      }
    }, [index, rowRef]);


    if (!isItemLoaded(index)) {
      return (
          <li  ref={rowRef} style={style} className={styles.ListBoxLoader}>
            {/*<CircularProgress size={15}/>*/}
          </li>
      );
    } else if (!data || index >= data.length) {
      return <li ref={rowRef} style={style}/>;
    } else {
      const option = data[index];

      return (
          <li ref={rowRef} style={style} {...other}>
            {option}
          </li>
      );
    }
  };


  useEffect(() => {
    if (hasMountedRef.current) {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache();
      }
    }
    hasMountedRef.current = true;
  }, [inputValue]);

  return (
      <InfiniteLoader
          ref={infiniteLoaderRef}
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          threshold={threshold}
      >
        {({ onItemsRendered, ref }) => (
            <div ref={ref}>
              <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight()}
                    width="100%"
                    ref={listRef}
                    {...(StickyHeaderComponent
                      ? {
                          innerElementType: ({ children, style, ...rest }) => {
                            return <div>
                              <div style={{ position: "sticky", top: 0, zIndex: 20 }}>
                                <StickyHeaderComponent {...stickyHeaderProps} />
                              </div>
                              <ul {...rest} style={{ ...style, position: "relative" }}>
                                {children}
                              </ul>
                            </div>;
                          }
                        }
                      : { innerElementType: "ul" }
                    )}
                    outerElementType={OuterElementType}
                    itemSize={getRowHeight}
                    onItemsRendered={onItemsRendered}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                  {Item}
                </VariableSizeList>
              </OuterElementContext.Provider>
            </div>
        )}
      </InfiniteLoader>
  );
});

export default ListBoxComponentNew;