import React from 'react';
import { useIntlMessageToString } from '../../../../utils/IntlMessages';

const useAssessmentSectionsNames = () => ({
    'M01': useIntlMessageToString('reports.M01'),
    'M02': useIntlMessageToString('reports.M02'),
    'M03': useIntlMessageToString('reports.M03'),
    'M04': useIntlMessageToString('reports.M04'),
    'M05': useIntlMessageToString('reports.M05'),
    'M06': useIntlMessageToString('reports.M06'),

    'A01': useIntlMessageToString('reports.A01'),
    'A02': useIntlMessageToString('reports.A02'),
    'A03': useIntlMessageToString('reports.A03'),
    'A04': useIntlMessageToString('reports.A04'),
    'A05': useIntlMessageToString('reports.A05'),
    'A06': useIntlMessageToString('reports.A06'),
    'A07': useIntlMessageToString('reports.A07'),
    'A08': useIntlMessageToString('reports.A08'),
    'A09': useIntlMessageToString('reports.A09'),

    'F01': useIntlMessageToString('reports.F01'),
    'F02': useIntlMessageToString('reports.F02'),
    'F03': useIntlMessageToString('reports.F03'),
    'F04': useIntlMessageToString('reports.F04'),
    'F05': useIntlMessageToString('reports.F05'),

    'O01': useIntlMessageToString('reports.O01'),
    'O02': useIntlMessageToString('reports.O02'),
    'O03': useIntlMessageToString('reports.O03'),
    'O04': useIntlMessageToString('reports.O04'),
    'O05': useIntlMessageToString('reports.O05'),
    'O06': useIntlMessageToString('reports.O06'),
    'O07': useIntlMessageToString('reports.O07'),
    'O08': useIntlMessageToString('reports.O08'),

    'T01': useIntlMessageToString('reports.T01'),
    'T02': useIntlMessageToString('reports.T02'),
    'T03': useIntlMessageToString('reports.T03'),
    'T04': useIntlMessageToString('reports.T04'),
    'T05': useIntlMessageToString('reports.T05'),
    'T06': useIntlMessageToString('reports.T06'),

    'I01': useIntlMessageToString('reports.I01'),
    'I02': useIntlMessageToString('reports.I02'),
    'I03': useIntlMessageToString('reports.I03'),
    'I04': useIntlMessageToString('reports.I04'),
    'I05': useIntlMessageToString('reports.I05'),
    'I06': useIntlMessageToString('reports.I06'),
    'I07': useIntlMessageToString('reports.I07'),

    'S01': useIntlMessageToString('reports.S01'),
    'S02': useIntlMessageToString('reports.S02'),
    'S03': useIntlMessageToString('reports.S03'),
    'S04': useIntlMessageToString('reports.S04'),
    'S05': useIntlMessageToString('reports.S05'),

    'R01': useIntlMessageToString('reports.R01'),
    'R02': useIntlMessageToString('reports.R02'),
    'R03': useIntlMessageToString('reports.R03'),
    'R04': useIntlMessageToString('reports.R04'),
    'R05': useIntlMessageToString('reports.R05'),
    'R06': useIntlMessageToString('reports.R06'),
    'R07': useIntlMessageToString('reports.R07'),
});

export default useAssessmentSectionsNames;