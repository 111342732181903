import React from "react";
import classNames from "classnames";
// UTILS
import IntlMessages from "../../../../../../../../utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";


const PreviewImageActions = ({
                               onReplace,
                               onRemove,
                               classes = {
                                 root: ""
                               },
                               replaceLabelID,
                               isReplace = true,
                               isRemove = true,
                               imageRounded = false
                             }) => {
  const rootClass = classNames({
    [styles.PreviewImageActions]: true,
    [styles.Rounded]: imageRounded,
    [classes.root]: classes.root
  });

  return (
    <div className={rootClass}>
      {isReplace && (
        <button
          onClick={onReplace}
          type="button"
          className={`${styles.PreviewImageActionsButtons} ${styles.PreviewImageActionsButtonsReplace}`}>
          <label htmlFor={replaceLabelID}>
            <IntlMessages id="appCommon.replace"/>
          </label>
        </button>
      )}
      {isRemove && (
        <button
          onClick={onRemove}
          type="button"
          className={`${styles.PreviewImageActionsButtons} ${styles.PreviewImageActionsButtonsRemove}`}>
          <IntlMessages id="appCommon.remove"/>
        </button>
      )}
    </div>
  );
};

export default PreviewImageActions;