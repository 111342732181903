import querystring from "query-string";
import { baseApiUrl, baseApiUrlV2 } from "@/configs/MainConfig";
import { requestActionCreator } from "@/utils/requestActionCreator";
import * as types from "../types";
import { authService } from "@/shared/services/auth.service";

export const fetchOrganizationCampaignsList = (id, page = 1, page_size = 3, searchValue, sort_by = "order_in_list,id") => {
  let url = `${baseApiUrl}/campaign/list/?organization_id=${id}&page=${page}&page_size=${page_size}&is_archived=false&sort_by=${sort_by}`;

  if (searchValue) {
    url = `${url}&name=${searchValue}`;
  }

  return requestActionCreator(
    types.FETCH_ORGANIZATION_CAMPAIGNS_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchAccountCampaignsSharedItemsList = ({
                                                       accountId,
                                                       page = 1,
                                                       pageSize = 10,
                                                       searchValue,
                                                       orderBy,
                                                       idsToTop
                                                     }) => {
  let url = `${baseApiUrlV2}/accounts/${accountId}/campaigns/?target=shared-items&page=${page}&page_size=${pageSize}`;
  if (searchValue) {
    url = `${url}&search=${searchValue}`;
  }

  if (orderBy) {
    url = `${url}&order_by=${searchValue}`;
  }

  if (idsToTop) {
    url = `${url}&ids_to_top=${idsToTop}`;
  }

  return requestActionCreator(
    types.FETCH_ORGANIZATION_CAMPAIGNS_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchOrganizationCampaignsAssessments = (payload = {}) => (dispatch, getState) => {

  const payloadQuery = {};

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  let url = `${baseApiUrl}/campaign/assessment/list/minimalistic/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_CAMPAIGNS_ASSESSMENTS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setOrganizationCampaignSort = (payload) => ({
  type: types.SET_ORGANIZATION_CAMPAIGNS_SORT_ORDER,
  payload
});

export const setOrganizationCampaignsCardsPagination = (payload) => ({
  type: types.SET_ORGANIZATION_CAMPAIGNS_CARDS_PAGINATION,
  payload
});

export const setOrganizationCampaignsFilters = (payload) => ({
  type: types.SET_ORGANIZATION_CAMPAIGNS_FILTERS,
  payload
});

export const resetOrganizationCampaignPagination = (payload) => ({
  type: types.RESET_ORGANIZATION_CAMPAIGNS_PAGINATION,
  payload
});

export const fetchCampaignsForAutocomplete = (payload) => {
  const payloadQuery = {
    page_size: 15
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);
  let url = `${baseApiUrl}/campaign/list/?${query}`;

  return requestActionCreator(
    types.FETCH_CAMPAIGNS_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const setOrganizationCampaignPageViewType = (payload) => ({
  type: types.SET_ORGANIZATION_CAMPAIGN_PAGE_VIEW_TYPE,
  payload
});

// MODALS
export const toggleOrganizationCampaignModal = (payload) => ({
  type: types.TOGGLE_ORGANIZATION_CAMPAIGN_MODAL,
  payload
});

export const setOrganizationCampaignPageBarAddButtonVisible = (payload) => ({
  type: types.SET_ORGANIZATION_CAMPAIGN_PAGE_BAR_ADD_BUTTON_VISIBLE,
  payload
});
