import React from "react";
import SideNav from "@/components/SideNav";
import PageContent from "../PageContent";
// MATERIAL UI
import { ThemeProvider } from "@mui/material/styles";
// HOOKS
import { getCurrentMuiTheme } from "@/shared/hooks/themes/muiThemes";
import UseSyncTokenWithReduxUser from "@/shared/hooks/user/useSyncTokenWithReduxUser";

const App = () => {
  UseSyncTokenWithReduxUser({ redirectToSignIn: true });

  return (
    <ThemeProvider theme={getCurrentMuiTheme()}>
      <div className="app-container fixed-drawer">
        <SideNav/>
        <PageContent/>
      </div>
    </ThemeProvider>
  );
};

App.displayName = "App";
export default App;