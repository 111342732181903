import React from "react";
// COMPONENTS
import AsrCommonLoader from "../../UI/Loaders/AsrCommonLoader/AsrCommonLoader";
import Modal from "../../components/Modals/AsrModal";
import AsrSaveButton from "../../components/Buttons/AsrSaveButton/AsrSaveButton";
import AsrCancelButton from "../../components/Buttons/AsrCancelButton/AsrCancelButton";
// STYLES
import styles from "./styles.module.scss";


const ConfirmDeleteModal = ({
                              show,
                              setShow,
                              text,
                              subText,
                              description,
                              title,
                              action,
                              disabled,
                              titleCapitalize = true,
                              confirmButtonLabel = "organizationMainPage.delete",
                              classes = {
                                content: "",
                                footer: "",
                                confirmButtonStyle: "",
                                description: "",
                                deleteInput: ""
                              },
                              loading,
                              CustomFooter,
                              DeleteInput,
                              DescriptionComponent
                            }) => {

  const handleConfirm = () => {
    action();
    setShow(false);
  };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <Modal
      open={show}
      closeModal={() => setShow(false)}
      title={title}
      titleCapitalize={titleCapitalize}
      classes={{
        modal: "asr-modal-wrapper"
      }}
    >
      {loading && <AsrCommonLoader withBackdrop/>}
      <div className={`${styles.ConfirmDeleteModalContent} ${classes.content}`}>
        {text && (
          <div className={styles.text}>
            {text}
          </div>
        )}
        {description &&
          <div className={classes.description}>
            {description}
          </div>}
        {DescriptionComponent &&
          <div className={classes.description}>
            <DescriptionComponent/>
          </div>}
        {subText && (
          <div className={`${styles.text} ${styles.subText}`}>{subText}</div>
        )}
        {DeleteInput &&
          <div className={classes.deleteInput}>
            <DeleteInput/>
          </div>}
      </div>
      {/* FOOTER */}
      <div className={`${styles.ConfirmDeleteModalFooter} ${classes.footer}`}>
        {CustomFooter ? <CustomFooter/> : (
          <>
            <AsrCancelButton mr={2} handler={handleCancel}/>
            <AsrSaveButton
              disabled={disabled}
              loading={loading}
              classNameWrapper="asr-modal-confirm-button"
              label={confirmButtonLabel}
              handler={handleConfirm}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;