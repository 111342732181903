import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// STORE
import { getLocale } from "@/store/Settings/selectors";

const assessmentStatuses = {
  UNVERIFIED: "unverified",
  VERIFIED: "verified",
  MODIFIED: "modified"
};

const useAuditorActionStatus = ({
                                  questionData,
                                  questionName,
                                  selectHandler
                                }) => {
  const { locale } = useSelector(getLocale);

  const unverified = getIntlMessageToStringLocal(locale, "assessments.unverified");
  const verified = getIntlMessageToStringLocal(locale, "assessments.verified");
  const modified = getIntlMessageToStringLocal(locale, "assessments.modified");

  const translateOptionsForAssessorSelect = useMemo(() => {
    return [unverified, verified, modified];
  }, [modified, unverified, verified]);

  const optionsForAssessorSelect = useMemo(() => {
    return [assessmentStatuses.UNVERIFIED, assessmentStatuses.VERIFIED, assessmentStatuses.MODIFIED];
  }, []);

  const [questionStatus, setQuestionStatus] = useState(questionData[questionName]?.status ?? "");

  useEffect(() => {
    const currentStatusIndex = optionsForAssessorSelect.indexOf(questionData[questionName]?.status);
    const currentStatus = translateOptionsForAssessorSelect[currentStatusIndex];
    setQuestionStatus(currentStatus);
  }, [optionsForAssessorSelect, questionData, questionName, translateOptionsForAssessorSelect]);

  const handleVerified = (e) => {
    const indexCurrentStatus = translateOptionsForAssessorSelect.indexOf(e.target.textContent);
    let indexNextStatus;
    if (indexCurrentStatus < optionsForAssessorSelect.length - 1) {
      indexNextStatus = indexCurrentStatus + 1;
    } else {
      indexNextStatus = 0;
    }
    const currentStatus = translateOptionsForAssessorSelect[indexNextStatus];
    setQuestionStatus(currentStatus);
    selectHandler({ event: e, questionName, questionData, status: optionsForAssessorSelect[indexNextStatus] });
  };

  return {
    handleVerified,
    questionStatus
  };
};

export default useAuditorActionStatus;