import { assessorApi } from "@/store/services/rtk";
// CONSTANTS
import { assessorApiTargets } from "../config/assessorApiConstants";
import { campaignAssessmentsTag, campaignAssessmentsTarget } from "./constants";
import { campaignParticipantsTags } from "../campaignParticipants/constants";
import { HTTPMethods } from "../config/constants";
// UTILS
import transformPayloadToQuery from "../utils/transformPayloadToQuery";
import transformPayloadToFormData from "../utils/transformPayloadToFormData";
import { CAMPAIGN_PARTICIPANT_INFO_TAG } from "../campaigns/constants";
import { setOrganizationAssessments } from "../../Organization/actions";


const assessments = assessorApi.injectEndpoints({
  endpoints: (build) => ({
    // GET Assessments
    getCampaignAssessments: build.query({
      query: ({ campaign_id, ...payload } = {}) => {
        const defaultQuery = {
          target: campaignAssessmentsTarget.CARD,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/campaigns/${campaign_id}/assessments/${query}` };
      },
      providesTags: [campaignAssessmentsTag.GET_ASSESSMENT]
    }),
    // GET Campaign Assessments Dashboard
    getCampaignAssessmentsDashboard: build.query({
      query: ({ campaign_id, ...payload } = {}) => {
        const defaultQuery = {
          target: campaignAssessmentsTarget.DASHBOARD,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/campaigns/${campaign_id}/assessments/${query}` };
      },
      providesTags: [campaignAssessmentsTag.GET_ASSESSMENT]
    }),
    // GET Assessments Forms FOR AUTOCOMPLETE
    getAssessmentsForAutocomplete: build.query({
      query: ({ account_id, ...payload } = {}) => {
        const defaultQuery = {
          target: assessorApiTargets.SHARED_ITEMS,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        let url = `/assessments/${query}`;
        if (account_id) {
          url = `/accounts/${account_id}${url}`;
        }
        return { url };
      },
      providesTags: [campaignAssessmentsTag.GET_ASSESSMENT]
    }),
    // GET Assessments FOR Power BI interface
    getAssessmentsForPowerBIDataset: build.query({
      query: ({ account_id, ...payload } = {}) => {
        const defaultQuery = {
          target: campaignAssessmentsTarget.POWER_BI_DATASET,
        };
        const query = transformPayloadToQuery({ payload, defaultQuery });
        return { url: `/accounts/${account_id}/assessments/${query}` };
      },
      onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
        const {data} = await queryFulfilled;
        dispatch(setOrganizationAssessments(data));
      },
      providesTags: [campaignAssessmentsTag.GET_ASSESSMENT]
    }),
    // GET Assessments Forms FOR POLICY MANAGE
    getAssessmentsForPolicyManage: build.query({
      query: ({ account_id, ...payload } = {}) => {
        const defaultQuery = {
          target: assessorApiTargets.POLICY_MANAGE,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${account_id}/assessments/${query}` };
      },
      providesTags: [campaignAssessmentsTag.GET_ASSESSMENT]
    }),
    // ADD Assessment
    addAssessment: build.mutation({
      query: (body) => {
        const formData = transformPayloadToFormData(body);
        return {
          url: `/assessments/?target=${assessorApiTargets.COMMON_CREATE}`,
          method: HTTPMethods.POST,
          body: formData
        };
      },
      invalidatesTags: [campaignAssessmentsTag.GET_ASSESSMENT, campaignAssessmentsTag.GET_CAMPAIGN_ASSESSMENTS]
    }),
    // ADD Assessment
    editAssessment: build.mutation({
      query: ({ assessment_id, ...body }) => {
        const formData = transformPayloadToFormData(body);
        return {
          url: `/assessments/${assessment_id}/?target=${assessorApiTargets.COMMON_UPDATE}`,
          method: HTTPMethods.PATCH,
          body: formData
        };
      },
      invalidatesTags: [campaignAssessmentsTag.GET_ASSESSMENT, campaignAssessmentsTag.GET_CAMPAIGN_ASSESSMENTS]
    }),
    // ADD Assessment
    deleteAssessment: build.mutation({
      query: (assessment_id) => {
        return {
          url: `/assessments/${assessment_id}/?target=${assessorApiTargets.COMMON_DELETE}`,
          method: HTTPMethods.DELETE,
        };
      },
      invalidatesTags: [campaignAssessmentsTag.GET_ASSESSMENT, campaignAssessmentsTag.GET_CAMPAIGN_ASSESSMENTS]
    }),
    // EDIT
    getAssessmentsForManage: build.query({
      query: ({ assessment_id, ...payload } = {}) => {
        const defaultQuery = {
          target: assessorApiTargets.COMMON_UPDATE,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/assessments/${assessment_id}/${query}` };
      },
      providesTags: [campaignAssessmentsTag.GET_ASSESSMENT]
    }),
    // Change Assessment Status
    changeAssessmentProgressStatus: build.mutation({
      query: ({ assessment_id, user_id, management_type, status_action }) => {

        return {
          url: `/assessments/${assessment_id}/act/user/${user_id}/mt/${management_type}/action/${status_action}/?target=set-progress-status`,
          method: HTTPMethods.POST,
        };
      },
      invalidatesTags: [CAMPAIGN_PARTICIPANT_INFO_TAG, campaignParticipantsTags.CAMPAIGN_PARTICIPANTS]
    }),
    changeAssessmentAccessibilityStatus: build.mutation({
      query: ({ assessment_id, user_id, management_type, status_action }) => {

        return {
          url: `/assessments/${assessment_id}/act/user/${user_id}/mt/${management_type}/action/${status_action}/?target=set-accessibility-status`,
          method: HTTPMethods.POST,
        };
      },
      invalidatesTags: [CAMPAIGN_PARTICIPANT_INFO_TAG, campaignParticipantsTags.CAMPAIGN_PARTICIPANTS]
    }),
    //
    getAssessmentAnswersForPassing: build.query({
      query: ({ assessment_id, user_id, management_type }) => {

        return {
          url: `/assessments/${assessment_id}/act/user/${user_id}/mt/${management_type}/?target=for-passing`
        };
      }
    }),
    // Update Answers
    updateAssessmentAnswers: build.mutation({
      query: ({ assessment_id, user_id, management_type, ...body }) => {
        return {
          url: `assessments/${assessment_id}/act/user/${user_id}/mt/${management_type}/?target=question-answers`,
          method: HTTPMethods.POST,
          body
        };
      }
    }),
    // Update Answers
    updateAssessmentActsAnswers: build.mutation({
      query: ({ act_id, management_type, ...body }) => {
        return {
          url: `assessments/acts/${act_id}/mt/${management_type}/?target=question-answers`,
          method: HTTPMethods.POST,
          body
        };
      }
    }),
    /// =====  CAMPAIGN =======
    // GET Assessments Forms FOR AUTOCOMPLETE
    getCampaignAssessmentsMinimalistic: build.query({
      query: ({ campaign_id, ...payload } = {}) => {
        const defaultQuery = {
          target: assessorApiTargets.SHARED_ITEMS,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/campaigns/${campaign_id}/assessments/${query}` };
      },
      providesTags: [campaignAssessmentsTag.GET_CAMPAIGN_ASSESSMENTS]
    }),
    // GET USERS ANSWERS
    getAssessmentUsersAnswers: build.query({
      query: ({ account_id, user_id, ...payload } = {}) => {
        const defaultQuery = {
          target: campaignAssessmentsTarget.AUDITOR_TABLE,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${account_id}/users/${user_id}/answers/${query}` };
      },
      providesTags: [campaignAssessmentsTag.USERS_ANSWERS]
    }),
    // GET USERS ANSWERS
    getAssessmentUsersAnswersById: build.query({
      query: ({ account_id, user_id, ...payload } = {}) => {
        const defaultQuery = {
          target: campaignAssessmentsTarget.AUDITOR_TABLE,
        };

        const query = transformPayloadToQuery({ payload, defaultQuery });

        return { url: `/accounts/${account_id}/users/${user_id}/answers/${query}` };
      },
      providesTags: [campaignAssessmentsTag.USERS_ANSWERS]
    }),
  }),
  overrideExisting: true,
});

assessments.enhanceEndpoints({
  addTagTypes: [
    campaignAssessmentsTag.GET_ASSESSMENT,
    campaignAssessmentsTag.GET_CAMPAIGN_ASSESSMENTS,
    campaignAssessmentsTag.USERS_ANSWERS
  ]
});

export const {
  useGetAssessmentsForAutocompleteQuery,
  useGetAssessmentsForPowerBIDatasetQuery,
  useGetAssessmentsForPolicyManageQuery,
  useLazyGetCampaignAssessmentsDashboardQuery,
  useGetCampaignAssessmentsQuery,
  useGetAssessmentsForManageQuery,
  useAddAssessmentMutation,
  useEditAssessmentMutation,
  useDeleteAssessmentMutation,
  useChangeAssessmentProgressStatusMutation,
  useChangeAssessmentAccessibilityStatusMutation,
  useUpdateAssessmentActsAnswersMutation,
  useGetAssessmentUsersAnswersQuery,
  useLazyGetAssessmentAnswersForPassingQuery,
} = assessments;