// CONSTANTS
import { actionsConstants } from "../constants/actionsConstants";
// VALIDATORS
import validators from "../validators";

/**
 * Validate if user has permission by policy validation
 * @returns {boolean} validation is user has permission.
 */
export const policyValidation = (({
                                   action = "",
                                   lookup,
                                   isSuperUser = false,
                                   isOwner = false,
                                   permissions
                                 }) => {
  if (isSuperUser) return true;
  const allPermissionsPolicies = permissions[actionsConstants.ALL];
  if (allPermissionsPolicies !== undefined) return true;

  const policySettings = permissions[action];

  if (policySettings === undefined) return false;
  if (lookup === undefined) return true;

  return validators[action]({ lookup, isOwner, policySettings, permissions });
});