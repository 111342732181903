import React from "react";
// COMPONENTS
import LanguageSelector from "@/components/AppHeader/components/LanguageSelector";


const ChooseLanguage = () => {
  return (
    <LanguageSelector/>
  )
};

export default ChooseLanguage;
