import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import queryString from "query-string";
// STORE
import { addSystemNotification } from "@/store/System/actions";
import { fetchAssessmentExtendedInfoByKey } from "@/store/PublicSurvey/actions";

import PublicSurveyEmail from "./PublicSurveyEmail";
import PublicSurveyQuestionsNew from "./PublicSurveyQuestionsNew/PublicSurveyQuestionsNew";
import CircularProgressCover from "@/shared/UI/Loaders/CircularProgressCover";
import ErrorBoundary from "@/shared/components/ErrorBoundary";

const PublicSurvey = () => {
  // LOCAL STATE
  const [loading, setLoading] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [assessmentBody, setAssessmentBody] = useState(null);
  const [skipFlashScreen, setSkipFlashScreen] = useState(true);
  const [email, setEmail] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  //
  const dispatch = useDispatch();

  const location = useLocation();

  const key = get(location, "state.key") || queryString.parse(location.search).key;

  // FETCH Assessment Info
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const dataAssessment = await dispatch(fetchAssessmentExtendedInfoByKey(key));
      const body = get(
        dataAssessment,
        "payload.response.results.survey.body",
        {}
      );

      setAssessmentBody(body);

      const isFlashScreen = get(
        dataAssessment,
        "payload.response.results.skip_flash_screen",
        {}
      );

      setSkipFlashScreen(isFlashScreen);

      const campaign = get(
        dataAssessment,
        "payload.response.results.campaign.name",
        ""
      );

      setCampaignName(campaign);

      const campaignId = get(
        dataAssessment,
        "payload.response.results.campaign.id",
        ""
      );
      setCampaignId(campaignId);

      const organization = get(
        dataAssessment,
        "payload.response.results.organization.name",
        ""
      );

      setOrganizationName(organization);

      const organizationId = get(
        dataAssessment,
        "payload.response.results.organization.id",
        ""
      );

      setOrganizationId(organizationId);
    } catch (e) {
      const error = get(
        e,
        "payload.response.data.errors.details",
        "An error has occurred1"
      );
      dispatch(addSystemNotification("", error, "error"));
    } finally {
      setLoading(false);
    }
  }, [dispatch, key]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  if (loading) return <CircularProgressCover open={loading}/>;

  return (
    <ErrorBoundary>
      {skipFlashScreen || currentPage === "survey-questions" ? (
        <PublicSurveyQuestionsNew
          assessmentBody={assessmentBody}
          campaignName={campaignName}
          organizationName={organizationName}
          organizationId={organizationId}
          surveyKey={key}
          campaignId={campaignId}
          email={email}
        />
      ) : (
        <PublicSurveyEmail setEmail={setEmail} setCurrentPage={setCurrentPage}/>
      )}
    </ErrorBoundary>
  );
};

export default PublicSurvey;
