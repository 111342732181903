import { capitalizeTheFirstLetter } from "@/utils/dtypes/string";

export const generateUserFullName = ({ firstName, lastName, titleName }) => {
  let userFullName = "";
  if (titleName) {
    userFullName += `${capitalizeTheFirstLetter(titleName)}.`;
  }

  if (firstName) {
    userFullName += ` ${firstName}`;
  }

  if (lastName) {
    userFullName += ` ${lastName}`;
  }
  return userFullName.trim();
};
