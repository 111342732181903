import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
// COMPONENTS
import AsrModal from "src/shared/components/Modals/AsrModal";
import AsrLightModal from "src/shared/components/Modals/AsrLightModal";
import ContactUsModalForm from "./components/ContactUsModalForm";
// STORE
import { getCurrentUser } from "@/store/User/selectors";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";


const ContactUsModal = ({
                          show,
                          closeModal,
                        }) => {
  const formRef = useRef();
  const currentUser = useSelector(getCurrentUser);

  const onSubmitButtonClick = async () => {
    if (!formRef.current) return;
    await formRef.current.handleSubmit();
  };

  const RenderModal = useCallback(({ children }) => {
    if (currentUser) {
      return (
        <AsrModal
          open={show}
          closeModal={closeModal}
          title={<IntlMessages id="appModule.contactUs"/>}
          islandContent={true}
          classes={{
            modalBox: styles.DefaultModalBox,
            modalContent: styles.ModalContent
          }}
          actionFooterButtons={[
            {
              title: <IntlMessages id="appModule.cancel"/>,
              type: "plainText",
              onClick: closeModal
            },
            {
              title: (
                <IntlMessages
                  id="appModule.sendItStyled"
                  values={{
                    text1: value => <div className={styles.Dark}>{value}</div>,
                    text2: value => <div className={styles.Light}>{value}</div>
                  }}
                />),
              type: "default",
              onClick: onSubmitButtonClick,
              classes: {
                button: styles.SendItButton
              }
            }
          ]}
        >
          {children}
        </AsrModal>
      );
    }

    return (
      <AsrLightModal
        open={show}
        onClose={closeModal}
        classes={{
          modalBox: styles.LightModalMox,
          titleContainer: styles.ModalTitle
        }}
        title={<IntlMessages id="appModule.contactUs"/>}
      >
        {children}
      </AsrLightModal>
    );
  }, [currentUser]);

  return (
    <RenderModal>
      <ContactUsModalForm
        formRef={formRef}
        closeModal={closeModal}
        isLoading={false}
        currentUser={currentUser}
      />
    </RenderModal>
  );
};

export default ContactUsModal;