import React from "react";
import PropTypes from "prop-types";
import CardBox from "./CardBox";
import IntlMessages from "../../../../utils/IntlMessages";
import QuestionAnswer from "./QuestionAnswer";

const Card10 = React.forwardRef(function Card10({ data, exportImage, keyForSavingCardState }, ref) {
    const objectivesQuestion = data.find((question) => question.question_id === "1591353106185759") || {};

    const {
        value: objectives = [],
    } = objectivesQuestion;

    return (
        <CardBox
            heading={<IntlMessages id='reports.card10Title'/>}
            exportImage={exportImage}
            ref={ref}
            keyForSavingCardState={keyForSavingCardState}
        >
            {objectives?.length > 0
                ? (
                    <div className='report-card__body__container'>
                        <QuestionAnswer
                            question={objectivesQuestion}
                        />
                    </div>
                ) : (
                    <div className='report-card__no-content'><IntlMessages id='reports.noContent'/></div>
                )}
        </CardBox>
    );
});

Card10.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    exportImage: PropTypes.func.isRequired,
    keyForSavingCardState: PropTypes.string.isRequired,
};

export default Card10;