import modificationSvgIcons from "./modificationSvgIcons";
import commonSvgIcons from "./commonSvgIcons";
import formsSvgIcons from "./formComponentsIcons";
import statementsSvgIcons from "./statementsSvgIcons";
import popupSvgIcons from "./popupSvgIcons";
import tablesSvgIcons from "./tablesSvgIcons";

export default {
  ...modificationSvgIcons,
  ...commonSvgIcons,
  ...formsSvgIcons,
  ...popupSvgIcons,
  ...statementsSvgIcons,
  ...tablesSvgIcons
};