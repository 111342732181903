import React from "react";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";


// Create styles
const styles = StyleSheet.create({
    cardListItem: {
        width: "100%",
        flexGrow: 1,
        flexShrink: 0,
        display: "flex",
        flexDirection: "row",
        fontWeight: "medium",
        color: "#333333",
        borderBottom: "1pt solid #fff",
    },
    questionWrapper: {
        width: "50%",
        flexShrink: 0,
        paddingHorizontal: "8pt",
        backgroundColor: "#F2F2F2"
    },
    answerWrapper: {
        width: "50%",
        flexShrink: 0,
        paddingHorizontal: "8pt",
    },
    multipletextAnswer: {
        flexDirection: "column",
        alignItems: "flex-start"
    },
    text: {
        flexShrink: 0,
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});

const removeSquareBrackets = (title) => title?.replace(/(\[\[|\]\])/g, "") || "";

const formatValue = (value) => {
    return value ? String(value).trim() : "";
};

// Create Component
const QuestionAnswer = ({ question, compactSize }) => {
    const {
        type,
        value,
        title,
    } = question;

    const textClass = [styles.text, {
        ...(!compactSize
                ? { fontSize: "8pt", paddingVertical: "2pt" }
                : { fontSize: "6pt", paddingVertical: "1pt" }
        )
    }];

    return (
        <View style={styles.cardListItem}>
            <View style={styles.questionWrapper}>
                <Text style={textClass}>{removeSquareBrackets(title)}</Text>
            </View>
            <View style={styles.answerWrapper}>
                {type === "checkbox" && (
                    <View style={styles.multipletextAnswer}>
                        {value.map((item, index) => (
                            <Text
                                key={index}
                                style={textClass}
                                render={() => removeSquareBrackets(item)}
                            />
                        ))}
                    </View>
                )}
                {(type === "text" || type === "dropdown") && (
                    <Text style={textClass} render={() => `${formatValue(value)}`} />
                )}
                {type === "rating" && (
                    <Text style={textClass} render={() => `${formatValue(value)}%`} />
                )}
                {type === "radiogroup" && (
                    <Text style={textClass} render={() => `${formatValue(value)}`} />
                )}
                {(type === "multipletext"  || type === "matrix") && (
                    <View style={styles.multipletextAnswer}>
                        {value?.map((item, index) => {
                            const { title, value } = item;
                            return (
                                <Text
                                    key={index}
                                    style={textClass}
                                    render={() => `${title}: ${value}`}
                                />
                            );
                        })}
                    </View>
                )}
                {type === "sortablelist" && (
                    <View style={styles.multipletextAnswer}>
                        {value?.map((item, index) => {
                            return (
                                <Text
                                    key={index}
                                    style={textClass}
                                    render={() => `${index + 1}: ${item}`}
                                />
                            );
                        })}
                    </View>
                )}
            </View>
        </View>
    );
};

export default QuestionAnswer;
