import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_MEMBERS_LIST_SORT, accountMemberListModals } from "./constants";

const initialState = {
  pagination: {
    page_size: 10,
    page: 1
  },
  sortBy: {
    sort_by: ACCOUNT_MEMBERS_LIST_SORT
  },
  modals: {
    [accountMemberListModals.CREATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberListModals.UPDATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberListModals.DELETE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberListModals.UNLOCK]: {
      isVisible: false,
      data: {},
    }
  }
};

export const counterSlice = createSlice({
  name: "accountMembersList",
  initialState,
  reducers: {
    setAccountMembersListPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload
      };
    },
    setAccountMembersListSort: (state, action) => {
      state.sortBy = action.payload;
    },
    toggleAccountMemberListModal: (state, action) => {
      state.modals[action.payload.target] = {
        ...state.modals?.[action.payload.target],
        isVisible: action.payload.isVisible,
        data: action.payload?.data || {}
      };
    },
  },
});


export const {
  setAccountMembersListPagination,
  setAccountMembersListSort,
  toggleAccountMemberListModal
} = counterSlice.actions;

export default counterSlice.reducer;