import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_MEMBERS_ROLES_SORT, accountMemberRolesModals } from "./constants";

const initialState = {
  pagination: {
    page_size: 10,
    page: 1
  },
  sortBy: {
    sort_by: ACCOUNT_MEMBERS_ROLES_SORT
  },
  modals: {
    [accountMemberRolesModals.CREATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberRolesModals.UPDATE]: {
      isVisible: false,
      data: {},
    },
    [accountMemberRolesModals.DELETE]: {
      isVisible: false,
      data: {},
    }
  }
};

export const counterSlice = createSlice({
  name: "accountMembersRoles",
  initialState,
  reducers: {
    setAccountMembersRolesPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload
      };
    },
    setAccountMembersRolesSort: (state, action) => {
      state.sortBy = {
        ...action.payload
      };
    },
    toggleAccountMemberRoleModal: (state, action) => {
      state.modals = {
        ...state.modals,
        [action.payload.target]: {
          ...state.modals?.[action.payload.target],
          isVisible: action.payload.isVisible,
          data: action.payload?.data || {}
        }
      };
    },
  },
});


export const {
  setAccountMembersRolesPagination,
  setAccountMembersRolesSort,
  toggleAccountMemberRoleModal
} = counterSlice.actions;

export default counterSlice.reducer;