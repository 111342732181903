import React, { useCallback } from "react";
import PropTypes from "prop-types";
// COMPONENTS
import AsrLightModal from "@/shared/components/Modals/AsrLightModal";
import AsrModal from "@/shared/components/Modals/AsrModal";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";


const ReplaceEmailConfirmationModal = ({
                                         open,
                                         closeModal,
                                         onSubmit,
                                         lightModal = false
                                       }) => {

  const RenderModal = useCallback(({ children }) => {
    if (lightModal) {
      return (
        <AsrLightModal
          open={open}
          onClose={closeModal}
          title={<IntlMessages id="user.replaceEmail"/>}
          classes={{
            modalBox: styles.LightModalBox,
            footerContainer: styles.ModalFooter
          }}
          actionFooterButtons={[
            {
              title: <IntlMessages id="appModule.cancel"/>,
              type: "plainText",
              classes: {
                button: styles.TextButton
              },
              onClick: closeModal
            },
            {
              title: <IntlMessages id='organizationMainPage.confirm'/>,
              type: "default",
              classes: {
                button: styles.SubmitButton
              },
              onClick: onSubmit
            }
          ]}
        >
          {children}
        </AsrLightModal>
      );
    }

    return (
      <AsrModal
        open={open}
        closeModal={closeModal}
        title={<IntlMessages id="user.replaceEmail"/>}
        classes={{
          modalBox: styles.DefaultModalBox,
          modalContent: styles.ContentContainer,
          footerContainer: styles.ModalFooter
        }}
        islandContent={true}
        actionFooterButtons={[
          {
            title: <IntlMessages id="appModule.cancel"/>,
            type: "plainText",
            classes: {
              button: styles.TextButton
            },
            onClick: closeModal
          },
          {
            title: <IntlMessages id='organizationMainPage.confirm'/>,
            type: "default",
            classes: {
              button: styles.SubmitButton
            },
            onClick: onSubmit
          }
        ]}
      >
        {children}
      </AsrModal>
    );
  }, [lightModal]);

  return (
    <RenderModal>
      <div className={styles.ModalContent}>
        <span>
          <IntlMessages
            id="user.youAreAttemptingToReplaceEmailInfo"
            values={{
              i: value => <i>{value}</i>
            }}
          />
        </span>

        <div className={styles.ListOfActions}>
          <div className={`${styles.UlText} ${styles.Bold}`}>
            <IntlMessages id="user.pleaseReadCarefully"/>:
          </div>

          <ul>
            <li>
              <IntlMessages id="user.noDataWillBeLost"/>.
            </li>
            <li>
              <IntlMessages
                id="user.oldEmailNoLongerBeUsedInFuture"
                values={{
                  i: value => <i>{value}</i>
                }}
              />.
            </li>
            <li>
              <IntlMessages
                id="user.anyOldEmailPendingInvitationsWillNoLongerBeWork"
                values={{
                  i: value => <i>{value}</i>
                }}
              />.
            </li>
          </ul>
        </div>

        <div>
          <span style={{ letterSpacing: "0.02em" }}><IntlMessages
            id="user.pleaseConfirmOrCancelRequest"
            values={{
              bold: value => <span className={styles.Bold}> {value}</span>
            }}
          />.</span>
        </div>
      </div>
    </RenderModal>
  );
};

ReplaceEmailConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  lightModal: PropTypes.bool
};
export default ReplaceEmailConfirmationModal;
