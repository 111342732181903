import { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";


const LazyLoader = ({ skeleton }) => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
      NProgress.remove();
    };
  });
  if (skeleton) return skeleton;
  return "";
};

export default LazyLoader;