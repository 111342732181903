import { success, error } from "redux-saga-requests";
import * as types from "@/store/Organization/types";
import {
  ORGANIZATION_ASSESSMENTS_CUSTOM_FIELDS_SORT,
  ORGANIZATION_ASSESSMENTS_ITEMS_SORT,
  ORGANIZATION_ASSESSOR_ASSIGNMENTS_SORT,
  ORGANIZATION_MEMBERS_SORT,
  ORGANIZATION_CAMPAIGN_CARDS_SORT,
  ORGANIZATION_REGISTRATION_CONFIRMATIONS_SORT,
  ORGANIZATION_SURVEY_TEMPLATE_SORT,
  campaignsModals,
  campaignsViewType
} from "@/store/Organization/constants";
import pageView from "@/shared/constants/pageView";


const initialState = {
  assessor: {
    attacheCampaignMembers: [],
    availableCount: 0,
    assignments: {
      list: [],
      availableCount: 0,
      pagination: {
        page_size: 10,
        page: 1,
      },
      pages_count: 1,
      filters: {},
      sortBy: {
        sort_by: ORGANIZATION_ASSESSOR_ASSIGNMENTS_SORT
      },
    },
    notes: {
      list: [],
      sortBy: {},
      availableCount: 0,
      pages_count: 0,
      extra_info: {},
      pagination: {
        page_size: 10,
        page: 1,
      },
      filters: {
        assignedUser: {},
        hashtags: [],
        assessments: [],
        registrations: [],
      }
    },
    // campaignMemberNotesList: [],
  },
  addresses: {
    list: [],
    availableCount: 0,
  },
  campaigns: {
    list: [],
    availableCount: 0
  },
  all_campaigns: {
    listSchemas: {
      default: [],
      card: [],
      table: []
    },
    availableCount: 0,
    selectedItem: {},
    pages_count: 1,
    pagination: {
      [campaignsViewType.MY_CAMPAIGNS]: {
        page_size: 10,
        page: 1,
      },
      [campaignsViewType.ASSIGNED_CAMPAIGNS]: {
        page_size: 10,
        page: 1,
      },
      [campaignsViewType.PUBLIC_CAMPAIGNS]: {
        page_size: 10,
        page: 1,
      }
    },
    pageViewType: pageView.CARD_VIEW,
    sortBy: {
      sort_by: ORGANIZATION_CAMPAIGN_CARDS_SORT
    },
    filters: {}, // is it used?
    modals: {
      [campaignsModals.CREATE]: {
        isVisible: false,
        data: {},
        isPageBarAddButtonVisible: false
      },
      [campaignsModals.UPDATE]: {
        isVisible: false,
        data: {}
      },
      [campaignsModals.DELETE]: {
        isVisible: false,
        data: {}
      }
    }
  },
  members: {
    list: [],
    availableCount: 0,
    selectedItem: {},
    pages_count: 1,
    pagination: {
      page_size: 10,
      page: 1
    },
    sortBy: {
      sort_by: ORGANIZATION_MEMBERS_SORT
    }
  },
  invites: {
    list: [],
    pagination: {
      page_size: 10,
      page: 1,
    },
    pages_count: 1,
    availableCount: 0,
  },
  registrations: {
    list: [],
    pageViewType: pageView.CARD_VIEW,
    selectedItem: null,
    count: null,
    pages_count: 1,
    pagination: {
      page_size: 10,
      page: 1,
    },
    tables: {
      defaultTable: {
        sortBy: ""
      }
    },
    confirmations: {
      list: [],
      selectedItem: null,
      count: null,
      pages_count: 1,
      pagination: {
        page_size: 10,
        page: 1,
      },
      sortBy: ORGANIZATION_REGISTRATION_CONFIRMATIONS_SORT,
    },
    survey: {
      id: null,
      body: null,
      answers: null,
      prepared_answers: null,
      slug_name: null
    },
    flows: {
      list: [],
    }
  },
  usersRoles: {
    list: [],
    availableCount: 0,
    selectedItem: {},
    pages_count: 1,
    pagination: {
      page_size: 10,
      page: 1
    },
    allPermissions: [],
  },
  assessments: {
    list: [],
    availableCount: 0,
  },
  log: {
    list: [],
    availableCount: 0,
    filters: {
      action_type: "",
      created_by_id: ""
    },
    loading: false
  },
  surveyTemplates: {
    list: [],
    availableCount: 0,
    selectedItem: {},
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: ORGANIZATION_SURVEY_TEMPLATE_SORT
    },
  },
  assessmentItems: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: ORGANIZATION_ASSESSMENTS_ITEMS_SORT
    },
  },
  assessmentItemsCustomFields: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: ORGANIZATION_ASSESSMENTS_CUSTOM_FIELDS_SORT
    },
  },
  relatedUsers: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {},
  },
  selectedAssessmentItemsCustomField: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.FETCH_ORGANIZATION_REGISTRATION_EXTENDED_BY_ID): {
      const { response: { results: { relations: { flows, survey: { content: { body } } } } } } = action.payload;

      return {
        ...state,
        registrations: {
          ...state.registrations,
          survey: {
            ...state.registrations.survey,
            body
          },
          flows: {
            ...state.registrations.flows,
            list: flows.list,
          }
        }
      };
    }

    case types.SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_SORT: {
      return {
        ...state,
        registrations: {
          ...state.registrations,
          confirmations: {
            ...state.registrations.confirmations,
            sortBy: action.payload
          }
        }
      };
    }

    // REGISTRATIONS -> SET VIEW TYPE
    case types.SET_ORGANIZATION_REGISTRATIONS_VIEW_TYPE: {
      return {
        ...state,
        registrations: {
          ...state.registrations,
          pageViewType: action.payload
        }
      };
    }

    // REGISTRATIONS -> SET PAGINATION
    case types.SET_ORGANIZATION_REGISTRATIONS_PAGINATION: {
      const { page, pageSize } = action.payload;
      return {
        ...state,
        registrations: {
          ...state.registrations,
          pagination: {
            page: page || state.registrations.pagination.page || 1,
            page_size: pageSize || state.registrations.pagination.page_size || 10
          }
        }
      };
    }

    // REGISTRATIONS -> RESET PAGINATION
    case types.RESET_ORGANIZATION_REGISTRATIONS_PAGINATION: {
      return {
        ...state,
        registrations: {
          ...state.registrations,
          pagination: {
            page: 1,
            page_size: 10
          }
        }
      };
    }

    // REGISTRATION SUBMISSIONS -> SET PAGINATION
    case types.SET_ORGANIZATION_REGISTRATION_SUBMISSIONS_PAGINATION: {
      const { page, pageSize } = action.payload;
      return {
        ...state,
        registrations: {
          ...state.registrations,
          confirmations: {
            ...state.registrations.confirmations,
            pagination: {
              page: page || state.registrations.confirmations.pagination.page || 1,
              page_size: pageSize || state.registrations.confirmations.pagination.page_size || 10
            }
          }
        }
      };
    }

    case types.SET_ORGANIZATION_REGISTRATIONS_SORT_BY: {
      const { tableType, sortBy } = action.payload;
      return {
        ...state,
        registrations: {
          ...state.registrations,
          tables: {
            ...state.registrations.tables,
            [tableType]: {
              ...state.registrations.tables[tableType],
              sortBy: sortBy
            }
          }
        }
      };
    }

    case success(types.FETCH_ORGANIZATION_REGISTRATION_ANSWERS): {
      const { response: { results: { id, relations, content: { answers, sjs_answers } } } } = action.payload;

      return {
        ...state,
        registrations: {
          ...state.registrations,
          survey: {
            ...state.registrations.survey,
            id,
            answers,
            prepared_answers: sjs_answers,
            slug_name: relations.organization.slug_name
          }
        }
      };
    }

    case types.CLEAR_ORGANIZATION_REGISTRATION_ANSWERS: {
      return {
        ...state,
        registrations: {
          ...state.registrations,
          survey: {
            id: null,
            body: null,
            answers: null,
            prepared_answers: null
          }
        }
      };
    }

    case types.CLEAR_ORGANIZATION_REGISTRATION: {
      return {
        ...state,
        registrations: {
          ...state.registrations,
          list: [],
          selectedItem: null,
          count: null,
          pages_count: 1,
          pagination: {
            page_size: 10,
            page: 1
          },
          confirmations: {
            ...state.registrations.confirmations,
            list: [],
            selectedItem: null,
            count: null,
            pages_count: 1,
            pagination: {
              page_size: 10,
              page: 1
            },
          }
        }
      };
    }
    case  types.CLEAR_ORGANIZATION_REGISTRATION_ITEM_MANAGE: {
      return {
        ...state,
        registrations: {
          ...state.registrations,
          selectedItem: null,
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_REGISTRATION_FEED_LIST): {
      const { response: { count, page_size, page, results, pages_count } } = action.payload;

      return {
        ...state,
        registrations: {
          ...state.registrations,
          confirmations: {
            ...state.registrations.confirmations,
            list: results,
            count: count,
            pages_count,
            pagination: {
              page_size,
              page
            }
          }
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_REGISTRATION_LIST): {
      const { response: { count, page_size, page, results, pages_count } } = action.payload;

      return {
        ...state,
        registrations: {
          ...state.registrations,
          list: results,
          count: count,
          pages_count,
          pagination: {
            page_size,
            page
          }
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_REGISTRATION_ITEM_MANAGE): {
      const { response } = action.payload;

      return {
        ...state,
        registrations: {
          ...state.registrations,
          selectedItem: { ...response.results.item, flows: response.results.flows.list },
        }
      };
    }
    // GET COLOR THEME
    case success(types.GET_ORGANIZATION_THEME) :
    case success(types.SET_ORGANIZATION_THEME) : {
      const { response } = action.payload;
      return {
        ...state,
        // TODO: REMOVE after switching to API v2
        color_scheme: response.results
      };
    }
    case success(types.FETCH_ORGANIZATION_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        ...response.results
      };
    }
    case types.RESET_ORGANIZATION_SELECTED_ITEM: {
      // TODO:  configure default items for reset
      return {
        ...state,
        id: "",
        name: "",
        description: "",
      };
    }
    case success(types.FETCH_ORGANIZATION_ADDRESSES_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        addresses: {
          ...state.addresses,
          list: response.results,
          availableCount: response.count
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_ASSESSMENTS): {
      const { response } = action.payload;
      return {
        ...state,
        assessments: {
          list: response.results,
          availableCount: response.count
        }
      };
    }
    // CAMPAIGNS
    case success(types.FETCH_ORGANIZATION_CAMPAIGNS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          list: response.results,
          availableCount: response.count
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_CAMPAIGNS_CARDS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          listSchemas: {
            ...state.all_campaigns.listSchemas,
            card: response.results
          },
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_CAMPAIGNS_TABLE_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          listSchemas: {
            ...state.all_campaigns.listSchemas,
            table: response.results
          },
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case types.TOGGLE_ORGANIZATION_CAMPAIGN_MODAL: {
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          modals: {
            ...state.all_campaigns.modals,
            [action.payload.target]: {
              ...state.all_campaigns.modals?.[action.payload.target],
              isVisible: action.payload.isVisible,
              data: action.payload?.data || {}
            }
          }
        }
      };
    }
    case types.SET_ORGANIZATION_CAMPAIGN_PAGE_VIEW_TYPE: {
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          pageViewType: action.payload
        }
      };
    }
    case types.SET_ORGANIZATION_CAMPAIGN_PAGE_BAR_ADD_BUTTON_VISIBLE: {
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          modals: {
            ...state.all_campaigns.modals,
            [campaignsModals.CREATE]: {
              ...state.all_campaigns.modals?.[campaignsModals.CREATE],
              isPageBarAddButtonVisible: action.payload
            }
          }
        }
      };
    }
    // ASSESSMENTS
    case success(types.FETCH_ORGANIZATION_CAMPAIGNS_ASSESSMENTS): {
      const { response } = action.payload;
      return {
        ...state,
        assessments: {
          ...state.assessments,
          list: response.results
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_MEMBERS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          list: response.results,
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case types.SET_ORGANIZATION_MEMBERS_SORT_ORDER: {
      return {
        ...state,
        members: {
          ...state.members,
          sortBy: {
            ...action.payload,
          }
        }
      };
    }
    case types.SET_ORGANIZATION_MEMBERS_PAGINATION: {
      return {
        ...state,
        members: {
          ...state.members,
          pagination: {
            ...state.members.pagination,
            ...action.payload
          }
        }
      };
    }
    // CAMPAIGNS
    case types.SET_ORGANIZATION_CAMPAIGNS_CARDS_PAGINATION: {
      if (!action?.payload?.target) return state;

      const target = action.payload.target;
      delete action.payload.target;

      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          pagination: {
            ...state.all_campaigns.pagination,
            [target]: {
              ...state.all_campaigns.pagination[target],
              ...action.payload
            }
          }
        }
      };
    }

    case types.SET_ORGANIZATION_CAMPAIGNS_SORT_ORDER: {
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          sortBy: {
            ...action.payload
          }
        }
      };
    }

    case types.RESET_ORGANIZATION_CAMPAIGNS_PAGINATION: {
      if (!action?.payload?.target) {
        return {
          ...state,
          all_campaigns: {
            ...state.all_campaigns,
            pagination: {
              [campaignsViewType.MY_CAMPAIGNS]: {
                page_size: 10,
                page: 1,
              },
              [campaignsViewType.ASSIGNED_CAMPAIGNS]: {
                page_size: 10,
                page: 1,
              },
              [campaignsViewType.PUBLIC_CAMPAIGNS]: {
                page_size: 10,
                page: 1,
              }
            }
          }
        };
      }
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          pagination: {
            ...state.all_campaigns.pagination,
            [action.payload.target]: {
              page_size: 10,
              page: 1,
            },
          }
        }
      };
    }

    case types.SET_ORGANIZATION_CAMPAIGNS_FILTERS: {
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          filters: {
            ...action.payload,
          },
        },
      };
    }
    case types.RESET_ORGANIZATION_MEMBERS_PAGINATION: {
      return {
        ...state,
        members: {
          ...state.members,
          pagination: {
            page_size: 10,
            page: 1,
            pages_count: 1,
          }
        }
      };
    }
    case types.RESET_ORGANIZATION_MEMBERS_SORT_ORDER: {
      return {
        ...state,
        members: {
          ...state.members,
          sortBy: {
            sort_by: ORGANIZATION_MEMBERS_SORT,
          }
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        surveyTemplates: {
          ...state.surveyTemplates,
          list: response.results,
          availableCount: response.count
        }
      };
    }
    case types.SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_PAGINATION: {
      return {
        ...state,
        surveyTemplates: {
          ...state.surveyTemplates,
          pagination: {
            ...state.surveyTemplates.pagination,
            ...action.payload.data
          }
        }
      };
    }
    case types.SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_ORDER: {
      return {
        ...state,
        surveyTemplates: {
          ...state.surveyTemplates,
          sortBy: action.payload.data
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_SURVEY_TEMPLATE_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        surveyTemplates: {
          ...state.surveyTemplates,
          selectedItem: response.results
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_MEMBER_INVITATIONS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        invites: {
          ...state.invites,
          list: response.results,
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page
          }
        }
      };
    }

    case types.SET_ORGANIZATION_MEMBERS_INVITATIONS_PAGINATION: {
      return {
        ...state,
        invites: {
          ...state.invites,
          pagination: {
            ...state.invites.pagination,
            ...action.payload
          }
        }
      };
    }

    case success(types.FETCH_ORGANIZATION_USERS_ROLES_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          list: response.results,
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page
          }
        }
      };
    }
    case types.SET_ORGANIZATION_USERS_ROLES_PAGINATION: {
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          pagination: {
            ...state.usersRoles.pagination,
            ...action.payload
          }
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_USER_ROLE_MULTI_LANG_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          selectedItem: response.results,
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_PERMISSIONS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          allPermissions: response.results,
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR): {
      const { response } = action.payload;

      return {
        ...state,
        assessor: {
          ...state.assessor,
          assignments: {
            ...state.assessor.assignments,
            list: response.results,
            availableCount: response.count,
            pages_count: response.pages_count,
            pagination: {
              page_size: response.page_size,
              page: response.page,
            }
          },
          attacheCampaignMembers: [...response.results],
          availableCount: response.count
        }
      };
    }

    case types.SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_ORDER : {
      return {
        ...state,
        assessor: {
          ...state.assessor,
          assignments: {
            ...state.assessor.assignments,
            sortBy: {
              ...state.assessor.assignments.sortBy,
              ...action.payload
            }
          },
        }
      };
    }

    case types.SET_CAMPAIGN_MEMBERS_LIST_FOR_ASSESSOR_PAGINATION : {
      return {
        ...state,
        assessor: {
          ...state.assessor,
          assignments: {
            ...state.assessor.assignments,
            pagination: {
              ...state.assessor.assignments.pagination,
              ...action.payload
            }
          },
        }
      };
    }

    case success(types.FETCH_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR): {
      const { response } = action.payload;

      return {
        ...state,
        members: {
          ...state.members,
          selectedItem: response.results,
        }
      };
    }
    case types.RESET_CAMPAIGN_MEMBER_EXTEND_INFO_RELATED_TO_ASSESSOR: {
      return {
        ...state,
        members: {
          ...state.members,
          selectedItem: {}
        }
      };
    }

    case success(types.FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR): {
      const { response } = action.payload;
      return {
        ...state,
        assessor: {
          ...state.assessor,
          notes: {
            ...state.assessor.notes,
            list: response.results,
            availableCount: response.count,
            pages_count: response.pages_count,
            extra_info: response.extra_info,
            pagination: {
              page_size: response.page_size,
              page: response.page,
            }
          },
        }
      };
    }
    case success(types.DELETE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR): {
      const { question_id } = action.meta;

      return {
        ...state,
        assessor: {
          ...state.assessor,
          notes: {
            ...state.assessor.notes,
            list: state.assessor.notes.list
              .map(item => item.question_id === question_id
                ? ({
                  ...item,
                  note: {
                    currentVersion: null,
                  }
                }) : ({
                  ...item,
                })
              )
          }
        }
      };
    }
    case success(types.DELETE_ORGANIZATION_CAMPAIGN_CARD): {
      return {
        ...state,
        all_campaigns: {
          ...state.all_campaigns,
          listSchemas: {
            ...state.all_campaigns.listSchemas,
            card: state.all_campaigns.listSchemas.card.filter(item => item.id !== action.meta.requestAction.meta)
          }
        }
      };
    }
    case success(types.UPDATE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR): {
      const { question_id, note } = action.meta;

      return {
        ...state,
        assessor: {
          ...state.assessor,
          notes: {
            ...state.assessor.notes,
            list: state.assessor.notes.list
              .map(item => item.question_id === question_id
                ? ({
                  ...item,
                  note: {
                    ...note,
                  }
                }) : ({
                  ...item,
                })
              )
          }
        }
      };
    }
    case success(types.CREATE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR): {
      const { question_id, note } = action.meta;

      return {
        ...state,
        assessor: {
          ...state.assessor,
          notes: {
            ...state.assessor.notes,
            list: state.assessor.notes.list
              .map(item => item.question_id === question_id
                ? ({
                  ...item,
                  note: {
                    ...note,
                  }
                }) : ({
                  ...item,
                })
              )
          }
        }
      };
    }
    case types.SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_PAGINATION: {
      return {
        ...state,
        assessor: {
          ...state.assessor,
          notes: {
            ...state.assessor.notes,
            pagination: {
              ...state.assessor.notes.pagination,
              ...action.payload
            }
          },
        }
      };
    }
    case types.SET_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR_FILTERS: {
      return {
        ...state,
        assessor: {
          ...state.assessor,
          notes: {
            ...state.assessor.notes,
            filters: {
              ...state.assessor.notes.filters,
              ...action.payload
            }
          },
        }
      };
    }
    case types.SET_AUDITOR_NOTES_SORT_ORDER: {
      return {
        ...state,
        assessor: {
          ...state.assessor,
          notes: {
            ...state.assessor.notes,
            sortBy: action.payload
          }
        }
      };
    }
    case types.CLEAN_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBERS_FOR_ASSESSOR: {
      return {
        ...state,
        assessor: {
          ...state.assessor,
          attacheCampaignMembers: [],
        }
      };
    }
    case types.FETCH_ORGANIZATION_LOG: {
      return {
        ...state,
        log: {
          ...state.log,
          loading: true
        }
      };
    }
    case success(types.FETCH_ORGANIZATION_LOG): {
      const { response } = action.payload;

      return {
        ...state,
        log: {
          ...state.log,
          list: [...state.log.list, ...response.results],
          availableCount: response.count,
          previous: response.previous,
          next: response.next,
          page: response.page,
          page_size: response.page_size,
          loading: false
        }
      };
    }
    case types.CLEAN_ORGANIZATION_LOG: {
      return {
        ...state,
        log: {
          ...state.log,
          list: [],
          availableCount: 0,
          previous: null,
          next: null,
          page: 1,
          page_size: 10,
        }
      };
    }

    case success(types.FETCH_ORGANIZATION_LOG_ACTION_TYPES): {
      const { response } = action.payload;
      return {
        ...state,
        log: {
          ...state.log,
          actionTypes: response.results
        }
      };
    }

    case types.UPDATE_ORGANIZATION_LOG_FILTERS: {
      return {
        ...state,
        log: {
          ...state.log,
          filters: action.payload
        }
      };
    }
    case types.CLEAN_ORGANIZATION_LOG_FILTERS: {
      return {
        ...state,
        log: {
          ...state.log,
          filters: {
            action_type: "",
            created_by_id: ""
          }
        }
      };
    }

    case success(types.FETCH_ASSESSMENT_ITEMS): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;

      return {
        ...state,
        assessmentItems: {
          ...state.assessmentItems,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_ASSESSMENT_ITEMS_PAGINATION: {
      return {
        ...state,
        assessmentItems: {
          ...state.assessmentItems,
          pagination: {
            ...state.assessmentItems.pagination,
            ...action.payload.data,
          },
        }
      };
    }
    case types.SET_ASSESSMENT_ITEMS_FILTERS: {
      return {
        ...state,
        assessmentItems: {
          ...state.assessmentItems,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_ASSESSMENT_ITEMS_SORT_ORDER: {
      return {
        ...state,
        assessmentItems: {
          ...state.assessmentItems,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    case success(types.FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELDS): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;

      return {
        ...state,
        assessmentItemsCustomFields: {
          ...state.assessmentItemsCustomFields,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_PAGINATION: {
      return {
        ...state,
        assessmentItemsCustomFields: {
          ...state.assessmentItemsCustomFields,
          pagination: {
            ...state.assessmentItemsCustomFields.pagination,
            ...action.payload.data,
          },
        }
      };
    }
    case types.SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_FILTERS: {
      return {
        ...state,
        assessmentItemsCustomFields: {
          ...state.assessmentItemsCustomFields,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_ASSESSMENT_ITEMS_CUSTOM_FIELDS_SORT_ORDER: {
      return {
        ...state,
        assessmentItemsCustomFields: {
          ...state.assessmentItemsCustomFields,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    case success(types.FETCH_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO): {
      const {
        response: {
          results,
        }
      } = action.payload;

      return {
        ...state,
        selectedAssessmentItemsCustomField: results,
      };
    }
    case types.CLEAN_ASSESSMENT_ITEMS_CUSTOM_FIELD_INFO: {
      return {
        ...state,
        selectedAssessmentItemsCustomField: {},
      };
    }

    case types.SET_ORGANIZATION_ASSESSMENTS: {
      const { response: { count, results } } = action.payload;
      return {
        ...state,
        assessments: {
          ...state.assessments,
          list: results,
          availableCount: count
        }
      };
    }

    case success(types.FETCH_USERS_RELATED_TO_ORGANIZATION): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;

      return {
        ...state,
        relatedUsers: {
          ...state.relatedUsers,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }

    case error(types.FETCH_ORGANIZATION_ASSESSMENTS):
    case error(types.FETCH_ORGANIZATION_SURVEY_TEMPLATE_EXTENDED_INFO):
    case error(types.FETCH_ORGANIZATIONS_LIST):
    case error(types.FETCH_ORGANIZATION_ADDRESSES_LIST):
    case error(types.FETCH_ORGANIZATION_EXTENDED_INFO):
    case error(types.CREATE_ORGANIZATION_ADDRESS):
    case error(types.UPDATE_ORGANIZATION_ADDRESS):
    case error(types.DELETE_ORGANIZATION_ADDRESS):
    case error(types.CREATE_ORGANIZATION_CAMPAIGN):
    case error(types.UPDATE_ORGANIZATION_CAMPAIGN):
    case error(types.DELETE_ORGANIZATION_CAMPAIGN):
    case error(types.DELETE_ORGANIZATION):
    case error(types.UPDATE_ORGANIZATION):
    case error(types.DELETE_ORGANIZATION_MEMBER):
    case error(types.CREATE_ORGANIZATION_MEMBER):
    case error(types.UPDATE_ORGANIZATION_MEMBER):
    case error(types.FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST):
    case error(types.CREATE_ORGANIZATION_SURVEY):
    case error(types.DELETE_ORGANIZATION_SURVEY):
    case error(types.FETCH_ORGANIZATION_USERS_ROLES_LIST):
    case error(types.FETCH_ORGANIZATION_USER_ROLE_MULTI_LANG_INFO):
    case error(types.FETCH_ORGANIZATION_PERMISSIONS_LIST):
    case error(types.FETCH_ORGANIZATION_ATTACHED_CAMPAIGN_MEMBER_NOTES_LIST_FOR_ASSESSOR):
    case error(types.DELETE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR):
    case error(types.UPDATE_ASSESSMENT_ANSWER_NOTE_BY_ASSESSOR):
    case error(types.FETCH_ORGANIZATION_LOG): {
      return {
        ...state,
        errors: action.payload.response.data.errors || action.payload.message,
      };
    }
    default:
      return state;
  }
};
