import React from "react";
import { Pie, PieChart, Cell, Legend } from "recharts";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import ChartSvg from "../../Components/Chart";
import CardBox from "../CardBox/CardBox";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";
import checkTotalValue from "../../../../../../utils/checkTotalValueForChart";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    display: "flex",
    flexDirection: "row",
    padding: "3px",
    border: "1px solid #24B2B2",
    overflow: "hidden",
  },
  chartWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  chartTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "8pt",
    backgroundColor: "#F5F5F5"
  },
  chartTitleText: {
    fontWeight: "medium",
    fontSize: "8pt",
    paddingVertical: "5pt",
    color: "#333333",
  }
});

const COLORS = ["#255e91", "#4472c4", "#a5a5a5", "#5b9bd5", "#264478", "#636363"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill='black' textAnchor={x > cx ? "start" : "end"} dominantBaseline='central'>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

// Create Component
const Card3 = ({ data }) => {
  const heading = useIntlMessageToString("reports.card3Title");
  const projectsByContractType = useIntlMessageToString("reports.projectsByContractType");
  const clientsType = useIntlMessageToString("reports.clientsType");
  const contractTypeDistribution = data.find((question) => question.question_id === "159135310618575") || {};
  const clientTypeDistribution = data.find((question) => question.question_id === "1591353106185752") || {};
  const { value: contracts = [] } = contractTypeDistribution;
  const { value: clients = [] } = clientTypeDistribution;

  return (
    <CardBox heading={heading}>
      <View style={styles.cardBody}>
        <View style={styles.chartWrapper}>
          <View style={[styles.chartTitle, { borderRight: "1px solid #FFFFFF" }]}>
            <Text style={styles.chartTitleText}>{projectsByContractType}:</Text>
          </View>
          <View>
            <ChartSvg>
              <PieChart width={250} height={280}>
                <Pie
                  dataKey='value'
                  nameKey='title'
                  data={checkTotalValue(contracts)}
                  cy="35%"
                  innerRadius={40}
                  outerRadius={80}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  isAnimationActive={false}
                >
                  {contracts.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index] || "#5b9bd5"}/>
                  ))}
                </Pie>
                <Legend
                  align='left'
                  layout='vertical'
                  verticalAlign='bottom'
                />
              </PieChart>
            </ChartSvg>
          </View>
        </View>
        <View style={styles.chartWrapper}>
          <View style={styles.chartTitle}>
            <Text style={styles.chartTitleText}>{clientsType}:</Text>
          </View>
          <View>
            <ChartSvg>
              <PieChart width={250} height={280}>
                <Pie
                  dataKey='value'
                  nameKey='title'
                  data={checkTotalValue(clients)}
                  cy="35%"
                  innerRadius={40}
                  outerRadius={80}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  isAnimationActive={false}
                >
                  {clients.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index] || "#5b9bd5"}/>
                  ))}
                </Pie>
                <Legend
                  align='left'
                  layout='vertical'
                  verticalAlign='bottom'
                />
              </PieChart>
            </ChartSvg>
          </View>
        </View>
      </View>
    </CardBox>
  );
};

export default Card3;
