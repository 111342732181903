import React from "react";
import * as Sentry from "@sentry/react";
// COMPONENTS
import FallbackComponent from "./components/FallbackComponent/FallbackComponent";


const ErrorBoundary = ({
                         children,
                         showDialog,
                         dialogOptions,
                         onError,
                         onMount,
                         onReset,
                         onUnmount,
                         beforeCapture
                       }) => {
  return (
    <Sentry.ErrorBoundary
      showDialog={showDialog}
      dialogOptions={dialogOptions}
      onError={onError}
      onMount={onMount}
      onReset={onReset}
      onUnmount={onUnmount}
      beforeCapture={beforeCapture}
      fallback={FallbackComponent}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;