import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const powerBIDataset = {
  [actionsConstants.PowerBIDatasetListView]: simpleValidator,
  [actionsConstants.PowerBIDatasetCreate]: simpleValidator,
  [actionsConstants.PowerBIDatasetModify]: simpleValidator,
  [actionsConstants.PowerBIDatasetLockUnlock]: simpleValidator,
  [actionsConstants.PowerBIDatasetDelete]: simpleValidator,
};

export default powerBIDataset;