import React from "react";
import { Provider } from "react-redux";
// STORE
import { store } from "../../../../store";
// COMPONENTS
import Document from "./Document";


// Create Document Component
const MainPDF = (props) => {
  return (
    <Provider store={store}>
      <Document {...props} />
    </Provider>
  );
};

export default MainPDF;
