import React from "react";
// COMPONENTS
import MyProfileContent from "./components/MyProfileContent";
// CONSTANTS
import { TAB_PROFILE } from "../../constants";
// STYLES
import styles from "./styles.module.scss";

const ProfileEditorContent = ({
                                profileInfo,
                                currentTab
                              }) => {
  return (
    <div className={styles.ContentContainer}>
      {currentTab === TAB_PROFILE && (
        <MyProfileContent profileInfo={profileInfo}/>
      )}
    </div>
  );
};

export default ProfileEditorContent;
