export const ACCOUNTS_TAG = "accounts";
export const ACCOUNT_TAG = "account";
export const ACCOUNT_FOR_MANAGE_TAG = "account_for_manage";
export const PAGE_TAG = "page";

export const PAGE_TARGET = "info";
export const PAGE_ACCOUNT_TARGET = "info";

export const ACCOUNTS_TARGET = "homepage";
export const ACCOUNT_TARGET = "general-info";
export const ACCOUNT_MEMBERS_TARGET = "members-me";
export const ADD_ACCOUNT_TARGET = "common-create";
export const UPDATE_ACCOUNT_TARGET = "common-update";

export const accountsQueryArg = {
  KEY: "key",
  PAGE: "page",
  PAGE_SIZE: "page_size",
  SORT_BY: "order_by",
  IDS: "ids",
  IS_ARCHIVED: "is_archived",
  EXCLUDE_IDS: "exclude_ids",
  ID: "id",
  ACCOUNT_ID: "account_id",
};