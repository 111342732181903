import * as types from "./types";
import { baseApiUrl } from "@/configs/MainConfig";
import { requestActionCreator } from "@/utils/requestActionCreator";
import { authService } from "@/shared/services/auth.service";

//new actions
export const fetchAssessmentExtendedInfoByKey = (key) => requestActionCreator(
  types.FETCH_ASSESSMENT_EXTENDED_INFO_BY_KEY,
  `${baseApiUrl}/campaign/assessment/by-key/?key=${key}`,
  "GET",
  { ...authService.getAuthHeaders() },
);


export const createAssessmentAnswersByKey = (dataSend, key) => requestActionCreator(
  types.CREATE_ASSESSMENT_ANSWER_BY_KEY,
  `${baseApiUrl}/campaign/assessment/non-auth-answer/by-key/?key=${key}`,
  "POST",
  { ...authService.getAuthHeaders() },
  dataSend,
);