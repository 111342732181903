import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const powerBIReport = {
  [actionsConstants.PowerBIReportListView]: simpleValidator,
  [actionsConstants.PowerBIReportCreate]: simpleValidator,
  [actionsConstants.PowerBIReportModify]: simpleValidator,
  [actionsConstants.PowerBIReportLockUnlock]: simpleValidator,
  [actionsConstants.PowerBIReportDelete]: simpleValidator,
};

export default powerBIReport;