// CONSTANTS
import { actionsConstants } from "@/shared/hooks/policyValidation/constants/actionsConstants";
// HOOKS
import usePolicyValidation from "@/shared/hooks/policyValidation/usePolicyValidation";

const useRegistrationFormPermissions = ({ regFormId, createdBy } = {}) => {
  const canViewRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormListView,
  });

  const canCreateRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormCreate,
  });

  const canModifyRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormModify,
    lookup: {
      id: regFormId,
      owner: createdBy
    }
  });

  const canDeleteRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormDelete,
    lookup: {
      id: regFormId,
      owner: createdBy
    }
  });

  const canGenerateIframeRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormGenerateIframe,
    lookup: {
      id: regFormId,
      owner: createdBy
    }
  });

  const canPublishAnswersRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormPublishPublicReport,
    lookup: {
      id: regFormId,
      owner: createdBy
    }
  });

  const canDownloadCsvRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormDownloadReportCSV,
    lookup: {
      id: regFormId,
      owner: createdBy
    }
  });

  const canDownloadXlsxRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormDownloadReportXLSX,
    lookup: {
      id: regFormId,
      owner: createdBy
    }
  });

  const canDownloadJsonRegistrationForm = usePolicyValidation({
    action: actionsConstants.RegistrationFormDownloadReportJSON,
    lookup: {
      id: regFormId,
      owner: createdBy
    }
  });

  return {
    canViewRegistrationForm,
    canCreateRegistrationForm,
    canModifyRegistrationForm,
    canDeleteRegistrationForm,
    canPublishAnswersRegistrationForm,
    canGenerateIframeRegistrationForm,
    canDownloadCsvRegistrationForm,
    canDownloadXlsxRegistrationForm,
    canDownloadJsonRegistrationForm
  };
};

export default useRegistrationFormPermissions;