import React, { useState } from "react";
import classnames from "classnames";
// COMPONENTS
import AsrInput from "@/components/Common/FormComponentsNew/AsrInput";
// STYLES
import styles from "../styles.module.scss";

const ValidateUserPasswordFormFields = ({ fmk }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleIsPasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const iconContainerStyles = classnames({
    [styles.EyeIconContainer]: true,
    [styles.CloseEye]: isPasswordVisible
  });

  const iconTooltipStyles = classnames({
    [styles.EyeIconTooltip]: true,
    [styles.CloseEye]: isPasswordVisible
  });

  return (
    <div className={styles.PasswordFieldContainer}>
      <AsrInput
        autoComplete="off"
        name="password"
        value={fmk.values.password || ""}
        type={isPasswordVisible ? "text" : "password"}
        classes={{
          input: styles.PasswordInput,
          inputIconContainer: iconContainerStyles,
          inputIconTooltip: iconTooltipStyles
        }}
        inputIcon={{
          iconName: isPasswordVisible ? "eye_slashed" : "eye",
          position: "right",
          onClick: toggleIsPasswordVisible,
          tooltip: isPasswordVisible ? "appCommon.hide" : "appCommon.show"
        }}
      />
    </div>
  );
};

export default ValidateUserPasswordFormFields;
