import React from "react";
// COMPONENTS
import UserPreview from "./components/UserPreview";
import SideMenuTabs from "./components/SideMenuTabs";
import ProfileEditorHeader from "@/components/Profile/Modals/ProfileEditorModal/components/ProfileEditorHeader";
// STYLES
import styles from "./styles.module.scss";


const ProfileEditorSideMenu = ({
                                 profileInfo,
                                 currentTab,
                                 changeCurrentTab,
                                 isMinimizedView,
                                 closeModal,
                                 isFetchLoading
                               }) => {
  return (
    <div className={styles.EditorSideMenuContainer}>

      {isMinimizedView && (
        <ProfileEditorHeader
          closeModal={closeModal}
          currentTab={currentTab}
          isMinimizedView={isMinimizedView}
        />
      )}

      <UserPreview
        profileInfo={profileInfo}
        isFetchLoading={isFetchLoading}
      />

      {!isMinimizedView && (
        <SideMenuTabs
          currentTab={currentTab}
          changeCurrentTab={changeCurrentTab}
        />
      )}
    </div>
  );
};

export default ProfileEditorSideMenu;
