import React, {memo} from "react";
import classnames from "classnames";

import "./styles.scss";


const AsrGlobalIcon = memo(({className, styles, defaultClassWrapper = true}) => {
    const classWrapper = classnames({
        "asr-global-icon--wrapper": defaultClassWrapper,
        [className]: className
    });
    return (
        <div className={classWrapper} style={styles}>
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.75 8.00049C13.75 11.4523 10.9518 14.2505 7.5 14.2505M13.75 8.00049C13.75 4.54871 10.9518 1.75049 7.5 1.75049M13.75 8.00049H1.25M7.5 14.2505C4.04822 14.2505 1.25 11.4523 1.25 8.00049M7.5 14.2505C9.0633 12.539 9.95172 10.318 10 8.00049C9.95172 5.68301 9.0633 3.46196 7.5 1.75049M7.5 14.2505C5.9367 12.539 5.04828 10.318 5 8.00049C5.04828 5.68301 5.9367 3.46196 7.5 1.75049M1.25 8.00049C1.25 4.54871 4.04822 1.75049 7.5 1.75049"
                    stroke="#24B3B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
});

export default AsrGlobalIcon;