import React from "react";
// MATERIAL UI
import { Alert } from "@mui/material";
// UTILS
import IntlMessages from "../../../../../utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";


const FallbackComponent = () => {
  return (
    <div className={styles.ErrorBoundary}>
      <Alert variant="filled" severity="error">
        <IntlMessages id="appModule.errorBoundary"/>
      </Alert>
    </div>
  );
};

export default FallbackComponent;