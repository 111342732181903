import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// UTILS
import _ from "lodash";
// COMPONENTS
import NoteButton from "./components/NoteButton";
// HOOKS
import useNoteTooltip from "../../hooks/useNoteTooltip";
// CONSTANTS
import {
  QUESTION_CONTROL_MODE,
  QUESTION_CONTROL_ICONS
} from "@/shared/constants/surveyJs";
// STORE
import { getAssessmentUpdatedQuestion } from "@/store/Campaign/selectors";
import { resetAssessmentQuestionState } from "@/store/Campaign/actions/actions";
import { getUpdatedRegistrationQuestion } from "@/store/SurveyRegistration/selectors";
// STYLES
import styles from "./styles.module.scss";


const SurveyHeaderActions = memo(({
                                    assessmentType,
                                    isVisible = true,
                                    currentNote,
                                    mode,
                                    question_id,
                                    noteHandler,
                                    locale
                                  }) => {
  const [noteImg, setNoteImg] = useState("");
  const [noteTooltip, setNoteTooltip] = useState("");
  const currentNoteFilled = currentNote && !_.isEmpty(currentNote);

  const dispatch = useDispatch();

  const updatedQuestion = useSelector(getAssessmentUpdatedQuestion);
  const updatedRegistrationQuestion = useSelector(getUpdatedRegistrationQuestion);

  const [tooltipAssessorNote, tooltipUserNote] = useNoteTooltip(locale);

  const setQuestionState = useCallback((currentNote) => {
    if (currentNoteFilled && currentNote?.is_visible) {
      const img = mode === QUESTION_CONTROL_MODE.user ? QUESTION_CONTROL_ICONS.NOTE_EXIST_SHARED : QUESTION_CONTROL_ICONS.NOTE_AUDITOR_SHARED;
      /*
      * (canModifyAssessorNotes ? tooltipAssessorNote.editShared : tooltipAssessorNote.viewShared)
      * */
      const tooltip = mode === QUESTION_CONTROL_MODE.user ? tooltipUserNote.viewShared : tooltipAssessorNote.viewShared;
      setNoteImg(img);
      setNoteTooltip(tooltip);
    } else if (currentNoteFilled && !currentNote?.is_visible) {
      const img = mode === QUESTION_CONTROL_MODE.user ? QUESTION_CONTROL_ICONS.NOTE_EXIST_PRIVATE : QUESTION_CONTROL_ICONS.NOTE_AUDITOR_PRIVATE;
      /*
      *  (canModifyAssessorNotes ? tooltipAssessorNote.edit : tooltipAssessorNote.view)
      * */
      const tooltip = mode === QUESTION_CONTROL_MODE.user ? tooltipUserNote.view : tooltipAssessorNote.view;
      setNoteImg(img);
      setNoteTooltip(tooltip);
    } else {
      const img = mode === QUESTION_CONTROL_MODE.user ? QUESTION_CONTROL_ICONS.NOTE_ADD : QUESTION_CONTROL_ICONS.NOTE_AUDITOR_ADD;
      const tooltip = mode === QUESTION_CONTROL_MODE.user ? tooltipUserNote.add : tooltipAssessorNote.add;
      setNoteImg(img);
      setNoteTooltip(tooltip);
    }
  }, [mode, tooltipAssessorNote, tooltipUserNote, currentNoteFilled]);

  useEffect(() => {
    setQuestionState(currentNote);
  }, [currentNote, setQuestionState]);


  useEffect(() => {
    if (assessmentType === "registration") {
      const registrationNote = updatedRegistrationQuestion?.auditor_note ? {
        ...updatedRegistrationQuestion?.auditor_note,
        include_in_export: updatedRegistrationQuestion?.auditor_note?.config?.include_in_export,
        is_visible: updatedRegistrationQuestion?.auditor_note?.config?.is_visible,
      } : null;

      if (registrationNote && question_id.toString() === updatedRegistrationQuestion?.question_id.toString()) {
        setQuestionState(registrationNote);
      }
    } else {
      const assessmentNote = updatedQuestion?.note;
      if (assessmentNote && question_id.toString() === updatedQuestion?.question_id.toString()) {
        setQuestionState(assessmentNote);
      }
    }
  }, [assessmentType, question_id, setQuestionState, updatedQuestion, updatedRegistrationQuestion]);

  useEffect(() => {
    return () => {
      dispatch(resetAssessmentQuestionState());
    };
  }, [dispatch]);

  const handleOnNote = () => {
    if (noteHandler) {
      noteHandler();
    }
  };

  if (!isVisible) return null;
  return (
    <div className={styles.SurveyHeaderActions}>
      <NoteButton
        noteImg={noteImg}
        noteTooltip={noteTooltip}
        handler={handleOnNote}
      />
    </div>
  );
});

export default SurveyHeaderActions;