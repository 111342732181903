import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";

const EvolutionaryContentStage1 = () => {
  return (
    <>
      <div>
        <IntlMessages id="auth.pleaseCheckYourInboxForConfirmation"/>.
      </div>
      <div>
        <IntlMessages id="auth.weWillWaitForYouHere"/>!
      </div>
    </>
  );
};

export default EvolutionaryContentStage1;
