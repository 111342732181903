import * as types from "./types";
import { v4 as uuidv4 } from "uuid";
// CONSTANTS
import { systemModals } from "@/shared/constants/system";

const initialState = {
  notifications: [],
  modals: {
    [systemModals.CONTACT_US]: {
      show: false
    },
    [systemModals.SIGN_IN]: {
      show: false
    },
    [systemModals.SIGN_UP]: {
      show: false,
      campaign: {
        name: undefined
      },
      user: {
        email: undefined
      }
    },
    [systemModals.RESTORE_PASSWORD]: {
      show: false,
    },
    [systemModals.ENTER_CODE]: {
      show: false
    },
    [systemModals.PROFILE]: {
      show: false
    }
  }
};

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_SYSTEM_NOTIFICATION: {
      const notification = {
        ...action.payload,
        id: uuidv4()
      };
      return {
        ...state,
        notifications: [
          ...state.notifications,
          notification
        ]
      };
    }

    case types.REMOVE_SYSTEM_NOTIFICATION: {
      const result = [];
      state.notifications.length > 0 && state.notifications.map(notification => {
        if (notification.id !== action.payload.id) {
          result.push(notification);
        }
      });
      return {
        ...state,
        notifications: result
      };
    }

    case types.TOGGLE_COMMON_MODAL_SHOW_STATUS: {
      const modalKey = action.payload?.modalKey;
      if (!modalKey) return state;

      let nextShowStatus = action.payload?.show;
      if (nextShowStatus === undefined) {
        nextShowStatus = !(state.modals?.[modalKey]?.show || false);
      }

      return {
        ...state,
        modals: {
          ...state.modals,
          [modalKey]: {
            ...(state.modals?.[modalKey] || {}),
            show: nextShowStatus
          }
        }
      };
    }

    case types.UPDATE_SIGN_UP_MODAL_STATE: {
      let nextShowStatus = action.payload?.show;
      if (nextShowStatus === undefined) {
        nextShowStatus = !(state.modals?.[systemModals.SIGN_UP]?.show || false);
      }
      const campaignName = action.payload?.campaignName || undefined;
      const userEmail = action.payload?.userEmail || undefined;

      return {
        ...state,
        modals: {
          ...state.modals,
          [systemModals.SIGN_UP]: {
            ...(state.modals?.[systemModals.SIGN_UP] || {}),
            show: nextShowStatus,
            campaign: {
              ...(state.modals?.[systemModals.SIGN_UP]?.campaign || {}),
              name: campaignName
            },
            user: {
              ...(state.modals?.[systemModals.SIGN_UP]?.user || {}),
              email: userEmail
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default systemReducer;
