import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
import { cookieConsentService } from "@/utils/cookies";
import { appAnalyticsService } from "@/utils/analytics";
// STORE
import { useGetSystemBlockInfoItemQuery } from "@/store/services/infoblocks/rtk";
import { SYSTEM_BLOCK_KEYS } from "@/store/services/infoblocks/constants";
// HOOKS
import useFetchLangDependency from "@/shared/hooks/useFetchLangDependency";
// STYLES
import styles from "./styles.module.scss";


const CookieBanner = () => {
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(false);

  const isDirectlySiteOpened = window === window.parent;
  const { data: cookieConsentApiData, isLoading, refetch } = useGetSystemBlockInfoItemQuery({
    targetKey: SYSTEM_BLOCK_KEYS.POLICY_CONSENT
  }, {
    skip: (
      cookieConsentService.getIsActualVersion()
      || !isDirectlySiteOpened
      || !showBanner
    )
  });
  useFetchLangDependency(refetch);

  useEffect(() => {
    // CHECK IF SITE IN IFRAME
    if (!isDirectlySiteOpened) return;

    // CHECK IF PREVIOUSLY PROCESS BANNED HAS THE SAME VERSION WITH CURRENT ACTIVE VERSION
    if (cookieConsentService.getIsActualVersion()) return;

    // // CHECK IF location.pathname NOT IN BLACKLIST OF SHOWING BANNER
    // if (cookieConsentService.checkIfPageInBlackList(location?.pathname)) return;

    setShowBanner(true);
  }, [location?.pathname, isDirectlySiteOpened]);

  const onAccept = () => {
    cookieConsentService.doAccept();
    appAnalyticsService.doInit();
    setShowBanner(false);
  };

  const onReject = () => {
    cookieConsentService.doReject();
    setShowBanner(false);
  };

  if (!cookieConsentApiData || isLoading || !showBanner) return null;
  if (!cookieConsentApiData?.content?.description) return null;
  return (
    <div className={styles.Banner}>
      <div className={styles.BannerContentContainer}>
        <div
          className={styles.BannerTextBlock}
          dangerouslySetInnerHTML={{ __html: cookieConsentApiData.content.description }}
        />

        <div className={styles.BannerActionsBlock}>
          <div
            className={`${styles.ActionButton} ${styles.AcceptButton}`}
            onClick={onAccept}
          >
            <IntlMessages id="organizationMainPage.accept"/>
          </div>

          <div
            className={`${styles.ActionButton} ${styles.RejectButton}`}
            onClick={onReject}
          >
            <IntlMessages id="organizationMainPage.reject"/>
          </div>
        </div>

      </div>
    </div>
  );
};

export default CookieBanner;
