import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Autocomplete from "@mui/material/Autocomplete";
import { get } from "lodash";
// MATERIAL UI
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
// ICONS
import icons from "@/shared/UI/SvgIcons";
// UTILS
import { useIntlMessageToString } from "@/utils/IntlMessages";
// COMPONENTS
import ListBoxComponentNew from "@/shared/components/FormComponents/ListboxComponent/ListBoxComponentNew";
import AsrAutocompleteMultipleRenderInput
  from "../components/AsrAutocompleteMultipleRenderInput/AsrAutocompleteMultipleRenderInput";
// STYLES
import "./styles.scss";
import styles from "@/shared/autocompletes/components/AutocompleteTablePanelWrapper/styles.module.scss";


const AsrAutocompleteSingle = ({
                                 disabled,
                                 selectedItem,
                                 withTooltip,
                                 selectedItemTooltip = "name",
                                 handleAutocompleteChange,
                                 options,
                                 renderOption,
                                 loading,
                                 placeholder,
                                 selectedLabel,
                                 className,
                                 paramToDisplay,
                                 loadNextPage,
                                 resetOnClose,
                                 handleInputChange,
                                 rowHeight,
                                 rowPadding,
                                 withLoadingIndicator = true,
                                 renderInputSelectedLabel = "name",
                                 optionSelected = "question_id",
                                 customRenderInput,
                                 itemEmptyChoicePlaceholder,
                                 popupIcon = "autocomplete_dropdown",
                                 withDefaultFilterOptions = false,
                                 classes = {
                                   root: "",
                                   selectFieldRoot: "",
                                   inputRoot: "",
                                   input: "",
                                   clearIndicatorDirty: "",
                                   clearIndicator: "",
                                   popupIndicator: "",
                                   endAdornment: "",
                                   paper: "",
                                   inputLabel: ""
                                 },
                                 hasNextPage,
                                 inputLabel,
                                 inputLabelColon = true,
                                 ...other
                               }) => {
  const [inputValue, setInputValue] = useState("");

  const hoverOpenText = useIntlMessageToString("organizations.open");
  const hoverCloseText = useIntlMessageToString("organizations.close");
  const noOptionsText = useIntlMessageToString("organizations.noOptions");

  const autocompleteClassName = classnames({
    "asr-autocomplete-single": true,
    "asr-autocomplete-single__disabled": disabled,
    [className]: !!className,
  });

  const tooltipClass = classnames({
    [styles.AutocompleteTablePanelWrapperTooltip]: true,
    "asr-classic-tooltip": true,
    "asr-classic-tooltip--br-0": true,
    "d-none": !selectedItem?.[selectedItemTooltip]
  });

  const inputLabelClassName = classnames({
    "styled-form-input__label": true,
    [classes?.inputLabel]: classes?.inputLabel
  });

  const selectFieldRootClass = classnames({
    "asr-table-autocomplete-text-field": true,
    [classes?.selectFieldRoot]: Boolean(classes?.selectFieldRoot)
  });


  useEffect(() => {
    if (!Object.keys(selectedItem || {}).length) {
      setInputValue("");
    }
  }, [selectedItem]);

  const handleOptionSelected = (option) => {
    return option[optionSelected];
  };

  const handleOnChange = (e, value, reason) => {
    handleAutocompleteChange(e, value, reason);

  };

  const handleOptionLabel = (option) => {
    if (paramToDisplay) {
      return paramToDisplay
        .map((param) => get(option, param))
        .filter(Boolean)
        .join(" | ");
    }
    return (option.name || "");
  };

  const onInputChange = (e, value, reason) => {
    if (value) {
      placeholder && setInputValue(value);
    } else {
      setInputValue(e?.target.value);
    }

    if (reason === "clear") {
      setInputValue("");
    }

    if (handleInputChange) {
      handleInputChange(e, value, reason);
    }
  };

  const handleOnClose = () => {
    if (resetOnClose) {
      resetOnClose();
    }
    setInputValue("");
  };
  const _renderInput = customRenderInput ? customRenderInput : (
    <AsrAutocompleteMultipleRenderInput
      itemEmptyChoicePlaceholder={itemEmptyChoicePlaceholder}
      label={selectedLabel}
      selectedItems={selectedItem && get(selectedItem, renderInputSelectedLabel)}
    />
  );

  const tooltipTitle = () => {
    if (withTooltip) {
      return selectedItem?.[selectedItemTooltip] || "";
    }

    return "";
  };

  const defaultRenderInput = (params) => (
    <Tooltip
      title={tooltipTitle()}
      placement="top"
      arrow
      classes={{
        popper: styles.AutocompleteTablePanelWrapperPopper,
        tooltip: tooltipClass,
        arrow: "asr-classic-tooltip-arrow"
      }}
    >
      <>
        <TextField
          {...params}
          placeholder={placeholder ? placeholder : null}
          variant="outlined"
          onChange={onInputChange}
          classes={{
            root: selectFieldRootClass
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {(withLoadingIndicator && loading) ? (
                  <CircularProgress
                    classes={{
                      root: "select-loading-indicator"
                    }}
                    color="primary" size={15}/>
                ) : null}
                {(!placeholder && !inputValue) && (
                  _renderInput
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      </>
    </Tooltip>
  );

  const customProps = {};
  if (!withDefaultFilterOptions) {
    customProps.filterOptions = (x) => x;
  }

  return (
    <div className={autocompleteClassName}>
      {inputLabel && (
        <div className={inputLabelClassName}>
          {inputLabel}
          {inputLabelColon ? ":" : null}
        </div>
      )}

      <Autocomplete
        {...other}
        {...customProps}
        disabled={disabled}
        value={selectedItem}
        onChange={handleOnChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        clearOnBlur={false}
        openText={hoverOpenText}
        closeText={hoverCloseText}
        noOptionsText={noOptionsText}
        ListboxComponent={ListBoxComponentNew}
        ListboxProps={{
          rowHeight,
          rowPadding,
          loadNextPage,
          hasNextPage,
          inputValue,
          isNextPageLoading: loading
        }}
        popupIcon={icons[popupIcon]}
        getOptionDisabled={() => loading}
        onClose={handleOnClose}
        isOptionEqualToValue={handleOptionSelected}
        getOptionLabel={handleOptionLabel}
        renderOption={renderOption}
        options={options}
        loading={loading}
        classes={{
          root: `select__root ${classes.root}`,
          inputRoot: `select ${classes.inputRoot}`,
          input: `select__input ${classes.input}`,
          clearIndicator: `select__clear-indicator ${classes.clearIndicatorDirty}`,
          endAdornment: `${classes.endAdornment}`,
          popupIndicator: `select__popup-indicator ${classes.popupIndicator}`,
          paper: `asr-autocomplete-paper ${classes.paper}`
        }}
        renderInput={defaultRenderInput}
      />
    </div>
  );
};

export default AsrAutocompleteSingle;