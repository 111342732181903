import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const accountInvitation = {
  [actionsConstants.AccountInvitationListView]: simpleValidator,
  [actionsConstants.AccountInvitationCreate]: simpleValidator,
  [actionsConstants.AccountInvitationModify]: allOwnedIdsValidator,
  [actionsConstants.AccountInvitationResend]: allOwnedIdsValidator,
  [actionsConstants.AccountInvitationDelete]: allOwnedIdsValidator,
};

export default accountInvitation;