import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import IntlMessages from "../../../../utils/IntlMessages";

const SideNavPages = ({ isGlobal = true, isStaffView = false, closeNavCallBack, path = null }) => {
  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const tittleIntl = isGlobal ? "sidenav.staticEditorGlobal" : "sidenav.staticEditor";
  const defaultPath = isGlobal ? "/static-editor" : `${match.url}/static-editor`;
  const _path = path ? path : defaultPath;

  return (
      <NavLink
          className={prependedClass}
          to={_path}
          onClick={closeNavCallBack}
      >
        <IntlMessages id={tittleIntl}/>
      </NavLink>
  );
};

SideNavPages.displayName = "SideNavPages";
export default SideNavPages;