import React from "react";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";


// Create styles
const styles = StyleSheet.create({
    sectionBox: {
        width: "100%",
        flexShrink: 0,
    },
    sectionContainer: {
        display: "flex",
        flexDirection: "column",
    },
    sectionHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#7D7C7D",
        padding: "2pt 8pt",
    },
    sectionHeaderText: {
        fontSize: "8pt",
        fontWeight: "medium",
        color: "#FFFFFF"
    },
    sectionBody: {
        flexShrink: 0,
        overflow: "hidden",
    }
});

// Create Component
const CardSection = ({ heading, children, lastChild }) => {
    return (
        <View style={[styles.sectionBox, { marginBottom: lastChild ? 0 : "3px" }]} wrap={false}>
            <View style={styles.sectionContainer}>
                <View style={styles.sectionHeader}>
                    <Text style={[styles.sectionHeaderText]}>
                        {heading}
                    </Text>
                </View>
                <View style={styles.sectionBody}>
                    {children}
                </View>
            </View>
        </View>
    );
};

export default CardSection;
