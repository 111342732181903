import { defaultJSONHeaders } from "@/configs/MainConfig";

class AuthService {
  getAuthHeaders() {
    const token = localStorage.getItem("userToken");
    const headers = defaultJSONHeaders();

    if (token) {
      headers["Authorization"] = `Bearer ${ token }`;
    }

    return headers;
  }
}

export const authService = new AuthService();

