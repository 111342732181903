import querystring from "query-string";

/**
 * Transform payload to query params.
 * @param {Object} params
 * @param {Object} params.payload- RTK QueryArgs
 * @param {Object} params.defaultQuery- Default Query Params.
 * @param {string[]=} params.excludeQueryParams- List of parameters that should not be included in the query string.
 */
const transformPayloadToQuery = ({
                                   payload = {},
                                   defaultQuery = {},
                                   excludeQueryParams = []
                                 }) => {
  const payloadQuery = {
    ...defaultQuery
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  if (excludeQueryParams.length) {
    excludeQueryParams.forEach((param) => {
      if (payloadQuery[param]) {
        delete payloadQuery[param];
      }
    });
  }

  const isPayloadQuery = Boolean(Object.keys(payloadQuery).length);

  return !isPayloadQuery ? "" : `?${querystring.stringify(payloadQuery)}`;
};

export default transformPayloadToQuery;
