export const campaignAssessmentsTag = {
  ADD_ASSESSMENT: "add_assessment",
  GET_ASSESSMENT: "get_assessment",
  GET_CAMPAIGN_ASSESSMENTS: "campaign_assessments",
  USERS_ANSWERS: "users-assessments-answers-tag",
};

export const campaignAssessmentsTarget = {
  CARD: "cards-in-campaign",
  TABLE: "table-in-campaign",
  AUDITOR_TABLE: "auditor-table",
  DASHBOARD: "campaign-dashboard",
  POWER_BI_DATASET: "powerbi-dataset"
};

export const campaignAssessmentsQueryArg = {
  TEMPLATE_FILLED: "template_filled"
};