import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import AsrWaitingHandGif from "@/shared/UI/Gifs/AsrWaitingHandGif";
import EvolutionaryContent from "./components/EvolutionaryContent";
// STYLES
import styles from "./styles.module.scss";

const SupportUserVerificationForm = ({
                                       userEmail,
                                       userPassword,
                                       showThankYouTitle = true,
                                       initialStage,
                                       maxStage,
                                       periodBetweenStages
                                     }) => {
  return (
    <div className={styles.SucceedModalContainer}>
      {showThankYouTitle && (
        <div className={styles.TitleText}>
          <IntlMessages id="appCommon.thankYou"/>!
        </div>
      )}

      <div className={styles.ConfirmEmailInfoBlock}>
        <div>
          <IntlMessages id="auth.weNeedToConfirmYourEmail"/>:
        </div>
        <div className={styles.UserEmail}>
          {userEmail}
        </div>
      </div>

      <AsrWaitingHandGif className={styles.WaitingHandGif}/>

      <EvolutionaryContent
        initialStage={initialStage}
        maxStage={maxStage}
        periodBetweenStages={periodBetweenStages}
        userEmail={userEmail}
        userPassword={userPassword}
      />
    </div>
  );
};

export default SupportUserVerificationForm;
