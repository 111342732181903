import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
// COMPONENTS
import { NotificationContent } from "../NotificationContent/NotificationContent";
// STORE
import { getNotifications } from "@/store/System/selectors";
import { removeSystemNotification } from "@/store/System/actions";

export const SystemNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach(({ id, type, text }) => {
      const textList = typeof text === "object" ?
        Object.values(text).filter((text, index, array) => array.indexOf(text) === index) : [text];

      return textList.forEach(text => {
        const key = enqueueSnackbar("", {
          variant: "default",
          autoHideDuration: 3000,
          onClick: () => closeSnackbar(key),
          anchorOrigin: { horizontal: "right", vertical: "top" },
          content: <NotificationContent id={ id } type={ type } text={ text }/>
        });

        dispatch(removeSystemNotification(id));
      });

    });
  }, [dispatch, enqueueSnackbar, closeSnackbar, notifications]);

  return null;
};

export default SystemNotifications;
