import React from "react";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";
import AsrWaitingHandGif from "@/shared/UI/Gifs/AsrWaitingHandGif";

const RestorePasswordSucceedModalForm = ({ userEmail = "---" }) => {
  return (
    <div className={styles.SucceedModalContainer}>
      <div className={styles.TitleText}>
        <IntlMessages id="appCommon.thankYou"/>!
      </div>

      <div className={styles.ConfirmEmailInfoBlock}>
        <div>
          <IntlMessages id="auth.weNeedToConfirmYourEmail"/>:
        </div>
        <div className={styles.UserEmail}>
          {userEmail}
        </div>
      </div>

      <AsrWaitingHandGif className={styles.WaitingHandGif}/>

      <div className={styles.CheckInboxInfoBlock}>
        <div>
          <IntlMessages id="auth.pleaseCheckYourInboxForConfirmation"/>.
        </div>
        <div>
          <IntlMessages id="auth.weWillWaitForYouHere"/>!
        </div>
      </div>
    </div>
  );
};

export default RestorePasswordSucceedModalForm;
