const useInitialValues = ({ personalInfo } = {}) => {
  return {
    email: personalInfo?.email || "",
    firstName: personalInfo?.first_name || "",
    lastName: personalInfo?.last_name || "",
    titleName: personalInfo?.title_name || "",
    newPassword: "",
    repeatNewPassword: ""
  };
};

export default useInitialValues;
