import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// MATERIAL UI
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// HELPERS
import { parseTermsWithFroalaHelper } from "./helpers/parseTerms";
// COMPONENTS
import OpenLinkIcon from "@/shared/UI/Icons/AsrOpenLinkIcon/AsrOpenLinkIcon";
// STORE
import { fetchTerm } from "@/store/Campaign/actions";
import { getFetchTermLoading } from "@/store/Campaign/selectors";
import { getWindowWidth } from "@/store/Settings/selectors";
// STYLES
import styles from "./styles.module.scss";

const SMALL_DEVICE_SIZE = 768;
const LEAVE_DELAY = 200;


const AsrTermBubble = memo(({
                              term,
                              withHeader = true,
                              classes = {
                                tooltip: "",
                                arrow: "",
                                tooltipContent: "",
                                termContainer: "",
                                termHeader: "",
                                term: "",
                                link: ""
                              }
                            }) => {

  const dispatch = useDispatch();
  const loading = useSelector(getFetchTermLoading);
  const windowWidth = useSelector(getWindowWidth);

  const [currentTerm, setCurrentTerm] = useState();
  const [currentElem, setCurrentElem] = useState();
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(false);

  useEffect(() => {
    if (windowWidth > SMALL_DEVICE_SIZE) {
      setIsSmallDevice(false);
    } else {
      setIsSmallDevice(true);
    }
  }, [windowWidth]);

  const fetchData = useCallback(async () => {
    const { payload: { data } } = await dispatch(fetchTerm(term.trim()));
    setCurrentTerm(data);
  }, [dispatch, term]);

  const handleOpen = useCallback(async (e) => {
    setOpen(true);
    if (currentElem) return;
    setError("");
    try {
      setCurrentElem(e.target);

      await fetchData();
    } catch (e) {
      const { payload } = e;
      if (payload?.response?.status === 400) {
        setError((payload?.response?.data.message));
      }
    }
  }, [currentElem, fetchData]);

  const handleOnClose = () => {
    setCurrentElem(undefined);
    setOpen(false);
  };

  // const preparedDescription = parseTermsHelper({
  //   term: currentTerm?.description,
  //   classes
  // });

  // temporary solution. Remove after refactoring on the backend
  const preparedDescription = parseTermsWithFroalaHelper({
    term: currentTerm?.description,
    classes
  });

  const renderTitle = () => {
    return (
      <div className={`${styles.termContainer} ${classes.termContainer}`}>
        {/* HEADER */}
        {withHeader && (
          <div className={`${styles.termHeader} ${classes.termHeader}`}>
            <span className={styles.termHeaderLabel}>{term}</span>
            {(!loading && currentTerm) && (
              <a
                href={`https://bimdictionary.com/${currentTerm?.language}/${currentTerm?.slug}/${currentTerm?.version}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenLinkIcon classes={{ root: styles.openLinkIcon }}/>
              </a>
            )}
          </div>
        )}
        {/* BODY */}
        <div className={`${styles.termHeaderContent} ${classes.tooltipContent}`}>
          {loading ?
            <>
              <Skeleton/>
              <Skeleton animation={false}/>
              <Skeleton animation="wave"/>
            </> :
            error ? error : (
              <>
                {preparedDescription}
              </>
            )
          }
        </div>
      </div>
    );
  };

  const handleClick = async () => {
    if (isSmallDevice) {
      setOpen(true);
      await fetchData();
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip
        open={open}
        disableFocusListener={isSmallDevice}
        disableHoverListener={isSmallDevice}
        disableTouchListener={isSmallDevice}
        arrow
        leaveDelay={LEAVE_DELAY}
        onOpen={handleOpen}
        // placement="top"
        onClose={handleOnClose}
        classes={{
          popper: `${styles.popper} ${classes.popper}`,
          tooltip: `${styles.tooltip} ${classes.tooltip}`,
          arrow: `${styles.arrow} ${classes.arrow}`
        }}
        title={renderTitle()}
      >
        <a className={`title__link ${classes.link}`} onClick={handleClick}>{term}</a>
      </Tooltip>
    </ClickAwayListener>
  );
});

export default AsrTermBubble;