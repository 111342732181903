import * as SurveyCore from "survey-core";

import countriesChoices from "@/shared/constants/countries/surveyJsCountries";
import { QUESTION_TYPES } from "@/shared/constants/surveyJs";


export const countriesWidget = () => {
  // skip if already added
  if (SurveyCore.ComponentCollection.Instance.items.find(item => item.name === QUESTION_TYPES.COUNTRY)) return;

  // add custom question
  SurveyCore.ComponentCollection.Instance.add({
    name: QUESTION_TYPES.COUNTRY,
    title: "Countries",
    iconName: "icon-country",
    questionJSON: {
      "type": "dropdown",
      "placeholder": "Select a country...",
      "choices": countriesChoices,
    },
  });
};