import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// TYPES
import surveyStateTypes from "@/store/SurveyAssessment/surveyStateTypes";
// STORES
import { surveyAssessmentsState } from "@/store/SurveyAssessment/selectors";


const useValueChanged = ({
                           surveyModel,
                           countUnansweredQuestions,
                           sendDataSingular,
                           questionCallback,
                           doOnCurrentPageChanged,
                         }) => {
  const dispatch = useDispatch();
  const { questionData } = useSelector(surveyAssessmentsState);

  const saveQuestionDataState = useCallback((result, options) => {
    const questionName = options.name;
    const qName = options.name?.replace("-Comment", "");
    dispatch({
      type: surveyStateTypes.SET_QUESTION_DATA,
      payload: {
        [questionName]: {
          ...questionData[questionName],
          created_dt: Date.now(),
        },
        [qName]: {
          ...questionData[qName],
          other_text: options?.question?.propertyHash?.comment,
          answer: options?.question?.propertyHash?.value,
          name: options?.question?.propertyHash?.name,
          question_id: options?.question?.propertyHash?.question_id,
          created_dt: Date.now(),
        }
      }
    });

  }, [dispatch, questionData]);

  const handleChange = useCallback((sender, options) => {
    if (sender.isCompleted) return;
    const question = options.question;
    const isValid = !question.hasErrors();
    countUnansweredQuestions(surveyModel.getAllQuestions());
    doOnCurrentPageChanged(sender);
    saveQuestionDataState(sender, options);
    if (sendDataSingular) {
      sendDataSingular({
        currentQuestion: {
          answer: question.value ?? question?.propertyHash?.value,
          ...question?.propertyHash
        },
        isValid
      });
    }

    if (!options.question) return;

    options.question.hasErrors(true);
    if (questionCallback) {
      questionCallback(options.question);
    }
  }, [
    doOnCurrentPageChanged,
    countUnansweredQuestions,
    questionCallback,
    saveQuestionDataState,
    sendDataSingular,
    surveyModel,
  ]);

  return {
    handleChange
  };

};

export default useValueChanged;