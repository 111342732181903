import React, { memo } from "react";
import AsrCloseIcon from "@/shared/UI/Icons/AsrCloseIcon";

import styles from "./styles.module.scss";

const AsrCloseModalButton = memo(({ isVisible, handler, iconClassName, rootClassName }) => {
  return (
      <>
        {isVisible ? (
            <div className={`${styles.headerClose} ${rootClassName}`} onClick={handler} aria-label="close">
              <AsrCloseIcon iconClassName={iconClassName} />
            </div>
        ) : null}
      </>
  );
});

export default AsrCloseModalButton;