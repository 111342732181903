import React, { memo } from "react";
// MATERIAL UI
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// STYLES
import styles from "../../styles.module.scss";
import commonStyles from "../../../../../../../common.module.scss";


const HashtagsInput = memo(({
                              locale,
                              name,
                              setFieldValue,
                              values,
                              classes = {
                                container: ""
                              }
                            }) => {

  const hashtagsLabel = getIntlMessageToStringLocal(locale, "assessments.hashtags");
  const tooltip = getIntlMessageToStringLocal(locale, "assessments.hashtagsTooltip");
  const placeholder = getIntlMessageToStringLocal(locale, "assessments.hashtagsInputPlaceholder");


  const handleOnChange = (e) => {
    const value = e?.target?.value;
    setFieldValue("hashtags", value);
  };
  return (
      <div
          className={`${styles.formContainerFooterCheckBox} ${styles.noteHashtagsInputContainer} ${classes.container}`}>
        <Tooltip
            title={tooltip}
            placement="top-start"
            classes={{
              popper: commonStyles.verifiedButtonTooltipPopper,
              tooltip: `${commonStyles.verifiedButtonTooltip} ${commonStyles.verifiedButtonTooltipHashtags}`,
              arrow: commonStyles.verifiedButtonArrow
            }}
            arrow
        >
          <div
              className={`${styles.formContainerFooterCheckBoxItemLabel} ${styles.noteHashtagsLabel}`}>{hashtagsLabel}</div>
        </Tooltip>

        <TextField
            classes={{
              root: styles.noteHashtagsInputField
            }}
            placeholder={placeholder}
            onChange={handleOnChange}
            name={name}
            value={values[name]}
            variant="outlined"/>
      </div>
  );
});

export default HashtagsInput;