import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
// HELPERS
import NestedLinks from "@/components/SideNav/helpers/NestedLinks/NestedLinks";


const TemplatesNestedLinks = ({
                                props,
                                canSeeAssessmentTemplates,
                                canSeeAssessmentItems,
                                canSeeAssessmentCustomFields
                              }) => {
  const match = useRouteMatch();

  const list = [
    {
      to: `${match.url}/assessment-templates#templates`,
      hash: "#templates",
      onClick: props.closeNavCallBack,
      isVisible: canSeeAssessmentTemplates,
      label: "templates.templatesList"
    },
    {
      to: `${match.url}/assessment-templates#items`,
      hash: "#items",
      onClick: props.closeNavCallBack,
      isVisible: canSeeAssessmentItems,
      label: "templates.itemsList"
    },
    {
      to: `${match.url}/assessment-templates#customFields`,
      hash: "#customFields",
      onClick: props.closeNavCallBack,
      isVisible: canSeeAssessmentCustomFields,
      label: "sidenav.customFields"
    },
  ];
  return (
      <Route path={`${match.path}/assessment-templates`}>
        <NestedLinks links={list}/>
      </Route>
  );
};

export default TemplatesNestedLinks;