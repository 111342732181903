import React from "react";

export default {
  "statement_sets": <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00016 1.33325L1.3335 4.66658L8.00016 7.99992L14.6668 4.66658L8.00016 1.33325Z" fill="#4F4F4F"
          stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.3335 11.3333L8.00016 14.6666L14.6668 11.3333" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M1.3335 8L8.00016 11.3333L14.6668 8" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>
};