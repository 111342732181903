import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// COMPONENTS
import AuditorsNestedLinks from "./components/AuditorsNestedLinks/AuditorsNestedLinks";


const SideNavAuditors = ({
                           isStaffView,
                           props,
                           isAuditor,
                           canSeeAuditorsNotes,
                           canSeeAuditorAssignments
                         }) => {
  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  if (!isAuditor && !(canSeeAuditorAssignments && canSeeAuditorsNotes)) return null;

  return (
    <>
      <NavLink
        className={prependedClass}
        to={`${match.url}/assessor`}
        onClick={props.closeNavCallBack}
      >
        <IntlMessages id="sidenav.assessor"/>
      </NavLink>
      <AuditorsNestedLinks
        props={props}
        canSeeAuditorAssignments={canSeeAuditorAssignments}
        canSeeAssessorNotes={canSeeAuditorsNotes}/>
    </>
  );
};

export default SideNavAuditors;