import React from "react";
// COMPONENTS
import AsrSaveButton from "@/shared/components/Buttons/AsrSaveButton";
// STYLES
import styles from "../styles.module.scss";


const AsrLightModalFooter = ({ classes, actionFooterButtons }) => {

  const getButtonType = (buttonType) => buttonType || "default";

  const getButtonClass = (footerButton) => {
    let className = "";
    if (footerButton.type === "plainText") {
      className += styles.TextFooterButton;
    } else {
      className += styles.DefaultFooterButton;
    }

    if (footerButton.classes?.button) {
      className += ` ${footerButton.classes?.button}`;
    }
    return className;
  };

  return (
    <div className={classes.footerContainer}>
      {actionFooterButtons.map((footerButton, index) => (
        <React.Fragment key={index}>
          {getButtonType(footerButton.type) === "plainText" ? (
            <div
              className={getButtonClass(footerButton)}
              onClick={footerButton.onClick}
            >
              {footerButton.title}
            </div>
          ) : (
            <AsrSaveButton
              classNameWrapper={footerButton.classes?.wrapper}
              classes={{
                buttonRoot: getButtonClass(footerButton)
              }}
              handler={footerButton.onClick}
            >
              {footerButton.title}
            </AsrSaveButton>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AsrLightModalFooter;
