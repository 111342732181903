import React, {memo} from "react";
import classnames from "classnames";

import "./style.scss";


const AsrLockIcon = memo(({className, styles}) => {
    const classWrapper = classnames({
        "asr-lock-icon--wrapper": true,
        [className]: className
    });

    return (
        <div className={classWrapper} style={styles}>
            <svg width="22" height="22" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.4375 6.21875V4.65625C2.4375 3.62025 2.84905 2.62668 3.58161 1.89411C4.31418 1.16155 5.30775 0.75 6.34375 0.75C7.37975 0.75 8.37332 1.16155 9.10589 1.89411C9.83845 2.62668 10.25 3.62025 10.25 4.65625V6.21875C10.6644 6.21875 11.0618 6.38337 11.3549 6.6764C11.6479 6.96942 11.8125 7.36685 11.8125 7.78125V11.6875C11.8125 12.1019 11.6479 12.4993 11.3549 12.7924C11.0618 13.0854 10.6644 13.25 10.25 13.25H2.4375C2.0231 13.25 1.62567 13.0854 1.33265 12.7924C1.03962 12.4993 0.875 12.1019 0.875 11.6875V7.78125C0.875 7.36685 1.03962 6.96942 1.33265 6.6764C1.62567 6.38337 2.0231 6.21875 2.4375 6.21875ZM8.6875 4.65625V6.21875H4V4.65625C4 4.03465 4.24693 3.43851 4.68647 2.99897C5.12601 2.55943 5.72215 2.3125 6.34375 2.3125C6.96535 2.3125 7.56149 2.55943 8.00103 2.99897C8.44057 3.43851 8.6875 4.03465 8.6875 4.65625Z" fill="#EB5757"/>
            </svg>
        </div>

    );
});

export default AsrLockIcon;