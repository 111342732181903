import { SnackbarProvider } from "notistack";
import React from "react";
// COMPONENTS
import SystemNotifications from "./components/SystemNotifications";
// STYLES
import styles from "./styles.module.scss";


const NotificationsProvider = () => {
  return (
    <SnackbarProvider classes={{ containerRoot: styles.containerRoot }} maxSnack={3}>
      <SystemNotifications/>
    </SnackbarProvider>
  );
};

export default NotificationsProvider;
