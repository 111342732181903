import { actionsConstants } from "../../constants/actionsConstants";
import campaignsValidator from "../validatorsHandlers/campaignsValidator";


const assessmentAct = {
  [actionsConstants.AssessmentActAnswerNoteView]: campaignsValidator,
  [actionsConstants.AssessmentActAnswerNoteCreate]: campaignsValidator,
  [actionsConstants.AssessmentActAnswerNoteModify]: campaignsValidator,
  [actionsConstants.AssessmentActAnswerNoteLockUnlock]: campaignsValidator,
  [actionsConstants.AssessmentActAnswerNoteDelete]: campaignsValidator,
  [actionsConstants.AssessmentActAnswerAnalyticsView]: campaignsValidator,
  [actionsConstants.AssessmentActAnswerVerify]: campaignsValidator,
  [actionsConstants.AssessmentActAnswerModify]: campaignsValidator,
  [actionsConstants.AssessmentActAccessLockUnlock]: campaignsValidator,
};

export default assessmentAct;