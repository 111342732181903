import React, { useMemo } from "react";
// MATERIAL UI
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// STYLES
import styles from "../../styles.module.scss";
import commonStyles from "../../../../../../../common.module.scss";


const FormFooterActions = ({
                             locale,
                             isFroalaEditor,
                             isValid,
                             isNoteEmpty,
                             handleCancel,
                             handleDelete,
                             handleEdit,
                             values,
                             canCreateAssessorNotes,
                             canModifyAssessorNotes,
                             canRemoveAssessorNotes,
                             tooltipNoteIsLocked,
                             tooltipPlacement = "top"
                           }) => {
  const cancelBtnLabel = getIntlMessageToStringLocal(locale, "appModule.cancel");
  const deleteBtnLabel = getIntlMessageToStringLocal(locale, "appModule.delete");
  const saveBtnLabel = getIntlMessageToStringLocal(locale, "appModule.save");
  const addBtnLabel = getIntlMessageToStringLocal(locale, "appModule.add");
  const editBtnLabel = getIntlMessageToStringLocal(locale, "appModule.edit");

  const isDeleteDisabled = useMemo(() => {
    return !values["text"];
  }, [values]);

  return (
      <div className={styles.formContainerFooterActionButtons}>
        {/* Cancel */}
        {isFroalaEditor && (
            <Button
                type="button"
                variant="outlined"
                // disabled={disabled || loading}
                className={`${styles.saveButton} ${styles.cancelButton}`}
                onClick={handleCancel}
            >
                <span className={styles.label}>
                  {cancelBtnLabel}
                </span>
            </Button>
        )}

        {(!isFroalaEditor && !isNoteEmpty) && (
            <Tooltip
                title={tooltipNoteIsLocked}
                placement={tooltipPlacement}
                classes={{
                  popper: commonStyles.verifiedButtonTooltipPopper,
                  tooltip: `${commonStyles.verifiedButtonTooltip}`,
                  arrow: commonStyles.verifiedButtonArrow
                }}
                arrow
            >
              <div>
                <Button
                    type="button"
                    variant="outlined"
                    disabled={values?.is_locked || !canRemoveAssessorNotes || isDeleteDisabled}
                    className={`${styles.saveButton} ${styles.deleteButton}`}
                    onClick={handleDelete}
                >
                  <span className={styles.label}>{deleteBtnLabel}</span>
                </Button>
              </div>
            </Tooltip>
        )}
        {/* SAVE /  EDIT */}
        {isFroalaEditor && (
            <Button
                variant="outlined"
                type="submit"
                disabled={(!isValid)}
                className={styles.saveButton}
            >
                    <span className={styles.label}>
                      {saveBtnLabel}
                    </span>
            </Button>
        )}
        {!isFroalaEditor && (
            <Tooltip
                title={tooltipNoteIsLocked}
                placement={tooltipPlacement}
                classes={{
                  popper: commonStyles.verifiedButtonTooltipPopper,
                  tooltip: `${commonStyles.verifiedButtonTooltip}`,
                  arrow: commonStyles.verifiedButtonArrow
                }}
                arrow
            >
              <div>
                <Button
                    variant="outlined"
                    className={styles.saveButton}
                    disabled={values?.is_locked || (!isNoteEmpty ? !canModifyAssessorNotes : !canCreateAssessorNotes)}
                    onClick={handleEdit}
                >
                    <span className={styles.label}>
                      {!isNoteEmpty ? editBtnLabel : addBtnLabel}
                    </span>
                </Button>
              </div>
            </Tooltip>
        )}
      </div>
  );
};

export default FormFooterActions;