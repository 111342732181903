import React from "react";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import CardBox from "../CardBox/CardBox";
import QuestionAnswer from "../../Components/QuestionAnswer";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    padding: "3px",
    border: "1px solid #24B2B2",
    display: "flex",
    flexShrink: 0
  },
  cardNoContent: {
    fontWeight: "medium",
    fontSize: "12pt",
    padding: "8pt 13pt",
    color: "#333333"
  }
});

// Create Component
const Card10 = ({ data }) => {
  const heading = useIntlMessageToString("reports.card10Title");
  const noContent = useIntlMessageToString("reports.noContent");

  const objectivesQuestion = data.find((question) => question.question_id === "1591353106185759") || {};

  const {
      value: objectives = [],
  } = objectivesQuestion;

  return (
    <CardBox heading={heading}>
      {objectives.length > 0
        ? (
          <View style={styles.cardBody}>
            <QuestionAnswer
              question={objectivesQuestion}
            />
          </View>
        ) : (
          <Text style={styles.cardNoContent}>{noContent}</Text>
        )}
    </CardBox>
  );
};

export default Card10;
