export default [
  "A01",
  "A02",
  "A03",
  "A04",
  "A05",
  "A06",
  "A07",
  "A08",
  "A09",
  "C01",
  "C02",
  "C03",
  "C04",
  "C05",
  "F01",
  "F02",
  "F03",
  "F04",
  "F05",
  "I01",
  "I02",
  "I03",
  "I04",
  "I05",
  "I06",
  "I07",
  "M01",
  "M02",
  "M03",
  "M04",
  "M05",
  "M06",
  "O01",
  "O02",
  "O03",
  "O04",
  "O05",
  "O06",
  "O07",
  "O08",
  "R01",
  "R02",
  "R03",
  "R04",
  "R05",
  "R06",
  "R07",
  "S01",
  "S02",
  "S03",
  "S04",
  "S05",
  "T01",
  "T02",
  "T03",
  "T04",
  "T05",
  "T06",
  "T07",
  "T08",
  "T09"
]