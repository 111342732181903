import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './styles.scss';

const CheckboxWithTooltip = ({ name, checked, label, tooltip, onChange }) => {
    return (
        <div className='checkbox-tooltip__wrapper'>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={onChange}
                        name={name}
                        classes={{
                            root: 'checkbox',
                            checked: 'checkbox-checked',
                        }}
                    />
                }
                label={label}
            />
            <div className='help-icon__wrapper'>
                <i className="zmdi zmdi-help-outline help-icon"/>
                <div className='checkbox-tooltip'>
                    {tooltip}
                </div>
            </div>
        </div>
    );
}

export default CheckboxWithTooltip;