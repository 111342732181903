import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    button: {
        background: "#E1C123",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "6px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#242323",
        minWidth: "175px",
        height: "50px",
        marginBottom: "16px",
        border: "none",
        transition: "0.2s all",
        "&:hover": {
            background: "#ffdc3f",
        },
        "&:focus": {
            background: "#c4a927",
            transition: "0.2s all",
        }
    },
}));

const ButtonForm = ({ children, isSubmitting, type }) => {
    const classes = useStyles();

    return (
        <button
            type={type}
            disabled={isSubmitting}
            className={classes.button}
        >
            {children}
        </button>
    );
};

ButtonForm.propTypes = {
    value: PropTypes.string,
    isSubmitting: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

export default ButtonForm;