// CONSTANTS
import statusConstants from "@/shared/constants/statuses";


/**
 * Extracting progress status of assessment in the way of defining progress status based on multiple factors
 *
 * @param {object} progressDetails data with detailed info regarding assessment status in relation to user
 * @return {string} status of assessment for showing it to user:
 * NOT_AVAILABLE || NOT_STARTED || IN_PROGRESS || COMPLETED
 */
export const getProgressStatusForUser = ({progressDetails}) => {
  if (!progressDetails) return statusConstants.NOT_AVAILABLE;

  const {
    user_assessment_status: overallStatus,
    user_progress_status: progressStatus,
  } = progressDetails;

  if (overallStatus === statusConstants.CLOSED) {
    if ([statusConstants.IN_PROGRESS, statusConstants.COMPLETED].includes(progressStatus)) {
      return progressStatus;
    }
    return statusConstants.NOT_AVAILABLE;
  }
  if ([
    statusConstants.NOT_AVAILABLE,
    statusConstants.NOT_STARTED,
    statusConstants.IN_PROGRESS,
    statusConstants.COMPLETED
  ].includes(overallStatus)) {
    return overallStatus;
  }

  return statusConstants.NOT_AVAILABLE;
};

export const getProgressStatusForPermittedUser = ({progressDetails}) => {
  if (!progressDetails) return statusConstants.NOT_AVAILABLE;

  const {
    user_assessment_status: overallStatus,
    user_progress_status: progressStatus,
  } = progressDetails;
  if (overallStatus === statusConstants.LOCKED) {
    if (progressStatus === statusConstants.NOT_STARTED) return statusConstants.NOT_AVAILABLE;
  }
  return progressStatus;
};

/**
 *
 * @param {object} progressDetails data with detailed info regarding assessment status in relation to user
 * @returns {string}
     CLOSED
     || LOCKED
     || COMPLETED
     || NOT_STARTED
     || IN_PROGRESS
 */
export const getAssessmentStatusForUser = ({progressDetails}) => {
  if (!progressDetails) return statusConstants.LOCKED;
  const {
    user_assessment_status: overallStatus,
    user_progress_status: progressStatus,
    user_availability: accessibility
  } = progressDetails;

  if (overallStatus === statusConstants.CLOSED) {
    return statusConstants.CLOSED;
  }

  if (overallStatus === statusConstants.NOT_AVAILABLE) {
    return statusConstants.LOCKED;
  }

  if (progressStatus === statusConstants.COMPLETED) {
    return statusConstants.COMPLETED;
  }

  if (progressStatus === statusConstants.NOT_STARTED && accessibility === statusConstants.AVAILABLE) {
    return statusConstants.NOT_STARTED;
  }

  if (progressStatus === statusConstants.IN_PROGRESS && accessibility === statusConstants.AVAILABLE) {
    return statusConstants.IN_PROGRESS;
  }

  if (accessibility === statusConstants.LOCKED && progressStatus === statusConstants.NOT_STARTED) {
    return statusConstants.LOCKED;
  }

  if (accessibility === statusConstants.LOCKED && progressStatus !== statusConstants.COMPLETED) {
    return statusConstants.COMPLETED;
  }

  if (accessibility === statusConstants.LOCKED) {
    return statusConstants.LOCKED;
  }
  return statusConstants.LOCKED;
};

/**
 * define if assessment is available to be managed by user (passing, updating, etc)
 *
 * @param {object} progressDetails data with detailed info regarding assessment status in relation to user
 * @return {boolean} true || false
 */
export const getAccessStatusForUser = ({progressDetails}) => {
  if (!progressDetails) return false;
  const {user_availability: accessibility} = progressDetails;

  return [
    statusConstants.AVAILABLE,
    statusConstants.FORCED_AVAILABLE
  ].includes(accessibility);
};

export const getAccessStatusForPermittedUser = ({progressDetails}) => {
  const progressStatus = getProgressStatusForPermittedUser({progressDetails});

  const {
    is_blocked_by_lower_rank: isBlockedByLowerRank,
    is_blocked_by_registration: isBlockedByRegistration
  } = progressDetails;

  const isBlockedByAnything = isBlockedByRegistration || isBlockedByLowerRank;

  return !isBlockedByAnything && [
    statusConstants.IN_PROGRESS,
    statusConstants.COMPLETED
  ].includes(progressStatus);
};
