const transformPayloadToFormData = (payload) => {
  const formData = new FormData();
  Object.keys(payload)
    .forEach(key => {
      if (payload[key]?.type) {
        formData.append(key, payload[key]);
      } else if (typeof payload[key] === "object") {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    });

  return formData;
};

export default transformPayloadToFormData;