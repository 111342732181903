import React from "react";
// COMPONENTS
import AsrSaveButton from "@/shared/components/Buttons/AsrSaveButton";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useToggleCommonModalHandlers from "@/components/App/Modals/CommonAppModals/hooks/useToggleCommonModalHandlers";
// STYLES
import styles from "../styles.module.scss";

const RestorePasswordFormFooter = ({ closeModal, isUserAuthenticated }) => {
  const modalToggleHandlers = useToggleCommonModalHandlers();
  const onCancelClick = () => {
    closeModal();
    if (!isUserAuthenticated) {
      modalToggleHandlers.signIn({ show: true });
    }
  };

  return (
    <div className={styles.ModalFooter}>
      <AsrSaveButton
        type='submit'
        classes={{
          buttonRoot: styles.SubmitButton
        }}
        label={"appModule.send"}
        classNameWrapper={styles.SubmitButtonWrapper}
      >
      </AsrSaveButton>

      <div
        className={styles.CancelText}
        onClick={onCancelClick}
      >
        <IntlMessages id="appModule.cancel"/>
      </div>
    </div>
  );
};

export default RestorePasswordFormFooter;
