import { requestActionCreator } from "../../../utils/requestActionCreator";
import * as types from "../types";
import { baseApiUrl } from "../../../configs/MainConfig";
import querystring from "query-string";
import { authService } from "../../../shared/services/auth.service";


export const fetchOrganizationsListForAutocomplete = (payload) => {
  const payloadQuery = {
    page: 1,
    page_size: 10,
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  return requestActionCreator(
    types.FETCH_ORGANIZATIONS_LIST_FOR_AUTOCOMPLETE,
    `${baseApiUrl}/organization/list/?is_archived=false&${query}`,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchOrganizationExtendedInfo = (id) => requestActionCreator(
  types.FETCH_ORGANIZATION_EXTENDED_INFO,
  `${baseApiUrl}/organization/extended/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const resetOrganizationSelectedItem = () => ({
  type: types.RESET_ORGANIZATION_SELECTED_ITEM
});
/**
 *
 * @param organizationData
 * @param {Object} organizationData
 * @param {Object} organizationData.id
 * @param {string} organizationData.name
 * @param {string} organizationData.description
 * @param {Object} organizationData.logo_image,
 * @param {string} organizationData.logo_url,
 * @param {string} organizationData.website,
 * @param {string} organizationData.contact_email_1,
 * @param {string} organizationData.contact_phone_1,
 * @param {string} organizationData.owner_email,
 * @returns {Object} {{payload: {request: {headers: *, method: *, data: *, url: *}}, meta: *, type: *}}
 */
export const updateOrganization = (organizationData) => {
  const formData = new FormData();
  Object.keys(organizationData)
    .forEach(key => /*organizationData[key] && */formData.append(key, organizationData[key]));

  return requestActionCreator(
    types.UPDATE_ORGANIZATION,
    `${baseApiUrl}/organization/?id=${organizationData.id}`,
    "PATCH",
    { ...authService.getAuthHeaders() },
    formData,
  );
};

export const deleteOrganization = (id) => requestActionCreator(
  types.DELETE_ORGANIZATION,
  `${baseApiUrl}/organization/?id=${id}`,
  "DELETE",
  { ...authService.getAuthHeaders() },
  null,
  id
);

export const fetchOrganizationPermissionsList = () => requestActionCreator(
  types.FETCH_ORGANIZATION_PERMISSIONS_LIST,
  `${baseApiUrl}/organization/member/role/permission/list/?page_size=200`,
  "GET",
  { ...authService.getAuthHeaders() }
);
// USER ROLE
export const fetchOrganizationUsersRolesList = (payload) => (dispatch, getState) => {
  const currentState = getState();
  const pagination = currentState.organization.selectedItem.usersRoles.pagination;

  const payloadQuery = {
    ...pagination,
    is_archived: false
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/organization/member/role/list/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_USERS_ROLES_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setOrganizationUsersRolesPagination = (payload) => ({
  type: types.SET_ORGANIZATION_USERS_ROLES_PAGINATION,
  payload
});

export const createOrganizationUserRole = (data) => requestActionCreator(
  types.CREATE_ORGANIZATION_USER_ROLE,
  `${baseApiUrl}/organization/member/role/`,
  "POST",
  { ...authService.getAuthHeaders() },
  data
);

export const updateOrganizationUserRole = (id, data) => requestActionCreator(
  types.UPDATE_ORGANIZATION_USER_ROLE,
  `${baseApiUrl}/organization/member/role/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  data
);

export const fetchOrganizationUserRoleMultiLangInfo = (id) => requestActionCreator(
  types.FETCH_ORGANIZATION_USER_ROLE_MULTI_LANG_INFO,
  `${baseApiUrl}/organization/member/role/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchUsersRelatedToOrganization = (organizationId, includeCampaignUsers = false, page = 1, pageSize = 10, searchValue = "") => {
  const includeCampaignUsersText = includeCampaignUsers.toString();

  let url = `${baseApiUrl}/user/related_to/organization/`;
  url += `?organization_id=${organizationId}`;
  url += `&include_campaigns=${includeCampaignUsersText}`;
  if (searchValue) {
    url += `&fullname__email=${searchValue}`;
  }

  url += `&page=${page}`;
  url += `&page_size=${pageSize}`;

  return requestActionCreator(
    types.FETCH_USERS_RELATED_TO_ORGANIZATION,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};
// REFRESH USERS  PERMISSIONS
export const refreshOrganizationPermissions = (id) => requestActionCreator(
  types.REFRESH_ORGANIZATION_PERMISSIONS,
  `${baseApiUrl}/organization/member/role/service/refresh-permissions/?role_id=${id}`,
  "POST",
  { ...authService.getAuthHeaders() },
);
// SET THEME
export const setOrganizationTheme = (id, data) => {
  return requestActionCreator(
    types.SET_ORGANIZATION_THEME,
    `${baseApiUrl}/organization/settings/scheme/?organization_id=${id}`,
    "PATCH",
    { ...authService.getAuthHeaders() },
    data
  );
};
// GET THEME
export const getOrganizationTheme = (id) => {
  return requestActionCreator(
    types.GET_ORGANIZATION_THEME,
    `${baseApiUrl}/organization/settings/scheme/?organization_id=${id}`,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};
// SET DEFAULT THEME
export const setDefaultTheme = () => ({
  type: types.SET_DEFAULT_THEME
});
// SET COLOR SCHEMA
export const setColorSchema = (payload) => ({
  type: types.SET_COLOR_SCHEMA,
  payload
});

// CHOICE
export const getChoicesForAutocomplete = (payload) => {
  const payloadQuery = {
    page: 1,
    page_size: 10,
    key: "CAMPAIGN_ASSESSMENT_PROGRESS_STATUSES"
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);
  const url = `${baseApiUrl}/choice-lists/?${query}`;

  return requestActionCreator(
    types.GET_CHOICES_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
  );
};