// HELPERS
import { initTooltip } from "./initTooltip";
import { destroyTooltip } from "./destroyTooltip";

export const eventTooltip = ({ termLink, id, text, locale }) => {
  termLink.onmouseenter = async ({ target }) => {
    await initTooltip({ target, id, text, termLink, locale });
  };
  // CLEAN UP
  termLink.onmouseleave = () => {
    destroyTooltip({ id, text });
  };
};