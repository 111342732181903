import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
// COMPONENTS
import NestedLinks from "@/components/SideNav/helpers/NestedLinks/NestedLinks";
// STORE
import { getAccountUserMeta } from "@/store/Organization/rootReducers/account/currentUser/selectors";
// CONSTANTS
import { campaignsViewType } from "@/store/Organization/constants";


const CampaignsNestedLinks = ({
                                props,
                                canViewAssignedCampaigns = false
                              }) => {
  const match = useRouteMatch();
  const { is_campaign_participant: isCampaignParticipant } = useSelector(getAccountUserMeta);

  const linksList = [
    {
      to: `${match.url}/campaigns#${campaignsViewType.ASSIGNED_CAMPAIGNS}`,
      hash: `#${campaignsViewType.ASSIGNED_CAMPAIGNS}`,
      onClick: props.closeNavCallBack,
      isVisible: canViewAssignedCampaigns,
      label: "campaign.assignedCampaigns"
    },
    {
      to: `${match.url}/campaigns#${campaignsViewType.MY_CAMPAIGNS}`,
      hash: `#${campaignsViewType.MY_CAMPAIGNS}`,
      onClick: props.closeNavCallBack,
      isVisible: isCampaignParticipant,
      label: "accounts.dashboard.myCampaigns"
    },
    {
      to: `${match.url}/campaigns#${campaignsViewType.PUBLIC_CAMPAIGNS}`,
      hash: `#${campaignsViewType.PUBLIC_CAMPAIGNS}`,
      onClick: props.closeNavCallBack,
      isVisible: true,
      label: "campaign.availablePublicCampaigns"
    },
  ];

  return (
    <Route path={`${match.path}/campaigns`}>
      <NestedLinks links={linksList}/>
    </Route>
  );
};

export default CampaignsNestedLinks;