import React from "react";
import classnames from "classnames";
// MUI
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
// STYLES
import "./styles.scss";


const StyledDialog = withStyles({
  paper: {
    maxWidth: "700px",
    borderRadius: "10px",
    backgroundColor: "#242323 !important",
  },
})(Dialog);

const styles = () => ({
  root: {
    margin: 0,
    padding: "8px",
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "1.6px",
    color: "#9e9e",
  },
  title: {
    margin: 0,
    fontFamily: "Roboto Helvetica sans-serif",
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: 1.6,
    letterSpacing: "0.0075em"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <div className={classes.title}>{children}</div>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <i className='zmdi zmdi-close complete-dialog-link'/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MessageModal = ({
                        show,
                        setShow,
                        children,
                        classes = {
                          contentText: ""
                        }
                      }) => {

  const contentTextStyles = classnames({
    "complete-dialog-text": true,
    [classes?.contentText]: Boolean(classes?.contentText)
  });

  return (
    <StyledDialog
      open={show}
      onClose={() => setShow(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        onClose={() => setShow(false)}
        id='alert-dialog-title'
      />
      <DialogContent>
        <DialogContentText id='alert-dialog-description' className={contentTextStyles}>
          {children}
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  );
};

export default MessageModal;