import { useMemo } from "react";

const useUpdatePageCssClasses = (surveyModel) => {
  useMemo(() => {
    if (surveyModel) {
      surveyModel
        .onUpdatePageCssClasses
        .add((survey, options) => {
          const classes = options.cssClasses;
          classes.page.title = "survey-page-title";
          classes.page.description = "survey-page-description";
        });
    }
  }, [surveyModel]);

};

export default useUpdatePageCssClasses;