export default {
  "--asr-app-background-logo-image": "https://cdn.assessor.io/media/LogoSimple.svg",
  "--asr-app-background-image": "",
  "--asr-app-bg": "#E5E5E5",
  "--asr-header-org-logo-image": "",
  "--asr-header-org-sub-logo-image": "",
  "--asr-header-bg": "#243B47",
  "--asr-header-text-color": "#E1C123",
  "--asr-header-logo-color-st0": "#231F20",
  "--asr-header-logo-color-st1": "#E1C123",
  "--asr-header-logo-color-st2": "#243B47",
  "--asr-header-language-list-item-color": "#E1C123",
  "--asr-header-language-list-item-hover": "#C0BFBF",
  "--asr-nav-user-profile-bg": "#000000",
  "--asr-nav-link-color": "#F2F2F2",
  "--asr-nav-link-hover-bg": "#243B47",
  "--asr-nav-link-hover-color": "#E1C123",
  "--asr-nav-link-active-color": "#E1C123",
  "--asr-nav-link-active-bg": "#000000",
  "--asr-nav-link-active-home-bg": "#243B47",
  "--asr-nav-link-active-hover-color": "#BFBFBF",
  "--asr-nav-link-active-hover-bg": "#000000",
  "--asr-side-nav-bg": "#243B47",
  "--asr-organization-header-background-image": "",
  "--asr-organization-link-color": "#24B3B3",
  "--asr-active-tab-border-bg-color": "#24B2B2",
};
