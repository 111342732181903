import React from "react";
// COMPONENTS
import PreviewUploadedImage from "./PreviewUploadedImage/PreviewUploadedImage";
import PreviewUrlImage from "./PreviewUrlImage/PreviewUrlImage";
// STYLES
import styles from "./styles.module.scss";


const ImagePreviewSection = ({
                               inputFileId,
                               setLogoContent,
                               logoContent,
                               isImageFromPc,
                               setIsImageFromPc,
                               imageRounded
                             }) => {
  return (
    <div className={styles.ImagePreviewSection}>
      {isImageFromPc ? (
        <PreviewUploadedImage
          inputFileId={inputFileId}
          setLogoContent={setLogoContent}
          logoContent={logoContent}
          imageRounded={imageRounded}
        />
      ) : (
        <PreviewUrlImage
          inputFileId={inputFileId}
          setLogoContent={setLogoContent}
          logoContent={logoContent}
          setIsImageFromPc={setIsImageFromPc}
          imageRounded={imageRounded}
        />
      )}
    </div>
  );
};

export default ImagePreviewSection;