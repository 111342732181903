const makeQueryString = (query, prefix) => {

    let str = [], prop;
    for (prop in query) {
        if (query[prop]) {
            if (Object.prototype.hasOwnProperty.call(query, prop)) {
                const key = prefix ? prefix + "[" + prop + "]" : prop,
                  value = query[prop];
                str.push((value !== null && typeof value === "object") ?
                    makeQueryString(value, key) :
                    encodeURIComponent(key) + "=" + encodeURIComponent(value));
            }
        }
    }
    return str.join("&");
};

export default makeQueryString;