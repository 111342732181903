import React, { memo } from "react";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { getLocale } from "@/store/Settings/selectors";


const SurveyQuestionCardFooter = memo(({ questionId, itemsServiceInfo }) => {
  const { locale } = useSelector(getLocale);
  const customFields = getIntlMessageToStringLocal(locale, "assessments.customFields");
  const itemID = getIntlMessageToStringLocal(locale, "appModule.itemID");

  const customFieldsRender = () => {
    if (itemsServiceInfo?.custom_fields?.length) {
      return (
        <div className={`${styles.SurveyQuestionItem} ${styles.SurveyQuestionCustomField}`}>
          <div className={styles.SurveyQuestionItemLabel}>{customFields}:</div>
          <div className={styles.SurveyQuestionCustomFieldItemContainer}>
            {itemsServiceInfo?.custom_fields?.map((item, i) => {
              return <div
                key={i}
                className={`${styles.SurveyQuestionItemValue} ${styles.SurveyQuestionCustomFieldItemValue}`}>{item}</div>;
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.SurveyQuestionCardFooter}>
      {/*  ITEM ID */}
      <div className={styles.SurveyQuestionItem}>
        <span className={styles.SurveyQuestionItemLabel}>{itemID}: </span>
        <span className={styles.SurveyQuestionItemValue}>{questionId}</span>
      </div>
      {/* CUSTOM FIELDS */}
      {customFieldsRender()}
    </div>
  );
});


export default SurveyQuestionCardFooter;