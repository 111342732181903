const surveyStateTypes = {
  INITIALIZE_STATE: "INITIALIZE_STATE",
  SET_QUESTION_DATA: "SET_QUESTION_DATA",
  SET_QUESTION_STATUS: "SET_QUESTION_STATUS",
  SET_QUESTION_NOTE: "SET_QUESTION_NOTE",
  COUNT_UNANSWERED_QUESTION: "COUNT_UNANSWERED_QUESTION",
  SET_PAGE_VALID: "SET_PAGE_VALID",
  ON_CURRENT_PAGE_CHANGED: "ON_CURRENT_PAGE_CHANGED",
  SET_STATE_COMMON: "SET_STATE_COMMON",
  RESET_SURVEY_ASSESSMENT_STATE: "RESET_SURVEY_ASSESSMENT_STATE",
};

export default surveyStateTypes;