import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
// COMPONENTS
import AsrModal from "src/shared/components/Modals/AsrModal";
import AsrLightModal from "src/shared/components/Modals/AsrLightModal";
import AsrChangeImageModalForm from "@/shared/modals/AsrChangeImageModal/components/AsrChangeImageModalForm";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
// CONSTANTS
import { imageSourceType } from "@/shared/constants/forms";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";

/**
 * Simple Light modal for handling image changes based on APIv2 structure with multiple sources
 * @param {Boolean} open - show modal state
 * @param {Function} closeModal - function which will close opened modal
 * @param {Function} onSubmit - method which will be called on successful form submit
 * @param {Boolean} isSubmitLoading - show loader after form submit
 * @param {Boolean} lightModal - render modal as AsrLightModal
 * @param {Object} [imageData] - object with data of image
 * @param {string} imageData.url - url by which image is stored
 * @param {('link'|'uploaded_file')} imageData.source - source of the image from url
 * @returns {JSX.Element}
 * @constructor
 */
const AsrChangeImageModal = ({
                               open,
                               closeModal,
                               imageData,
                               onSubmit,
                               isSubmitLoading = false,
                               lightModal = false
                             }) => {
  const formRef = useRef();

  const onSubmitButtonClick = async () => {
    if (!formRef.current) return;
    await formRef.current.handleSubmit();
  };

  const RenderModal = useCallback(({ children }) => {
    if (lightModal) {
      return (
        <AsrLightModal
          open={open}
          onClose={closeModal}
          title={<IntlMessages id="appModule.updateImage"/>}
          classes={{
            modalBox: styles.LightModalBox
          }}
          actionFooterButtons={[
            {
              title: <IntlMessages id="appModule.cancel"/>,
              type: "plainText",
              onClick: closeModal
            },
            {
              title: <IntlMessages id='appModule.save'/>,
              type: "default",
              onClick: onSubmitButtonClick
            }
          ]}
        >
          {children}
        </AsrLightModal>
      );
    }

    return (
      <AsrModal
        open={open}
        closeModal={closeModal}
        title={<IntlMessages id="appModule.updateImage"/>}
        islandContent={true}
        classes={{
          modalBox: styles.DefaultModalBox,
          modalContent: styles.AsrModalContentContainer,
          footerContainer: styles.AsrModalFooter
        }}
        actionFooterButtons={[
          {
            title: <IntlMessages id="appModule.cancel"/>,
            type: "plainText",
            onClick: closeModal
          },
          {
            title: <IntlMessages id='appModule.save'/>,
            type: "default",
            onClick: onSubmitButtonClick
          }
        ]}
      >
        {children}
      </AsrModal>
    );
  }, [lightModal]);

  return (
    <RenderModal>
      <AsrChangeImageModalForm
        formRef={formRef}
        imageData={imageData}
        onSubmit={onSubmit}
      />

      {isSubmitLoading && (
        <AsrCommonLoader/>
      )}
    </RenderModal>
  );
};

AsrChangeImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  imageData: PropTypes.shape({
    url: PropTypes.string,
    source: PropTypes.oneOf([
      imageSourceType.LINK,
      imageSourceType.UPLOADED_FILE
    ]),
  }),
  onSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  lightModal: PropTypes.bool
};
export default AsrChangeImageModal;
