import { success, error } from "redux-saga-requests";
import * as types from "./types";

const initialState = {
  cards: {
    card1: [],
    card2: [],
    card3: [],
    card4: [],
    card5: [],
    card6: [],
    card7: [],
    card8: [],
    card9: [],
    card10: [],
    card11: [],
    card12: [],
    card13: [],
    card14: [],
    card15: [],
    card16: [],
    card17: [],
    card18: [],
    card19: [],
  },
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.GET_CAMPAIGN_STATISTICS): {
      const { response } = action.payload;
      return {
        ...state,
        cards: response.results
      };
    }
    default:
      return state;
  }
};