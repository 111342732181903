const stringToHtmlSupport = (function () {
  if (!window.DOMParser) return false;
  const parser = new DOMParser();
  try {
    parser.parseFromString("x", "text/html");
  } catch (err) {
    return false;
  }
  return true;
})();

export const stringToHTML = function (str) {

  if (stringToHtmlSupport) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    return doc.body;
  }

  const dom = document.createElement("div");
  dom.innerHTML = str;
  return dom;
};

export const capitalizeTheFirstLetter = (text) => {
  return text[0].toUpperCase() + text.slice(1);
};

export const removeHtmlTags = (text) => {
  if (!text) return text;
  return text.replace(/<[^>]*>?/gm, "");
};