import React, { memo } from "react";
import parse from "html-react-parser";
//  UTILS
import reactStringReplace from "@/shared/utils/stringReplace";
// REGX
import { parsBracketsRegex } from "@/shared/regex/auditor-regex";
// COMPONENTS
import AsrTermBubble from "@/shared/components/AsrTermBubble/AsrTermBubble";
// STYLES
import styles from "./styles.module.scss";


const ParseQuestionName = memo(({ name }) => {
  // todo: need more pretty way to parse html with custom terms
  const renderFunction = () => {
    return reactStringReplace(name, parsBracketsRegex, (match, i) => {
      return (
        <AsrTermBubble
          key={i}
          withHeader={false}
          term={match}
          classes={{
            tooltip: styles.assessmentHeaderTooltip,
            arrow: styles.assessmentHeaderTooltipArrow,
            tooltipContent: styles.assessmentTooltipContent,
            termContainer: styles.assessmentTooltipTermContainer,
            link: styles.assessmentHeaderTooltipLink
          }}
        />
      );
    });
  };

  return (
    <span className={styles.renderedName}>
      {/*{renderFunction()}*/}
      {renderFunction()?.map((item, index) => (
        <span key={index}>
          {typeof item === "string" ? parse(item) : item}
        </span>
      ))}
    </span>
  );
});

export default ParseQuestionName;
