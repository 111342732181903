import React from "react";
// UTILS
import { formatDate } from "@/shared/utils/date";
import IntlMessages from "@/utils/IntlMessages";
import { generateUserFullName } from "@/utils/helpers/user";
// COMPONENTS
import UserAvatar from "./components/UserAvatar";
// STYLES
import styles from "../../styles.module.scss";

const UserPreview = ({
                       profileInfo,
                       isFetchLoading
                     }) => {
  return (
    <div className={styles.UserPreviewContainer}>
      <UserAvatar
        userId={profileInfo?.id}
        userFullName={generateUserFullName({
          firstName: profileInfo?.personal_info?.first_name,
          lastName: profileInfo?.personal_info?.last_name
        })}
        avatarData={profileInfo?.avatar}
        isFetchLoading={isFetchLoading}
      />

      <div className={styles.UserInfoContainer}>
        <div className={styles.UserName}>
          {generateUserFullName({
            titleName: profileInfo?.personal_info?.title_name,
            firstName: profileInfo?.personal_info?.first_name,
            lastName: profileInfo?.personal_info?.last_name
          })}
        </div>

        <div>
          {profileInfo?.personal_info?.email}
        </div>

        <div className={styles.JoinedInfo}>
          <IntlMessages id="organizationMainPage.joined"/>
          {" " + formatDate(profileInfo?.meta?.created_dt, "MMM D, YYYY")}
        </div>

        <div className={styles.RegisteredUserId}>
          <IntlMessages id="appModule.registeredUser"/>
          {" " + profileInfo?.id}
        </div>
      </div>
    </div>
  );
};

export default UserPreview;
