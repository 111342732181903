import hiddenQuestionAnalytics from "../hiddenQuestionAnalytics";

const useHiddenQuestionType = (questionType, availableQuestionsType) => {
  const type = questionType?.inputType ?? questionType?.type;
  const isQuestionTypeSupported = availableQuestionsType.includes(type);
  // Hide analytics if Survey Analytics does not support the question type
  if (!isQuestionTypeSupported) {
    return true;
  }
  // Hide analytics if question type  in the "hiddenQuestionAnalytics"
  return hiddenQuestionAnalytics.includes(type);
};

export default useHiddenQuestionType;