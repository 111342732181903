import { success } from "redux-saga-requests";
import * as types from "./types";
import { DEFAULT_ACTIONS_VERB_SORT } from "./constants";

const initialState = {
  actionVerbs: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: DEFAULT_ACTIONS_VERB_SORT
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.FETCH_ACTION_VERBS) : {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;
      return {
        ...state,
        actionVerbs: {
          ...state.actionVerbs,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_ACTION_VERBS_PAGINATION : {
      return {
        ...state,
        actionVerbs: {
          ...state.actionVerbs,
          pagination: {
            ...state.actionVerbs.pagination,
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_ACTION_VERBS_FILTERS: {
      return {
        ...state,
        actionVerbs: {
          ...state.actionVerbs,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_ACTION_VERBS_ORDER: {
      return {
        ...state,
        actionVerbs: {
          ...state.actionVerbs,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};