import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    Bar,
    ComposedChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Line,
    LabelList,
} from "recharts";
import CardBox from "./CardBox";
import IntlMessages, { useIntlMessageToString } from "../../../../utils/IntlMessages";
import useAssessmentSectionsNames from "./useAssessmentSectionsNames";

const CardAssessment = React.forwardRef(function CardAssessment({ title, data, barColor, exportImage, windowWidth, keyForSavingCardState }, ref) {
    const isLargeWindow = windowWidth >= 1100;

    const sectionsNames = useAssessmentSectionsNames();

    const preparedData = data.map((item) => ({ ...item, title: sectionsNames[item.topic_id] }));

    const avgLabel = useIntlMessageToString("reports.average");

    return (
        <CardBox
            heading={title}
            exportImage={exportImage}
            ref={ref}
            keyForSavingCardState={keyForSavingCardState}
        >
            {data.length > 0
                ? (
                    <div className='report-card__body__container'>
                        <div className='bar-chart__title'>
                            {title} <IntlMessages id='reports.chart'/>:
                        </div>
                        {isLargeWindow
                            ? (
                                <ResponsiveContainer width='100%' height={400}>
                                    <ComposedChart
                                        data={preparedData}
                                        margin={{ top: 10, right: 5, bottom: 0 }}
                                    >
                                        <XAxis
                                            height={130}
                                            axisLine={false}
                                            tick={false}
                                        />
                                        <YAxis
                                            type='number'
                                            domain={[0, 100]}
                                            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                            axisLine={false}
                                            unit='%'
                                            tickLine={false}
                                        />
                                        <Tooltip/>
                                        <Legend
                                            align='right'
                                            layout='vertical'
                                            verticalAlign='middle'
                                            wrapperStyle={{
                                                paddingLeft: "30px",
                                            }}
                                        />
                                        <CartesianGrid
                                            stroke='#f5f5f5'
                                            vertical={false}
                                        />
                                        <Bar
                                            dataKey='score'
                                            barSize={isLargeWindow ? 30 : 12}
                                            fill={barColor}
                                            name='SCORE'
                                        >
                                            <LabelList
                                                dataKey='title'
                                                position='bottom'
                                                offset={10}
                                            />
                                        </Bar>
                                        <Line
                                            type='monotone'
                                            dataKey='moy'
                                            name={avgLabel}
                                            stroke='#bfbfbf'
                                            dot={false}
                                            strokeWidth={2}
                                        />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            ) : (
                                <ResponsiveContainer width='100%' height={600}>
                                    <ComposedChart
                                        layout='vertical'
                                        data={preparedData}
                                    >
                                        <YAxis
                                            width={200}
                                            dataKey='title'
                                            type='category'
                                        />
                                        <XAxis
                                            type='number'
                                            domain={[0, 100]}
                                            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                            axisLine={false}
                                            unit='%'
                                            tickLine={false}
                                        />
                                        <Tooltip/>
                                        <CartesianGrid
                                            stroke='#f5f5f5'
                                        />
                                        <Bar
                                            dataKey='score'
                                            barSize={12}
                                            fill={barColor}
                                            name='SCORE'
                                        />
                                        <Line
                                            type='monotone'
                                            dataKey='moy'
                                            name={avgLabel}
                                            stroke='#bfbfbf'
                                            dot={false}
                                            strokeWidth={2}
                                        />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            )}
                    </div>
                ) : (
                    <div className='report-card__no-content'><IntlMessages id='reports.noContent'/></div>
                )}
        </CardBox>
    );
});

CardAssessment.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    barColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    exportImage: PropTypes.func.isRequired,
    windowWidth: PropTypes.number.isRequired,
    keyForSavingCardState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    windowWidth: state.settings.windowWidth,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(CardAssessment);