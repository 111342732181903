import { useDispatch, useSelector } from "react-redux";
// UTILS
import { getIntlMessageToStringLocal } from "@/utils/IntlMessages";
// STORE
import { addSystemNotification } from "@/store/System/actions";
import { getLocale } from "@/store/Settings/selectors";


const useFroalaConfig = ({
                           onMaxCharNumberWasExceeded
                         }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(getLocale);
  const pasteError = getIntlMessageToStringLocal(locale, "appModule.maxCharNumberWasExceeded");

  let pasteText = "";

  const validatePasteEvent = {
    "charCounter.exceeded": function () {
      if (pasteText) {
        dispatch(addSystemNotification("", pasteError, "error"));
        pasteText = "";
      }
      if (onMaxCharNumberWasExceeded) {
        onMaxCharNumberWasExceeded();
      }
    },
    "paste.beforeCleanup": function (clipboard_html) {
      pasteText = clipboard_html;
    }
  };

  return {
    validatePasteEvent
  };
};

export default useFroalaConfig;