import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
// COMPONENTS
import LogoPlaceholder from "./components/LogoPlaceholder/LogoPlaceholder";
import AsrImage from "@/shared/components/AsrImage/AsrImage";
// STORE
import { getOrganizationExtendedInfoLoading } from "@/store/Organization/selectors";
// HOOKS
import useAccountLogoExtractor from "@/shared/hooks/themes/useAccountLogoExtractor";
// STYLES
import "./styles.scss";

const Logo = ({
                alt,
                classes = {
                  root: "",
                  logo: "",
                  subLogo: ""
                },
                disableLink = false,
                ignoreAccountTheme = false,
                redirectTo = "/"
              }) => {
  const orgExtendedInfoLoading = useSelector(getOrganizationExtendedInfoLoading);
  const logoInfo = useAccountLogoExtractor({ ignoreAccountTheme });

  const appLogoClasses = classnames({
    "app-logo": true,
    [classes.root]: classes.root
  });

  const logoClasses = classnames({
    "asr-header-logo": true,
    "asr-header-logo--default": logoInfo.isLogoLinkDefault,
    [classes.logo]: classes.logo
  });

  const subLogoClasses = classnames({
    "asr-header-sub-logo": true,
    "asr-header-sub-logo--default": logoInfo.isSubLogoLinkDefault,
    [classes.subLogo]: classes.subLogo
  });

  const generateLogosBlock = () => {
    return (
      <>
        {logoInfo.logoLink && (
          <AsrImage
            src={logoInfo.logoLink}
            alt={alt}
            className={logoClasses}
          />
        )}
        {logoInfo.subLogoLink && (
          <AsrImage
            src={logoInfo.subLogoLink}
            alt={alt}
            className={subLogoClasses}
          />
        )}
      </>
    );
  };

  if (orgExtendedInfoLoading) return <LogoPlaceholder/>;
  return (
    <>
      {disableLink ? (
        <div className={appLogoClasses}>
          {generateLogosBlock()}
        </div>
      ) : (
        <Link className={appLogoClasses} to={redirectTo}>
          {generateLogosBlock()}
        </Link>
      )}
    </>
  );
};

Logo.displaName = "Logo";
export default Logo;
