import React, { useMemo } from "react";
// MATERIAL UI
import Tooltip from "@mui/material/Tooltip";
// COMPONENTS
import AsrCheckBoxSmall
  from "../../../../../../../../../../../../shared/components/FormComponents/AsrCheckBoxSmall/AsrCheckBoxSmall";
// STYLES
import commonStyles from "../../../../../../../common.module.scss";
import styles from "../../styles.module.scss";


const NoteCheckbox = ({
                        handler,
                        tooltip,
                        tooltipNoteIsLocked,
                        label,
                        checked,
                        classes = {
                          tooltip: "",
                          root: ""
                        },
                        isLocked,
                        disabled = false,
                        id,
                        tooltipPlacement = "top"
                      }) => {

  const tooltipTitle = useMemo(() => {
    if (isLocked) {
      return tooltipNoteIsLocked;
    } else if (!disabled) {
      return tooltip;
    } else {
      return "";
    }
  }, [disabled, isLocked, tooltip, tooltipNoteIsLocked]);

  return (
      <label className="mb-1" htmlFor={id}>
        <div className={`${styles.formContainerFooterCheckBoxItem} ${classes.root}`}>
          <Tooltip
              title={tooltipTitle}
              placement={tooltipPlacement}
              classes={{
                popper: commonStyles.verifiedButtonTooltipPopper,
                tooltip: `${commonStyles.verifiedButtonTooltip} ${classes.tooltip}`,
                arrow: commonStyles.verifiedButtonArrow
              }}
              arrow
          >
            <span
                className={`${styles.formContainerFooterCheckBoxItemLabel} ${(isLocked || disabled) && styles.formContainerFooterCheckBoxItemLabelDisabled}`}>{label}</span>
          </Tooltip>
          <AsrCheckBoxSmall
              id={id}
              checked={checked}
              disabled={isLocked || disabled}
              handler={handler}
          />
        </div>
      </label>
  );
};

export default NoteCheckbox;