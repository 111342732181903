import React from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
// STORE
import { getLocale } from "../../../../store/Settings/selectors";
// LOCALIZATION
import AppLocale from "../../../../localization";
// COMPONENTS
import Card1 from "./CardsPDF/Card1/Card1";
import Card2 from "./CardsPDF/Card2/Card2";
import Card3 from "./CardsPDF/Card3/Card3";
import Card4 from "./CardsPDF/Card4/Card4";
import Card5 from "./CardsPDF/Card5/Card5";
import Card6 from "./CardsPDF/Card6/Card6";
import Card7 from "./CardsPDF/Card7/Card7";
import Card10 from "./CardsPDF/Card10/Card10";
import Card19 from "./CardsPDF/Card19/Card19";
import CardAssessment from "./CardsPDF/CardAssessment/CardAssessment";
import CardAssessmentQuestions from "./CardsPDF/CardAssessmentQuestions/CardAssessmentQuestions";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: "70px 0 45px"
  },
  pageHeader: {
    position: "absolute",
    zIndex: 1,
    height: "60px",
    padding: "10px 30px",
    display: "flex",
    flexDirection: "row",
    top: 0,
    left: 0,
    right: 0,
  },
  logo: {
    height: "100%",
    marginRight: "12px",
  },
  sublogo: {
    height: "100%",
  },
  pageFooter: {
    position: "absolute",
    zIndex: 1,
    height: "40px",
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    fontSize: "10px",
    letterSpacing: "0.8025px",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    color: "#828282",
    marginRight: "10px"
  },
  pageNumber: {
    color: "#000",
  }
});

// Create Document Component
const DocumentComponent = ({ data, titles, header, footer, isIqcRelated }) => {
  const locale = useSelector(getLocale);
  const currentAppLocale = locale ? AppLocale[locale.locale] : AppLocale["en"];
  const { logo, sublogo, headerBgColor } = header;

  const {
    card1,
    card2,
    card3,
    card4,
    card5,
    card6,
    card7,
    card10,
    card11,
    card12,
    card13,
    card15,
    card16,
    card17,
    card18,
    card19,
    card20,
    card21,
    card22,
    card23,
    card24,
    card25,
    card26,
  } = data;

  const {
    card11Title,
    card12Title,
    card13Title,
    card15Title,
    card16Title,
    card17Title,
    card18Title,
    card20Title,
    card21Title,
    card22Title,
    card23Title,
    card24Title,
    card25Title,
    card26Title,
  } = titles;

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <View style={[styles.pageHeader, { backgroundColor: headerBgColor }]} fixed>
            {logo && (
              <Image
                src={logo}
                style={styles.logo}
              />
            )}
            {sublogo && (
              <Image
                src={sublogo}
                style={styles.sublogo}
              />
            )}
          </View>
          <Card1 data={card1}/>
          <Card2 data={card2}/>
          <Card3 data={card3}/>
          <Card4 isIqcRelated={isIqcRelated} data={card4}/>
          <Card5 data={card5}/>
          <Card6 data={card6}/>
          <Card7 data={card7}/>
          <Card10 data={card10}/>
          {isIqcRelated && (
            <>
              <CardAssessment data={card11} title={card11Title} barColor="#b74250"/>
              <CardAssessmentQuestions data={card20} title={card20Title}/>
              <CardAssessment data={card12} title={card12Title} barColor="#f1ad7b"/>
              <CardAssessmentQuestions data={card21} title={card21Title}/>
              <CardAssessment data={card13} title={card13Title} barColor="#c9d888"/>
              <CardAssessmentQuestions data={card22} title={card22Title}/>
              <Card19 data={card19}/>
              <CardAssessment data={card15} title={card15Title} barColor="#66b4bd"/>
              <CardAssessmentQuestions data={card23} title={card23Title}/>
              <CardAssessment data={card16} title={card16Title} barColor="#c9b2df"/>
              <CardAssessmentQuestions data={card24} title={card24Title}/>
              <CardAssessment data={card17} title={card17Title} barColor="#f4e6a0"/>
              <CardAssessmentQuestions data={card25} title={card25Title}/>
              <CardAssessment data={card18} title={card18Title} barColor="#bca684"/>
              <CardAssessmentQuestions data={card26} title={card26Title}/>
            </>
          )}
          <View style={styles.pageFooter} fixed>
            <Text style={styles.footerText}>{footer.text}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `Page ${pageNumber} of ${totalPages}`
            )}/>
          </View>
        </Page>
      </Document>
    </IntlProvider>
  );
};

export default DocumentComponent;
