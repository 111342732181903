import React from "react";
import classnames from "classnames";
import { Alert } from "@mui/material";
// COMPONENTS
import CircularProgress from "../CircularProgress";
// STYLES
import styles from "./styles.module.scss";


const AsrCommonLoader = ({
                           description,
                           withBackdrop = false,
                           classes = {
                             container: ""
                           }
                         }) => {

  const wrapClass = classnames({
    [styles.root]: true,
    [classes.container]: classes.container,
    [styles.rootWithBackDrop]: withBackdrop,
  });

  return (
      <div className={wrapClass}>
        {description && <Alert severity="info">{description}</Alert>}
        <CircularProgress color="inherit"/>
      </div>
  );
};

export default AsrCommonLoader;