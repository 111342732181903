import React, { memo } from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
//STYLES
import useStyles from "./styles";

const AsrButtonWithLoading = memo(({ onClick, loading, buttonClassname, label = "button", isVisible = true }) => {
  const classes = useStyles();

  if (!isVisible) return null;

  return (
      <div className={classes.wrapper}>
        <Button
            variant="contained"
            // color="primary"
            className={buttonClassname}
            disabled={loading}
            onClick={onClick}
        >
          {label}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </div>
  );
});

export default AsrButtonWithLoading;