import React, { useState } from "react";
import PropTypes from "prop-types";
// SURVEY
import { Survey } from "survey-react-ui";
// COMPONENTS
import BottomNavigation from "@/components/SurveyJs/components/ViewerV2/components/BottomNavigation";
// HOOKS
import useInitializeSurvey from "@/components/SurveyJs/components/ViewerV2/hooks/useInitializeSurvey";
import useSurveyValueChanged from "@/components/SurveyJs/components/ViewerV2/hooks/useSurveyValueChanged";
import useClasses from "@/components/SurveyJs/components/ViewerV2/hooks/useClasses";
// CONSTANTS
import {
  SURVEY_CONTAINER_NAME,
  USER_TYPES,
  parentTargetTypes
} from "@/components/SurveyJs/constants";

const SurveyViewerV2 = ({
                          templateBody,
                          progressInfo,
                          callbacks,
                          readOnly = false,
                          // NAVIGATION
                          navigation,
                          permissions,
                          validations,
                          parentTarget,
                          service,

                          // need to be removed
                          publicSurveyEmail,
                          classes = {
                            root: ""
                          }
                        }) => {
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
  const { sendDataHandler } = useSurveyValueChanged({
    onSubmitCallback: callbacks?.onSubmit
  });

  const { surveyModel, handleChange } = useInitializeSurvey({
    templateBody,
    progressInfo,
    readOnly,
    callbacks,
    permissions,
    validations,
    publicSurveyEmail,
    parentTarget,
    service
  });
  const _classes = useClasses({ classes });

  const onComplete = async (result) => {
    await sendDataHandler({
      result,
      isCompleted: true
    });
    setIsSurveyCompleted(true);
  };

  if (!surveyModel) return null;
  return (
    <div className={_classes.root}>
      <Survey
        model={surveyModel}
        showNavigationButtons={false}
        onValueChanged={handleChange}
        onComplete={onComplete}
        id={SURVEY_CONTAINER_NAME}
        css={{
          title: "survey__title",
          description: "survey__description",
          navigationButton: "survey__navigation-button",
          completedPage: "survey__complete-page"
        }}
      />

      {/*  PAGES NAVIGATION */}
      {!isSurveyCompleted && (
        <BottomNavigation
          surveyModel={surveyModel}
          showCompleteBtn={navigation?.showCompleteBtn}
          showQuestionsTotalCount={navigation?.showTotalUnansweredQuestionsCount}
        />
      )}
    </div>
  );
};

SurveyViewerV2.propTypes = {
  templateBody: PropTypes.object.isRequired,
  progressInfo: PropTypes.shape({
    sjs_answers: PropTypes.object,
    object_answers: PropTypes.arrayOf(PropTypes.object),
  }),

  readOnly: PropTypes.bool,
  callbacks: PropTypes.shape({
    onSubmit: PropTypes.func,
    onAnswerChanged: PropTypes.func,
    onAfterRenderQuestion: PropTypes.func,
    onQuestionAnswerValidate: PropTypes.func,
    onAnswerVerificationStatusChanged: PropTypes.func,
    onItemNoteChanged: PropTypes.func
  }),
  // NAVIGATION
  navigation: PropTypes.shape({
    showTotalUnansweredQuestionsCount: PropTypes.bool,
    showCompleteBtn: PropTypes.bool,
  }),
  permissions: PropTypes.shape({
    canViewManagement: PropTypes.bool,  // allow to view management items from other permissions
    canViewItemNotes: PropTypes.bool,
    canCreateItemNotes: PropTypes.bool,
    canModifyItemNotes: PropTypes.bool,
    canDeleteItemNotes: PropTypes.bool,
    canModifyAnswers: PropTypes.bool,
    canVerifyAnswer: PropTypes.bool,
    canViewAnalytics: PropTypes.bool,
    canViewRecommendations: PropTypes.bool,
  }),
  validations: PropTypes.shape({
    validateLockAnswerProperty: PropTypes.bool
  }),
  // Relation to assessment / registration
  parentTarget: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    type: PropTypes.oneOf(Object.values(parentTargetTypes))
  }),
  service: PropTypes.shape({
    questionsInfo: PropTypes.object,
    managementType: PropTypes.oneOf(Object.values(USER_TYPES))
  })
};
export default SurveyViewerV2;