import ReactGA from "react-ga4";
import { appService } from "./app";
import { cookieConsentService } from "./cookies";

export class AppAnalyticsService {
  doInit({ ga4 = true } = {}) {
    ga4 && this.doInitGoogleAnalytics();
  }

  doInitGoogleAnalytics() {
    if (!appService.getIsProductionMode()) return;
    const isCookieConsentAccepted = cookieConsentService.getIsAccepted();
    if (!isCookieConsentAccepted) return;
    const googleAnalyticsId = appService.getGoogleAnalyticsId();
    if (!googleAnalyticsId) return;

    ReactGA.initialize(googleAnalyticsId);
  }
}

export const appAnalyticsService = new AppAnalyticsService();
