import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import classNames from "classnames";
import { useCheckPermissions } from "@/utils/checkPermission";
import { ResultsContext } from "../Modals/CampaignResultsModal/CampaignResultsModal";

const saveCardStatus = (userId, keyForSavingCardState, isCollapsed) => {
    const reportCardsState = localStorage.getItem("reportCardsState");

    if (reportCardsState) {
        const parsedState = JSON.parse(reportCardsState);

        let updatedState;

        if (parsedState[userId]) {
            updatedState = {
                ...parsedState,
                [userId]: {
                    ...parsedState[userId],
                    [keyForSavingCardState]: {
                        ...(parsedState[userId][keyForSavingCardState] || {}),
                        isCollapsed,
                    },
                }
            };

            localStorage.setItem("reportCardsState", JSON.stringify(updatedState));
            return;
        }

        updatedState = {
            ...parsedState,
            [userId]: {
                [keyForSavingCardState]: {
                    isCollapsed,
                },
            }
        };

        localStorage.setItem("reportCardsState", JSON.stringify(updatedState));
        return;
    }

    const state = {
        [userId]: {
            [keyForSavingCardState]: {
                isCollapsed,
            },
        }
    };

    localStorage.setItem("reportCardsState", JSON.stringify(state));
};

const getCardStatus = (userId, keyForSavingCardState) => {
    const reportCardsState = localStorage.getItem("reportCardsState");

    if (reportCardsState) {
        const parsedState = JSON.parse(reportCardsState);
        return get(parsedState, `${userId}.${keyForSavingCardState}.isCollapsed`, false);
    }

    return false;
};

const CardBox = React.forwardRef(function CardBox({
                                                      heading,
                                                      children,
                                                      exportImage,
                                                      userPermissions,
                                                      keyForSavingCardState,
                                                  }, ref) {
    const { currentUserId } = useContext(ResultsContext);

    const [isCollapsed, setIsCollapsed] = useState(getCardStatus(currentUserId, keyForSavingCardState));

    const minimize = () => {
        setIsCollapsed(true);
        saveCardStatus(currentUserId, keyForSavingCardState, true);
    };

    const maximize = () => {
        setIsCollapsed(false);
        saveCardStatus(currentUserId, keyForSavingCardState, false);
    };

    const canDownloadCardAsImage = useCheckPermissions(
        userPermissions,
        [
            "campaign.report.download_report_card_image",
            "organization.full_access.all_permissions_to_any_campaign_in_organization",
        ],
        false,
    );

    const headerClass = classNames({
        "jr-card-header": true,
        "report-card__header__container": true,
        "collapsed": isCollapsed,
    });

    return (
        <div className='col-lg-12 col-sm-12 report-card__wrapper'>
            <div ref={ref}>
                <div className='jr-card report-card__container'>
                    <div className={headerClass}>
                        <h3 className='report-card__header__text'>{heading}</h3>
                        <div>
                            {canDownloadCardAsImage && !isCollapsed && (
                                <i
                                    className='zmdi zmdi-copy zmdi-hc-2x'
                                    style={{ color: "#24B2B2", cursor: "pointer", marginRight: "28px" }}
                                    onClick={exportImage}
                                />
                            )}
                            {isCollapsed ? (
                                <i
                                    className='zmdi zmdi-plus zmdi-hc-2x'
                                    style={{ color: "#24B2B2", cursor: "pointer" }}
                                    onClick={maximize}
                                />
                            ) : (
                                <i
                                    className='zmdi zmdi-minus zmdi-hc-2x'
                                    style={{ color: "#24B2B2", cursor: "pointer" }}
                                    onClick={minimize}
                                />
                            )}
                        </div>
                    </div>
                    {!isCollapsed && (
                        <div className='jr-card-body report-card__body__wrapper'>
                            {children}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

const mapStateToProps = (state) => ({
    userPermissions: [
        ...get(state, "campaign.requester_membership.role.permissions", []),
        ...get(state, "organization.selectedItem.requester_membership.role.permissions", []),
    ],
});

CardBox.propTypes = {
    heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    exportImage: PropTypes.func.isRequired,
    userPermissions: PropTypes.array.isRequired,
    keyForSavingCardState: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CardBox);