import React from "react";
import _ from "lodash";
// CONSTANTS
import surveyJsLanguages from "@/shared/constants/surveyJs/languages";
// UTILS
import CustomScrollbars from "@/utils/CustomScrollbars";
import { extractSystemLanguageCodes } from "@/utils/language";
// COMPONENTS
import LanguageItem from "./LanguageItem";
// STYLES
import styles from "../../../../styles.module.scss";


const LanguageSwitcherSurvey = ({
                                  switchLanguage,
                                  handleRequestClose,
                                  surveyCurrentLocale = "en",
                                  usedSurveyLocales
                                }) => {
  const systemLocaleCodes = extractSystemLanguageCodes();
  const surveyHasSystemLocales = usedSurveyLocales.some(code => systemLocaleCodes.indexOf(code) !== -1);

  const languagesList = usedSurveyLocales.map(langCode => {
    return {
      code: langCode,
      nativeName: surveyJsLanguages[langCode]
    };
  });
  const languagesListSorted = _.sortBy(languagesList, "nativeName");

  return (
    <CustomScrollbars className="scrollbar app-custom-scrollbar">
      <ul className={styles.LanguagesDropdownList}>
        {languagesListSorted.map((languageData, index) => (
          <LanguageItem
            key={index}
            language={languageData}
            handleRequestClose={handleRequestClose}
            switchLanguage={switchLanguage}
            surveyCurrentLocale={surveyCurrentLocale}
            showLanguageIcon={surveyHasSystemLocales}
          />
        ))}
      </ul>
    </CustomScrollbars>
  );
};

export default LanguageSwitcherSurvey;