import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export const NotFound = () => {
  const history = useHistory();
  
  useEffect(()=>{
    history.replace("/");
  },[history]);
  
  return null;
};