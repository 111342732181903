import classnames from "classnames";
import styles from "../styles.module.scss";


const useClasses = (classes, props) => {

  const overlay = classnames({
    "asr-modal-overlay": true,
    [classes?.overlay]: classes.overlay,
  });

  const modal = classnames({
    "asr-modal": true,
    [classes.modal]: classes.modal,
    [props?.className]: props?.className
  });

  const modalBox = classnames({
    [styles.AsrModalBox]: true,
    [classes.modalBox]: classes.modalBox,
  });

  const closeButton = classnames({
    [styles.CloseButton]: true,
    [classes.closeButton]: classes.closeButton,
    [styles.CloseButtonHidden]: !props?.withCloseButton
  });

  const modalHeader = classnames({
    "asr-modal-header": true,
    [classes.modalHeader]: classes.modalHeader,
  });

  const modalTitle = classnames({
    "asr-modal-title": true,
    [classes.modalTitle]: classes.modalTitle,
    [styles.TextCapitalize]: props?.titleCapitalize
  });

  const modalIslandContentContainer  = classnames({
    [styles.IslandContentContainer]: Boolean(props?.islandContent)
  });

  const modalContent = classnames({
    "asr-modal-content": true,
    [styles.IslandContent]: Boolean(props?.islandContent),
    [classes.modalContent]: classes.modalContent,
  });

  const modalFooterContainerStyles = classnames({
    "asr-modal-footer": true,
    [styles.ModalFooter]: true,
    [classes?.footerContainer]: Boolean(classes?.footerContainer)
  });


  return {
    modal,
    overlay,
    modalBox,
    closeButton,
    modalHeader,
    modalTitle,
    modalIslandContentContainer: modalIslandContentContainer,
    modalContent,
    modalFooter: modalFooterContainerStyles,
  };
};

export default useClasses;