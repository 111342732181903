// AUDITOR CONTROLS
import * as types from "./types";
import { requestActionCreator } from "@/utils/requestActionCreator";
import { baseApiUrl, baseApiUrlV2 } from "@/configs/MainConfig";
import { authService } from "@/shared/services/auth.service";
//  CONSTANTS
import { targetTypes } from "@/shared/constants/surveyJs";
import { assessorApiTargets } from "@/store/services/config/assessorApiConstants";

export const setAuditorControls = (payload) => ({
  type: types.SET_SURVEY_AUDITOR_CONTROLS,
  payload
});
// NOTE
export const toggleSurveyHeaderNotePanel = (data) => {
  const url = `${baseApiUrl}/campaign/assessment/config/user/management/`;
  return requestActionCreator(
    types.SURVEY_HEADER_PANEL_TOGGLE_NOTE,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};
export const toggleSurveyHeaderRecommendationsPanel = (data) => {
  const url = `${baseApiUrl}/campaign/assessment/config/user/management/`;
  return requestActionCreator(
    types.SURVEY_HEADER_PANEL_TOGGLE_RECOMMENDATIONS,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};

export const toggleSurveyHeaderAnalyticsPanel = (data) => {
  const url = `${baseApiUrl}/campaign/assessment/config/user/management/`;
  return requestActionCreator(
    types.SURVEY_HEADER_PANEL_TOGGLE_ANALYTICS,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};

export const resetAuditorControls = () => ({
  type: types.RESET_SURVEY_AUDITOR_CONTROLS,
});

export const modifySurveyAuditorQuestionPanel = (payload) => ({
  type: types.MODIFY_SURVEY_AUDITOR_QUESTION_PANEL,
  payload
});

export const resetSurveyAssessmentAnswer = () => ({
  type: types.RESET_SURVEY_ASSESSMENT_ANSWER
});


export const fetchSurveyAnalytics = (data) => {
  const url = `${baseApiUrl}/campaign/assessment/answer/survey-analytics/`;
  return requestActionCreator(
    types.FETCH_SURVEY_ANALYTICS,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};

export const updateSurveyAnswersV2 = ({
                                        target: {
                                          targetType,
                                          targetId,
                                          targetActId
                                        } = {},
                                        userId,
                                        managementType,
                                        body,
                                      }) => {
  let url;
  if (targetType === targetTypes.ASSESSMENT) {
    url = targetActId
      ? `${baseApiUrlV2}/assessments/act/${targetActId}/mt/${managementType}/?target=${assessorApiTargets.QUESTION_ANSWERS}`
      : `${baseApiUrlV2}/assessments/${targetId}/act/user/${userId}/mt/${managementType}/?target=${assessorApiTargets.QUESTION_ANSWERS}`;
  } else if (targetType === targetTypes.REGISTRATION) {
    url = targetActId
      ? `${baseApiUrlV2}/registrations/acts/${targetActId}/mt/${managementType}/action/edit/?target=${assessorApiTargets.QUESTION_ANSWERS}`
      : `${baseApiUrlV2}/registrations/${targetId}/acts/user/${userId}/mt/${managementType}/action/edit/?target=${assessorApiTargets.QUESTION_ANSWERS}`;
  } else {
    throw new Error("passed targetType is not supported.");
  }

  return requestActionCreator(
    types.UPDATE_SURVEY_ANSWER_INFO_V2,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    body
  );
};

export const addSurveyAnalyticsId = (id) => ({
  type: types.ADD_SURVEY_ANALYTICS_ID,
  payload: { id }
});

export const removeSurveyAnalyticsId = (id) => ({
  type: types.REMOVE_SURVEY_ANALYTICS_ID,
  payload: { id }
});

export const setCurrentSurveyAnalytics = (payload) => ({
  type: types.SET_CURRENT_SURVEY_ANALYTICS,
  payload
});

export const clearSurveyAnalyticsId = () => ({
  type: types.CLEAR_SURVEY_ANALYTIC_IDS
});
export const clearSurveyAnalytics = () => ({
  type: types.CLEAR_SURVEY_ANALYTICS
});

export const setSurveyHeaderPanelToggleNote = (payload) => ({
  type: types.SET_SURVEY_HEADER_PANEL_TOGGLE_NOTE,
  payload
});

export const mountSurveyViewer = ({
                                    usedLocales = [],
                                    locale = "en"
                                  }) => ({
  type: types.MOUNT_SURVEY_VIEWER,
  payload: {
    usedLocales,
    locale
  }
});
export const unmountSurveyViewer = () => ({
  type: types.UNMOUNT_SURVEY_VIEWER
});

export const setSurveyLocale = ({ locale }) => ({
  type: types.SET_SURVEY_VIEWER_LANGUAGE,
  payload: { locale }
});