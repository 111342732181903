import React from "react";
import classNames from "classnames";
// UTILS
import { useIntlMessageToString } from "@/utils/IntlMessages";
// COMPONENTS
import AsrInput from "@/components/Common/FormComponentsNew/AsrInput";
// STYLES
import styles from "./styles.module.scss";


const UrlField = ({
                    name,
                    imageURL,
                    handleSetUrl,
                    isImageFromPc
                  }) => {
  const addFromUrlPlaceholder = useIntlMessageToString("assessments.addUrl");

  const inputClass = classNames({
    [styles.UrlInput]: true,
  });

  return (
    <div className={styles.UrlField}>
      <AsrInput
        inputClassName={inputClass}
        readOnly={isImageFromPc}
        isLabel={false}
        variant="outlined"
        name={name}
        placeholder={addFromUrlPlaceholder}
        pattern="https?://.+"
        title="Include https://"
        type="url"
        label=""
        errorClassName={styles.FieldError}
        tooltipError={!isImageFromPc}
        tooltipPopperProps={{
          disablePortal: true,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 15],
              },
            },
          ],
        }}
        value={imageURL}
        onChange={handleSetUrl}
      />
    </div>
  );
};

export default UrlField;