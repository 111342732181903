import enMessages from "../locales/en_US.json";
import appCommon from "../modules/appCommon/en.json";
import breadcrumbs from "../modules/breadcrumbs/breadcrumbs_US.json";
import accounts from "../modules/accounts/en.json";
import accountMembers from "../modules/accountMembers/en.json";
import policies from "../modules/policies/en.json";
import campaign from "../modules/campaign/en.json";
import notes from "../modules/notes/en.json";
import landing from "../modules/landing/en.json";
import registrations from "../modules/registrations/en.json";
import appModule from "../modules/appModule/en.json";
import auth from "../modules/auth/en.json";
import user from "../modules/user/en.json";

const EnLang = {
  messages: {
    ...appCommon,
    ...enMessages,
    ...breadcrumbs,
    ...accounts,
    ...accountMembers,
    ...policies,
    ...campaign,
    ...notes,
    ...landing,
    ...registrations,
    ...appModule,
    ...auth,
    ...user
  },
  locale: "en-US",
};
export default EnLang;
