import React, { useEffect, useState, useRef, createContext, useMemo, useCallback } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
// MATERIAL UI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import withStyles from "@mui/styles/withStyles";
import { ButtonGroup } from "@mui/material";
// SERVICES
import { pdfService } from "@/shared/services/pdf.service";
// UTILS
import createDownloadLink from "@/utils/createDownloadLink";
import IntlMessages, { useIntlMessageToString } from "@/utils/IntlMessages";
import { getDateNow } from "@/shared/utils/date";
// HOOKS
import usePermissions from "./hooks/usePermissions";
// COMPONENTS
import CircularProgress from "@/shared/UI/Loaders/CircularProgress";
import {
  Card1,
  Card2,
  Card3,
  Card4,
  Card5,
  Card6,
  Card7,
  Card10,
  Card19,
  CardAssessment,
  CardAssessmentQuestions,
} from "../../Cards";
import AsrButtonWithLoading from "@/shared/components/Buttons/AsrButtonWithLoading/AsrButtonWithLoading";
import ErrorBoundary from "@/shared/components/ErrorBoundary/ErrorBoundary";
// HELPERS
import getDataAssessment from "../helpers/getDataAssessment";
// STORE
import {
  downloadStatisticsByMember,
  getCampaignStatistics, downloadCampaignStatistics
} from "@/store/Statistics/actions";
import { getColorScheme } from "@/store/Organization/selectors";
import { getUserId } from "@/store/User/selectors";
import { getStatisticsCards } from "@/store/Statistics/selectors";
// STYLES
import "../../styles.scss";


const ToolbarModal = withStyles({
  root: {
    justifyContent: "space-between",
  },
})(Toolbar);

export const arrayCards = [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26];

export const ResultsContext = createContext();

const CampaignResultsModal = ({
                                isVisible,
                                closeModal,
                                member,
                                userName,
                                memberId,
                                forAssessorMemberProfile = false,
                                campaignId,
                                organizationId
                              }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const cards = useSelector(getStatisticsCards);

  // const [downloadXLSX, setDownloadXLSX] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [downloadReportLoading, setDownloadReportLoading] = useState({
    csv: false,
    xlsx: false
  });

  const currentUserId = useSelector(getUserId);

  const resultsContextValue = useMemo(() => ({
    memberId,
    forAssessorMemberProfile,
    currentUserId,
    campaignId,
    organizationId
  }), [memberId, forAssessorMemberProfile, currentUserId, campaignId, organizationId]);
  const isIqcRelated = useMemo(() => {
    return Number(campaignId) === 2 || Number(campaignId) === 21;
  }, [campaignId]);

  const card1Ref = useRef(), card1Title = useIntlMessageToString("reports.card1Title");
  const card2Ref = useRef(), card2Title = useIntlMessageToString("reports.card2Title");
  const card3Ref = useRef(), card3Title = useIntlMessageToString("reports.card3Title");
  const card4Ref = useRef(), card4Title = useIntlMessageToString("reports.card4Title");
  const card5Ref = useRef(), card5Title = useIntlMessageToString("reports.card5Title");
  const card6Ref = useRef(), card6Title = useIntlMessageToString("reports.card6Title");
  const card7Ref = useRef(), card7Title = useIntlMessageToString("reports.card7Title");
  const card10Ref = useRef(), card10Title = useIntlMessageToString("reports.card10Title");
  const card11Ref = useRef(), card11Title = useIntlMessageToString("reports.card11Title");
  const card12Ref = useRef(), card12Title = useIntlMessageToString("reports.card12Title");
  const card13Ref = useRef(), card13Title = useIntlMessageToString("reports.card13Title");
  const card15Ref = useRef(), card15Title = useIntlMessageToString("reports.card15Title");
  const card16Ref = useRef(), card16Title = useIntlMessageToString("reports.card16Title");
  const card17Ref = useRef(), card17Title = useIntlMessageToString("reports.card17Title");
  const card18Ref = useRef(), card18Title = useIntlMessageToString("reports.card18Title");
  const card19Ref = useRef(), card19Title = useIntlMessageToString("reports.card19Title");
  const card20Ref = useRef(), card20Title = useIntlMessageToString("reports.card20Title");
  const card21Ref = useRef(), card21Title = useIntlMessageToString("reports.card21Title");
  const card22Ref = useRef(), card22Title = useIntlMessageToString("reports.card22Title");
  const card23Ref = useRef(), card23Title = useIntlMessageToString("reports.card23Title");
  const card24Ref = useRef(), card24Title = useIntlMessageToString("reports.card24Title");
  const card25Ref = useRef(), card25Title = useIntlMessageToString("reports.card25Title");
  const card26Ref = useRef(), card26Title = useIntlMessageToString("reports.card26Title");

  const reportRef = useRef();

  const fetchStatistics = useCallback(async () => {
    const cards = arrayCards.join(",");
    setLoading(true);
    try {
      await dispatch(getCampaignStatistics({
        user_id: memberId,
        cards,
        for_auditor: forAssessorMemberProfile,
        campaign_id: campaignId,
        organization_id: organizationId
      }));

      setLoading(false);
    } catch (e) {
      const errCode = e?.payload?.response?.status;
      if (errCode === 403) {
        closeModal();
      }
    } finally {
      setLoading(false);
    }
  }, [campaignId, closeModal, dispatch, forAssessorMemberProfile, memberId, organizationId]);

  useEffect(() => {
    (async () => {
      await fetchStatistics();
    })();
  }, [dispatch, fetchStatistics, forAssessorMemberProfile, memberId]);

  const downloadReport = async (extension = "xlsx") => {
    try {
      setDownloadReportLoading({ ...downloadReportLoading, [extension.toLowerCase()]: true });
      const response = await dispatch(downloadCampaignStatistics(extension, {
        organization_id: organizationId,
        campaign_id: campaignId,
        user_id: memberId,
        for_auditor: forAssessorMemberProfile
      }));

      const fileName = `${extension.toUpperCase()} Report - ${userName} ${getDateNow("YYYYMMDDHHmm")}`;
      createDownloadLink(response?.payload?.response, `${extension.toLowerCase()}`, fileName);
    } catch (e) {
      console.log(e);
    } finally {
      setDownloadReportLoading({ ...downloadReportLoading, [extension.toLowerCase()]: false });
    }
  };

  const reportTitle = useIntlMessageToString("reports.snapshot");
  const theme = useSelector(getColorScheme);

  const downloadReportPDF = async () => {
    const defaultLogoSrc = "https://cdn.assessor.io/media/assessor/AssessorioTextInPDF.png";
    const defaultSublogoSrc = "";
    const defaultHeaderBgColor = "#333333";
    const logoSrc = theme["--asr-header-org-logo-image"];
    const sublogoSrc = theme["--asr-header-org-sub-logo-image"];
    const headerBgColor = theme["--asr-header-bg"];
    const fileName = `${userName} - assessor.io ${reportTitle} ${getDateNow()}.pdf`;

    const data = {
      cards: {
        ...cards,
        card11: getDataAssessment(cards.card11),
        card12: getDataAssessment(cards.card12),
        card13: getDataAssessment(cards.card13),
        card15: getDataAssessment(cards.card15),
        card16: getDataAssessment(cards.card16),
        card17: getDataAssessment(cards.card17),
        card18: getDataAssessment(cards.card18),
      },
      titles: {
        card11Title,
        card12Title,
        card13Title,
        card15Title,
        card16Title,
        card17Title,
        card18Title,
        card20Title,
        card21Title,
        card22Title,
        card23Title,
        card24Title,
        card25Title,
        card26Title,
      },
      headerData: {
        logoSrc,
        sublogoSrc,
        headerBgColor,
        defaultLogoSrc,
        defaultSublogoSrc,
        defaultHeaderBgColor
      },
      footerData: {
        text: fileName
      }
    };

    setDownloadPDF(true);
    await pdfService.savePDF({ data, fileName, isIqcRelated });
    setDownloadPDF(false);
  };

  const exportImage = (ref, fileName) => () => {
    exportComponentAsPNG(ref, `${fileName}.png`);
  };

  const { isDownloadCSVVisible, isDownloadPDFVisible } = usePermissions({
    forAssessorMemberProfile,
    campaignId,
    member,
    memberId
  });

  return (
    <Dialog
      fullScreen
      open={isVisible}
      onClose={closeModal}
      disableEscapeKeyDown={true}
      scroll="body"
    >
      {loading || !Object.keys(cards).length
        ? (
          <div
            className="react-responsive-modal-overlay"
            style={{ "backgroundColor": "rgba(0, 0, 0, 0.1)" }}
          >
            <CircularProgress className="react-responsive-modal-modalCenter"/>
          </div>
        ) : (
          <>
            <AppBar className="position-relative app-header__client-theme">
              <ToolbarModal>
                <IconButton onClick={closeModal} aria-label="Close" size="large">
                  <i className="zmdi zmdi-close zmdi-hc-1x text-white"/>
                </IconButton>

                <div className="campaign-results__download__container">

                  <ButtonGroup>
                    <AsrButtonWithLoading
                      isVisible={isDownloadCSVVisible}
                      loading={downloadReportLoading.xlsx}
                      buttonClassname="campaign-results__download xlsx"
                      label={<IntlMessages id="reports.downloadXLSX"/>}
                      onClick={() => downloadReport("xlsx")}
                    />
                    <AsrButtonWithLoading
                      isVisible={isDownloadPDFVisible}
                      loading={downloadPDF}
                      buttonClassname="campaign-results__download pdf"
                      label={<IntlMessages id="reports.downloadPDF"/>}
                      onClick={downloadReportPDF}
                    />
                  </ButtonGroup>
                </div>

                <Typography variant="subtitle1" className="text-white">
                  {userName}
                </Typography>
              </ToolbarModal>
            </AppBar>

            <div className="app-content__client-theme campaign-results__modal__container">
              <div
                className="campaign-results__cards-wrapper"
                ref={reportRef}
              >
                <ResultsContext.Provider value={resultsContextValue}>
                  <ErrorBoundary>
                    <Card1
                      data={cards.card1}
                      ref={card1Ref}
                      exportImage={exportImage(card1Ref, card1Title)}
                      keyForSavingCardState="card1"
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Card2
                      data={cards.card2}
                      ref={card2Ref}
                      exportImage={exportImage(card2Ref, card2Title)}
                      keyForSavingCardState="card2"
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Card3
                      data={cards.card3}
                      ref={card3Ref}
                      exportImage={exportImage(card3Ref, card3Title)}
                      keyForSavingCardState="card3"
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Card4
                      data={cards.card4}
                      ref={card4Ref}
                      isIqcRelated={isIqcRelated}
                      exportImage={exportImage(card4Ref, card4Title)}
                      keyForSavingCardState="card4"
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Card5
                      data={cards.card5}
                      ref={card5Ref}
                      exportImage={exportImage(card5Ref, card5Title)}
                      keyForSavingCardState="card5"
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Card6
                      data={cards.card6}
                      ref={card6Ref}
                      exportImage={exportImage(card6Ref, card6Title)}
                      keyForSavingCardState="card6"
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Card7
                      data={cards.card7}
                      ref={card7Ref}
                      exportImage={exportImage(card7Ref, card7Title)}
                      keyForSavingCardState="card7"
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Card10
                      data={cards.card10}
                      ref={card10Ref}
                      exportImage={exportImage(card10Ref, card10Title)}
                      keyForSavingCardState="card10"
                    />
                  </ErrorBoundary>
                  {isIqcRelated && (
                    <>
                      <ErrorBoundary>
                        <CardAssessment
                          data={getDataAssessment(cards.card11)}
                          barColor="#b74250"
                          title={card11Title}
                          ref={card11Ref}
                          exportImage={exportImage(card11Ref, card11Title)}
                          keyForSavingCardState="card11"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessmentQuestions
                          title={card20Title}
                          data={cards.card20}
                          ref={card20Ref}
                          exportImage={exportImage(card20Ref, card20Title)}
                          keyForSavingCardState="card20"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessment
                          data={getDataAssessment(cards.card12)}
                          barColor="#f1ad7b"
                          title={card12Title}
                          ref={card12Ref}
                          exportImage={exportImage(card12Ref, card12Title)}
                          keyForSavingCardState="card12"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessmentQuestions
                          title={card21Title}
                          data={cards.card21}
                          ref={card21Ref}
                          exportImage={exportImage(card21Ref, card21Title)}
                          keyForSavingCardState="card21"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessment
                          data={getDataAssessment(cards.card13)}
                          barColor="#c9d888"
                          title={card13Title}
                          ref={card13Ref}
                          exportImage={exportImage(card13Ref, card13Title)}
                          keyForSavingCardState="card13"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessmentQuestions
                          title={card22Title}
                          data={cards.card22}
                          ref={card22Ref}
                          exportImage={exportImage(card22Ref, card22Title)}
                          keyForSavingCardState="card22"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <Card19
                          data={cards.card19}
                          ref={card19Ref}
                          exportImage={exportImage(card19Ref, card19Title)}
                          keyForSavingCardState="card19"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessment
                          data={getDataAssessment(cards.card15)}
                          barColor="#66b4bd"
                          title={card15Title}
                          ref={card15Ref}
                          exportImage={exportImage(card15Ref, card15Title)}
                          keyForSavingCardState="card15"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessmentQuestions
                          title={card23Title}
                          data={cards.card23}
                          ref={card23Ref}
                          exportImage={exportImage(card23Ref, card23Title)}
                          keyForSavingCardState="card23"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessment
                          data={getDataAssessment(cards.card16)}
                          barColor="#c9b2df"
                          title={card16Title}
                          ref={card16Ref}
                          exportImage={exportImage(card16Ref, card16Title)}
                          keyForSavingCardState="card16"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessmentQuestions
                          title={card24Title}
                          data={cards.card24}
                          ref={card24Ref}
                          exportImage={exportImage(card24Ref, card24Title)}
                          keyForSavingCardState="card24"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessment
                          data={getDataAssessment(cards.card17)}
                          barColor="#f4e6a0"
                          title={card17Title}
                          ref={card17Ref}
                          exportImage={exportImage(card17Ref, card17Title)}
                          keyForSavingCardState="card17"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessmentQuestions
                          title={card25Title}
                          data={cards.card25}
                          ref={card25Ref}
                          exportImage={exportImage(card25Ref, card25Title)}
                          keyForSavingCardState="card25"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessment
                          data={getDataAssessment(cards.card18)}
                          barColor="#bca684"
                          title={card18Title}
                          ref={card18Ref}
                          exportImage={exportImage(card18Ref, card18Title)}
                          keyForSavingCardState="card18"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <CardAssessmentQuestions
                          title={card26Title}
                          data={cards.card26}
                          ref={card26Ref}
                          exportImage={exportImage(card26Ref, card26Title)}
                          keyForSavingCardState="card26"
                        />
                      </ErrorBoundary>
                    </>
                  )}
                </ResultsContext.Provider>
              </div>
            </div>
          </>
        )
      }
    </Dialog>
  );
};

const mapDispatchToProps = {
  downloadStatisticsByMember
};

CampaignResultsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  memberId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired
};

export default connect(null, mapDispatchToProps)(CampaignResultsModal);