import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
// ASSETS
import LogoImageSvg from "@/assets/images/logo-assessor.svg";
// STYLES
import styles from "./styles.module.scss";


const AsrLogo = ({
                   redirectOnClick = true,
                   showLogoImage = true,
                   showLogoText = true,
                   classes = {
                     container: "",
                     logo: "",
                     text: ""
                   }
                 }) => {
  const history = useHistory();

  const onLogoClick = () => {
    if (!redirectOnClick) return;
    history.push("/");
  };

  const logoContainerStyles = classnames({
    [styles.AsrLogoContainer]: true,
    [classes?.container]: Boolean(classes?.container)
  });

  const logoImageStyles = classnames({
    [styles.AsrLogoImage]: true,
    [styles.Clickable]: redirectOnClick,
    [classes?.logo]: Boolean(classes?.logo)
  });

  const logoTextStyles = classnames({
    [styles.AsrLogoText]: true,
    [classes?.text]: Boolean(classes?.text)
  });

  if (!showLogoImage && !showLogoText) return null;
  return (
    <div className={logoContainerStyles}>
      {showLogoImage && (
        <img
          src={LogoImageSvg}
          className={logoImageStyles}
          alt="assessor.io"
          title="assessor.io"
          onClick={onLogoClick}
        />
      )}

      {showLogoText && (
        <div className={logoTextStyles}>
          <span>assessor.</span><span>io</span>
        </div>
      )}
    </div>
  );
};


AsrLogo.propTypes = {
  redirectOnClick: PropTypes.bool,
  showLogoImage: PropTypes.bool,
  showLogoText: PropTypes.bool,
  classes: PropTypes.shape({
    logo: PropTypes.string,
    text: PropTypes.string
  })
};
export default AsrLogo;
