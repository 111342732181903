import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// STORE
import { refreshUserToken } from "@/store/User/actions";
import { getIsUserAuthenticated } from "@/store/User/selectors";
import { useLazyGetMyProfileInfoQuery } from "@/store/services/users/rtk";


const UseSyncTokenWithReduxUser = ({ redirectToSignIn = false } = {}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserAuthenticated = useSelector(getIsUserAuthenticated);
  const [getMyProfileInfo] = useLazyGetMyProfileInfoQuery();

  useEffect(() => {
    let mounted = true;
    const userToken = localStorage.getItem("userToken");
    if (!userToken) {
      mounted && redirectToSignIn && history.replace("/");
      return;
    }

    if (!isUserAuthenticated) {
      mounted && getMyProfileInfo();
    }

    const nextUpdateTime = localStorage.getItem("nextUpdateTime");
    if (!nextUpdateTime || Number(nextUpdateTime) <= Date.now()) {
      localStorage.setItem("nextUpdateTime", JSON.stringify(Date.now() + 2 * 60 * 60 * 1000)); // next update after 2h
      mounted && dispatch(refreshUserToken());
    }

    return () => {
      mounted = false;
    };
  }, [isUserAuthenticated, history, getMyProfileInfo, redirectToSignIn, refreshUserToken, dispatch]);
  return null;
};

export default UseSyncTokenWithReduxUser;