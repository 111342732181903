import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLocale } from "@/store/Settings/selectors";

const useFetchLangDependency = (fetchCallback) => {
  const { locale } = useSelector(getLocale);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await fetchCallback();
        setLoading(false);
      } catch (e) {
        // console.log(e);
      }
    })();
  }, [fetchCallback, locale]);

  return loading;
};

export default useFetchLangDependency;