import React from "react";
// CONSTANTS
import { imageSourceType } from "@/shared/constants/forms";
// COMPONENTS
import ImageInfo from "./components/ImageInfo/ImageInfo";
import FileField from "./components/FileField/FileField";
import UrlField from "./components/UrlField/UrlField";
import ImageTypeSwitcher from "./components/ImageTypeSwitcher/ImageTypeSwitcher";
// STYLES
import styles from "./styles.module.scss";


const ImageActionsSection = ({
                               inputFileId,
                               isImageFromPc,
                               setIsImageFromPc,
                               name,
                               setLogoContent,
                               logoContent,
                             }) => {
  const handleChange = ({ target }) => {
    const checked = target?.checked;
    setIsImageFromPc(!checked);
  };

  const handleSetUrl = ({ target }) => {
    const value = target?.value;
    setLogoContent((prevState) => ({
      ...prevState,
      [imageSourceType.LINK]: value
    }));
  };

  const handleSetImageFile = (file) => {
    setLogoContent((prevState) => ({
      ...prevState,
      [imageSourceType.UPLOADED_FILE]: file
    }));
  };

  return (
    <div className={styles.ImageActionsSection}>
      {/* ANT SWITCHER */}
      <ImageTypeSwitcher
        setIsImageFromPc={setIsImageFromPc}
        onChange={handleChange}
        checked={!isImageFromPc}
      />
      {/* LABEL */}
      <ImageInfo isImage={isImageFromPc}/>

      {/* UPLOAD FILE FIELD */}
      <div className={styles.ImageActionsSectionFields}>
        <FileField
          inputFileId={inputFileId}
          imageFile={logoContent?.[imageSourceType.UPLOADED_FILE]}
          setImageFile={handleSetImageFile}/>
        {/* URL FIELD */}
        <UrlField
          name={name}
          isImageFromPc={isImageFromPc}
          imageURL={logoContent?.[imageSourceType.LINK]}
          handleSetUrl={handleSetUrl}
        />
      </div>
    </div>
  );
};

export default ImageActionsSection;