import * as types from "../types";
import { baseApiUrl, defaultJSONHeaders } from "@/configs/MainConfig";
import { requestActionCreator } from "@/utils/requestActionCreator";
import { authService } from "@/shared/services/auth.service";

// MEMBERS
export * from "./campaignMembers.actions";
// INVITATION
export * from "./invitationMembers.actions";
// MEMBER ROLES
export * from "./campaignMemberRole.actions";
// ASSESSMENTS
export * from "./campaignAssessments.actions";
// DASHBOARD
export * from "./campaignDashboard.actions";

export const fetchCampaignExtendedInfo = (id) => requestActionCreator(
  types.FETCH_CAMPAIGN_EXTENDED_INFO,
  `${baseApiUrl}/campaign/extended/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const cloneCampaign = (data) => {
  const {campaign_id: campaignId} = data;
  if (!campaignId) return;

  return requestActionCreator(
    types.CLONE_CAMPAIGN,
    `${baseApiUrl}/campaign/${campaignId}/clone/`,
    "POST",
    {...authService.getAuthHeaders()},
    data
  );
};


export const fetchCampaignOnboardingAndAssessments = (campaignId) => requestActionCreator(
  types.FETCH_CAMPAIGN_ONBOARDING_AND_ASSESSMENTS,
  `${baseApiUrl}/campaign/assessments/list/?campaign_id=${campaignId}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchCampaignOboardingSurveysList = (id) => requestActionCreator(
  types.FETCH_CAMPAIGN_ONBOARDING_SURVEYS_LIST,
  `${baseApiUrl}/campaign/onboarding-module/survey/list/?campaign_id=${id}&is_archived=false&non_auth=false&is_processed=false`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchCampaignOnboardingModuleItemExtendedInfo = (id) => requestActionCreator(
  types.FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_EXTENDED_INFO,
  `${baseApiUrl}/campaign/onboarding-module/survey/extended/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchCampaignOnboardingModuleItemMultiLangExtendedInfo = (id) => requestActionCreator(
  types.FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO,
  `${baseApiUrl}/campaign/onboarding-module/survey/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

/**
 *
 * @param {Object} onboardingModuleItemData
 * @param {string} onboardingModuleItemData.name
 * @param {number} onboardingModuleItemData.survey
 * @param {number} onboardingModuleItemData.camp_module
 * @param {Object} onboardingModuleItemData.logo_image,
 * @param {string} onboardingModuleItemData.logo_url,
 * @returns {Object} {{payload: {request: {headers: *, method: *, data: *, url: *}}, meta: *, type: *}}
 */
export const createCampaingOnboardingModuleItem = (onboardingModuleItemData) => {
  const formData = new FormData();
  Object.keys(onboardingModuleItemData)
    .forEach(key => onboardingModuleItemData[key] && formData.append(key, onboardingModuleItemData[key]));
  return requestActionCreator(
    types.CREATE_CAMPAIGN_ONBOARDING_MODULE_ITEM,
    `${baseApiUrl}/campaign/onboarding-module/survey/`,
    "POST",
    { ...authService.getAuthHeaders() },
    formData
  );
};

/**
 * @param id,
 * @param {Object} onboardingModuleItemData
 * @param {string} onboardingModuleItemData.name
 * @param {Object} onboardingModuleItemData.logo_image,
 * @param {string} onboardingModuleItemData.logo_url,
 * @param {string} locale,
 * @returns (Object) {{payload: {request: {headers: *, method: *, data: *, url: *}}, meta: *, type: *}}
 */
export const updateCampaingOnboardingModuleItem = (
  id, onboardingModuleItemData, locale
) => {
  const formData = new FormData();
  Object.keys(onboardingModuleItemData)
    .forEach(key => /*onboardingModuleItemData[key] &&*/ formData.append(key, onboardingModuleItemData[key]));
  return requestActionCreator(
    types.UPDATE_CAMPAIGN_ONBOARDING_MODULE_ITEM,
    `${baseApiUrl}/campaign/onboarding-module/survey/?id=${id}`,
    "PATCH",
    { ...authService.getAuthHeaders(), "Accept-Language": `${locale}` },
    formData,
  );
};

export const deleteCampaingOnboardingModuleItem = (id) => requestActionCreator(
  types.DELETE_CAMPAIGN_ONBOARDING_MODULE_ITEM,
  `${baseApiUrl}/campaign/onboarding-module/survey/?id=${id}`,
  "DELETE",
  { ...authService.getAuthHeaders() }
);

//Fetch assessors
export const fetchCampaignMembersListAssessors = (id = 1, page = 1, page_size = 10, searchValue) => {
  let url = `${baseApiUrl}/organization/members/list/minimalistic/?members_type=assessor&organization_id=${id}&page=${page}&page_size=${page_size}`;

  if (searchValue) {
    url = `${url}&fullname_email=${searchValue}`;
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_LIST_ASSESSORS,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};


export const fetchCampaignMembersListAttachedToAssessors = (searchBy, searchValue, campaignId = "", assessorId = 1, page = 1, page_size = 10) => {
  let url = `${baseApiUrl}/campaign/member/list/?campaign_id=${campaignId}&filter_type=for_assessor&assessor_id=${assessorId}&page=${page}&page_size=${page_size}`;

  if (searchBy && searchValue) {
    url = `${url}&${[searchBy]}=${searchValue}`;
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_ATTACHED_TO_ASSESSOR,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const fetchCampaignMembersPotentialAttachedToAssessors = (searchBy, searchValue, campaignId = "", assessorId = 1, page = 1, page_size = 10) => {
  let url = `${baseApiUrl}/campaign/member/list/?campaign_id=${campaignId}&filter_type=for_assessor&assessor_id=${assessorId}&potential=true&page=${page}&page_size=${page_size}`;

  if (searchBy && searchValue) {
    url = `${url}&${[searchBy]}=${searchValue}`;
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_POTENTIAL_ATTACHED_TO_ASSESSOR,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
    {},
    { takeLatest: true }
  );
};

export const fetchCampaignMembersUnassignedToAssessors = ({
  campaignId = "",
  assessorId = 1,
  searchValue,
  affil,
  excludeIds = [],
  page = 1,
  pageSize = 10
}) => {
  let url = `${baseApiUrl}/campaign/member/list/auditor-assignments/?campaign_id=${campaignId}&auditor_unassigned=${assessorId}&page=${page}&page_size=${pageSize}`;

  if (affil) {
    url = `${url}&assessments_results=${affil}`;
  }

  if (excludeIds.length) {
    url = `${url}&exclude_ids=${excludeIds}`;
  }

  if (searchValue) {
    url = `${url}&fullname_email=${searchValue}`;
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_UNASSIGNED_TO_ASSESSOR,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
    {},
    { takeLatest: true }
  );
};

export const fetchCampaignMembersAssignedToAssessors = ({
  campaignId = "",
  assessorId = 1,
  searchValue,
  affil,
  excludeIds = [],
  page = 1,
  pageSize = 10
}) => {
  let url = `${baseApiUrl}/campaign/member/list/auditor-assignments/?campaign_id=${campaignId}&auditor_assigned=${assessorId}&page=${page}&page_size=${pageSize}`;

  if (affil) {
    url = `${url}&assessments_results=${affil}`;
  }

  if (excludeIds.length) {
    url = `${url}&exclude_ids=${excludeIds}`;
  }

  if (searchValue) {
    url = `${url}&fullname_email=${searchValue}`;
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBERS_ASSIGNED_TO_ASSESSOR,
    url,
    "GET",
    { ...authService.getAuthHeaders() },
    {},
    { takeLatest: true }
  );
};

export const setUsersWithinCampaign = (payload) => {
  return {
    type: types.SET_MEMBERS_UNASSIGNED_TO_ASSESSOR,
    payload
  };
};

export const setAlreadyAssigned = (payload) => {
  return {
    type: types.SET_MEMBERS_ASSIGNED_TO_ASSESSOR,
    payload
  };
};

export const setTotalAssignedUsersCount = (payload) => {
  return {
    type: types.SET_AVAILABLE_ASSIGNED_COUNT,
    payload
  };
};

export const setTotalUnassignedUsersCount = (payload) => {
  return {
    type: types.SET_AVAILABLE_UNASSIGNED_COUNT,
    payload
  };
};

export const setAssignmentsSelectedMember = (payload) => {
  return {
    type: types.SET_ASSIGNMENTS_SELECTED_MEMBER,
    payload
  };
};

export const cleanAttachedToAssessors = () => {
  return {
    type: types.CLEAN_ATTACHED_TO_ASSESSOR,
  };
};

export const deleteAuditorAssignmentsInfo = () => {
  return {
    type: types.DELETE_AUDITOR_ASSIGNMENTS_INFO,
  };
};

export const cleanMembersAssignedToAssessors = () => {
  return {
    type: types.CLEAN_ASSIGNED_TO_ASSESSOR,
  };
};

export const cleanMembersUnassignedToAssessors = () => {
  return {
    type: types.CLEAN_UNASSIGNED_TO_ASSESSOR,
  };
};

export const setCheckedAssignedMembersToAssessor = (payload) => {
  return {
    type: types.SET_ASSIGNED_TO_ASSESSOR_CHECKED,
    payload
  };
};

export const setCheckedUnassignedMembersToAssessor = (payload) => {
  return {
    type: types.SET_UNASSIGNED_TO_ASSESSOR_CHECKED,
    payload
  };
};

export const setAssignedMembersSearch = (payload) => {
  return {
    type: types.SET_ASSIGNED_MEMBER_SEARCH,
    payload
  };
};

export const setUnassignedMembersSearch = (payload) => {
  return {
    type: types.SET_UNASSIGNED_MEMBER_SEARCH,
    payload
  };
};

export const setAlreadyAssignedAdded = (payload) => {
  return {
    type: types.SET_ASSIGNED_MEMBERS_ADDED,
    payload
  };
};

export const setUsersWithinCampaignAdded = (payload) => {
  return {
    type: types.SET_UNASSIGNED_MEMBERS_ADDED,
    payload
  };
};

export const attachedUsersToAssessors = (members_ides, campaignId, assessorId) => requestActionCreator(
  types.ATTACH_USERS_ASSESSOR,
  `${baseApiUrl}/campaign/members/relation-to-assessor/`,
  "POST",
  { ...authService.getAuthHeaders() },
  { members_ides, campaign: campaignId, assessor: assessorId }
);

export const deleteAssessorUsers = (members_ides, campaignId, assessorId) => requestActionCreator(
  types.DELETE_ASSESSOR_USERS,
  `${baseApiUrl}/campaign/members/relation-to-assessor/`,
  "DELETE",
  { ...authService.getAuthHeaders() },
  { members_ides, campaign: campaignId, assessor: assessorId }
);

// export const updateCampaignInviteMember = (memberId, /*userId, organizationId,  is_owner=false,*/ role_name="MyRolee",role_type=3/*, is_accepted=true*/) => requestActionCreator(
//     types.UPDATE_CAMPAIGN_MEMBER,
//     `${baseApiUrl}/campaign/member/?id=${memberId}`,
//     'PATCH',
//     {...defaultJSONHeadersWithAuth()},
//     {/*user: userId, organization: organizationId, is_owner, is_accepted,*/ role_name, role_type}
// );
//
// export const deleteCampaignInviteMember = (id) => requestActionCreator(
//     types.DELETE_CAMPAIGN_MEMBER,
//     `${baseApiUrl}/campaign/member/id=${id}`,
//     'DELETE',
//     {...defaultJSONHeadersWithAuth()}
// );

export const fetchTerm = (term) => requestActionCreator(
  types.FETCH_TERM,
  `https://bimdictionary.com/api/v1/terms/by_title?title=${encodeURIComponent(term)}&lang=${defaultJSONHeaders["Accept-Language"]}`,
  "GET",
  {}
  // {...defaultJSONHeaders}
);

export const fetchCampaignPotentialMembersList = (campaignId = "", value = "", page, page_size = 15) => requestActionCreator(
  types.FETCH_CAMPAIGN_POTENTIAL_MEMBERS_LIST,
  `${baseApiUrl}/campaign/member/potential/list/?campaign_id=${campaignId}&fullname_email=${value}&page=${page}&page_size=${page_size}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchCampaignPotentialMembersAnswersList = (campaignId = "", page = 1, page_size = 5) => requestActionCreator(
  types.FETCH_CAMPAIGN_POTENTIAL_MEMBERS_ANSWERS_LIST,
  `${baseApiUrl}/campaign/onboarding-module/survey/answers/non-auth/list/?campaign_id=${campaignId}&page=${page}&page_size=${page_size}&status=1&is_archived=false`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchCampaignPotentialMemberAnswersExtended = (id) => requestActionCreator(
  types.FETCH_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS_EXTENDED,
  `${baseApiUrl}/campaign/onboarding-module/survey/answers/non-auth/extended/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const acceptCampaignPotentialMembersAnswers = (id) => requestActionCreator(
  types.ACCEPT_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS,
  `${baseApiUrl}/campaign/onboarding-module/survey/answers/non-auth/action/accept/?id=${id}`,
  "POST",
  { ...authService.getAuthHeaders() }
);

export const rejectCampaignPotentialMembersAnswers = (id) => requestActionCreator(
  types.REJECT_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS,
  `${baseApiUrl}/campaign/onboarding-module/survey/answers/non-auth/action/reject/?id=${id}`,
  "POST",
  { ...authService.getAuthHeaders() }
);

export const fetchCampaignMemberRoleTypes = () => requestActionCreator(
  types.FETCH_CAMPAIGN_MEMBER_ROLE_TYPES,
  `${baseApiUrl}/core/choice-lists/?key=CAMPAIGN_MEMBER_ROLE_TYPES`,
  "GET",
  { ...authService.getAuthHeaders() }
);


export const fetchCampaignInviteMemberRoleTypesByCurrentUser = (campaignId = "") => requestActionCreator(
  types.FETCH_CAMPAIGN_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER,
  `${baseApiUrl}/campaign/members/invitation/available-roles/?campaign_id=${campaignId}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const checkCampaignPasscode = (campaignId = "", password) => requestActionCreator(
  types.CHECK_CAMPAIGN_PASSCODE,
  `${baseApiUrl}/campaign/check-password/?id=${campaignId}`,
  "POST",
  { ...authService.getAuthHeaders() },
  { password }
);


export const fetchCampaignNonAuthAnswersList = (campaignId = "", page = 1, page_size = 5) => requestActionCreator(
  types.FETCH_NON_AUTH_ANSWERS_LIST,
  `${baseApiUrl}/campaign/assessment/non-auth-answer/list/?campaign_id=${campaignId}&page=${page}&page_size=${page_size}&status=1&is_archived=false`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const fetchCampaignNonAuthAnswersExtended = (id) => requestActionCreator(
  types.FETCH_NON_AUTH_ANSWER_EXTENDED_INFO,
  `${baseApiUrl}/campaign/assessment/non-auth-answer/extended/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const updateRegistrationCampaignNonAuthAnswer = (dataSend) => requestActionCreator(
  types.UPDATE_REGISTRATION_CAMPAIGN_NON_AUTH_ANSWER,
  `${baseApiUrl}/campaign/assessment/non-auth-answer/manage/singular/`,
  "POST",
  { ...authService.getAuthHeaders() },
  dataSend,
);

export const submitCampaignNonAuthAnswers = (id, action) => requestActionCreator(
  types.ACCEPT_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS,
  `${baseApiUrl}/campaign/assessment/non-auth-answer/action/${action}/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() }
);

// SEND registration to crm
export const sendRegistrationToCrm = (data) => {
  return requestActionCreator(
    types.SEND_REGISTRATION_TO_CRM,
    `${baseApiUrl}/organization/iqc-only/registration/send-to-crm/`,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};

export const fetchCampaignLog = (campaign_id, filters, page = 1, page_size = 10) => {
  let url = `${baseApiUrl}/log/list/?campaign_id=${campaign_id}`;
  if (filters) {
    for (const [filterKey, filterValue] of Object.entries(filters)) {
      url = url + `&${filterKey}=${filterValue}`;
    }
  }

  return requestActionCreator(
    types.FETCH_CAMPAIGN_LOG,
    `${url}&page=${page}&page_size=${page_size}&sort_by=-id`,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const updateCampaignLogFilters = (payload) => ({
  type: types.UPDATE_CAMPAIGN_LOG_FILTERS,
  payload
});

export const cleanCampaignLog = () => ({
  type: types.CLEAN_CAMPAIGN_LOG,
});

export const fetchUsersRelatedToCampaign = (campaignId, page = 1, pageSize = 10) => {
  let url = `${baseApiUrl}/user/related_to/campaign/`;
  url += `?campaign_id=${campaignId}`;
  url += `&page=${page}`;
  url += `&page_size=${pageSize}`;

  return requestActionCreator(
    types.FETCH_USERS_RELATED_TO_CAMPAIGN,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};


// INITIALIZE SELECTED COLUMNS
export const initializeSelectedColumn = (payload) => ({ type: types.INITIALIZE_SELECTED_COLUMNS, payload });
// SET SELECTED COLUMNS
export const setSelectedColumn = (payload) => ({ type: types.SET_SELECTED_COLUMN, payload });
// EDIT
export const editSelectedColumn = (payload) => ({ type: types.EDIT_SELECTED_COLUMN, payload });
// REMOVE SELECTED COLUMNS
export const removeSelectedColumn = (payload) => ({ type: types.REMOVE_SELECTED_COLUMN, payload });

// SET TABLE CONFIG
export const setTableConfig = (body) => requestActionCreator(
  types.SET_TABLE_CONFIG,
  `${baseApiUrl}/campaign/settings/table-config/`,
  "POST",
  { ...authService.getAuthHeaders() },
  body
);

export const restoreTableConfig = (body) => requestActionCreator(
  types.RESTORE_TABLE_CONFIG,
  `${baseApiUrl}/campaign/settings/table-config/default/`,
  "POST",
  { ...authService.getAuthHeaders() },
  body
);

export const fetchTableConfig = (id) => requestActionCreator(
  types.FETCH_TABLE_CONFIG,
  `${baseApiUrl}/campaign/settings/table-config/?campaign_id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() },
);

export const resetAssessmentQuestionState = () => ({
  type: types.RESET_ASSESSMENT_QUESTION_MANAGEMENT_STATE
});

export const deleteCampaignMembersList = () => ({
  type: types.DELETE_CAMPAIGN_MEMBERS_LIST
});
// CAMPAIGN BASE INFO
export const setCampaignBaseInfo = (payload) => ({
  type: types.SET_CAMPAIGN_BASE_INFO,
  payload
});

export const resetCampaignBaseInfo = (payload) => ({
  type: types.RESET_CAMPAIGN_BASE_INFO,
  payload
});

// CAMPAIGN PARTICIPATION
export const setCampaignParticipantMe = (payload) => ({
  type: types.SET_CAMPAIGN_PARTICIPANT_ME,
  payload
});
