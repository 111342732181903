import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// UTILS
import IntlMessages from "../../../../utils/IntlMessages";


const NestedLinks = ({
                       links
                     }) => {
  const location = useLocation();
  const locationHash = location.hash; //|| `#${location.pathname.split("/").pop()}`;

  return (
      <>
        {links.map((item, index) => {
          const {
            isVisible = true,
            hash = `#${location.pathname.substring(1)}`,
            label,
            className = "",
            to,
            onClick,
          } = item;
          if (!isVisible) return null;
          const isActive = () => locationHash === hash;
          return (
              <NavLink
                  key={`${index}_${label}`}
                  className={`nested-link ${className}`}
                  to={to}
                  isActive={isActive}
                  onClick={onClick}
              >
                <IntlMessages id={label}/>
              </NavLink>
          );
        })}
      </>
  );
};

export default NestedLinks;