export const FETCH_CAMPAIGN_EXTENDED_INFO = "FETCH_CAMPAIGN_EXTENDED_INFO";
export const FETCH_CAMPAIGN_MEMBERSHIP_INFO = "FETCH_CAMPAIGN_MEMBERSHIP_INFO";
export const FETCH_CAMPAIGN_MULTI_LANG_EXTENDED_INFO = "FETCH_CAMPAIGN_MULTI_LANG_EXTENDED_INFO";

export const UPDATE_CAMPAIGN_DESCRIPTION = "UPDATE_CAMPAIGN_DESCRIPTION";
export const CLONE_CAMPAIGN = "CLONE_CAMPAIGN";

export const REJECT_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS = "REJECT_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS";
export const ACCEPT_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS = "ACCEPT_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS";
export const ATTACH_USERS_ASSESSOR = "ATTACH_USERS_ASSESSOR";
export const DELETE_ASSESSOR_USERS = "DELETE_ASSESSOR_USERS";
export const FETCH_CAMPAIGN_POTENTIAL_MEMBERS_ANSWERS_LIST = "FETCH_CAMPAIGN_POTENTIAL_MEMBERS_ANSWERS_LIST";
export const FETCH_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS_EXTENDED = "FETCH_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS_EXTENDED";
export const FETCH_CAMPAIGN_POTENTIAL_MEMBERS_LIST = "FETCH_CAMPAIGN_POTENTIAL_MEMBERS_LIST";

export const FETCH_CAMPAIGN_MEMBERS_LIST = "FETCH_CAMPAIGN_MEMBERS_LIST";
export const FETCH_CAMPAIGN_MEMBERS_LIST_MINIMALISTIC = "FETCH_CAMPAIGN_MEMBERS_LIST_MINIMALISTIC";
export const FETCH_CAMPAIGN_MEMBERS_TABLE = "FETCH_CAMPAIGN_MEMBERS_TABLE";
export const FETCH_CAMPAIGN_MEMBERS_CARD = "FETCH_CAMPAIGN_MEMBERS_CARD";
export const GET_CAMPAIGN_MEMBER_FOR_EDITING = "GET_CAMPAIGN_MEMBER_FOR_EDITING";
export const SET_CAMPAIGN_MEMBERS_SORT_ORDER = "SET_CAMPAIGN_MEMBERS_SORT_ORDER";
export const SET_CAMPAIGN_MEMBERS_PAGINATION = "SET_CAMPAIGN_MEMBERS_PAGINATION";
export const SET_CAMPAIGN_MEMBERS_PAGE_VIEW_TYPE = "SET_CAMPAIGN_MEMBERS_PAGE_VIEW_TYPE";
export const RESET_CAMPAIGN_MEMBERS_PAGINATION = "RESET_CAMPAIGN_MEMBERS_PAGINATION";
export const RESET_CAMPAIGN_MEMBERS_SORT_ORDER = "RESET_CAMPAIGN_MEMBERS_SORT_ORDER";
export const DELETE_CAMPAIGN_MEMBERS_LIST = "DELETE_CAMPAIGN_MEMBERS_LIST";
export const FETCH_CAMPAIGN_MEMBERS_LIST_ASSESSORS = "FETCH_CAMPAIGN_MEMBERS_LIST_ASSESSORS";
export const FETCH_CAMPAIGN_MEMBERS_LIST_FOR_AUTOCOMPLETE = "FETCH_CAMPAIGN_MEMBERS_LIST_FOR_AUTOCOMPLETE";
export const TOGGLE_CAMPAIGN_MEMBER_MODAL = "TOGGLE_CAMPAIGN_MEMBER_MODAL";
export const SET_CAMPAIGN_MEMBERS_PAGE_BAR_ADD_BUTTON_VISIBLE = "SET_CAMPAIGN_MEMBERS_PAGE_BAR_ADD_BUTTON_VISIBLE";

export const FETCH_CAMPAIGN_MEMBERS_ATTACHED_TO_ASSESSOR = "FETCH_CAMPAIGN_MEMBERS_ATTACHED_TO_ASSESSOR";
export const FETCH_CAMPAIGN_MEMBERS_POTENTIAL_ATTACHED_TO_ASSESSOR = "FETCH_CAMPAIGN_MEMBERS_POTENTIAL_ATTACHED_TO_ASSESSOR";
export const CREATE_CAMPAIGN_MEMBER = "CREATE_CAMPAIGN_MEMBER";
export const UPDATE_CAMPAIGN_MEMBER = "UPDATE_CAMPAIGN_MEMBER";
export const DELETE_CAMPAIGN_MEMBER = "DELETE_CAMPAIGN_MEMBER";
export const SET_ASSIGNMENTS_SELECTED_MEMBER = "SET_ASSIGNMENTS_SELECTED_MEMBER";
export const FETCH_CAMPAIGN_MEMBERS_UNASSIGNED_TO_ASSESSOR = "FETCH_CAMPAIGN_MEMBERS_UNASSIGNED_TO_ASSESSOR";
export const FETCH_CAMPAIGN_MEMBERS_ASSIGNED_TO_ASSESSOR = "FETCH_CAMPAIGN_MEMBERS_ASSIGNED_TO_ASSESSOR";

export const FETCH_CAMPAIGN_MEMBER_INVITATIONS_LIST = "FETCH_CAMPAIGN_MEMBER_INVITATIONS_LIST";
export const FETCH_CAMPAIGN_MEMBER_INVITATIONS_TABLE = "FETCH_CAMPAIGN_MEMBER_INVITATIONS_TABLE";
export const SET_CAMPAIGN_MEMBER_INVITATIONS_LIST_PAGINATION = "SET_CAMPAIGN_MEMBER_INVITATIONS_LIST_PAGINATION";
export const SET_CAMPAIGN_MEMBER_INVITATIONS_SORT_ORDER = "SET_CAMPAIGN_MEMBER_INVITATIONS_SORT_ORDER";
export const CREATE_CAMPAIGN_MEMBER_INVITATION = "CREATE_CAMPAIGN_MEMBER_INVITATION";
export const UPDATE_CAMPAIGN_MEMBER_INVITATION = "UPDATE_CAMPAIGN_MEMBER_INVITATION";
export const RESEND_CAMPAIGN_MEMBER_INVITATION = "RESEND_CAMPAIGN_MEMBER_INVITATION";
export const DELETE_CAMPAIGN_MEMBER_INVITATION = "DELETE_CAMPAIGN_MEMBER_INVITATION";
export const TOGGLE_CAMPAIGN_MEMBER_INVITATIONS_MODAL = "TOGGLE_CAMPAIGN_MEMBER_INVITATIONS_MODAL";

export const FETCH_TERM = "FETCH_TERM";
export const FETCH_CAMPAIGN_MEMBER_ROLE_TYPES = "FETCH_CAMPAIGN_MEMQBER_ROLE_TYPES";
export const FETCH_CAMPAIGN_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER = "FETCH_CAMPAIGN_INVITATION_MEMBER_ROLE_TYPES_BY_CURRENT_USER";
export const FETCH_CAMPAIGN_PASSCODE = "FETCH_CAMPAIGN_PASSCODE";
export const CHECK_CAMPAIGN_PASSCODE = "CHECK_CAMPAIGN_PASSCODE";

export const FETCH_CAMPAIGN_ONBOARDING_AND_ASSESSMENTS = "FETCH_CAMPAIGN_ONBOARDING_AND_ASSESSMENTS";
export const FETCH_CAMPAIGN_ONBOARDING_ANSWERS_LIST = "FETCH_CAMPAIGN_ONBOARDING_ANSWERS_LIST";
export const FETCH_CAMPAIGN_ONBOARDING_ANSWER_EXTENDED_INFO = "FETCH_CAMPAIGN_ONBOARDING_ANSWER_EXTENDED_INFO";
export const CREATE_CAMPAIGN_ONBOARDING_ANSWER = "CREATE_CAMPAIGN_ONBOARDING_ANSWER";
export const UPDATE_CAMPAIGN_ONBOARDING_ANSWER = "UPDATE_CAMPAIGN_ONBOARDING_ANSWER";
export const DELETE_CAMPAIGN_ONBOARDING_ANSWER = "DELETE_CAMPAIGN_ONBOARDING_ANSWER";

export const FETCH_CAMPAIGN_ONBOARDING_SURVEYS_LIST = "FETCH_CAMPAIGN_ONBOARDING_SURVEYS_LIST";
export const FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO = "FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO";
export const FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_EXTENDED_INFO = "FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_EXTENDED_INFO";
export const CREATE_CAMPAIGN_ONBOARDING_MODULE_ITEM = "CREATE_CAMPAIGN_ONBOARDING_MODULE_ITEM";
export const UPDATE_CAMPAIGN_ONBOARDING_MODULE_ITEM = "UPDATE_CAMPAIGN_ONBOARDING_MODULE_ITEM";
export const DELETE_CAMPAIGN_ONBOARDING_MODULE_ITEM = "DELETE_CAMPAIGN_ONBOARDING_MODULE_ITEM";

export const FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_AND_CAMPAIGN_EXTENDED_INFO = "FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_AND_CAMPAIGN_EXTENDED_INFO";

export const FETCH_CAMPAIGN_ASSESSMENT_SURVEYS_LIST = "FETCH_CAMPAIGN_ASSESSMENT_SURVEYS_LIST";
export const FETCH_CAMPAIGN_ASSESSMENT_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO = "FETCH_CAMPAIGN_ASSESSMENT_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO";
export const FETCH_CAMPAIGN_ASSESSMENT_MODULE_ITEM_EXTENDED_INFO = "FETCH_CAMPAIGN_ASSESSMENT_MODULE_ITEM_EXTENDED_INFO";
export const CREATE_CAMPAIGN_ASSESSMENT_MODULE_ITEM = "CREATE_CAMPAIGN_ASSESSMENT_MODULE_ITEM";
export const UPDATE_CAMPAIGN_ASSESSMENT_MODULE_ITEM = "UPDATE_CAMPAIGN_ASSESSMENT_MODULE_ITEM";
export const DELETE_CAMPAIGN_ASSESSMENT_MODULE_ITEM = "DELETE_CAMPAIGN_ASSESSMENT_MODULE_ITEM";

export const FETCH_CAMPAIGN_ASSESSMENT_ANSWER_EXTENDED_INFO = "FETCH_CAMPAIGN_ASSESSMENT_ANSWER_EXTENDED_INFO";
export const CREATE_CAMPAIGN_ASSESSMENT_ANSWER = "CREATE_CAMPAIGN_ASSESSMENT_ANSWER";
export const UPDATE_CAMPAIGN_ASSESSMENT_ANSWER = "UPDATE_CAMPAIGN_ASSESSMENT_ANSWER";

export const FETCH_CAMPAIGN_USERS_ROLES_LIST = "FETCH_CAMPAIGN_USERS_ROLES_LIST";
export const FETCH_CAMPAIGN_USERS_ROLES_TABLE = "FETCH_CAMPAIGN_USERS_ROLES_TABLE";
export const FETCH_CAMPAIGN_USERS_ROLES_FOR_AUTOCOMPLETE = "FETCH_CAMPAIGN_USERS_ROLES_FOR_AUTOCOMPLETE";
export const SET_CAMPAIGN_USERS_ROLES_LIST_PAGINATION = "SET_CAMPAIGN_USERS_ROLES_LIST_PAGINATION";
export const SET_CAMPAIGN_MEMBERS_ROLES_SORT_ORDER = "SET_CAMPAIGN_MEMBERS_ROLES_SORT_ORDER";
export const FETCH_CAMPAIGN_USER_ROLE_MULTI_LANG_INFO = "FETCH_CAMPAIGN_USER_ROLE_MULTI_LANG_INFO";
export const CREATE_CAMPAIGN_USER_ROLE = "CREATE_CAMPAIGN_USER_ROLE";
export const UPDATE_CAMPAIGN_USER_ROLE = "UPDATE_CAMPAIGN_USER_ROLE";
export const DELETE_CAMPAIGN_USER_ROLE = "DELETE_CAMPAIGN_USER_ROLE";
export const FETCH_CAMPAIGN_PERMISSIONS_LIST = "FETCH_CAMPAIGN_PERMISSIONS_LIST";
export const TOGGLE_CAMPAIGN_MEMBER_ROLE_MODAL = "TOGGLE_CAMPAIGN_MEMBER_ROLE_MODAL";

export const FETCH_CAMPAIGN_ASSESSMENTS = "FETCH_CAMPAIGN_ASSESSMENTS";
export const FETCH_CAMPAIGN_ASSESSMENTS_CARDS = "FETCH_CAMPAIGN_ASSESSMENTS_CARDS";
export const FETCH_CAMPAIGN_ASSESSMENTS_TABLE = "FETCH_CAMPAIGN_ASSESSMENTS_TABLE";
export const FETCH_CAMPAIGN_ASSESSMENTS_MINIMALISTIC = "FETCH_CAMPAIGN_ASSESSMENTS_MINIMALISTIC";
export const SET_CAMPAIGNS_ASSESSMENTS_CARDS_PAGINATION = "SET_CAMPAIGNS_ASSESSMENTS_CARDS_PAGINATION";
export const SET_CAMPAIGNS_ASSESSMENTS_PAGINATION = "SET_CAMPAIGNS_ASSESSMENTS_PAGINATION";
export const SET_CAMPAIGN_ASSESSMENT_TABLE_SIZE = "SET_CAMPAIGN_ASSESSMENT_TABLE_SIZE";
export const SET_CAMPAIGN_ASSESSMENT_FILTERS = "SET_CAMPAIGN_ASSESSMENT_FILTERS";
export const SET_CAMPAIGN_ASSESSMENT_PAGE_VIEW_TYPE = "SET_CAMPAIGN_ASSESSMENT_PAGE_VIEW_TYPE";
export const SET_CAMPAIGN_ASSESSMENT_SORT_ORDER = "SET_CAMPAIGN_ASSESSMENT_SORT_ORDER";
export const RESET_CAMPAIGN_ASSESSMENT_STATE = "RESET_CAMPAIGN_ASSESSMENT_STATE";
export const TOGGLE_CAMPAIGN_ASSESSMENTS_MODAL = "TOGGLE_CAMPAIGN_ASSESSMENTS_MODAL";
export const FETCH_ASSESSMENT_EXTENDED_INFO_BY_ID = "FETCH_ASSESSMENT_EXTENDED_INFO_BY_ID";
export const FETCH_ASSESSMENT_EXTENDED_INFO_BY_ID_FOR_ASSESSOR = "FETCH_ASSESSMENT_EXTENDED_INFO_BY_ID_FOR_ASSESSOR";
export const FETCH_EXTENDED_INFO_OF_ASSESSMENT_ANSWER_FOR_ASSESSOR = "FETCH_EXTENDED_INFO_OF_ASSESSMENT_ANSWER_FOR_ASSESSOR";
export const FETCH_ASSESSMENT_MULTI_LANG_INFO = "FETCH_ASSESSMENT_MULTI_LANG_INFO";
export const UPDATE_ASSESSMENT_INFO = "UPDATE_ASSESSMENT_INFO";
export const DELETE_ASSESSMENT_INFO = "DELETE_ASSESSMENT_INFO";
export const CREATE_ASSESSMENT_ANSWER = "CREATE_ASSESSMENT_ANSWER";
export const FETCH_ASSESSMENT_ANSWER_EXTENDED_INFO = "FETCH_ASSESSMENT_ANSWER_EXTENDED_INFO";

export const UPDATE_ASSESSMENT_ANSWER = "UPDATE_ASSESSMENT_ANSWER";

export const FETCH_CAMPAIGN_MEMBER_EXTENDED_INFO = "FETCH_CAMPAIGN_MEMBER_EXTENDED_INFO";
export const FETCH_NON_AUTH_ANSWERS_LIST = "FETCH_NON_AUTH_ANSWERS_LIST";
export const FETCH_NON_AUTH_ANSWER_EXTENDED_INFO = "FETCH_NON_AUTH_ANSWER_EXTENDED_INFO";
export const UPDATE_REGISTRATION_CAMPAIGN_NON_AUTH_ANSWER = "UPDATE_REGISTRATION_CAMPAIGN_NON_AUTH_ANSWER";
export const SUBMIT_NON_AUTH_ANSWER = "SUBMIT_NON_AUTH_ANSWER";
export const CREATE_ASSESSMENT_ANSWER_BY_ASSESSOR = "CREATE_ASSESSMENT_ANSWER_BY_ASSESSOR";
export const UPDATE_ASSESSMENT_ANSWER_BY_ASSESSOR = "UPDATE_ASSESSMENT_ANSWER_BY_ASSESSOR";
export const CLEAN_ATTACHED_TO_ASSESSOR = "CLEAN_ATTACHED_TO_ASSESSOR";
export const CLEAN_ASSIGNED_TO_ASSESSOR = "CLEAN_ASSIGNED_TO_ASSESSOR";
export const CLEAN_UNASSIGNED_TO_ASSESSOR = "CLEAN_UNASSIGNED_TO_ASSESSOR";
export const DELETE_AUDITOR_ASSIGNMENTS_INFO = "DELETE_AUDITOR_ASSIGNMENTS_INFO";
export const SET_ASSIGNED_TO_ASSESSOR_CHECKED = "SET_ASSIGNED_TO_ASSESSOR_CHECKED";
export const SET_UNASSIGNED_TO_ASSESSOR_CHECKED = "SET_UNASSIGNED_TO_ASSESSOR_CHECKED";
export const SET_ASSIGNED_MEMBER_SEARCH = "SET_ASSIGNED_MEMBER_SEARCH";
export const SET_UNASSIGNED_MEMBER_SEARCH = "SET_UNASSIGNED_MEMBER_SEARCH";
export const SET_ASSIGNED_MEMBERS_ADDED = "SET_ASSIGNED_MEMBERS_ADDED";
export const SET_UNASSIGNED_MEMBERS_ADDED = "SET_UNASSIGNED_MEMBERS_ADDED";
export const SET_MEMBERS_UNASSIGNED_TO_ASSESSOR = "SET_MEMBERS_UNASSIGNED_TO_ASSESSOR";
export const SET_MEMBERS_ASSIGNED_TO_ASSESSOR = "SET_MEMBERS_ASSIGNED_TO_ASSESSOR";
export const SET_AVAILABLE_ASSIGNED_COUNT = "SET_AVAILABLE_ASSIGNED_COUNT";
export const SET_AVAILABLE_UNASSIGNED_COUNT = "SET_AVAILABLE_UNASSIGNED_COUNT";

export const FETCH_CAMPAIGN_LOG = "FETCH_CAMPAIGN_LOG";
export const UPDATE_CAMPAIGN_LOG_FILTERS = "UPDATE_CAMPAIGN_LOG_FILTERS";
export const CLEAN_CAMPAIGN_LOG = "CLEAN_CAMPAIGN_LOG";

export const FETCH_CAMPAIGN_DASHBOARD_HEADER = "FETCH_CAMPAIGN_DASHBOARD_HEADER";
export const FETCH_CAMPAIGN_DASHBOARD_COMPOSITION = "FETCH_CAMPAIGN_DASHBOARD_COMPOSITION";
export const FETCH_CAMPAIGN_DASHBOARD_COMPOSITION_BY_SCROLL = "FETCH_CAMPAIGN_DASHBOARD_COMPOSITION_BY_SCROLL";
export const FETCH_CAMPAIGN_DASHBOARD_MANAGERS = "FETCH_CAMPAIGN_DASHBOARD_MANAGERS";
export const FETCH_CAMPAIGN_DASHBOARD_MANAGERS_BY_SCROLL = "FETCH_CAMPAIGN_DASHBOARD_MANAGERS_BY_SCROLL";
export const FETCH_CAMPAIGN_DASHBOARD_PARTICIPANTS = "FETCH_CAMPAIGN_DASHBOARD_PARTICIPANTS";
export const FETCH_CAMPAIGN_DASHBOARD_AUDITOR_ACTIONS = "FETCH_CAMPAIGN_DASHBOARD_AUDITOR_ACTIONS";
export const FETCH_CAMPAIGN_DASHBOARD_EVENTS = "FETCH_CAMPAIGN_DASHBOARD_EVENTS";
export const FETCH_CAMPAIGN_DASHBOARD_EVENTS_BY_SCROLL = "FETCH_CAMPAIGN_DASHBOARD_EVENTS_BY_SCROLL";

export const CLEAN_ASSESSMENT_MULTI_LANG_INFO = "CLEAN_ASSESSMENT_MULTI_LANG_INFO";

export const FETCH_USERS_RELATED_TO_CAMPAIGN = "FETCH_USERS_RELATED_TO_CAMPAIGN";
// REFRESH Campaign Permissions
export const REFRESH_CAMPAIGN_PERMISSIONS = "REFRESH_CAMPAIGN_PERMISSIONS";
// SELECT CAMPAIGN TABLE COLUMNS
export const SET_SELECTED_COLUMN = "SET_SELECTED_COLUMN";
// EDIT SELECTED COLUMN
export const EDIT_SELECTED_COLUMN = "EDIT_SELECTED_COLUMN";
// SELECT CAMPAIGN TABLE COLUMNS
export const REMOVE_SELECTED_COLUMN = "REMOVE_SELECTED_COLUMN";
// INITIALIZE SELECTED COLUMNS
export const INITIALIZE_SELECTED_COLUMNS = "INITIALIZE_SELECTED_COLUMNS";
// TABLE CONFIG
export const SET_TABLE_CONFIG = "SET_TABLE_CONFIG";
//
export const SEND_REGISTRATION_TO_CRM = "SEND_REGISTRATION_TO_CRM";
export const RESTORE_TABLE_CONFIG = "RESTORE_TABLE_CONFIG";
export const FETCH_TABLE_CONFIG = "FETCH_TABLE_CONFIG";

// assessment Make Uncompleted
export const ASSESSMENT_MAKE_UNCOMPLETED = "ASSESSMENT_MAKE_UNCOMPLETED";
export const ASSESSMENT_CHANGE_COMPLETION_STATUS = "ASSESSMENT_CHANGE_COMPLETION_STATUS";
export const ASSESSMENT_MAKE_UNCOMPLETED_FOR_ASSESSOR = "ASSESSMENT_MAKE_UNCOMPLETED_FOR_ASSESSOR";
// ASSESSMENT QUESTIONS
export const RESET_ASSESSMENT_QUESTION_MANAGEMENT_STATE = "RESET_ASSESSMENT_QUESTION_MANAGEMENT_STATE";
// MODALS
export const SET_CAMPAIGN_ASSESSMENTS_PAGE_BAR_ADD_BUTTON_VISIBLE = "SET_CAMPAIGN_ASSESSMENTS_PAGE_BAR_ADD_BUTTON_VISIBLE";
// CAMPAIGN BASE INFO
export const SET_CAMPAIGN_BASE_INFO = "SET_CAMPAIGN_BASE_INFO";
export const RESET_CAMPAIGN_BASE_INFO = "RESET_CAMPAIGN_BASE_INFO";
// CAMPAIGN PARTICIPATION
export const SET_CAMPAIGN_PARTICIPANT_ME = "SET_CAMPAIGN_PARTICIPANT_ME";
