import React from "react";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../../../utils/IntlMessages";
import classNames from "classnames";


const SideNavUsers = ({ isStaffView = false, closeNavCallBack }) => {
  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });
  const tittleIntl = "sidenav.users";
  const path = "/users";

  return (
      <NavLink
          className={prependedClass}
          to={path}
          onClick={closeNavCallBack}
      >
        <IntlMessages id={tittleIntl}/>
      </NavLink>
  );
};

SideNavUsers.displayName = "SideNavUsers";
export default SideNavUsers;