import { success, error } from "redux-saga-requests";
import * as types from "./types";
import { UPDATE_ASSESSMENT_ANSWER_NOTE_SINGULAR } from "../Organization/types";
import {
  CAMPAIGN_ASSESSMENTS_CARDS_SORT,
  CAMPAIGN_MEMBERS_SORT,
  memberModals,
  memberInvitationModals,
  assessmentsModals,
  CAMPAIGN_MEMBER_INVITATION_TABLE_SORT,
  memberRolesModals,
  CAMPAIGN_MEMBER_ROLES_SORT
} from "./constants";

import pageView from "../../shared/constants/pageView";

function updateObjectInArray(array, action) {
  return array.map((item) => {
    if (item.id !== action.payload.id) {
      return item;
    }

    return {
      ...item,
      ...action.payload
    };
  });
}

const initialState = {
  info: {},
  requester: {
    membership: {}, // deprecated
    participantMe: {}
  },
  members: {
    list: [],
    listSchema: {
      default: [],
      card: [],
      table: [],
      listMinimalistic: []
    },
    modals: {
      [memberModals.CREATE]: {
        isVisible: false,
        data: {},
        isPageBarAddButtonVisible: false
      },
      [memberModals.UPDATE]: {
        isVisible: false,
        data: {}
      },
      [memberModals.DELETE]: {
        isVisible: false,
        data: {}
      },
    },
    availableCount: 0,
    selectedItem: {},
    pages_count: 1,
    pageViewType: pageView.TABLE_VIEW,
    pagination: {
      page_size: 10,
      page: 1
    },
    sortBy: {
      sort_by: CAMPAIGN_MEMBERS_SORT
    }
  },
  assignments: {
    selectedMember: null,
    assignedMembersSearch: "",
    unassignedMembersSearch: "",
    userUnassignedMembers: {
      availableCount: 0,
      list: [],
    },
    userAssignedMembers: {
      availableCount: 0,
      list: [],
    },
    userUnassignedMembersChecked: [],
    userAssignedMembersChecked: [],
    alreadyAssignedAdded: [],
    usersWithinCampaignAdded: [],
  },
  assessors: {
    list: [],
    availableCount: 0
  },
  userAttachedToAssessors: {
    list: [],
    availableCount: 0
  },
  userPotentialAssessorMembers: {
    list: [],
    availableCount: 0
  },
  potential_members: {
    list: [],
    availableCount: 0,
  },
  potential_members_answers: {
    list: [],
    availableCount: 0,
  },
  potential_member_answers: {},
  invites: {
    list: [],
    listSchema: {
      default: [],
      card: [],
      table: []
    },
    modals: {
      [memberInvitationModals.CREATE]: {
        isVisible: false,
        data: {},
      },
      [memberInvitationModals.RESEND_INVITE]: {
        isVisible: false,
        data: {},
      },
    },
    availableCount: 0,
    pages_count: 1,
    pagination: {
      page_size: 10,
      page: 1
    },
    sortBy: {
      sort_by: CAMPAIGN_MEMBER_INVITATION_TABLE_SORT
    }
  },
  usersRoles: {
    list: [],
    listSchema: {
      default: [],
      card: [],
      table: []
    },
    modals: {
      [memberRolesModals.CREATE]: {
        isVisible: false,
        data: {},
      },
      [memberRolesModals.UPDATE]: {
        isVisible: false,
        data: {},
      },
      [memberRolesModals.DELETE]: {
        isVisible: false,
        data: {},
      }
    },
    availableCount: 0,
    selectedItem: {},
    allPermissions: [],
    pages_count: 1,
    pagination: {
      page_size: 10,
      page: 1
    },
    sortBy: {
      sort_by: CAMPAIGN_MEMBER_ROLES_SORT
    }
  },
  assessments_modules: {
    list: [],
    availableCount: 0,
    selectedItem: {},
    multiLangInfo: {},
  },
  onboarding_modules: {
    list: [],
    availableCount: 0,
    selectedItem: {},
    multiLangInfo: {},
  },
  // ASSESSMENTS
  user_modules: {
    list: [],
    availableCount: 0,
    page: 1,
    page_size: 1,
    pages_count: 1,
    loading: false,
    management: {
      updatedQuestion: null
    }
  },
  // ASSESSMENTS_CARDS
  assessment: {
    listSchemas: {
      default: [],
      card: [],
      table: []
    },
    availableCount: 0,
    pages_count: 1,
    pagination: {
      page_size: 10,
      page: 1
    },
    modals: {
      [assessmentsModals.CREATE]: {
        isVisible: false,
        data: {},
        isPageBarAddButtonVisible: false
      },
      [assessmentsModals.UPDATE]: {
        isVisible: false,
        data: {},
      },
      [assessmentsModals.FOLLOW_LINK]: {
        isVisible: false,
        data: {},
      },
      [assessmentsModals.NO_CONNECTED_SURVEY]: {
        isVisible: false,
        data: {},
      },
      [assessmentsModals.NO_CAMPAIGN_MEMBER]: {
        isVisible: false,
        data: {},
      },
      [assessmentsModals.PUBLISH_JSON]: {
        isVisible: false,
        data: {},
      },
    },
    pageViewType: pageView.CARD_VIEW,
    filters: {},
    management: {
      updatedQuestion: null
    },
    sortBy: {
      sort_by: CAMPAIGN_ASSESSMENTS_CARDS_SORT
    },
  },
  multiLangInfo: {},
  errors: null,
  log: {
    list: [],
    availableCount: 0,
    filters: {},
    loading: false
  },
  datasets: {
    list: [],
    availableCount: 0,
  },
  selectedColumns: [],
  // CAMPAIGN DASHBOARD
  campaignDashboard: {
    organization: {},
    header: {
      cid: null,
      name: null,
      description: null,
      logo: "",
      registrationForm: {},
      availability: null,
      status: null,
      lifecycles: { active: null, all: [] },
      createdDate: null,
    },
    composition: {
      list: [],
    },
    managers: {
      availableCount: 0,
      list: [],
      next: null,
    },
    participants: {
      availableCount: 0,
      history: {},
      sliceType: "",
      invitedCount: 0,
      completedCount: 0,
      inProgressCount: 0,
    },
    auditorActions: {
      count: 0,
      notesCount: 0,
      verificationsCount: 0,
    },
    mainEvents: {
      availableCount: 0,
      list: [],
      next: null,
    },
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.FETCH_TABLE_CONFIG): {
      const { response: { results } } = action.payload;
      return {
        ...state,
        selectedColumns: results.columns,
        config: {
          ...state.config,
          table: {
            ...state.config.table,
            columns: results.columns
          }
        }
      };
    }
    case types.INITIALIZE_SELECTED_COLUMNS: {
      return {
        ...state,
        selectedColumns: action.payload
      };
    }
    case types.EDIT_SELECTED_COLUMN: {
      const newValue = updateObjectInArray(state.selectedColumns, action);
      return {
        ...state,
        selectedColumns: [...newValue]
      };
    }
    case types.SET_SELECTED_COLUMN: {
      return {
        ...state,
        selectedColumns: [
          ...state.selectedColumns,
          action.payload
        ]
      };
    }
    case types.REMOVE_SELECTED_COLUMN: {
      const payload = state.selectedColumns.filter((item) => item.id !== action.payload.id);

      return {
        ...state,
        selectedColumns: payload
      };
    }
    case success(types.FETCH_CAMPAIGN_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        ...response.results
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERSHIP_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        requester: {
          membership: {
            ...response
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MULTI_LANG_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        multiLangInfo: response.results,
      };
    }
    // MEMBER
    case success(types.FETCH_CAMPAIGN_MEMBERS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          list: response.results,
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERS_TABLE): {
      const { response } = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          listSchema: {
            ...state.members.listSchema,
            table: response.results
          },
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERS_CARD): {
      const { response } = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          listSchema: {
            ...state.members.listSchema,
            card: response.results
          },
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERS_LIST_MINIMALISTIC): {
      const { response } = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          listSchema: {
            ...state.members.listSchema,
            listMinimalistic: response.results
          },
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case types.SET_CAMPAIGN_MEMBERS_SORT_ORDER: {
      return {
        ...state,
        members: {
          ...state.members,
          sortBy: {
            ...action.payload
          }
        }
      };
    }
    case types.SET_CAMPAIGN_MEMBERS_PAGINATION: {
      return {
        ...state,
        members: {
          ...state.members,
          pagination: {
            ...state.members.pagination,
            ...action.payload
          }
        }
      };
    }
    case types.SET_CAMPAIGN_MEMBERS_PAGE_VIEW_TYPE: {
      return {
        ...state,
        members: {
          ...state.members,
          pageViewType: action.payload
        }
      };
    }
    case types.RESET_CAMPAIGN_MEMBERS_PAGINATION: {
      return {
        ...state,
        members: {
          ...state.members,
          pagination: {
            page_size: 10,
            page: 1
          },
        }
      };
    }
    case types.RESET_CAMPAIGN_MEMBERS_SORT_ORDER: {
      return {
        ...state,
        members: {
          ...state.members,
          sortBy: {
            sort_by: CAMPAIGN_MEMBERS_SORT
          }
        }
      };
    }
    case types.DELETE_CAMPAIGN_MEMBERS_LIST: {
      return {
        ...state,
        members: {
          ...state.members,
          list: [],
          listSchema: {
            default: [],
            card: [],
            table: []
          },
          availableCount: 0,
          selectedItem: {},
          pages_count: 1,
          pagination: {
            page_size: 10,
            page: 1
          },
          sortBy: {
            sort_by: CAMPAIGN_MEMBERS_SORT
          }
        }
      };
    }
    case types.TOGGLE_CAMPAIGN_MEMBER_MODAL: {
      return {
        ...state,
        members: {
          ...state.members,
          modals: {
            ...state.members.modals,
            [action.payload.target]: {
              ...state.members.modals?.[action.payload.target],
              isVisible: action.payload.isVisible,
              data: action.payload?.data || {}
            }
          }
        }
      };
    }
    case types.SET_CAMPAIGN_MEMBERS_PAGE_BAR_ADD_BUTTON_VISIBLE: {
      return {
        ...state,
        members: {
          ...state.members,
          modals: {
            ...state.members.modals,
            [memberModals.CREATE]: {
              ...state.members.modals[memberModals.CREATE],
              isPageBarAddButtonVisible: action.payload
            }
          }
        }
      };
    }

    // ATTACHED TO ASSESSOR
    case success(types.FETCH_CAMPAIGN_MEMBERS_LIST_ASSESSORS): {
      const { response } = action.payload;

      return {
        ...state,
        assessors: {
          list: response.results,
          availableCount: response.count
        }
      };
    }
    case types.CLEAN_ATTACHED_TO_ASSESSOR: {
      return {
        ...state,
        userAttachedToAssessors: {
          list: [],
          availableCount: 0,
        },
        userPotentialAssessorMembers: {
          list: [],
          availableCount: 0,
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERS_ATTACHED_TO_ASSESSOR): {
      const { response } = action.payload;

      const addToList = response.results
        .filter(itemResponse => !state.userAttachedToAssessors.list.find(itemState => itemState.id === itemResponse.id));

      return {
        ...state,
        userAttachedToAssessors: {
          list: [...state.userAttachedToAssessors.list, ...addToList],
          availableCount: response.count,
          previous: response.previous,
          next: response.next,
          page: response.page,
          page_size: response.page_size,
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERS_POTENTIAL_ATTACHED_TO_ASSESSOR): {
      const { response } = action.payload;

      const addToList = response.results
        .filter(itemResponse => !state.userPotentialAssessorMembers.list.find(itemState => itemState.id === itemResponse.id));

      return {
        ...state,
        userPotentialAssessorMembers: {
          list: [...state.userPotentialAssessorMembers.list, ...addToList],
          availableCount: response.count,
          previous: response.previous,
          next: response.next,
          page: response.page,
          page_size: response.page_size,
        }
      };
    }
    case types.SET_ASSIGNMENTS_SELECTED_MEMBER: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          selectedMember: action.payload
        }
      };
    }
    case types.SET_ASSIGNED_MEMBER_SEARCH: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          assignedMembersSearch: action.payload
        }
      };
    }
    case types.SET_UNASSIGNED_MEMBER_SEARCH: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          unassignedMembersSearch: action.payload
        }
      };
    }
    case types.SET_ASSIGNED_MEMBERS_ADDED: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          alreadyAssignedAdded: action.payload,
        }
      };
    }
    case types.SET_UNASSIGNED_MEMBERS_ADDED: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          usersWithinCampaignAdded: action.payload,
        }
      };
    }
    case types.SET_ASSIGNED_TO_ASSESSOR_CHECKED: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userAssignedMembersChecked: [...action.payload],
        }
      };
    }
    case types.SET_UNASSIGNED_TO_ASSESSOR_CHECKED: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userUnassignedMembersChecked: [...action.payload],
        }
      };
    }
    case types.DELETE_AUDITOR_ASSIGNMENTS_INFO: {
      return {
        ...state,
        assignments: {
          selectedMember: null,
          assignedMembersSearch: "",
          unassignedMembersSearch: "",
          userUnassignedMembers: {
            availableCount: 0,
            list: [],
          },
          userAssignedMembers: {
            availableCount: 0,
            list: [],
          },
          userUnassignedMembersChecked: [],
          userAssignedMembersChecked: [],
          alreadyAssignedAdded: [],
          usersWithinCampaignAdded: [],
        }
      };
    }
    case types.CLEAN_ASSIGNED_TO_ASSESSOR: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userAssignedMembers: {
            list: [],
            availableCount: 0,
          },
        }

      };
    }
    case types.CLEAN_UNASSIGNED_TO_ASSESSOR: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userUnassignedMembers: {
            list: [],
            availableCount: 0,
          },
        }
      };
    }
    case types.SET_MEMBERS_UNASSIGNED_TO_ASSESSOR: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userUnassignedMembers: {
            ...state.assignments.userUnassignedMembers,
            list: action.payload
          }
        }

      };
    }
    case types.SET_MEMBERS_ASSIGNED_TO_ASSESSOR: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userAssignedMembers: {
            ...state.assignments.userAssignedMembers,
            list: action.payload
          }
        }
      };
    }
    case types.SET_AVAILABLE_ASSIGNED_COUNT: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userAssignedMembers: {
            ...state.assignments.userAssignedMembers,
            availableCount: action.payload
          }
        }
      };
    }
    case types.SET_AVAILABLE_UNASSIGNED_COUNT: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          userUnassignedMembers: {
            ...state.assignments.userUnassignedMembers,
            availableCount: action.payload
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERS_UNASSIGNED_TO_ASSESSOR): {
      const { response } = action.payload;

      const currentList = state.assignments.userUnassignedMembers.list;
      const addToList = response.results
        .filter(itemResponse => !currentList.find(itemState => itemState.id === itemResponse.id));
      const excludeIdsCount = state.assignments.usersWithinCampaignAdded.length;

      return {
        ...state,
        assignments: {
          ...state.assignments,
          userUnassignedMembers: {
            list: [...currentList, ...addToList],
            availableCount: response.count + excludeIdsCount,
            previous: response.previous,
            next: response.next,
            page: response.page,
            page_size: response.page_size,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBERS_ASSIGNED_TO_ASSESSOR): {
      const { response } = action.payload;

      const currentList = state.assignments.userAssignedMembers.list;
      const addToList = response.results
        .filter(itemResponse => !currentList.find(itemState => itemState.id === itemResponse.id));
      const excludeIdsCount = state.assignments.alreadyAssignedAdded.length;

      return {
        ...state,
        assignments: {
          ...state.assignments,
          userAssignedMembers: {
            list: [...currentList, ...addToList],
            availableCount: response.count + excludeIdsCount,
            previous: response.previous,
            next: response.next,
            page: response.page,
            page_size: response.page_size,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS_EXTENDED): {
      const { response } = action.payload;
      return {
        ...state,
        potential_member_answers: response.results
      };
    }
    case success(types.FETCH_CAMPAIGN_POTENTIAL_MEMBERS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        potential_members: { list: response.results, availableCount: response.count }
      };
    }
    // INVITATIONS
    case success(types.FETCH_CAMPAIGN_MEMBER_INVITATIONS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        invites: {
          ...state.invites,
          list: response.results,
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }

    case types.SET_CAMPAIGN_MEMBER_INVITATIONS_LIST_PAGINATION: {
      return {
        ...state,
        invites: {
          ...state.invites,
          pagination: {
            ...state.invites.pagination,
            ...action.payload
          }
        }
      };
    }
    case types.SET_CAMPAIGN_MEMBER_INVITATIONS_SORT_ORDER: {
      return {
        ...state,
        invites: {
          ...state.invites,
          sortBy: {
            ...action.payload
          }
        }
      };
    }
    case types.TOGGLE_CAMPAIGN_MEMBER_INVITATIONS_MODAL: {
      return {
        ...state,
        invites: {
          ...state.invites,
          modals: {
            ...state.invites.modals,
            [action.payload.target]: {
              ...state.invites.modals?.[action.payload.target],
              isVisible: action.payload.isVisible,
              data: action.payload?.data || {}
            }
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_MEMBER_INVITATIONS_TABLE): {
      const { response } = action.payload;
      return {
        ...state,
        invites: {
          ...state.invites,
          listSchema: {
            ...state.invites.listSchema,
            table: response.results
          },
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    //
    case success(types.FETCH_CAMPAIGN_ASSESSMENT_SURVEYS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        assessments_modules: {
          ...state.assessments_modules,
          list: response.results, availableCount: response.count
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_ONBOARDING_SURVEYS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        onboarding_modules: {
          ...state.onboarding_modules,
          list: response.results,
          availableCount: response.count
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        onboarding_modules: { ...state.onboarding_modules, selectedItem: response.results }
      };
    }
    case success(types.FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        onboarding_modules: {
          ...state.onboarding_modules,
          multiLangInfo: response.results
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_ASSESSMENT_MODULE_ITEM_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        assessments_modules: {
          ...state.assessments_modules,
          selectedItem: response.results,
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_USERS_ROLES_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          list: response.results,
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_USERS_ROLES_TABLE): {
      const { response } = action.payload;
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          listSchema: {
            ...state.usersRoles.listSchema,
            table: response.results
          },
          availableCount: response.count,
          pages_count: response.pages_count,
          pagination: {
            page_size: response.page_size,
            page: response.page,
          }
        }
      };
    }
    case types.TOGGLE_CAMPAIGN_MEMBER_ROLE_MODAL: {
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          modals: {
            ...state.usersRoles.modals,
            [action.payload.target]: {
              ...state.usersRoles.modals?.[action.payload.target],
              isVisible: action.payload.isVisible,
              data: action.payload?.data || {}
            }
          }
        }
      };
    }
    case types.SET_CAMPAIGN_MEMBERS_ROLES_SORT_ORDER: {
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          sortBy: {
            ...action.payload
          }
        }
      };
    }
    case types.SET_CAMPAIGN_USERS_ROLES_LIST_PAGINATION: {
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          pagination: {
            ...state.usersRoles.pagination,
            ...action.payload
          }
        }
      };
    }

    case success(types.FETCH_CAMPAIGN_USER_ROLE_MULTI_LANG_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          selectedItem: response.results,
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_PERMISSIONS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        usersRoles: {
          ...state.usersRoles,
          allPermissions: response.results,
        }
      };
    }
    // ASSESSMENTS
    case types.FETCH_CAMPAIGN_ASSESSMENTS: {
      return {
        ...state,
        user_modules: {
          ...state.user_modules,
          loading: true
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_ASSESSMENTS): {
      const { response } = action.payload;
      return {
        ...state,
        user_modules: {
          list: response.results,
          availableCount: response.count,
          page: response.page,
          page_size: response.page_size,
          pages_count: response.pages_count,
          loading: false
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_ASSESSMENTS_CARDS): {
      const { response } = action.payload;
      return {
        ...state,
        assessment: {
          ...state.assessment,
          listSchemas: {
            ...state.assessment.listSchemas,
            card: response.results
          },
          availableCount: response.count,
          page_size: response.page_size,
          page: response.page,
          pages_count: response.pages_count,
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_ASSESSMENTS_TABLE): {
      const { response } = action.payload;
      return {
        ...state,
        assessment: {
          ...state.assessment,
          listSchemas: {
            ...state.assessment.listSchemas,
            table: response.results,
          },
          availableCount: response.count,
          page_size: response.page_size,
          page: response.page,
          pages_count: response.pages_count,
        }
      };
    }
    case types.SET_CAMPAIGNS_ASSESSMENTS_PAGINATION: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          pagination: {
            ...state.assessment.pagination,
            ...action.payload
          }
        }
      };
    }
    case types.SET_CAMPAIGN_ASSESSMENT_PAGE_VIEW_TYPE: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          pageViewType: action.payload
        }
      };
    }
    case types.SET_CAMPAIGN_ASSESSMENT_TABLE_SIZE: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          pagination: {
            ...state.assessment.pagination,
            ...action.payload,
          },
        },
      };
    }
    case types.SET_CAMPAIGN_ASSESSMENT_FILTERS: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          filters: {
            ...action.payload,
          },
        },
      };
    }
    case types.SET_CAMPAIGN_ASSESSMENT_SORT_ORDER: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          sortBy: {
            ...action.payload,
          },
        },
      };
    }
    case types.RESET_CAMPAIGN_ASSESSMENT_STATE: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          listSchemas: {
            default: [],
            card: [],
            table: []
          },
          pagination: {
            page_size: 10,
            page: 1
          },
        },
      };
    }
    case types.TOGGLE_CAMPAIGN_ASSESSMENTS_MODAL: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          modals: {
            ...state.assessment.modals,
            [action.payload.target]: {
              ...state.assessment.modals?.[action.payload.target],
              isVisible: action.payload.isVisible,
              data: action.payload?.data || {}
            }
          }
        }
      };
    }
    case types.SET_CAMPAIGN_ASSESSMENTS_PAGE_BAR_ADD_BUTTON_VISIBLE: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          modals: {
            ...state.assessment.modals,
            [assessmentsModals.CREATE]: {
              ...state.assessment.modals[assessmentsModals.CREATE],
              isPageBarAddButtonVisible: action.payload
            }
          }
        }
      };
    }
    //
    case success(types.FETCH_ASSESSMENT_MULTI_LANG_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        assessments_modules: {
          ...state.assessments_modules,
          multiLangInfo: response.results
        }
      };
    }
    case types.CLEAN_ASSESSMENT_MULTI_LANG_INFO: {
      return {
        ...state,
        assessments_modules: {
          ...state.assessments_modules,
          multiLangInfo: {},
        }
      };
    }
    //
    case success(types.FETCH_CAMPAIGN_MEMBER_EXTENDED_INFO): {
      const { response } = action.payload;
      return {
        ...state,
        members: {
          ...state.members,
          selectedItem: response.results,
        }
      };
    }
    case success(types.FETCH_NON_AUTH_ANSWERS_LIST): {
      const { response } = action.payload;
      return {
        ...state,
        potential_members_answers: {
          list: response.results,
          availableCount: response.count,
        }
      };
    }
    case success(types.ATTACH_USERS_ASSESSOR): {
      return {
        ...state,
      };
    }
    case types.FETCH_CAMPAIGN_LOG: {
      return {
        ...state,
        log: {
          ...state.log,
          loading: true
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_LOG): {
      const { response } = action.payload;

      return {
        ...state,
        log: {
          ...state.log,
          list: [...state.log.list, ...response.results],
          availableCount: response.count,
          previous: response.previous,
          next: response.next,
          page: response.page,
          page_size: response.page_size,
          loading: false
        }
      };
    }
    case types.UPDATE_CAMPAIGN_LOG_FILTERS: {
      return {
        ...state,
        log: {
          ...state.log,
          filters: action.payload
        }
      };
    }
    case types.CLEAN_CAMPAIGN_LOG: {
      return {
        ...state,
        log: {
          ...state.log,
          list: [],
          availableCount: 0,
          previous: null,
          next: null,
          page: 1,
          page_size: 10,
        }
      };
    }
    case success(UPDATE_ASSESSMENT_ANSWER_NOTE_SINGULAR) : {
      const { response: { results } } = action.payload;
      return {
        ...state,
        user_modules: {
          ...state.user_modules,
          management: {
            ...state.user_modules.management,
            updatedQuestion: results
          }
        }
      };
    }
    case types.RESET_ASSESSMENT_QUESTION_MANAGEMENT_STATE: {
      return {
        ...state,
        user_modules: {
          ...state.user_modules,
          management: {
            updatedQuestion: null
          }
        }
      };
    }
    // CAMPAIGN DASHBOARD
    case success(types.FETCH_CAMPAIGN_DASHBOARD_HEADER): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          organization: response.relations.organization,
          header: {
            cid: response.cid,
            name: response.content.name,
            description: response.content.description,
            logo: response.content.logo,
            registrationForm: response.relations.registration_form,
            availability: response.settings.availability,
            status: response.settings.status,
            lifecycles: response.meta.lifecycles,
            createdDate: response.meta.created_dt,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_COMPOSITION): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          composition: {
            availableCount: response.count,
            list: response.results,
            next: response.next
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_COMPOSITION_BY_SCROLL): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          composition: {
            availableCount: response.count,
            list: [...state.campaignDashboard.composition.list, ...response.results],
            next: response.next
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_MANAGERS): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          managers: {
            availableCount: response.count,
            list: response.results,
            next: response.next
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_MANAGERS_BY_SCROLL): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          managers: {
            availableCount: response.count,
            list: [...state.campaignDashboard.managers.list, ...response.results],
            next: response.next
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_PARTICIPANTS): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          participants: {
            availableCount: response.count,
            history: response.history.new_participants.items,
            sliceType: response.history.new_participants.slice_type,
            invitedCount: response.stats.invited,
            completedCount: response.stats.completed,
            inProgressCount: response.stats.in_progress,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_AUDITOR_ACTIONS): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          auditorActions: {
            count: response.count,
            notesCount: response.notes.count,
            verificationsCount: response.stats.verifications,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_EVENTS): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          mainEvents: {
            availableCount: response.count,
            list: response.results,
            next: response.next,
          }
        }
      };
    }
    case success(types.FETCH_CAMPAIGN_DASHBOARD_EVENTS_BY_SCROLL): {
      const { response } = action.payload;
      return {
        ...state,
        campaignDashboard: {
          ...state.campaignDashboard,
          mainEvents: {
            availableCount: response.count,
            list: [...state.campaignDashboard.mainEvents.list, ...response.results],
            next: response.next,
          }
        }
      };
    }
    // BASE INFO
    case types.SET_CAMPAIGN_BASE_INFO: {
      return {
        ...state,
        info: action.payload
      };
    }
    case types.RESET_CAMPAIGN_BASE_INFO: {
      return {
        ...state,
        info: {}
      };
    }

    // PARTICIPATION
    case types.SET_CAMPAIGN_PARTICIPANT_ME: {
      return {
        ...state,
        requester: {
          ...state.requester,
          participantMe: action.payload
        }
      };
    }

    case error(types.FETCH_CAMPAIGN_ONBOARDING_AND_ASSESSMENTS):
    case error(types.FETCH_CAMPAIGN_POTENTIAL_MEMBER_ANSWERS_EXTENDED):
    case error(types.FETCH_CAMPAIGN_POTENTIAL_MEMBERS_ANSWERS_LIST):
    case error(types.FETCH_CAMPAIGN_POTENTIAL_MEMBERS_LIST):
    case error(types.FETCH_CAMPAIGN_MEMBER_INVITATIONS_LIST):
    case error(types.FETCH_CAMPAIGN_ASSESSMENT_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO):
    case error(types.FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_MULTI_LANG_EXTENDED_INFO):
    case error(types.FETCH_CAMPAIGN_MULTI_LANG_EXTENDED_INFO):
    case error(types.FETCH_CAMPAIGN_ASSESSMENT_MODULE_ITEM_EXTENDED_INFO):
    case error(types.FETCH_CAMPAIGN_ONBOARDING_MODULE_ITEM_EXTENDED_INFO):
    case error(types.FETCH_CAMPAIGN_ONBOARDING_SURVEYS_LIST):
    case error(types.FETCH_CAMPAIGN_ASSESSMENT_SURVEYS_LIST):
    case error(types.UPDATE_CAMPAIGN_DESCRIPTION):
    case error(types.DELETE_CAMPAIGN_MEMBER):
    case error(types.UPDATE_CAMPAIGN_MEMBER):
    case error(types.CREATE_CAMPAIGN_MEMBER):
    case error(types.FETCH_CAMPAIGN_MEMBERS_LIST):
    case error(types.FETCH_CAMPAIGN_EXTENDED_INFO):
    case error(types.FETCH_CAMPAIGN_USERS_ROLES_LIST):
    case error(types.FETCH_CAMPAIGN_USER_ROLE_MULTI_LANG_INFO):
    case error(types.FETCH_CAMPAIGN_PERMISSIONS_LIST):
    case error(types.CREATE_ASSESSMENT_ANSWER):
    case error(types.FETCH_ASSESSMENT_ANSWER_EXTENDED_INFO):
    case error(types.FETCH_CAMPAIGN_MEMBER_EXTENDED_INFO):
    case error(types.FETCH_NON_AUTH_ANSWERS_LIST):
    case error(types.CLONE_CAMPAIGN):
    case error(types.UPDATE_REGISTRATION_CAMPAIGN_NON_AUTH_ANSWER):
    case error(UPDATE_ASSESSMENT_ANSWER_NOTE_SINGULAR):
    case error(types.FETCH_CAMPAIGN_DASHBOARD_HEADER):
    case error(types.FETCH_CAMPAIGN_DASHBOARD_COMPOSITION):
    case error(types.FETCH_CAMPAIGN_DASHBOARD_MANAGERS):
    case error(types.FETCH_CAMPAIGN_DASHBOARD_PARTICIPANTS):
    case error(types.FETCH_CAMPAIGN_DASHBOARD_AUDITOR_ACTIONS):
    case error(types.FETCH_CAMPAIGN_DASHBOARD_EVENTS):
    case error(types.FETCH_CAMPAIGN_LOG): {
      return {
        ...state,
        errors: action.payload.response.data.errors || action.payload.message,
      };
    }
    default:
      return state;
  }
};
