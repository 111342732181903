import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    container: {
        marginBottom: '41px',
    },
    input: {
        background: 'transparent',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#7D7C7D',
        height: '50px',
        width: '300px',
        border: 'none',
        borderBottom: '1px solid #7D7C7D',

        '&::placeholder': {
            fontSize: '18px',
        },

        '&:focus': {
            outline: 'none',
        }
    },
    error: {
        marginTop: '3px',
        color: 'red',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
    }
}));

const InputForm = ({ name, ...props }) => {
    const [field, meta] = useField({ name, ...props });
    const error = meta.error && meta.touched ? meta.error : '';

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <input
                {...field}
                {...props}
                className={classes.input}
                autoComplete='off'
            />
            {error && (
                <div className={classes.error}>
                    {error}
                </div>
            )}
        </div>
    );
}

InputForm.propTypes = {
    name: PropTypes.string.isRequired,
};

export default InputForm;