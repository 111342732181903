import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-responsive-modal";
// MATERIAL UI
import Box from "@mui/material/Box";
// COMPONENTS
import AsrCloseModalButton from "@/shared/components/Buttons/AsrCloseModalButton";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
import AsrLightModalFooter from "./components/AsrLightModalFooter";
// HOOKS
import useClasses from "@/shared/components/Modals/AsrLightModal/hooks/useClasses";

const AsrLightModal = ({
                         open,
                         onClose,
                         children,
                         isLoading,
                         title,
                         classes = {
                           overlay: "",
                           modal: "",
                           modalBox: "",
                           modalGapContainer: "",
                           contentContainer: "",
                           titleContainer: "",
                           footerContainer: ""
                         },
                         dashedContentBorder = true,
                         minHeight = 220,
                         actionFooterButtons,
                       }) => {

  const modalClasses = useClasses({
    classes,
    showDashedContentBorder: dashedContentBorder
  });

  const hasHeader = Boolean(title);
  const hasFooter = Boolean(actionFooterButtons?.length > 0);

  return (
    <Modal
      open={open}
      onClose={onClose}
      classNames={{
        overlay: modalClasses.overlay,
        modal: modalClasses.modal
      }}
      center
      focusTrapped={false}
      closeIcon={<AsrCloseModalButton isVisible={false}/>}
    >
      <Box minHeight={minHeight} className={modalClasses.modalBox}>
        <div className={modalClasses.modalGapContainer}>
          <div className={modalClasses.contentContainer}>
            {/* MODAL TITLE */}
            {hasHeader && (
              <div className={modalClasses.titleContainer}>
                {title}
              </div>
            )}

            {/* BODY */}
            {children}

            {/* FOOTER */}
            {hasFooter && (
              <AsrLightModalFooter
                classes={modalClasses}
                actionFooterButtons={actionFooterButtons}
              />
            )}
          </div>
        </div>
      </Box>

      {/* LOADER */}
      {isLoading && <AsrCommonLoader/>}
    </Modal>
  );
};

AsrLightModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.shape({
    overlay: PropTypes.string,
    modal: PropTypes.string,
    modalBox: PropTypes.string,
    modalGapContainer: PropTypes.string,
    contentContainer: PropTypes.string,
    titleContainer: PropTypes.string,
    footerContainer: PropTypes.string,
  }),
  dashedContentBorder: PropTypes.bool,
  minHeight: PropTypes.number,
  actionFooterButtons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    type: PropTypes.oneOf(["plainText", "default"]),
    classes: PropTypes.shape({
      wrapper: PropTypes.string,
      button: PropTypes.string
    }),
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  }))
};
export default AsrLightModal;
