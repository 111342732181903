import React, { useMemo } from "react";
// COMPONENTS
import AsrCloseModalButton from "@/shared/components/Buttons/AsrCloseModalButton";
// import IconButton from "@mui/material/IconButton";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// CONSTANTS
import { TAB_PROFILE } from "../../constants";
// STYLES
import styles from "./styles.module.scss";

const ProfileEditorHeader = ({
                               closeModal,
                               currentTab,
                               // isMinimizedView
                             }) => {

  const formTitle = useMemo(() => {
    switch (currentTab) {
      case TAB_PROFILE:
        return <IntlMessages id="user.myProfile"/>;
      default:
        return currentTab;
    }
  }, [currentTab]);

  return (
    <div className={styles.ContentHeaderContainer}>
      <div className={styles.MenuAndTitleContainer}>
        {/*{isMinimizedView && (*/}
        {/*  // todo open drawer on click (drawer is not set up)*/}
        {/*  <IconButton*/}
        {/*    className={`jr-menu-icon ${styles.BurgerIconContainer}`}*/}
        {/*    aria-label="Menu"*/}
        {/*    // onClick={onToggleCollapsedNav}*/}
        {/*    size="large"*/}
        {/*  >*/}
        {/*    <span className="menu-icon"/>*/}
        {/*  </IconButton>*/}
        {/*)}*/}
        {formTitle}
      </div>

      <div>
        <AsrCloseModalButton
          isVisible={true}
          handler={closeModal}
          iconClassName={styles.CloseIcon}
        />
      </div>
    </div>
  );
};

export default ProfileEditorHeader;
