import React from "react";
import PropTypes from "prop-types";
// COMPONENTS
import TextField from "@mui/material/TextField";
// HOOKS
import useClasses from "./hooks/useClasses";
// STYLES
import styles from "./styles.module.scss";

const AsrMuiInput = ({
                       variant = "standard",
                       fullWidth = true,
                       error,
                       isTouched = false,
                       classes = {
                         inputRoot: "",
                         inputFocused: "",
                         inputUnderline: "",
                         labelRoot: ""
                       },
                       type = "text",
                       ...props
                     }) => {
  const componentStyles = useClasses({ classes });

  return (
    <div className={styles.AsrMuiInputWrapper}>
      <TextField
        variant={variant}
        fullWidth={fullWidth}
        InputProps={{
          classes: {
            root: componentStyles.inputRoot,
            focused: componentStyles.inputFocused,
            underline: componentStyles.inputUnderline
          }
        }}
        InputLabelProps={{
          classes: {
            root: componentStyles.labelRoot
          }
        }}
        type={type}
        {...props}
      />
      {error && isTouched && (
        <div className={`form__error-text ${styles.ErrorText}`}>{error}</div>
      )}
    </div>
  );
};


AsrMuiInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  isTouched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  autoComplete: PropTypes.string,
  classes: PropTypes.shape({
    inputRoot: PropTypes.string,
    inputFocused: PropTypes.string,
    inputUnderline: PropTypes.string,
    labelRoot: PropTypes.string,
  })
};
export default AsrMuiInput;
