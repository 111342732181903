import { combineReducers } from "redux";
// GENERAL INFO
import generalInfo from "./generalInfo";
//  DASHBOARD
import accountDashboard from "./accountDashboard";
// ACCOUNT MEMBERS
import accountMembersList from "./accountMembersList";
import accountMembersRoles from "./accountMembersRoles";
import accountMembersAssignments from "./accountMembersAssignments";
import accountMembersPolicies from "./accountMembersPolicies";
import accountMembersInvitations from "./accountMembersInvitations";
import currentUser from "./currentUser";


const account = combineReducers({
  generalInfo,
  accountMembersRoles,
  accountMembersAssignments,
  accountDashboard,
  accountMembersPolicies,
  accountMembersInvitations,
  accountMembersList,
  currentUser
});

export default account;