import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";

const accountUser = {
  [actionsConstants.AccountUserListView]: simpleValidator,
  [actionsConstants.AccountUserCreate]: simpleValidator,
  [actionsConstants.AccountUserModify]: allOwnedIdsValidator,
  [actionsConstants.AccountUserLockUnlock]: allOwnedIdsValidator,
  [actionsConstants.AccountUserDelete]: allOwnedIdsValidator,
};

export default accountUser;