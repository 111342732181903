import { success } from "redux-saga-requests";
import * as types from "./types";
import { QUESTIONS_PANELS } from "@/shared/constants/surveyJs";
import { UPDATE_ASSESSMENT_ANSWER } from "../Campaign/types";


const initialState = {
  surveyManagement: {
    auditorControls: {
      headerSections: {
        [QUESTIONS_PANELS.NOTES]: {
          checked: true,
        },
        [QUESTIONS_PANELS.ANALYTICS]: {
          checked: true,
        },
        [QUESTIONS_PANELS.RECOMMENDATIONS]: {
          checked: true,
        }
      },
      questionsManagement: {}
    },
    settings: {
      availableLocales: [],
      currentLocale: null
    }
  },
  assessmentAnswer: {},
  surveyAnalytics: {
    currentAnalytics: {},
    ids: [],
    list: []
  }
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SURVEY_AUDITOR_CONTROLS: {
      return {
        ...state,
        surveyManagement: {
          auditorControls: {
            ...state.surveyManagement.auditorControls,
            ...action.payload
          }
        }
      };
    }
    case types.SET_SURVEY_HEADER_PANEL_TOGGLE_NOTE : {
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          auditorControls: {
            ...state.surveyManagement.auditorControls,
            headerSections: {
              ...state.surveyManagement.auditorControls.headerSections,
              [QUESTIONS_PANELS.NOTES]: {
                ...state.surveyManagement.auditorControls.headerSections[QUESTIONS_PANELS.NOTES],
                checked: action.payload,
              }
            }
          }
        }
      };
    }
    case success(types.SURVEY_HEADER_PANEL_TOGGLE_NOTE): {
      const { response: { results: { auditor_controls } } } = action.payload;
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          auditorControls: {
            ...state.surveyManagement.auditorControls,
            headerSections: {
              ...state.surveyManagement.auditorControls.headerSections,
              [QUESTIONS_PANELS.NOTES]: {
                ...state.surveyManagement.auditorControls.headerSections[QUESTIONS_PANELS.NOTES],
                checked: auditor_controls.auditor_notes,
              }
            }
          }
        }
      };
    }
    case success(types.SURVEY_HEADER_PANEL_TOGGLE_ANALYTICS): {
      const { response: { results: { auditor_controls } } } = action.payload;
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          auditorControls: {
            ...state.surveyManagement.auditorControls,
            headerSections: {
              ...state.surveyManagement.auditorControls.headerSections,
              [QUESTIONS_PANELS.ANALYTICS]: {
                ...state.surveyManagement.auditorControls.headerSections[QUESTIONS_PANELS.ANALYTICS],
                checked: auditor_controls.item_analytics,
              }
            }
          }
        }
      };
    }
    case success(types.SURVEY_HEADER_PANEL_TOGGLE_RECOMMENDATIONS): {
      const { response: { results: { auditor_controls } } } = action.payload;
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          auditorControls: {
            ...state.surveyManagement.auditorControls,
            headerSections: {
              ...state.surveyManagement.auditorControls.headerSections,
              [QUESTIONS_PANELS.RECOMMENDATIONS]: {
                ...state.surveyManagement.auditorControls.headerSections[QUESTIONS_PANELS.RECOMMENDATIONS],
                checked: auditor_controls.recommendations,
              }
            }
          }
        }
      };
    }
    case types.RESET_SURVEY_AUDITOR_CONTROLS: {
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          auditorControls: {
            headerSections: {
              ...state.surveyManagement.auditorControls.headerSections
            },
            questionsManagement: {}
          }
        }
      };
    }
    case types.MODIFY_SURVEY_AUDITOR_QUESTION_PANEL: {
      const { question_id, question_panel, data } = action.payload;
      const question = state?.surveyManagement?.auditorControls?.questionsManagement[question_id] ? state?.surveyManagement?.auditorControls?.questionsManagement[question_id] : {};
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          auditorControls: {
            ...state.surveyManagement.auditorControls,
            questionsManagement: {
              ...state.surveyManagement.auditorControls.questionsManagement,
              [question_id]: {
                ...question,
                [question_panel]: {
                  ...question[question_panel],
                  ...data
                }
              }
            }
          }
        }
      };
    }
    case success(UPDATE_ASSESSMENT_ANSWER): {
      const { response: { results } } = action.payload;
      return {
        ...state,
        assessmentAnswer: {
          ...state.assessmentAnswer,
          ...results
        }
      };
    }
    case types.RESET_SURVEY_ASSESSMENT_ANSWER: {
      return {
        ...state,
        assessmentAnswer: {}
      };
    }
    case success(types.FETCH_SURVEY_ANALYTICS): {
      const {
        response
      } = action.payload;

      return {
        ...state,
        surveyAnalytics: {
          ...state.surveyAnalytics,
          list: response
        }
      };
    }

    case types.ADD_SURVEY_ANALYTICS_ID: {
      const { id } = action.payload;
      return {
        ...state,
        surveyAnalytics: {
          ...state.surveyAnalytics,
          ids: [id]
        }
      };
    }
    case types.REMOVE_SURVEY_ANALYTICS_ID: {
      const { id } = action.payload;
      const ids = state.surveyAnalytics.ids.filter(i => i !== id);
      return {
        ...state,
        surveyAnalytics: {
          ...state.surveyAnalytics,
          ids
        }
      };
    }
    case types.CLEAR_SURVEY_ANALYTIC_IDS: {
      return {
        ...state,
        surveyAnalytics: {
          ...state.surveyAnalytics,
          ids: []
        }
      };
    }
    case types.SET_CURRENT_SURVEY_ANALYTICS: {
      return {
        ...state,
        surveyAnalytics: {
          ...state.surveyAnalytics,
          currentAnalytics: action.payload
        }
      };
    }
    case types.CLEAR_SURVEY_ANALYTICS: {
      return {
        ...state,
        surveyAnalytics: {
          ids: [],
          list: [],
          currentAnalytics: {}
        }
      };
    }
    case types.MOUNT_SURVEY_VIEWER: {
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          settings: {
            ...state.surveyManagement.settings,
            availableLocales: action.payload?.usedLocales || [],
            currentLocale: action.payload?.locale || "en"
          }
        }
      };
    }
    case types.UNMOUNT_SURVEY_VIEWER: {
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          settings: {
            ...state.surveyManagement.settings,
            availableLocales: [],
            currentLocale: null
          }
        }
      };
    }
    case types.SET_SURVEY_VIEWER_LANGUAGE: {
      return {
        ...state,
        surveyManagement: {
          ...state.surveyManagement,
          settings: {
            ...state.surveyManagement.settings,
            currentLocale: action.payload?.locale || "en"
          }
        }
      };
    }
    default:
      return state;
  }
};

export default surveyReducer;
