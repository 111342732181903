import React from "react";
// MATERIAL UI
import Switch from "@mui/material/Switch";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import useStyles from "./styles";

const AntSwitch = ({
                     label,
                     isLabel = false,
                     styleOptions = {},
                     ...props
                   }) => {
  const antSwitchConfig = {
    trackBgColor: "#BDBDBD",
    ...styleOptions
  };
  const classes = useStyles(antSwitchConfig);

  return (
    <div className="d-flex flex-column">
      {isLabel && (
        <span className="asr-field-label mb-0">
          <IntlMessages id={`${label}`}/>
        </span>
      )}

      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />

    </div>
  );
};

export default AntSwitch;