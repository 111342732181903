import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
// MATERIAL UI
import { Button } from "@mui/material";
// REGEX
import { HASHTAGS_REG } from "../regex";
import { parsBracketsRegex } from "@/shared/regex/auditor-regex";
// CONSTANTS
import { NoteEditorMode, QUESTIONS_PANELS } from "@/shared/constants/surveyJs";
// UTILS
import reactStringReplace from "@/shared/utils/stringReplace";
import {
  getIntlMessageToStringLocal,
} from "@/utils/IntlMessages";
//  validation
import validationSchema from "./validationSchema";
import { emptyNote } from "@/components/SurveyJs/constants";
//  HELPERS
import { getQuestionId } from "@/components/SurveyJs/components/ViewerV2/helpers/surveyCore/questions";
// COMPONENTS
import FroalaEditor from "@/components/Common/FroalaEditor";
import NoteCheckbox from "./components/NoteCheckbox";
import Hashtags from "./components/Hashtags";
import HashtagsInput from "./components/HashtagsInput";
import FormFooterActions from "./components/FormFooterActions";
import ConfirmDeleteModal from "@/shared/modals/ConfirmDeleteModal/ConfirmDeleteModal";
import AsrTermBubble from "@/shared/components/AsrTermBubble/AsrTermBubble";
// STORE
import { getCurrentUser } from "@/store/User/selectors";
import { modifySurveyAuditorQuestionPanel } from "@/store/Survey/actions";
// STYLES
import froalaIframeInlineStyle from "./froalaIframeInlineStyle";
import styles from "./styles.module.scss";
import commonStyles from "@/components/SurveyJs/components/ViewerV2/components/common.module.scss";


const NoteForm = ({
                    locale,
                    question_id,
                    changeQuestionNote,
                    setCharactersCount,
                    manageType,
                    questionsManagement,
                    canCreateAssessorNotes,
                    canModifyAssessorNotes,
                    canRemoveAssessorNotes,
                    onNoteUpdate,
                    onNoteDelete
                  }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const formRef = useRef();

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isFroalaEditor, setIsFroalaEditor] = useState(false);
  const [includeInExportsChecked, setIncludeInExportsChecked] = useState(false);
  const [shareWithUserChecked, setShareWithUserChecked] = useState(false);
  const [currentNote, setCurrentNote] = useState(questionsManagement[getQuestionId(question_id)]?.notes.currentNote);

  // SET EDITOR MODE
  const setEditorMode = (noteEditorMode = NoteEditorMode.READ) => {
    const newNotePayload = {
      question_id: getQuestionId(question_id),
      question_panel: QUESTIONS_PANELS.NOTES,
      data: {
        mode: noteEditorMode
      }
    };
    dispatch(modifySurveyAuditorQuestionPanel(newNotePayload));
    const isFroalaVisible = noteEditorMode === NoteEditorMode.EDIT;
    setIsFroalaEditor(isFroalaVisible);
  };

  useEffect(() => {
    if (questionsManagement[getQuestionId(question_id)]) {
      setCurrentNote(questionsManagement[getQuestionId(question_id)][QUESTIONS_PANELS.NOTES].currentNote);
    }
  }, [question_id, questionsManagement]);

  const cancelBtnLabel = getIntlMessageToStringLocal(locale, "appModule.cancel");
  const confirmLabel = getIntlMessageToStringLocal(locale, "organizationMainPage.confirm");
  const shareWithUserTooltip = getIntlMessageToStringLocal(locale, "assessments.shareWithUserTooltip");
  const shareWithUserBtn = getIntlMessageToStringLocal(locale, "assessments.shareWithUser");
  const includeInExportsTooltip = getIntlMessageToStringLocal(locale, "assessments.includeInExportsTooltip");
  const includeInExportsBtn = getIntlMessageToStringLocal(locale, "assessments.includeInExports");
  const deleteNote = getIntlMessageToStringLocal(locale, "assessor.deleteNote");

  const deleteNoteText = getIntlMessageToStringLocal(locale, "assessor.deleteNoteText", {
    cannotBeUndone: getIntlMessageToStringLocal(locale, "appModule.cannotBeUndoneSimple")
  });

  const noNotes = getIntlMessageToStringLocal(locale, "assessments.noNotes");
  const functionNotAvailableNoteIsLocked = getIntlMessageToStringLocal(locale, "assessments.functionNotAvailableNoteIsLocked");

  const initialValues = {
    text: currentNote?.text || "",
    sharedWithUser: currentNote?.is_visible || currentNote?.config?.is_visible || false,
    includedInExport: currentNote?.include_in_export || currentNote?.config?.include_in_export || false,
    hashtags: currentNote?.hashtags?.map(i => `#${i}`).join(" ") || "",
    is_locked: currentNote?.is_locked ?? false
  };

  useEffect(() => {
    setIncludeInExportsChecked(currentNote?.include_in_export || currentNote?.config?.include_in_export);
    setShareWithUserChecked(currentNote?.is_visible || currentNote?.config?.is_visible);
  }, [currentNote]);

  useEffect(() => {
    if (questionsManagement[getQuestionId(question_id)]) {
      const isEditMode = questionsManagement[getQuestionId(question_id)][QUESTIONS_PANELS.NOTES].mode === NoteEditorMode.EDIT;
      setIsFroalaEditor(isEditMode);
    }
  }, [question_id, questionsManagement]);

  const handleUpdateNote = async (values, payload) => {
    const hashtagsPayload = values
      .hashtags
      ?.trim()
      ?.match(HASHTAGS_REG)
      ?.map((item) => item.trim().slice(1));

    const newCurrentNote = {
      createdDt: Date.now(),
      created_by: {
        id: currentUser.id,
        fullname: currentUser?.content?.fullname,
        email: currentUser?.content?.email
      },
      text: values?.text ?? "",
      hashtags: hashtagsPayload ?? [],
      is_visible: payload?.is_visible ?? false,
      is_locked: payload?.is_locked ?? false,
      include_in_export: payload?.include_in_export ?? false
    };

    try {
      await changeQuestionNote(newCurrentNote, currentNote, question_id, manageType);

      const newNotePayload = {
        question_id: getQuestionId(question_id),
        question_panel: QUESTIONS_PANELS.NOTES,
        data: {
          currentNote: newCurrentNote,
          mode: newCurrentNote ? NoteEditorMode.READ : NoteEditorMode.EDIT
        }
      };

      dispatch(modifySurveyAuditorQuestionPanel(newNotePayload));
      if (onNoteUpdate) {
        onNoteUpdate({ updatedNote: newCurrentNote });
      }
    } catch (e) {
      // console.log(e);
      console.log(e);
    }
  };

  const handleSubmit = async (values) => {
    const payload = {
      is_visible: shareWithUserChecked,
      include_in_export: includeInExportsChecked
    };

    await handleUpdateNote(values, payload);

    setEditorMode(NoteEditorMode.READ);
  };

  const handleEdit = () => {
    setEditorMode(NoteEditorMode.EDIT);
    if (formRef) {
      formRef.current.setErrors({});
    }
  };

  const handleCancel = (setFieldValue) => {
    setFieldValue("text", (currentNote?.text || ""));
    setFieldValue("sharedWithUser", (currentNote?.is_visible || currentNote?.config?.is_visible || false));
    setFieldValue("includedInExport", (currentNote?.include_in_export || currentNote?.config?.is_visible || false));
    setFieldValue("hashtags", (currentNote?.hashtags?.map(i => `#${i}`).join(" ") || ""));

    setEditorMode(NoteEditorMode.READ);
  };

  const handleDelete = () => {
    setShowModalConfirm(true);
  };

  const handleShareWithUserChecked = async (e, setFieldValue, values) => {
    const checked = e.target.checked;
    setFieldValue("sharedWithUser", checked);
    setShareWithUserChecked(checked);
    if (!isFroalaEditor && values.text) {
      const payload = {
        is_visible: checked,
        include_in_export: includeInExportsChecked
      };
      await handleUpdateNote(values, payload);
    }
  };

  const handleIncludeInExports = async (e, setFieldValue, values) => {
    const checked = e.target.checked;
    setFieldValue("includedInExport", checked);
    setIncludeInExportsChecked(checked);
    if (!isFroalaEditor && values.text) {
      const payload = {
        is_visible: shareWithUserChecked,
        include_in_export: checked
      };
      await handleUpdateNote(values, payload);
    }
  };

  const handleSetCharactersCount = (data) => {
    setCharactersCount(data);
  };

  const prepareNoteTitle = (text) => {
    return ReactHtmlParser(text, {
      decodeEntities: true,
      transform: (node, index) => {
        return convertNodeToElement(node, index, (node) => {
          if (node?.data) {
            return reactStringReplace(node.data, parsBracketsRegex, (match, i) => (
              <AsrTermBubble key={i} term={match}/>
            ));
          }
        });
      },
    });
  };

  const CustomDeleteFooter = () => {
    const confirmDeleteNote = async () => {
      await changeQuestionNote({ ...emptyNote }, currentNote, question_id);

      const newNotePayload = {
        question_id: getQuestionId(question_id),
        question_panel: QUESTIONS_PANELS.NOTES,
        data: {
          currentNote: null,
          mode: NoteEditorMode.READ
        }
      };
      dispatch(modifySurveyAuditorQuestionPanel(newNotePayload));
      if (onNoteDelete) {
        const note = null;
        onNoteDelete({ note });
      }
      if (formRef) {
        formRef.current.setFieldValue("text", "");
        formRef.current.setFieldValue("sharedWithUser", false);
        formRef.current.setFieldValue("includedInExport", false);
        formRef.current.setFieldValue("hashtags", "");
        formRef.current.setTouched({ "text": false });
      }

      setShowModalConfirm(false);
    };

    const closeModal = () => {
      setShowModalConfirm(false);
    };

    return (
      <>
        <Button
          variant="outlined"
          className={`${styles.saveButton} ${styles.cancelButton}`}
          onClick={closeModal}
        >
              <span className={styles.label}>
                {cancelBtnLabel}
              </span>
        </Button>
        <Button
          variant="outlined"
          className={styles.saveButton}
          onClick={confirmDeleteNote}
        >
              <span className={styles.label}>
                {confirmLabel}
              </span>
        </Button>
      </>
    );
  };

  const canHandleCheckBox = (
    (currentNote?.text && canModifyAssessorNotes)
    || (!currentNote?.text && canCreateAssessorNotes)
  );

  return (
    <div>
      <Formik
        validationSchema={validationSchema(locale)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({ isValid, values, errors, setFieldValue, touched }) => {
          return (
            <Form className={styles.formContainer}>
              <div className="editor-wrapper">
                {/*  NOTE EDITOR */}
                <div className={`${styles.noteEditor} ${!isFroalaEditor && styles.hidden}`}>
                  <div className={`${styles.froalaEditorContainer}`}>
                    <FroalaEditor
                      value={values["text"]}
                      // iframe
                      charCounterMax={500}
                      classes={{
                        editor: "asr-assessment-auditor-froala-editor"
                      }}
                      iframeStyle={froalaIframeInlineStyle}
                      charCounterCount={false}
                      setCharCount={handleSetCharactersCount}
                      onChange={(value) => setFieldValue("text", value)}
                    />
                    {(errors.text && touched.text) && <span
                      className={`form__error-text ${styles.froalaEditorContainerError}`}>{errors.text}</span>}
                  </div>

                  <HashtagsInput
                    locale={locale}
                    values={values}
                    setFieldValue={setFieldValue}
                    name="hashtags"/>
                </div>
                {/*   NOTE CONTENT  */}
                {(values.text) && (
                  <div className={`${styles.noteContent} ${isFroalaEditor && styles.hidden}`}>
                    <div className={`${styles.editorText}`}>
                      {prepareNoteTitle(values.text)}
                    </div>

                    <Hashtags hashtags={values["hashtags"]} locale={locale}/>
                  </div>
                )}
              </div>
              {/*  FOOTER  */}
              <div className={styles.formContainerFooter}>
                {/* FOOTER CHECKBOX */}
                {(values.text || isFroalaEditor) ? (
                  <div className={styles.formContainerFooterCheckBox}>
                    {/*  SHARE WITH USER */}
                    <NoteCheckbox
                      id={`${question_id}-${shareWithUserBtn}`}
                      checked={values["sharedWithUser"]}
                      handler={(e) => handleShareWithUserChecked(e, setFieldValue, values)}
                      label={shareWithUserBtn}
                      classes={{
                        tooltip: commonStyles.tooltipShared
                      }}
                      disabled={(!isFroalaEditor && !values.text) || !canHandleCheckBox}
                      isLocked={values?.is_locked}
                      tooltip={shareWithUserTooltip}
                      tooltipNoteIsLocked={values?.is_locked ? functionNotAvailableNoteIsLocked : ""}
                    />
                    {/*  INCLUDE IN EXPORTS  */}
                    <NoteCheckbox
                      id={`${question_id}-${includeInExportsBtn}`}
                      checked={values["includedInExport"]}
                      handler={(e) => handleIncludeInExports(e, setFieldValue, values)}
                      label={includeInExportsBtn}
                      classes={{
                        tooltip: commonStyles.tooltipIncludeExports,
                        root: commonStyles.tooltipIncludeExportsRoot
                      }}
                      disabled={(!isFroalaEditor && !values.text) || !canHandleCheckBox}
                      isLocked={values?.is_locked}
                      tooltip={includeInExportsTooltip}
                      tooltipNoteIsLocked={values?.is_locked ? functionNotAvailableNoteIsLocked : ""}
                    />
                  </div>
                ) : (
                  <>
                    {!isFroalaEditor && <div className={styles.noNotes}>{noNotes}</div>}
                  </>
                )}
                {/*  FOOTER ACTIONS  */}
                <FormFooterActions
                  locale={locale}
                  isValid={isValid}
                  isNoteEmpty={!values.text}
                  isFroalaEditor={isFroalaEditor}
                  handleCancel={() => handleCancel(setFieldValue)}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  values={values}
                  canCreateAssessorNotes={canCreateAssessorNotes}
                  canModifyAssessorNotes={canModifyAssessorNotes}
                  canRemoveAssessorNotes={canRemoveAssessorNotes}
                  tooltipNoteIsLocked={values?.is_locked ? functionNotAvailableNoteIsLocked : ""}
                />
              </div>
              {showModalConfirm && (
                <ConfirmDeleteModal
                  show={showModalConfirm}
                  setShow={setShowModalConfirm}
                  title={deleteNote}
                  text={<div dangerouslySetInnerHTML={{ __html: deleteNoteText }}/>}
                  CustomFooter={CustomDeleteFooter}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NoteForm;