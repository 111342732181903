import React, { lazy, Suspense } from "react";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
// PAGES PAGES
import { NotFound } from "@/pages/NotFound/NotFound";
// COMPONENTS
import LazyLoader from "@/shared/UI/Loaders/LazyLoader/LazyLoader";
// STORE
import { getIsUserAuthenticated } from "@/store/User/selectors";
// LAZY PAGES
const Labels = lazy(() => import("@/pages/Labels"));
const Statements = lazy(() => import("@/pages/Statements"));
const StaticPagesEditor = lazy(() => import("@/pages/StaticPages/StaticPagesEditor"));
const Reports = lazy(() => import("@/pages/Reports"));
const Logs = lazy(() => import("@/pages/Logs"));
const Users = lazy(() => import("@/pages/Users"));
const Campaign = lazy(() => import("@/pages/Campaign"));
const Organization = lazy(() => import("@/pages/Organization"));
const OrganizationList = lazy(() => import("@/pages/Organization/UserOrganizations/OrganizationList"));


const MainPageRouter = (props) => {
  const isUserAuthenticated = useSelector(getIsUserAuthenticated);
  const { setOnScrollData } = props;

  return (
    <Suspense fallback={<LazyLoader/>}>
      <Switch>
        <Route path="/users/">
          {isUserAuthenticated && <Users/>}
        </Route>

        <Route path="/organizations">
          {isUserAuthenticated && <OrganizationList/>}
        </Route>

        <Route path="/statements">
          {isUserAuthenticated && <Statements/>}
        </Route>
        {/*  LABELS */}
        <Route path="/labels">
          {isUserAuthenticated && <Labels/>}
        </Route>
        {/*  PAGES  */}
        <Route path="/static-editor">
          {isUserAuthenticated && <StaticPagesEditor/>}
        </Route>
        {/* LOGS */}
        <Route path="/log">
          {isUserAuthenticated && <Logs/>}
        </Route>
        {/*  REPORTS  */}
        <Route path="/reports">
          {isUserAuthenticated && <Reports/>}
        </Route>

        <Route path='/campaign/:campaignId'>
          <Campaign setOnScrollData={setOnScrollData}/>
        </Route>

        <Route path='/organization/:organizationId'>
          <Organization setOnScrollData={setOnScrollData}/>
        </Route>

        <Route exact path="/">
          <Redirect to="/organizations"/>
        </Route>

        <Route path="*">
          <NotFound/>
        </Route>
      </Switch>
    </Suspense>
  );
};
const mapDispatchToProps = {};

MainPageRouter.displayName = "MainPageRouter";
export default connect(null, mapDispatchToProps)(withRouter(MainPageRouter));