import { useMemo } from "react";
import { useSelector } from "react-redux";
// UTILS
import { attachEventToBracketsTooltipText, parseBracketsWithTooltipText } from "@/utils/customFunctions";
// STORE
import { getLocale } from "@/store/Settings/selectors";


const useTextMarkdown = (surveyModel) => {
  const { locale } = useSelector(getLocale);

  useMemo(() => {
    if (!surveyModel) return;

    surveyModel
      .onTextMarkdown
      .add((survey, options) => {
        const qId = options?.element?.question_id;
        options.html = parseBracketsWithTooltipText(options.text, qId, locale, true);
      });

    surveyModel.onAfterRenderQuestion.add((sender, options) => {
      attachEventToBracketsTooltipText({
        locale: surveyModel.locale || "en",
        questionId: options.question.propertyHash?.question_id
      });
    });

    surveyModel.onLocaleChangedEvent.add(() => {
      attachEventToBracketsTooltipText({ locale: surveyModel.locale || "en" });
    })
  }, [locale, surveyModel]);

};

export default useTextMarkdown;