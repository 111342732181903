import { success, error } from "redux-saga-requests";
import * as types from "./types";

const initialState = {
    content: "",
    errors: null,
    multiLangInfo: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case success(types.FETCH_STATIC_PAGE): {
            const { response } = action.payload;
            return {
                ...state,
                content: response.results,
                errors: null,
            };
        }

        case success(types.FETCH_STATIC_PAGE_EXTENDED): {
            const { response } = action.payload;
            return {
                ...state,
                multiLangInfo: response.results,
                errors: null,
            };
        }

        case error(types.FETCH_STATIC_PAGE_EXTENDED):
        case error(types.FETCH_STATIC_PAGE):
        case error(types.UPDATE_STATIC_PAGE): {
            return {
                ...state,
                errors: action.payload.response.data.errors || action.payload.message,
            };
        }

        default:
            return state;
    }
};