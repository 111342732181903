import React from "react";
import { NavLink, useParams, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
// CONSTANTS
import { actionsConstants } from "../../../../../../../shared/hooks/policyValidation/constants/actionsConstants";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import usePolicyValidation from "../../../../../../../shared/hooks/policyValidation/usePolicyValidation";
// COMPONENTS
import UsersNestedLinks from "./components/UsersNestedLinks/UsersNestedLinks";


const SideNavUsers = ({ isStaffView, props }) => {
  const match = useRouteMatch();
  const { campaignId } = useParams();
  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const canCampaignParticipantListView = usePolicyValidation({
    action: actionsConstants.CampaignParticipantListView,
    lookup: {
      campaign: campaignId,
    }
  });

  const canCampaignInvitationListView = usePolicyValidation({
    action: actionsConstants.CampaignInvitationListView,
    lookup: {
      campaign: campaignId,
    }
  });
  // TODO: POL
  const canSeeUsers = canCampaignParticipantListView
    || canCampaignInvitationListView;
  //   useCheckPermissions(
  //     userPermissions,
  //     [
  //       "campaign.member.see_members",
  //       "campaign.member_invitation.see_list",
  //       "campaign.assessment.managing_of_non_auth_assessment_answers",
  //       "campaign.member_role.see_list",
  //       "campaign.member.manage_assessor_relations",
  //       "organization.full_access.all_permissions_to_any_campaign_in_organization",
  //     ],
  //     false
  // );

  if (!canSeeUsers) return null;

  return (
    <>
      <NavLink
        className={prependedClass}
        to={`${match.url}/members`}
        onClick={props.closeNavCallBack}
      >
        <IntlMessages id="campaign.participants"/>
      </NavLink>
      <UsersNestedLinks
        props={props}
        canCampaignParticipantListView={canCampaignParticipantListView}
        canCampaignInvitationListView={canCampaignInvitationListView}
      />
    </>
  );
};

export default SideNavUsers;