import React, { useRef, useState } from "react";
import classnames from "classnames";
// COMPONENTS
import MyProfileReadOnlyForm from "./components/MyProfileReadOnlyForm";
import MyProfileEditForm from "./components/MyProfileEditForm";
import ValidateUserPasswordModal from "@/components/Profile/Modals/ValidateUserPasswordModal";
import AsrCommonLoader from "@/shared/UI/Loaders/AsrCommonLoader";
import EmailChangeConfirmationMessageModal from "../../modals/EmailChangeConfirmationMessageModal";
import ReplaceEmailConfirmationModal from "@/components/Auth/Modals/ReplaceEmailConfirmationModal";
// STORE
import { useUpdateUserProfileMutation } from "@/store/services/users/rtk";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import useErrors from "@/shared/hooks/errors/useErrors";
// STYLES
import rootContentStyles from "../../styles.module.scss";
import styles from "./styles.module.scss";

const MyProfileContent = ({ profileInfo }) => {
  const [isReadOnlyMode, setIsReadOnlyMode] = useState(true);
  const [verifiedPassword, setVerifiedPassword] = useState(null);
  const [showVerificationPasswordModal, setShowVerificationPasswordModal] = useState(false);
  const [showEmailChangedConfirmationMessageModal, setShowEmailChangedConfirmationMessageModal] = useState(false);
  const [showReplaceEmailConfirmationModal, setShowReplaceEmailConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  const { handleFetchErrors, setFormFieldErrors } = useErrors();

  const [updateUserProfile] = useUpdateUserProfileMutation();

  const toggleReadOnlyMode = () => {
    setIsReadOnlyMode(!isReadOnlyMode);
  };

  const onHandleButtonClick = () => {
    if (isReadOnlyMode) {
      setShowVerificationPasswordModal(true);
    } else {
      // toggle ReadOnly mode of not ref is set
      if (!formRef?.current) {
        return toggleReadOnlyMode();
      }

      // show confirmation modal to define if user really wants to change his email
      if (getIsNewEmail(formRef.current.values?.email)) {
        return setShowReplaceEmailConfirmationModal(true);
      }

      // just submit form data to update user email without any confirmation
      formRef.current.handleSubmit();
    }
  };

  const onCloseReplaceEmailConfirmationModal = () => {
    setShowReplaceEmailConfirmationModal(false);
  };

  const onSubmitReplaceEmailConfirmationModal = async () => {
    if (!formRef?.current) {
      await setShowReplaceEmailConfirmationModal(false);
      return toggleReadOnlyMode();
    }
    await formRef.current.handleSubmit();
    await setShowReplaceEmailConfirmationModal(false);
  };

  const closeVerificationModal = () => {
    setShowVerificationPasswordModal(false);
  };

  const onCloseEmailChangeConfirmationMessageModal = () => {
    toggleReadOnlyMode();
    setShowEmailChangedConfirmationMessageModal(false);
  };

  const onPasswordVerificationSucceed = ({ password }) => {
    setVerifiedPassword(password);
    setShowVerificationPasswordModal(false);
    toggleReadOnlyMode();
  };

  const getIsNewEmail = (newEmail) => newEmail && newEmail.toLowerCase() !== profileInfo?.personal_info?.email;

  const onSubmitValues = async (values) => {
    await setIsLoading(true);

    try {
      await updateUserProfile({
        userId: profileInfo?.id,
        verificationPassword: verifiedPassword,
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        titleName: values?.titleName,
        newPassword: values?.newPassword || undefined
      }).unwrap();

      if (getIsNewEmail(values?.email)) {
        await setShowEmailChangedConfirmationMessageModal(true);
      } else {
        await toggleReadOnlyMode();
      }
    } catch (e) {
      const errorData = e?.data;
      await handleFetchErrors(errorData, { notificationWhiteList: "*" });
      formRef?.current && setFormFieldErrors({
        setFieldError: formRef.current.setFieldError,
        details: errorData?.details
      });
    }
    await setIsLoading(false);
  };

  const handleButtonStyles = classnames({
    [styles.ProfileHandleButton]: true,
    [styles.ConfirmButton]: !isReadOnlyMode
  });

  if (!profileInfo) return null;
  return (
    <>
      <div className={styles.ProfileContentContainer}>
        {/* CARD FORM */}
        <div className={rootContentStyles.ContentCard}>
          {isReadOnlyMode ? (
            <MyProfileReadOnlyForm profileInfo={profileInfo}/>
          ) : (
            <MyProfileEditForm
              profileInfo={profileInfo}
              formRef={formRef}
              onSubmit={onSubmitValues}
            />
          )}
        </div>

        {/* HANDLE BUTTON */}
        <div className={handleButtonStyles} onClick={onHandleButtonClick}>
          {isReadOnlyMode ? (
            <IntlMessages id="appModule.update"/>
          ) : (
            <IntlMessages id="organizationMainPage.confirm"/>
          )}
        </div>
      </div>

      {/* MODALS */}
      {showVerificationPasswordModal && (
        <ValidateUserPasswordModal
          open={showVerificationPasswordModal}
          closeModal={closeVerificationModal}
          onSuccessHandler={onPasswordVerificationSucceed}
          lightModal={false}
        />
      )}

      {showEmailChangedConfirmationMessageModal && (
        <EmailChangeConfirmationMessageModal
          open={showEmailChangedConfirmationMessageModal}
          closeModal={onCloseEmailChangeConfirmationMessageModal}
        />
      )}

      {showReplaceEmailConfirmationModal && (
        <ReplaceEmailConfirmationModal
          open={showReplaceEmailConfirmationModal}
          closeModal={onCloseReplaceEmailConfirmationModal}
          onSubmit={onSubmitReplaceEmailConfirmationModal}
          lightModal={false}
        />
      )}

      {isLoading && (
        <AsrCommonLoader/>
      )}
    </>
  );
};

export default MyProfileContent;

