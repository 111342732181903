import classnames from "classnames";

const useClasses = ({ classes }) => {
  const rootStyles = classnames({
    "survey-viewer-v2": true,
    [classes?.root]: Boolean(classes?.root)
  });

  return {
    root: rootStyles
  };
};

export default useClasses;
