import querystring from "query-string";
import { baseApiUrl } from "../../../configs/MainConfig";
import { requestActionCreator } from "../../../utils/requestActionCreator";
import * as types from "../types";
import { authService } from "../../../shared/services/auth.service";


export const fetchCampaignMemberInvitationsTable = (payload = {}) => (dispatch, getState) => {
  const currentState = getState();
  const pagination = currentState.campaign.invites.pagination;
  const sort = currentState.campaign.invites.sortBy;

  const payloadQuery = {
    ...pagination,
    ...sort
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/campaign/members/invitation/list/table/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_CAMPAIGN_MEMBER_INVITATIONS_TABLE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setCampaignMemberInvitationsListPagination = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBER_INVITATIONS_LIST_PAGINATION,
  payload
});

export const setCampaignMemberInvitationsSortOrder = (payload) => ({
  type: types.SET_CAMPAIGN_MEMBER_INVITATIONS_SORT_ORDER,
  payload
});

// MODALS
export const toggleCampaignMemberInvitationsModal = (payload) => ({
  type: types.TOGGLE_CAMPAIGN_MEMBER_INVITATIONS_MODAL,
  payload
});