import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList } from "recharts";
// REACT-PDF
import { View, Text, StyleSheet } from "@react-pdf/renderer";
// COMPONENTS
import ChartSvg from "../../Components/Chart";
import CardBox from "../CardBox/CardBox";
// UTILS
import { useIntlMessageToString } from "../../../../../../utils/IntlMessages";


// Create styles
const styles = StyleSheet.create({
  cardBody: {
    padding: "3px",
    border: "1px solid #24B2B2",
    display: "flex",
    flexDirection: "column"
  },
  chartWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  chartTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "8pt",
    backgroundColor: "#F5F5F5",
  },
  chartTitleText: {
    fontWeight: "medium",
    fontSize: "8pt",
    paddingVertical: "5pt",
    color: "#333333",
  },
  cardNoContent: {
    fontWeight: "medium",
    fontSize: "12pt",
    padding: "8pt 13pt",
    color: "#333333"
  }
});

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;
  return (
    <g>
      <text x={x + width - 20} y={y + 15} fill='#fff' textAnchor='middle' dominantBaseline='middle'>
          {value}%
      </text>
    </g>
  );
};

// Create Component
const Card6 = ({ data }) => {
  const heading = useIntlMessageToString("reports.card6Title");
  const noContent = useIntlMessageToString("reports.noContent");

  return (
    <CardBox heading={heading}>
      {data.every((question) => question.value === null)
        ? (
          <Text style={styles.cardNoContent}>{noContent}</Text>
        ) : (
          <View style={styles.cardBody}>
            {data.map((item) => {
              return (
                <View style={styles.chartWrapper} key={item.question_id}>
                  <View style={styles.chartTitle}>
                    <Text style={styles.chartTitleText}>{item.title}</Text>
                  </View>
                  <View>
                    <ChartSvg>
                      <BarChart
                        layout='vertical'
                        data={[item]}
                        width={500}
                        height={70}
                      >
                        <CartesianGrid horizontal={false}/>
                        <XAxis
                          type='number'
                          domain={[0, 100]}
                          ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                          hide
                        />
                        <YAxis
                          dataKey='title'
                          type='category'
                          width={0}
                          tickLine={false}
                          axisLine={false}
                          tick={false}
                        />
                        <Bar dataKey='value' barSize={30} fill='#4ea684' isAnimationActive={false}>
                          <LabelList dataKey='value' content={renderCustomizedLabel}/>
                        </Bar>
                      </BarChart>
                    </ChartSvg>
                  </View>
                </View>
              );
            })}
          </View>
      )}
    </CardBox>
  );
};

export default Card6;
