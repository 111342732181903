import { createSelector } from "reselect";
import { QUESTIONS_PANELS } from "@/shared/constants/surveyJs";
import { FETCH_SURVEY_ANALYTICS  } from "./types";

export const surveyState = (state) => state.survey;
export const networkState = (state) => state.network;

export const getQuestionsAuditorControls = createSelector(surveyState, (state) => state?.surveyManagement?.auditorControls);
export const getQuestionsManagement = createSelector(surveyState, (state) => state?.surveyManagement?.auditorControls?.questionsManagement ?? {});
export const getQuestionNotesChecked = createSelector(surveyState, (state) => {
  if (state?.surveyManagement?.auditorControls?.headerSections) {
    return state?.surveyManagement?.auditorControls?.headerSections[QUESTIONS_PANELS.NOTES]?.checked;
  }
});
export const getAvailableLocales = createSelector(surveyState, (state) => state?.surveyManagement?.settings?.availableLocales);
export const getCurrentLocale = createSelector(surveyState, (state) => state?.surveyManagement?.settings?.currentLocale);
export const getQuestionAnalyticsChecked = createSelector(surveyState, (state) => {
  if (state?.surveyManagement?.auditorControls?.headerSections) {
    return state?.surveyManagement?.auditorControls?.headerSections[QUESTIONS_PANELS.ANALYTICS]?.checked;
  }
});
export const getQuestionRecommendationsChecked = createSelector(surveyState, (state) => {
  if (state?.surveyManagement?.auditorControls?.headerSections) {
    return state?.surveyManagement?.auditorControls?.headerSections[QUESTIONS_PANELS.RECOMMENDATIONS]?.checked;
  }
});

export const getSurveyAnalytics = createSelector(surveyState, (state) => state?.surveyAnalytics?.list);
export const getSurveyAnalyticIds = createSelector(surveyState, (state) => state?.surveyAnalytics?.ids);
export const getCurrentSurveyAnalytics = createSelector(surveyState, (state) => state?.surveyAnalytics?.currentAnalytics);
// NETWORK
export const fetchSurveyAnalyticsLoading = createSelector(networkState, (state) => Boolean(state?.mutations[FETCH_SURVEY_ANALYTICS]?.pending));