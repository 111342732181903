import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const labelGroup = {
  [actionsConstants.LabelGroupListView]: simpleValidator,
  [actionsConstants.LabelGroupCreate]: simpleValidator,
  [actionsConstants.LabelGroupModify]: simpleValidator,
  [actionsConstants.LabelGroupLockUnlock]: simpleValidator,
  [actionsConstants.LabelGroupDelete]: simpleValidator,
};

export default labelGroup;