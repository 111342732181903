import React, { forwardRef } from "react";
// STYLES
import styles from "./styles.module.scss";
// ASSETS
import LogoError from "../../../../assets/images/logo-assessor-error.svg";
import Logo from "../../../../assets/images/logo-assessor.svg";

export const NotificationContent = forwardRef(({ text, type }, ref) => {
  const logoControl = type === "success" ? Logo : LogoError;
  const errorClass = type === "error" ? styles.colorError : null;

  const errors = () => {
    if (typeof text === "object") {
      return Object.values(text).map((item, index) => {
        return <p key={`${item}_${index}`} className={errorClass}>{item}</p>;
      });
    }

    if (typeof text === "string") {
      return <p className={errorClass}>{text}</p>;
    }
  };

  return (
      <div ref={ref} className={styles.systemNotificationsContainer}>
        <div className={styles.systemNotificationsContentWrapper}>
          <img src={logoControl} alt={"logo"} className={styles.systemNotificationsLogo}/>
          {errors()}
        </div>
      </div>
  );
});