const allOwnedIdsValidator = ({ lookup, isOwner, policySettings }) => {
  let hasPermission = false;
  for (const policySetting of policySettings) {
    if (policySetting.all) {
      hasPermission = true;
      break;
    }

    if (policySetting.owned && isOwner) {
      hasPermission = true;
      break;
    }

    if (policySetting.ids.includes(lookup.id) || policySetting.ids.includes(Number(lookup.id))) {
      hasPermission = true;
      break;
    }
  }
  return hasPermission;
};

export default allOwnedIdsValidator;