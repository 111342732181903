import React from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

import localization from "@/localization";

const InjectMassage = props => <FormattedMessage {...props} />;
export default injectIntl(InjectMassage, {
  withRef: false
});

export function useIntlMessageToString(messageId, values) {
  return useIntl().formatMessage({ id: messageId }, values);
}

export function getIntlMessageToStringLocal(locale, messageId, values) {
  let localizationString = localization[locale]?.messages[messageId] || messageId;
  if (values) {
    Object.keys(values).map((item) => {
      localizationString = localization[locale]?.messages[messageId].replace(/\{(.*?)\}/gmi, `<strong>${values[item]}</strong>`);
    });
  }
  return localizationString;
}

export function getAvailableLocales(currentLocale = "en", withLanguageName = false) {
  if (withLanguageName) {
    return Object.keys(localization).map((locale) => {
      return {
        locale,
        language: localization[currentLocale]?.messages[`appModule.${locale}`],
      };
    });
  }
  return Object.keys(localization);
}

export function getNormalizeAvailableLocales(currentLocale = "en") {
  return Object.keys(localization).reduce((acc, item) => {
    return {
      ...acc,
      [item]: {
        locale: item,
        language: localization[currentLocale]?.messages[`appModule.${item}`],
      }
    };
  }, []);
}

// UTILS FOR FORMS
export function getTranslatableFields(fields, locales) {
  const translatableFields = {};
  locales.forEach((item) => {
    fields.forEach((field) => {
      translatableFields[`${field}_${item}`] = "";
    });
  });

  return translatableFields;
}

export function getTranslatableFieldsWithDefaultValue(fields, locales) {
  const translatableFields = {};
  locales.forEach((item) => {
    fields.forEach((field) => {
      translatableFields[`${field.name}_${item}`] = field.value;
    });
  });
  return translatableFields;
}

//
export const transformTranslatableFieldsToPayload = (values, fields) => {
  const result = {};
  fields.forEach((field) => {
    return getAvailableLocales().forEach((item) => {
      result[`${field}_${item}`] = values[`${field}_${item}`] || "";
    });
  });
  return result;
};

export const transformTranslatableFieldsToInitialValues = (values, fields) => {
  const result = {};

  fields.forEach((field) => {
    return getAvailableLocales().forEach((item) => {
      result[`${field}_${item}`] = values?.[`${field}_${item}`] || "";
    });
  });
  return result;
};

export const getTranslatableFieldsName = (name) => {
  return getAvailableLocales().map((lang) => `${name}_${lang}`);
};