import React, { useMemo } from "react";
import classnames from "classnames";
// MATERIAL UI
import Avatar from "@mui/material/Avatar";
// CONSTANTS
import { imageSourceType } from "@/shared/constants/forms";
// COMPONENTS
import AsrSvgIcon from "@/shared/components/AsrSvgIcon";
import PreviewImageActions from "../PreviewImageActions/PreviewImageActions";
// STYLES
import styles from "../styles.module.scss";


const PreviewUploadedImage = ({
                                inputFileId,
                                setLogoContent,
                                logoContent,
                                imageRounded
                              }) => {
  const getUrl = useMemo(() => {
    if (typeof logoContent?.[imageSourceType.UPLOADED_FILE] === "string") {
      return logoContent?.[imageSourceType.UPLOADED_FILE];
    } else {
      return logoContent?.[imageSourceType.UPLOADED_FILE] && URL.createObjectURL(logoContent?.[imageSourceType.UPLOADED_FILE]);
    }
  }, [logoContent]);

  const handleRemove = (e) => {
    e.preventDefault();
    setLogoContent((prevState) => ({
      ...prevState,
      [imageSourceType.UPLOADED_FILE]: ""
    }));
  };

  const previewImageContainerStyles = classnames({
    [styles.PreviewImageContainer]: true,
    [styles.Rounded]: imageRounded
  });

  const avatarStyles = classnames({
    [styles.Avatar]: true,
    [styles.Rounded]: imageRounded
  });

  return (
    <div className={styles.PreviewImageLabel}>
      <div className={previewImageContainerStyles}>
        {logoContent?.[imageSourceType.UPLOADED_FILE] ? (
          <Avatar
            variant="rounded"
            alt="campaignAvatar"
            src={getUrl}
            className={avatarStyles}
          />
        ) : (
          <AsrSvgIcon
            classes={{
              asrSvgIconContainer: avatarStyles
            }}
            icon="logo_placeholder"
          />
        )}
        <div className={styles.ImagePreviewActions}>
          <PreviewImageActions
            onRemove={handleRemove}
            isRemove={getUrl}
            replaceLabelID={inputFileId}
            imageRounded={imageRounded}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewUploadedImage;