import { actionsConstants } from "../../constants/actionsConstants";
import allOwnedIdsValidator from "../validatorsHandlers/allOwnedIdsValidator";
import simpleValidator from "../validatorsHandlers/simpleValidator";


const accountMember = {
  [actionsConstants.AccountMemberListView]: simpleValidator,
  [actionsConstants.AccountMemberCreate]: simpleValidator,
  [actionsConstants.AccountMemberModify]: allOwnedIdsValidator,
  [actionsConstants.AccountMemberLockUnlock]: allOwnedIdsValidator,
  [actionsConstants.AccountMemberHoldAndActivate]: allOwnedIdsValidator,
  [actionsConstants.AccountMemberDelete]: allOwnedIdsValidator,
};

export default accountMember;