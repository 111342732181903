import React from "react";
// UTILS
import IntlMessages from "../../../../../../../../../utils/IntlMessages";
// STYLES
import styles from "./styles.module.scss";


const ImageInfo = ({ isImage }) => {
  return (
    <span className={styles.ImageInfo}>
      {isImage ? (
        <IntlMessages
          id="appCommon.uploadImageInfo"
          values={{
            imageFile: <span className={`${styles.ImageInfoImage}`}><IntlMessages id="appCommon.imageFile"/></span>
          }}
        />
      ) : (
        <IntlMessages
          id="appCommon.linkImageInfo"
          values={{
            link: <span className={styles.ImageInfoLink}><IntlMessages id="appCommon.link"/></span>
          }}
        />
      )}
    </span>
  );
};

export default ImageInfo;