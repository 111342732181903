import ReactDOMServer from "react-dom/server";
import React from "react";

import showdown from "showdown";

import defaultAvatar from "./../assets/images/Avatar-Default.svg";
import AppLocale from "../localization";
import getBrowserLanguage from "../shared/utils/getBrowserLanguage";
// COMPONENTS
import SurveyTermTooltip from "@/components/SurveyJs/components/ViewerV2/components/SurveyTermTooltip";
import languageData from "@/components/LanguageSwitcher/data";
// HELPERS
import { eventTooltip } from "@/components/SurveyJs/components/ViewerV2/helpers/eventTooltip";


export const getMediaUrl = (image, link) => {
  return image ? `${image}` : link;
};

export const getUserAvatar = (image, link) => {
  return image ? `${image}` : link ? link : defaultAvatar;
};

const getLocalesList = (langList = []) => {
  let locales = [];
  if (langList.length) {
    langList.map((lang) => locales.push(lang.locale));
  }

  return locales;
};


const getDefaultLocale = () => {
  const locale = languageData.filter((item) => item.locale === getBrowserLanguage())[0];
  return locale ? locale : {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  };
};

const setLocale = (langList = []) => {
  const localStorageLocale = localStorage.getItem("locale");
  let locale = undefined;
  if (langList.length && localStorageLocale) {
    locale = langList.find((lang) => lang.locale === localStorageLocale);
  }

  return locale || getDefaultLocale();
};

export const getCurrentLocale = () => setLocale(languageData);

export const localesList = getLocalesList(languageData);

export const parseBracketsText = (text, cbName) => {
  function getLink(match, p1) {
    const preparedP1 = p1.replace("'", "\\'").replace('"', '\\"');
    let link = `<a class="title__link" onClick="${cbName}('${preparedP1}')">${p1}</a>`;
    return link;
  }

  return text.replace(/\[\[(.*?)\]\]/gmi, getLink);
};

export const parseBracketsWithTooltipText = (text, id, locale, ignoreEventTooltip) => {
  function getLink(match) {
    return ReactDOMServer.renderToString(
      <SurveyTermTooltip
        id={id}
        text={match}
        locale={locale}
        ignoreEventTooltip={ignoreEventTooltip}
      />
    );
  }

  return text.replace(/\[\[(.*?)\]\]/gmi, getLink);
};

export const attachEventToBracketsTooltipText = ({
                                                   locale,
                                                   questionId
                                                 }) => {
  let idQueryLookup = "asr-cf-term-link__";
  if (questionId) {
    idQueryLookup += questionId;
  }
  let queryLookup = `[id^="${idQueryLookup}"]`;

  let linkEls = document.querySelectorAll(queryLookup);
  linkEls.forEach(linkEl => {
    eventTooltip({
      termLink: linkEl,
      // id: linkEl.dataset.questionId,
      text: linkEl.dataset.termText,
      locale: locale || linkEl.dataset.language || "en"
    });
  });
};

export const parseBrackets = (text) => {
  return text.replace(/[\[\]']/gmi, "");
};


export const getMessage = (locale, message) =>
  AppLocale[locale].messages[message];


export const removeDomElements = (elements) => {
  elements && elements.forEach(el => {
    el.remove();
  });
};

const converter = new showdown.Converter();

export const parseTermins = (string) => {
  if (!string) {
    return "";
  }
  const title = parseBracketsText(string, "window.openTermModal");
  let str = converter.makeHtml(title);
  // remove root paragraphs <p></p>
  str = str.substring(3);
  str = str.substring(0, str.length - 4);
  return str;
};

export const prepareQuestionAnswer = (questionAnswer) => {
  if (Array.isArray(questionAnswer)) {
    return questionAnswer
      .map(item => parseTermins(item))
      .join("<br/>");
  } else if (typeof questionAnswer === "object") {
    return Object.entries(questionAnswer)
      .map(([key, value]) => {
        return `${parseTermins(key)}<span> - </span>${value}`;
      })
      .join("<br/>");
  } else if (typeof questionAnswer === "string") {
    return parseTermins(questionAnswer);
  } else {
    return questionAnswer;
  }
};