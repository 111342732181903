import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
// CONSTANTS
import { actionsConstants } from "@/shared/hooks/policyValidation/constants/actionsConstants";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// HOOKS
import usePolicyValidation from "@/shared/hooks/policyValidation/usePolicyValidation";
// COMPONENTS
import MembersNestedLinks from "./components/MembersNestedLinks/MembersNestedLinks";


const SideNavMembers = ({
                          props,
                          isStaffView
                        }) => {
  const match = useRouteMatch();

  const prependedClass = classNames({
    "prepend-icon": true,
    "admin": isStaffView,
  });

  const canAccountMemberListView = usePolicyValidation({
    action: actionsConstants.AccountMemberListView,
  });
  const canAccountInvitationListView = usePolicyValidation({
    action: actionsConstants.AccountInvitationListView,
  });
  const canAccountRoleListView = usePolicyValidation({
    action: actionsConstants.AccountRoleListView,
  });

  const canPolicyListView = usePolicyValidation({
    action: actionsConstants.PolicyListView,
  });

  const canCampaignParticipantAuditorAssignment = usePolicyValidation({
    action: actionsConstants.CampaignParticipantAuditorAssignment,
  });

  const canSeeMembers = (
    canAccountMemberListView
    || canAccountInvitationListView
    || canAccountRoleListView
    || canPolicyListView
    || canCampaignParticipantAuditorAssignment
  );

  const userPermissions = {
    canAccountMemberListView,
    canAccountInvitationListView,
    canAccountRoleListView,
    canPolicyListView,
    canCampaignParticipantAuditorAssignment
  };

  if (!canSeeMembers) return null;

  return (
    <>
      <NavLink
        className={prependedClass}
        to={`${match.url}/members`}
        onClick={props.closeNavCallBack}
      >
        <IntlMessages id="organization.members"/>
      </NavLink>
      <MembersNestedLinks
        props={props}
        userPermissions={userPermissions}/>
    </>
  );
};

export default SideNavMembers;