export const appConstants = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  STAGING: "staging",
};

export const appReleaseNotesLink = "/release-notes/";

export const appViewModes = {
  DEFAULT: "default",
  IFRAME: "iframe"
};

export const cookieConsentKey = "CookieConsent";
export const cookieConsentVersion = "1.0";
