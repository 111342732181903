import { actionsConstants } from "../../constants/actionsConstants";
import simpleValidator from "../validatorsHandlers/simpleValidator";

const customField = {
  [actionsConstants.CustomFieldListView]: simpleValidator,
  [actionsConstants.CustomFieldCreate]: simpleValidator,
  [actionsConstants.CustomFieldModify]: simpleValidator,
  [actionsConstants.CustomFieldDelete]: simpleValidator,
};

export default customField;