import { success } from "redux-saga-requests";
import * as types from "./types";
import { getAvailableLocales } from "../../utils/IntlMessages";
import {
  DEFAULT_STATEMENT_SETS_SORT,
  DEFAULT_STATEMENT_SORT,
  DEFAULT_STATEMENT_TYPES_SORT
} from "./constants";

const initialState = {
  statements: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: DEFAULT_STATEMENT_SORT
    },
  },
  statementTypes: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: DEFAULT_STATEMENT_TYPES_SORT
    },
  },
  sets: {
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: DEFAULT_STATEMENT_SETS_SORT
    },
  },
  selectedStatement: {},
  selectedSet: {},
  statementsTranslations: {
    table: {
      columns: getAvailableLocales()
    },
    list: [],
    availableCount: 0,
    pagination: {
      page_size: 10,
      page: 1,
    },
    filters: {},
    sortBy: {
      sort_by: "-modified_dt"
    },
  },
  assessmentItemsForStatement: {
    list: [],
    availableCount: 0,
    pagination: {
      page: 1,
      page_size: 10,
    },
    filters: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case success(types.FETCH_STATEMENTS): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;

      return {
        ...state,
        statements: {
          ...state.statements,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_STATEMENTS_PAGINATION: {
      return {
        ...state,
        statements: {
          ...state.statements,
          pagination: {
            ...state.statements.pagination,
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_STATEMENTS_FILTERS: {
      return {
        ...state,
        statements: {
          ...state.statements,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_STATEMENTS_SORT_ORDER: {
      return {
        ...state,
        statements: {
          ...state.statements,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    case success(types.FETCH_STATEMENT_INFO):
    case success(types.FETCH_ORGANIZATION_STATEMENT_INFO): {
      const {
        response: {
          results,
        }
      } = action.payload;
      return {
        ...state,
        selectedStatement: results,
      };
    }
    case types.CLEAN_STATEMENT_INFO: {
      return {
        ...state,
        selectedStatement: {},
      };
    }
    case success(types.FETCH_STATEMENT_TYPES) : {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;
      return {
        ...state,
        statementTypes: {
          ...state.statementTypes,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_STATEMENT_TYPES_SORT_ORDER: {
      return {
        ...state,
        statementTypes: {
          ...state.statementTypes,
          sortBy: {
            ...action.payload,
          },
        },
      };
    }
    case types.SET_STATEMENT_TYPES_PAGINATION: {
      return {
        ...state,
        statementTypes: {
          ...state.statementTypes,
          pagination: {
            ...state.statementTypes.pagination,
            ...action.payload,
          },
        },
      };
    }
    case types.SET_STATEMENT_TYPES_FILTERS: {
      return {
        ...state,
        statementTypes: {
          ...state.statementTypes,
          filters: {
            ...action.payload,
          },
        },
      };
    }
    case success(types.FETCH_STATEMENTS_SETS): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;

      return {
        ...state,
        sets: {
          ...state.sets,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_STATEMENTS_SETS_PAGINATION: {

      return {
        ...state,
        sets: {
          ...state.sets,
          pagination: {
            ...state.sets.pagination,
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_STATEMENTS_SETS_FILTERS: {
      return {
        ...state,
        sets: {
          ...state.sets,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_STATEMENTS_SETS_SORT_ORDER: {
      return {
        ...state,
        sets: {
          ...state.sets,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    case success(types.FETCH_STATEMENTS_SET_INFO): {
      const {
        response: {
          results,
        }
      } = action.payload;

      return {
        ...state,
        selectedSet: results,
      };
    }
    case types.CLEAN_STATEMENTS_SET_INFO: {
      return {
        ...state,
        selectedSet: {},
      };
    }
    case success(types.FETCH_STATEMENTS_TRANSLATIONS): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;

      return {
        ...state,
        statementsTranslations: {
          ...state.statementsTranslations,
          list: results,
          availableCount: count,
          pagination: {
            page_size,
            page,
          }
        }
      };
    }
    case types.SET_STATEMENTS_TRANSLATIONS_PAGINATION: {
      return {
        ...state,
        statementsTranslations: {
          ...state.statementsTranslations,
          pagination: {
            ...state.statementsTranslations.pagination,
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_STATEMENTS_TRANSLATIONS_FILTERS: {
      return {
        ...state,
        statementsTranslations: {
          ...state.statementsTranslations,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_STATEMENTS_TRANSLATIONS_SORT_ORDER: {
      return {
        ...state,
        statementsTranslations: {
          ...state.statementsTranslations,
          sortBy: {
            ...action.payload.data,
          },
        },
      };
    }
    case success(types.FETCH_ASSESSMENT_ITEMS_FOR_STATEMENT): {
      const {
        response: {
          results,
          count,
          page,
          page_size,
        }
      } = action.payload;

      const addToList = results
        .filter(itemResponse => !state.assessmentItemsForStatement.list.some(itemState => itemState.id === itemResponse.id));

      return {
        ...state,
        assessmentItemsForStatement: {
          ...state.assessmentItemsForStatement,
          list: [...state.assessmentItemsForStatement.list, ...addToList],
          availableCount: count,
          pagination: {
            page,
            page_size,
          }
        }
      };
    }
    case types.SET_ASSESSMENT_ITEMS_FOR_STATEMENT_PAGINATION: {
      return {
        ...state,
        assessmentItemsForStatement: {
          ...state.assessmentItemsForStatement,
          pagination: {
            ...state.assessmentItemsForStatement.pagination,
            ...action.payload.data,
          },
        },
      };
    }
    case types.SET_ASSESSMENT_ITEMS_FOR_STATEMENT_FILTERS: {
      return {
        ...state,
        assessmentItemsForStatement: {
          ...state.assessmentItemsForStatement,
          filters: {
            ...action.payload.data,
          },
        },
      };
    }
    case types.CLEAN_ASSESSMENT_ITEMS_FOR_STATEMENT: {
      return {
        ...state,
        assessmentItemsForStatement: {
          ...state.assessmentItemsForStatement,
          list: [],
          availableCount: 0,
          pagination: {
            page: 1,
            page_size: 10,
          }
        }
      };
    }
    default:
      return state;
  }
};
