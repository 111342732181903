import React from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";


const CustomScrollbars = React.forwardRef(({ options = {}, ...props }, ref) => {
    return (
        <OverlayScrollbarsComponent
            ref={ref}
            options={options}
            {...props}
        />
    );
});

export default CustomScrollbars;