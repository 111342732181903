import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseApiUrlV2 } from "@/configs/MainConfig";
// HELPERS
import prepareHeaders from "@/store/services/utils/headers/prepareHeaders";


const assessorApi = createApi({
  reducerPath: "assessorApi",
  baseQuery: fetchBaseQuery(
    {
      baseUrl: `${baseApiUrlV2}/`,
      prepareHeaders: prepareHeaders,
    }),
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});

export default assessorApi;