/*eslint-disable  no-undef*/
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// DATA
import ignoreSentryErrors from "./data/ignoreSentryErrors";
import ignoreSentryUrls from "./data/ignoreSentryUrls";
// CONSTANTS
import { appConstants } from "../../constants/app";

class AppService {
  #ignoreSentryErrors = ignoreSentryErrors;
  #ignoreSentryUrls = ignoreSentryUrls;

  bootstrap() {
    this.#initSentry();
  }

  #initSentry() {
    const isInitSentry = process.env.REACT_APP_ENVIRONMENT === appConstants.PRODUCTION || process.env.REACT_APP_ENVIRONMENT === appConstants.STAGING;
    if (isInitSentry) {
      Sentry.init({
        environment: process.env.REACT_APP_ENVIRONMENT,
        dsn: process.env.REACT_APP_SENTRY_KEY,
        integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
        tracesSampleRate: 1.0,
        ignoreErrors: this.#ignoreSentryErrors,
        ignoreUrls: this.#ignoreSentryUrls,
        beforeSend(event, hint) {
          const error = hint.originalException;
          // ERROR in survey-knockout | will be fixed in SurveyCreator v2
          if (error && error.message && (error.message.match(/Unable to process binding "foreach: function\(\){return actions }"/i))) {
            return null;
          }

          if (error && error.message && (error.message.match(/Non-Error promise rejection captured with"/i))) {
            return null;
          }
          return event;
        },
      });
    }
  }
}

export const appService = new AppService();