import icons from "@/components/SurveyJs/icons/customIcons";
import { parseBracketsWithTooltipText } from "@/utils/customFunctions";
import { eventTooltip } from "../eventTooltip";
import { QUESTION_PROPERTIES } from "@/shared/constants/surveyJs";

export const getQuestionId = (id) => {
  return `q${id}`;
};


// After SurveyJs v1.9.2 disappeared span with class ".circle". it is needed to style custom radio buttons
// export const modifyRadioGroupQuestion = (options) => {
//   if (options.question.getType() === "radiogroup") {
//     const inputElements = options.htmlElement.querySelectorAll(".radio-input__item__label");
//     inputElements.forEach((item) => {
//       const circle = document.createElement("span");
//       circle.classList.add("circle");
//       circle.innerHTML = icons.circle;
//       item.appendChild(circle);
//     });
//   }
// };
// After SurveyJs v1.9.34 disappeared span with class ".circle". it is needed to style custom radio buttons
// export const modifySingleMatrixGroupQuestion = (options) => {
//   if (options.question.getType() === "matrix") {
//     const inputElements = options.htmlElement.querySelectorAll(".matrix-input__label");
//     inputElements.forEach((item) => {
//       const circleElements = item.querySelector(".circle");
//       if (circleElements) return;
//
//       const circle = document.createElement("span");
//       circle.classList.add("circle");
//       circle.innerHTML = icons.circle;
//       item.appendChild(circle);
//     });
//   }
// };

// ADD Custom Icon && TERM TOOLTIP
export const modifyRadioGroupQuestionSortableList = (options, { locale }) => {
  if (options.question.getType() !== "sortablelist") return;

  const qId = options.question.propertyHash.question_id;
  const sortableItem = options.htmlElement.querySelectorAll(".sjs-sortablejs-item");

  sortableItem.forEach((item) => {
    const text = item.innerHTML;

    const iconDragElement = item.querySelector(".handle");

    if (iconDragElement) return null;

    item.innerHTML = `<div>${parseBracketsWithTooltipText(item.innerHTML, qId, locale)}</div>`;

    text.replace(/\[\[(.*?)\]\]/gmi, (match) => {
      const termLink = document.getElementById(`asr-cf-term-link__${qId}-${match}`);

      if (termLink) eventTooltip({ termLink, qId, text: match, locale });
    });

    const handle = document.createElement("div");
    handle.classList.add("handle");
    handle.innerHTML = icons.dragelement;

    item.prepend(handle);
  });
};


export const modifyDropdown = (options) => {
  if (options.question.getType() !== "dropdown") return;
  const selectElem = options.htmlElement.querySelector(".sv_select_wrapper");
  if (!selectElem) return;

  const optionsElement = selectElem.querySelectorAll("option");
  optionsElement.forEach(option => {
    option.innerHTML = option.innerHTML.replace(/\[\[(.*?)\]\]/gmi, "$1");
  });
};

export const modifyRadiogroup = (options) => {
  if (options.question.getType() !== "radiogroup") return;

  const inputsEl = options.htmlElement.querySelectorAll("input");
  const setCheckClass = () => {
    inputsEl.forEach(i => {
      if (i.checked) {
        i.onclick = () => {
          options.question.value = null;
          return options.question.clearValue();
        };
      } else {
        i.onclick = () => {
        };
      }
    });
  };
  options.question.onPropertyChanged.add(() => {
    setCheckClass();
  });
};

// VALIDATE QUESTION ANSWER
export const validateQuestionAnswer = ({
                                         options,
                                         canModifyAnswers = true,
                                         validateLockAnswerProperty = false
                                       }) => {
  const question = options?.question;
  const lockAnswer = options?.question?.propertyHash?.[QUESTION_PROPERTIES.LOCK_ANSWER] ?? false;

  if (validateLockAnswerProperty && lockAnswer) {
    question.readOnly = true;
  }
  if (!canModifyAnswers) {
    question.readOnly = true;
  }
};