import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
// MUI
import Icon from "@mui/material/Icon";
// COMPONENTS
import AsrLangIcon from "@/shared/UI/Icons/AsrLangIcon";
import LanguageSwitcherSurvey from "./components/LanguageSwitcherSurvey";
// STORE
import { setSurveyLocale } from "@/store/Survey/actions";
import { getAvailableLocales, getCurrentLocale } from "@/store/Survey/selectors";
import { switchLanguage as switchSystemLanguage } from "@/store/Settings/actions";
// CONSTANTS
import surveyJsLanguages from "@/shared/constants/surveyJs/languages";
// UTILS
import { findSystemLangSettingByCode } from "@/utils/language";
// HOOKS
import useStyles from "../../hooks/useStyles";
// STYLES
import styles from "../../styles.module.scss";


const LanguageSelectorSurvey = ({
                                  onItemCLickHandler,
                                  classes,
                                  hideIfOnlyOneLang = true
                                }) => {
  const dispatch = useDispatch();
  const [langSwitcher, setLangSwitcher] = useState(false);
  const surveyLocale = useSelector(getCurrentLocale);
  const usedSurveyLocales = useSelector(getAvailableLocales);

  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
  };

  const onSwitchLanguage = (lang) => {
    if (!lang?.code) return;
    dispatch(setSurveyLocale({ locale: lang.code }));

    const systemLang = findSystemLangSettingByCode({ code: lang.code });
    if (systemLang) {
      localStorage.setItem("locale", systemLang.locale);
      dispatch(switchSystemLanguage(systemLang));
    }
    onItemCLickHandler && onItemCLickHandler();
  };
  const languageName = surveyJsLanguages[surveyLocale] || surveyLocale;

  const hookClasses = useStyles({ classes });

  if (hideIfOnlyOneLang && (!usedSurveyLocales?.length || usedSurveyLocales?.length < 2)) return null;
  return (
    <div className={hookClasses.root}>
      <Dropdown
        className={hookClasses.dropdown}
        isOpen={langSwitcher}
        toggle={onLangSwitcherSelect}
      >
        <DropdownToggle
          tag="span"
          data-toggle="dropdown"
          className={hookClasses.dropdownToggle}
        >
          <span className={hookClasses.label}>
            {languageName}
          </span>
          <Icon className={hookClasses.icon}>
            <AsrLangIcon classes={{
              circle: "app-header__language-icon_st0",
              fillPath: "app-header__language-icon_st1"
            }}/>
          </Icon>
        </DropdownToggle>

        <DropdownMenu className={styles.LanguagesDropdownMenu}>
          <LanguageSwitcherSurvey
            switchLanguage={onSwitchLanguage}
            handleRequestClose={handleRequestClose}
            surveyCurrentLocale={surveyLocale}
            usedSurveyLocales={usedSurveyLocales}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default LanguageSelectorSurvey;