/*
* 1. to disable notifications set "*"
* 2. to disable certain notifications set ["error_code_1", "error_code_2", ...]
* */
const checkNotificationBlackList = (notificationBlackList, errCode) => {
  if (!notificationBlackList || !notificationBlackList?.length) {
    return false;
  }
  if (notificationBlackList === "*") {
    return true;
  }

  return !!(notificationBlackList && notificationBlackList?.length && notificationBlackList?.includes(errCode));
};

export default checkNotificationBlackList;