import React from "react";
// COMPONENTS
import AsrSaveButton from "@/shared/components/Buttons/AsrSaveButton";
import AsrCheckBoxSmall from "@/shared/components/FormComponents/AsrCheckBoxSmall";
// UTILS
import IntlMessages from "@/utils/IntlMessages";
// STYLES
import styles from "../styles.module.scss";

const SignUpFormFooter = ({ fmk }) => {

  const isTouched = fmk?.touched?.agreement;
  const error = fmk?.errors?.agreement;

  const onChangeAgreement = (e) => {
    fmk.setFieldValue("agreement", e.target.checked);
  };

  const onAgreementLearnInfoClick = (key) => () => {
    window.open(`/${key}/`, "_blank");
  };

  return (
    <div className={styles.ModalFooter}>
      <AsrSaveButton
        type='submit'
        classes={{
          buttonRoot: styles.SubmitButton
        }}
        classNameWrapper={styles.SubmitButtonWrapper}
      >
        <IntlMessages
          id="auth.signUpButtonText"
          values={{
            span: value => <span className={styles.AttachmentSpan}>{value}</span>
          }}
        />
      </AsrSaveButton>

      <div className={styles.AgreementBlockWrapper}>
        <div className={styles.AgreementBlock}>
          <AsrCheckBoxSmall handler={onChangeAgreement}/>
          <span>
            <IntlMessages
              id="auth.iAgreeToTermsAndPolicy"
              values={{
                "terms-link": value => (
                  <span
                    className={styles.Link}
                    onClick={onAgreementLearnInfoClick("terms-of-use")}
                  >
                    {value}
                  </span>
                ),
                "policy-link": value => (
                  <span
                    className={styles.Link}
                    onClick={onAgreementLearnInfoClick("privacy")}
                  >
                    {value}
                  </span>
                )
              }}
            />
          </span>
        </div>

        {error && isTouched && (
          <div
            className={"form__error-text"}
            style={{
              marginTop: "2px",
              position: "absolute"
            }}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpFormFooter;
