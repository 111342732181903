import querystring from "query-string";
// UTILS
import { requestActionCreator } from "@/utils/requestActionCreator";
// TYPES
import * as types from "../types";

import { baseApiUrl } from "@/configs/MainConfig";
import { authService } from "@/shared/services/auth.service";


export const fetchOrganizationSurveyTemplatesList = (payload) => (dispatch, getState) => {
  const currentState = getState();
  const payloadQuery = {};

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const sortString = currentState.organization.selectedItem.surveyTemplates.sortBy;

  if (sortString.sort_by) {
    payloadQuery.sort_by = sortString.sort_by;
  }

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/survey-template/list/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const fetchOrganizationSurveyTemplatesListForAutocomplete = (payload) => (dispatch, getState) => {
  const currentState = getState();
  const payloadQuery = {
    sort_by: "created_dt_code",
  };

  Object.entries(payload).forEach((item) => {
    if (item[1]) {
      payloadQuery[item[0]] = item[1];
    }
  });

  const sortString = currentState.organization.selectedItem.surveyTemplates.sortBy;

  if (sortString.sort_by) {
    payloadQuery.sort_by = sortString.sort_by;
  }

  const query = querystring.stringify(payloadQuery);

  const url = `${baseApiUrl}/survey-template/list/?${query}`;

  return dispatch(requestActionCreator(
    types.FETCH_ORGANIZATION_SURVEY_TEMPLATES_LIST_FOR_AUTOCOMPLETE,
    url,
    "GET",
    { ...authService.getAuthHeaders() }
  ));
};

export const setOrganizationSurveyTemplatesListPagination = (data) => ({
  type: types.SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_PAGINATION,
  payload: {
    data
  }
});

export const setOrganizationSurveyTemplatesListSortOrder = (data) => ({
  type: types.SET_ORGANIZATION_SURVEY_TEMPLATES_LIST_ORDER,
  payload: {
    data
  }
});

export const fetchOrganizationSurveyExtendedInfo = (id) => requestActionCreator(
  types.FETCH_ORGANIZATION_SURVEY_TEMPLATE_EXTENDED_INFO,
  `${baseApiUrl}/survey-template/extended/?id=${id}`,
  "GET",
  { ...authService.getAuthHeaders() }
);

export const createOrganizationSurvey = (data) => requestActionCreator(
  types.CREATE_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/`,
  "POST",
  { ...authService.getAuthHeaders() },
  data
);

export const getOrganizationSurveyForManaging = (id) => {
  return requestActionCreator(
    types.GET_ORGANIZATION_SURVEY_FOR_MANAGING,
    `${baseApiUrl}/survey-template/?id=${id}`,
    "GET",
    { ...authService.getAuthHeaders() }
  );
};

export const updateOrganizationSurvey = (id, data) => requestActionCreator(
  types.UPDATE_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  data
);

export const updateOrganizationSurveyBody = (id, body) => requestActionCreator(
  types.UPDATE_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  { body }
);

export const deleteOrganizationSurvey = (id) => requestActionCreator(
  types.DELETE_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/?id=${id}`,
  "DELETE",
  { ...authService.getAuthHeaders() },
  null
);

export const cloneOrganizationSurvey = ({ id, data }) => requestActionCreator(
  types.CLONE_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/clone/?id=${id}`,
  "POST",
  { ...authService.getAuthHeaders() },
  data
);

export const downloadOrganizationSurvey = (id) => requestActionCreator(
  types.DOWNLOAD_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/file/extract-body/?id=${id}`,
  "POST",
  { ...authService.getAuthHeaders() },
  null
);

export const uploadOrganizationSurvey = ({ id, data }) => {
  const query = { id };
  const queryString = querystring.stringify(query);
  const url = `${baseApiUrl}/survey-template/file/upload-body/?${queryString}`;

  return requestActionCreator(
    types.UPLOAD_ORGANIZATION_SURVEY,
    url,
    "POST",
    { ...authService.getAuthHeaders() },
    data
  );
};

export const lockOrganizationSurvey = (id) => requestActionCreator(
  types.LOCK_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  { is_locked: true }
);

export const unlockOrganizationSurvey = (id) => requestActionCreator(
  types.UNLOCK_ORGANIZATION_SURVEY,
  `${baseApiUrl}/survey-template/?id=${id}`,
  "PATCH",
  { ...authService.getAuthHeaders() },
  { is_locked: false }
);
