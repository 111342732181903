import React, { useMemo } from "react";
import get from "lodash/get";
// MATERIAL UI
import Checkbox from "@mui/material/Checkbox";
// COMPONENTS
import AsrGlobalIcon from "@/shared/UI/Icons/AsrGlobalIcon";
import AsrLockIcon from "@/shared/UI/Icons/AsrLockIcon";
// STYLES
import styles from "./styles.module.scss";


const AsrAutocompleteMultipleRenderOption = ({
                                               selected,
                                               option,
                                               loading,
                                               withCheckbox = true,
                                               withGlobalityCheck = true,
                                               renderName,
                                               labelToDisplay,
                                               classes = {
                                                 option: "",
                                                 mainContent: "",
                                                 mainContentName: "",
                                                 mainContentNameStatus: "",
                                                 code: "",
                                                 checkbox: "",
                                                 lockIcon: "",
                                                 globalIcon: ""
                                               },
                                               customCode,
                                               customLabel,
                                               ...props
                                             }) => {
  const code = (option?.short_name || option?.abbreviation || option?.content?.abbreviation || customCode) ?? null;

  const isGlobal = withGlobalityCheck && (
    option?.organization === null
    || option?.group?.organization === null
    || option?.settings?.is_global
    || option?.group?.settings?.is_global
  );

  const isLocked = option?.is_locked || option?.settings?.is_locked;
  const name = useMemo(() => {
    if (labelToDisplay) {
      return labelToDisplay
        .map((item) => {
          return get(option, item);
        })
        .join(" | ");
    } else {
      return option?.name || option?.content?.name || renderName;
    }
  }, [labelToDisplay, option, renderName]);


  return (
    <div className={styles.AsrAutocompleteMultipleRenderOptionContainer}>
      {loading && <div className={styles.loader}/>}
      <div
        {...props}
        className={`${styles.AsrAutocompleteMultipleRenderOption} ${classes.option}`}>

        {withCheckbox && (
          <Checkbox
            checked={selected}
            classes={{
              root: `${styles.checkbox} ${classes.checkbox}`
            }}
            checkedIcon={<div className={`${styles.icon} ${styles.checkedIcon}`}/>}
            icon={<div className={styles.icon}/>}
          />
        )}
        {code && <div className={`${styles.code} ${classes.code}`}>{code}</div>}
        <div className={`${styles.mainContent} ${classes.mainContent}`}>
          <div className={`${styles.mainContentName} ${classes.mainContentName}`}>
            {customLabel ? customLabel : name}
          </div>
          <div className={`${styles.mainContentNameStatus} ${classes.mainContentNameStatus}`}>
            {isLocked && <AsrLockIcon className={`${styles.lockIcon} ${classes.lockIcon}`}/>}
            {isGlobal && <AsrGlobalIcon className={`${styles.globalIcon} ${classes.globalIcon}`}/>}
          </div>
        </div>
      </div>
    </div>

  );
};

export default AsrAutocompleteMultipleRenderOption;