import React, { useState, useContext } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { ResultsContext } from "../Modals/CampaignResultsModal/CampaignResultsModal";

const saveSectionStatus = (userId, keyForSavingCardState, keyForSavingSectionState, isSectionCollapsed) => {
    const reportCardsState = localStorage.getItem("reportCardsState");

    if (reportCardsState) {
        const parsedState = JSON.parse(reportCardsState);

        let updatedState;

        if (parsedState[userId]) {
            updatedState = {
                ...parsedState,
                [userId]: {
                    ...parsedState[userId],
                    [keyForSavingCardState]: {
                        ...(parsedState[userId][keyForSavingCardState] || {}),
                        [keyForSavingSectionState]: isSectionCollapsed,
                    },
                }
            };

            localStorage.setItem("reportCardsState", JSON.stringify(updatedState));
            return;
        }

        updatedState = {
            ...parsedState,
            [userId]: {
                [keyForSavingCardState]: {
                    [keyForSavingSectionState]: isSectionCollapsed,
                },
            }
        };

        localStorage.setItem("reportCardsState", JSON.stringify(updatedState));
        return;
    }

    const newState = {
        [userId]: {
            [keyForSavingCardState]: {
                [keyForSavingSectionState]: isSectionCollapsed,
            },
        }
    };

    localStorage.setItem("reportCardsState", JSON.stringify(newState));
};

const getSectionStatus = (userId, keyForSavingCardState, keyForSavingSectionState) => {
    const reportCardsState = localStorage.getItem("reportCardsState");

    if (reportCardsState) {
        const parsedState = JSON.parse(reportCardsState);
        return get(parsedState, `${userId}.${keyForSavingCardState}.${keyForSavingSectionState}`, false);
    }

    return false;
};

const CardSection = ({ heading, children, keyForSavingCardState, keyForSavingSectionState }) => {
    const { currentUserId } = useContext(ResultsContext);

    const [isCollapsed, setIsCollapsed] = useState(getSectionStatus(currentUserId, keyForSavingCardState, keyForSavingSectionState));

    const minimize = () => {
        setIsCollapsed(true);
        saveSectionStatus(currentUserId, keyForSavingCardState, keyForSavingSectionState, true);
    };

    const maximize = () => {
        setIsCollapsed(false);
        saveSectionStatus(currentUserId, keyForSavingCardState, keyForSavingSectionState, false);
    };

    return (
        <div className='col-lg-12 col-sm-12 report-card__section__wrapper'>
            <div className='report-card__container'>
                <div className='jr-card-header report-card__section__header__container'>
                    <h3 className='report-card__section__header__text'>{heading}</h3>
                    <div>
                        {isCollapsed ? (
                            <i
                                className='zmdi zmdi-plus zmdi-hc-2x'
                                style={{ color: "#FFFFFF", cursor: "pointer" }}
                                onClick={maximize}
                            />
                        ) : (
                            <i
                                className='zmdi zmdi-minus zmdi-hc-2x'
                                style={{ color: "#FFFFFF", cursor: "pointer" }}
                                onClick={minimize}
                            />
                        )}
                    </div>
                </div>
                {!isCollapsed && (
                    <div className='jr-card-body report-card__section__body__container'>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

CardSection.propTypes = {
    heading: PropTypes.string.isRequired,
    keyForSavingCardState: PropTypes.string.isRequired,
    keyForSavingSectionState: PropTypes.string.isRequired,
};

export default CardSection;