import React from "react";
// STYLES
import styles from "./styles.module.scss";

const BgLogo = () => {
  return (
      <div className={styles.BgLogo}>
        <div className={styles.BgLogoImage}/>
      </div>
  );
};

BgLogo.displayName = "BgLogo";
export default BgLogo;