import icons from "@/shared/UI/SvgIcons";

export const getAvailableSvgIcons = () => {
  return Object.keys(icons);
};

export const getIconsTypes = () => {
  const availableSvgIcons = getAvailableSvgIcons();
  const types = {};
  availableSvgIcons.forEach((item) => {
    types[item.toUpperCase()] = item;
  });
  return Object.freeze(types);
};